import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,  
  getGridStringOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import FileUploadIcon from "../../../assets/images/file-upload.svg";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";
import AddCircle from "../../../assets/images/add_circle.svg";
import constants, { DISPATCH_ORDER_STATUS, DpStatus, PROJECT_PREFIX, pageSetting, pageSizeModel } from "../../../constants/constants";
import { checkAbility } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block";
import {    
  getDpList,
} from "../../../services/warehouseService";

import moment from "moment";
import ExportDispatchOrdersData from "./exportData";

const DispatchOrdersList: React.FC = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [exportPopup,setExportPopup] = useState(false);
  const [isNewGrnPopup,setIsNewGrnPopup] = useState(false);
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const handleNewDpo = () => {
    navigate("add");
  }

  const handleEditClick = (id: GridRowId) => () =>{
    navigate(`edit/${id}`);
  }

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`view/${id}`);
  }

  const fetchData = (defaultParams: any) => {
    getDpList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if(filterModel.items[0].field === "dispatch_number"){
        filterModel.items[0].value = filterModel.items[0].value.replace(/[DP-]/g, '');
      }
      if(filterModel.items[0].field === "$project.project_no$"){
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "dispatch_number",
      headerName: "Dispatch Number",
      flex: 1,
      sortable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params){
        return params.row.dispatch_number_str
      }
    },
    {
      field: "dispatched_date",
      headerName: "Dispatch Date",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.dispatched_date),
      renderCell(params) {
        const orderDate = new Date(params.row.dispatched_date);
        return (
          params.row.dispatched_date ? (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
          ) : "-"
        );
      },
    },
    {
      field: "$project.project_no$",
      headerName: "Project Order No.",
      flex: 1,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params){
        return params.row?.project?.project_no ? params.row?.project?.project_no_str : "-"
      }
    },
    {
      field: "$project.name$",
      headerName: "Customer Name",      
      flex: 1,
      sortable: true,   
      renderCell(params){
        return params.row?.project.name ? params.row?.project.name : "-"
      }   
    },
    {
      field: "address",
      headerName: "Address",      
      flex: 1,
      sortable: true,   
      renderCell(params){
        return params.row.address ? params.row.address : "-"
      }   
    },
    {
      field: "received_date",
      headerName: "Received Date",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.received_date),
      renderCell(params) {
        const orderDate = new Date(params.row.received_date);
        return (
           params.row.received_date ? (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
          ) : "-"
        );
      },
    },
    {
      field: "$driver.full_name$",
      headerName: "Driver",      
      flex: 1,
      sortable: true,   
      renderCell(params){
        return params.row?.driver?.full_name ? params.row?.driver?.full_name : "-"
      }   
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      valueOptions: DpStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        if (params.row.status) {
          const statusName = DpStatus.find(
            (item) => item.key == params.row.status
          );
          return (
            <span
              title={statusName?.value}
              className={`py-1 ms-0 px-2 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
            >
              {statusName?.value}
            </span>
          );
        } else {
          return "-";
        }
      },
    }, 
  ];
  
  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.DISPATCH_ORDER
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.DISPATCH_ORDER
    );     

    if (
      checkEditAbilityCondition || checkViewAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if (checkEditAbilityCondition && row.status != DISPATCH_ORDER_STATUS.RECEIVED) {
            gridActions.push(
              <GridActionsCellItem
              label="View"
              icon={<EditIcon />}
              showInMenu={false}
              onClick={handleEditClick(id)}
              className="text-primary"
              />,              
            );
          }
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
              label="View"
              icon={<ViewIcon />}
              showInMenu={false}
              onClick={handleViewClick(id)}
              className="text-primary"
              />,              
            );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setLimit(newPaginationModel.pageSize);
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
            <div className="pageHeader px-3 py-2 my-1">
              <Row className="align-items-center">
                <Col>
                  <h1 className="fw-bold h4 mt-2 mb-0">Dispatch Orders</h1>
                </Col>
                <Col className="text-end">
                {exportPopup && <ExportDispatchOrdersData isOpen={exportPopup} onClose={toggleExportPopup} exportType="deal" label="Export Deals" text="Export Deals" />} 
                  <button
                    className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                    title="Export Dispatch Orders"
                    onClick={toggleExportPopup}
                  >
                    <img src={FileUploadIcon} alt="File Upload Icon" />
                  </button>                 
                  {checkAbility(
                    PERMISSION_ACCESS.ADD,
                    PERMISSION_MODULE.DISPATCH_ORDER
                  ) && (
                    <>  
                    <Button
                      className="fw-semibold fs-12 text-white  btn btn-primary"
                      variant="primary"
                      type="button"
                      onClick={() => handleNewDpo()}
                    >
                      <img
                        src={AddCircle}
                        className="me-2 fs-15"
                        alt="File Upload Icon"
                      />
                      Add Dispatch Order
                    </Button>
                    </>
                  )}
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className=" w-100 pageContentInner">
                <div className="d-flex flex-column  rounded-0 dataGridListMain">
                  <DataGrid
                    rows={data}
                    {...data}
                    columns={columns}
                    rowCount={totalRow}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationModelChange}
                    paginationModel={paginationModel}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    sortingMode="server"
                    pageSizeOptions={pageSizeModel}
                    className="border-0 rounded-2"
                  />                  
                </div>
              </Card>
            </div>
          </div>          
        </>
      )}
    </React.Fragment>
  );
};

export default DispatchOrdersList;
