import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
  GridRowHeightParams,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  getEmployeeDocumentList,
  getEmployeeUploadedDocument,
  deleteEmployeeDocument,
  uploadEmployeeDocument
} from "../../../../services/employeeService";
import Loader from "../../../../components/common/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import AddCircle from "../../../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";
import datetimeFormats, {
  pageSetting,
  pageSizeModel,
} from "../../../../constants/constants";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../../constants/permissionUser";
import { checkAbility } from "../../../../utility/common";
import constants, {
  EmployeeDocumentListType,
} from "../../../../constants/constants";
import SendToEmployeeDocumentPopup from "./Add/SendToEmployee";
import DeletePopup from "../../../../components/common/deletePopup";
import UploadDocument from "./Add/uploadDocument";
import UploadIcon from "@mui/icons-material/FileUpload";
import BlockIcon from "@mui/icons-material/Block";
interface AddProps {  
  employeeID: number;
  mainTitle?: string |number | null | undefined;
  uType?: string | null | undefined;
}

const DocumentSentsToEmployee: React.FC<AddProps> = ({
  employeeID,mainTitle ="Documents Sent To Employee By HR",uType="emp"
}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  // This state is used to add a special class for print action button when we are fetching signed url for PDF. Adding that class will make that button disabled when a request of getting signed url is in processing.
  const [loadingRows, setLoadingRows] = useState<GridRowId[]>([]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isNewDocumentPopupOpen, setNewDocumentPopupOpen] = useState(false);
  const [documentId, setDocumentId] = useState<GridRowId | null>(null);
  const [isUploadPopupOpen, setUploadPopupOpen] = useState(false);
  const [isDocLoading, setDocLoading] = useState(false);

  /**
   * New Document click handler
   */
  const addNewDocument = () => {
    setDocumentId(null);
    setNewDocumentPopupOpen(true);
   // navigate(`/employees/send-email/${employeeID}`);
  };  

  /**
   * New Document click handler
   */
  const handleNewDocumentClose = () => {
    setUploadPopupOpen(false);
    setNewDocumentPopupOpen(false);
    setIsMutation(!isMutation);
    setDocumentId(null);
   // navigate(`/employees/send-email/${employeeID}`);
  };

   /**
   * upload Document click handler
   */
   const handleUploadClick = (params: GridRowHeightParams) => () => {
    // Open the modal and set the selected row ID
    setUploadPopupOpen(true);
    setDocumentId(params.id);
    // navigate(`/employees/sent-email-detail/${id}`);
  };
 
  /**
   * Edit Document click handler
   */
  const handleEditClick = (id: GridRowId) => () => {
    setDocumentId(id);
    setNewDocumentPopupOpen(!isNewDocumentPopupOpen);
    // navigate(`/employees/sent-email-detail/${id}`);
  };

  /**
   * upload Document handler
   */
  const handleUploadDocument = (pdfFile : any) => {
    // Implement your upload logic here using formData
   // console.log('Uploading file for ID:', formData);
   setDocLoading(true);
    const formData = new FormData();
    formData.append('file', pdfFile);
    uploadEmployeeDocument(employeeID,formData,documentId)
        .then((response) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          handleNewDocumentClose();
          setDocLoading(false);
        })
        .catch(() => {
          handleNewDocumentClose();
          setDocLoading(false);
        });     
  }

  /**
   * delete Document click handler
   */
  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteEmployeeDocument(deletedId,employeeID)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  /**
   * Download sent email template click handler
   */
  const handleDownloadClick = (id: GridRowId) => () => {
    getEmployeeUploadedDocument(id, employeeID)
      .then((response: any) => {
        const signedFileUrl = response.data.file;
        // window.open(signedFileUrl, '_blank');
        window.open(signedFileUrl, "_blank");
        //console.log(signedFileUrl);
      })
      .catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {});
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const convertDataToObjects = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      uploaded_date: new Date(item.uploaded_date),
    }));
  };

  /**
   * Fetch sent Documents from server
   * @param {defaultParams}
   */
  const fetchData = (defaultParams: any) => {
    getEmployeeDocumentList(
      employeeID,
      EmployeeDocumentListType.SentToHr,
      defaultParams
    )
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet);
        setData(convertData);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    console.log("filterModel", filterModel);

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S. No.",
      sortable: true,
      filterable: false,
    },
    {
      field: "doc_name",
      headerName: "Document Name",
      flex: 1.5,
      sortable: true,
      renderCell(params) {
        return params.row.status == 1 ? (
          <button
            type="button"
            className="btn btn-link ps-0 text-decoration-none"
            title={params.row.doc_name}
            onClick={handleDownloadClick(params.row.id)}
          >
            {params.row.doc_name ? params.row.doc_name : "-"}
          </button>
        ) : (
          <span title={params.row.doc_name}>{params.row.doc_name ? params.row.doc_name : "-"}</span>
        );
      },
    },
    {
      field: "uploaded_date",
      headerName: "Uploaded Date",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.uploaded_date),
      renderCell(params) {
        const orderDate = new Date(params.row.uploaded_date);
        return params.row.status == '1' && params.row.uploaded_date ? (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        ) : "-";
      },
    },    
    
  ];

  const handleEditViewActionPermission = () => {
    let status1Label = "Received";
    if(uType == "emp"){
      columns.push({
        field: "description",
        headerName: "Remarks",
        flex: 2,
        sortable: true,  
        renderCell(params) {
          return (
            <span title={params.row.description}>{params.row.description ? params.row.description : "-"}</span> 
          )        
        },      
      });
      status1Label = "Sent";

    }
    columns.push({
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      valueOptions: [{ value: 0, label: 'Pending' },
      { value: 1, label: status1Label }],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        const statusVal = params.row.status == 1 ? status1Label : "Pending";
        const tagClass =
          params.row.status == 1 ? "Permanent-tag" : "Probation-tag";
        return (
          <span
            className={`py-1 px-2 rounded-1 d-inline-block fs-11 ${tagClass}`}
            title={statusVal}
          >
            {statusVal}
          </span>
        );
      },
    });

    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );
    
    if(uType == "emp"){
      if (checkEditAbilityCondition || checkViewAbilityCondition) {
        columns.push({
          field: "actions",
          type: "actions",
          headerName: "Action",
          flex: 1,
          cellClassName: "actions",
          getActions: ({ id, row }) => {
            const gridActions = [];  
            if(uType == "emp"){
              if (checkEditAbilityCondition) {
                if(!(row?.status == 1)){
                  gridActions.push(              
                    <GridActionsCellItem
                      label="Upload Document"
                      onClick={handleUploadClick(row)}
                      showInMenu
                      icon={<UploadIcon />}
                    />
                  );
                }
                gridActions.push(              
                  <GridActionsCellItem
                    label="Edit"
                    onClick={handleEditClick(id)}
                    showInMenu
                    icon={<EditIcon />}
                  />
                );            
              }
              if (checkDeleteAbilityCondition) {
                gridActions.push(
                  <GridActionsCellItem
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    showInMenu
                    icon={<DeleteIcon />}
                  />
                );
              }
            } 
            else{
              gridActions.push(
                <GridActionsCellItem
                  label="View"
                  onClick={handleDeleteClick(id)}
                  showInMenu
                  icon={<ViewIcon />}
                  className="text-primary"
                />
              );
            }       
            return gridActions;
          },
        });
      } else {
        columns.push({
          field: "actions",
          type: "actions",
          headerName: "Action",
          flex: 1,
          cellClassName: "actions",
          getActions: ({ id }) => {
            const gridActions = [];  
            gridActions.push(<GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />);
            
            return gridActions;
          },
        });
      }
    }
    else{
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];            
          gridActions.push(
            <GridActionsCellItem
              label="View"
              title="View"
              onClick={handleDownloadClick(id)}
              showInMenu={false}
              icon={<ViewIcon />}
              className="text-primary"
            />
          );          
          return gridActions;
        },
      });
    }    
  };
  handleEditViewActionPermission();
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>
              <h1 className="fw-bold h4 my-2 info-text fs-20">
                {mainTitle}
              </h1>
            </div>
            <div>
              {checkAbility(
                PERMISSION_ACCESS.EDIT,
                PERMISSION_MODULE.ALL_EMPLOYEE
              ) && (
                <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => addNewDocument()}
                >
                  <img
                    src={AddCircle}
                    className="me-2 fs-15 d-inline-block v-align-bottom"
                  />
                  Add Document
                </Button>
              )}
            </div>
          </div>

          <Card className="p-0 w-100 pageContentInner">
            <div className="d-flex flex-column rounded-0 dataGridListMain">
              <DataGrid
                rows={data.map((row: any, index: number) => ({
                  ...row,
                  serialNumber: index + 1,
                }))}
                {...data}
                columns={columns}
                rowCount={totalRow}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                onSortModelChange={handleSortModelChange}
                loading={loading}
                sortingMode="server"
                disableRowSelectionOnClick
                pageSizeOptions={pageSizeModel}
                className="border-0 rounded-2"
              />
            </div>
          </Card>
          {isNewDocumentPopupOpen && (
            <SendToEmployeeDocumentPopup
              isOpen={isNewDocumentPopupOpen}
              onClose={handleNewDocumentClose}
              employeeID={employeeID}
              documentId={documentId}
            />
          )}
          <UploadDocument
            isOpen={isUploadPopupOpen}
            onClose={handleNewDocumentClose}
            onUpload={handleUploadDocument}
            docLoading={isDocLoading}
          />
          <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Document"
                  text="Are you sure you want to delete this Document?"
                />
        </div>
      )}
    </React.Fragment>
  );
};

export default DocumentSentsToEmployee;
