import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";
import Loader from "../../components/common/Loader";
import ViewIcon from "@mui/icons-material/Visibility";
import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import AddCircle from "../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";
import LogExpensesPopup from "./Add";
import { checkAbility, currencyMask } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import constants, { PROJECT_PREFIX, expensesCategories, pageSizeModel } from "../../constants/constants";
import moment from "moment";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteExpenseApi, getExpenseList } from "../../services/expenseService";
import ExportLogExpensesData from "./exportData";
import FileUploadIcon from "../../assets/images/file-upload.svg";
import { useNavigate } from "react-router-dom";
import ViewExpensesPopup from "./View";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ViewExpenseQueryPopup from "./Query";

interface RoleOption {
  key: string;
  value: string;
}
interface IPostalCode {
  id?: number;
  code?: string;
  createdAt: string;
  updatedAt: string;
  postalCodeUsers: any;
}
const LogExpensesList: React.FC<Record<string, string>> = ({
  utype = ""
}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [deletedId, setDeletedId] = useState<GridRowId | null | any>(null);
  const [editableData, setEditableData] = useState<IPostalCode | null>();
  const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);
  const [exportPopup,setExportPopup] = useState(false);

  const [logExpensePopupOpen, setLogExpensePopupOpen] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isViewPopupOpen, setViewPopupOpen] = useState(false);
  const [isQueryPopupOpen, setQueryPopupOpen] = useState(false);


  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  const toggleNewLogExpensePopup = () => {
    setDeletedId(null);
    setLogExpensePopupOpen(!logExpensePopupOpen);
  }; 

  const handleViewClick = async (id: GridRowId) => {
    setDeletedId(id);
    setViewPopupOpen(!isViewPopupOpen);
  };

  const handleDeleteClick = (id: GridRowId) => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleAskQueryClick = async (id: GridRowId) => {
    setDeletedId(id);
    setQueryPopupOpen(true);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const handleClose = () => {
    setViewPopupOpen(false);
    setDeletePopupOpen(false);
    setLogExpensePopupOpen(false);
    setQueryPopupOpen(false);
    setDeletedId(null);
    setIsMutation(!isMutation);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteExpenseApi(deletedId)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        setDeletePopupOpen(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = (defaultParams: any) => {
    getExpenseList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  type UserDetails = {
    full_name: string;
    id: number;
  };

  type UserArrayElement = {
    user_id: number;
    userDetails: UserDetails;
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if(filterModel.items[0].field === "$project.project_no$"){
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      sortable: true,
      hideable: false,
    },
    {
      field: "$project.project_no$",
      headerName: "Project",
      flex: 1,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params) {
        return params.row?.contract_id ? (
          <span title={params.row?.project?.project_no_str}>
            {params.row?.project?.project_no_str}
          </span>
        ) : '-';
      },
    },
    {
      field: "$category.name$",
      headerName: "Category",
      flex: 1,
      sortable: true,      
      renderCell(params) {
        if (params.row.category_id) {         
          return <span title={params.row.category.name}>{params.row.category.name}</span>;
        } else {
          return "-";
        }
      },
    },
    { field: "amount", headerName: "Amount", flex: 1, sortable: true, renderCell(params) {
      return (
        <span title={params.row?.amount}>
          {currencyMask(params.row?.amount)}
        </span>
      );
    }, },
    { field: "vendor_name", headerName: "Vendor", flex: 1, sortable: true },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date),
      renderCell(params) {
        const eventDate = new Date(params.row.date);
        return (
          <span>
            {moment(eventDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
  ];

  const handleEditViewActionPermission = () => {      

   /*  if (checkEditAbilityCondition || checkDeleteAbilityCondition) { */
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: .5,
        cellClassName: "actions",
        getActions: ({ id, row}) => {
          const gridActions = [];          
        //  if (checkEditAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                onClick={(event) => handleViewClick(id)}
                showInMenu
                icon={<ViewIcon />}
              />
            );
            gridActions.push(
              <GridActionsCellItem
                label="Ask Query"
                onClick={(event) => handleAskQueryClick(id)}
                showInMenu
                icon={<QuestionAnswerIcon className="fs-20" />}
              />
              )
         // }
          if (row.status == "0") {
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                onClick={(event) => handleDeleteClick(id)}
                showInMenu
                icon={<DeleteIcon />}
              />
            );
          }
          return gridActions;
        },
      });
   /*  } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    } */
  };
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);
  
  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Logged Expenses</h1>
              </div>
              <div>
              {exportPopup && <ExportLogExpensesData isOpen={exportPopup} onClose={toggleExportPopup} exportType="deal" label="Export Vendors" text="Export Vendors" />} 
                  <button
                    className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                    title="Export Vendors"
                    onClick={toggleExportPopup}
                  >
                    <img src={FileUploadIcon} alt="File Upload Icon" />
                  </button>
               
                 <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => toggleNewLogExpensePopup()}
                  >
                    <img
                      src={AddCircle}
                      className="me-2 fs-15"
                      alt="File Upload Icon"
                    />
                    Log Expense
                  </Button>                  
                {/* )}                */}
              </div>
            </div>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" border-0 w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  pageSizeOptions={pageSizeModel}
                  sortingMode="server"
                  disableRowSelectionOnClick
                />
                {logExpensePopupOpen && (
                  <LogExpensesPopup
                    isOpen={logExpensePopupOpen}
                    hId={deletedId}
                    onClose={handleClose}
                  />
                )}               

                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Expense"
                  text="Are you sure you want to delete this expense?"
                />
                {
                  isViewPopupOpen && (
                    <ViewExpensesPopup isOpen={isViewPopupOpen} onClose={handleClose} expensesId={deletedId} viewType="emp" />
                  )
                }
                {
                  isQueryPopupOpen && (
                    <ViewExpenseQueryPopup isOpen={isQueryPopupOpen} onClose={handleClose} expensesId={deletedId} viewType="emp" />
                  )
                }
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LogExpensesList;
