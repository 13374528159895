import { useRoutes } from "react-router-dom";
import storage from "../helpers/storage";
// import { NotFound } from "../pages/NotFound/NotFound";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import { Login } from "../pages/Auth/Login";

/**
 *
 * @returns Routes Elements
 */
export default function AppRoutes() {
  
  const commonRoutes = [
    {
      path: "*",
      element: <Login />,
    },
  ];
  const mainRoutes = storage.getToken() != null ? protectedRoutes : [];
  // const mainRoutes = protectedRoutes;
  const element = useRoutes([...publicRoutes,...mainRoutes,...commonRoutes]);
  return <>{element}</>;
}

