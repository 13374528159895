import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Image, Row,   } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import Loader from "../../../components/common/Loader";
import { checkAbility } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import { getProjectDetails, projectControlSectionMarkComplete } from "../../../services/project.service";
import SurveyInitiate from "./SurveyInitiate";
import PreviewSurveyPage from "../../Survey/Start_Survey/compareAndFinish/previewSurvey";
import RevisedSurvey from "./RevisedSurvey";
import ComparativeStudy from "./ComparativeStudy";
import ActiveIcon from "../../../assets/images/currentactive.svg";
interface IViewProject {
  setActiveTab?: any;
  activeTabisDone?: string | number;
}

const ProjectSurvey: FC<IViewProject> = ({ setActiveTab, activeTabisDone }) => {
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState<any>({});
  const navigate = useNavigate();
  const [isMutation, setIsMutation] = useState(false);
  const [projectData, setProjectData] = useState<any>({});
  const [projectControls, setProjectControl] = useState<any>({});
  const [mainProjectControls, setMainProjectControl] = useState<any>({});
  const [surveyId, setSurveyId] = useState<string | any>(null);
  const [currentActiveTab, setCurrentActiveTab] = useState("");
  const [currentTabIsDone, setCurrentTabIsDone] = useState(false);
  const [isNextEnable,setIsNextEnable] = useState(false);
  const [checkEditPermission, setCheckEditPErmission] = useState(() => {
    return checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.ALL_EMPLOYEE);
  });
  let { id } = useParams<string>();

  const employeeDetails = employee?.employeeDetails
    ? employee?.employeeDetails
    : {};
  const kinDetails = employee?.kinDetails ? employee?.kinDetails : {};
  const employeeBankDetails = employee?.emplyeeBankDetails
    ? employee?.emplyeeBankDetails
    : {};

  /**
   * Get employee details from server
   * @async
   * @param {id} ID of employee
   */
  const fetchProjectDetail = (id: any) => {
    setLoading(true);
    getProjectDetails(id)
      .then((response) => {
        console.log("response      =====", response);
        setProjectData(response.data.project);
        getProjectControl(response.data.project);
        if (response.data.project?.survey) {
          setSurveyId(response.data.project?.survey?.id);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/projects");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/projects");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/projects");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setCorrentActiveTab = (projectControlData: any) => {
    // {projectControls?.subsection && projectControls?.subsection.map((controls: any, index: any) =>{
    if (projectControlData?.subsection) {
      const subsection = projectControlData.subsection;
      for (let i = 0; i < subsection.length; i++) {
        const controls = subsection[i];
        console.log("controls",controls)
        if(controls.key==='COMPARATIVE_STUDY' && controls.value){
          setIsNextEnable(true);
        }
        if(controls.key==='REVISE_CONTRACT' && controls.value){
          setIsNextEnable(true);
        }
        if (!controls.value) {
          setCurrentActiveTab(controls.key);
          setCurrentTabIsDone(controls.value);
          // break the loop
          return;
        } else {
          setCurrentActiveTab(controls.key);
          setCurrentTabIsDone(controls.value);
        }
      }
    }
  };

  const getProjectControl = (projectDataObj: any) => {
    const projectControls = projectDataObj?.project_page_control.find(
      (item: any) => item.key == "SURVEY"
    );
    setMainProjectControl(projectDataObj?.project_page_control);
    setProjectControl(projectControls);
    setCorrentActiveTab(projectControls);
  };

  useEffect(() => {
    if (id) {
      fetchProjectDetail(id);
    } else {
      navigate(-1);
    }
  }, [isMutation]);

  const handleNextPhaseClick = () => {
    // navigate(`/projects/procurement/${id}`);
    if (isNextEnable) {
      projectControlSectionMarkComplete("SURVEY", id).then((response) => {
       
        setActiveTab("PROCUREMENT");   
      });      
    }
  };

  const handleNextControlChange = (controls: any) => {
     
    if(controls.key==='COMPARATIVE_STUDY' && controls.value){
      setIsNextEnable(true);
    } 
    if(controls.key==='REVISE_CONTRACT' && controls.value){
      setIsNextEnable(true);
    }
      setCurrentActiveTab(controls.key);
     
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" p-3 w-100 pageContentInner mb-3 project-shadow rounded-4">
              <Row className="align-items-center">
                <Col className="col-md-10">
                  {/* { JSON.stringify(projectControls?.subsection)} */}
                  {projectControls?.subsection &&
                    projectControls?.subsection.map(
                      (controls: any, index: any) => (
                        <div
                          className={`cursor-pointer d-inline-block fs-14 fw-semibold me-4 ${
                            controls.value
                              ? "project-active"
                              : controls.key == "SURVEY_INITIATED"
                              ? "project-active"
                              : "project-default"
                          }`}
                          onClick={() => {
                            handleNextControlChange(controls);
                          }}
                        >
                          {currentActiveTab === controls.key ? (
                            <Image
                              src={ActiveIcon}
                              alt="active"
                              className="me-2"
                            />
                          ) : (
                            <span></span>
                          )}
                          {controls.label}
                        </div>
                      )
                    )}

                  {/* <div className="d-inline-block fs-14 fw-semibold me-4 project-next"><span></span>Collect all Document</div>
                  <div className="d-inline-block fs-14 fw-semibold project-default"><span></span>Assing Team</div> */}
                </Col>
                <Col className="text-end">
               
                  <Button
                    onClick={handleNextPhaseClick}
                    className={`fw-semibold fs-12 fs-14 fw-bold text-white  me-2 ${
                      isNextEnable
                        ? "btn btn-primary"
                        : "btn btn-secondary"
                    }`}
                    type="button"
                  >
                    Next Phase
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.56688 13.1337L10.1927 9.5L6.56688 5.86625L7.68313 4.75L12.4331 9.5L7.68313 14.25L6.56688 13.1337Z"
                        fill="#889EAE"
                      />
                    </svg>
                  </Button>
                </Col>
              </Row>
            </Card>

            {currentActiveTab === "SURVEY_INITIATED" && (
              <SurveyInitiate
                projectData={projectData}
                kinDetails={kinDetails}
                employeeBankDetails={employeeBankDetails}
              />
            )}
            {/*  {currentActiveTab === "SURVEY_DONE" && !currentTabIsDone && (
                 <h1 className="label-title">{JSON.stringify(currentTabIsDone)} Survey in progress</h1>
            )  
            } */}


            {    (currentActiveTab === "SURVEY_DONE" ||
              currentActiveTab === "SURVEY_DONE" ||
              currentActiveTab === "REVIEW_SURVEY") && (
              <>
                  <PreviewSurveyPage
                  survey_id={surveyId}
                  onlyView={true}
                  contract_id={projectData?.id}
                  setIsMutation={setIsMutation}
                  currentTabIsDone={currentTabIsDone}
                />   
              
              </>
            )}

            {currentActiveTab === "COMPARATIVE_STUDY" && (
              <ComparativeStudy
                contract_id={projectData?.id}
                currentTabIsDone={currentTabIsDone}
                setIsMutation={setIsMutation}
              />
            )}

            {currentActiveTab === "REVISE_CONTRACT" && (
              <RevisedSurvey
                contract_id={projectData?.id}
                setIsMutation={setIsMutation}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProjectSurvey;
