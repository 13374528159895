import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../components/common/InputText";
import { complaintSchema } from "./validation";
import FormSelect from "../../../components/common/FormSelect";
import constants, { departmentOptions, tickerStatus } from "../../../constants/constants";
import CustomButton from "../../../components/common/CustomButton";
import CancelIcon from '@mui/icons-material/Cancel';
import Loader from "../../../components/common/Loader";
import InputDatePicker from "../../../components/common/InputDatePicker";
import { getAllManagerList } from "../../../services/authService";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import InputTextArea from "../../../components/common/InputTextArea";
import GeneralFileUploader from "../../../components/common/GeneralFileUploader";
import moment from "moment";
import {
  createServiceComplaintApi,
  getServiceComplaintNumber,
  getServiceComplaintDetailsApi,
  updateServiceComplaintApi,
  uploadServiceComplaintImageApi,
} from "../../../services/ticketService";
import { ComplaintDataDto } from "./complaint.type";
import { getOrderNumberListApi } from "../../../services/dealService";
import LightboxGallery from "../../../components/common/LightboxGallery";

export const AddEditServiceWarrantyComplaint = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [complaintData, setComplaintData] = useState<any>({});
  const [imageLoading, setImageLoading] = useState(false);
  const [serviceComplaintNumber, setServiceComplaintNumber] = useState(null);
  const [signedImages, setSignedImages] = useState<any>([])
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [imagesSet, setImagesSet] = useState<any>([]);
 

  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ];
  const maxFileSizeMB = 1;

  let { id } = useParams();

  /**
   * Submit Method to call when vendor save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: ComplaintDataDto, { setSubmitting }: any) => {
    const formData = {
      ...values,
      due_date: moment(values.due_date).format(
        constants.dateFormats.databaseFormat
      ),
    };
    
    delete formData.filename;
    delete formData.filesSignedURL;
    //  return;
    setBtnLoading(true);
    if (id) {
      updateServiceComplaintApi(id, formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/service-warranty");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createServiceComplaintApi(formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/service-warranty");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getServiceWarrantyDetails = (id: string) => {
    setLoading(true);
    getServiceComplaintDetailsApi(id)
      .then((response) => {
        setComplaintData(response.data);   
        setSignedImages(response.data.filesSignedURL)    
         setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/service-warranty");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/service-warranty");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/service-warranty");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchComplaintNumber = async () => {
    return new Promise((resolve, reject) => {
      if (id) {
        return resolve(true);
      } else {
        getServiceComplaintNumber().then((response) => {
          setServiceComplaintNumber(response?.data?.ticket_no);
          return resolve(true);
        });
      }
    });
  };

  useEffect(() => {
    Promise.all([fetchComplaintNumber()]).then(() => {
      if (id) {
        getServiceWarrantyDetails(id);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleCancel = () => {
    navigate(-1);
  };

  const uploadFile = (event: any, setFieldValue: any, values?: any) => {
    const imageFile = event.target.files;
     
    // Check file type
    for(let k=0; k<imageFile.length;k++ ){
      if (!allowedFileTypes.includes(imageFile[k].type)) {
        toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
          type: toast.TYPE.ERROR,
        });
        return;
      } else if (imageFile[k].size > maxFileSizeMB * 1024 * 1024) {
        toast(`File size must be less than ${maxFileSizeMB} MB.`, {
          type: toast.TYPE.ERROR,
        });
  
        return;
      } 
    }
      setImageLoading(true);

      // Create FormData object
      const formData = new FormData();
      
      for (let p = 0; p< imageFile.length; p++) {
        formData.append("files", imageFile[p]);
      }


      uploadServiceComplaintImageApi(formData)
        .then((response) => {
          setImageLoading(false);
          console.log("values.files",values.files)
          const uploadFiles = [...values.files, ...response.data.files];
          console.log("uploadFiles",uploadFiles)
          setFieldValue("files", uploadFiles);
          setFieldValue("filename", imageFile.name);
          setSignedImages((previous: any) => [...previous, ...response.data.filesSignedURL]);
        })
        .catch(() => {
          setImageLoading(false);
        });
    
  };

  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType: "project",
        showAll: true
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      const transformParam = {
        ...params,
      };
      getAllManagerList(transformParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const initialValues: any = {
    ticket_no: id ? complaintData.ticket_no_str : serviceComplaintNumber,
    title: complaintData?.title ? complaintData?.title : "",
    contract_id: complaintData?.contract_id ? {
      ...complaintData?.project,
      name: complaintData?.project.project_no,
    } : null,
    due_date: complaintData?.due_date ? moment(complaintData?.due_date) : null,
    assigned_to: complaintData?.assigned_to
      ? {
          ...complaintData?.assignedUser,
          name: complaintData?.assignedUser.full_name,
        }
      : null,
    task_owner_id: complaintData?.task_owner_id
      ? {
          ...complaintData?.taskOwner,
          name: complaintData?.taskOwner.full_name,
        }
      : null,
    description: complaintData?.description ? complaintData?.description : "",
    department: complaintData?.department
      ? departmentOptions.find((item) => item.key == complaintData?.department)
      : null,    
    files: complaintData?.files?complaintData?.files : [],
    filename:   complaintData?.files?complaintData?.files : [],
    filesSignedURL: complaintData?.filesSignedURL?.length
      ? complaintData?.filesSignedURL[0]
      : "",
  };


  const handleClose = () => {
   
    setCurrentIndex(0)
    setLightboxIsOpen(false);
  };
  const openLightbox = ( imageIndex: any) => {
    const updatedImgSet = signedImages.map((item: any) => {
      return { ...item, src: item };
    });
     setImagesSet(updatedImgSet)
    setCurrentIndex(imageIndex);
    setLightboxIsOpen(true);
  };
  const handleDeleteReturnImage= (formValues:any, signedImages: any, index: number, setFieldValue: any) =>{
      const failedGrnImagePreview  = signedImages;
      const failedGrnImageDetails = formValues.files;

      failedGrnImagePreview.splice(index,1)
      failedGrnImageDetails.splice(index,1)
      setSignedImages(failedGrnImagePreview)       
      setFieldValue(`files`, failedGrnImageDetails);

  }
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {id ? "Edit" : "Create"} Service Log
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={complaintSchema}
              initialValues={initialValues}
              onSubmit={(values: ComplaintDataDto, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <div className="col-md-12">
                            <Row>
                              <Col className="col-md-4">
                                <InputText
                                  controlId="ticket_no"
                                  label="Service Log Number"
                                  placeholder="Enter Service Log Number"
                                  isDisable
                                  touched={touched.ticket_no}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.ticket_no}
                                  value={values.ticket_no}
                                />
                              </Col>
                              <Col className="col-md-4">
                                <FormAsyncSelect
                                  id="contract_id"
                                  name="contract_id"
                                  label="Project Order Number"
                                  isAsync
                                  isClearable
                                  getOptions={getOrderNumber}
                                  value={values.contract_id}
                                  error={errors.contract_id}
                                  touched={touched.contract_id}
                                  onChange={(name: any, value: any) => {
                                    setFieldValue(name, value);
                                  }}
                                />
                              </Col>
                              <Col className="col-md-4">
                                <InputText
                                  controlId="title"
                                  label="Service Log Title"
                                  placeholder="Enter Service Log Title"
                                  touched={touched.title}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.title}
                                  value={values.title}
                                />
                              </Col>
                              <Col className="col-md-4">
                                <FormSelect
                                  placeholder="Select"
                                  label="Department"
                                  name="department"
                                  options={departmentOptions}
                                  getOptionLabel={(option: any) => option.value}
                                  getOptionValue={(option: any) => option.key}
                                  onChange={(name: string, value: string) => {
                                    setFieldValue(name, value);
                                  }}
                                  value={values.department}
                                  error={errors.department}
                                  touched={touched.department}
                                />
                              </Col>
                              <Col className="col-md-4">
                                <InputDatePicker
                                  name="due_date"
                                  label="Due Date"
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      placeholder: "Select",
                                      className: "form-control",
                                      readOnly: true,
                                      id: "due_date",
                                    },
                                  }}
                                  value={values.due_date}
                                  onChange={(name, newValue) =>
                                    setFieldValue(name, newValue)
                                  }
                                  touched={touched.due_date}
                                  errors={errors.due_date}
                                />
                              </Col>
                              <Col className="col-md-4">
                                <FormAsyncSelect
                                  id="task_owner_id"
                                  name="task_owner_id"
                                  label="Project Task Owner"
                                  isAsync
                                  isClearable
                                  getOptions={getProjectOwner}
                                  value={values.task_owner_id}
                                  error={errors.task_owner_id}
                                  touched={touched.task_owner_id}
                                  onChange={(name: any, value: any) => {
                                    setFieldValue(name, value);
                                  }}
                                />
                              </Col>
                              <div className="col-md-8">
                            <InputTextArea
                              controlId="description"
                              label="Service Log Description"
                              placeholder="Enter Service Log Description"
                              touched={touched.description}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.description}
                              name="description"
                              value={values.description}
                              className="form-control textarea-height"
                            />
                          </div>
                              <Col className="col-md-4">
                                <FormAsyncSelect
                                  id="assigned_to"
                                  name="assigned_to"
                                  label="Assignee"
                                  isAsync
                                  isClearable
                                  getOptions={getProjectOwner}
                                  value={values.assigned_to}
                                  error={errors.assigned_to}
                                  touched={touched.assigned_to}
                                  onChange={(name: any, value: any) => {
                                    setFieldValue(name, value);
                                  }}
                                />                                
                              </Col>
                              <Col className="col-md-12">
                                <GeneralFileUploader
                                  uploadFile={(e) =>
                                    uploadFile(e, setFieldValue, values)
                                  }
                                  imageLoading={imageLoading}
                                  label="Attachment"
                                  filename=""
                                  acceptFormat=".jpeg, .png,.jpg,.svg"
                                  
                                  isMultiple={true}
                                />
                                <ErrorMessage
                                  name="filename"
                                  component="div"
                                  className="text-danger fs-14"
                                />
                                  <div className="d-flex flex-wrap">
                {signedImages.map((image: any, index: number) => {
                  return (
                    <div key={index} className="m-2 position-relative">
                      <img
                        src={image}
                        alt="preview"
                        loading="lazy"
                        style={{ width: "80px", height: "80px"  }}
                        className="img-fluid imageFitScal"
                        onClick={()=>openLightbox(index)}
                      />
                      <div
                                        className="good-inward-delete-icon cursor-pointer"
                                        onClick={() =>
                                          handleDeleteReturnImage(
                                           values,
                                            signedImages,
                                            index,
                                            setFieldValue
                                          )
                                        }
                                      >
                                         
                                        <CancelIcon className="text-danger bg-white rounded-5 fs-16" />
                                        
                                      </div>
                    </div>
                  );
                })}
              </div>
                              </Col>
                            </Row>
                          </div>
                          
                        </Row>
                        <Row></Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <div
                            className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                            onClick={handleCancel}
                          >
                            Cancel
                          </div>

                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={imageLoading || btnLoading}
                            className="fw-semibold fs-13 text-white  mt-2"
                            variant="primary"
                          >
                            {id ? "Update" : "Add"}
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
       <LightboxGallery images={imagesSet} currentIndex={currentIndex} open={lightboxIsOpen} onClose={handleClose} />
    </React.Fragment>
  );
};
