import { Route, Routes } from "react-router-dom";
import AddForm from "./Add-Form";
import FormList from "./index";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";

export const Formbuilder = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.FORM_BUILDER) && (
        <Route path="/" element={<FormList />} />
      )}
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.FORM_BUILDER) && (
        <Route path="/add-form" element={<AddForm />} />
      )}

      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.FORM_BUILDER) && (
        <Route path="/edit-form/:id" element={<AddForm />} />
      )}
    </Routes>
  );
};
