import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridSingleSelectOperators
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Button, Card, Col, Row } from "react-bootstrap";
import { checkAbility } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import constants, { LeaveStatus, pageSizeModel } from "../../../constants/constants";
import moment from "moment";
import ViewIcon from "@mui/icons-material/Visibility";
import { getEmployeeLeaveHistoryList } from "../../../services/leavesService";
import LeaveHistoryData from "../../Leaves/My-Leaves/LeaveHistoryData";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ApproveLeave from "../../Leaves/View/approveLeave";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../features/Auth/authSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import AddEmployeeLeave from "./EmployeeAddLeave";
import BackspaceIcon from '@mui/icons-material/Backspace';

const EmployeeLeaves: React.FC<Record<string, string>> = ({
  employeeID,rtype = "all"
}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("date_from");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [selectedYear, setSelectedYear] = useState<any>(null);
  const [leaveHistroyData, setLeaveHistroyData] = useState<any | null>([]);
  const [leaveAvailableData, setLeaveAvailableData] = useState<any | null>(0);
  const [deletedId, setDeletedId] = useState<GridRowId | null | any>(null);
  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [applyLeavePopupOpen, setApplyLeavePopupOpen] = useState(false);
  const [approveType, setApproveType] = useState("");
  const {user }:any = useSelector(selectAuthentication);
  const userId = user.user.id;


  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const handleRejectClick = (id: GridRowId) => {
    setRejectPopupOpen(true);
    setApproveType('reject');
    setDeletedId(id);
  };
  const handleApproveClick = (id: GridRowId) => {
    setRejectPopupOpen(true);
    setApproveType('approve');
    setDeletedId(id);
  };

  const handleCancelClick = (id: GridRowId) => {
    setRejectPopupOpen(true);
    setApproveType('cancel');
    setDeletedId(id);
  };
  
  const handleViewClick = async (id: GridRowId) => {
    navigate(`/leaves/view/${id}/${employeeID}`);
  };  

  const handleApplyLeave = () => {
    setApplyLeavePopupOpen(true)
  };

  const handleClose = (isReload?:boolean) => {
    setRejectPopupOpen(false);    
    setApplyLeavePopupOpen(false);    
    setDeletedId(null);
    if(isReload){
      setIsMutation(!isMutation);
    }
  };  

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,    
    ...(search ? { search } : {}),
  };


  const fetchData = (defaultParams: any) => {    
    getEmployeeLeaveHistoryList(defaultParams,employeeID)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
        setLeaveHistroyData(response.data?.leavesSummary);
        setLeaveAvailableData(response.data?.remainingLeaves?.leaves ? response.data?.remainingLeaves?.leaves : 0);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S. No.",
      sortable: false,
      filterable: false,
      hideable:false
    },
    {
      field: "$leaveType.name$",
      headerName: "Leave Type",
      flex: 1,
      sortable: true,
      hideable: false,
      renderCell(params) {
        const orderDate = new Date(params.row.date_from);
        return (
          <span title={params.row.leaveType.name}>
            {params.row.leaveType.name}
          </span>
        );
      },
    },    
    {
      field: "leaves_count",
      headerName: "No. of Days",
      type: "number",
      flex: 0.5,
      sortable: true,
      renderCell(params) {        
        return params.row.leaves_count == '0.5' ?  `${params.row.leaves_count} (${params.row.session == '1' ? 'AM' : 'PM'})`  : params.row.leaves_count;
      }
    },
    {
      field: "date_applied",
      headerName: "Date Applied",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date_applied),
      renderCell(params) {
        const orderDate = new Date(params.row.date_applied);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "date_from",
      headerName: "From",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date_from),
      renderCell(params) {
        const orderDate = new Date(params.row.date_from);
        return (
          <span>
          {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)} { params.row.is_appointment_leave && (`(${params.row.appointment_time})`)}
        </span>
        );
      },
    },
    {
      field: "date_to",
      headerName: "To",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date_to),
      renderCell(params) {
        const orderDate = new Date(params.row.date_to);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)} { params.row.is_appointment_leave && (`(${moment(params.row.appointment_time, "HH:mm:ss").add('2', 'hours').format('HH:mm:ss')})`)}
          </span>
        );
      },
    },    
    
  ];

  const handleEditViewActionPermission = () => {
    if(rtype == 'rm'){
      columns.push({
        field: "leaves_remaining",
        headerName: "Remaining leaves",
        type: 'number',
        flex: 1,
        sortable: true        
      })
    }
    columns.push({
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      valueOptions: LeaveStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        if (params.row.status) {
          const statusName = LeaveStatus.find(
            (item) => item.key == params.row.status
          );
          return (
            <span
              title={statusName?.value}
              className={`py-1 px-2 ms-0 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
            >
              {statusName?.value}
            </span>
          );
        } else {
          return "-";
        }
      },
    })
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.LEAVES
    ) || rtype == 'rm';
   
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.LEAVES
    ) || rtype == 'rm';

    if (
      checkEditAbilityCondition ||
      checkViewAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 0.8,
        headerClassName:"justify-content-between",
        cellClassName: "actions justify-content-between",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if (checkEditAbilityCondition && employeeID != userId) {
            if (row.status == "pending") {
              gridActions.push(
                <GridActionsCellItem
                  label="Reject"
                  title="Reject Leave"
                  onClick={(event) => handleRejectClick(id)}
                  showInMenu={false}
                  className="text-danger"
                  icon={<CancelIcon />}
                />,
                <GridActionsCellItem
                  label="Approve"
                  title="Approve Leave"
                  onClick={(event) => handleApproveClick(id)}
                  showInMenu={false}
                  className="text-primary"
                  icon={<CheckedIcon />}
                />
              );
            }
            else if(row.status == 'approve' || row.status == 'approve_lm'){
              const today = moment();
              const sixtyDaysAgo = moment().subtract(60, 'days');
              // show button for future leave, and status is not pending and leave apply within last 60 days
              if(moment(row.date_applied).isBetween(sixtyDaysAgo, today, undefined, '[]') && moment(row.date_from).isSameOrAfter(today,'day')){
                gridActions.push(
                  <GridActionsCellItem
                    label="Cancel"
                    title="Cancel Leave"
                    onClick={(event) => handleCancelClick(id)}
                    showInMenu={false}
                    className="text-info"
                    icon={<BackspaceIcon />}
                  />
                );
              }
            }
          }
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                onClick={(event) => handleViewClick(id)}
                showInMenu={false}
                className="text-primary"
                icon={<ViewIcon />}
              />
            );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        headerAlign: 'left',
        cellClassName: "actions justify-content-between",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="View"
              onClick={(event) => handleViewClick(id)}
              showInMenu={false}
              className="text-primary"
              icon={<ViewIcon />}
            />,
            // <GridActionsCellItem
            //   label="No Action"
            //   icon={<BlockIcon />}
            //   showInMenu
            //   // You can handle the click event if needed
            //   onClick={() => {}}
            // />,
          ];
        },
      });
    }
  };
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {
            rtype !== 'rm' && (
              <div className="px-0 emp-tab-leave">
                {leaveHistroyData && leaveAvailableData && (
                  <LeaveHistoryData
                    leaveHistroyData={leaveHistroyData}
                    leaveAvailableData={leaveAvailableData}
                  />
                )}
              </div>
            )
          }
          <div className="pageHeader px-0 py-0 my-1">
            <Row className="d-flex justify-content-between align-items-center">
              <Col className="col">
                <h1 className="fw-bold h4 my-2">Leaves</h1>
              </Col>  
              <Col className="text-end">
              <Button
                  className="fw-semibold h-100 fs-12 ms-2 text-white "
                  variant="primary"
                  type="button"
                  onClick={() => handleApplyLeave()}
                >
                  <LogoutIcon className="me-2" />
                  Apply Leave
                </Button>
              </Col>            
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-0 pb-3">
            <Card className=" border-0 w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data.map((row: any, index: number) => ({
                    ...row,
                    serialNumber: index + 1,
                  }))}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  pageSizeOptions={pageSizeModel}
                  sortingMode="server"
                  disableRowSelectionOnClick
                  
                />
              </div>
            </Card>
          </div>         
                
          { isRejectPopupOpen && (<ApproveLeave
            isOpen={isRejectPopupOpen}
            onClose={handleClose}            
            leaveId={deletedId}
            approveType={approveType}
          />) }

          { applyLeavePopupOpen && (<AddEmployeeLeave
            isOpen={applyLeavePopupOpen}
            onClose={handleClose}   
            employeeID={employeeID}        
            
          />) }
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeeLeaves;
