import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR
} from "../../../validations/ValidationErrors";

export const updateWarehouseSchema = yup.object({
  warehouse_name: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_30_CHAR)
    .label("Warehouse Name"),
  location_address: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Location Address"),
  shipping_address: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Shipping Address"),
  user_id: yup.object().required().label("Manager"),
  shelf_number: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_30_CHAR)
    .label("Warehouse Name"),
});
