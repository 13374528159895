import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import FormSelect from "../../../components/common/FormSelect";
import {
  getAllManagerList,
  getAllUsersApiCall,
} from "../../../services/authService";
import CustomButton from "../../../components/common/CustomButton";

import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";

import moment, { Moment } from "moment";
import constants from "../../../constants/constants";
import { getFleetDriverHistoryApi, updateFleetDriverHistoryApi } from "../../../services/fleetService";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import InputDatePicker from "../../../components/common/InputDatePicker";
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import * as yup from "yup";
import { checkAbility } from "../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import DeletePopup from "../../../components/common/deletePopup";

interface IFleetData {
  id?: number;
  asset_no_str?: string;
  model?: string;
  registration?: string;
  driver?: any;
  driverHistory?: any;
}

interface AddProps {
  isOpen: boolean;
  fleetId: number | string | undefined | null;
  onClose: (isReload?:boolean) => void;
}

interface ProjectOption {
  id: string;
  full_name: string;
}

interface FormValues {
  driver_id: any;
  transfer_date: any;
}

const addSchema = yup.object({
  driver_id: yup.object().required().label("Transfer to"),
  transfer_date: yup.date().required().label("Transfer Date"),
});

const FleetDriverHistory: React.FC<AddProps> = ({
  isOpen,
  onClose,
  fleetId,
}) => {
  const [fleetData, setFleetData] = useState<IFleetData>();
  const [exitstingDrivers, setExitstingDrivers] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [confirmLabel, setConfirmLabel] = useState("");
  const [loading, setLoading] = useState(true);

  const initialValues = {
    driver_id: "",
    transfer_date: null,
  };

  const id = fleetId;

  const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      const transformParam = {
        ...params,
        isDriver: true,
      };
      getAllManagerList(transformParam)
        .then((res) => {          
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    const driverExists = exitstingDrivers.find((driver: { driver_id: any; }) => driver.driver_id === values?.driver_id?.id);
    if(driverExists){
      
      setDeletePopupOpen(true);
      setConfirmLabel(`Are you sure driver ${values.driver_id?.name} is already assigned on another fleet (${driverExists.asset_no_str})?`)
      return;
    }
    saveDriverData(values);  
    return 

  };

  const saveDriverData = async(values: FormValues) => {

    setBtnLoading(true);
    const formData = {
      ...values,
      transfer_date: moment(values.transfer_date).format(
        constants.dateFormats.databaseFormat
      ),
    };

    if(id){
     await updateFleetDriverHistoryApi(id,formData).then((response)=>{
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        onClose(true)
  
      }).catch((error)=>{
        setBtnLoading(false);
  
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      })
    }

  }

  const getFleetDriverHistory = async (id: number | string) => {
    setLoading(true);
    await getFleetDriverHistoryApi(id)
      .then((response: any) => {
        setFleetData(response.data);
        setExitstingDrivers(response.data?.allDrivers);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    if (id) {
      getFleetDriverHistory(id);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="driver-history-modal"
          show={isOpen}
          onHide={onClose}
          centered
        >
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative pb-0">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={() => onClose()}
                  ></button>
                  <h4 className="fw-bold info-text mb-4">Driver History</h4>
                  <Row className="g-2">
                    <Col>
                      <label className="control-label fw-bold">Asset Id</label>
                      <div className="fs-14">{fleetData?.asset_no_str}</div>
                    </Col>
                    <Col>
                      <label className="control-label fw-bold">Model</label>
                      <div className="fs-14">{fleetData?.model}</div>
                    </Col>
                    <Col>
                      <label className="control-label fw-bold">
                        Registration
                      </label>
                      <div className="fs-14">{fleetData?.registration}</div>
                    </Col>
                    <Col>
                      <label className="control-label fw-bold">
                        Current Driver
                      </label>
                      <div className="fs-14">{fleetData?.driver?.name ?? "-"}</div>
                    </Col>
                  </Row>
                  <hr className="driver-history-border"/>
                  { checkAbility(
                  PERMISSION_ACCESS.EDIT,
                  PERMISSION_MODULE.FLEET_MANAGEMENT
                ) && (
                  <>
                  <Row className="row g-3">
                    <Col className="col-md-1 text-primary text-center driver">
                      <CircleOutlinedIcon className="fs-13 driver-histroy-circle driver-histroy-circle2"/>
                    </Col>
                    <Col className="col-md-4">
                      <FormAsyncSelect
                        id="driver_id"
                        name="driver_id"
                        label=""
                        isAsync
                        isClearable
                        getOptions={getProjectOwner}
                        value={values.driver_id}
                        error={errors.driver_id}
                        touched={touched.driver_id}
                        onChange={(name: any, value: any) => {
                          setFieldValue(name, value);
                        }}
                      />

                      
                    </Col>
                    <Col className="col-md-4">
                      <InputDatePicker
                        name="transfer_date"
                        label=""
                        slotProps={{
                          textField: {
                            size: "small",
                            placeholder: "Transfer Date",
                            readOnly: true,
                            className: "form-control",
                            id: "transfer_date",
                          },
                        }}
                        value={values.transfer_date}
                        onChange={(name, newValue) =>
                          setFieldValue(name, newValue)
                        }
                        touched={touched.transfer_date}
                        errors={errors.transfer_date}
                      />
                    </Col>
                    <Col className="col-md-3">
                      <CustomButton
                        type="submit"
                        loading={btnLoading}
                        className="fw-semibold fs-13 text-white mw-60 mt-0 h-40"
                        variant="primary"
                      >
                        Submit
                      </CustomButton>
                    </Col>
                  </Row>                    
                    </>
                )}                
                  {
                    fleetData?.driverHistory.map((item: any,index: any) => (
                      <Row key={`driverHistroy_${index}`} className="my-3 gx-3 mt-0">
                        <Col className="col-md-1 text-primary text-center driver-histroy-circle-cover">
                          { item.transfer_to ? (<CircleOutlinedIcon className="fs-13 driver-histroy-circle gray-text" />) : (<CircleIcon className="fs-13 driver-histroy-circle" />)}</Col>
                        <Col className="col-md-4 fw-bold fs-14 text-black">{ item.driver.name}</Col>
                        <Col className="col-md-7 text-black fs-14">{ item.transfer_from} to { item.transfer_to ? item.transfer_to : "Till Now"}</Col>
                      </Row>
                    ))
                  }
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0"></Modal.Footer>
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={() => setDeletePopupOpen(false)}
                  onDelete={() => saveDriverData(values)}
                  actionType="info"
                  label="Are You Sure"
                  text={confirmLabel}
                />
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default FleetDriverHistory;
