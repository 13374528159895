import React, { Fragment, useContext, useEffect, useState } from "react";
import { Formik, getIn } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Accordion from "react-bootstrap/Accordion";
import plusIcon from "../../../assets/images/formbuilderAdd.svg";

import { validationSchema } from "./validation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card, Col, Row } from "react-bootstrap";
 

import {
  addFieldSheetAPICall,
  getFieldsheetApiCall,
  getFieldSheetApiCall,
  updateFieldsheetApiCall,
  updateOrderApiCall,
  generatePDFPreviewApiCall,
} from "../../../services/formbuilder/fieldsheet.services";
import {
  getSectionApiCall,
  deleteSectionApiCall,
  deleteFieldApiCall,
} from "../../../services/formbuilder/section.services";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import "../formbuilder.scss";
import ArrowRightField from "../../../assets/images/arrow-right-field.svg";
import { toast } from "react-toastify";
import AddSection from "../Section";
import AddSubSection from "../Section/subSection";
import moveIcon from "../../../assets/images/feather-move.svg";
import infoIcon from "../../../assets/images/awesome-info-circle.svg";
import editIcon from "../../../assets/images/formbuilderedit.svg";
import deleteIcon from "../../../assets/images/formbuilderdelete.svg";
import addIcon from "../../../assets/images/formbuilderplus.svg";
import backIcon from "../../../assets/images/formbuilderopen.svg";
import { AccordionContext, Spinner } from "react-bootstrap";
// import AddField from "../Field";

import InputText from "../../../components/common/InputText";
import InputTextArea from "../../../components/common/InputTextArea";
import Loader from "../../../components/common/Loader";
import SubsectionContent from "./SubsectionContent";
import { FORMBUILDER_FINISH } from "../../../validations/ValidationErrors";
import DeletePopup from "../../../components/common/deletePopup";
import Condition from "../Condition";

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;
  let activeClass;
  if (isCurrentEventKey) {
    activeClass = "active";
  } else {
    activeClass = "inactive";
  }
  return (
    <button
      type="button"
      className={`accodion-arrow ` + activeClass}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
};

const Content = React.memo(function Content(props) {
  const [loadForm, setLoadForm] = useState(true);
  const [firstStep, setFirstStep] = useState(true);
  const [secondStep, setSecondStep] = useState(false);
  const [fieldSheetData, setFieldSheetData] = useState(null);
  const [initialSave, setInitialSave] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [sectionData, setSectionData] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [showSubSection, setShowSubSection] = useState(null);
  const [subSectionId, setSubSectionId] = useState(null)

  const [isView, setIsView] = useState(false);
  const LimitField = 500;
  const [loading, setLoading] = useState(true);
  const [fieldList, setFieldList] = useState([]);
  const [nextLoading, setNextLoading] = useState(false);
  const [secontStepLoading, setSecontStepLoading] = useState(false);
  const [sectionLength, setSectionLength] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [pdfPreivewLoader, setPdfPreivewLoader] = useState(false);
  const [isMutation, setIsMutation] = useState(false)
  const [sectionOrderLength,setSectionOrderLength] = useState(0)
  const [subSectionOrderLength,setSubSectionOrderLength] = useState(0)
  const [deleteSectionPopup,setDeleteSectionPopup] = useState(false);
  const [deleteSectionId,setDeleteSectionId] = useState(0)
  const [deleteSubSectionId,setDeleteSubSectionId] = useState(0)
  const [conditionStep, setConditionStep] = useState(false);
  const fieldSheetCompleted = "";

  let navigate = useNavigate();
  let { id } = useParams();
  const [fid, setId] = useState(id);

  const handleModalClose = () => {
    setShowSection(false);
  
  };

  const handleSubSectionClose = () => {
    setShowSubSection(false);
  };


  const enableConditionStep =() =>{
    setConditionStep(true)
    setSecondStep(false)  
  }

  const handleModelShow = () => {
    setSectionId(null);
    setShowSection(true);
  };
  const handleEditModel = (id) => {
    setSectionId(id);
    setShowSection(true);
  };

  const handleEditSubModel = (id,sectionId,subOrderLength) => {
    setSubSectionId(id);
    setSectionId(sectionId)
    setShowSubSection(true);
    if(subOrderLength==0){
      setSubSectionOrderLength(1)
    }else{
      setSubSectionOrderLength(subOrderLength)

    }
  };


  const addSubSectioinModel = (sectionid, orderLength) => {
    setSectionId(sectionid);
    setSubSectionId(null)
    setShowSubSection(true);
    if(orderLength==0){
      setSubSectionOrderLength(1)
    }else{
      setSubSectionOrderLength(orderLength+1)

    }
  };

 

  const checkFieldHasCondition = (fieldcondition,id) => {
    const hasCondition = fieldcondition.some(entry => {
      return (
        entry.conditions.some(condition => condition.field.section_id === id) ||
        entry.actions.some(action => action.field.section_id === id)
      );
  });
    return hasCondition;
  }


  const handleDelete = (id) => {

 let  hasCondition = false;
    if(fieldSheetData.fieldConditions && fieldSheetData.fieldConditions.field_conditions && fieldSheetData.fieldConditions.field_conditions.length > 0){
      hasCondition = checkFieldHasCondition(fieldSheetData.fieldConditions.field_conditions,id);
    }

    if(hasCondition){
      toast.error("Section has condition, Please delete the condition first")
      return false;
    }
    setDeleteSectionPopup(true);
    setDeleteSectionId(id)
  
  };

  const handleDeleteSection = () =>{
    
    deleteSectionApiCall(deleteSectionId).then((response) => {
      toast.success(response.msg)
      setIsMutation(!isMutation)
      setDeleteSectionPopup(false)
    });

  }
   useEffect(() => {
    if (fid) {
      getFieldsheetApiCall(fid).then((response) => {
        setFieldSheetData(response.data);
        setSectionOrderLength(response.data.length == 0 ? "1" : response.data.length + 1);

        setLoadForm(false);
      });
    } else {
      setLoadForm(false);
    }
  }, []);

  const fetchSection = (fid) => {
    setSecontStepLoading(true);
    getSectionApiCall(fid).then((response) => {
      if (response.data.length) {
        setSectionData(response.data);
        setSectionLength(response.data.length);

      }else{
        setSectionData([])
      }
      setSectionOrderLength(response.data.length == 0 ? "1" : response.data.length + 1);


      setSecontStepLoading(false);
    });
  };

  useEffect(() => {
    if (fid) {
      fetchSection(fid);
    }
  }, [firstStep, showSection, showSubSection, secondStep, fid,isMutation]);

  const handleBack = () => {
    setSecondStep(false);
    setFirstStep(true);
  };

  const handleBackToField = () => {
    setSecondStep(true);
    setFirstStep(false);
    setConditionStep(false)
  };

  const handleFinish = () => {
    toast.success(FORMBUILDER_FINISH);
    navigate("/formbuilder");
  };
  const handleSubmit = (values, actions) => {
    setNextLoading(true);
    const formdata = {
      ...values,
      duplicate_id: values?.duplicate_id?.value,
    };

    if (fid) {
      updateFieldsheetApiCall(fid, formdata)
        .then((response) => {
          setNextLoading(false);
          toast.success(response.data.message);
          setFirstStep(false);
          setSecondStep(true);
        })
        .catch((error) => {
          setNextLoading(false);
          toast.error(error.response.data.message);
        });
    } else if (initialSave == true) {
      setFirstStep(false);
      setSecondStep(true);
    } else {
      addFieldSheetAPICall(formdata)
        .then((response) => {
          //navigate(adminRoutes.manageFaq.path);
          setFieldSheetData(response.data.data);
          setId(response.data.data.id);
          toast.success(response.data.message);
          setFirstStep(false);
          setSecondStep(true);
          setInitialSave(true);
          setNextLoading(false);
        })
        .catch((error) => {
          setNextLoading(false);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleChangeNew = async (value) => {
    if (value == "duplicate") {
      await fetchSheet();
    } else {
      setFieldList([]);
    }
  };
  const fetchSheet = async () => {
    setLoading(true);
    let params = {
      page: 1,
      limit: LimitField,
    };
    getFieldSheetApiCall(params).then((response) => {
      let fieldData = [];
      if (response.data.fieldResult.length > 0) {
        response.data.fieldResult.map((val) => {
          fieldData.push({
            key: val.field_sheet_title + `  (${val.field_sheet_number})`,
            value: val.sheet_id,
          });
        });
      }

      setFieldList(fieldData);

      setLoading(false);
    });
  };

  const handleDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    let updatedOrderId = [];
    const newItems = [...sectionData];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    const updateItemCount = Array.from(newItems);
    const updatedItems = updateItemCount.map((item, index) => {
      updatedOrderId.push(item.id);
      return {
        ...item,
        order: index + 1,
      };
    });

    setSectionData(updatedItems);
    updateOrderApiCall(fid, updatedOrderId);
  };

  const handleDragStart = (e) => {
    setSelectedId(e.draggableId);
    e.source.classList.add("dragging");
  };
  const handleCloseSectionDelete = () => {
    setDeleteSectionPopup(false);
    setDeleteSectionId(null);
  };


  const handleBackToFirstStep = () => {
    if(secondStep){
      setSecondStep(false);
      setFirstStep(true);
    }
    if(conditionStep){
      setConditionStep(false);
      setSecondStep(true);
    } 
  }

  const handleBackToSecondStep = () => {
    if(conditionStep){
      setConditionStep(false);
      setSecondStep(true);
    }
  }


  return (
    <div className="content-wrapper ">
      {/* Content Header (Page header) */}
      {deleteSectionPopup &&  <DeletePopup
                isOpen={deleteSectionPopup}
                onClose={handleCloseSectionDelete}
                onDelete={handleDeleteSection}
                label="Delete Section"
                text="Are you sure you want to delete this Section?"
              />
              }



      {secondStep && showSection && (
        <AddSection
          showSection={showSection}
          handleModalClose={handleModalClose}
          field_sheet_id={fid ? fid : fieldSheetData.id}
          sectionId={sectionId ? sectionId : null}
          sectionOrder={sectionOrderLength}
        />
      )}

      {secondStep && showSubSection && (
        <AddSubSection
          showSection={showSubSection}
          handleModalClose={handleSubSectionClose}
          field_sheet_id={fid ? fid : fieldSheetData.id}
          sectionId={sectionId ? sectionId : null}
          subSectionId={subSectionId ? subSectionId : null}
          sectionOrder={subSectionOrderLength}
          
        />
      )}

     

      <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
        <div className="pageHeader px-3 py-2 my-1">
          <Row className="align-items-center">
            <Col className="col-md-4">
              <h1 className="fw-bold h4 my-2">{props.name}</h1>
            </Col>
            <Col className="text-end col-md-8">
              <a
                href="javascript:void(0)"
                className={`form-builder-link ${firstStep && `active`} ${
                   (secondStep || conditionStep )  && `previous`
                }`}
                onClick={handleBackToFirstStep}
              >
                Basic Details
                {(secondStep || conditionStep) && (
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06634 10.0666L10.7663 5.36659L9.83301 4.43325L6.06634 8.19992L4.16634 6.29992L3.23301 7.23325L6.06634 10.0666ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4916 4.39967 13.1416C3.58856 12.7916 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20801 10.411 0.858008 9.59992C0.508008 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508008 5.21103 0.858008 4.39992C1.20801 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20825 4.39967 0.858252C5.21079 0.508252 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508252 9.59967 0.858252C10.4108 1.20825 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7913 3.58881 13.1413 4.39992C13.4913 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4913 8.78881 13.1413 9.59992C12.7913 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7916 9.59967 13.1416C8.78856 13.4916 7.9219 13.6666 6.99967 13.6666Z"
                      fill="#21AC59"
                    />
                  </svg>
                )}
              </a>
              <a
                href="javascript:void(0)"
                className={`form-builder-link ${secondStep && `active`} ${
                  conditionStep && `previous`
               }`}
               onClick={handleBackToSecondStep}
              >
                Edit Form
                {conditionStep && (
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06634 10.0666L10.7663 5.36659L9.83301 4.43325L6.06634 8.19992L4.16634 6.29992L3.23301 7.23325L6.06634 10.0666ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4916 4.39967 13.1416C3.58856 12.7916 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20801 10.411 0.858008 9.59992C0.508008 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508008 5.21103 0.858008 4.39992C1.20801 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20825 4.39967 0.858252C5.21079 0.508252 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508252 9.59967 0.858252C10.4108 1.20825 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7913 3.58881 13.1413 4.39992C13.4913 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4913 8.78881 13.1413 9.59992C12.7913 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7916 9.59967 13.1416C8.78856 13.4916 7.9219 13.6666 6.99967 13.6666Z"
                      fill="#21AC59"
                    />
                  </svg>
                )}
              </a>
              <a
                href="javascript:void(0)"
                className={`form-builder-link ${conditionStep && `active`} `}
              >
               field Condition
              {conditionStep && (
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06634 10.0666L10.7663 5.36659L9.83301 4.43325L6.06634 8.19992L4.16634 6.29992L3.23301 7.23325L6.06634 10.0666ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4916 4.39967 13.1416C3.58856 12.7916 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20801 10.411 0.858008 9.59992C0.508008 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508008 5.21103 0.858008 4.39992C1.20801 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20825 4.39967 0.858252C5.21079 0.508252 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508252 9.59967 0.858252C10.4108 1.20825 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7913 3.58881 13.1413 4.39992C13.4913 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4913 8.78881 13.1413 9.59992C12.7913 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7916 9.59967 13.1416C8.78856 13.4916 7.9219 13.6666 6.99967 13.6666Z"
                      fill="#21AC59"
                    />
                  </svg>
                )}
              </a>
            </Col>
          </Row>

          {/* /.col */}

          {/* /.row */}

          {/* /.container-fluid */}
        </div>
      </div>
      {/* /.content-header */}
      {/* Main content */}
      {loadForm ? (
        <Loader isLoading={true} />
      ) : (
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            title: fieldSheetData ? fieldSheetData.field_sheet_title : "",
            description: fieldSheetData ? fieldSheetData.description : "",

          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            status,
          }) => (
            <div className=" flex-grow-1 overflow-auto pageContent px-3 pb-3 main-wraper-formbuilder">
             
             {firstStep && (
                  
                  <div id="firstStep">
                     <div className="card innerCard p-0 mb-3  border-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-11">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group mb-0">
                                <div className="row">
                                  <div className="col-md-8">
                                    <InputText
                                      controlId="title"
                                      label="Form Title "
                                      placeholder="Enter Form  Title"
                                      touched={touched.title}
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      errorsField={errors.title}
                                      value={values.title}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-10">
                                    <InputTextArea
                                      controlId="description"
                                      label="Description"
                                      placeholder="Enter Description"
                                      touched={touched.description}
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      errorsField={errors.description}
                                      name="description"
                                      value={values.description}
                                      className={`form-control resize-none ${
                                        errors.description &&
                                        touched.description
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    {/* {touched.description &&
                                      errors.description && (
                                        <p className="fs-11 text-danger pt-1 mb-0">
                                          {errors.description}
                                        </p>
                                      )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
             )}
              

             {secondStep && (
                 <div>
                   <div className="card innerCard p-0 mb-3  border-0">
                  <div className="card-body">
                    <Row className="align-items-center border-left-5">
                  <div className="col-md-6">
                            <div className="">
                           <label className="control-label fs-14"> Form Title</label>
                              <div className="fs-20 fw-bold info-text">
                                {values.title}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 text-end">
                          <button
                                  className="createSectionBtn bg-transparent fs-14 fw-bold border-0"
                                  onClick={handleModelShow}
                                >
                                  <img src={plusIcon} className="plus-icon me-2 v-text-bottom" />
                                 Add Section
                                </button>
                          </div>
                          </Row>

                    </div></div>
             
                    {secontStepLoading ? (
                      <>
                        {" "}
                        <div className="align-items-center d-flex justify-content-center">
                          <Spinner size={"xl"} />
                        </div>
                      </>
                    ) : (
                      <div id="secondStep">
                       
                       
                        <div id="order">
                          {sectionData && sectionData.length > 0 ? (
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <Droppable droppableId="list">
                                {(provided, snapshot) => (
                                  <Accordion
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ minHeight: "250px" }}
                                    allowMultiple={false}
                                  >
                                    {sectionData &&
                                      sectionData.length &&
                                      sectionData.map((val, key) => {
                                        return (
                                          <Draggable
                                            key={val.id}
                                            draggableId={val.id.toString()}
                                            index={key}
                                          >
                                            {(provided) => (
                                              <>
                                              
                                                <Card
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                                  onClick={() =>
                                                    setSelectedId(id)
                                                  }
                                                  className={` field-card item ${
                                                    snapshot.isDragging
                                                      ? "dragging"
                                                      : ""
                                                  } ${
                                                    selectedId === id
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                  style={{
                                                    zIndex: "1",
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                >
                                                  <Card.Header>
                                                    <div className="header-items d-flex justify-content-between align-items-center">
                                                      <div className="firstPart fs-18 fw-semibold d-flex">
                                                      <div className="me-2">
                                                      <img src={moveIcon} />{" "}
                                                      </div>
                                                      <div>
                                                        {val.title} &nbsp;
                                                        {val.sub_title && (
                                                         <p className="mb-0 fs-14 text-gray text-light">({val.sub_title})</p>
                                                        )}

                                                       </div>
                                                      </div>
                                                      <div className="otherpart">
                                                        <div className="section-action d-flex align-items-center">
                                                          {!val.is_testing && (
                                                            <ul className="m-0">
                                                              <li
                                                                onClick={() =>
                                                                  addSubSectioinModel(
                                                                    val.id,
                                                                    val.subsections.length
                                                                  )
                                                                }
                                                                title="Add sub section"
                                                              >
                                                                <img
                                                                  src={addIcon}
                                                                />
                                                              </li>
                                                              <li
                                                                className="cursor-pointer"
                                                                onClick={() =>
                                                                  handleEditModel(
                                                                    val.id
                                                                  )
                                                                }
                                                                title="Edit section"
                                                              >
                                                                <img
                                                                  src={editIcon}
                                                                />
                                                              </li>
                                                              <li
                                                                className="cursor-pointer"
                                                                onClick={() =>
                                                                  handleDelete(
                                                                    val.id
                                                                  )
                                                                }
                                                                title="Delete section"
                                                              >
                                                                <img
                                                                  src={
                                                                    deleteIcon
                                                                  }
                                                                />
                                                              </li>
                                                            </ul>
                                                          )}

                                                          <ContextAwareToggle
                                                            eventKey={key}
                                                          >
                                                            <img
                                                              src={backIcon}
                                                            
                                                            />
                                                          </ContextAwareToggle>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Card.Header>
                                                  <SubsectionContent
                                                    keyVal={key}
                                                    val={val}
                                                    handleEditSubModel={
                                                      handleEditSubModel
                                                    }
                                                    conditionData={fieldSheetData.fieldConditions}
                                                    isMutation={isMutation}
                                                    setIsMutation={setIsMutation}
                                                  />
                                                </Card>
                                               
                                              </>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                    {provided.placeholder}
                                  </Accordion>
                                )}
                              </Droppable>
                            </DragDropContext>
                          ) : (
                            <div className="text-center">
                              No section Available
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                 


                 </div>
             )}
              
              {firstStep && (
                <div className="row mt-2 text-end">
                  <div className=" col-md-12 mb-5 pt-3">
                    {nextLoading ? (
                      <button
                        type="submit"
                        disabled={true}
                        className="fw-semibold fs-13 text-white mw-90 mt-2 btn btn-primary"
                      >
                        <div className="align-item-center d-flex justify-content-center">
                          <Spinner size={"sm"} />
                        </div>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="fw-semibold fs-13 text-white mw-90 mt-2 btn btn-primary"
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              )}

              {secondStep && (
                <div className="row mt-2 text-end">
                  <div className="col-md-12 mb-5 pt-3 d-flex justify-content-between">
                    <button
                      type="submit"
                      onClick={handleBack}
                      className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info "
                    >
                      Back
                    </button>
                    <button  type="submit"
                      onClick={enableConditionStep}
                      className="fw-semibold fs-13 text-white mw-90 mt-2 btn btn-primary ">
                      Next
                    </button>
                  
                  </div>
                </div>
              )}

              {conditionStep && (
                <Condition   
                        fieldsheetId={fid ? fid : fieldSheetData.id}
                        fieldConditionsData={fieldSheetData.fieldConditions}
                        handleBackToField={handleBackToField}
                />
              )}

          {/* {conditionStep && (
               <div className="row mt-2 text-end">
               <div className="col-md-12 mb-5 pt-3 d-flex justify-content-between">
                 <button
                   type="submit"
                   onClick={handleBackToField}
                   className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info "
                 >
                   Back
                 </button>
              
               </div>
             </div>
              )} */}

            </div>
          )}
        </Formik>
      )}

      {/* /.content */}
    </div>
  );
});
export default Content;

