import { Route, Routes } from "react-router-dom";
import AddRoles from "./Add";
import ViewRole from "./View/index";
import RoleList from "./index";

export const Roles = () => {
  return (
    <Routes>
      <Route path="/" element={<RoleList />} />
      <Route path="/add-role" element={<AddRoles />} />
      <Route path="/edit-role/:id" element={<AddRoles />} />
      <Route path="/view-role/:id" element={<ViewRole />} />
    </Routes>
  );
};
