import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DescriptionIcon from '@mui/icons-material/Description';
import ContactComplicatd from "../../../assets/images/contact-complicatd-new.svg";
import Loader from "../../../components/common/Loader";
import { getInstallationMediaImagesApi } from "../../../services/installation.service";
import { Link } from "react-router-dom";
import ImageIcon from '@mui/icons-material/Image';
import { getLocalDateFormat } from "../../../utility/common";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LightboxGallery from "../../../components/common/LightboxGallery";
interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string | number | undefined;
}

interface ImagePreview {
  signedUrl: string;
  name: string;
  size: number;
}

const ProjectInstallMediaFilesPopup: React.FC<AddProps> = ({
  isOpen,
  onClose,
  projectId
}) => {
  const [loading, setLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState<ImagePreview[]>([]);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesSet, setImagesSet] = useState<any>([]);

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    setLoading(true);
    getInstallationMediaImagesApi(projectId).then((response) => {
        setImagePreview(response.data);
        setLoading(false);
        }
    ).catch(() => {
        setLoading(false);
    }).finally(() => {
        setLoading(false);
    });
  };

  useEffect(() => {
    transformImage(imagePreview);
  }, [imagePreview]);

  const transformImage = (images: any[]) => {
    if (images.length > 0) {
      const updatedImages = images.map(
        (image: { signedUrl: any; src: any; file_name: any }) => {
          const extension: any = image.file_name
            .split(".")
            .pop()
            ?.toLowerCase();
          let img: any = {};
          if (["png", "jpeg", "jpg", "svg"].includes(extension)) {
            img.src = image.signedUrl;
            return img;
          }
        }
      ).filter(img => img !== undefined);      
      setImagesSet(updatedImages);
    }
  };


  const getExtension = (fileObj: any) => {
    const extension:any = fileObj.file_name.split('.').pop()?.toLowerCase();  
    if (extension === 'pdf') {
      return (<Link to={fileObj?.signedUrl} target="_blank" className=" w-100"><img
      src={ContactComplicatd}
      alt="Icon"
      className="w-100"
    /></Link>)
    } else if (['png', 'jpeg', 'jpg', 'svg'].includes(extension)) {
      return (<><img
      src={fileObj?.signedUrl}
      alt="Logo"
      className="img-fluid"
      style={{ width: "100px", height: "100px" }}
      onClick={() => openLightbox(fileObj)}
    />
    <span className="btn btn-green">
      <ImageIcon className="text-white fs-10" />
    </span></>)
    } else {
      return (<Link to={fileObj?.signedUrl} target="_blank" className="p-2" ><DescriptionIcon className="rounded-1 blue-text w-100 h-100" /></Link>);
    }
  };

  const openLightbox = (fileObj: any) => {
    const index = imagesSet.findIndex((image: { src: any; }) => image.src === fileObj.signedUrl);
    setCurrentIndex(index);
    setLightboxIsOpen(true);
  };
  
  const handleClose = () => {
    setLightboxIsOpen(false);
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="export-excel-modal"
          aria-labelledby="Additional Files"
          centered
          show={isOpen}
          onHide={onClose}
        >
          <Modal.Body className="position-relative project-assign-modal pb-0">
            <button
              type="button"
              className="btn-close text-right  btn-option-cover"
              aria-label="Close"
              onClick={onClose}
            ></button>
            <h4 className="fw-bold info-text mb-3">Additional Files</h4>
            <div className="position-relative">
              <div className="d-flex flex-wrap  mt-0 row">
                  {imagePreview.length > 0 ? (
                    imagePreview.map((image: any, index: number) => (
                      <div key={index} className="d-flex col-md-3 flex-wrap align-content-start mb-3">
                        <div className="media-upload-img">{getExtension(image)}</div>
                        <h5 className="fs-14 fw-bold mb-2">
                          {image.file_name.substring(
                            image.file_name.lastIndexOf("/") + 1
                          )}
                        </h5>
                        <p className="fs-14 gray-text mb-0">
                          <CalendarTodayIcon className="me-1 fs-13" />
                          {getLocalDateFormat(image.created_at)}
                        </p>
                      </div>
                    ))
                  ) : (
                    <Row>
                      <Col>
                        <span className="mt-2 mb-2">No Uploaded Media found</span>
                      </Col>
                    </Row>
                  )}
              </div>
            </div>
            <LightboxGallery images={imagesSet} currentIndex={currentIndex} open={lightboxIsOpen} onClose={handleClose} />
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center mb-3 pt-0">
            {" "}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default ProjectInstallMediaFilesPopup;
