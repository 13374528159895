import React from "react";
import { Card, Row, Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import moment from "moment";

import constants from "../../constants/constants";

interface props {
  medicalData: any;
  signatureUrl: string | null;
}

const ViewMedical: React.FC<props> = ({ medicalData, signatureUrl }) => {
  return (
    <>
      
      <Card className="p-3 w-100 pageContentInner mb-2">
        <h3 className="fs-20 fw-bold info-text mb-2">Section 1</h3>
        <p className="mb-3">Please fill in this form as accurately as possible</p>
        <Row>
          <div className="col-md-12">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Post Offered
              </label>
              <div className="position-relative">
                <label>{medicalData.post_offered}</label>
              </div>
            </Form.Group>
          </div>
        </Row>
        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Surname
              </label>
              <div className="position-relative">
                <label>{medicalData.surname}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Forename(S)
              </label>
              <div className="position-relative">
                <label>{medicalData.forename}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Telephone
              </label>
              <div className="position-relative">
                <label>{medicalData.telephone}</label>
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Mobile
              </label>
              <div className="position-relative">
                <label>{medicalData.mobile}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-8">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Address
              </label>
              <div className="position-relative">
                <label>{medicalData.address}</label>
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Name of GP
              </label>
              <div className="position-relative">
                <label>{medicalData.gp_name}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-8">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Address of GP
              </label>
              <div className="position-relative">
                <label>{medicalData.gp_address}</label>
              </div>
            </Form.Group>
          </div>
        </Row>
      </Card>

      
      <Card className="p-3 w-100 pageContentInner mb-2">
        <h3 className="fs-20 fw-bold info-text mb-3">Section 2</h3>
        <div className="form-hrmc-statment medical-form-first">
          <Row>
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Has your employment ever been terminated on the grounds of ill
                health?
              </label>
              <div className="position-relative">
                <label>
                  {medicalData.health_questions.employment_ever_terminated === "1"
                    ? "Yes"
                    : "No"}
                </label>
              </div>
            </Form.Group>
          </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              Approximately how many days/weeks sickness/absence have you had:
            </label>
            <div className="position-relative">
              <label>
                In the last twelve months:{" "}
                {medicalData.health_questions.sickness_duration_last_12_months}
              </label>
              <label>
                In the last twelve months prior to that:{" "}
                {
                  medicalData.health_questions
                    .sickness_duration_prior_to_last_12_months
                }
              </label>
            </div>
          </Form.Group>
        </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              How many units of alcohol do you consume weekly?
            </label>
            <div className="position-relative">
              <label>{medicalData.health_questions.alcohol_units}</label>
            </div>
          </Form.Group>
        </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              Do you smoke?
            </label>
            <div className="position-relative">
              <label>
                {medicalData.health_questions.smoke === "1" ? "Yes" : "No"}
              </label>
            </div>
          </Form.Group>
        </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">

        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              Are you currently taking prescribed medicine?
            </label>
            <div className="position-relative">
              <label>
                {medicalData.health_questions.medicine === "1" ? "Yes" : "No"}
              </label>
            </div>
          </Form.Group>
        </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              Are you currently under the care of a doctor or other medical
              professional?
            </label>
            <div className="position-relative">
              <label>
                {medicalData.health_questions.under_care === "1" ? "Yes" : "No"}
              </label>
            </div>
          </Form.Group>
        </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              When did you last consult your GP and why?
            </label>
            <div className="position-relative">
              <label>{medicalData.health_questions.last_consult_gp}</label>
            </div>
          </Form.Group>
        </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">
        <Row>
          <Form.Group className="mb-3 custom-from-group flex-wrap">
            <label className="lh-sm control-label fs-14 fw-bold">
              Are you currently suffering from or have suffered from any of the
              illnesses listed below:
            </label>
            <div className="medical-form-full">
            <div className="position-relative">
              {medicalData.health_questions?.illness &&
                medicalData.health_questions?.illness.length > 0 &&
                medicalData.health_questions?.illness.map((element: string) => {
                  return (
                    <div className={`mb-3`}>
                      <label>{element}</label>
                    </div>
                  );
                })}
              {medicalData.health_questions?.illness.length === 0 && "-"}
            </div>
            </div>
          </Form.Group>
        </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              Please give details of above checked illness (if checked)
            </label>
            <div className="position-relative">
              <label>
                {medicalData.health_questions?.details_of_illness &&
                medicalData.health_questions?.details_of_illness !== ""
                  ? medicalData.health_questions?.details_of_illness
                  : "-"}
              </label>
            </div>
          </Form.Group>
        </Row>
        </div>
        <div className="form-hrmc-statment medical-form-first">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              Do you suffer from any disabilities, which may be relevant to your
              employment? If so please identify:
            </label>
            <div className="position-relative">
              <label>
                {medicalData.health_questions?.disabilities &&
                medicalData.health_questions?.disabilities !== ""
                  ? medicalData.health_questions?.disabilities
                  : "-"}
              </label>
            </div>
          </Form.Group>
        </Row>
        </div>
      </Card>

     
      <Card className="p-3 w-100 pageContentInner mb-2">
        <h3 className="fs-20 fw-bold info-text mb-3">Declaration</h3>
        <p className="fs-14 control-label">I hereby declare that the information given is full and true to the best of my knowledge. I understand that if, at a later date, it is discovered that I have knowingly withheld medical information, disciplinary action may be taken against me, which may include dismissal.</p>
        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                FULL NAME
              </label>
              <div className="position-relative">
                <label>{medicalData.declaration_full_name}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">Date</label>
              <div className="position-relative">
                <label>
                  {moment(medicalData.declaration_date).format(
                    constants.dateFormats.slashSeparatedDate
                  )}
                </label>
              </div>
            </Form.Group>
          </div>
        </Row>
        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Signature
              </label>
              {signatureUrl && (
                <Image className="img-fluid" src={signatureUrl} alt="Logo" />
              )}
            </Form.Group>
          </div>
        </Row>
      </Card>
    </>
  );
};

export default ViewMedical;
