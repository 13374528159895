import React from "react";
import { Image } from "react-bootstrap";
import AddPhotoAlternate from "../../assets/images/add_photo_alternate.svg";

import ProfileEditIcon from "../../assets/images/profile-edit-icon.svg";
import { Spinner } from "react-bootstrap";
import { MdOutlineCloudUpload } from "react-icons/md";

interface ImageUploadProps {
  imageLoading: boolean;
  imagePreview?: any;
  label?: string;
  acceptFormat?: string;
  uploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MultiFileUploader: React.FC<ImageUploadProps> = ({
  imageLoading,
  imagePreview,
  uploadImage,
  label,
  acceptFormat = "image/png, image/gif, image/jpeg",
}) => {
  return (
    <>
      {label && (
        <label className="lh-sm control-label fs-14 fw-bold form-label">
          {label}
        </label>
      )}
      <div className="position-relative">
        {imageLoading ? (
          <label className="file-box multifile-upload">
            <span>
              <Spinner />
            </span>
          </label>
        ) :(
          <label className="file-box multifile-upload">
            <input
              type="file"
              name="image"
              onChange={uploadImage}
              accept={acceptFormat}
              multiple
            />
            <span className="d-flex">
              {" "}
              <div>
                <div  className="fs-28 text-center">
                  <svg width="42" height="29" viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.9163 28.2412C8.13579 28.2412 5.76009 27.319 3.78926 25.4746C1.81842 23.6303 0.833008 21.3761 0.833008 18.712C0.833008 16.4285 1.55106 14.3939 2.98717 12.6081C4.42329 10.8223 6.30245 9.68052 8.62467 9.18283C9.38856 6.48949 10.9163 4.30847 13.208 2.63977C15.4997 0.971069 18.0969 0.136719 20.9997 0.136719C24.5747 0.136719 27.6073 1.32969 30.0976 3.71564C32.5879 6.10159 33.833 9.00718 33.833 12.4324C35.9413 12.6666 37.6906 13.5376 39.0809 15.0452C40.4712 16.5529 41.1663 18.3168 41.1663 20.3368C41.1663 22.5324 40.3643 24.3987 38.7601 25.9357C37.1559 27.4727 35.208 28.2412 32.9163 28.2412H22.833C21.8247 28.2412 20.9615 27.8972 20.2434 27.2092C19.5254 26.5212 19.1663 25.6942 19.1663 24.7281V15.682L16.233 18.4046L13.6663 15.9455L20.9997 8.91935L28.333 15.9455L25.7663 18.4046L22.833 15.682V24.7281H32.9163C34.1997 24.7281 35.2844 24.3036 36.1705 23.4546C37.0566 22.6056 37.4997 21.5663 37.4997 20.3368C37.4997 19.1072 37.0566 18.0679 36.1705 17.2189C35.2844 16.37 34.1997 15.9455 32.9163 15.9455H30.1663V12.4324C30.1663 10.0025 29.2726 7.93131 27.4851 6.21869C25.6976 4.50608 23.5358 3.64977 20.9997 3.64977C18.4636 3.64977 16.3018 4.50608 14.5143 6.21869C12.7268 7.93131 11.833 10.0025 11.833 12.4324H10.9163C9.14412 12.4324 7.63162 13.0326 6.37884 14.2328C5.12606 15.4331 4.49967 16.8823 4.49967 18.5803C4.49967 20.2782 5.12606 21.7274 6.37884 22.9277C7.63162 24.128 9.14412 24.7281 10.9163 24.7281H15.4997V28.2412H10.9163Z" fill="#A3A3A3"/>
                  </svg>
                </div>
                <p>Upload Files</p>
              </div>
            </span>
          </label>
        )}
      </div>
    </>
  );
};

export default MultiFileUploader;
