// authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { titleRoles } from "../../constants/constants";

interface User {
  id: number;
  username: string;
  access_token: string;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  selectedTile?: string;
}

interface RefreshToken extends User {
  refreshToken: string;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  loading: true,
  selectedTile: localStorage.getItem("selectedTile") || titleRoles.A,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    login: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.user.access_token = action.payload.access_token;
      state.isAuthenticated = true;
      state.loading = false;
    },
    loginFailure: (state) => {
      state.loading = false;
    },
    refreshTokenRequest: (state) => {
      state.loading = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.loading = false;
    },
    refreshToken: (state, action: PayloadAction<RefreshToken>) => {
      state.user = action.payload;
      state.user.access_token = action.payload.access_token;
      state.isAuthenticated = true;
      state.loading = false;
    },
    refreshTokenFailure: (state) => {
      state.loading = false;
    },
    selectedTile: (state, action: PayloadAction<string>) => {
      console.log("action.payload", action);
      state.selectedTile = action.payload;
    }
  },
});

export const {
  loginRequest,
  login,
  loginFailure,
  logout,
  refreshTokenRequest,
  refreshToken,
  refreshTokenFailure,
  selectedTile
} = authSlice.actions;
export const selectAuthentication = (state: RootState) => state.auth;

export default authSlice.reducer;
