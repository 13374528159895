import React from "react";
import { FC } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import moreIcon from "../../../assets/images/more_horiz.svg";
import InputText from "../../../components/common/InputText";
import { ITypeProps } from "./surveyInterface";
import { SentimentVerySatisfiedOutlined } from "@mui/icons-material";

const CommonNotes: FC<ITypeProps> = ({
  field,
  index,
  fieldIndex,
  subIndex,
  templateData,
  setTemplateData,
  errors,
  values,
  setFieldValue,
  handleBlur,
  touched,
}) => {
  const addNotesToField = (
    e: any,
    index: number,
    subIndex: number,
    fieldIndex: number
  ) => {
    let value = e.target.value.trim();
    
    if(value){

    
    let fieldSheetTextTemp = [...templateData];
    fieldSheetTextTemp[index].subsections[subIndex].fields[fieldIndex].notes =
      e.target.value;
    setTemplateData(fieldSheetTextTemp);
    }else{
      let fieldSheetTextTemp = [...templateData];
      fieldSheetTextTemp[index].subsections[subIndex].fields[fieldIndex].notes =
       '';
      setTemplateData(fieldSheetTextTemp);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle className="survery-menu-icon " id="dropdown-basic">
        <img src={moreIcon} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="p-3 surveyNotes">
          <InputText
            controlId={`${field.name}_notes`}
            label="Notes"
            placeholder="Enter Notes"
            handleBlur={handleBlur}
            handleChange={(e: any) =>
              addNotesToField(e, index, subIndex, fieldIndex)
            }
            value={field.notes}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CommonNotes;
