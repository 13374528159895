import * as Yup from "yup";
import {
  ALPHABETS_ALLOW,
  ALPHA_NUMERIC_ALLOW,
  MAXIMUM_100_CHAR,
  MAXIMUM_200_CHAR,
  MAXIMUM_255_CHAR,
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  ONLY_NUMBER_ALLOW,
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE, ONLY_ALPHABET } from "../../../validations/regex";

const dataSetDropdownValidation = Yup.object({
  label: Yup.string()
    .trim()
    .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
    .required()
    .max(30, MAXIMUM_30_CHAR)
    .label("Label"),
});

const textAndMessageValidation = Yup.object({
  default_value: Yup.string()
    .trim()
    .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Default Value"),
});

const mediaUploadButtonValidation = Yup.object({
  button_message: Yup.string()
  .trim()
  .required()
  .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
  .min(3, MINIMUM_3_CHAR)
  .max(30, MAXIMUM_30_CHAR)
  .label("Button Message"),
  button_title : Yup.string()
  .trim()
  .required()
  .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
  .min(3, MINIMUM_3_CHAR)
  .max(30, MAXIMUM_30_CHAR)
  .label("Button Title"),
});

const dataSetSliderValidation = Yup.object({
  from: Yup.number().required().label("From").typeError(ONLY_NUMBER_ALLOW),
  //TO is not greater than FROM
  to : Yup.number().required().label("To").typeError(ONLY_NUMBER_ALLOW).when('from', (from, schema) => {
    return schema.test({
      test: to => to > from,
      message: 'To must be greater than From',
    })
  }),
  //to: Yup.number().required().label("To").typeError(ONLY_NUMBER_ALLOW),
  default_value: Yup.string()
    .trim()
    .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Default Value"),
});

const validationSchema = Yup.object({
  title: Yup.string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(200, MAXIMUM_200_CHAR)
    .label("Title"),
  type: Yup.object().required().label("Type"),
  order: Yup.number().required().label("Order"),
  visibility: Yup.object()
    .required()
    .label("Visibility")
    .typeError("Visibility is required"),
  data_sets: Yup.array().when("type", {
      is: (type) => {
       return type && (type.key === "checkbox" || type.key === "radio_button")},
      then:()=> Yup.array(dataSetDropdownValidation).min(1, "At least one data set is required."),
    }).when("type",{
      is: (type) => {
       return type && (type.key === "slider")},
      then:()=> Yup.array(dataSetSliderValidation).min(1, "At least one data set is required."),
    }).when("type",{
      is : (type) => {
        return type && (type.key === "button" )    
      },
      then:()=> Yup.array(mediaUploadButtonValidation).min(1, "At least one data set is required."), 
    }),
  min_length :  Yup.number().when("type",{
    is:(type) =>{
      return type && (type.key==="button") && require},
      then:()=> Yup.number().min(0).max(100).required().label("Min Images"),
    })
  
  
 
});

export { validationSchema };
