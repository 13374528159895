import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,  
  GridFilterModel
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Row } from "react-bootstrap";
import constants, { pageSetting } from "../../../constants/constants";
import { currencyMask } from "../../../utility/common";
import {  getProductRecievedList } from "../../../services/productService";
import moment from "moment";


interface AddProps {
  productId: string | number | undefined;
}

const ProductReceived: React.FC<AddProps> = ({productId}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getProductRecievedList(productId,defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "$purchaseOrder.po_number$",
      headerName: "PO Number",
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,     
      renderCell(params){
        return <Link target="_blank"  to={`/purchase-orders/view/${params.row.purchaseOrder.id}`} className="blue-text fw-semibold text-decoration-none">{params.row.purchaseOrder.po_number_str}</Link>
      }
    },
    {
      field: "grn",
      headerName: "Good Received Note",
      flex: 1.5,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return params.row.purchaseOrder.goodsReceivedNotes.length ? 
        params.row.purchaseOrder.goodsReceivedNotes.map((grn : any,index: number) => (
          <Link target="_blank"  to={`/good-inward/view/${grn?.id}`} className="blue-text text-decoration-none fw-semibold me-1">{grn?.gr_number}</Link> 
        ))
        : "-"
       }, 
    },
    {
      field: "received_qty",
      headerName: "Received Qty.",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return params.row?.received_qty ? params.row?.received_qty : "-"
       }, 
    },
    {
      field: "$vendor.vendor_name$",
      headerName: "Vendor",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return params.row?.purchaseOrder?.vendor_id ? (<span title={params.row?.purchaseOrder?.vendor?.vendor_name}>{params.row?.purchaseOrder?.vendor?.vendor_name}</span>) : "-"
       }, 
    },  
    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 1.5,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return <span title={currencyMask(params.row?.price)}>{currencyMask(params.row?.price)}</span>
      },
    },
    {
      field: "$purchaseOrder.received_date$",
      headerName: "Date Acquired",
      type: "date",
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter: (params) => new Date(params?.row?.purchaseOrder?.received_date),
      renderCell(params) {
        const orderDate = new Date(params?.row?.purchaseOrder?.received_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
  ];
 
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };


  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

   
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="flex-grow-1 pageContent pb-2">
            <Card className=" p-3 w-100 pageContentInner mb-3">
              <Row>
                <Col>
                <h6 className="fs-18 fw-bold mb-3 info-text">Products Recieved</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"   
                  disableRowSelectionOnClick             
                  pageSizeOptions={[5]}
                /> 
                </Col>
              </Row>
            </Card>
          </div>        
      )}
    </React.Fragment>
  );
};

export default ProductReceived;
