import * as yup from "yup";
import {
  CONFIRM_PASSWORD_REQUIRED,
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MINIMUM_6_CHAR,
  MAXIMUM_255_CHAR,
  ALPHABETS_ALLOW,
  PASSWORD_MUST_MATCH,
  PASSWORD_REGEX,
  PASSWORD_REQUIRED,
} from "../../validations/ValidationErrors";
import { EMAIL_REGEX, ONLY_ALPHABET } from "../../validations/regex";

export const profileChangeRequestValidation = (profileUpdateReqData: any) => {

  const userSchema:any = {};
  const employDetailsSchema:any = {};
  const kinDetailsSchema:any = {};
  const employeeBankDetailsSchema:any = {};
  if (profileUpdateReqData.users?.first_name) {
    userSchema["first_name"] = yup
      .string()
      .trim()
      .required()
      .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("First Name");
  }
  if (profileUpdateReqData.users?.last_name) {
    userSchema["last_name"] = yup
      .string()
      .trim()
      .required()
      .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Family Name");
  }
  if (profileUpdateReqData.users?.phone_no) {
    userSchema["phone_no"] = yup
      .number()
      .required()
      .typeError("Contact number must be a number")
      .max(999999999999999, "Contact number must be at most 15 digits") // Maximum 15 digits
      .label("Contact No");
  }

  if (profileUpdateReqData.Employ_details?.address) {
    employDetailsSchema["address"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Address");
  }

  if (profileUpdateReqData.Employ_details?.city) {
    employDetailsSchema["city"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("City");
  }

  if (profileUpdateReqData.Employ_details?.state) {
    employDetailsSchema["state"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("County");
  }

  if (profileUpdateReqData.Employ_details?.postal_code) {
    employDetailsSchema["postal_code"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Postal Code");
  }

  if (profileUpdateReqData.Employ_details?.ni_number) {
    employDetailsSchema["ni_number"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("NI Number");
  }

  if (profileUpdateReqData.Kin_Details?.keen_name1) {
    kinDetailsSchema["keen_name1"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Kin Name 1");
  }

  if (profileUpdateReqData.Kin_Details?.keen_contact_no1) {
    kinDetailsSchema["keen_contact_no1"] = yup
      .number()
      .required()
      .typeError("Kin Contact number must be a number")
      .positive("Kin Contact number must be positive")
      .integer("Kin Contact number must be an integer")
      .max(999999999999999, "Kin Contact number must be at most 15 digits") // Maximum 15 digits
      .label("Kin Contact 1");
  }

  if (profileUpdateReqData.Kin_Details?.keen_relation1) {
    kinDetailsSchema["keen_relation1"] = yup
      .object()
      .required()
      .label("Keen Relation 1 ");
  }

  if (profileUpdateReqData.Kin_Details?.keen_name2) {
    kinDetailsSchema["keen_name2"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Kin  Name 2");
  }

  if (profileUpdateReqData.Kin_Details?.keen_contact_no2) {
    kinDetailsSchema["keen_contact_no2"] = yup
      .number()
      .required()
      .typeError("Kin Contact number must be a number")
      .positive("Kin Contact number must be positive")
      .integer("Kin Contact number must be an integer")
      .max(999999999999999, "Kin Contact number must be at most 15 digits") // Maximum 15 digits
      .label("Kin Contact 2");
  }

  if (profileUpdateReqData.Kin_Details?.keen_relation2) {
    kinDetailsSchema["keen_relation2"] = yup
      .object()
      .required()
      .label("Keen Relation 1 ");
  }

  if (profileUpdateReqData.Employee_Bank_Details?.bank_name) {
    employeeBankDetailsSchema["bank_name"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Bank Name");
  }

  if (profileUpdateReqData.Employee_Bank_Details?.account_name) {
    employeeBankDetailsSchema["account_name"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Account Name");
  }

  if (profileUpdateReqData.Employee_Bank_Details?.account_number) {
    employeeBankDetailsSchema["account_number"] = yup
      .number()
      .required()
      .typeError("Account Number must be a number")
      .positive("Account Number must be positive")
      .integer("Account Number must be an integer")
      .min(9999, "Account Number  must be minimum 4 digits") // Maximum 15 digits
      .max(999999999999999, "Account Number must be at most 15 digits"); // Maximum 15 digits
  }

  if (profileUpdateReqData.Employee_Bank_Details?.sort_code) {
    employeeBankDetailsSchema["sort_code"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Sort Code");
  }

  if (profileUpdateReqData.Employ_details?.job_description) {
    employDetailsSchema["job_description"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .label("Job Description");
  }

  const yupSchema: Record<string, any> = {'users':yup.object(userSchema),'Employ_details':yup.object(employDetailsSchema),
  'Kin_Details':yup.object(kinDetailsSchema),'Employee_Bank_Details':yup.object(employeeBankDetailsSchema)};

  return yup.object(yupSchema);
};
