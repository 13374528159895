import { Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { AuthHeader } from '../authHeader/AuthHeader';
import InputText from '../../../components/common/InputText';
import { LoginSchema } from '../../../validations/Auth';
import { FaEnvelope, FaLock } from "react-icons/fa";
import { FaRegEye , FaRegEyeSlash} from "react-icons/fa";

import { useEffect, useRef, useState } from 'react';
import Loader from '../../../components/common/Loader';
import storage from '../../../helpers/storage';
import { toast } from 'react-toastify';
import { CAPTCH_INVALID } from '../../../validations/ValidationErrors';
import { loginWithUserNameAndPassword } from '../../../services/authService';
import Cookies from "universal-cookie";

import { Image } from 'react-bootstrap'
import LogoBanner from '../../../assets/images/login-right-banner.png';
import CustomButton from '../../../components/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {login,loginFailure,loginRequest, refreshTokenRequest, selectAuthentication} from '../../../features/Auth/authSlice'
import { nameBasedProtectedRoutes } from '../../../router/protected';
import Captcha from '../captcha';
import { SUPER_ADMIN } from '../../../constants/constants';
import ability, { buildAbilities, buildAbilitiesForSuperAdmin } from '../../../role-manager/ability';

export const Login = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
    // remember me

  const rememberMeEmail = cookies.get("admin_email");
  const rememberMePassword = cookies.get("admin_password");
  const alreadyChecked = cookies.get("admin_checked");
  const [isChecked, setIsChecked] = useState(!!alreadyChecked);
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLoading, setIsLoading]= useState(true)
  const [captchaCode, setCaptchaCode] = useState("");
  const [passwordVisible,setPasswordVisible] = useState(false)
  const rememberMe: { email: string; isChecked: boolean; password: string } = storage?.getData("remember-me");
  const dispatch = useDispatch();
  const {user, loading, isAuthenticated } = useSelector(selectAuthentication);
 // data
 const [data, setData] = useState({
  email: "",
  password: "",
  captcha: "",
});
  useEffect(()=>{
   
    if(isAuthenticated || storage.getToken() != null ){      
      navigate('/home')
    }
    setIsLoading(false)
    
  },[navigate])
  const initialValues = {
    email: rememberMeEmail ?rememberMeEmail : "",
    password: rememberMePassword ? rememberMePassword : "",
    captcha: "",
    rememberCheck: false
  };
  
  const changePasswordVisible = () =>{
    setPasswordVisible(!passwordVisible)
  }
  const onChangeCheckbox = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const onChangeCaptchaCode = (value: any) => {
    setCaptchaCode(value);
  };



  const removeCookies = () =>{
      cookies.remove("admin_email");
      cookies.remove("admin_password");
      cookies.remove("admin_checked");
  }
  /**
   * Submit Form
   *
   * @async
   * @param {string} email
   * @param {string} password
   * @param {*} captcha
   * @param {*} actions
   * @returns {*}
   */
  const submitForm = async (
    email: string,
    password: string,
    captcha: any,
    actions: any) => {
    if (!captchaCode) {
      toast.error(CAPTCH_INVALID);
      return;
    }
    dispatch(loginRequest())
    setBtnLoading(true)
    loginWithUserNameAndPassword({ email, password })
      .then((response) => {       
        dispatch(login(response.data)); 
        setBtnLoading(false)
        if(response.data.role.role==SUPER_ADMIN){
          ability.update(buildAbilitiesForSuperAdmin())
          
        }else{
          ability.update(buildAbilities(response.data.role.permission))
        }

        storage.setToken(response.data.tokens.access?.token);
        storage.setRefreshToken(response.data.tokens.refresh?.token);
        if (isChecked === true) {
          // console.log("values", values.password);
          cookies.set("admin_email", email);
          cookies.set("admin_password", password);
          cookies.set("admin_checked", isChecked);
        }else{
          removeCookies()
        }
        navigate('/home');

      })
      .catch((error) => {
        dispatch(loginFailure())
        setBtnLoading(false)
        toast.error(error.response.data.message);
      });
  };
  return (
    <>
      {loading ? <Loader isLoading={loading} /> : <Formik
        validationSchema={LoginSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          submitForm(
            values.email,
            values.password,
            values.captcha,
            actions
          );
        }}

      >

        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <div className='container-fluid'>
            <div className='row bg-white'>
            <div className='col-md-6 col-xl-4'>
                <div className="d-flex  h-100 w-100">
                    <div className="align-content-between d-flex flex-wrap min-vh-100 login-left-mobile mw-350 mx-auto py-4 w-100">
                      <AuthHeader />
                      <div className="d-inline-block mt-5  w-100">
                        <Form className="" noValidate onSubmit={handleSubmit}>
                          <h6 className="fw-bold fs-32  mb-0">Welcome Back</h6>                        
                          <p className='fs-13'>Enter your login credentials</p>

                          <InputText
                            controlId="email"
                            label='Email Address'
                            placeholder="Enter Email Address"
                            touched={touched.email}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.email}
                            value={values.email}
                            //rightIcon={<FaEnvelope size="13" />}
                          />
                          <div className='position-relative w-100'>
                          <InputText
                            controlId="password"
                            label='Password'
                            type={passwordVisible?"text":"password"}
                            placeholder="Enter Password"
                            touched={touched.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.password}
                            value={values.password}
                            
                          />
                          <span className='eyeIcon'>{passwordVisible?<FaRegEye size="13"   onClick={changePasswordVisible}/>:<FaRegEyeSlash size="13"   onClick={changePasswordVisible}/>}</span>
</div>
                          <Form.Group className="mb-3">
                            <Row xs="auto" className="align-items-center justify-content-between gx-0">
                              <Col>
                                <Form.Check
                                  id="rememberCheck"
                                  className="fs-13 mh-auto mb-0 user-select-none lh-20"
                                  type="checkbox"
                                  label="Remember me"
                                  checked={isChecked}
                                  onChange={onChangeCheckbox}
                                />
                              </Col>
                              <Col>
                                <Link className="fs-13 text-decoration-none fw-bold" to="/forgot-password">Forgot Password?</Link>
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="captcha">
                          
                           <Captcha  onChangeCaptchaCode={onChangeCaptchaCode}/>
                          </Form.Group>

                          {/* <Button className="fw-semibold fs-13 text-white mw-90 mt-2" variant="primary" type="submit">Login</Button> */}
                          <CustomButton 
                            type="submit"
                            loading={btnLoading}
                            className="fw-semibold fs-13 text-white mw-60"
                            variant="primary"
                            >
                            Sign In
                          </CustomButton>
                            
                         
                        </Form>
                      </div>
                      <div className='min-h-30 w-100'></div>
                    </div>
                </div>
              </div>
              <div className='col-md-6 col-xl-8 login-right'>
                <div className='left-0 login-right-box lh-38 p-3 px-4 position-absolute text-white top-30 z-2 fw-300 mw-366 fs-28'><strong className='fw-bold'>Enabling & restoring independence</strong></div>
                <Image className="img-fluid " src={LogoBanner} alt="Logo Banner"  />
              </div>
            </div>
          </div>
        
      )}
    </Formik >}
      
    </>
  )
}
