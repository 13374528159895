import React from "react";
import { FC } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ITypeProps } from "./surveyInterface";
import CommonNotes from "./commonNotes";
import FormSelect from "../../../components/common/FormSelect";

const TypeDropdown: FC<ITypeProps> = ({
  field,
  index,
  fieldIndex,
  subIndex,
  templateData,
  setTemplateData,
  errors,
  values,
  setFieldValue,
  handleBlur,
  touched,
  fieldConditions
}) => {

  const evaluateCondition = (conditionValue: any,operator: string, value: any) => {
    let visibility = false;
    switch (operator) {
      case 'Equal':
        visibility = conditionValue == value;
        break;
      case 'Not Equal':
        visibility = conditionValue != value;
        break;
      case 'Greater Than':
        visibility = conditionValue > value;
        break;
      case 'Less Than':
        visibility = conditionValue < value;
        break;
      default:
        visibility = false;
    }
    return visibility;
   };
  const changeConditions = (  value: any, field_id:number) => {
     if(fieldConditions.length > 0){  
         fieldConditions.map((item: any) => {
           if(item.conditions.length > 0){
              item.conditions.map((condition: any) => {
                if(condition.field.key === field_id){
                      
                      if(evaluateCondition(condition.value.toLowerCase(), condition.condition.value, value.key.toLowerCase()))
                      {
                        if(item.actions.length > 0 ){
                          item.actions.map((action: any)=>{                                                                                                         
                            let fieldSheetTextTemp = [...templateData];
                            //update the visibility of the field where fieldSheetTextTemp find the section id an sub section id and field id
                              fieldSheetTextTemp.map((section: any, index: number) => {
                                if(section.section_id === item.section_id){
                                  section.subsections.map((subSection: any, subIndex: number) => {
                                    if(subSection.subsection_id === item.subsection_id){
                                      subSection.fields.map((field: any, fieldIndex: number) => {
                                        if(field.field_id === action.field.key){     
                                          console.log("field.field_id",field.field_id)
                                          console.log("action.field.key",action.field.key)                                   
                                          field.visibility = action.action.key == 'Show' ? true : false;
                                        } 
                                      })
                                    }
                                  })
                                }
                              }
                            )
                            setTemplateData(fieldSheetTextTemp);
                            //  fieldSheetTextTemp[index].subsections[subIndex].fields.find((item: any) => item.field_id === action.field.key).visibility = action.action.key == 'Show' ? true : false;
                            //  setTemplateData(fieldSheetTextTemp); 
                         })  
                        }                                  
                      }
                      //set else with reset the visibility of the field
                      else{
                        if(item.actions.length > 0 ){
                          item.actions.map((action: any)=>{                                                                                                         
                            let fieldSheetTextTemp = [...templateData];
                            //update the visibility of the field where fieldSheetTextTemp find the section id an sub section id and field id
                              fieldSheetTextTemp.map((section: any, index: number) => {
                                if(section.section_id === item.section_id){
                                  section.subsections.map((subSection: any, subIndex: number) => {
                                    if(subSection.subsection_id === item.subsection_id){
                                      subSection.fields.map((field: any, fieldIndex: number) => {
                                        if(field.field_id === action.field.key){   
                                                                                  
                                          field.visibility = !field.visibility
                                        } 
                                      })
                                    }
                                  })
                                }
                              }
                            )
                            setTemplateData(fieldSheetTextTemp);
                            //  fieldSheetTextTemp[index].subsections[subIndex].fields.find((item: any) => item.field_id === action.field.key).visibility = action.action.key == 'Show' ? true : false;
                            //  setTemplateData(fieldSheetTextTemp); 
                         })
                        }
                      }
                }
              }
            )
            }
          }
        )
    }
  }

                     
  const handleInputChangeSelect = (
    value: string,
    index: number,
    subIndex: number,
    fieldIndex: number,
    name: string,
    setFieldValue: any
  ) => {
    setFieldValue(name, value);
    let fieldSheetTextTemp = [...templateData];
    fieldSheetTextTemp[index].subsections[subIndex].fields[fieldIndex].value =
      value;
    setTemplateData(fieldSheetTextTemp);
    changeConditions( value, field.field_id);

  };

  //dropdown array transform
  const transformedArray = (originalArray: any) => {
    const newOptions = originalArray.map((item: any) => {
      return {
        key: item.label,
        value: item.label,
      };
    });
    console.log("newOptions", newOptions);
    return newOptions;
  };

  return (
    <div>
      <Card className="p-3 w-100 pageContentInner mb-3">
        <div className="d-flex flex-column h-100 rounded-0 ">
          <Row className="d-flex">
            <Col className="col-md-11">
              <p className="fs-14 mb-2">
                {field.title} {field.require && "*"}
              </p>
              <div className="w-100 survery-input">
                <FormSelect
                  placeholder={field.label}
                  label={field.label}
                  name={field.name}
                  options={transformedArray(field?.data_set)}
                  getOptionLabel={(option: any) => option.value}
                  getOptionValue={(option: any) => option.key}
                  onChange={(name: string, value: string) =>
                    handleInputChangeSelect(
                      value,
                      index,
                      subIndex,
                      fieldIndex,
                      field.name,
                      setFieldValue
                    )
                  }
                  handleBlur={handleBlur}
                  value={values[field.name]}
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              </div>
            </Col>
            <Col className="col-md-1 text-end">
              <CommonNotes
                field={field}
                index={index}
                fieldIndex={fieldIndex}
                subIndex={subIndex}
                touched={touched}
                errors={errors}
                values={values}
                templateData={templateData}
                setTemplateData={setTemplateData}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default TypeDropdown;
