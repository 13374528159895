import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import CustomButton from "./CustomButton";
import { toast } from "react-toastify";
import Loader from "./Loader";
import * as yup from "yup";
import InputText from "./InputText";
import { getFirstLetters } from "../../utility/common";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../features/Auth/authSlice";
import { allowedFileTypes, maxFileSizeMB } from "../../constants/constants";
import SendIcon from '@mui/icons-material/Send';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Badge from '@mui/material/Badge';
import FormAsyncSelect from "./AsyncSelect/FormAsyncSelect";
import { getAllManagerList } from "../../services/authService";

import CancelIcon from '@mui/icons-material/Cancel';

const addSchema = yup.object({
    message: yup.string().trim().required().label("Comment"),
});

interface AddProps {  
  ticketId: any;
  onSubmitData: (formData:any, deletedId:number) => void;  
  onUpload: (formData: any, uploadedFiles: any, signedUrlsFiles:any) => void;
  isMutation?: boolean;
  uploadedFilesData?: string[];
  signedUrlsFilesData?: string[];
  buttonLoaing?: boolean;
  imageLoading?: boolean;
}

interface FilterValues {
    message: string;
    tag_users_id: any;
    files: any;
    filename: any;
}

const CommentsForm: React.FC<AddProps> = ({
 
  ticketId,
  onSubmitData,
  onUpload,
  isMutation,
  uploadedFilesData=[],
  signedUrlsFilesData = [],
  buttonLoaing,
  imageLoading
}) => {
  const [btnLoading, setBtnLoading] = useState(buttonLoaing);
  const [loading, setLoading] = useState(false);
  const {user }:any = useSelector(selectAuthentication);
  const [uploadedFiles, setUploadedFiles] = useState<any>(uploadedFilesData);
  const [signedUrlsFiles, setSignedUrlsFiles] = useState<any>(signedUrlsFilesData);
  const [isShowTagged, setIsshowTagged] = useState(false);
 // const [imageLoading, setImageLoading] = useState(imageLoading);
  const  userName = user.user.full_name; 
//   console.log(buttonLoaing);
  useEffect(() => {
    setLoading(false);
    setIsshowTagged(false);
    setSignedUrlsFiles([]);
    setUploadedFiles([]);
    setBtnLoading(buttonLoaing)
  }, [isMutation]);

  useEffect(() => {
   // setBtnLoading(buttonLoaing)
    if(signedUrlsFilesData.length){
        setSignedUrlsFiles(signedUrlsFilesData);
        setUploadedFiles(uploadedFilesData);
       // console.log(uploadedFilesData, signedUrlsFilesData);
    }
  }, [uploadedFilesData, signedUrlsFilesData]);
  //  const id  = deletedData && deletedData.id ? deletedData.id :""
  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (
    values: FilterValues,
    { setSubmitting, resetForm }: any
  ) => {
    const formData: any = {
      ...values,
      files:uploadedFiles
    };
   // console.log(formData, ticketId, uploadedFilesData);
    setBtnLoading(true);
    setLoading(true);
    onSubmitData(formData, ticketId);
    resetForm();
    return;
  };

  const uploadFile = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    //console.log("file", imageFile);

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
    //  setImageLoading(true);
        onUpload(imageFile,uploadedFiles,signedUrlsFiles);

     
    }
  };


  const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      const transformParam = {
        ...params
      };
      getAllManagerList(transformParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const handleDeleteReturnImage = (
    formValues: any,
    indexNumber: number,
    setFieldValue: any
  ) => {
    const failedGrnImageDetails = formValues.files;
    const failedGrnImagePreview = formValues.filename;
    failedGrnImageDetails.splice(indexNumber, 1);
    failedGrnImagePreview.splice(indexNumber, 1);
    setUploadedFiles(failedGrnImageDetails);
    setSignedUrlsFiles(failedGrnImagePreview);
    setFieldValue(`files`, failedGrnImageDetails);
    setFieldValue(`filename`, failedGrnImagePreview);
  };
  

  const initialValues = {
    message : "",
    tag_users_id : [],
    files : uploadedFilesData,
    filename : signedUrlsFilesData
  }

 // console.log(initialValues)

  return (
    <>
      {loading ? (
        <label className="file-box1 pt-2 text-center w-100">
        <span>
          <Spinner />
        </span>
      </label>
       
      ) : (
        <Row>
        <Col>
       
        <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FilterValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
                <Form className="ticket-comment-bottom" noValidate onSubmit={handleSubmit}>
                 
                <Row>
                <Col className="col-7 ticket-comment-input d-flex">
                  <span className="ticket-user-tag ms-2 mt-2">{ getFirstLetters(userName)}</span>
                  <InputText
                    controlId="message"
                    label=""
                    placeholder="Enter Comment"
                    className="border-0 bg-none"
                    touched={touched.message}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errorsField={errors.message}
                    value={values.message}
                  />
                </Col>                    
                <Col className="text-end">
                <input
                  type="file"
                  id="fileInput"
                  className="d-none"
                  accept=".jpeg, .png,.jpg,.svg"
                  onChange={(e) =>
                    uploadFile(e, setFieldValue)
                  }
                />
                {/* Custom icon/button */}
                <label htmlFor="fileInput" className="cursor-pointer">
                <AddLinkIcon className="text-gray" />
                </label>                      
                  <CustomButton
                    type="button"                        
                    className="fw-semibold fs-13 text-gray"
                    variant="link"
                    onClick={() => setIsshowTagged(!isShowTagged)}
                  >
                    <Badge badgeContent={values.tag_users_id.length} color="primary">
                    <AlternateEmailIcon />
                    </Badge>                       
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    loading={btnLoading || buttonLoaing}
                    disabled={btnLoading || imageLoading || buttonLoaing}
                    className="fw-semibold fs-13 text-primary ps-0"
                    variant="link"
                  >
                  <SendIcon />
                  </CustomButton>
                </Col>
                { isShowTagged && (
                    <div className="p-4 py-0">
                      <Row>
                      <Col className="col-12 position-relative ">
                        <span>

                      <FormAsyncSelect
                          id="tag_users_id"
                          name="tag_users_id"
                          label=""
                          isAsync
                          isClearable
                          isMulti
                          getOptions={getProjectOwner}
                          value={values.tag_users_id}
                          onChange={(name: any, value: any) => {
                            setFieldValue(name, value);
                          }}
                        /> 
                        <div
                        className="good-inward-delete-icon cursor-pointer"
                        onClick={() => setIsshowTagged(false)}
                        
                      >
                        <CancelIcon className="text-danger bg-white rounded-5 fs-16" />
                        
                      </div>
                        </span>
                      </Col>
                      </Row>
                     </div>
                  )}

                { signedUrlsFiles.length>0 && (
                  <div className="p-4 py-0 ticket-comment-right">
                    <Row>
                    <Col className="col-12 ticket-comment-img-scroll">
                    {signedUrlsFiles.map((fileUrl: any, index: any) => (
                      <label
                      key={`commentImages${index}`}
                      className="file-box good-inward-box-cover me-3"
                    >
                      <span>
                        {" "}
                        <img
                          className="img-fluid"
                          src={fileUrl} 
                          alt="Logo"
                        />
                        <div
                          className="good-inward-delete-icon cursor-pointer"
                          onClick={() =>
                            handleDeleteReturnImage(
                              values,
                              index,
                              setFieldValue
                            )
                          }
                        >
                          <CancelIcon className="text-danger bg-white rounded-5 fs-16" />
                          
                        </div>
                      </span>
                    </label>                    
                    ))}
                    </Col>
                    </Row>
                </div>
                )}
                
                </Row>
              </Form>
            )}
          </Formik>  
        </Col>
       </Row>
      )}
    </>
  );
};
export default CommentsForm;
