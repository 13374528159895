import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { deleteRoleApi } from "../../services/roleService";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "@mui/icons-material/Visibility";

import BlockIcon from "@mui/icons-material/Block";
import { toast } from "react-toastify";
import {
  getReportingManagerEmployeeList,
} from "../../services/employeeService";
import { Card } from "react-bootstrap";
import AddCircle from "../../assets/images/add_circle.svg";
import { pageSetting, pageSizeModel } from "../../constants/constants";
import { checkAbility, getTagClass } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { Email, CheckCircle, MeetingRoom } from "@mui/icons-material";

const Roles: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });


  /**
   * View employee click handler
   */
  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/my-team/view/${id}`);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getReportingManagerEmployeeList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const handleSendEmail = (id: GridRowId) => {
    navigate(`/employees/send-email/${id}`);
  }

  const handleScheduleMeeting = (id: GridRowId) => {
    navigate(`/my-team/view/${id}/meetings`);
  }

  const handleLeavesApproval = (id: GridRowId) => {
    navigate(`/my-team/view/${id}/leaves`);
  }

  const columns: GridColDef[] = [
    {
      field: "$employeeDetails.employment_id$",
      headerName: "Employee ID",
      flex: 1,
      sortable: true,
      hideable: false,
     
      renderCell(params) {
        return <span>{params.row.employeeDetails.employment_id}</span>;
      },
    },

    { field: "full_name", headerName: "Name", flex: 1, sortable: true },
    { field: "email", headerName: "Email", flex: 2, sortable: true },
    {
      field: "$employeeDetails.designation$",
      headerName: "Designation",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.employeeDetails.designation}>
            {params.row.employeeDetails.designation}
          </span>
        );
      },
    },
    {
      field: "$employeeDetails.department$",
      headerName: "Department",
      flex: 2,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.employeeDetails.department}>
            {params.row.employeeDetails.department}
          </span>
        );
      },
    },
    {
      field: "$employeeDetails.employment_status$",
      headerName: "Status",
      flex: 2,
      sortable: true,
      renderCell(params) {
        return (
          <span
            title={params.row.employeeDetails.employment_status}
            className={`py-1 px-2 rounded-1 ${getTagClass(
              params.row.employeeDetails.employment_status
            )} d-inline-block fs-11`}
          >
            {params.row.employeeDetails.employment_status}
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const gridActions = [];
          gridActions.push(
            <GridActionsCellItem
              label="View"
              icon={<ViewIcon />}
              onClick={handleViewClick(id)}
            />,
            <GridActionsCellItem
              label="Send Email"
              onClick={() => handleSendEmail(id)}
              showInMenu
              icon={<Email />}
            />,
            <GridActionsCellItem
              label="Schedule Meetings"
              onClick={() => handleScheduleMeeting(id)}
              showInMenu
              icon={<Email />}
            />,
            <GridActionsCellItem
                label="Leave Approval"
                onClick={() => handleLeavesApproval(id)}
                showInMenu
                icon={<CheckCircle />}
              />,
          );
        
        return gridActions;
      },
    }
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  // useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     pageInfo?.totalRowCount !== undefined
  //       ? pageInfo?.totalRowCount
  //       : prevRowCountState,
  //   );
  // }, [pageInfo?.totalRowCount, setRowCountState]);
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">My Team</h1>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Roles;
