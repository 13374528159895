import { Route, Routes } from "react-router-dom";
import EmployeeMeetings from "./index";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { Login } from "../Auth/Login";
import {MEETING_SOURCE_PAGES} from "../../constants/constants";

export const TeamMeetings = () => {
  return (
    <Routes>
      <Route path="/" element={<EmployeeMeetings source = {MEETING_SOURCE_PAGES.reporting_manager_team} />} />
    </Routes>
  );
};
