import { Card, Col, Row } from "react-bootstrap";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { getPendingTaskForHr } from "../../services/hrdashboard.service";
import Loader from "../../components/common/Loader";
import TableSkeleton from "../../components/common/Skeleton/Table.skeleton";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import {   useNavigate } from "react-router-dom";

const PendingTaskHR = () => {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const navigation =  useNavigate()
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, []);
  const fetchData = (signal: any) => {
    getPendingTaskForHr(signal)
      .then((res) => {
        setListData(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleViewClick = (rows: any) =>{
    console.log("rows",rows)
    navigation(`/employees/view-employee/${rows.user_id}/${rows.type}`)
  }

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S. No.",
      sortable: false,
      filterable: true,
      hideable: false,
    },

    {
      field: "name",
      headerName: "Task Name",
      flex: 1,
      filterable: true,
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      flex: 1,
      filterable: true,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      filterable: true,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell(params) {
        return (
          <GridActionsCellItem
            label="View"
            icon={<ViewIcon />}
            showInMenu={false}
            onClick={() => handleViewClick(params.row)}
            className="text-primary"
          />
        );
      },
    }
  ];

  return (
    <>
      <Row className="h-100">
        <Col md={12} className="h-100">
          <Card  className="p-3 w-100   rounded-2">
            {loading ? (
              <TableSkeleton />
            ) : (
              <div className="d-flex flex-column rounded-0 custom-table-new">
                <DataGrid
                  rows={
                    listData.length
                      ? listData.map((row: any, index: number) => ({
                          ...row,
                          serialNumber: index + 1,
                        }))
                      : []
                  }
                  columns={columns}
                  pagination                   
                  rowSelection={false}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 4 } },
                  }}
                />
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PendingTaskHR;
