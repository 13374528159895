import * as yup from "yup";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_30_CHAR,
  MAXIMUM_255_CHAR,
  ALPHA_NUMERIC_ALLOW
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";

// This function validates the status of asset should not be same.
const assetStatusValidation = (status: string) => (value:string, context: any) => {
  if(status === 'in_inventory' || status === 'dead_inventory'){
    console.log(context.options.originalValue);
    if(status === context.options.originalValue){
      switch (status){
        case 'in_inventory':
          return context.createError({message:'Asset already is in inventory. Select other option'});
          break;
        case 'dead_inventory':
          return context.createError({message:'Asset already is in dead inventory. Select other option'});
          break;
        default:
          return context.createError({message: 'Status is invalid'})
          break;
      }
    }
    else{
      return true;
    }
  }
  else{
    return true;
  }
};

export const addSchema = (status: string) => {
  return yup.object({
    status: yup.string().required('Please select one allocation option').test('assetStatus',assetStatusValidation(status)),
    employee_id: yup.object().when('status', {
      is: 'allocated',
      then: (schema) => yup.object().required('Member is required'),
      otherwise: (schema) => yup.object(),
    }),
    note :  yup.string().trim().optional().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).label("Note")
  });
}
