import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  VALID_EMAIL,
  INVALID_DATE,
} from "../../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../../validations/regex";

interface QualityStatus {
  key: string;
  // Other properties as needed
}

const commonSchema = yup.object({  
  receivedProducts: yup.array().of(
    yup.object({
      qty_received: yup
      .number()
      .required()
      .integer()
      .positive()
      .typeError("Qty. Received must be a number")
      .test('is-equal', "Qty. Received must be equals to Qty. Ordered", function(value) {
        return value === this.parent.balance_qty;
      })      
        .min(
          yup.ref("qty_discard"),
          "Qty. Received must be greater than Qty. Discard"
        )
        .min(
          yup.ref("qty_returned"),
          "Qty. Received must be greater than Qty. Returned"
        )
        .test(
          "sum-validation",
          "Qty. Received must be greater than equals to the sum of Qty. Discard and Qty. Returned",
          function (value) {
            const qtyDiscard = this.parent.qty_discard ? this.parent.qty_discard as number : 0;
            const qtyReturned = this.parent.qty_returned ? this.parent.qty_returned as number: 0;
            return value >= qtyDiscard + qtyReturned;
          }
          )
          .label("Qty. Received"),
      qty_discard: yup.number().integer().moreThan(-1, 'Enter valid Qty').typeError("Qty. Discard must be a number").required().label("Qty. Discard"),
      qty_returned: yup.number().integer().moreThan(-1, 'Enter valid Qty').typeError("Qty. Returned must be a number").required().label("Qty. Returned"),
      quality_status: yup
      .object<QualityStatus>()
      .nullable()
      .when("qty_received", {
        is: (qty_received: number) => qty_received && qty_received > 0,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional(),
      })
      .test('failed-check', 'Quality Check must be failed if Qty. Discard or Qty. Returned is provided', function (value:any) {
        const { qty_discard, qty_returned } = this.parent as any; // Adjust this based on your exact types
        if (qty_discard > 0 || qty_returned > 0) {
          return value && value.key === 'Fail';
        }
        return true;
      })      
      .label("Quality Check"),
      return_remarks: yup.string().when("quality_status", {
        is: (status: QualityStatus) => status && status.key === "Fail",
        then: (schema) => schema.required().min(3, MINIMUM_3_CHAR)
        .max(255, MAXIMUM_255_CHAR).label("Return Reason"),
        otherwise: (schema) => schema.optional().min(3, MINIMUM_3_CHAR)
        .max(255, MAXIMUM_255_CHAR).label("Return Reason"),
      }),
      images: yup.array().label("Product Images").when("quality_status", {
        is: (status: QualityStatus) => status && status.key === "Fail",
        then: (schema) => schema.min(1, "Please add atleast one product Image").required(),
        otherwise: (schema) => schema.optional(),
      }),
    })
  ),
});

const grnSchema = (context: string | undefined | null) => {
  if (context) {
    // // If it's an edit context, add/edit specific validation rules
    // return commonSchema.shape({
    //   // Additional validation rules for edit
    //   // For example, you might not require certain fields in edit
    //   pay_status:yup.object().required().label("Payment Status"),
    //   // Add other edit-specific fields here
    // });
  }

  // If it's an add context, return the common schema
  return commonSchema;
};

export default grnSchema;
