import React, { useEffect, useState, FC } from "react";
import {  useSelector, useDispatch } from 'react-redux';
import Form from "react-bootstrap/Form";
import { FieldArray, Formik, getIn } from "formik";
import { toast } from "react-toastify";
import InputText from "../../components/common/InputText";
import { Card, Col, Image, Row, Spinner } from "react-bootstrap";
import {
    editAdminProfile,
} from "../../services/authService";
import { adminProfileValidationSchema } from "./profileValidation";
import Loader from "../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import { AdminDataType } from "./profiledata.type";
import CustomButtonCancel from "../../components/common/CustomButtonCalcel";
import { refreshToken, refreshTokenFailure, refreshTokenRequest, selectAuthentication } from "../../features/Auth/authSlice";
import storage from "../../helpers/storage";
import { refreshTokenCALL } from "../../services/authService";
import ability, { buildAbilities, buildAbilitiesForSuperAdmin } from "../../role-manager/ability";

const EditAdminProfile:FC = () => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { user }: any = useSelector(selectAuthentication);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = storage.getRefreshToken();


  let { id } = useParams();

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: AdminDataType, { setSubmitting }: any) => {
    const formData = {
      ...values,
    };

    setBtnLoading(true);
    
    editAdminProfile(formData)
    .then((response: any) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setBtnLoading(false);
        refreshTokenFn(response.message); // Refresh token to update user state. Update state will display updated first and last name in header.
    })
    .catch((error: any) => {
        setBtnLoading(false);
        if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
        });
        } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
        });
        }
    });
    
  };

  /**
   * Refresh the token to update the global user state
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const refreshTokenFn = (toastMsg: string) => {
    const tokenObj = {
      refreshToken: token || "",
    };

    dispatch(refreshTokenRequest());
    refreshTokenCALL(tokenObj)
      .then((response) => {
        dispatch(refreshToken(response.data));
        ability.update(buildAbilitiesForSuperAdmin())
        storage.setToken(response.data.tokens.access?.token);
        storage.setRefreshToken(response.data.tokens.refresh?.token);
        toast(toastMsg, { type: toast.TYPE.SUCCESS });
      })
      .catch((error) => {
        dispatch(refreshTokenFailure())
      }).finally(() => {
         navigate('/dashboard');
      });
  };


  const initialValues: AdminDataType = {
    first_name: user.user.first_name,
    last_name: user.user.last_name,
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  Edit Profile
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={adminProfileValidationSchema}
              initialValues={initialValues}
              onSubmit={(values: AdminDataType, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Card className="p-3 w-100 pageContentInner mb-3">
                    <div>
                      <Row>
                        <div className="col-md-8">
                          <Row>
                            <div className="col-md-6">
                              
                              <InputText
                              controlId="first_name"
                              label="First Name"
                              placeholder="Enter first name"
                              touched={touched.first_name}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.first_name}
                              value={values.first_name}
                            /> 
                            </div>

                            <div className="col-md-6">
                              <InputText
                                controlId="last_name"
                                label="Family Name"
                                placeholder="Enter family name"
                                touched={touched.last_name}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.last_name}
                                value={values.last_name}
                              /> 
                            </div>
                            
                          </Row>
                        </div>
                      </Row>

            
                    </div>
                  </Card>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-end my-3">
                        <CustomButtonCancel label="Cancel" />

                        <CustomButton
                          type="submit"
                          loading={btnLoading}
                          className="fw-semibold fs-13 text-white mw-90 mt-2"
                          variant="primary"
                        >
                          Update
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default EditAdminProfile;