import { Route, Routes } from "react-router-dom";
import DealsList from "./List/list";
import { AddDeals } from "./Add/index";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { checkAbility } from "../../utility/common";

export const Deals = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.DEALS) && (
        <Route path="/" element={<DealsList />} />
      )}

      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.DEALS) && (
        <Route path="list" element={<DealsList />} />
      )}
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.DEALS) && (
        <Route path="add" element={<AddDeals />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.DEALS) && (
        <Route path="edit/:id" element={<AddDeals />} />
      )}
    </Routes>
  );
};
