import { Card, Col, Row } from "react-bootstrap";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CalendarEventModal from "../Planner/CalendarEventModal";
import InputDateRangePicker from "../../components/common/InputDateRangePicker";
import moment from "moment";
import constants from "../../constants/constants";
import { getMyTeamPreviousTask } from "../../services/task.service";
import Loader from "../../components/common/Loader";

const TaskInProgress = () => {
  const [data, setData] = useState({} as any);
  const [loading, setLoading] = useState(true);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState({} as any);
  const [orderDateRange, setOrderDateRange] = useState("");

  useEffect(() => {
    fetchData();
  }, [orderDateRange]);
  const fetchData = () => {
    getMyTeamPreviousTask(orderDateRange)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleEventModal = (row: any) => {
    const data = {
      event: {
        extendedProps: row,
        title: row.title,
        end: row.end_date,
        start: row.start_date,
        project : row.project
      },
    };

    setModalEventContent(data);
    setIsOpenEventModal(true);
  };
  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S. No.",
      sortable: false,
      filterable: true,
      hideable: false,
      width: 40,
    },
    {
      field: "title",
      headerName: "Task Name",
      flex: 1,
      filterable: true,
    },
    {
      field: "assigned_to",
      headerName: "Assigned To",
      flex: 1,
      renderCell: (params) => {
        return <div>{params.row.assignee.full_name}</div>;
      },
      valueGetter: (params) => {
        return params.row.assignee.full_name;
      }

    },
    {
      field: "job_type",
      headerName: "Task Type",
      flex: 1,
      filterable: true,
    },
    {
      field: "project",
      headerName: "Project Order No",
      flex: 1,
      filterable: true,
      renderCell: (params) => {
        return (
          <div title={params.row.project.project_no_str}>
            {params.row.project.project_no_str}
          </div>
        );
      },
      valueGetter: (params) => {
        return params.row.project.project_no_str;
      }
      
    },
    {
      field: "start_date",
      headerName: "Task Date",
      filterable: true,
      flex: 1,
    },
    {
      field: "start_time",
      headerName: "Task Time",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {moment(params.row.start_date + " " + params.row.start_time).format(
              "hh:ss A"
            )}{" "}
            -{" "}
            {moment(params.row.end_date + " " + params.row.end_time).format(
              "hh:ss A"
            )}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Task Status",
      flex: 1,
      renderCell(params) {
        if(params.row.status){
          const className = params.row.status.toLowerCase().replace(/\s+/g, '-');
          return <span className={`asset-status-${className}`}>{params.row.status}</span>
        }else{
          return '-';
        }
      }
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            <a
              href="javascript:void(0)"
              onClick={() => handleEventModal(params.row)}
              className="text-primary"
            >
              <VisibilityIcon />
            </a>
          </div>
        );
      },
    },
  ];

  const DateFilter = (data: any) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(
        constants.dateFormats.databaseFormat
      );
      const endDate = moment(dataRange[1]).format(
        constants.dateFormats.databaseFormat
      );
      setOrderDateRange(`${startDate},${endDate}`);
    } else {
      setOrderDateRange("");
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-semibold h4 my-2">Previous Task</h1>
              </div>
              <div className="mt-2">
              <InputDateRangePicker
                controlId="order_date"
                label=""
                placeholder="Select Date Range"
                touched={false}
                handleBlur={false}
                handleChange={DateFilter}
                handleClose={DateFilter}
                errorsField={false}
                value={orderDateRange}
                position="bottomEnd"
              />
            </div>
               
            </div>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
              <Card className="p-0 w-100 ">
                <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
                  <DataGrid
                    rows={
                      data.length
                        ? data.map((row: any, index: number) => ({
                            ...row,
                            serialNumber: index + 1,
                          }))
                        : []
                    }
                    columns={columns}
                    pageSizeOptions={[5]}
                    rowSelection={false}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      <CalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        modalEventContent={modalEventContent}
        onlyEmployeeView={true}
      />
    </React.Fragment>
  );
};

export default TaskInProgress;
