import { Image } from 'react-bootstrap'
import Logo from '../../../assets/images/logo.svg';
import { Link } from 'react-router-dom';


export const AuthHeader = () => {
    return (
        <>
            <Link to="/"><Image className="img-fluid text-blue" src={Logo} alt="Logo" width={185} height={38} /></Link>
            
        </>
    )
}
