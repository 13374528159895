import { axios } from "../services/axios";
import {  
  VENDOR_LIST,
  CREATE_VENDOR,
  UPDATE_VENDOR,  
  UPLOAD_VENDOR_IMAGES,
  UPDATE_VENDOR_STATUS,
  VENDOR_DELETE,
  GET_ALL_VENDOR_NAMES,
  VENDOR_RELATED_PRODUCTS,
  VENDOR_PO_HISTORY,
  VENDOR_EXPORT
} from "../constants/paths";



export const getVendorList = (formData: any): Promise<any> => {
  return axios.get(VENDOR_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};


export const createVendorApi = (data: any): Promise<any> => {
  const transformData = {
    ...data,
    vendor_type: data?.vendor_type?.key,
    credit_terms: data?.credit_terms?.key
  };
  
  return axios.post(CREATE_VENDOR, transformData);
};

/**
 * @param {id: string } params
 * @returns VENDOR details
 */
export const getVendorDetailsApi = (id: string): Promise<any> => {
  return axios.get(VENDOR_LIST + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateVendorApi = (id: string, data: any): Promise<any> => {
  const transformData = {
    ...data,
    vendor_type: data?.vendor_type?.key,
    credit_terms: data?.credit_terms?.key    
  };
  return axios.put(UPDATE_VENDOR + "/" + id, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteVendorApi = (id: string | number | null): Promise<any> => {
  return axios.delete(VENDOR_DELETE + "/" + id);
};





export const uploadVendorImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${UPLOAD_VENDOR_IMAGES}`, data, config);
};

export const updateVendorStatusApi =(id: string, data: any): Promise<any> => {
  const transformData = {
    status : data.status=='active' ? 'inactive':'active'
  };
  return axios.patch(UPDATE_VENDOR_STATUS + "/" + id, transformData);
};

export const getAllVendorsName = (): Promise<any> => {
  return axios.get(GET_ALL_VENDOR_NAMES);
};

export const getVendorRelatedItemsList = (vendorId: string | number | undefined,formData: any): Promise<any> => {
  return axios.get(`${VENDOR_RELATED_PRODUCTS}/${vendorId}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      name:formData.name,
      category:formData.category,
      qty:formData.qty,
      
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const getVendorPoHistoryList = (vendorId: string | number | undefined,formData: any): Promise<any> => {
  return axios.get(`${VENDOR_PO_HISTORY}/${vendorId}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      date: formData.date,
      
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const exportVendorData = (params:any = {}) : Promise<any> => {
  return axios.get(VENDOR_EXPORT,{params,responseType: 'arraybuffer',});
}