import { Route, Routes } from "react-router-dom";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { ApplyLeave } from "./Add";
import EmployeeLeaveHistoryList from "./My-Leaves";
import ViewLeaveDetail from "./View/view";
import LeavesList from "./index";
import LeavesHistoryList from "./leaveHistory";

export const Leaves = () => {
  return (
    <Routes>
      <Route path="/leave-history" element={<EmployeeLeaveHistoryList />} />
      <Route path="/apply" element={<ApplyLeave onClose={function (isReload?: boolean | undefined): void {
        throw new Error("Function not implemented.");
      } } />} />
      <Route path="/view/:id/:empId?" element={<ViewLeaveDetail />} />
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.LEAVES) && (
        <Route path="/" element={<LeavesList />} />
      )}
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.LEAVES) && (
        <Route path="/history" element={<LeavesHistoryList />} />
      )}
     {/*  {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.LIBRARY) && (
        <Route path="/add" element={<LibraryAdd />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.LIBRARY) && (
        <Route path="/edit/:id" element={<LibraryAdd />} />
      )} */}
    </Routes>
  );
};
