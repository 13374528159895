import React, { useEffect, useState, FC } from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, getIn } from "formik";
import { profileChangeRequestValidation } from "./validation";
import { toast } from "react-toastify";
import InputText from "../../components/common/InputText";
import { Card, Col, Image, Row } from "react-bootstrap";
import {
  getProfileUpdateRequest,
  profileChangeRequestAction,
} from "../../services/employeeService";
import Loader from "../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import FormSelect from "../../components/common/FormSelect";
import {
  EmployeeDataType,
  EmployeeProfileChangeRequest,
  profileChangeFieldsLabel,
} from "../Profile/profiledata.type";
import {
  allowedFileTypes,
  maxFileSizeMB,
  relatioinOptions,
} from "../../constants/constants";
import FileUploader from "../../components/common/FileUploader";
import {
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
} from "../../validations/ValidationErrors";
import CustomButtonCancel from "../../components/common/CustomButtonCalcel";
import { selectAuthentication } from "../../features/Auth/authSlice";
import CloseIcon from '@mui/icons-material/Close';
import UserPlaceholderImage from "../../assets/images/user-placeholder-image.png";

interface pendingField {
  label: string;
  value: string;
}

// This component render the fields which are pending for approval
const PendingVerficationField: FC<pendingField> = ({ label, value }) => {
  return (
    <div className="mb-3 custom-from-group">
      <label className="lh-sm control-label fs-14 fw-bold form-label">
        {label}
      </label>
      <div className="position-relative ">
        <p className="fs-14">{`${value} (Verification Pending)`}</p>
      </div>
    </div>
  );
};

const View: FC = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<any>({}); // State which will contain the employee's existing profile details
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [profileUpdateReqData, setProfileUpdateReqData] =
    useState<EmployeeProfileChangeRequest>({}); // State which will contain employee's profile change request details
  const [validationSchema, setValidationSchema] = useState<any>({});
  const { user }: any = useSelector(selectAuthentication);
  const navigate = useNavigate();

  let { id } = useParams();

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (
    values: EmployeeDataType,
    { setSubmitting }: any
  ) => {
    const formData = {
      ...values,
      action: "1",
    };

    setBtnLoading(true);
    if (id) {
      profileChangeRequestAction(id, formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/profile-change-requests");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (id) {
      getEmployeeDetails(id);
    } else {
      setLoading(false);
    }
  }, []);

  const getEmployeeDetails = (id: string) => {
    setLoading(true);
    getProfileUpdateRequest(id)
      .then((response) => {
        setEmployeeData(response.data.employeeProfileExisting);
        const employeeProfileUpdate = response.data.employeeProfileUpdate;

        if (employeeProfileUpdate?.Kin_Details?.keen_relation1) {
          employeeProfileUpdate.Kin_Details.keen_relation1 = {
            value: employeeProfileUpdate.Kin_Details.keen_relation1,
            key: employeeProfileUpdate.Kin_Details.keen_relation1,
          };
        }
        if (employeeProfileUpdate?.Kin_Details?.keen_relation2) {
          employeeProfileUpdate.Kin_Details.keen_relation2 = {
            value: employeeProfileUpdate.Kin_Details.keen_relation2,
            key: employeeProfileUpdate.Kin_Details.keen_relation2,
          };
        }

        setProfileUpdateReqData(response.data.employeeProfileUpdate);
        if (response.data.employeeProfileUpdate.Employ_details.user_photo) {
          setImagePreview(
            response.data.employeeProfileUpdate.Employ_details.user_photo_url
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadProfileImage = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      // Check file type
      if (!allowedFileTypes.includes(imageFile.type)) {
        toast(FILE_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
        toast(FILE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        setFieldValue("Employ_details.user_photo", imageFile);
        if (imageFile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(imageFile);
        } else {
          setImagePreview(null);
        }
      }
    }
  };

  // This use effect will update the validation schema whenever any field will get deleted. If the field gets removed from the form, the corresponding schema should also get removed
  useEffect(() => {
    const schema = profileChangeRequestValidation(profileUpdateReqData);
    setValidationSchema(schema);
  }, [profileUpdateReqData]);

  /**
   * @description This is the remove handler. Whenever a field will be removed in the form, the ProfileUpdateReqData state will get updated to rerender the form with the remaining fields.
   * @param {key: Model Key, subkey: Field Key, updatedValues: Current visible fields along with values }
   * @returns Promise<any>
   */
  const handleRemoveField = (
    key: string,
    subkey: string,
    updatedValues: EmployeeProfileChangeRequest
  ) => {
    const allFormikFields: EmployeeDataType = {
      ...updatedValues.users,
      ...updatedValues.Employ_details,
      ...updatedValues.Kin_Details,
      ...updatedValues.Employee_Bank_Details,
    };
    delete allFormikFields.user_photo_url;
    // If only one field left, it should not get deleted.
    if (Object.keys(allFormikFields).length === 1) {
      toast(
        "Error: At least one field should be present to approve a profile change",
        {
          type: toast.TYPE.ERROR,
        }
      );
    } else {
      const newProfileData = JSON.parse(JSON.stringify(updatedValues));
      delete newProfileData[key][subkey];
      setProfileUpdateReqData(newProfileData);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        Object.keys(profileUpdateReqData).length > 0 && (
          <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
            <div className="pageHeader px-3 py-2 my-1">
              <Row className="align-items-center">
                <Col>
                  <h1 className="fw-bold h4 my-2">
                    Compare & Approve Changes 
                    <span className="py-1 px-2 rounded-1 Onboarding-tag d-inline-block fs-14">Employee Name:{" "} {employeeData?.users?.full_name}</span>
                  </h1>
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
              <Formik
                validationSchema={validationSchema}
                enableReinitialize={true}
                initialValues={profileUpdateReqData}
                onSubmit={(values: any, actions) => {
                  submitForm(values, actions);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldError,
                  setFieldValue,
                }) => (
                  <Form className="" noValidate onSubmit={handleSubmit}>
                    {/* {errors.users && <div className="error">{errors.users.phone_no}</div>} */}
                    <Row>
                          <Col className="col-md-6">
                            <Card className="p-3 w-100 pageContentInner mb-3 rounded-2">
                              <Row>
                                  
                                    <div className="col-md-12">
                                      <h3 className="fs-20 info-text fw-semibold mb-3">Employee Current Details</h3>
                                    </div>
                                      {" "}
                                      {/* This column contains contain employee's existing profile data */}
                                      <div className="col-md-12">
                                        {Object.keys(profileUpdateReqData).map(
                                          (key: string) =>
                                            Object.keys(profileUpdateReqData[key]).map(
                                              (subkey: string) => {
                                                const value = employeeData?.[key]?.[
                                                  subkey
                                                ]
                                                  ? employeeData[key][subkey]
                                                  : "";
                                                return (
                                                  <Row key={subkey}>
                                                    <div className="col-md-12">
                                                      <div className="mb-3 custom-from-group">
                                                        <label className="lh-sm control-label fs-14 fw-bold form-label">
                                                          {
                                                            profileChangeFieldsLabel[
                                                              subkey
                                                            ]
                                                          }
                                                        </label>
                                                        <div className="position-relative">
                                                          {subkey === "user_photo" &&
                                                            (employeeData[key][
                                                              "user_photo_url"
                                                            ] ? (
                                                              <div className="file-box">
                                                                <span>
                                                                <Image
                                                                  className="img-fluid"
                                                                  src={
                                                                    employeeData[key][
                                                                      "user_photo_url"
                                                                    ]
                                                                  }
                                                                  alt="Employee Image"
                                                                />
                                                                </span>
                                                              </div>
                                                            ) : (
                                                              <div className="file-box">
                                                                <span>
                                                                <Image
                                                                  className="img-fluid"
                                                                  src={UserPlaceholderImage}
                                                                  alt="No Image"
                                                                />
                                                                </span>
                                                              </div>
                                                            ))}
                                                          {subkey !== "user_photo" &&
                                                            subkey !==
                                                              "user_photo_url" && (
                                                              <input
                                                                className="form-control"
                                                                type="text"
                                                                value={value}
                                                                readOnly
                                                                disabled={true}
                                                              />
                                                            )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                );
                                              }
                                            )
                                        )}
                                      </div>
                                    
                                </Row>
                            </Card>
                          </Col>
                          <Col className="col-md-6">
                            <Card className="p-3 w-100 pageContentInner mb-3 rounded-2">
                              <div>
                                <Row>
                                    <div className="col-md-12">
                                        <h3 className="fs-20 text-green fw-semibold mb-3">Employee Change Request</h3>
                                    </div>
                                    {" "}
                                    {/* This column contains contain employee's profile change request data */}
                                    <div className="col-md-12">
                                      {Object.keys(profileUpdateReqData).map(
                                        (key: string) =>
                                          Object.keys(profileUpdateReqData[key]).map(
                                            (subkey: string) => {
                                              return (
                                                <Row key={subkey}>
                                                  <div className="col-md-12 profile-request-chnage-input-group">
                                                    {(subkey === "keen_relation1" ||
                                                      subkey === "keen_relation2") && (
                                                      <FormSelect
                                                        placeholder={
                                                          profileChangeFieldsLabel[
                                                            subkey
                                                          ]
                                                        }
                                                        label={
                                                          profileChangeFieldsLabel[
                                                            subkey
                                                          ]
                                                        }
                                                        name={`${key}.${subkey}`}
                                                        options={relatioinOptions}
                                                        getOptionLabel={(option: any) =>
                                                          option.value
                                                        }
                                                        getOptionValue={(option: any) =>
                                                          option.key
                                                        }
                                                        onChange={(
                                                          name: string,
                                                          value: string
                                                        ) => {
                                                          setFieldValue(name, value);
                                                        }}
                                                        value={values[key][subkey]}
                                                        error={getIn(
                                                          errors,
                                                          `${key}.${subkey}`
                                                        )}
                                                        touched={getIn(
                                                          touched,
                                                          `${key}.${subkey}`
                                                        )}
                                                      />
                                                    )}

                                                    {subkey !== "keen_relation1" &&
                                                      subkey !== "keen_relation2" &&
                                                      subkey !== "user_photo" &&
                                                      subkey !== "user_photo_url" && (
                                                        <InputText
                                                          controlId={`${key}.${subkey}`}
                                                          label={
                                                            profileChangeFieldsLabel[
                                                              subkey
                                                            ]
                                                          }
                                                          placeholder={
                                                            profileChangeFieldsLabel[
                                                              subkey
                                                            ]
                                                          }
                                                          touched={getIn(
                                                            touched,
                                                            `${key}.${subkey}`
                                                          )}
                                                          handleBlur={handleBlur}
                                                          handleChange={handleChange}
                                                          errorsField={getIn(
                                                            errors,
                                                            `${key}.${subkey}`
                                                          )}
                                                          value={values[key][subkey]}
                                                        />
                                                      )}

                                                    {subkey === "user_photo" && (
                                                      <><label className="lh-sm control-label fs-14 fw-bold form-label">
                                                      {
                                                        profileChangeFieldsLabel[
                                                          subkey
                                                        ]
                                                      }
                                                    </label>
                                                      <FileUploader
                                                        uploadProfileImage={(e) =>
                                                          uploadProfileImage(
                                                            e,
                                                            setFieldValue
                                                          )
                                                        }
                                                        imageLoading={imageLoading}
                                                        imagePreview={imagePreview}
                                                      />
                                                      </>
                                                    )}
                                                  </div>
                                                  {subkey !== "user_photo_url" && (
                                                    <div className="col-auto">
                                                      <button
                                                        className="fw-semibold fs-13 text-white profile-danger-btn"
                                                        type="button"
                                                        onClick={() =>
                                                          handleRemoveField(
                                                            key,
                                                            subkey,
                                                            values
                                                          )
                                                        }
                                                      >
                                                        <CloseIcon className="text-danger" />
                                                      </button>
                                                    </div>
                                                  )}
                                                </Row>
                                              );
                                            }
                                          )
                                      )}
                                    </div>
                                  
                                </Row>
                              </div>
                            </Card>
                          </Col>
                      </Row>
                    

                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <CustomButtonCancel label="Cancel" />

                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            className="fw-semibold fs-13 text-white mt-2"
                            variant="primary"
                          >
                            Update
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )
      )}
    </React.Fragment>
  );
};

export default View;
