import Accordion from "react-bootstrap/Accordion";
import Ipayment from "../../assets/images/plannerImg/Ipayment.svg";
import Fpayment from "../../assets/images/plannerImg/Fpayment.svg";
import FirstDay from "../../assets/images/plannerImg/firstDay.svg";
import LastDay from "../../assets/images/plannerImg/lastDay.svg";
import SurveyDate from "../../assets/images/plannerImg/survetDate.svg";
import Leave from "../../assets/images/plannerImg/leave.svg";
import DynamicClassComponent from "./DynamicClassComponent";
import EditIcon from "@mui/icons-material/Edit";
import { IoMdAddCircle } from "react-icons/io";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { useEffect, useState } from "react";
import TeamMemeberShowToList from "./TeamMemeberShowToList";
import {
  getEmployeeUnderPM,
  getMyTeamInProgressTask,
  getProjectAssignTeamAndDetailsAPi,
} from "../../services/task.service";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../features/Auth/authSlice";

function SideEvents({
  teamview,
  onlyEmployeeView,
  selectedContract,
  setCheckedMembers,
  checkedMembers,
  setIssidebarLoaded,
  setSelectedTeamMembers,
  selectedTeamMembers
}) {
  const { user } = useSelector(selectAuthentication);

  const [allUsers, setAllUser] = useState([]);
  const [assignTeamPopupOpen, setAssignTeamPopupOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
 // const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const designation = user?.user?.employeeDetails?.designation
    ? user?.user?.employeeDetails?.designation
    : "Super Admin";

  const [isMutation, setIsMutation] = useState(false);

  const handleEditTeam = () => {
    const projectIds = [
      {
        id: selectedContract,
      },
    ];
    setSelectionModel(projectIds);
    setAssignTeamPopupOpen(true);
  };
  const handleDetailReload = () => {
    setAssignTeamPopupOpen(false);
    setSelectionModel([]);
    setIsMutation(!isMutation);
  };

  useEffect(() => {
    if (!onlyEmployeeView) {      
      getEmployeeUnderProjectManager();
    }else{
      setIssidebarLoaded(true)
    }
  }, []);

  const getEmployeeUnderProjectManager = () => {
    getEmployeeUnderPM()
      .then((response) => {
        console.log("yt tata", response.data)
        setSelectedTeamMembers(response.data);
          setCheckedMembers(
            response.data.map((item) => item.user_id)
          );
          setIssidebarLoaded(true)
      })
      .catch((error) => {
        console.log("e", error);
      });
  };

  useEffect(() => {
    if (selectedContract) {
      getTeam();
    }
  }, [selectedContract]);
  const getTeam = () => {
    /*   setLoading(true); */
    return new Promise((resolve, reject) => {
      getProjectAssignTeamAndDetailsAPi(selectedContract)
        .then((response) => {
          console.log("response", response);
          setSelectedTeamMembers(response.data.project_members);
          setCheckedMembers(
            response.data.project_members.map((item) => item.user_id)
          );
          resolve(true);
        })
        .catch((error) => {
          /*  setLoading(false);   */
          reject(error);
        });
    });
  };

  return (
    <>
      <Accordion
        defaultActiveKey={["0", "1", "2"]}
        alwaysOpen
        className="bg-white planner-accordion"
      >
        
        {(!onlyEmployeeView || designation === "HR Administration") && (
          <Accordion.Item eventKey="0">
            <Accordion.Header className="border-0 fs-14 fw-bold">
              Team Member{" "}
            </Accordion.Header>
           {/*  {checkAbility(
              PERMISSION_ACCESS.EDIT,
              PERMISSION_MODULE.PROJECTS
            ) && ( */}
              <span onClick={handleEditTeam} className="editTeamMember">
                {selectedContract ? (
                  <EditIcon className="text-primary fs-18" />
                ) : (
                  <IoMdAddCircle className="text-primary fs-18" />
                )}
              </span>
          {/*   )} */}

            <Accordion.Body>
              <div className="teamMemberBos">
                <ul className="list-unstyled">
                  {selectedTeamMembers.map((member) => (
                    <li
                      key={member.id}
                      className="d-flex"
                      for={`default-checkbox`}
                    >
                      {/*  {teamview && (
                    <DynamicClassComponent
                      className={`with-circle bg-event-${member?.user?.id}`}
                    ></DynamicClassComponent>
                  )} */}
                      <Form.Check
                        type="checkbox"
                        id={`default-checkbox`}
                        className="me-2"
                        checked={checkedMembers.includes(member?.user_id)}
                        onChange={(e) => {
                          setCheckedMembers((prevState) => {
                            if (e.target.checked) {
                              return [...prevState, member?.user_id];
                            } else {
                              return prevState.filter(
                                (item) => item !== member?.user_id
                              );
                            }
                          });
                        }}
                      />
                      {`${member?.full_name ? member?.full_name : member?.user?.full_name}`}
                    </li>
                  ))}
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
        <Accordion.Item eventKey="1">
          <Accordion.Header className="border-0 fs-14 fw-bold">
            Events
          </Accordion.Header>
          <Accordion.Body>
            <ul className="list-unstyled ps-0">
              {!onlyEmployeeView && (
                <>
                  {" "}
                  <li>
                    <img src={Ipayment} width="13" className="me-2" />
                    Interim Payment Date
                  </li>
                  <li>
                    <img src={Fpayment} width="13" className="me-2" />
                    Final Payment Date
                  </li>
                  <li>
                    <img src={FirstDay} width="13" className="me-2" />
                    First Day
                  </li>
                  <li>
                    <img src={LastDay} width="13" className="me-2" />
                    Last Day
                  </li>
                  <li>
                    <img src={SurveyDate} width="13" className="me-2" />
                    Survey Date
                  </li>{" "}
                </>
              )}
              <li>
                <img src={Leave} width="13" className="me-2" />
                Leave
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header className="border-0 fs-14 fw-bold">
            Tasks
          </Accordion.Header>
          <Accordion.Body>
            <ul className="list-unstyled ps-0">
              <li>
                <span className="planner-task-color-circle task-color-light"></span>
                Projects
              </li>
              <li>
                <span className="planner-task-color-circle task-color-orange"></span>
                Electrician
              </li>
              <li>
                <span className="planner-task-color-circle task-color-pink"></span>
                EA Install Floorer
              </li>
              <li>
                <span className="planner-task-color-circle task-color-green"></span>
                Sub - Contract Floorer
              </li>
              <li>
                <span className="planner-task-color-circle task-color-purple"></span>
                Remedial Works
              </li>

              <li>
                <span className="planner-task-color-circle task-color-blue"></span>
                National Holiday
              </li>
              {!onlyEmployeeView && (
                <li>
                  <span className="planner-task-color-circle task-color-meeting"></span>
                  Meeting
                </li>
              )}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {assignTeamPopupOpen && (
        <TeamMemeberShowToList
          isOpen={assignTeamPopupOpen}
          onClose={handleDetailReload}
          projectsData={selectionModel}
          selectedTeamMembers={selectedTeamMembers}
          setSelectedTeamMembers={setSelectedTeamMembers}
          setCheckedMembers={setCheckedMembers}
          setIsMutation={setIsMutation}
          isMutation={isMutation}
          
        />
      )}
    </>
  );
}

export default SideEvents;
