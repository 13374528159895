import { maxFileSizeMB, formTemplateMaxFileSizeMB } from "../constants/constants";

export const USER_NAME_REQUIRED = "Username is required.";
export const EMAIL_REQUIRED = "Email address is required.";
export const PASSWORD_REQUIRED = "Password is required.";
export const CONFIRM_PASSWORD_REQUIRED = "Confirm new password is required.";
export const MINIMUM_15_CHAR = "Must be a minimum of 15 characters.";
export const MINIMUM_6_CHAR = "Must be a minimum of 6 characters."
export const MINIMUM_2_CHAR = "Must be a minimum of 2 characters."
export const MAXIMUM_15_CHAR = "Can not be a maximum than 15 characters.";
export const MAXIMUM_30_CHAR = "Can not be a maximum than 30 characters.";
export const MAXIMUM_50_CHAR = "Can not be a maximum than 50 characters.";
export const MAXIMUM_100_CHAR = "Can not be a maximum than 100 characters.";
export const MAXIMUM_200_CHAR = "Can not be a maximum than 200 characters.";
export const MAXIMUM_255_CHAR = "Can not be a maximum than 255 characters.";
export const MAXIMUM_500_CHAR = "Can not be a maximum than 500 characters.";
export const MINIMUM_3_CHAR = "Must be a minimum of 3 characters.";
export const PASSWORD_REGEX = "Your password must have 6 to 32 characters containing 1 upper case letter, 1 lower case letter, 1 special character & 2 digits with no blank spaces.";
export const PASSWORD_MUST_MATCH = "Confirm password must match with new password.";
export const VALID_EMAIL = "Email must be a valid email.";
export const SITE_CODE_REQUIRED = "Site code is required.";
export const SITE_NAME_REQUIRED = "Site name is required.";
export const CONTACT_FIRST_NAME_REQUIRED = "Person first name is required.";
export const CONTACT_LAST_NAME_REQUIRED = "Person last name is required.";
export const PHONE_NO_REQUIRED = "Phone no is required.";
export const CHAR_MAX_10 = "Can exeed upto maximum 10 characters.";
export const CHAR_MAX_20 = "Can exeed upto maximum 20 characters.";
export const CHAR_MAX_50 = "Can exeed upto maximum 50 characters.";
export const CHAR_MAX_300 = "Can exeed upto maximum 300 characters.";
export const PHONE_NUM_LENGTH = "Phone no should have 10 characters.";
export const EMPLOYEE_CODE_REQUIRED = "Employee code is required.";
export const SITES_CODE_REQUIRED = "Sites selection is required.";
export const REQUIRED = "Field is Required.";
export const NUMERIC_10 = "Should be less than 10";
export const NUMERIC_PERCENTAGE = "Should be valid percentage";
export const CAPTCH_INVALID = "Please Enter Captcha";
export const ROLE_REQUIRED = "Role Title is required."
export const ROLE_DESC_REQUIRED = "Role Description is required."
export const FILE_TYPE_ERROR = 'Only PNG, JPG, JPEG, and SVG file types are allowed.'
export const IMPORT_FILE_TYPE_ERROR = 'Only xls and .xlsx file types are allowed.'
export const FILE_SIZE_ERROR = `File size must be less than ${maxFileSizeMB} MB.`
export const MIN_ONE_ROLE_REQUIRE = 'At least one role is required'
export const ALPHABETS_ALLOW='Only alphabets are allowed'
export const ALPHA_NUMERIC_ALLOW='Only alphabets and numeric are allowed'
export const FORMBUILDER_FINISH = "Form has been completed."
export const FORM_TEMPLATE_TYPE_ERROR = 'Only PDF and Word files are allowed.'
export const DOCUMENT_TYPE_ERROR = 'Only PDF, Images and Word files are allowed.'
export const PDF_TYPE_ERROR = 'Only PDF files are allowed.'
export const FORM_TEMPLATE_SIZE_ERROR = `File size must be less than ${formTemplateMaxFileSizeMB} MB.`
export const ONLY_NUMBER_ALLOW = "Only numbers are allowed."
export const SELECT_ONE_USER_IN_RECEIPIENT_LIST = 'Please select at least one employee in CC recipients'
export const ASSET_ALLOCATION_NOT_ALLOWED='Pending or Rejected assets cannot be allocated'
export const INVALID_DATE = 'Please enter valid date'
export const FILE_LIMIT_10 = `You can't add more than 10 FILES.`
export const SOMETHING_WENT_WRONG = "Something went wrong. Please try again later."
export const SIGNATURE_UPLOADED = "Signature Uploaded Successfully"
export const VALID_QUANTITY = "Quantity should be greater than 0 and less than 1000"
export const CAPITAL_LETTERS = 'Only capital letters are allowed'
export const SELECT_ONE_OPTION='Please select one option'
