import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  MIN_ONE_ROLE_REQUIRE,
  ALPHABETS_ALLOW,
  ALPHA_NUMERIC_ALLOW,
  INVALID_DATE
} from "../../../validations/ValidationErrors";
import { ONLY_ALPHABET, URL_REGEX, ONLY_ALPHANUMERIC } from "../../../validations/regex";
export const dealSchema = yup.object({
  order_no: yup
    .string()    
    .trim()    
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .required()
    .label("Order No."),
  name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .required()
    .label("Customer Name"),
  contract_type: yup.object().required().label("Contract Type"),
  status: yup.object().required().label("Status"),
  order_date: yup
    .date()
    .required()
    .typeError(INVALID_DATE)
    .label("Order Date"),
  amount: yup
    .number()
    .positive()
    .typeError("Order Value must be a number")
    .required()
    .label("Order Value"),
  project_type: yup.object().required().label("Project Type"),
  address: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Address"),
  postal_code: yup.object().required().label("Postal Code"),
  phone: yup
    .number()
    .required()
    .typeError("Phone number must be a number")
    .max(999999999999999, "Phone number must be at most 15 digits") // Maximum 15 digits
    .label("Phone No"),
  contract_signee: yup
    .string()
    .optional()
    .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Contract Signee"),

  payment_deposited_by: yup
    .string()
    .optional()
    .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Payment Deposited BY"),
  contract_date: yup
    .date()
    .nullable()
    .typeError(INVALID_DATE)
    .label("Contract Date"),
  payment_date: yup
    .date()
    .nullable()
    .label("Payment Date")
    .typeError(INVALID_DATE),
    paid_amount: yup
    .number()
    .positive()
    .max(yup.ref('amount'), 'Deposit Amount must be less than order value')
    .typeError("Paid Amount must be a number")
    .optional()
    .label("Paid Amount"),
});
