import * as yup from "yup";
import { 
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  MIN_ONE_ROLE_REQUIRE,
  ALPHABETS_ALLOW
} from "../../../validations/ValidationErrors";
import { ONLY_ALPHABET, URL_REGEX } from "../../../validations/regex";
export const addSchema = yup.object({
  site_name : yup.string().matches(ONLY_ALPHABET, ALPHABETS_ALLOW).trim().min(3,MINIMUM_3_CHAR).max(30,MAXIMUM_30_CHAR).required().label('Site Name'),
  site_url: yup.string().trim().matches(URL_REGEX,
    'Enter correct url!'
).required().min(3,MINIMUM_3_CHAR).max(30,MAXIMUM_30_CHAR).label("Site URL"),
  site_icon :  yup.string().trim().required().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).label("Site icon"),
  role : yup.array().min(1,MIN_ONE_ROLE_REQUIRE).required().label("Role"),
});
