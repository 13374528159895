import React from "react";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';

interface AddProps {
    label: string;
    emotion_options: [];
    colleague_comment: string;
  }

const ViewObjectEmotions: React.FC<AddProps> = ({ label,emotion_options, colleague_comment }) => {
    return (
        <React.Fragment>
            <div className="d-flex justify-content-between">
                <p className="fs-16 fw-bold text-black mb-0">{label}</p>
                <p className="mb-0 view-obj-icon">
                {emotion_options.map((emotionKey:string) => {
                    if(emotionKey === 'happy'){
                        return <svg className={colleague_comment && colleague_comment === emotionKey ? 'emotionHighlight' : ''} width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2498 15C30.2498 23.2843 23.5154 30 15.2081 30C6.90088 30 0.166504 23.2843 0.166504 15C0.166504 6.71573 6.90088 0 15.2081 0C23.5154 0 30.2498 6.71573 30.2498 15ZM12.5989 13.7117C12.5989 15.0325 11.5252 16.1031 10.2008 16.1031C8.87639 16.1031 7.80273 15.0325 7.80273 13.7117C7.80273 12.391 8.87639 11.3203 10.2008 11.3203C11.5252 11.3203 12.5989 12.391 12.5989 13.7117ZM22.6142 13.7117C22.6142 15.0325 21.5406 16.1031 20.2162 16.1031C18.8917 16.1031 17.8181 15.0325 17.8181 13.7117C17.8181 12.391 18.8917 11.3203 20.2162 11.3203C21.5406 11.3203 22.6142 12.391 22.6142 13.7117ZM15.2073 22.8504C12.1175 22.85 10.5723 20.4415 10.508 20.3386C10.3095 20.022 10.4064 19.6056 10.7231 19.4077C11.0398 19.2106 11.4566 19.3056 11.655 19.6199C11.7066 19.6998 12.8862 21.4991 15.2073 21.4991C17.5283 21.4991 18.7532 19.6302 18.7651 19.6112C18.9699 19.2985 19.3898 19.2106 19.7025 19.4148C20.0161 19.6191 20.1042 20.0378 19.8994 20.3497C19.8327 20.4518 18.2279 22.85 15.2073 22.8504ZM15.2073 22.8504C15.207 22.8504 15.2067 22.8504 15.2065 22.8504H15.2081C15.2078 22.8504 15.2075 22.8504 15.2073 22.8504Z" fill="#DDDDDD" fill-opacity="0.866667"/>
                        </svg>
                    //return <SentimentSatisfiedAltIcon className={colleague_comment && colleague_comment === emotionKey ? 'emotionHighlight' : ''}/>
                    } else if(emotionKey === 'neutral'){
                        return <svg className={colleague_comment && colleague_comment === emotionKey ? 'emotionHighlight' : ''} width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.1665 15C30.1665 23.2843 23.4322 30 15.1249 30C6.81762 30 0.083252 23.2843 0.083252 15C0.083252 6.71573 6.81762 0 15.1249 0C23.4322 0 30.1665 6.71573 30.1665 15ZM12.5149 14.9971C12.5149 16.3178 11.4413 17.3885 10.1169 17.3885C8.79246 17.3885 7.71881 16.3178 7.71881 14.9971C7.71881 13.6764 8.79246 12.6057 10.1169 12.6057C11.4413 12.6057 12.5149 13.6764 12.5149 14.9971ZM22.5311 14.9971C22.5311 16.3178 21.4574 17.3885 20.133 17.3885C18.8086 17.3885 17.7349 16.3178 17.7349 14.9971C17.7349 13.6764 18.8086 12.6057 20.133 12.6057C21.4574 12.6057 22.5311 13.6764 22.5311 14.9971ZM19.2503 22.8484H10.9996C10.6257 22.8484 10.3217 22.546 10.3217 22.1723C10.3217 21.7987 10.625 21.4963 10.9996 21.4963H19.2503C19.6242 21.4963 19.9282 21.7987 19.9282 22.1723C19.9282 22.546 19.625 22.8484 19.2503 22.8484Z" fill="#DDDDDD" fill-opacity="0.866667"/>
                        </svg>
                    //return <SentimentNeutralIcon className={colleague_comment && colleague_comment === emotionKey ? 'emotionHighlight' : ''}/>
                    } else if(emotionKey === 'sad'){
                        return <svg className={colleague_comment && colleague_comment === emotionKey ? 'emotionHighlight' : ''} width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0833 15C30.0833 23.2843 23.3489 30 15.0416 30C6.73437 30 0 23.2843 0 15C0 6.71573 6.73437 0 15.0416 0C23.3489 0 30.0833 6.71573 30.0833 15ZM12.4317 13.7117C12.4317 15.0325 11.3581 16.1031 10.0337 16.1031C8.70927 16.1031 7.63562 15.0325 7.63562 13.7117C7.63562 12.391 8.70927 11.3203 10.0337 11.3203C11.3581 11.3203 12.4317 12.391 12.4317 13.7117ZM22.4479 13.7117C22.4479 15.0325 21.3742 16.1031 20.0498 16.1031C18.7254 16.1031 17.6518 15.0325 17.6518 13.7117C17.6518 12.391 18.7254 11.3203 20.0498 11.3203C21.3742 11.3203 22.4479 12.391 22.4479 13.7117ZM19.1679 22.8503C18.9432 22.8503 18.7226 22.7386 18.594 22.5352C18.5424 22.4552 17.3628 20.6559 15.0417 20.6559C12.7207 20.6559 11.4958 22.5249 11.4839 22.5439C11.2791 22.8566 10.8592 22.9445 10.5465 22.7402C10.2329 22.536 10.1448 22.1172 10.3496 21.8053C10.4163 21.7032 12.0213 19.3047 15.0425 19.3047C18.0637 19.3047 19.6783 21.7135 19.7426 21.8164C19.941 22.1331 19.8442 22.5494 19.5275 22.7473C19.4156 22.817 19.2917 22.8503 19.1687 22.8503H19.1679Z" fill="#DDDDDD" fill-opacity="0.866667"/>
                        </svg>
                    //return <SentimentDissatisfiedIcon className={colleague_comment && colleague_comment === emotionKey ? 'emotionHighlight' : ''}/>
                    }
                })}
                </p>
            </div>
            <hr></hr>
        </React.Fragment>
    );
};

export default ViewObjectEmotions;