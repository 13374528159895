import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { getInvoiceChartData } from "../../../services/dashboardMaster.service";
import { Card } from "react-bootstrap";
import { DateRangeInterface } from "../dashboardInterface";
import BarChartSkeleton from "../../../components/common/Skeleton/BarChart.skeleton";
import { currencySymbol } from "../../../constants/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const InvoiceChart : React.FC<DateRangeInterface> = ({orderDateRange}) => {
  const [loading, setLoading] = React.useState(true);
  const [chartData, setChartData] = React.useState<any>({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, [orderDateRange]);
  const fetchData = (signal:any) => {
    getInvoiceChartData(orderDateRange,signal)
      .then((res) => {
        setChartData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  const options = {
    responsive: true,
    elements: {
      point: {
        pointStyle: "circle",
        radius: 4,
      },
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Line Chart - Multi Axis',
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false, // Disable datalabels for this specific chart
      },
    
    },
    scales: {
      x: {
        grid: {
          display: false, // Removes vertical grid lines for the x-axis
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        grid: {
          drawOnChartArea: true, // Keeps horizontal grid lines for the y-axis
        },             
       
      },
     
    },
    maintainAspectRatio: false ,
    
  };
  const labels = chartData.PastMonths;
   //['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  const data = {
    labels,
    datasets: [
      {
        label: 'Total Received',
        data:  chartData.TotalReceivedData,
        borderColor: 'rgb(33, 172, 89)',
        backgroundColor: 'rgba(33, 172, 89, 1)',
        
      },
      {
        label: 'Amount Overdue',
        data: chartData.TotalAmountOverdue,
        borderColor: 'rgb(238, 48, 48)',
        backgroundColor: 'rgba(238, 48, 48, 1)',
         
      },
      {
        label: 'Total Upcomming',
        data: chartData.TotalUpcommingPayment,
        borderColor: 'rgb(41, 127, 206)',
        backgroundColor: 'rgba(41, 127, 206, 1)',
        
      },
     /*  {
        label: 'Total Pending',
        data: chartData.TotalPendingPaymentMilestone,
        borderColor: 'rgb(248, 120, 27)',
        backgroundColor: 'rgba(248, 120, 27,1)',
         
      }, */
       
    ],
    
  };

  return (
    <Card className="w-100 pageContentInner rounded-2  mb-3 p-3">                
      {loading ? 
      <>
      <h5 className="fs-18 info-text fw-bold mb-3">Invoices</h5>
       <BarChartSkeleton />
      </> :
      <div>
        <div className="d-flex justify-content-between">
          <h5 className="fs-18 info-text fw-bold mb-3">Invoices</h5>
          <div className="countBox d-flex justify-content-end">
            <div className="dashbord-invoice-box p-2 rounded-2 dashbord-invoice-gray-box"> 
              <div className="fs-14 fw-bold dashbord-invoice-gray-text">Total Revenue</div>
              <div className="fs-14 fw-bold">{currencySymbol.POUND}{chartData.paymentData.total_revenue}</div>
            </div>

            <div className="dashbord-invoice-box p-2 rounded-2 dashbord-invoice-green-box"> 
              <div className="fs-14 fw-bold green-text">Amount Received</div>
              <div className="fs-14 fw-bold">{currencySymbol.POUND}{chartData.paymentData.total_received}</div>
            </div>

            <div className="dashbord-invoice-box p-2 rounded-2 dashbord-invoice-red-box">  
              <div className="fs-14 fw-bold red-text">Amount Overdue</div>
              <div className="fs-14 fw-bold">{currencySymbol.POUND}{chartData.paymentData.amount_overdue}</div>
            </div>

            <div className="dashbord-invoice-box p-2 rounded-2 dashbord-invoice-blue-box"> 
              <div className="fs-14 fw-bold blue-text">Upcoming Payment</div>
              <div className="fs-14 fw-bold">{currencySymbol.POUND}{chartData.paymentData.upcomming_payment}</div>
            </div>

          {/*   <div className="dashbord-invoice-box p-2 rounded-2 dashbord-invoice-orange-box">  
              <div className="fs-14 fw-bold orange-text">Pending Payment Milestones</div>
              <div className="fs-14 fw-bold">{currencySymbol.POUND}{chartData.paymentData.pending_payment_milestone}</div>
            </div> */}

          
          </div>
        </div>
        <div className="dashbord-lineChartBox">
          <Line   data={data} options={options} height="380px"/>
        </div>
      </div>
      }
    </Card>
    
  );
};

export default InvoiceChart;
