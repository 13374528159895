import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import CustomButton from "../../../../../components/common/CustomButton";
import {    
  getEmployeeDocumentRequestDetail,  
  uploadEmployeeDocument,
  updateEmployeeDocumentSendToHr,
  saveEmployeeDocumentSendToHr
} from "../../../../../services/employeeService";
import { toast } from "react-toastify";
import Loader from "../../../../../components/common/Loader";
import InputText from "../../../../../components/common/InputText";
import InputDatePicker from "../../../../../components/common/InputDatePicker";
import InputTextArea from "../../../../../components/common/InputTextArea";
import {sendDocValidationSchema} from "./NeededFromEmployeeValidation";
import moment from "moment";
import constants, { formTemplateAllowedFileTypes, formTemplateMaxFileSizeMB } from "../../../../../constants/constants";
import GeneralFileUploader from "../../../../../components/common/GeneralFileUploader";
import { FORM_TEMPLATE_SIZE_ERROR, FORM_TEMPLATE_TYPE_ERROR } from "../../../../../validations/ValidationErrors";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  employeeID: number;
  documentId?: string |number | null | undefined;
}

const SendToEmployeeDocumentPopup: React.FC<AddProps> = ({ isOpen, onClose, employeeID, documentId }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentRequestData, setDocumentRequestData] = useState<any>({});   
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(()=>{
    fetchData()
   },[])
 
   const fetchData = () =>{
    if(documentId){
      setLoading(true)
      getEmployeeDocumentRequestDetail(employeeID,documentId).then((response)=>{
        setDocumentRequestData(response?.data?.doc);
        setLoading(false)  
      }).catch((error)=>{
        setLoading(false)
        onClose()
  
      })
    }
    else{
      setLoading(false)
    }
   }

   const initialValues = {    
    doc_name: documentRequestData?.doc_name ? documentRequestData?.doc_name : "",
    description: documentRequestData?.description ? documentRequestData?.description : "",
    file: documentRequestData?.file ? documentRequestData?.file : "",
    filename: documentRequestData?.file ? documentRequestData?.file : "",

  };
  /**
   * Submit Method to call when user save button
   * @async
   * @param  values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
   
    setBtnLoading(true);
    const formData: any = {
      ...values,
    };
    console.log(formData);//return;
    delete formData.filename;
    // Example usage:
    const saveOrUpdateDocumentSendToHr = () => {
      // Provide the appropriate API request function
      return documentId
        ? updateEmployeeDocumentSendToHr(documentId, employeeID, formData)
        : saveEmployeeDocumentSendToHr(employeeID, formData);
    };
 
    saveOrUpdateDocumentSendToHr().then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose()

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

  /**
   * Upload form template PDF/Word file
   * @param {events, setFieldValue}
   * @returns {*}
   */
  const uploadFile = (event: any, setFieldValue: any) => {
    const file = event.target.files[0];
    if(file){
      // Check file type
      if (!formTemplateAllowedFileTypes.includes(file.type)) {
        toast(FORM_TEMPLATE_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (file.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
        toast(FORM_TEMPLATE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        // Create FormData object
        const formData = new FormData();
        // Append the file to the FormData object
        formData.append("file", file);
        uploadEmployeeDocument(employeeID,formData)
        .then((response) => {
          setImageLoading(false);
          setFieldValue('file', response.data.file);
        })
        .catch(() => {
          setImageLoading(false);
        });        
        setFieldValue('filename',file.name);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal max-modal-590" show={isOpen} onHide={onClose} centered>
          <Formik
            validationSchema={sendDocValidationSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Add Documents <span className="fs-15 fw-normal">(Send To Employee by HR)</span>
                  </h4> 
                  <Row>
                    <Col>
                    <InputText
                      controlId="doc_name"
                      label="Document Name"                      
                      placeholder="Enter document name"
                      touched={touched.doc_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.doc_name}
                      value={values.doc_name}
                    />
                    </Col>                    
                  </Row>       
                  <Row>
                    <Col>
                    <InputTextArea
                        controlId="description"
                        label="Description"
                        placeholder="Enter description"
                        touched={touched.description}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.description}
                        value={values.description}
                      />
                    </Col>                   
                  </Row>                
                  <Row>
                    <Col>
                    <GeneralFileUploader
                            uploadFile={(e) =>
                              uploadFile(e, setFieldValue)
                            }
                            imageLoading={imageLoading}
                            label="Upload Form"
                            filename={values.filename}
                          />
                    <ErrorMessage
                      name="file"
                      component="div"
                      className="text-danger fs-14"
                    />
                    </Col>                   
                  </Row>                
                  
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-60 mt-2"
                    variant="primary"
                  >
                    Add
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default SendToEmployeeDocumentPopup;
