import React, { useEffect, useState } from "react";
import { getInstallationCheckList, saveInstallationFirstStep } from "../../services/installation.service";
import Loader from "../../components/common/Loader";
import { Formik } from "formik";
import { MAXIMUM_255_CHAR } from "../../validations/ValidationErrors";
import * as Yup from "yup";
import { ValidType } from "../Survey/Start_Survey/surveyInterface";
import { toast } from "react-toastify";
import TypeText from "../Survey/Start_Survey/typeText";
import TypeDropdown from "../Survey/Start_Survey/typeDropdown";
import TypeMediaUpload from "../Survey/Start_Survey/typeMediaUpload";
import TypeRangeSlider from "../Survey/Start_Survey/typeRangeSlider";
import TypeRadioButton from "../Survey/Start_Survey/typeRadioButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface FirstDayCheckListProps {
  projectId: number | string | null;
  projectData ?: any;
}
const FirstDayCheckList: React.FC<FirstDayCheckListProps> = ({ projectId, projectData}) => {
  const [templateData, setTemplateData] = useState([]);
  const [conditions, setConditions] = useState<any>([]);
  const navigate = useNavigate()
  const survey_id = null;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
     fetchData();
  }, []);
  const fetchData = async () => {
    getInstallationCheckList(projectId)
      .then((response) => {
        setTemplateData(response.data.first_day);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const getValidationSchema = (subSection: any[]) => {
    let schemaObject: { [key: string]: Yup.AnySchema } = {}; // Initialize as an object
    subSection.forEach((subItem: any) => {
      subItem.fields.forEach((element: any) => {
        //  for (const element of fieldValues) {
        const propertyName = element.name;
        const labelName = element.title;
        if (element.visibility && element.require) {
          if (element.type === "text") {
            schemaObject[propertyName] = Yup.string()
              .required()
              .trim()
              .max(255, MAXIMUM_255_CHAR)
              .label(labelName);
          }
          if (element.type === "dropdown") {
            schemaObject[propertyName] = Yup.object()
              .required()
              .label(labelName);
          }

          if (element.type === "radio_button") {
            schemaObject[propertyName] = Yup.string()
              .required()
              .label(labelName);
          }
          if (element.type === "slider") {
            schemaObject[propertyName] = Yup.string()
              .required()
              .label(labelName);
          }
          if (element.type == "button") {
            schemaObject[propertyName] = Yup.string()
              .required()
              .label(labelName);
          }
          if (element.type == "sign_off") {
            schemaObject[propertyName] = Yup.string()
              .trim()
              .required()
              .label(labelName);
          }
        }
        //}
      });
    });
    const schema = Yup.object().shape(schemaObject);
    return schema;
  };

  const getInitialValues = (subSection: any[]) => {
    const initialValueArr: { [key: string]: string } = {}; // Initialize as an object
    subSection.forEach((subItem: any) => {
      subItem.fields.forEach((valid: ValidType) => {
        initialValueArr[valid.name] = (
          valid.value
            ? valid.value
            : valid.default_value
            ? valid.default_value
            : ""
        ) as string;
      });
    });

    return initialValueArr;
  };

  const handleSubmit = async (values: any) => {
    const formData = {
      project_id: projectId,
      first_day: templateData,
    };
    setLoading(true);
    saveInstallationFirstStep(formData).then((response) => {  
      toast.success(response.message);
    }
    ).catch((error) => {
      toast.error(error.response.data.message);
    }).finally(() => {
      setLoading(false);
    }
    );

  };

  return (
    <div>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          {templateData.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3   mx-auto survery-middle-content">
                {/* fields and sections will be here  */}
                <Formik
                  validationSchema={getValidationSchema(item.subsections)}
                  initialValues={getInitialValues(item.subsections)}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    setFieldError,
                    setFieldValue,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      {item.subsections?.map(
                        (subItem: any, subIndex: number) => {
                          return (
                            <>
                              {subItem.fields?.map(
                                (field: any, fieldIndex: number) => {
                                  return (
                                    // field type text
                                    <>
                                      {field.visibility &&
                                        field.type === "text" && (
                                          <TypeText
                                            field={field}
                                            index={index}
                                            fieldIndex={fieldIndex}
                                            subIndex={subIndex}
                                            touched={touched}
                                            errors={errors}
                                            values={values}
                                            templateData={templateData}
                                            setTemplateData={setTemplateData}
                                            setFieldValue={setFieldValue}
                                            fieldConditions={conditions}
                                            handleBlur={handleBlur}
                                          />
                                        )}

                                      {/* field type message */}
                                      {field.visibility &&
                                        field.type === "message" && (
                                          <p className="fs-14 mb-2 px-3 ">
                                            {field.default_value
                                              ? field.default_value
                                              : "No default value"}
                                          </p>
                                        )}

                                      {/* field type dropdown  */}
                                      {field.visibility &&
                                        field.type === "dropdown" && (
                                          <TypeDropdown
                                            field={field}
                                            index={index}
                                            fieldIndex={fieldIndex}
                                            subIndex={subIndex}
                                            touched={touched}
                                            errors={errors}
                                            values={values}
                                            templateData={templateData}
                                            setTemplateData={setTemplateData}
                                            setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            fieldConditions={conditions}
                                          />
                                        )}

                                      {/* field type medio upload button */}
                                      {field.visibility &&
                                        field.type === "button" && (
                                          <TypeMediaUpload
                                            field={field}
                                            index={index}
                                            fieldIndex={fieldIndex}
                                            subIndex={subIndex}
                                            touched={touched}
                                            errors={errors}
                                            values={values}
                                            templateData={templateData}
                                            setTemplateData={setTemplateData}
                                            setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            survey_id={survey_id}
                                          />
                                        )}

                                      {/* field type range slider */}
                                      {field.visibility &&
                                        field.type === "slider" && (
                                          <TypeRangeSlider
                                            field={field}
                                            index={index}
                                            fieldIndex={fieldIndex}
                                            subIndex={subIndex}
                                            touched={touched}
                                            errors={errors}
                                            values={values}
                                            templateData={templateData}
                                            setTemplateData={setTemplateData}
                                            setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            survey_id={survey_id}
                                            fieldConditions={conditions}
                                          />
                                        )}

                                      {/* field type radio button */}

                                      {field.visibility &&
                                        field.type === "radio_button" && (
                                          <TypeRadioButton
                                            field={field}
                                            index={index}
                                            fieldIndex={fieldIndex}
                                            subIndex={subIndex}
                                            touched={touched}
                                            errors={errors}
                                            values={values}
                                            templateData={templateData}
                                            setTemplateData={setTemplateData}
                                            setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            survey_id={survey_id}
                                            fieldConditions={conditions}
                                          />
                                        )}
                                    </>
                                  );
                                }
                              )}
                              {projectData && projectData.status != "cp" && (
                              <div className="d-flex justify-content-end mt-3 border-top pt-3 ">
                              <div
                                className="fw-semibold fs-13  mw-60 me-3  btn btn-outline-info"
                                onClick={handleCancel}
                              >
                                Cancel
                              </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary text-white fs-14"
                                >
                                  submit
                                  
                                </button>
                              </div>
                      )}
                            </>
                          );
                        }
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default FirstDayCheckList;
