import { Route, Routes } from 'react-router-dom';
import { AddEditServiceWarrantyComplaint } from './Add';
import { ViewServiceWarrantyComplaint } from './View';
import { checkAbility } from '../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../constants/permissionUser';
import ServiceWarrantyList from './index';

export const ServiceWarranty = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.SERVICE_WARRANTY_MANAGEMENT) && (
                <Route path="/" element={<ServiceWarrantyList />} />
            )}
            {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.SERVICE_WARRANTY_MANAGEMENT) && (
                <Route path="/Add" element={<AddEditServiceWarrantyComplaint />} />
            )}
            {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.SERVICE_WARRANTY_MANAGEMENT) && (
                <Route path="/edit/:id" element={<AddEditServiceWarrantyComplaint />} />
            )}
            <Route path="/view/:id" element={<ViewServiceWarrantyComplaint />} />
        </Routes>
    )
};