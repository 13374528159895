import React, { FC, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import * as yup from "yup";

import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import constants from "../../../../constants/constants";
import CustomButton from "../../../../components/common/CustomButton";

import Loader from "../../../../components/common/Loader";
import { PoDataDTO } from "../Add/po.type";
import InputTextArea from "../../../../components/common/InputTextArea";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import moment from "moment";
import { INVALID_DATE, MAXIMUM_500_CHAR, MINIMUM_3_CHAR } from "../../../../validations/ValidationErrors";
import { updateGrnNoteApi } from "../../../../services/warehouseService";


interface UpdateGrnNoteProps {
  grnData: PoDataDTO
}

export const UpdateGrnNote: FC<UpdateGrnNoteProps> = ({grnData}) => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);  
  const navigate = useNavigate();
  

  let { id } = useParams();

  /**
   * Submit Method to call when note save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: PoDataDTO, { setSubmitting }: any) => {
    const formData = {
      ...values,
      return_date: moment(values.return_date).isValid() ? moment(values.return_date).format(constants.dateFormats.databaseFormat) : null,
    };
   // console.log(formData);
   // return;
    setBtnLoading(true);    
    updateGrnNoteApi(grnData.id,formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });          
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    
  };

 

  useEffect(() => {    
      setLoading(false);    
  }, []);

  const updateGrnSchema = yup.object({
    remarks: yup
    .string()
    .trim()
    .optional()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .label("Note"),
    return_date: yup.date().optional().nullable().typeError(INVALID_DATE).label("Good Return Date"),
  });

  const initialValues: any = {
    remarks: grnData?.remarks ? grnData?.remarks : "",
    return_date: grnData?.return_date ? moment(grnData?.return_date) : null,
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (

        <Formik
                  validationSchema={updateGrnSchema}
                  initialValues={initialValues}
                  onSubmit={(values: PoDataDTO, actions) => {
                    submitForm(values, actions);
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    setFieldError,
                    setFieldValue,
                  }) => (
                    <Form className="" noValidate onSubmit={handleSubmit}>

                    
        <Card className="p-3 w-100 pageContentInner mb-3">
          <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">          
              <Row className="align-items-center">
                <Col>
                  <h3 className="fs-18 fw-bold info-text mt-2">Update Note</h3>
                </Col>
              </Row>          
              <div className="">
                
                      <div>
                      <Row>
                              <Col className="col-md-6">
                                <InputTextArea
                                  controlId="remarks"
                                  label="Add Note"
                                  placeholder="Enter Note"
                                  touched={touched.remarks}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.remarks}
                                  name="remarks"
                                  value={values.remarks}
                                  className="form-control textarea-height"
                                />
                              </Col>
                              <Col className="col-md-4">
                                <InputDatePicker
                                  name="return_date"
                                  label="Goods Return Date"
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      placeholder: "Select",
                                      className: "form-control",
                                      readOnly: true,
                                      id: "return_date",
                                    },
                                  }}
                                  value={values.return_date}
                                  onChange={(name, newValue) =>
                                    setFieldValue(name, newValue)
                                  }
                                  touched={touched.return_date}
                                  errors={errors.return_date}
                                />
                              </Col>
                            </Row>
                        
                      </div>
                    
              </div>
          </div>
        </Card>
        <div className="row">
            <div className="col-md-12">
              <div className="text-end my-2">  
                <CustomButton
                  type="submit"
                  loading={btnLoading}
                  disabled={btnLoading}
                  className="fw-semibold fs-13 text-white mw-65"
                  variant="primary"
                >
                Update
                </CustomButton>
              </div>
            </div>
          </div>
        </Form>
                  )}
                </Formik>
      )}
    </React.Fragment>
  );
};
