import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { addSchema } from "./validation";
import InputText from "../../../components/common/InputText";
import CustomButton from "../../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { createHolidayApi, getHolidayByIdApi, updateHolidayApi } from "../../../services/holidaysService";
import InputDatePicker from "../../../components/common/InputDatePicker";
import moment, { Moment } from "moment";
import constants from "../../../constants/constants";

interface IHolidays {
  id?: number;
  event_name?: string;
  event_date?: string;
  createdAt: string;
  updatedAt: string;
}

interface AddProps {
  isOpen: boolean;
  hId?: number | string | undefined;
  onClose: () => void;  
}

interface ProjectOption {
  id:string;
  full_name:string;
}

interface FormValues {
  event_name : string;
  event_date : any;  
}
const PostalcodeAdd: React.FC<AddProps> = ({ isOpen, onClose, hId }) => {
  const [editableData, setEditableData] = useState<IHolidays>()
  const [projectOwnerOption , setProjectOwnerOption] = useState([]);
  const [btnLoading,setBtnLoading] = useState(false)
  const [loading, setLoading] = useState(true)

  const filterOptions = (editableOptions: any) =>{
    
    const filteredArray = projectOwnerOption.filter((itemTwo:any) =>
  editableOptions.some((itemOne:any) => itemOne.user_id === itemTwo.key)
  )
  
return filteredArray

  }
  const initialValues = {
    event_name : editableData && editableData.event_name ? editableData.event_name : "",
    event_date : editableData && editableData.event_date ? moment(editableData.event_date) : null,
  }

  const id  = hId; 
   /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
   const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setBtnLoading(true);
    const formData = {
      ...values,
      event_date: moment(values.event_date).format(
        constants.dateFormats.databaseFormat
      ),
     };
 
     if(id){

      updateHolidayApi(id,formData).then((response)=>{
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        onClose()
  
      }).catch((error)=>{
        setBtnLoading(false);
  
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      })
     }else{
      createHolidayApi(formData).then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose()

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })}

   }

   const getHolidayDetail = async (id: number | string) => {
    setLoading(true);
    await getHolidayByIdApi(id)
      .then((response: any) => {
        setEditableData(response.data);
        setLoading(false);        
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };

   useEffect(() => {
    if (id) {
      getHolidayDetail(id);
    }else{
      setLoading(false)
    }
  }, []);

  return <>
  {loading? <Loader isLoading={loading} />:
  <Modal className="export-excel-modal modal-middle-size assign-modal" show={isOpen} onHide={onClose} centered>
  <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
      <Modal.Body className="position-relative pb-0" >
            <button type="button" className="btn-close text-right  btn-option-cover" aria-label="Close" onClick={onClose}></button>
            <h4 className="fw-bold info-text mb-3">{id?"Edit":"Add New"} National Holiday</h4>
           
            
                
                <div className="row">
                  <div className="col-md-12">
                    <InputText
                      controlId="event_name"
                      label="Event Name"                      
                      placeholder="Enter event Name"
                      touched={touched.event_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.event_name}
                      value={values.event_name}
                    />
                  </div>

                  <div className="col-md-12">
                  <InputDatePicker
                            name="event_date"
                            label="Event Date"
                            slotProps={{
                              textField: {
                                size: "small",
                                placeholder: "Select",
                                readOnly: true,
                                className: "form-control",
                                id: "event_date",
                              },
                            }}
                            value={values.event_date}
                            onChange={(name, newValue) =>
                              setFieldValue(name, newValue)
                            }
                            touched={touched.event_date}
                            errors={errors.event_date}
                          />
                  </div>
                  
                </div>
             
            
            
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
            <button className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info" onClick={onClose}>
              Cancel
            </button>
            <CustomButton 
              type="submit"
              loading={btnLoading}
              className="fw-semibold fs-13 text-white mw-60 mt-2"
              variant="primary"
              >
             Submit
            </CustomButton>
        
        
      </Modal.Footer>
      </Form>
     
      )}
      </Formik>
    </Modal>
}
  </>
}
export default PostalcodeAdd;