// DeletePopup.tsx
import React, { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FormAsyncSelect from "../../components/common/AsyncSelect/FormAsyncSelect";
import {
  exportDealListApi,
  getProductTypeList,
} from "../../services/dealService";
import { Formik } from "formik";
import InputText from "../../components/common/InputText";
import FormSelect from "../../components/common/FormSelect";
import constants, {
  dealContractType,
  dealStatus,
  projectStatus,
  vendorType,
} from "../../constants/constants";
import { getPostalCodes } from "../../services/postalcode.service";
import InputDateRangePicker from "../../components/common/InputDateRangePicker";
import moment from "moment";
import { getAllManagerList } from "../../services/authService";
import { toast } from "react-toastify";
import {
  exportGrnData,
  getPoNumberListApi,
} from "../../services/warehouseService";
import { exportVendorData, getAllVendorsName } from "../../services/vendorService";

interface ExportDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  exportType: string;
  text: string;
}

interface ProjectOption {
  id: string;
  vendor_name: string;
  contact_person: string;
}

const ExportVendorData: FC<ExportDataPopupProps> = ({
  isOpen,
  onClose,
  label,
  text,
  exportType,
}) => {
  const [orderDateRange, setOrderDateRange] = useState<any>(null);
  const [vendorOption, setVendorsOption] = useState([]);
  const [contactPersonOption, setContactPersonOption] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, [isOpen]);
  const getPoNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType,
      };
      //console.log(requestParam);
      getPoNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const fetchData = () => {    
    setLoading(true);
    getAllVendorsName()
      .then((response) => {
        const modifiyOption = response.data.map((item: ProjectOption) => ({
          key: item.id,
          value: item.vendor_name,
        }));
        setVendorsOption(modifiyOption);
        const modifiyContactPersonOption = response.data.map((item: ProjectOption) => ({
          key: item.id,
          value: item.contact_person,
        }));
        setContactPersonOption(modifiyContactPersonOption);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onClose();
      });
  };
  const handleSubmit = (values: any) => {
    const transformedValues = {
      ...values,
      vendor_name: values.vendor_name?.value,
      contact_person: values.contact_person?.value,
      preferred_payment_method: values.preferred_payment_method?.value,      
      vendor_type: values.vendor_type?.key,      
      status: values.status?.key,      
      //  order_date:orderDateRange
    };

    delete transformedValues.inspection_date_arr;
    delete transformedValues.received_date_arr;

  //  console.log(transformedValues);
    //  return;
    exportVendorData(transformedValues)
      .then((response) => {
        // Create a Blob object from the response data
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()} _vendor_data.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
        onClose();
      })
      .catch((error) => {
        if (error.response.status == " 404") {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`No records available for export.`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };


  const preferredPaymentMethod =  [
    {key : "Cheque", value : "Cheque"},
    {key : "Online", value : "Online"}    
  ];

  const statusArr =  [
    {key : "active", value : "Active"},
    {key : "inactive", value : "In Active"}    
  ];

  return (
    <>
      <Formik
        initialValues={{
          vendor_name: null,
          contact_person: null,
          preferred_payment_method: null,
          vendor_type: null,
          status: null,
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            className="export-excel-modal"
            show={isOpen}
            onHide={onClose}
            centered
          >
            <Form className="" noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <div className="fs-24 fw-bolder mb-3 info-text">
                  Export to Excel
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="All"
                      label="Vendor Name"
                      name="vendor_name"
                      options={vendorOption}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.vendor_name}
                      error={errors.vendor_name}
                      touched={touched.vendor_name}
                    />
                  </div>
                  <div className="col-md-6">
                  
                  <FormSelect
                      placeholder="All"
                      label="Contact Person"
                      name="contact_person"
                      options={contactPersonOption}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.contact_person}
                      error={errors.contact_person}
                      touched={touched.contact_person}
                    />                   
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="All"
                      label="Preferred Payment Method"
                      name="preferred_payment_method"
                      options={preferredPaymentMethod}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.preferred_payment_method}
                      error={errors.preferred_payment_method}
                      touched={touched.preferred_payment_method}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="All"
                      label="Vendor Type"
                      name="vendor_type"
                      options={vendorType}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.vendor_type}
                      error={errors.vendor_type}
                      touched={touched.vendor_type}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="All"
                      label="Active/ In Active"
                      name="status"
                      options={statusArr}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.status}
                      error={errors.status}
                      touched={touched.status}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                <button
                  className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <Button
                  type="submit"
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Export
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ExportVendorData;
