import * as yup from "yup";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  MAXIMUM_100_CHAR,
  ALPHA_NUMERIC_ALLOW,
  INVALID_DATE
} from "../../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../../validations/regex";

export const MeetingValidationSchema = (id: string | number | undefined) => {
  return yup.object({
    title : yup.string().trim().required().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(100,MAXIMUM_100_CHAR).label('Meeting Title'),
    date: yup
    .date()
    .required()
    .typeError(INVALID_DATE)
    .label('Meeting Date'),
    manager_id: yup.object().required().label("Reporting Manager"),
    description : yup.string().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).trim().required().label('Description'), 
    notes : yup.string().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).trim().optional().label('Meeting Notes'), 
  });
};
