import { FC, ReactNode } from "react";
import { Button, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InfoIcon from "@mui/icons-material/Info";
import parse from "html-react-parser";

interface IInputCheckBoxGroup {
  controlId: string;
  label?: string | ReactNode;
  labelTooltip?: any;
  handleChange?: any;
  handleBlur?: any;
  errorsField?: any;
  touched?: any;
  value?: any;
  data?: any;
  placeholder?: string;
  id?: string;
  rightIcon?: any;
  name?: any;
  existingData: string[];
}

/**
 * Custom Select Reusable Component
 * @date 4/13/2023 - 1:57:15 PM
 *
 * @param {{ controlId: any; label: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; data: any; placeholder: any; id: any; rightIcon: any; size: any; name: any; }} {
    controlId, label, handleChange, handleBlur, errorsField, touched, value, data, placeholder, id, rightIcon, size, name
}
 * @returns {*}
 */

const InputCheckBoxGroup: FC<IInputCheckBoxGroup> = ({
  controlId,
  label,
  labelTooltip,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  value,
  data,
  placeholder,
  id,
  rightIcon,
  name,
  existingData,
}) => {
  return (
    <Form.Group className="mb-3" controlId={controlId}>
      {label ? (
        <Form.Label className="lh-sm fs-14 fw-bold control-label mb-2">
          {label}
        </Form.Label>
      ) : null}
      <div className={`position-relative`}>
        <>
          {data.map((row: any, index: number) => {
            const key = row.key;
            return (
              <div
                key={index}
                className={`mb-3  ${errorsField ? "is-invalid" : ""}`}
              >
                <Form.Check
                  name={`${controlId}[${[index]}]`}
                  type="checkbox"
                  label={
                    <>
                      {row.name}
                      {labelTooltip || ""}
                    </>
                  }
                  value={key}
                  id={`switch-${controlId}-${index}`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={!!errorsField && touched}
                  checked={existingData.includes(row.key)}
                />
                {row.tooltip && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        <p>{parse(row.tooltip)}</p>
                      </Tooltip>
                    }
                  >
                    <Button
                      className="align-baseline d-inline-block ms-1 p-0 text-light"
                      variant="link"
                    >
                      <InfoIcon className="fs-15 me-1 link-text" />
                    </Button>
                  </OverlayTrigger>
                )}
              </div>
            );
          })}
        </>
        {touched && (
          <Form.Control.Feedback type="invalid">
            {errorsField}
          </Form.Control.Feedback>
        )}
      </div>
    </Form.Group>
  );
};

export default InputCheckBoxGroup;
