import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  INVALID_DATE,
  MAXIMUM_255_CHAR,
  MAXIMUM_500_CHAR,
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";
export const addSchema = yup.object({
  title: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .required()
    .label("Title"),
  category: yup.object().required().label("category"),
  contract_id: yup.object().optional().nullable().label("Project"),
  price: yup.number().required("Please enter required value").positive().typeError("value must be a number").label("Per Item Cost"),
  qty: yup.number().positive().required("Please enter required value").typeError("value must be a number").label("Quantity"),
  vendor_name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .required()
    .label("Vendor Name"),
  date: yup.date().required().typeError(INVALID_DATE).label("Date"),  
  remarks: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .required()
    .label("Notes"),
  file: yup
    .string()
    .trim()
    .required()
    .label("Invoice File"),
});
