import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import {Link} from 'react-router-dom';
import CustomButton from "../../../../components/common/CustomButton";
import { getEmployeeMeetingDetail, createEmployeeMeeting, updateEmployeeMeeting } from "../../../../services/employeeService";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import InputText from "../../../../components/common/InputText";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import InputTimePicker from "../../../../components/common/InputTimePicker";
import InputTextArea from "../../../../components/common/InputTextArea";
import {MeetingValidationSchema} from "./meetingValidation";
import moment from "moment";
import constants, { formTemplateAllowedFileTypes, formTemplateMaxFileSizeMB, MEETING_TYPES, Reporting_Manager, MEETING_INITIAL_YEAR, MEETING_SOURCE_PAGES } from "../../../../constants/constants";
import GeneralFileUploader from "../../../../components/common/GeneralFileUploader";
import { FORM_TEMPLATE_SIZE_ERROR, FORM_TEMPLATE_TYPE_ERROR } from "../../../../validations/ValidationErrors";
import FormSelect from "../../../../components/common/FormSelect";
import { getAllReportingManagerList } from "../../../../services/authService";
import InputCheckBox from "../../../../components/common/InputCheckBox";
import FormAsyncSelect from "../../../../components/common/AsyncSelect/FormAsyncSelect";
import { getEmployeeLeavesList } from "../../../../services/leavesService";
import { getLocalDateFormat } from "../../../../utility/common";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  employeeID: number | any;
  meetingId?: string |number | null | undefined;
  meetingType: string;
  source?: string;
  loggedInName?: string;
  loggedInID?: number;
  leaveId?: number | string | undefined;
}
interface ProjectOption {
  id:string;
  name:string;
}


const AddEmployeeMeeting: React.FC<AddProps> = ({ isOpen, onClose, employeeID, meetingId, meetingType, source, loggedInName, loggedInID, leaveId}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [meetingData, setMeetingData] = useState<any>({}); 
  const [meetingFile, setMeetingFile] = useState(null);
  const [managerData, setManagerData] = useState<any>({});
  const [leaveData, setLeaveData] = useState<any>({});

  useEffect(()=>{    
    fetchEmployeeLeaves().then(() => {
      fetchData();
      // Call this function after fetchData completes
    });    
   },[]);
   
   // Getting Meeting details for edit form
   const fetchData = () =>{
    setLoading(true)
      if(meetingId){
        getEmployeeMeetingDetail(meetingId).then((response)=>{
          setMeetingData(response?.data);
          setMeetingFile(response.meeting_file);
          setLoading(false)  
        }).catch((error)=>{
          setLoading(false)
        })
      }
      else{
        setLoading(false)
      }
   }

   const fetchEmployeeLeaves = () => {    
     return new Promise((resolve, reject) => {
       if (meetingType == "other") {
         getEmployeeLeavesList(employeeID)
           .then((res) => {
            let leaveDataOptions = res?.data?.map((item: any) => ({
              key: item.id,
              value: `${item.leaveType?.name} - (${getLocalDateFormat(item.date_from)} - ${getLocalDateFormat(item.date_to)})`,
            }));

            if(leaveId){
              leaveDataOptions = leaveDataOptions.filter(
                (item: any) => item.key == leaveId
              )
            }
            setLeaveData(leaveDataOptions);
            resolve(res);
           })
           .catch((err) => {
             console.log(err);
             resolve(true);
           });
       } else {
         resolve(true);
       }
     });
   };

   // Fetching reporting managers
   const getReportingManagers = (params: any) => {
    return new Promise((resolve, reject) => {
      if(employeeID){
        params['employee_id'] = employeeID;
      }
      getAllReportingManagerList(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  /**
   * Upload PDF/Word file
   * @param {events, setFieldValue}
   * @returns {*}
   */
  const uploadFile = (event: any, setFieldValue: any) => {
    const file = event.target.files[0];
    if(file){
      // Check file type
      if (!formTemplateAllowedFileTypes.includes(file.type)) {
        toast(FORM_TEMPLATE_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (file.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
        toast(FORM_TEMPLATE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        setFieldValue('file', file);
        setFieldValue('filename',file.name);
      }
    }
  };

   const initialValues = {    
    title: meetingData?.title ?? "",
    date: meetingData?.date ? moment(meetingData.date) : null,
    time: meetingData?.time ? moment(`${meetingData.date} ${meetingData.time}`) : null,
    manager_id: (source === MEETING_SOURCE_PAGES.reporting_manager) ? {id:loggedInID,name:loggedInName} : meetingData?.manager_id ? meetingData?.manager : null,
    description: meetingData?.description ?? "",
    leave_id: meetingData?.leave_id ?  leaveData.find(
      (item: any) => item.key == meetingData?.leave_id
    ): leaveId ? leaveData[0] : null,
    notes: meetingData?.notes ?? "",
    complete: meetingData?.completed_at ? true : false,
    file: meetingData?.file ? meetingData?.file : "",
    filename: meetingData?.file ? meetingData?.file_original_name : ""
  };
  
  /**
   * Submit Method to call when user save button
   * @async
   * @param  values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
   
    setBtnLoading(true);
    const formData: any = {
      ...values,
      type: meetingType,
      date: moment(values.date).isValid() ? moment(values.date).format(constants.dateFormats.databaseFormat): "",
      time: moment(values.time).isValid() ? moment(values.time).format("HH:mm"): ""
    };
    
    const createOrUpdate = () => {
      // Provide the appropriate API request function
      return meetingId
        ? updateEmployeeMeeting(meetingId, formData)
        : createEmployeeMeeting(employeeID, formData);
    };
 
    createOrUpdate().then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose()

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="modal-middle-size"
          show={isOpen}
          onHide={onClose}
          centered
        >
          <Formik
            validationSchema={MeetingValidationSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    {meetingId ? "Edit" : "Add"}{" "}
                    {
                      MEETING_TYPES[meetingType as keyof typeof MEETING_TYPES]
                    }{" "}
                    Meeting
                  </h4>
                  <Row>
                    <Col>
                      <InputText
                        controlId="title"
                        label="Meeting Title"
                        placeholder="Enter Meeting Title"
                        touched={touched.title}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.title}
                        value={values.title}
                      />
                    </Col>
                    <Col>
                      <InputDatePicker
                        name="date"
                        label="Meeting Date"
                        slotProps={{
                          textField: {
                            size: "small",
                            placeholder: "Select",
                            className: "form-control",
                            readOnly: true,
                            id: "date",
                          },
                        }}
                        value={values.date}
                        onChange={(name, newValue) =>
                          setFieldValue(name, newValue)
                        }
                        touched={touched.date}
                        errors={errors.date}
                        minDate={moment(`${MEETING_INITIAL_YEAR}-01-01`)}
                        maxDate={moment(`${moment().year() + 1}-12-31`)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {source === MEETING_SOURCE_PAGES.reporting_manager ? (
                      <Col className="col-md-6">
                        <InputText
                          controlId="manager_id"
                          label="Reporting Manager"
                          name="manager_id"
                          value={values.manager_id.name}
                          isDisable={true}
                        />
                      </Col>
                    ) : (
                      <Col className="col-md-6">
                        <FormAsyncSelect
                          id="manager_id"
                          label="Reporting Manager"
                          name="manager_id"
                          isAsync
                          isClearable
                          getOptions={getReportingManagers}
                          value={values.manager_id}
                          onChange={(name: any, value: any) => {
                            setFieldValue(name, value);
                          }}
                          error={errors.manager_id}
                          touched={touched.manager_id}
                        />
                      </Col>
                    )}
                    <Col>
                      <InputTimePicker
                        name="time"
                        label="Meeting Time"
                        slotProps={{
                          textField: {
                            size: "small",
                            placeholder: "Select",
                            className: "form-control",
                            id: "time",
                          },
                        }}
                        value={values.time}
                        onChange={(name, newValue) =>
                          setFieldValue(name, newValue)
                        }
                        touched={touched.time}
                        errors={errors.time}
                      />
                    </Col>
                  </Row>
                  {meetingType == "other" && (
                    <Row>
                      <Col md={6}>
                        <FormSelect
                          placeholder="Select"
                          label="Leave Detail"
                          name="leave_id"
                          options={leaveData}
                          getOptionLabel={(option: any) => option.value}
                          getOptionValue={(option: any) => option.key}
                          onChange={(name: string, value: string) => {
                            setFieldValue(name, value);
                          }}
                          value={values.leave_id}
                          error={errors.leave_id}
                          touched={touched.leave_id}
                        />
                      </Col>
                    </Row>
                  )}
                 {/*  {meetingId && (   */}
                    <Row>
                      <div className="col-md-12 mb-3">
                        <GeneralFileUploader
                          uploadFile={(e) => uploadFile(e, setFieldValue)}
                          imageLoading={false}
                          label="Upload Form"
                          filename={values.filename}
                          downloadExisting={true}
                          fileurl={meetingFile ? meetingFile : null}
                        />
                      </div>
                    </Row>
                  {/* )} */}
                  <Row>
                    <Col>
                      <InputTextArea
                        controlId="description"
                        label="Description"
                        placeholder="Enter Description"
                        touched={touched.description}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.description}
                        value={values.description}
                      />
                    </Col>
                  </Row>
                  {meetingId && (
                    <React.Fragment>
                      <Row>
                        <Col>
                          <InputTextArea
                            controlId="notes"
                            label="Meeting Notes"
                            placeholder="Enter Meeting Notes"
                            touched={touched.notes}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.notes}
                            value={values.notes}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <InputCheckBox
                            controlId="complete"
                            label="Mark as Complete"
                            className="fs-14"
                            touched={touched.complete}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.complete}
                            value={values.complete}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    disabled={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    {meetingId ? "Update" : "Schedule"}
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default AddEmployeeMeeting;