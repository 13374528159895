import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import CustomButton from "../../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import moment, { Moment } from "moment";
import constants, { OperationDesignation } from "../../../constants/constants";
import { updateFleetMileageApi } from "../../../services/fleetService";
import InputDatePicker from "../../../components/common/InputDatePicker";
import * as yup from "yup";
import { checkAbility } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import InputCurrencyText from "../../../components/common/InputCurrencyText";
import { selectAuthentication } from "../../../features/Auth/authSlice";
import { useSelector } from "react-redux";
import DeletePopup from "../../../components/common/deletePopup";

interface IFleetData {
  id?: number;
  asset_no_str?: string;
  model?: string;
  registration?: string;
}

interface AddProps {
  isOpen: boolean;
  fleetId: number | string | undefined | null;
  onClose: (isReload?: boolean) => void;
  fleetData : any;
}

interface FormValues {
  mileage: string;
  mileage_date: any;
}

const addSchema = yup.object({
  mileage: yup.number().required().label("Mileage"),
  mileage_date: yup.date().required().label("Mileage Date"),
});

const FleetMileage: React.FC<AddProps> = ({ isOpen, onClose, fleetId, fleetData }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const {user }:any = useSelector(selectAuthentication);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const isDriverUser = user?.user?.employeeDetails?.designation == OperationDesignation.DS ? true : false;
  
  const initialValues = {
    mileage: "",
    mileage_date: null,
  };

  // console.log(fleetData);

  const id = fleetId;
  
  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    const currentMileage = fleetData?.current_mileage;
    if(values.mileage < currentMileage){
      setDeletePopupOpen(true);
      return;
    }
    saveMileageData(values);
    return;
  };

  const saveMileageData = async(values: FormValues) => {
    setDeletePopupOpen(false);
    setBtnLoading(true);
    const formData = {
      ...values,
      mileage_date: moment(values.mileage_date).format(
        constants.dateFormats.databaseFormat
      ),
    };

    if (id) {
      await updateFleetMileageApi(id, formData, isDriverUser)
        .then((response) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          onClose(true);
        })
        .catch((error) => {
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="driver-history-modal"
          show={isOpen}
          onHide={onClose}
          centered
        >
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative pb-0">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={() => onClose()}
                  ></button>
                  <h4 className="fw-bold info-text mb-4 fs-24">Add Mileage</h4>
                  {(checkAbility(
                    PERMISSION_ACCESS.EDIT,
                    PERMISSION_MODULE.FLEET_MANAGEMENT
                  ) || isDriverUser) && (
                    <Row className="row gx-2">
                      <Col className="col-md-5">
                        <InputCurrencyText
                          name="mileage"
                          label="Mileage"
                          placeholder="Enter Mileage"
                          value={values.mileage}
                          isInteger={true}
                          onChange={(value, name) => {
                            setFieldValue("mileage", value);
                          }}
                          touched={touched.mileage}
                          errors={errors.mileage}
                        />
                      </Col>
                      <Col className="col-md-5">
                        <InputDatePicker
                          name="mileage_date"
                          label="Mileage Date"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              readOnly: true,
                              className: "form-control",
                              id: "mileage_date",
                            },
                          }}
                          value={values.mileage_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          disableFuture
                          minDate={moment(fleetData?.current_mileage_date)}
                          touched={touched.mileage_date}
                          errors={errors.mileage_date}
                        />
                      </Col>
                      <Col className="col-md-2">
                        <CustomButton
                          type="submit"
                          loading={btnLoading}
                          className="fw-semibold fs-13 text-white mw-60 mt-4 h-40"
                          variant="primary"
                        >
                          Submit
                        </CustomButton>
                      </Col>
                    </Row>
                  )}
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0"></Modal.Footer>
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={() => setDeletePopupOpen(false)}
                  onDelete={() => saveMileageData(values)}
                  actionType="info"
                  label="Are You Sure"
                  text="The entered mileage is less than the current mileage. Are you sure you want to proceed?"
                />
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default FleetMileage;
