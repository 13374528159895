import { Route, Routes } from "react-router-dom";
import { AddWarehouse } from "./Add/index";
import { Dashboard } from "./Dashboard/index";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import OpeningStockList from "./Opening-Stock";
import PurchaseOrdersList from "./Purchase-Orders";
import { AddNewPo } from "./Purchase-Orders/Add";
import { ViewPo } from "./Purchase-Orders/View";
import GoodsInwardsList from "./Goods-Inwards";
import { ViewGoodsInwardNote } from "./Goods-Inwards/View";
import { AddNewGoodsInwardNote } from "./Goods-Inwards/Add";
import DispatchOrdersList from "./Dispatch-Orders";
import { AddNewDispatchOrder } from "./Dispatch-Orders/Add";
import { ViewDispatchOrder } from "./Dispatch-Orders/View";

export const Warehouse = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.WAREHOUSE) && (
        <>
          <Route path="/" element={<Dashboard />} />
          <Route path="/update-warehouse" element={<AddWarehouse />} />
        </>
      )} 

    </Routes>
  );
};

export const OpeningStocks = () => {
  return (
    <Routes>      
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.OPENING_STOCK) && (
        <>
          <Route path="/" element={<OpeningStockList />} />
        </>
      )}
    </Routes>
  );
};

export const PurchaseOrders = () => {
  return (
    <Routes>      
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.WAREHOUSE) && (
        <Route path="/:delayed?" element={<PurchaseOrdersList />} /> 
      )}
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.WAREHOUSE) && (
        <Route path="/add" element={<AddNewPo />} /> 
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.WAREHOUSE) && (
        <Route path="/edit/:id" element={<AddNewPo />} />
      )}
      {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.WAREHOUSE) && (
        <Route path="/view/:id" element={<ViewPo />} />       
      )}
    </Routes>
  );
};

export const GoodsInward = () => {
  return (
    <Routes>      
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.GOOD_INWARD) && (
        <Route path="/" element={<GoodsInwardsList />} /> 
      )}     
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.GOOD_INWARD) && (
        <Route path="/add-grn/:poId" element={<AddNewGoodsInwardNote />} />
      )}
      {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.GOOD_INWARD) && (
        <Route path="/view/:id" element={<ViewGoodsInwardNote />} />       
      )}
    </Routes>
  );
};

export const DispatchOrders = () => {
  return (
    <Routes>      
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.DISPATCH_ORDER) && (
        <Route path="/" element={<DispatchOrdersList />} /> 
      )}     
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.DISPATCH_ORDER) && (
        <Route path="/add" element={<AddNewDispatchOrder />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.DISPATCH_ORDER) && (
        <Route path="/edit/:id" element={<AddNewDispatchOrder />} />
      )}
      {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.DISPATCH_ORDER) && (
        <Route path="/view/:id" element={<ViewDispatchOrder />} />       
      )}
    </Routes>
  );
};
