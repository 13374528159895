import React, { useEffect, useState } from "react";
import Loader from "../../components/common/Loader";
import { Card, Row, Col } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import contract from "../../assets/images/contract.svg";
import InfoIcon from "@mui/icons-material/Info";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate, useParams } from "react-router-dom";
import { getContractPdfAndSurveyForm, getSurveyApi } from "../../services/survey/survey.service";
import { Slider } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PROJECT_PREFIX } from "../../constants/constants";
import LightboxGallery from "../../components/common/LightboxGallery";
const ViewCompletedSurvey: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({});
  const [templateData, setTemplateData] = useState<any>({});

  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesSet, setImagesSet] = useState<any>([]);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const navigate = useNavigate();
  //  set id tyep in useParams
  const { id }  = useParams();
  useEffect(() => {
     
    fetchData();
  }, []);
  const fetchData = () => {
    getContractPdfAndSurveyForm(id)
      .then((res) => {
        setLoading(false);
        setData(res.data);
        setTemplateData(res.data.template[0])
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleStartSurvery = () => {
   navigate('/survey/start-survey', { state: { survey_id: id } })
  }
  const getInitivalValues = (data: any) => {
    let marks: any = [];
    marks = [
      {
        value: data[0].from,
        label: `${data[0].from}`,
      },
      {
        value: data[0].to,
        label: `${data[0].to}`,
      },
    ];
    console.log("marks", marks);
    return marks;
  };

  const handleClose = () => {
    setImagesSet([])
    setCurrentIndex(0)
    setLightboxIsOpen(false);
  };
  const openLightbox = (fileObj: any, imageIndex: any) => {
    const updatedImgSet = fileObj.map((item: any) => {
      return { ...item, src: item };
    });
    console.log("updatedImgSet",updatedImgSet)
    setImagesSet(updatedImgSet)
    setCurrentIndex(imageIndex);
    setLightboxIsOpen(true);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader  px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-between pt-1">
              <div>
                <h1 className="fw-bold h4 my-2">
                  {data?.survey?.project?.name}{" "}
                  <span className="Onboarding-tag py-1 px-2 rounded-1 fs-14">
                    #{PROJECT_PREFIX}{data.survey?.project?.project_no}
                  </span>
                </h1>
              </div>
              <div>
                {data.survey?.survey_pdf && (
                 <a href={data.survey?.survey_pdf} target="_blank"><button className="btn btn-primary fs-14 text-white me-2"><PictureAsPdfIcon /> Download PDF</button></a>
                )}
               </div>
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
          {templateData.map((item: any, index: number) => {
              if (item.subsections.length) {
                return (
                  <>
                  <div className="fs-20 fw-bold mb-3 info-text">{item.title}</div>
                    {item.subsections.map((subItem: any, subIndex: number) => {
                      if (subItem.fields.length) {
                        return (
                          <>
                            {
                              <>
                                {subItem.fields.length &&
                                  subItem.fields.map(
                                    (field: any, fieldIndex: number) => {
                                      if(field.visibility){
                                        return (                                        
                                          <Card className="p-3 w-100  mb-3">
                                            <div className="d-flex flex-column rounded-0 ">
                                              <Row className="d-flex">
                                                {/* type text */}
                                                {field.type == "text" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <p className="fw-bold mb-0">
                                                        {field.value}
                                                      </p>
                                                    </div>
                                                  </Col>
                                                )}
  
                                                {/* type dropdown */}
                                                {field.type == "dropdown" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <p className="fw-bold mb-0">
                                                        {field.value.value}
                                                      </p>
                                                    </div>
                                                  </Col>
                                                )}
  
                                                {/* type slider */}
                                                {field.type == "slider" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <Slider
                                                        aria-label="Always visible"
                                                        defaultValue={field.value}
                                                        step={0}
                                                        marks={getInitivalValues(
                                                          field.data_set
                                                        )}
                                                        max={field.data_set[0].to}
                                                        disabled
                                                        valueLabelDisplay="on"
                                                      />
                                                    </div>
                                                  </Col>
                                                )}
  
                                                {/* type radio_button */}
                                                {field.type == "radio_button" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <p className="fw-bold mb-0">
                                                        {field.value}
                                                      </p>
                                                    </div>
                                                  </Col>
                                                )}
  
                                                {/* type message */}
                                                {field.type == "message" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.default_value}{" "}
                                                    </p>
                                                  </Col>
                                                )}
  
                                                {/* type button */}
                                                {field.type == "button" && (
                                                  <Col className="col-md-11 pe-0">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <label className="browse-cover   survey-browse">
                                                      <div className="d-flex">
                                                        {field.signed_image_set && field.signed_image_set
                                                          .length &&
                                                          field.signed_image_set.map(
                                                            (
                                                              image: any,
                                                              imageIndex: number
                                                            ) => {
                                                              return (
                                                                <div className="survey-image p-0 m-1">
                                                                  <img
                                                                    src={image}
                                                                    height={80}
                                                                    width={80}
                                                                    className="imageFitScal"
                                                                    onClick={()=>openLightbox(field.signed_image_set,imageIndex)}

                                                                  />
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                    </label>
                                                  </Col>
                                                )}
  
                                                {field.type == "sign_off" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <p className="fw-bold mb-0">
                                                        {field.value}
                                                      </p>
                                                      <div>
                                                        <img
                                                          src={
                                                            field.signed_signature
                                                          }
                                                          height={50}
                                                          width={120}
                                                        />
                                                      </div>
                                                    </div>
                                                  </Col>
                                                )}
                                                
                                              </Row>
  
                                              {field.notes && (
                                                <Row>
                                                  <Col className="col-md-11">
                                                    <p
                                                      className="fw-bold mb-0 text-danger"
                                                      title={field.notes}
                                                    >
                                                      <InfoIcon /> Note :{" "}
                                                      {field.notes}{" "}
                                                    </p>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                          </Card>
                                        );
                                      }
                                   
                                    }
                                  )}
                              </>
                            }
                          </>
                        );
                      }
                    })}
                  </>
                );
              }
            })}
          </div>  
        </div>
      )}
                         <LightboxGallery images={imagesSet} currentIndex={currentIndex} open={lightboxIsOpen} onClose={handleClose} />

    </React.Fragment>
  );
};

export default ViewCompletedSurvey;
