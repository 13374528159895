import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridSingleSelectOperators,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import FileUploadIcon from "../../../assets/images/file-upload.svg";
import BlockIcon from "@mui/icons-material/Block";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Card, Col, Row } from "react-bootstrap";
import constants, {
  PROJECT_PREFIX,
  expensesCategories,
  expensesFinanceStatus,
  pageSetting,
  pageSizeModel,
} from "../../../constants/constants";
import ViewAgenda from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { checkAbility, currencyMask } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import moment from "moment";
import ExportFinanceExpensesData from "./exportData";
import DeletePopup from "../../../components/common/deletePopup";
import { toast } from "react-toastify";
import { deleteExpenseApi, getFinancExpenseList, saveFinanceInvoiceApi } from "../../../services/expenseService";
import ViewExpensesPopup from "../../Expenses/View";
import ViewExpenseQueryPopup from "../../Expenses/Query";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const FinanceInvoiceList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [isViewPopupOpen, setViewPopupOpen] = useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [exportPopup, setExportPopup] = useState(false);
  const [isQueryPopupOpen, setQueryPopupOpen] = useState(false);

  const handleViewClick = (id: GridRowId) => () => {
    setViewPopupOpen(true);
    setDeletedId(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleAskQueryClick = async (id: GridRowId) => {
    setDeletedId(id);
    setQueryPopupOpen(true);
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setViewPopupOpen(false);
    setQueryPopupOpen(false);
    setDeletedId(null);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteExpenseApi(deletedId, 'fm')
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddInvoice = () => {
    setBtnLoading(true);
    saveFinanceInvoiceApi(selectionModel)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setSelectionModel([]);
        apiRef.current.setRowSelectionModel([]);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        setBtnLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getFinancExpenseList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if (filterModel.items[0].field === "inv_number") {
        filterModel.items[0].value = filterModel.items[0].value.replace(
          /[GR-]/g,
          ""
        );
      }
      console.log(filterModel.items[0].field);
      if (filterModel.items[0].field === "$project.project_no$") {
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      sortable: true,
      hideable: false,
      renderCell(params) {
        return <span title={params.row.title}>{params.row.title}</span>;
      },
    },
    {
      field: "$project.project_no$",
      headerName: "Project Order No.",
      flex: 1,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell(params) {
        return params.row?.contract_id
          ? `${params.row?.project?.project_no_str}`
          : "-";
      },
    },
    {
      field: "$user.full_name$",
      headerName: "Employee Name",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.user?.full_name ? params.row?.user?.full_name : "-";
      },
    },
    {
      field: "$reporting_manager.full_name$",
      headerName: "Reporting Manager",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.reporting_manager?.full_name ? params.row?.reporting_manager?.full_name : "-";
      },
    },
    {
      field: "$category.name$",
      headerName: "Category",
      flex: 1,
      sortable: true,
     
      renderCell(params) {        
        return (
          <span
            title={params.row?.category?.name}
          >
            {params.row?.category?.name}
          </span>
        );
      },
    },
    {
      field: "amount",
      type: "number",
      headerName: "Amount",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row?.amount}>
            {currencyMask(params.row?.amount)}
          </span>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date),
      renderCell(params) {
        const orderDate = new Date(params.row.date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type: "singleSelect",
      valueOptions: expensesFinanceStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf"
      ),
      renderCell(params) {
        const statusName = expensesFinanceStatus.find(
          (item) => item.key == params.row.status_finance
        );
        return (
          <span
            title={statusName?.value}
            className={`py-1 px-2 ms-0 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
          >
            {statusName?.value}
          </span>
        );
      },
    },
  ];

  const handleEditViewActionPermission = () => {
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.FINANCE_EXPENSES
    );
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.FINANCE_EXPENSES
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.FINANCE_EXPENSES
    );

    if (
      checkViewAbilityCondition ||
      checkEditAbilityCondition ||
      checkDeleteAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                icon={<ViewAgenda />}
                showInMenu={false}
                className="text-primary"
                onClick={handleViewClick(id)}
              />
            );
            gridActions.push(
              <GridActionsCellItem
                label="Ask Query"
                onClick={(event) => handleAskQueryClick(id)}
                showInMenu={false}
                className="text-primary"
                icon={<QuestionAnswerIcon className="fs-20" />}
              />
              )
          }
          
          if (checkDeleteAbilityCondition && row.status_finance == 0) {
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                showInMenu={false}
                className="text-primary"
                onClick={handleDeleteClick(id)}
              />
            );
          }

          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu={false}
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  };

  const isRowSelectable = (params: any) => {
    return params.row.status_finance == 0;
  };

  const handleSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel
  ) => {
    //console.log(data);
    const filteredArray: any = data
      .filter((itemTwo: any) =>
        rowSelectionModel.some((itemOne: any) => itemOne === itemTwo.id)
      )
      .map((item: any) => (item.id));
    //console.log(filteredArray);
    setSelectionModel(filteredArray);
  };

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {exportPopup && (
            <ExportFinanceExpensesData
              isOpen={exportPopup}
              onClose={toggleExportPopup}
              exportType="project"
              label="Export Invoices"
              text="Export Invoices"
            />
          )}
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Expenses</h1>
              </Col>
              <Col className="text-end">
                <button
                  className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2 "
                  onClick={toggleExportPopup}
                >
                  <img src={FileUploadIcon} alt="File Upload Icon" />
                </button>
                {checkAbility(
                  PERMISSION_ACCESS.EDIT,
                  PERMISSION_MODULE.FINANCE_EXPENSES
                ) && (
                  <Button
                    className={`fw-semibold fs-14  btn ${selectionModel?.length ? 'btn-primary text-white' : 'btn-secondary control-label'}`}
                    variant="primary"
                    type="button"
                    disabled={btnLoading}
                    onClick={() => handleAddInvoice()}
                  >
                    <AddCircleIcon className="me-1 add-icon-expenses vertical-top fs-16"/>
                    Create Invoice
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain expenses-table">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  apiRef={apiRef}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  disableRowSelectionOnClick
                  localeText={{ noRowsLabel: "No Expenses found" }}
                  checkboxSelection
                  isRowSelectable={isRowSelectable}
                  onRowSelectionModelChange={handleSelectionModelChange}                  
                  className="border-0 rounded-2"
                />
              </div>
            </Card>
          </div>
          {
            isViewPopupOpen && (
              <ViewExpensesPopup isOpen={isViewPopupOpen} onClose={handleClose} expensesId={deletedId} viewType="fm" />
            )
          }
          {
            isQueryPopupOpen && (
              <ViewExpenseQueryPopup isOpen={isQueryPopupOpen} onClose={handleClose} expensesId={deletedId} viewType="fm" />
            )
          }
          <DeletePopup
            isOpen={isDeletePopupOpen}
            onClose={handleClose}
            onDelete={handleDelete}
            label="Delete Expense"
            text="Are you sure you want to delete this Expense?"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FinanceInvoiceList;
