import { axios } from "./axios";
import {   
  FINANCE_INVOICE_LIST,
  FINANCE_INVOICE_ADD,
  FINANCE_INVOICE_DELETE,
  FINANCE_INVOICE_DETAIL,
  FINANCE_INVOICE_EDIT,
  FINANCE_INVOICE_NUMBER,
  FINANCE_INVOICE_EXPORT,
  FINANCE_INVOICE_PRINT,
  FINANCE_EXTERNAL_PROCUREMENT,
  ASSET_DASHBOARD_PENDING,
  FINANCE_DASHBOARD_INVOICE,
  FINANCE_DASHBOARD_PROJECT,
  FINANCE_DASHBOARD_EXPENSES,
  FINANCE_INVOICE_SYNC,
} from "../constants/paths";

export type createInvociesDTO = {
  payment_milestone_id: any;
  contract_id: any;
  invoiceDetails: Array<{
    qty: string | number;
    price: string | number;
    tax_percentage: string | number;
    amount: string | number;
    tax_amount: string | number;
    invoice_id: string | number;
  }>;
};
export const getInvociesList = (formData: any): Promise<any> => {
  return axios.get(FINANCE_INVOICE_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * Get Invoice number
 * @returns Invoice number
 */
export const getInvoiceNumberApi = (): Promise<any> => {
  return axios.get(FINANCE_INVOICE_NUMBER);
};

export const createInvociesApi = (data: createInvociesDTO): Promise<any> => {
  const parsedData = data.invoiceDetails.map((item) => ({
    ...item,
    qty: typeof item.qty === 'string' ? parseFloat(item.qty) : item.qty,
    price: typeof item.price === 'string' ? parseFloat(item.price) : item.price,
    tax_percentage: typeof item.tax_percentage === 'string' ? parseFloat(item.tax_percentage) : item.tax_percentage,
    amount: typeof item.amount === 'string' ? parseFloat(item.amount) : item.amount,
}));
  const transformData = {
    ...data,
    contract_id : data?.contract_id?.id,
    payment_milestone_id : data?.payment_milestone_id?.key ? data?.payment_milestone_id?.key : null,
    invoiceDetails : parsedData
  };

  return axios.post(FINANCE_INVOICE_ADD, transformData);
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getInvociesByIdApi = (id: string | number): Promise<any> => {
  return axios.get(FINANCE_INVOICE_DETAIL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateInvociesApi = (
  id: string | number | undefined,
  data: createInvociesDTO
): Promise<any> => {
  const parsedData = data.invoiceDetails.map(({ invoice_id,tax_amount,  ...item }) => ({
    ...item,
    qty: typeof item.qty === 'string' ? parseFloat(item.qty) : item.qty,
    price: typeof item.price === 'string' ? parseFloat(item.price) : item.price,
    tax_percentage: typeof item.tax_percentage === 'string' ? parseFloat(item.tax_percentage) : item.tax_percentage,
    amount: typeof item.amount === 'string' ? parseFloat(item.amount) : item.amount,
}));
  const transformData = {
    ...data,
    contract_id : data?.contract_id?.id,
    payment_milestone_id : data?.payment_milestone_id?.key ? data?.payment_milestone_id?.key : null,
    invoiceDetails : parsedData
  };
  return axios.put(FINANCE_INVOICE_EDIT + "/" + id, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteInvociesApi = (id: string | number | null): Promise<any> => {
  return axios.delete(FINANCE_INVOICE_DELETE + "/" + id);
};

export const exportFinanceInvoicesApi = (params:any) : Promise<any> => {
  return axios.get(FINANCE_INVOICE_EXPORT,{params,responseType: 'arraybuffer',});
}

export const printFinanceInvoicesApi = ( invoiceId: string | number | undefined): Promise<any> => {
  return axios.get(`${FINANCE_INVOICE_PRINT}/${invoiceId}`);
};

export const getFinanceExternalExpenseList = (formData: any): Promise<any> => {
  return axios.get(`${FINANCE_EXTERNAL_PROCUREMENT}/list`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const exportFinanceExternalExpensesApi = (params:any) : Promise<any> => {
  return axios.get(`${FINANCE_EXTERNAL_PROCUREMENT}/export`,{params,responseType: 'arraybuffer',});
}

export const getFinanceExternalProcurementTemplate= () : Promise<any> => {
  return axios.get(`${FINANCE_EXTERNAL_PROCUREMENT}/exteranl-procurement-template`);
}

export const importFinanceExternalExpensesApi = (formData: any) : Promise<any> => {
  return axios.post(`${FINANCE_EXTERNAL_PROCUREMENT}/import`,formData);
}

export const importUpdateFinanceExternalExpensesApi = (formData: any) : Promise<any> => {
  return axios.post(`${FINANCE_EXTERNAL_PROCUREMENT}/import-update`,formData);
}
// Finance Dashboard APIs

export const getFinanceDashboardPendingAssets = (formData: any): Promise<any> => {
  return axios.get(`${ASSET_DASHBOARD_PENDING}/list`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};


export const approveFinanceDashboardPendingAssets = (assetId:string | number | undefined, formData: any) : Promise<any> => {
  return axios.post(`${ASSET_DASHBOARD_PENDING}/approve-reject/${assetId}`,formData);
}

export const getFinanceDashboardOutstandingInvoices = (formData: any) : Promise<any> => {
  return axios.get(`${FINANCE_DASHBOARD_INVOICE}/outstanding-invoices`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      dateFrom: formData.dateFrom,
      dateTo: formData.dateTo,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });   
}

export const getFinanceDashboardIncomeExpenses = (formData: any) : Promise<any> => {
  return axios.get(`${FINANCE_DASHBOARD_PROJECT}/income-expenses-list`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      dateFrom: formData.dateFrom,
      dateTo: formData.dateTo,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });   
}

export const getFinanceDashboardProjectsOnboardStats = (formData: any) : Promise<any> => {
  return axios.post(`${FINANCE_DASHBOARD_PROJECT}/projects-onboarding-stats`,formData);
}

export const getFinanceDashboardPendingInvoiceCreation = (formData: any) : Promise<any> => {
  return axios.post(`${FINANCE_DASHBOARD_PROJECT}/pending-invoice-creation`,formData);
}

export const getFinanceDashboardInvoiceForecasting = (formData: any) : Promise<any> => {
  return axios.post(`${FINANCE_DASHBOARD_PROJECT}/invoice-forecasting`,formData);
}

export const getFinanceDashboardPaymentStatus = (formData: any) : Promise<any> => {
  return axios.post(`${FINANCE_DASHBOARD_PROJECT}/project-payment-status`,formData);
}

export const getFinanceDashboardTotalIncomeExpense = (formData: any) : Promise<any> => {
  return axios.post(`${FINANCE_DASHBOARD_PROJECT}/total-income-expenses`,formData);
}

export const getFinanceDashboardExpensesChart = (formData: any) : Promise<any> => {
  return axios.post(`${FINANCE_DASHBOARD_EXPENSES}/expenses-chart`,formData);
}

export const syncInvoicesFromXeroApi = (): Promise<any> => {
  return axios.get(FINANCE_INVOICE_SYNC);
};