import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

interface CustomTooltipProps extends TooltipProps {
  maxWidth?: string;
  fontSize?: string;
  backgroundColor?: string;
  color?: string;
  fontWeight?: string;
  border?: string;
  tooltipIconSize?: string;
  placement?: any;
}

const CustomTooltip = styled(({ className,  ...props }: CustomTooltipProps) => (
    <Tooltip TransitionComponent={Fade} 
      TransitionProps={{ timeout: 600 }} 
      {...props} 
      classes={{ popper: className }} 
      placement = {props.placement || 'top'}
      style={{'fontSize': props.tooltipIconSize || ''}}  />
  ))
    `& .${tooltipClasses.tooltip} {
      max-width: ${(props) => props?.maxWidth};
      font-size: ${(props) => props?.fontSize};
      background-color: ${(props) => props?.backgroundColor};
      color: ${(props) => props?.color};
      font-weight: ${(props) => props?.fontWeight};
      border: ${(props) => props?.border};
    },
`;

export default CustomTooltip;