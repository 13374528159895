import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,  
  VALID_EMAIL,
} from "../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../validations/regex";

export const vendorSchema = yup.object({
  vendor_name: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Vendor Company"),
  contact_person: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Contact Person"),
  email: yup
    .string()
    .matches(EMAIL_REGEX,VALID_EMAIL)
    .required()
    .max(255, MAXIMUM_255_CHAR)
    .label("Email"),
  phone_number: yup
    .number()
    .required()
    .typeError("Phone number must be a number")
    .max(999999999999999, "Phone number must be at most 15 digits") // Maximum 15 digits
    .label("Phone Number"),
  vendor_address: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Vendor Address"),
  vendor_type: yup
    .object()
    .required()    
    .label("Vendor Type"),
  credit_terms: yup
    .object()
    .required()    
    .label("Credit Terms"),  
});
