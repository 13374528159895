// CustomButton.tsx
import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface CustomButtonProps extends ButtonProps {
  label: string;
}

const CustomButtonCancel: React.FC<CustomButtonProps> = () => {
  const navigate = useNavigate();

  // cancel redirection
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info" onClick={handleCancel}>
                    Cancel
                  </div>
  );
};

export default CustomButtonCancel;
