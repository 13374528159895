import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Form } from "react-bootstrap";
import constants from "../../constants/constants";
import LogoutIcon from "@mui/icons-material/Logout";

interface MyDatePickerProps {
  name: string;
  label?: string;
  className?: string;
  slotProps?: { textField: any };
  value?: Moment | null | Date;
  onChange: (name: string, newValue: Moment | null) => void;
  touched?: any;
  errors?: any | undefined;
  view?: any | undefined;
  format?: any | undefined;
  slots?: any | undefined;
  disableFuture?: boolean;
  disabledWeekend?: boolean;
  minDate?: Moment | null;
  maxDate?: Moment | null;
  isDisable? : boolean;
}

const InputDatePicker: React.FC<MyDatePickerProps> = ({
  name,
  label,
  className = "",
  slotProps,
  value,
  onChange,
  touched,
  errors,
  view = ['year', 'month', 'day'],
  format=constants.dateFormats.slashSeparatedDate,
  slots = {},  
  disableFuture = false,
  disabledWeekend = false,
  minDate = null,
  maxDate = null,
  isDisable = false
}) => {
  const isWeekend = (date: any) => {
    const day = date.day();  
    return day === 0 || day === 6;
  };
    
  return (
    <>
    <Form.Group className="mb-3 custom-from-group">
      <Form.Label className="lh-sm control-label fs-14 fw-bold">
        {label}
      </Form.Label>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          name={name}
          format={format}
          className={`form-control date-icon ${
            touched && !!errors ? "is-invalid" : ""
          } ${className}`}
          slotProps={slotProps}          
          slots={slots}
          views={view}
          minDate={minDate} 
          maxDate={maxDate}           
          value={value ? moment(value): null}
          disabled={isDisable}
          onChange={(newValue) => onChange(name, newValue)}
          disableFuture={disableFuture}
          shouldDisableDate={disabledWeekend ? isWeekend: undefined}
        />
      </LocalizationProvider>
      <Form.Control.Feedback type="invalid">
                    {errors}
      </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default InputDatePicker;
