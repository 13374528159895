import { axios } from "../services/axios";
import {
  UPLOAD_SITE_ICON,
  CREATE_EXTERNAL_LINK,
  EXTERNAL_LIST,
  UPDATE_EXTERNAL,
  EXTERNAL_DELETE,
} from "../constants/paths";

export type createExternalDTO = {
  site_name: string;
  site_url: string;
  site_icon: string;
  role: string[] | any;
};

export type RoleItem = {
  key: string;
  value: string;
};

export const getExternalList = (formData: any): Promise<any> => {
  return axios.get(EXTERNAL_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const createExternalApi = (data: createExternalDTO): Promise<any> => {
  const transformData = {
    ...data,
    role: data.role.map((roleItem: RoleItem) => ({ id: roleItem.key })),
  };

  return axios.post(CREATE_EXTERNAL_LINK, transformData);
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getExternalByIdApi = (id: string): Promise<any> => {
  return axios.get(EXTERNAL_LIST + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateExternalApi = (
  id: string,
  data: createExternalDTO
): Promise<any> => {
  const transformData = {
    ...data,
    role: data.role.map((roleItem: RoleItem) => ({ id: roleItem.key })),
  };
  return axios.put(UPDATE_EXTERNAL + "/" + id, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteExternalApi = (id: string | number | null): Promise<any> => {
  return axios.delete(EXTERNAL_DELETE + "/" + id);
};

export const uploadSiteImageApi = (data: any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${UPLOAD_SITE_ICON}`, data, config);
};
