import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";

import ClearIcon from '@mui/icons-material/Clear';
import InputText from "../../components/common/InputText";
import InputTextArea from "../../components/common/InputTextArea";
import Loader from "../../components/common/Loader";
import CustomButtonCancel from "../../components/common/CustomButtonCalcel";
import CustomButton from "../../components/common/CustomButton";

import { medicalSchema } from "./validation";
import {
  medicalDto,
  fillMedicalQuestionnaire,
  getIllnessList,
} from "../../services/medicalService";
import InputDatePicker from "../../components/common/InputDatePicker";
import FormAsyncSelect from "../../components/common/AsyncSelect/FormAsyncSelect";
import InputRadio from "../../components/common/InputRadio";
import FileUploader from "../../components/common/FileUploader";
import InputCheckBoxGroup from "../../components/common/InputCheckBoxGroup";

import constants, {
  allowedFileTypes,
  maxFileSizeMB,
} from "../../constants/constants";
import {
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
} from "../../validations/ValidationErrors";
import DeletePopup from "../../components/common/deletePopup";

interface props {
  illnessData: string[];
  setIsMutation: () => void;
}

const FormMedical: React.FC<props> = ({ illnessData, setIsMutation }) => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [isSaveConfirmationPopupOpen, setSaveConfirmationPopup] =
    useState(false);

  const navigate = useNavigate();
  const sigCanvas = useRef<SignatureCanvas>(null);

  /**
   * Submit Method to call when user save button
   * @async
   * @param {medicalDto, actions} values
   * @returns {*}
   */
  const submitMedicalForm = async (
    values: medicalDto,
    { setSubmitting }: any
  ) => {
    setLoading(true);

    const formData = {
      ...values,
      declaration_date: moment(values.declaration_date).isValid()
        ? moment(values.declaration_date).format(
            constants.dateFormats.databaseFormat
          )
        : "",
      health_questions: JSON.stringify(values.health_questions),
    };

    fillMedicalQuestionnaire(formData)
      .then((response: any) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setBtnLoading(false);
        setIsMutation();
      })
      .catch((error) => {
        setBtnLoading(false);

        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .finally(() => {
        setLoading(false);
        setImagePreview(null);
      });
  };

  // Signature Update
  function setSignatureOnChange(setFieldValue: any) {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL("image/png");
      setFieldValue("sign", dataUrl);
    }
  }

  // Clear signature
  const clearSignature = (setFieldValue: any) => {
    if (sigCanvas.current) {
      setFieldValue("sign", '');
      sigCanvas.current.clear();
    }
  }

  const initialValues = {
    post_offered: "",
    surname: "",
    forename: "",
    telephone: "",
    mobile: "",
    address: "",
    gp_name: "",
    gp_address: "",
    health_questions: {
      employment_ever_terminated: null,
      sickness_duration_last_12_months: "",
      sickness_duration_prior_to_last_12_months: "",
      alcohol_units: "",
      smoke: null,
      medicine: null,
      under_care: null,
      last_consult_gp: "",
      illness: [],
      details_of_illness: "",
      disabilities: "",
    },
    declaration_full_name: "",
    declaration_date: null,
    sign: ""
  };

  const handleClose = () => {
    setSaveConfirmationPopup(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Formik
          validationSchema={medicalSchema}
          initialValues={initialValues}
          onSubmit={(values: medicalDto, actions) => {
            if (isSaveConfirmationPopupOpen === false) {
              setSaveConfirmationPopup(true);
            } else {
              setSaveConfirmationPopup(false);
              submitMedicalForm(values, actions);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            submitForm,
          }) => (
            <Form className="" noValidate onSubmit={handleSubmit}>
              
              
              <Card className="p-3 w-100 pageContentInner mb-2">
                <h3 className="fs-20 fw-bold info-text mb-2">Section 1</h3>
                <p className="mb-3">Please fill in this form as accurately as possible</p>
                <Row>
                  <div className="col-md-12">
                    <InputText
                      controlId="post_offered"
                      label="Post Offered"
                      placeholder="Enter post"
                      touched={touched.post_offered}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.post_offered}
                      value={values.post_offered}
                    />
                  </div>
                </Row>

                <Row>
                  <div className="col-md-4">
                    <InputText
                      controlId="surname"
                      label="Surname"
                      placeholder="Enter surname"
                      touched={touched.surname}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.surname}
                      value={values.surname}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputText
                      controlId="forename"
                      label="Forename(S)"
                      placeholder="Enter forename"
                      touched={touched.forename}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.forename}
                      value={values.forename}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputText
                      controlId="telephone"
                      label="Telephone"
                      placeholder="Enter telephone number"
                      touched={touched.telephone}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.telephone}
                      value={values.telephone}
                    />
                  </div>
                </Row>

                <Row>
                  <div className="col-md-4">
                    <InputText
                      controlId="mobile"
                      label="Mobile"
                      placeholder="Enter mobile number"
                      touched={touched.mobile}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.mobile}
                      value={values.mobile}
                    />
                  </div>
                  <div className="col-md-8">
                    <InputText
                      controlId="address"
                      label="Address"
                      placeholder="Enter address"
                      touched={touched.address}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.address}
                      value={values.address}
                    />
                  </div>
                </Row>

                <Row>
                  <div className="col-md-4">
                    <InputText
                      controlId="gp_name"
                      label="Name of GP"
                      placeholder="Enter name of GP"
                      touched={touched.gp_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.gp_name}
                      value={values.gp_name}
                    />
                  </div>
                  <div className="col-md-8">
                    <InputText
                      controlId="gp_address"
                      label="Address of GP"
                      placeholder="Enter address of GP"
                      touched={touched.gp_address}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.gp_address}
                      value={values.gp_address}
                    />
                  </div>
                </Row>
              </Card>

             
              <Card className="p-3 w-100 pageContentInner mb-2">
                <h3 className="fs-20 fw-bold info-text mb-3">Section 2</h3>
                <div className="form-hrmc-statment medical-form-first">
                  <Row>
                    <InputRadio
                      controlId="employment_ever_terminated"
                      label="Has your employment ever been terminated on the grounds of ill health?"
                      name="employment_ever_terminated"
                      data={[
                        { key: "1", name: "Yes" },
                        { key: "0", name: "No" },
                      ]}
                      handleChange={(event: any) => {
                        setFieldValue(
                          "health_questions.employment_ever_terminated",
                          event.target.value
                        );
                      }}
                      value={values.health_questions.employment_ever_terminated}
                      errorsField={
                        errors.health_questions?.employment_ever_terminated
                      }
                      touched={
                        touched.health_questions?.employment_ever_terminated
                      }
                    />
                  </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                <Row>
                  <div className="col-md-6">
                    <label className="lh-sm fs-14 fw-bold control-label mb-2 form-label">
                      Approximately how many days/weeks sickness/absence have
                      you had:
                    </label>
                  </div>
                  <div className="col-md-6">
                    <Row>
                    <div className="col-md-6">
                    <InputText
                      controlId="sickness_duration_last_12_months"
                      label=""
                      placeholder="In the last twelve months"
                      touched={
                        touched.health_questions
                          ?.sickness_duration_last_12_months
                      }
                      handleBlur={handleBlur}
                      handleChange={(event: any) => {
                        setFieldValue(
                          "health_questions.sickness_duration_last_12_months",
                          event.target.value
                        );
                      }}
                      errorsField={
                        errors.health_questions
                          ?.sickness_duration_last_12_months
                      }
                      value={
                        values.health_questions.sickness_duration_last_12_months
                      }
                    />
                    </div>
                    <div className="col-md-6">
                      <InputText
                        controlId="sickness_duration_prior_to_last_12_months"
                        label=""
                        placeholder="In the last twelve months prior to that"
                        touched={
                          touched.health_questions
                            ?.sickness_duration_prior_to_last_12_months
                        }
                        handleBlur={handleBlur}
                        handleChange={(event: any) => {
                          setFieldValue(
                            "health_questions.sickness_duration_prior_to_last_12_months",
                            event.target.value
                          );
                        }}
                        errorsField={
                          errors.health_questions
                            ?.sickness_duration_prior_to_last_12_months
                        }
                        value={
                          values.health_questions
                            .sickness_duration_prior_to_last_12_months
                        }
                      />
                    </div>
                    </Row>
                  </div>
                  
                </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                <Row>
                  <div className="col-md-6">
                    <label className="lh-sm fs-14 fw-bold control-label mb-2 form-label">
                      How many units of alcohol do you consume weekly?
                    </label>
                  </div>
                  <div className="col-md-6">
                    <Row>
                      <div className="col-md-6">
                        <InputText
                          controlId="alcohol_units"
                          label=""
                          placeholder="Enter"
                          touched={touched.health_questions?.alcohol_units}
                          handleBlur={handleBlur}
                          handleChange={(event: any) => {
                            setFieldValue(
                              "health_questions.alcohol_units",
                              event.target.value
                            );
                          }}
                          errorsField={errors.health_questions?.alcohol_units}
                          value={values.health_questions.alcohol_units}
                        />
                      </div>
                    </Row>
                  </div>
                  
                </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                <Row>
                  <InputRadio
                    controlId="smoke"
                    label="Do you smoke?"
                    name="smoke"
                    data={[
                      { key: "1", name: "Yes" },
                      { key: "0", name: "No" },
                    ]}
                    handleChange={(event: any) => {
                      setFieldValue(
                        "health_questions.smoke",
                        event.target.value
                      );
                    }}
                    value={values.health_questions.smoke}
                    errorsField={errors.health_questions?.smoke}
                    touched={touched.health_questions?.smoke}
                  />
                </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                <Row>
                  <InputRadio
                    controlId="medicine"
                    label="Are you currently taking prescribed medicine?"
                    name="medicine"
                    data={[
                      { key: "1", name: "Yes" },
                      { key: "0", name: "No" },
                    ]}
                    handleChange={(event: any) => {
                      setFieldValue(
                        "health_questions.medicine",
                        event.target.value
                      );
                    }}
                    value={values.health_questions.medicine}
                    errorsField={errors.health_questions?.medicine}
                    touched={touched.health_questions?.medicine}
                  />
                </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                <Row>
                  <InputRadio
                    controlId="under_care"
                    label="Are you currently under the care of a doctor or other medical professional?"
                    name="under_care"
                    data={[
                      { key: "1", name: "Yes" },
                      { key: "0", name: "No" },
                    ]}
                    handleChange={(event: any) => {
                      setFieldValue(
                        "health_questions.under_care",
                        event.target.value
                      );
                    }}
                    value={values.health_questions.under_care}
                    errorsField={errors.health_questions?.under_care}
                    touched={touched.health_questions?.under_care}
                  />
                </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                <Row>
                  <div className="col-md-6">
                    <label className="lh-sm fs-14 fw-bold control-label mb-2 form-label">When did you last consult your GP and why?</label>
                  </div>
                  <div className="col-md-6">
                    <Row>
                        <div className="col-md-12">
                        <InputText
                          controlId="last_consult_gp"
                          label=""
                          placeholder="Enter"
                          touched={touched.health_questions?.last_consult_gp}
                          handleBlur={handleBlur}
                          handleChange={(event: any) => {
                            setFieldValue(
                              "health_questions.last_consult_gp",
                              event.target.value
                            );
                          }}
                          errorsField={errors.health_questions?.last_consult_gp}
                          value={values.health_questions.last_consult_gp}
                        />
                      </div>
                    </Row>
                  </div>
                  
                </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                <Row>
                  <label className="lh-sm fs-14 fw-bold control-label mb-2 form-label">
                    Are you currently suffering from or have suffered from any
                    of the illnesses listed below:
                  </label>
                  <div className="medical-form-full">
                  <InputCheckBoxGroup
                    controlId="illness"
                    touched={touched.health_questions?.illness}
                    data={illnessData}
                    handleChange={(event: any) => {
                      if (values.health_questions.illness === null) {
                        values.health_questions.illness = [];
                      }
                      if (event.target.checked) {
                        setFieldValue("health_questions.illness", [
                          ...values.health_questions.illness,
                          event.target.value,
                        ]);
                      } else {
                        setFieldValue(
                          "health_questions.illness",
                          values.health_questions.illness.filter(
                            (item) => item !== event.target.value
                          )
                        );
                      }
                    }}
                    handleBlur={handleBlur}
                    errorsField={errors.health_questions?.illness}
                    existingData={values.health_questions?.illness ?? []}
                  />
                  </div>
                  
                </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                <Row>
                  <div className="col-md-6">
                    <label className="lh-sm fs-14 fw-bold control-label mb-2 form-label">
                      Please give details of above checked illness (if checked)
                    </label>
                  </div>
                  <div className="col-md-6">
                    <Row>
                      <div className="col-md-12">
                        <InputText
                          controlId="details_of_illness"
                          label=""
                          placeholder="Enter"
                          touched={touched.health_questions?.details_of_illness}
                          handleBlur={handleBlur}
                          handleChange={(event: any) => {
                            setFieldValue(
                              "health_questions.details_of_illness",
                              event.target.value
                            );
                          }}
                          errorsField={errors.health_questions?.details_of_illness}
                          value={values.health_questions.details_of_illness}
                        />
                      </div>
                    </Row>
                  </div>
                  
                </Row>
                </div>
                <div className="form-hrmc-statment medical-form-first">
                  <Row>
                    <div className="col-md-6">
                      <label className="lh-sm fs-14 fw-bold control-label mb-2 form-label">
                        Do you suffer from any disabilities, which may be relevant
                        to your employment? If so please identify:
                      </label>
                    </div>
                    <div className="col-md-6">
                      <Row>
                        <div className="col-md-12">
                          <InputText
                            controlId="disabilities"
                            label=""
                            placeholder="Enter"
                            touched={touched.health_questions?.disabilities}
                            handleBlur={handleBlur}
                            handleChange={(event: any) => {
                              setFieldValue(
                                "health_questions.disabilities",
                                event.target.value
                              );
                            }}
                            errorsField={errors.health_questions?.disabilities}
                            value={values.health_questions.disabilities}
                          />
                        </div>
                      </Row>
                    </div>
                  </Row>
                </div>
              </Card>

              
              <Card className="p-3 w-100 pageContentInner mb-2">
                <h3 className="fs-20 fw-bold info-text mb-3">Declaration</h3> 
                <p className="fs-14 control-label">I hereby declare that the information given is full and true to the best of my knowledge. I understand that if, at a later date, it is discovered that I have knowingly withheld medical information, disciplinary action may be taken against me, which may include dismissal.</p>
                <Row>
                  <div className="col-md-4">
                    <InputText
                      controlId="declaration_full_name"
                      label="FULL NAME (USE CAPITAL LETTERS)"
                      placeholder="Enter FULL NAME"
                      touched={touched.declaration_full_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.declaration_full_name}
                      value={values.declaration_full_name}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputDatePicker
                      name="declaration_date"
                      label="Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          placeholder: "Select",
                          className: "form-control",
                          id: "declaration_date",
                        },
                      }}
                      value={values.declaration_date}
                      onChange={(name, newValue) =>
                        setFieldValue(name, newValue)
                      }
                      touched={touched.declaration_date}
                      errors={errors.declaration_date}
                    />
                  </div>
                </Row>
                <Row>
                <div className="col-md-4">
                <div className="position-relative">
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{ className: "sigPad" }}
                    onEnd={(e: any) => {
                      setSignatureOnChange(setFieldValue);
                    }}
                  />
                  <Button className="btn-outline-secondary cav-close" type="button" onClick={() => clearSignature(setFieldValue)} ><ClearIcon /></Button>
                   </div>
                  <p>Sign here using MOUSE or TOUCH screen if using a tablet.</p>
                  {errors.sign && touched.sign ? (
                    <div className="error-message text-danger">{errors.sign}</div>
                  ) : null}

                    
                  </div>
                </Row>
              </Card>

              <div className="text-end my-3 ">
                <CustomButton
                  type="submit"
                  loading={btnLoading}
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Submit
                </CustomButton>
              </div>

              {isSaveConfirmationPopupOpen && (
                <DeletePopup
                  isOpen={isSaveConfirmationPopupOpen}
                  onClose={handleClose}
                  onDelete={() => {
                    submitForm();
                  }}
                  actionType="submit"
                  label="Are you sure?"
                  text="Once you submit, you will not be able to fill and save the Medical questionnaire again, are you sure to submit?"
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};

export default FormMedical;
