import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, Col, Row } from "react-bootstrap";

import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { getLeaveDetailById, updateLeaveStatusApi } from "../../../services/leavesService";
import constants, { LeaveStatus, MEETING_SOURCE_PAGES } from "../../../constants/constants";
import moment from "moment";
import { selectAuthentication } from "../../../features/Auth/authSlice";
import { useSelector } from "react-redux";
import AddEmployeeMeeting from "../../Employees/View/Meetings/Add";
import ApproveLeave from "./approveLeave";
import { checkAbility } from "../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";

export default function ViewLeaveDetail() {
  const [loading, setLoading] = useState(true);
  const [leaveDetailData, setLeaveDetailData] = useState<any>({});
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState<any>(AddPhotoAlternate);
  let { id, empId } = useParams<string>();
  const {user }:any = useSelector(selectAuthentication);
  const userId = user.user.id;
  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [isNewMeetingPopupOpen, setNewMeetingPopupOpen] = useState(false);
  const [isMutation, setIsMutation] = useState(false);
  const [approveType, setApproveType] = useState("");

  const handleRejectClick = () => {
    setRejectPopupOpen(true);  
    setApproveType('reject');  
  };
  const handleApproveClick = () => {
    setRejectPopupOpen(true);  
    setApproveType('approve');  
  };

  /**
     * Add New meeting click handler
     */
  const addNewMeeting = () => {   
    setNewMeetingPopupOpen(!isNewMeetingPopupOpen);
  };

  /**
   * Get existing letter template from server
   * @async
   * @param {id} ID of template
   */
  const getLeaveDetail = async (id: string) => {
    setLoading(true);
    await getLeaveDetailById(id, empId)
      .then((response: any) => {
        setLeaveDetailData(response.data);
        if(response.data?.user?.employeeDetails?.user_photo){
          setImagePreview(response.data.user.employeeDetails.user_photo)
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  const handleClose = (closeType?:any) => {    
    setRejectPopupOpen(false);  
    setNewMeetingPopupOpen(false);  
    if(typeof closeType == 'boolean' && closeType) {
      setIsMutation(!isMutation);
    }
  };

  /**
   * Cancel handler - redirection to listing
   * @returns {*}
   */
  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) {
      getLeaveDetail(id);
    } else {
      navigate(-1);
    }
  }, [isMutation]);

  const sanitizeHTML = (html: string) => {
    return { __html: html };
  };

  const renderStatusDiv = ( checkedVal: number | string |undefined) => {
    const statsuArr:any = LeaveStatus;
    const statusName = statsuArr.find(
      (item: { key: string | number | undefined; }) => item.key == checkedVal
    );
    return <span className={`${statusName?.tagClass} py-1 px-2 rounded-1 d-inline-block fs-14 vertical-middle`}>{statusName?.value}</span>
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Leave Details</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="p-3 w-100 pageContentInner">
              <div>
                <Row className="align-items-center">
                  <Col className="col-md-3 col-xl-3">
                    <div className="media align-items-center">
                      <div className="project-profile me-3 vendor-dummy-img">
                        <img
                          className=""
                          src={imagePreview}
                          alt={leaveDetailData?.user?.full_name}
                        />
                      </div>
                      <div className="media-body">
                        <label className="fs-16 fw-bold">
                          {leaveDetailData?.user?.full_name}
                        </label>
                        <div className="fs-14 control-label">
                          <span className="py-1 px-2 ms-0 rounded-1 info-tag d-inline-block fs-11 fw-semibold">
                            {" "}
                            {leaveDetailData?.leaveType?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {(checkAbility(
                    PERMISSION_ACCESS.EDIT,
                    PERMISSION_MODULE.LEAVES
                  ) ||
                    leaveDetailData?.user?.employeeDetails?.reporting_manager ==
                      userId) &&
                    leaveDetailData?.user_id != userId &&
                    leaveDetailData.status == "pending" && (
                      <Col className="text-end">
                        {leaveDetailData.leaveMeetings.length == 0 && (
                          <Button
                            className="fw-bold fs-12 btn btn-outline-info me-2"
                            variant="outline-info"
                            type="button"
                            onClick={addNewMeeting}
                          >
                            <Diversity3Icon className="me-2" />
                            Create Review Meeting
                          </Button>
                        )}
                        <Button
                          className="fw-bold fs-12 text-white  btn btn-danger me-2"
                          variant="danger"
                          type="button"
                          onClick={handleRejectClick}
                        >
                          <CloseIcon className="fs-16 me-2" />
                          Reject
                        </Button>
                        <Button
                          className="fw-bold fs-12 text-white  btn btn-primary btn-green"
                          variant="primary"
                          type="button"
                          onClick={handleApproveClick}
                        >
                          <DoneIcon className="fs-16 me-2" />
                          Approve
                        </Button>
                      </Col>
                    )}
                </Row>
              </div>
            </Card>
            <Card className="p-3 w-100 pageContentInner">
              <div>
                <Row>
                  <Col className="col-md-3 col-xl-3">
                    <div className="mb-3 custom-from-group">
                      <label className="lh-sm control-label fs-14 fw-bold form-label">
                        No. of Days
                      </label>
                      <div className="position-relative ">
                        <p className="fs-14">                        
                          {leaveDetailData.leaves_count == '0.5' ?  `${leaveDetailData.leaves_count} (${leaveDetailData.session == '1' ? 'AM' : 'PM'})`  : leaveDetailData.leaves_count} (
                          {moment(leaveDetailData.date_from).format(
                            constants.dateFormats.slashSeparatedDate
                          )} 
                          { leaveDetailData.is_appointment_leave && (` ${moment(leaveDetailData.appointment_time, "HH:mm:ss").format('HH:mm')}`)}
                          -
                          {moment(leaveDetailData.date_to).format(
                            constants.dateFormats.slashSeparatedDate
                          )}
                          { leaveDetailData.is_appointment_leave && (` ${moment(leaveDetailData.appointment_time, "HH:mm:ss").add('2', 'hours').format('HH:mm')}`)}
                          )
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-3 col-xl-3">
                    <div className="mb-3 custom-from-group">
                      <label className="lh-sm control-label fs-14 fw-bold form-label">
                        Applied Date
                      </label>
                      <div className="position-relative ">
                        <p className="fs-14">
                          {moment(leaveDetailData.date_applied).format(
                            constants.dateFormats.slashSeparatedDate
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-3 col-xl-3">
                    <div className="mb-3 custom-from-group">
                      <label className="lh-sm control-label fs-14 fw-bold form-label">
                        Leave in Last 3 Months
                      </label>
                      <div className="position-relative ">
                        <p className="fs-14">
                          {leaveDetailData.leaves_3_months}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-3 col-xl-3">
                    <div className="mb-3 custom-from-group">
                      <label className="lh-sm control-label fs-14 fw-bold form-label">
                        Current Status
                      </label>
                      <div className="position-relative ">
                        {renderStatusDiv(leaveDetailData.status)}
                      </div>
                    </div>
                  </Col>
                </Row>
                {leaveDetailData.status == "reject" && (
                  <Row>
                    <div className="col-md-12 col-xl-12">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Reject Reason
                        </label>
                        <div className="position-relative ">
                          <p className="fs-14">
                            {leaveDetailData.reject_reason}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>
                )}
                <Row>
                  <div className="col-md-12 col-xl-12">
                    <div className="mb-3 custom-from-group">
                      <label className="lh-sm control-label fs-14 fw-bold form-label">
                        Description
                      </label>
                      <div className="position-relative ">
                        <p className="fs-14">{leaveDetailData.description}</p>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Card>
          </div>
          {isNewMeetingPopupOpen && (
            <AddEmployeeMeeting
              isOpen={isNewMeetingPopupOpen}
              onClose={() => handleClose(true)}
              employeeID={leaveDetailData?.user_id}
              meetingType="other"
              leaveId={id}
              source={
                leaveDetailData?.user?.employeeDetails?.reporting_manager ==
                userId
                  ? MEETING_SOURCE_PAGES.reporting_manager
                  : MEETING_SOURCE_PAGES.hr
              }
              //designation={user.user?.employeeDetails?.designation}
              loggedInName={user.user?.full_name}
              loggedInID={user.user.id}
            />
          )}

          {/*  { isApprovePopupOpen && (
            <DeletePopup
            isOpen={isApprovePopupOpen}
            onClose={handleClose}
            onDelete={handleApprove}
            label="Approve Leave Request"
            actionType="info"
            text="Are you sure you want to Approve this leave request?"
          />
          )}
          
          { isRejectPopupOpen && (<DeleteWithReasonPopup
            isOpen={isRejectPopupOpen}
            onClose={handleClose}
            onSubmitData={handleReject}                  
            deletedId={id}
            label="Reject Leave Request"
            text="Are you sure you want to reject this leave request"
            reasonText="Reject"
          />) } */}

          {isRejectPopupOpen && (
            <ApproveLeave
              isOpen={isRejectPopupOpen}
              onClose={handleClose}
              leaveId={id}
              approveType={approveType}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}
