import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../router/protected";
import { deleteRoleApi, getRolesList } from "../../services/roleService";
import Loader from "../../components/common/Loader";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeletePopup from "../../components/common/deletePopup";
import ViewIcon from '@mui/icons-material/Visibility';
import { toast } from "react-toastify";
import AddCircle from '../../assets/images/add_circle.svg';
import { Card } from "react-bootstrap";
import { pageSetting, pageSizeModel } from "../../constants/constants";


const Roles: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  const addRoles = () => {
    navigate(nameBasedProtectedRoutes.addRole.path);
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/roles/edit-role/${id}`);
  };

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/roles/view-role/${id}`);
  };
  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteRoleApi(deletedId)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        console.log("error", error);
        toast(error.response?.data?.message, { type: toast.TYPE.ERROR });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getRolesList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);
  
  const columns: GridColDef[] = [
    { field: "role", headerName: "Role Title",  flex: 1, sortable: true, hideable:false },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      sortable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      flex: 0.5,
      cellClassName: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          label="Edit"
          icon={<EditIcon />}
          showInMenu
          onClick={handleEditClick(id)}
        />,
        <GridActionsCellItem
          label="Delete"
          icon={<DeleteIcon />}
          onClick={handleDeleteClick(id)}
          showInMenu
        />,
        <GridActionsCellItem
          label="View"
          onClick={handleViewClick(id)}
          showInMenu
          icon={<ViewIcon />}
        />,
      ],
    },
  ];
  const handleSortModelChange = (params: any) => {};
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize)
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search]);

  // useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     pageInfo?.totalRowCount !== undefined
  //       ? pageInfo?.totalRowCount
  //       : prevRowCountState,
  //   );
  // }, [pageInfo?.totalRowCount, setRowCountState]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Roles</h1>
              </div>
              <div>
                <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => addRoles()}
                >
                   <img src={AddCircle} className='me-2 fs-15' alt="File Upload Icon"/>
                  Add New Role
                </Button>
               
              </div>
            </div>
          </div>
        
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className=" w-100 pageContentInner">
          <div className="d-flex flex-column  rounded-0 dataGridListMain">
          <DataGrid
                rows={data}
                {...data}
                columns={columns}
                rowCount={totalRow}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                onSortModelChange={handleSortModelChange}
                loading={loading}
                pageSizeOptions={pageSizeModel}
                className="border-0 rounded-2"

              />
              <DeletePopup
                isOpen={isDeletePopupOpen}
                onClose={handleClose}
                onDelete={handleDelete}
                label="Delete Role"
                text="Are you sure you want to delete this role?"
              />
          </div>
        </Card>
      </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Roles;
