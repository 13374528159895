// DeletePopup.tsx
import React, { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import {
  exportDealListApi,
  getOrderNumberListApi,
  getProductTypeList,
} from "../../../services/dealService";
import { Formik } from "formik";
import InputText from "../../../components/common/InputText";
import FormSelect from "../../../components/common/FormSelect";
import constants, { dealContractType, dealStatus, departmentCheck, projectStatus } from "../../../constants/constants";
import { getPostalCodes } from "../../../services/postalcode.service";
import InputDateRangePicker from "../../../components/common/InputDateRangePicker";
import moment from "moment";
import { getAllManagerList } from "../../../services/authService";
import { toast } from "react-toastify";

interface ExportDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  exportType: string;
  text: string;
}

const ExportData: FC<ExportDataPopupProps> = ({
  isOpen,
  onClose,
  label,
  text,
  exportType
}) => {
  const [orderNumber, setOrderNumber] = useState<any>(null);
  const [orderDateRange, setOrderDateRange] = useState<any>(null);
  const [productTypeData, setProductTypeData] = useState<any>({});
  const [statusData, setStatusData] = useState<any>({});
  useEffect(() => {
    fetchProductType();
    setStatusData(exportType == 'project' ? projectStatus : dealStatus );
   // console.log("yess");
  }, [isOpen]);
  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType,
        showAll : true
      }
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const getPostalCode = (params: any) => {
    return new Promise((resolve, reject) => {
      getPostalCodes(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  // fetch product type data to set in the listing
  const fetchProductType = async () => {
    return new Promise((resolve, reject) => {
      getProductTypeList().then((response) => {
        const productTypeOptions = response.data?.map((item: any) => ({
          key: String(item.id),
          value: item.name,
        }));
        setProductTypeData(productTypeOptions);
        return resolve(true);
      });
    });
  };


  const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      getAllManagerList(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const handleSubmit = (values: any) => {
    const transformedValues = {
      ...values,
      exportType: exportType,      
      orderNumber: values.orderNumber?.name,
      contract_type: values.contract_type?.key,
      postal_code_id: values.postalcode?.id,
      project_owner_id: values.projectowner?.id,
      project_type_id: values.projectType?.key,
      sales_rep_id: values.sales_representative?.id,
      status:values.status?.key,
      order_date:orderDateRange
    };
    delete transformedValues.postalcode;
    delete transformedValues.project;
    delete transformedValues.projectowner;
    delete transformedValues.projectType;
    delete transformedValues.sales_representative;
   // console.log(transformedValues);
  //  return;
    exportDealListApi(transformedValues)
      .then((response) => {
        // Create a Blob object from the response data
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()} _${exportType}s_report.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
        onClose();
      })
      .catch((error) => {
        if (error.response.status ==" 404") {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`No records available for export.`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const orderDateFilter = (data: any) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(constants.dateFormats.databaseFormat);
      const endDate = moment(dataRange[1]).format(constants.dateFormats.databaseFormat);
      setOrderDateRange(`${startDate},${endDate}`);
    } else {
      setOrderDateRange("");
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          orderNumber: null,
          sales_representative: null,
          contract_type: null,
          postalcode: null,
          order_value: "",
          project: null,
          projectowner: null,
          order_date: null,
          projectType: null,
          status: null,
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            className="export-excel-modal"
            show={isOpen}
            onHide={onClose}
            centered
          >
            <Form className="" noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <div className="fs-18 fw-bold mb-3 info-text">
                  Export to Excel
                </div>

                <div className="row">
                  {exportType == "project" && (
                    <>
                      <div className="col-md-6">
                        <FormAsyncSelect
                          id="orderNumber"
                          name="orderNumber"
                          label="Project order No."
                          isAsync
                          isClearable
                          getOptions={getOrderNumber}
                          value={values.orderNumber}
                          onChange={(name: any, value: any) => {
                            setFieldValue(name, value);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <FormAsyncSelect
                          id="sales_representative"
                          name="sales_representative"
                          label="Sales Representative"
                          isAsync
                          isClearable
                          defaultFilter={{"department": departmentCheck.SA}}
                          getOptions={getProjectOwner}
                          value={values.sales_representative}
                          onChange={(name: any, value: any) => {
                            setFieldValue(name, value);
                          }}
                        />                       
                      </div>
                    </>
                  )}

                  <div className="col-md-6">
                    <FormSelect
                      placeholder="Select"
                      label="Contract Type"
                      name="contract_type"
                      options={dealContractType}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.contract_type}
                      error={errors.contract_type}
                      touched={touched.contract_type}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormAsyncSelect
                      id="postalcode"
                      name="postalcode"
                      label="Postal Code"
                      isAsync
                      isClearable
                      getOptions={getPostalCode}
                      value={values.postalcode}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      controlId="order_value"
                      label="Order Value (£)"
                      placeholder="Enter Order Value"
                      handleChange={handleChange}
                      value={values.order_value}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputDateRangePicker
                      controlId="order_date"
                      label="Order Date Range"
                      placeholder="Date Range"
                      touched={false}
                      handleBlur={false}
                      handleChange={orderDateFilter}
                      handleClose={orderDateFilter}
                      errorsField={false}
                      value={values.order_date}
                      position="bottomEnd"
                    />
                  </div>
                  {exportType == "project" && (
                    <div className="col-md-6">
                      <FormAsyncSelect
                        id="projectOwner"
                        name="projectowner"
                        label="Project Owner"
                        isAsync
                        isClearable
                        getOptions={getProjectOwner}
                        value={values.projectowner}
                        onChange={(name: any, value: any) => {
                          setFieldValue(name, value);
                        }}
                      />
                    </div>
                  )}
                  {exportType == "deal" && (
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="Select"
                      label="Project Type"
                      name="projectType"
                      options={productTypeData}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.projectType}
                      error={errors.projectType}
                      touched={touched.projectType}
                    />
                  </div>
                  )}
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="Select"
                      label="Status"
                      name="status"
                      options={statusData}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.status}
                      error={errors.status}
                      touched={touched.status}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                <button
                  className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <Button
                  type="submit"
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Export
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ExportData;
