import React, { FC } from "react";
import Modal from "react-bootstrap/Modal";
import InfoIcon from "@mui/icons-material/Info";
import { ApplyLeave } from "../../Leaves/Add";

interface AddLeaveProps {
  isOpen: boolean;
  onClose: (isReload?:boolean) => void;
  employeeID: any;
}

const AddEmployeeLeave: FC<AddLeaveProps> = ({
  isOpen,
  onClose,
  employeeID,
}) => {
  return (
    <>
      <Modal
        className="export-excel-modal"
        show={isOpen}
        onHide={onClose}
        centered
      >
        <Modal.Header className="border-0"></Modal.Header>
        <Modal.Body className="pt-0 position-relative">
          <button
            type="button"
            className="btn-close text-right  btn-option-cover"
            aria-label="Close"
            onClick={() => onClose()}
          ></button>
          <h4 className="fw-bold info-text mb-3">Apply Leave</h4>
          <ApplyLeave uType="rm" employeeID={employeeID} onClose={onClose}/>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center mb-3 pt-0"></Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEmployeeLeave;
