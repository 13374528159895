import { axios } from "./axios";
import {
  LIBRARY_LIST,
  LIBRARY_ADD,
  LIBRARY_DELETE,
  LIBRARY_DETAIL,
  LIBRARY_EDIT,
  LIBRARY_VERSION,
} from "../constants/paths";

export type createLibraryDTO = {
  title: string;
  description: string;
};
export const getLibraryList = (formData: any): Promise<any> => {
  return axios.get(LIBRARY_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const getLibraryVersionHistoryList = (formData: any): Promise<any> => {
  return axios.get(LIBRARY_VERSION, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const createLibraryApi = (data: createLibraryDTO): Promise<any> => {
  const transformData = {
    ...data,
  };

  return axios.post(LIBRARY_ADD, transformData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getLibraryByIdApi = (id: string | number): Promise<any> => {
  return axios.get(LIBRARY_DETAIL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateLibraryApi = (
  id: string | number | undefined,
  data: createLibraryDTO
): Promise<any> => {
  const transformData = {
    ...data,
  };
  return axios.put(LIBRARY_EDIT + "/" + id, transformData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteLibraryApi = (id: string | number | null): Promise<any> => {
  return axios.delete(LIBRARY_DELETE + "/" + id);
};
