// DeletePopup.tsx
import  { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import {  
  getOrderNumberListApi,  
} from "../../../services/dealService";
import { Formik } from "formik";
import FormSelect from "../../../components/common/FormSelect";
import constants, { DpStatus, dealContractType, dealStatus, projectStatus } from "../../../constants/constants";
import InputDateRangePicker from "../../../components/common/InputDateRangePicker";
import moment from "moment";
import { getAllManagerList } from "../../../services/authService";
import { toast } from "react-toastify";
import { exportDpData } from "../../../services/warehouseService";

interface ExportDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  exportType: string;
  text: string;
}

const ExportDispatchOrdersData: FC<ExportDataPopupProps> = ({
  isOpen,
  onClose,
  label,
  text,
  exportType
}) => { 
  const [orderDateRange, setOrderDateRange] = useState<any>(null);
  useEffect(() => {
   // setStatusData(exportType == 'project' ? projectStatus : dealStatus );
   // console.log("yess");
  }, [isOpen]);
 
  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType: "project",
        showAll : true
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const handleSubmit = (values: any) => {
    const transformedValues = {
      ...values,     
      contract_id: values.contract_id?.id,     
      driver_id: values.driver_id?.id,     
      received_date: values?.received_date_arr,
      dispatched_date: values?.dispatched_date_arr,
      status: values?.status?.key
    //  order_date:orderDateRange
    };   

    delete transformedValues.received_date_arr;
    delete transformedValues.dispatched_date_arr;
    
  // console.log(transformedValues);
  //  return;
    exportDpData(transformedValues)
      .then((response) => {
        // Create a Blob object from the response data
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()} _dispatch_orders_data.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
        onClose();
      })
      .catch((error) => {
        if (error.response.status ==" 404") {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`No records available for export.`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      const transformParam = {
        ...params,
        isDriver: true,
      };
      getAllManagerList(transformParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const orderDateFilter = (data: any, fieldName:string, setFieldValue:any) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(constants.dateFormats.databaseFormat);
      const endDate = moment(dataRange[1]).format(constants.dateFormats.databaseFormat);
      setFieldValue(fieldName,`${startDate},${endDate}`);
      setOrderDateRange(`${startDate},${endDate}`);
    } else {
      setOrderDateRange("");
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          status: null,          
          driver_id: null,          
          contract_id: null,          
          received_date: null,
          dispatched_date: null,
          dispatched_date_arr: null
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            className="export-excel-modal"
            show={isOpen}
            onHide={onClose}
            centered
          >
            <Form className="" noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <div className="fs-24 fw-bolder mb-3 info-text">
                  Export to Excel
                </div>

                <div className="row">
                  
                      <div className="col-md-6">
                        <FormAsyncSelect
                          id="contract_id"
                          name="contract_id"
                          label="Project Order No."
                          isAsync
                          isClearable
                          getOptions={getOrderNumber}
                          value={values.contract_id}
                          onChange={(name: any, value: any) => {
                            setFieldValue(name, value);
                          }}
                        />
                      </div>                                        
                 
                  <div className="col-md-6">
                    <InputDateRangePicker
                      controlId="dispatched_date"
                      label="Dispatch Date Range"
                      placeholder="Select"
                      touched={false}
                      handleBlur={false}
                      handleChange={(e :any) => {
                        setFieldValue("dispatched_date",e)
                        orderDateFilter(e,'dispatched_date_arr',setFieldValue);
                      }}
                      handleClose={orderDateFilter}
                      errorsField={false}
                      value={values.dispatched_date}
                      position="bottomEnd"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputDateRangePicker
                      controlId="received_date"
                      label="Received Date Range"
                      placeholder="Select"
                      touched={false}
                      handleBlur={false}
                      handleChange={(e :any) => {
                        setFieldValue("received_date",e)
                        orderDateFilter(e,'received_date_arr',setFieldValue);
                      }}
                      handleClose={orderDateFilter}
                      errorsField={false}
                      value={values.received_date}
                      position="bottomEnd"
                    />
                  </div>
                  <div className="col-md-6">
                        <FormAsyncSelect
                          id="driver_id"
                          name="driver_id"
                          label="Driver"
                          isAsync
                          isClearable
                          getOptions={getProjectOwner}
                          value={values.driver_id}
                          onChange={(name: any, value: any) => {
                            setFieldValue(name, value);
                          }}
                        />
                      </div>  
                  <div className="col-md-6">
                  <FormSelect
                      placeholder="Select"
                      label="Status"
                      name="status"
                      options={DpStatus}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.status}
                      error={errors.status}
                      touched={touched.status}
                    />
                      </div>  
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                <button
                  className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <Button
                  type="submit"
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Export
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ExportDispatchOrdersData;
