import * as yup from "yup";
import { useParams } from "react-router-dom";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_500_CHAR,
  ALPHA_NUMERIC_ALLOW,
  SELECT_ONE_USER_IN_RECEIPIENT_LIST,
  MAXIMUM_30_CHAR,
  MAXIMUM_255_CHAR
} from "../../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../../validations/regex";

export const InductionValidationSchema = (id: string | number | undefined) => {
  return yup.object({
    name : yup.string().min(3,MINIMUM_3_CHAR).max(30,MAXIMUM_30_CHAR).trim().required().label('Induction Process Name'),
    remarks : yup.string().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).trim().required().label('Description'),   
  });
}
