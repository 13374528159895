import * as yup from "yup";
import {  
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,    
  MAXIMUM_500_CHAR,
  INVALID_DATE,
} from "../../../validations/ValidationErrors";

export const complaintSchema = yup.object({
  contract_id: yup
    .object()
    .required()    
    .label("Project Order Number"),
  title: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Service Log title"),
  description: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .label("Description"),  
  assigned_to: yup
    .object()
    .required()    
    .label("Assignee"),
  task_owner_id: yup
    .object()
    .required()    
    .label("Project Task Owner"),
  due_date: yup
    .date()
    .required()    
    .typeError(INVALID_DATE)
    .label("Due Date"),
  department: yup
    .object()
    .required()    
    .label("Department")
});
