import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "../../../../components/common/Loader";
import { getHmrcForm, downloadHmrcForm, downloadBlankHmrcForm } from "../../../../services/hmrcService";

import ViewHmrc from "../../../Hmrc/ViewHmrc";

import CustomButton from "../../../../components/common/CustomButton";

const Hmrc: React.FC<Record<string, number>> = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [hmrcData, setHmrcData] = useState<any>(null);
  const [signatureUrl, setSignatureUrl] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    if (id) {
      getHmrcForm(id)
        .then((response) => {
          setHmrcData(response?.data);
          setSignatureUrl(response?.signatureUrl);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
          }
        });
    } else {
      setLoading(false);
    }
  };

  const onDownloadPDF = () => {
    if(btnLoading === true){
      toast('Previous download request is still in progress!!!', { type: toast.TYPE.ERROR });
      return;
    }

    const downloadAction = () => {
      // Provide the appropriate API request function
      return hmrcData && id
        ? downloadHmrcForm(id)
        : downloadBlankHmrcForm();
    };

    setBtnLoading(true);
    if (id) {
      downloadAction()
        .then((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = hmrcData ? 'HMRC-form.pdf' : 'HMRC-blank-form.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          
          setBtnLoading(false);
        })
        .catch((error) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
          }
        });
    }else{
      setBtnLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">HMRC Form</h1>
              </Col>
              <Col className="text-end">
              <CustomButton
                type="button"
                loading={btnLoading}
                className="fw-semibold fs-13 text-white mw-60 mt-2"
                variant="primary"
                onClick={onDownloadPDF}
            >
                {hmrcData ? 'Download PDF' : 'Download Blank PDF'}
            </CustomButton>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
              {hmrcData && <ViewHmrc hmrcData={hmrcData} signatureUrl={signatureUrl} />}
              {hmrcData === null && <p>Employee have not filled the HMRC form yet!!!</p>}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Hmrc;
