import React, { useEffect } from 'react';
import styled from 'styled-components';

// Define your array of background colors
//const backgroundColors: string[] = ['#F6F2E5', '#FF9854', '#C852FF', '#24FF61', '#DCBAFF','#ABABAB','#B4E9FC','#FF5353'];

//add backgroundColors with  key value pair like bg : '#FF9854', color : '#FF9854'
const backgroundColors: {bg:string, color:string}[] = [
  { bg: 'rgba(41, 127, 206, 0.2)', color: '#297FCE' },
  { bg: 'rgba(238, 48, 48, 0.15)', color: '#EE3030' },
  { bg: 'rgba(239, 167, 60, 0.2)', color: '#EFA73C' },
  { bg: 'rgba(33, 172, 89, 0.2)', color: '#21AC59' },
 
  


   
   
   
];
// Function to extract user ID from class name
const getUserIdFromClassName = (className: string): number | null => {
  const match = className.match(/bg-event-(\d+)/);
  return match ? parseInt(match[1], 10) : null;
};

// Function to select background color based on user ID
const getBackgroundColor = (userId: number | null, type: 'bg' | 'color'): string => {
  if (userId !== null) {
    const colorIndex: number = userId % backgroundColors.length;
    return backgroundColors[colorIndex][type];
  }
  // Return a default color if user ID is not provided or invalid
  return '#CCCCCC';
};

// Styled component with dynamic background color
const DynamicBackground = styled.div<{ className: string}>`
  background-color: ${props => getBackgroundColor(getUserIdFromClassName(props.className), 'bg')};
  
  color: ${props => getBackgroundColor(getUserIdFromClassName(props.className), 'color')};
  padding: 5px 8px;
  border-radius: 5px;  
  border: 1px solid   ${props => getBackgroundColor(getUserIdFromClassName(props.className), 'bg')} !important;
`;

interface DynamicClassComponentProps {
  className: string;
  children: React.ReactNode;

}

const DynamicClassComponent: React.FC<DynamicClassComponentProps> = ({ className='bg-event-0',children }) => {

  useEffect(() => {
    console.log('className in',className);
  },  
  [className]);

  return (
    <DynamicBackground className={className} >
      {children}
    </DynamicBackground>
  );
};

export default DynamicClassComponent;
