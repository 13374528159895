import React, { useEffect, useState, FC } from "react";
import {  useSelector } from 'react-redux';
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { profileValidationSchema } from "./profileValidation";
import { toast } from "react-toastify";
import InputText from "../../components/common/InputText";
import { Card, Col, Image, Row, Spinner } from "react-bootstrap";
import InputTextArea from "../../components/common/InputTextArea";
import {
  getProfileUpdateRequest
} from "../../services/employeeService";
import {
  editProfile,
  getProfileAPI,
} from "../../services/authService";
import Loader from "../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import FormSelect from "../../components/common/FormSelect";
import { EmployeeDataType } from "./profiledata.type";
import {
  allowedFileTypes,
  commonMasking,
  maxFileSizeMB,
  relatioinOptions,
} from "../../constants/constants";
import { phoneMask, getDirtyValues } from "../../utility/common";
import FileUploader from "../../components/common/FileUploader";
import {
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
} from "../../validations/ValidationErrors";
import CustomButtonCancel from "../../components/common/CustomButtonCalcel";
import { selectAuthentication } from "../../features/Auth/authSlice";

interface pendingField{
    label: string;
    value: string
}

const PendingVerficationField: FC<pendingField> = ({label, value}) => {
    return (
        <div className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold form-label">
                {label}
            </label>
            <div className="position-relative ">
            <p className="fs-14">{ `${value} (Verification Pending)` }</p>
            </div>
        </div>
    )
}

const EditProfile:FC = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [profileUpdateReqData, setProfileUpdateReqData] = useState<any>({});
  const { user }: any = useSelector(selectAuthentication);
  const navigate = useNavigate();

  let { id } = useParams();

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: EmployeeDataType, { setSubmitting }: any, initialValues:EmployeeDataType) => {
    // Check if any field has been updated by user, if yes then only send the change request to server
    const dirtyFields = getDirtyValues(values, initialValues);
    if(!Object.keys(dirtyFields).length){
      toast('No Change request made', { type: toast.TYPE.SUCCESS });
      navigate("/profile/view");
    }else{
      const formData = {
        ...values,
      };
  
      setBtnLoading(true);
      
      editProfile(formData)
      .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/profile/view");
          setBtnLoading(false);
          setBtnLoading(false);
      })
      .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
          });
          } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
          });
          }
      }); 
    }
  };

  useEffect(() => {
    getEmployeeDetails(user.user.id);
  }, []);
  

  const getEmployeeDetails = (id: string) => {
    setLoading(true);
    getProfileAPI(id)
      .then((response) => {
        setEmployeeData(response.data);
        if (response.usePhoto) {
          setImagePreview(response.usePhoto);
        }
        getProfileUpdateRequest(id).then((profileReqResponse) => {
            setProfileUpdateReqData(profileReqResponse.data.employeeProfileUpdate);
        });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
          });
          } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
          });
          }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadProfileImage = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    if(imageFile){
      // Check file type
      if (!allowedFileTypes.includes(imageFile.type)) {
        toast(FILE_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
        toast(FILE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        setFieldValue('user_photo', imageFile);
        if (imageFile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(imageFile);
        } else {
            setImagePreview(null);
        }
      }
    }
  };

  const initialValues: EmployeeDataType = {
    first_name: employeeData?.first_name ? employeeData?.first_name : "",
    last_name: employeeData?.last_name ? employeeData?.last_name : "",
    phone_no: employeeData.phone_no ? employeeData.phone_no : "",
    address: employeeData?.employeeDetails?.address
      ? employeeData?.employeeDetails?.address
      : "",
    city: employeeData?.employeeDetails?.city
      ? employeeData?.employeeDetails?.city
      : "",
    state: employeeData?.employeeDetails?.state
      ? employeeData?.employeeDetails?.state
      : "",
    postal_code: employeeData?.employeeDetails?.postal_code
      ? employeeData?.employeeDetails?.postal_code
      : "",
    ni_number: employeeData?.employeeDetails?.ni_number
      ? employeeData?.employeeDetails?.ni_number
      : "",
    job_description: employeeData?.employeeDetails?.job_description
      ? employeeData?.employeeDetails?.job_description
      : "",
    keen_name1: employeeData?.kinDetails?.keen_name1
      ? employeeData?.kinDetails?.keen_name1
      : "",
    keen_contact_no1: employeeData?.kinDetails?.keen_contact_no1
      ? employeeData?.kinDetails?.keen_contact_no1
      : "",
    keen_relation1: employeeData?.kinDetails
      ? relatioinOptions.find(
          (item) => item.key == employeeData.kinDetails.keen_relation1
        )
      : relatioinOptions[0],
    keen_name2: employeeData?.kinDetails?.keen_name2
      ? employeeData?.kinDetails?.keen_name2
      : "",
    keen_contact_no2: employeeData?.kinDetails?.keen_contact_no2
      ? employeeData?.kinDetails?.keen_contact_no2
      : "",
    keen_relation2: employeeData?.kinDetails
      ? relatioinOptions.find(
          (item) => item.key == employeeData.kinDetails.keen_relation2
        )
      : relatioinOptions[0],
    bank_name: employeeData?.emplyeeBankDetails?.bank_name
      ? employeeData?.emplyeeBankDetails?.bank_name
      : "",
    account_name: employeeData?.emplyeeBankDetails?.account_name
      ? employeeData?.emplyeeBankDetails?.account_name
      : "",
    account_number: employeeData?.emplyeeBankDetails?.account_number
      ? employeeData?.emplyeeBankDetails?.account_number
      : "",
    sort_code: employeeData?.emplyeeBankDetails?.sort_code
      ? employeeData?.emplyeeBankDetails?.sort_code
      : "",
    user_photo: employeeData?.employeeDetails?.user_photo
      ? employeeData?.employeeDetails?.user_photo
      : "",
      personal_email : employeeData?.employeeDetails?.personal_email
      ? employeeData?.employeeDetails?.personal_email
      : "",
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  Edit Profile
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={() => profileValidationSchema(profileUpdateReqData)}
              initialValues={initialValues}
              onSubmit={(values: EmployeeDataType, actions) => {
                submitForm(values, actions, initialValues);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Card className="p-3 w-100 pageContentInner mb-3">
                    <div>
                      <Row>
                        <div className="col-md-8">
                          <Row>
                            <div className="col-md-6">
                              {!profileUpdateReqData.users?.first_name ? 
                              <InputText
                              controlId="first_name"
                              label="First Name"
                              placeholder="Enter first name"
                              touched={touched.first_name}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.first_name}
                              value={values.first_name}
                            /> : <PendingVerficationField label = {'First Name'} value = {profileUpdateReqData.users.first_name} /> }
                            </div>

                            <div className="col-md-6">
                              {!profileUpdateReqData.users?.last_name ? <InputText
                                controlId="last_name"
                                label="Family Name"
                                placeholder="Enter family name"
                                touched={touched.last_name}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.last_name}
                                value={values.last_name}
                              /> : <PendingVerficationField label = {'Family Name'} value = {profileUpdateReqData.users.last_name} />}
                            </div>
                            
                            
                           
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                {!profileUpdateReqData.users?.phone_no ? <InputText
                                    controlId="phone_no"
                                    label="Contact Number"
                                    placeholder="Enter contact number"
                                    touched={touched.phone_no}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    errorsField={errors.phone_no}
                                    value={phoneMask(values.phone_no)}
                                    mask={commonMasking}
                                  /> : <PendingVerficationField label = {'Contact Number'} value = {profileUpdateReqData.users.phone_no} />}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {!profileUpdateReqData.users?.personal_email ? <InputText
                                controlId="personal_email"
                                label="Personal Email"
                                placeholder="Enter personal email"
                                touched={touched.personal_email}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.personal_email}
                                value={values.personal_email}
                              /> : <PendingVerficationField label = {'Personal Email'} value = {profileUpdateReqData.users.personal_email} />}
                            </div>
                          </Row>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 custom-from-group">
                            <label className="lh-sm control-label fs-14 fw-bold form-label">
                              Profile Photo
                            </label>
                            <div className="position-relative ">
                            {!profileUpdateReqData.Employ_details?.user_photo ? <FileUploader
                                uploadProfileImage={(e) =>
                                  uploadProfileImage(e, setFieldValue)
                                }
                                imageLoading={imageLoading}
                                imagePreview={imagePreview}
                              /> : <><Image className="img-fluid admin-profile-box" src={profileUpdateReqData.Employ_details.user_photo_url} alt="Employee Image"  />
                              <div className="position-relative ">
                                <p className="fs-14">{ `(Verification Pending)` }</p>
                                </div>
                              </>}
                            </div>
                           
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-4">
                        {!profileUpdateReqData.Employ_details?.address ? <InputText
                            controlId="address"
                            label="Address"
                            placeholder="Enter address"
                            touched={touched.address}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.address}
                            value={values.address}
                          /> : <PendingVerficationField label = {'Address'} value = {profileUpdateReqData.Employ_details.address} />}
                        </div>
                        <div className="col-md-4">
                        {!profileUpdateReqData.Employ_details?.city ? <InputText
                            controlId="city"
                            label="City"
                            placeholder="Enter city"
                            touched={touched.city}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.city}
                            value={values.city}
                          /> : <PendingVerficationField label = {'City'} value = {profileUpdateReqData.Employ_details.city} />}
                        </div>
                        <div className="col-md-4">
                        {!profileUpdateReqData.Employ_details?.state ? <InputText
                            controlId="state"
                            label="County"
                            placeholder="Enter state"
                            touched={touched.state}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.state}
                            value={values.state}
                          /> : <PendingVerficationField label = {'State'} value = {profileUpdateReqData.Employ_details.state} />  }
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-4">
                        {!profileUpdateReqData.Employ_details?.postal_code ? <InputText
                            controlId="postal_code"
                            label="Postal Code"
                            placeholder="Enter postal code"
                            touched={touched.postal_code}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.postal_code}
                            value={values.postal_code}
                          /> : <PendingVerficationField label = {'Postal Code'} value = {profileUpdateReqData.Employ_details.postal_code} />}
                        </div>

                        
                        <div className="col-md-4">
                        {!profileUpdateReqData.Employ_details?.ni_number ? <InputText
                            controlId="ni_number"
                            label="NI Number"
                            placeholder="Enter NI number"
                            touched={touched.ni_number}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.ni_number}
                            value={values.ni_number}
                          /> : <PendingVerficationField label = {'NI Number'} value = {profileUpdateReqData.Employ_details.ni_number} />}
                        </div>
                      </Row>

                     

                      <Row>
                        

                        <div className="col-md-4">
                          <div className="mb-3 custom-from-group">
                            <div className="position-relative ">
                            {!profileUpdateReqData.Employ_details?.job_description ? <InputTextArea
                                controlId="job_description"
                                label=" Job Description"
                                placeholder="Enter Description"
                                touched={touched.job_description}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.job_description}
                                name="job_description"
                                value={values.job_description}
                                className="max-height300 resize-none"
                              /> : <PendingVerficationField label = {'Job Description'} value = {profileUpdateReqData.Employ_details.job_description} />}
                              <div className="invalid-feedback"></div>
                            </div>
                          </div>
                        </div>

                        
                      </Row>
                    </div>
                  </Card>
                  <Card className="p-3 w-100 pageContentInner mb-3">
                    <h6 className="fs-18 fw-bold mb-3 info-text">
                      Next of Kin Details
                    </h6>
                    <Row>
                      <div className="col-md-4">
                      {!profileUpdateReqData.Kin_Details?.keen_name1 ? <InputText
                          controlId="keen_name1"
                          label="Kin 1 - Name"
                          placeholder="Enter Kin 1 - name"
                          touched={touched.keen_name1}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.keen_name1}
                          value={values.keen_name1}
                        /> : <PendingVerficationField label = {'Kin 1 - Name'} value = {profileUpdateReqData.Kin_Details.keen_name1} />}
                      </div>

                      <div className="col-md-4">
                      {!profileUpdateReqData.Kin_Details?.keen_contact_no1 ? <InputText
                          controlId="keen_contact_no1"
                          label="Kin 1 - Contact Number"
                          placeholder="Enter Kin 1 - contact number"
                          touched={touched.keen_contact_no1}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.keen_contact_no1}
                          value={phoneMask(values.keen_contact_no1)}
                          mask={commonMasking}
                        /> : <PendingVerficationField label = {'Kin 1 - Contact Number'} value = {profileUpdateReqData.Kin_Details.keen_contact_no1} />}
                      </div>
                      <div className="col-md-4">
                      {!profileUpdateReqData.Kin_Details?.keen_relation1 ? <FormSelect
                          placeholder="Relation"
                          label="Relation"
                          name="keen_relation1"
                          options={relatioinOptions}
                          getOptionLabel={(option: any) => option.value}
                          getOptionValue={(option: any) => option.key}
                          onChange={(name: string, value: string) => {
                            setFieldValue(name, value);
                          }}
                          value={values.keen_relation1}
                          error={errors.keen_relation1}
                          touched={touched.keen_relation1}
                        /> : <PendingVerficationField label = {'Relation'} value = {profileUpdateReqData.Kin_Details.keen_relation1} />}
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-4">
                      {!profileUpdateReqData.Kin_Details?.keen_name2 ? <InputText
                          controlId="keen_name2"
                          label="Kin  2- Name"
                          placeholder="Enter Kin 2 - name"
                          touched={touched.keen_name2}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.keen_name2}
                          value={values.keen_name2}
                        /> : <PendingVerficationField label = {'Kin  2- Name'} value = {profileUpdateReqData.Kin_Details.keen_name2} />}
                      </div>

                      <div className="col-md-4">
                      {!profileUpdateReqData.Kin_Details?.keen_contact_no2 ? <InputText
                          controlId="keen_contact_no2"
                          label="Kin 2 - Contact Number"
                          placeholder="Enter Kin 2 - contact number"
                          touched={touched.keen_contact_no2}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.keen_contact_no2}
                          value={phoneMask(values.keen_contact_no2)}
                          mask={commonMasking}
                        /> : <PendingVerficationField label = {'Kin 2 - Contact Number'} value = {profileUpdateReqData.Kin_Details.keen_contact_no2} />}
                      </div>
                      <div className="col-md-4">
                      {!profileUpdateReqData.Kin_Details?.keen_relation2 ? <FormSelect
                          placeholder="Relation"
                          label="Relation"
                          name="keen_relation2"
                          options={relatioinOptions}
                          getOptionLabel={(option: any) => option.value}
                          getOptionValue={(option: any) => option.key}
                          onChange={(name: string, value: string) => {
                            setFieldValue(name, value);
                          }}
                          value={values.keen_relation2}
                          error={errors.keen_relation2}
                          touched={touched.keen_relation2}
                        /> : <PendingVerficationField label = {'Relation'} value = {profileUpdateReqData.Kin_Details.keen_relation2} />}
                      </div>
                    </Row>
                  </Card>
                  <Card className="p-3 w-100 pageContentInner mb-3">
                    <h6 className="fs-18 fw-bold mb-3 info-text">
                      Bank Details
                    </h6>
                    <Row>
                      <div className="col-md-4">
                      {!profileUpdateReqData.Employee_Bank_Details?.bank_name ? <InputText
                          controlId="bank_name"
                          label="Bank Name"
                          placeholder="Enter bank name"
                          touched={touched.bank_name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.bank_name}
                          value={values.bank_name}
                        /> : <PendingVerficationField label = {'Bank Name'} value = {profileUpdateReqData.Employee_Bank_Details.bank_name} />}
                      </div>

                      <div className="col-md-4">
                      {!profileUpdateReqData.Employee_Bank_Details?.account_name ? <InputText
                          controlId="account_name"
                          label="Account Name"
                          placeholder="Enter account name"
                          touched={touched.account_name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.account_name}
                          value={values.account_name}
                        /> : <PendingVerficationField label = {'Account Name'} value = {profileUpdateReqData.Employee_Bank_Details.account_name} />}
                      </div>
                      <div className="col-md-4">
                      {!profileUpdateReqData.Employee_Bank_Details?.account_number ? <InputText
                          controlId="account_number"
                          label="Account Number"
                          placeholder="Enter account number"
                          touched={touched.account_number}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.account_number}
                          value={values.account_number}
                        /> : <PendingVerficationField label = {'Account Number'} value = {profileUpdateReqData.Employee_Bank_Details.account_number} />}
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-4">
                      {!profileUpdateReqData.Employee_Bank_Details?.sort_code ? <InputText
                          controlId="sort_code"
                          label="Sort Code"
                          placeholder="Enter sort code"
                          touched={touched.sort_code}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.sort_code}
                          value={values.sort_code}
                        /> : <PendingVerficationField label = {'Sort Code'} value = {profileUpdateReqData.Employee_Bank_Details.sort_code} />}
                      </div>
                    </Row>
                  </Card>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-end my-3">
                        <CustomButtonCancel label="Cancel" />

                        <CustomButton
                          type="submit"
                          loading={btnLoading}
                          className="fw-semibold fs-13 text-white  mt-2"
                          variant="primary"
                        >
                          Update
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default EditProfile;