import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { getPurchaseOrderDelayedData } from "../../../services/dashboardMaster.service";
import Loader from "../../../components/common/Loader";
import moment from "moment";
import { Link } from "react-router-dom";
interface Props {
  overdueList: any;
}
const OverdueList: React.FC<Props> = ({ overdueList }) => {
     const [data, setData] = useState<any[]>(overdueList);
    const columns: GridColDef[] = [
        {
          field: "ticket_no",
          headerName: "Ticket Id",
          sortable: true,
          filterable: true,
          hideable: false,
          renderCell: (params) => {
            return <div><Link className="blue-text text-decoration-none fw-semibold" to={`/tickets/view/${params.row.id}`}>{params.row.ticket_no}</Link></div>;
          },

        },
        {
          field: "created_at",
          headerName: "Date",
          flex: 1,
          filterable: true,
          renderCell: (params) => {
            return <div>{moment(params.row.created_at).format('DD/MM/YYYY')}</div>;
          }
        },
        {
          field: "description",
          headerName: "Subject",
          flex: 1,
          renderCell: (params) => {
            return <div>{params.row.description}</div>;
          },
        },
        {
          field: "due_date",
          headerName: "Days Delayed",
          flex: 1,
          filterable: false,
          sortable: false,
          renderCell : (params) => {
             //check if due date is less than current date and count the days 
            if(params.row.due_date){
              const dueDate = new Date(params.row.due_date);
              const currentDate = new Date();
              const diffTime = Math.abs(currentDate.getTime() - dueDate.getTime());
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              return <div>{diffDays}</div>;
            }

          }
        },
       
        {
          field: "department",
          headerName: "Department",
          flex: 1,
          filterable: true,

        },{
          field:  "createdUserFullName",
          headerName: "Created By",
          flex: 1,
          filterable: true,
          renderCell: (params) => {
            return <div>{params.row.createdUserFullName}</div>;
          }
        }
         
         
      ];

      
     useEffect(() => {
      if(overdueList.length){
/*         overdueList.map((row: any, index: number) =>  ({
          ...row,
          createdUserFullName: row.createdUser.full_name,
        })); */
        const newDataArr = overdueList.map((row: any, index: number) =>  ({
          ...row,
          createdUserFullName: row.createdUser.full_name,
        }));

        console.log("overdueList",newDataArr);
        setData(newDataArr);
         
      }
   
     }, [overdueList]);


    return (
        <div className="">
                  <>  
          
              <h5 className="fs-18 info-text fw-bold mb-3">Overdue Tickets</h5>
              
              <div className="dashboard-delay-order-table dashboard-Overdue-table">
              <DataGrid
                rows={
                  data.length
                    ? data.map((row: any, index: number) => ({
                        ...row,
                        
                      }))
                    : []
                }
                columns={columns}
                pageSizeOptions={[5]}
                rowSelection={false}
                initialState={{
                     
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
               
              />
              </div>
            
        </>
      </div>
    )
}

export default OverdueList;