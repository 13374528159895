import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridFilterModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import {
    allocatedAssetsList
} from "../../../services/employeeService";
import Loader from "../../../components/common/Loader";
import AddCircle from "../../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";
import datetimeFormats, {
  pageSetting,
  pageSizeModel,

} from "../../../constants/constants";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import { checkAbility } from "../../../utility/common";

const EmployeeAllocatedAssets: React.FC<Record<string, number>> = ({employeeID}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });

  /**
   * Add asset click handler
   */
  const addAsset = () => {
    navigate(`/assets/add-asset`);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const convertDataToObjects = (data:any) => {
    return data.map((item:any) => ({
      ...item,
      created_at: new Date(item.created_at),
    }));
  };

  /**
   * Fetch allocated assets from server
   * @param {defaultParams}
   */
  const fetchData = (defaultParams: any) => {
    allocatedAssetsList(employeeID,defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet)
        setData(convertData);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    console.log("filterModel", filterModel);

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
        field: "$asset.asset_id$",
        headerName: "Asset ID",
        flex: 1,
        sortable: true,
        renderCell(params) {
          return  <span title={params.row.asset.asset_id}>{params.row.asset.asset_id}</span>;
        },
    },
    {
        field: "$asset.name$",
        headerName: "Name",
        flex: 1,
        sortable: true,
        renderCell(params) {
          return  <span title={params.row.asset.name}>{params.row.asset.name}</span>;
        },
    },
    {
        field: "$asset.serial_number$",
        headerName: "Serial Number",
        flex: 1,
        sortable: true,
        renderCell(params) {
          return  <span title={params.row.asset.serial_number}>{params.row.asset.serial_number}</span>;
        },
    },
    {
        field: "$asset.type$",
        headerName: "Type",
        flex: 1,
        sortable: true,
        renderCell(params) {
          return  <span title={params.row.asset.type}>{params.row.asset.type}</span>;
        },
    },
    { 
      field: "created_at", 
      headerName: "Allotment Date", 
      flex: 1, 
      sortable: true,
      type: 'date',
      renderCell(params) {
        return (
          <span>
            {moment(params.row.created_at).format(datetimeFormats.dateFormats.slashSeparatedDate)}
          </span>
        );
      }
    }
  ];
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []); 
    const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <h1 className="fw-bold h4 my-2 info-text fs-20">Assets Allocated</h1>
              </div>
              <div>
              {checkAbility(PERMISSION_ACCESS.ADD,PERMISSION_MODULE.ASSET_MANAGEMENT) && <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => addAsset()}
                >
                  <img
                    src={AddCircle}
                    className="me-2 fs-15"
                    alt="File Upload Icon"
                  />
                  Add Asset
                </Button>}

              </div>
            </div>
         

          
            <Card className="p-0 w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain ">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                />
              </div>
            </Card>
          
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeeAllocatedAssets;
