import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewIcon from "@mui/icons-material/RemoveRedEye";

import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import InputSwitchBox from "../../components/common/InputSwitchBox";
import { Card, Col, Row } from "react-bootstrap";
import AddCircle from "../../assets/images/add_circle.svg";
import constants, { OperationDesignation, pageSetting, pageSizeModel } from "../../constants/constants";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block";
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import FileUploadIcon from "../../assets/images/file-upload.svg";
import { deleteFleetApi, getFleetList } from "../../services/fleetService";
import moment from "moment";
import FleetDriverHistory from "./View/driverHistory";
import FleetMileage from "./View/mileage";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../features/Auth/authSlice";

const FleetList: React.FC<Record<string, string>> = ({
  uType = "",
}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [fleetData, setFleetData] = useState<any>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);  
  const [exportPopup, setExportPopup] = useState(false);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isDriverHistoryPopupOpen, setIsDriverHistoryPopupOpen] = useState(false);
  const [isMileagePopupOpen, setIsMileagePopupOpen] = useState(false);
  const {user }:any = useSelector(selectAuthentication);

  const isDriverUser = user?.user?.employeeDetails?.designation == OperationDesignation.DS ? true : false;


  //console.log(uType);

  const addNewFleet = () => {
    navigate("add");
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/fleet/edit/${id}`);
  };

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`view/${id}`);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleDriverHistoryClick = (id: GridRowId) => () => {    
    setIsDriverHistoryPopupOpen(true);
    setDeletedId(id);
  };

  const handleAddMileageClick = (id: GridRowId, fleetData:any) => () => {
    setIsMileagePopupOpen(true);
    setFleetData(fleetData);
    setDeletedId(id);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteFleetApi(deletedId)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        setDeletePopupOpen(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = (isReload:boolean = false) => {
    setDeletePopupOpen(false);
    setIsDriverHistoryPopupOpen(false);
    setIsMileagePopupOpen(false);
    setDeletedId(null);
    setFleetData(null);
    if(isReload){
      setIsMutation(!isMutation)
    }
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getFleetList(uType,defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if (filterModel.items[0].field === "asset_no") {
        filterModel.items[0].value = filterModel.items[0].value.replace(
          /[AS-]/g,
          ""
        );
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "asset_no",
      headerName: "Asset ID",
      flex: 1,
      sortable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell(params) {
        return `${params.row.asset_no_str}`;
      },
    },    
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      sortable: true,
    },
    {
      field: "registration",
      headerName: "Registration",
      flex: 1,
      sortable: true,
    },
    {
      field: "current_mileage",
      type: "number",
      headerName: "Current Mileage",
      flex: 1,
      sortable: true,
    },
    {
      field: "current_mileage_date",
      headerName: "Current Mileage Date",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.current_mileage_date),
      renderCell(params) {
        const orderDate = new Date(params.row.current_mileage_date);
        return params.row.current_mileage_date ? (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        ) : "-";
      },
    },
    { field: "ownership", headerName: "Ownership", flex: 1, sortable: true },
    {
      field: "mot_due_date",
      headerName: "Mot Due Date",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.mot_due_date),
      renderCell(params) {
        const orderDate = new Date(params.row.mot_due_date);
        return params.row.mot_due_date ? (
          <span className={moment().diff(moment(orderDate,'days')) > 0 ? "text-danger" : ""}>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        ) : "-";
      },
    },
    {
      field: "roadtax_due_date",
      headerName: "Road Tax Due Date",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.roadtax_due_date),
      renderCell(params) {
        const orderDate = new Date(params.row.roadtax_due_date);
        return (
          <span className={moment().diff(moment(orderDate,'days')) > 0 ? "text-danger" : ""}>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "insurance_due_date",
      headerName: "Insurance Due Date",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.insurance_due_date),
      renderCell(params) {
        const orderDate = new Date(params.row.insurance_due_date);
        return (
          <span className={moment().diff(moment(orderDate,'days')) > 0 ? "text-danger" : ""}>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "service_due_date",
      headerName: "Service Due Date",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.service_due_date),
      renderCell(params) {
        const orderDate = new Date(params.row.service_due_date);
        return (
          <span className={moment().diff(moment(orderDate,'days')) > 0 ? "text-danger" : ""}>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
  ];

  const handleEditViewActionPermission = () => {
    if(uType != 'driver'){
      columns.splice(1, 0, {
        field: "$driver.name$",
        headerName: "Driver",
        flex: 1,
        sortable: true,      
        renderCell(params) {
          return params.row?.driver_id  && params.row?.driver?.name ? (
            <span title={params.row?.driver?.name}>
              <Link to={`/employees/view-employee/${params.row?.driver_id}`}>{params.row?.driver?.name}</Link>
            </span>
          ) : (
            "-"
          );
        },
      }); 
    }
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.FLEET_MANAGEMENT
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.FLEET_MANAGEMENT
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.FLEET_MANAGEMENT
    );

    if (
      checkEditAbilityCondition ||
      checkViewAbilityCondition ||
      checkDeleteAbilityCondition || isDriverUser
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if (checkEditAbilityCondition && uType != 'driver') {
            gridActions.push(
              <GridActionsCellItem
                label="Edit"
                icon={<EditIcon />}
                showInMenu
                onClick={handleEditClick(id)}
              />
            );
          }
          if (checkViewAbilityCondition || isDriverUser) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                icon={<ViewIcon />}
                showInMenu
                onClick={handleViewClick(id)}
              />
            );
          }
          if (checkEditAbilityCondition && uType != 'driver') {
            gridActions.push(
              <GridActionsCellItem
                label="Driver History"
                icon={<DepartureBoardIcon />}
                showInMenu
                onClick={handleDriverHistoryClick(id)}
              />
            );
          }
          if (checkEditAbilityCondition || isDriverUser) {
            gridActions.push(
              <GridActionsCellItem
                label="Add Mileage"
                icon={<GasMeterIcon />}
                showInMenu
                onClick={handleAddMileageClick(id, row)}
              />
            );
          }
          /* if (checkDeleteAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                showInMenu
                onClick={handleDeleteClick(id)}
              />
            );
          } */
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setLimit(newPaginationModel.pageSize);
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  };

  useEffect(() => {
    //console.log(uType);
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection, uType]);

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">{ uType != 'driver' ? 'Fleet Manager' : 'My Fleets'}</h1>
              </Col>
              <Col className="text-end">             
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.FLEET_MANAGEMENT
                ) && uType != 'driver' && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => addNewFleet()}
                  >
                    <img
                      src={AddCircle}
                      className="me-2 fs-15"
                      alt="File Upload Icon"
                    />
                    Add Fleet Manager
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="w-100 pageContentInner border-0 rounded-2">
              <div className="d-flex flex-column rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  className="border-0 rounded-2"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                />
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Fleet"
                  text="Are you sure you want to delete this Fleet?"
                />
                { isDriverHistoryPopupOpen && (
                  <FleetDriverHistory 
                    isOpen={isDriverHistoryPopupOpen}
                    onClose={handleClose} 
                    fleetId = {deletedId}                
                  />

                )}
                { isMileagePopupOpen && (
                  <FleetMileage 
                    isOpen={isMileagePopupOpen}
                    onClose={handleClose} 
                    fleetId = {deletedId}  
                    fleetData = { fleetData}              
                  />

                )}
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FleetList;
