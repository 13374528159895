import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function ProjectCountSkeleton() {
  return (
    <>
      <div className="mb-3 d-flex justify-content-between w-100">
        <Skeleton variant="rounded" width="100%" height={60} className="m-2" />
        <Skeleton variant="rounded" width="100%" height={60} className="m-2" />

        <Skeleton variant="rounded" width="100%" height={60} className="m-2" />

        <Skeleton variant="rounded" width="100%" height={60} className="m-2" />
        <Skeleton variant="rounded" width="100%" height={60} className="m-2" />
      </div>
    </>
  );
}
