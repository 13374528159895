import { axios } from "../services/axios";
import {
    CREATE_DEAL,
    DEAL_LIST,
    UPDATE_DEAL,
    VIEW_DEAL,
    DEAL_DELETE,
    DEAL_PDF_UPLOAD,
    DEAL_PAYMENT_UPLOAD,
    CONVERT_TO_PROJECT,
    PRODUCT_TYPE_LIST,
    ORDER_NUMBER_LIST,
    DEAL_EXPORT,
    DEAL_SYNC

} from "../constants/paths";

export type createDealDTO = {
    order_no: string,
    name: string,
    contract_type: string[] | any,
    status: string[] | any,
    order_date: string,
    amount: string,    
    address: string,
    postal_code: string[] | any,
    phone: string,
    remarks: string,
    contract_signee: string,
    contract_date: string,
    contract_file: string,
    payment_deposited_by: string,
    payment_date: string,
    paid_amount: string,
    payment_file: string,  
    project_type: string[] | any
};

export type ProjectType = {
  key: string;
  value: string;
};

export const getDealList = (formData: any): Promise<any> => {
  return axios.get(DEAL_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const createDealApi = (data: createDealDTO): Promise<any> => {
  const optionalFields = [
    "remarks",
    "contract_signee",
    "contract_date",
    "contract_file",
    "payment_deposited_by",
    "payment_date",
    "paid_amount",
    "payment_file",
  ]
  const transformData: any = {
    ...data,
    project_type_id: data.project_type?.key,
    contract_type: data.contract_type?.key,
    postal_code_id: data.postal_code?.id,
    status: data.status?.key,
  };

  optionalFields.forEach(fieldName => {
    if(!transformData[fieldName]){
      transformData[fieldName] = null;
    }
    
  });

  delete transformData.postal_code;
  delete transformData.project_type;

  return axios.post(CREATE_DEAL, transformData);
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getDealByIdApi = (id: string): Promise<any> => {
  return axios.get(VIEW_DEAL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateDealApi = (
  id: string,
  data: createDealDTO
): Promise<any> => {
  const optionalFields = [
    "remarks",
    "contract_signee",
    "contract_date",
    "contract_file",
    "payment_deposited_by",
    "payment_date",
    "paid_amount",
    "payment_file",
  ]
  const transformData: any = {
    ...data,
    project_type_id: data.project_type?.key,
    contract_type: data.contract_type?.key,
    postal_code_id: data.postal_code?.id,
    status: data.status?.key,
  };

  optionalFields.forEach(fieldName => {
    if(!transformData[fieldName]){
      transformData[fieldName] = null;
    }
    
  });

  delete transformData.postal_code;
  delete transformData.project_type;
  delete transformData.order_no;
  return axios.put(UPDATE_DEAL + "/" + id, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteDealApi = (id: string | number | null, remarks:any): Promise<any> => {
  const transformData:any = {
    archive_remarks: remarks
  }
  return axios.delete(DEAL_DELETE + "/" + id, {data: transformData});
};

export const convertToProjectApi = (id: string | number | null): Promise<any> => {
  return axios.patch(CONVERT_TO_PROJECT + "/" + id);
};

export const uploadPDFApi = (data: any, type:string): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${DEAL_PDF_UPLOAD}/${type}`, data, config);
};
export const uploadPaymentPDFApi = (data: any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${DEAL_PAYMENT_UPLOAD}`, data, config);
};

export const getProductTypeList = () : Promise<any> => {
    return axios.get(PRODUCT_TYPE_LIST);
};



export const getOrderNumberListApi = (params:any) : Promise<any> => {
  return axios.get(ORDER_NUMBER_LIST,{params});
};

export const exportDealListApi = (params:any) : Promise<any> => {
  return axios.get(DEAL_EXPORT,{params,responseType: 'arraybuffer',});
}

export const syncDealsFromHubSpot = (): Promise<any> => {
  return axios.get(DEAL_SYNC);
};

