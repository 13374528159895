import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { getEmployeeInductionDetail } from "../../services/employeeService";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import moment from "moment";
import constants from "../../constants/constants";
import { getLibraryByIdApi } from "../../services/libraryService";
import PDFViewer from "../../components/common/PDFViewer";

interface AddProps {
  isOpen: boolean;
  onClose: () => void; 
  libraryId?: string |number | null | undefined;
}

const ViewLibraryPopup: React.FC<AddProps> = ({ isOpen, onClose, libraryId }) => {  
  const [loading, setLoading] = useState(true);
  const [libraryData, setLibraryData] = useState<any>({}); 
  const [docUrl, setDocUrl] = useState<any>(); 

  useEffect(()=>{
    fetchData()
   },[])
 
   const fetchData = () =>{         
      if(libraryId){
        setLoading(true)
        getLibraryByIdApi(libraryId)
            .then((response: any) => {
              setLibraryData(response.data);
              if(response.docUrl){
                setDocUrl(response.docUrl);
              }
              else{
                onClose();
              }
              setLoading(false);
            })
            .catch((error: any) => {
              setLoading(false);
              toast(error.data.msg, { type: toast.TYPE.ERROR });
            });      
      }
      else{
        setLoading(false)
      }  
   } 

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="pdf-viewer" show={isOpen} onHide={onClose} centered>          
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    { libraryData.title}
                  </h4> 
                  <Row>
                    <Col>
                      <div className="mb-3 custom-from-group">
                        <p className="fs-14">{libraryData?.description}</p>
                      </div>
                    </Col>                            
                  </Row> 
                  { docUrl && (
                  <Row>
                    <Col>
                    <PDFViewer pdfUrl={docUrl} />
                    </Col>
                  </Row> 
                  )}
                                 
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                {" "}                
                </Modal.Footer>              
        </Modal>
      )}
    </>
  );
};
export default ViewLibraryPopup;