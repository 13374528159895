import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function InstallationSkeleton() {
  return (
    <>
      <div className="mb-3 mt-4">
        <Skeleton variant="rounded" width="100%" height={80} />
      </div>
      <div className="mb-3">
        <Skeleton variant="rounded" width="100%" height={80} />
      </div>
      <div className="mb-3">
        <Skeleton variant="rounded" width="100%" height={80} />
      </div>
      
      
 
    </>
  );
}
