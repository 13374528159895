import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,  
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Button, Card, Col, Row } from "react-bootstrap";
import constants, {
  PROJECT_PREFIX,
  pageSetting,
  pageSizeModel,
} from "../../../constants/constants";
import { checkAbility, currencyMask } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import moment from "moment";
import ExportFinanceExternalExpensesData from "./exportData";
import ViewExpensesPopup from "../../Expenses/View";
import { getFinanceExternalExpenseList } from "../../../services/financeService";
import FileUploadIcon from "../../../assets/images/file-upload.svg";
import FileSave from "../../../assets/images/file_save.svg";

import DownloadIcon from "@mui/icons-material/Download";
import { FileUpload } from "@mui/icons-material";
import ImportExternalProcurementExpenses from "./importData";

const FinanceExnternalFinanaceList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const apiRef = useGridApiRef();
  const [isImportPopupOpen, setImportPopupOpen] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [exportPopup, setExportPopup] = useState(false);

  const handleClose = () => {
    setImportPopupOpen(false);
    setIsMutation(!isMutation);
  }; 

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getFinanceExternalExpenseList(defaultParams)
      .then((response) => {
        setTotalRow(response.data?.data?.totalResults);
        setData(response.data?.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if (filterModel.items[0].field === "inv_number") {
        filterModel.items[0].value = filterModel.items[0].value.replace(
          /[GR-]/g,
          ""
        );
      }
      console.log(filterModel.items[0].field);
      if (filterModel.items[0].field === "$project.project_no$") {
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');
        
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [    
    {
      field: "$project.project_no$",
      headerName: "Project Order No.",
      flex: .8,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell(params) {
        return params.row?.project?.project_no
          ? (<Link
            target="_blank"
            className="blue-text text-decoration-none fw-semibold"
            to={`/projects/view/${params.row?.project?.id}`}
          >{params.row?.project?.project_no_str}</Link>)
          : "-";
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.category ? params.row?.category : "-";
      },
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.sub_category ? params.row?.sub_category : "-";
      },
    },
    {
      field: "vendor",
      headerName: "Vendors",
      flex: 1.5,
      sortable: true,
      renderCell(params) {
        return params.row?.vendor ? params.row?.vendor : "-";
      },
    },
    {
      field: "invoice_date",
      headerName: "Invoice Date",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.invoice_date),
      renderCell(params) {
        const orderDate = new Date(params.row.invoice_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "amount",
      type: "number",
      headerName: "Invoice Value",
      flex: 0.8,
      sortable: true,
      cellClassName: "text-start",
      renderCell(params) {
        return (
          <span title={params.row?.amount}>
            {currencyMask(params.row?.amount)}
          </span>
        );
      },
    }
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  };

   /**
   * Import click handler
   */
   const handleImportStock = () => {    
    setImportPopupOpen(true);
  };  

  
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {exportPopup && (
            <ExportFinanceExternalExpensesData
              isOpen={exportPopup}
              onClose={toggleExportPopup}
              exportType="project"
              label="Export Invoices"
              text="Export Invoices"
            />
          )}
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">External Procurement Expenses</h1>
              </Col>
              <Col className="text-end">
              {checkAbility(
                  PERMISSION_ACCESS.VIEW,
                  PERMISSION_MODULE.FINANCE_EXTERNAL_EXPENSES
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white w-40 btn btn-info me-2 px-2"
                    variant="info"
                    type="button"
                    title="Export to excel"
                    onClick={() => toggleExportPopup()}
                  >
                    <img src={FileUploadIcon} alt="File Upload Icon" />                 
                  </Button>
                )}
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.FINANCE_EXTERNAL_EXPENSES
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white w-40 btn blue-btn px-2"
                    variant="info"
                    type="button"                    
                    title="Import"
                    onClick={() => handleImportStock()}
                  >
                     
                    <img src={FileSave} alt="File Save Icon" />   
                    
                  </Button>
                )}                
              </Col>
            </Row>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  apiRef={apiRef}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  disableRowSelectionOnClick
                  localeText={{ noRowsLabel: "No Expenses found" }}                                  
                  className="border-0 rounded-2"
                />
              </div>
            </Card>
          </div>
          {
            isImportPopupOpen && (
              <ImportExternalProcurementExpenses isOpen={isImportPopupOpen} onClose={handleClose}/>
            )
          }          
        </div>
      )}
    </React.Fragment>
  );
};

export default FinanceExnternalFinanaceList;
