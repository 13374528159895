// DeletePopup.tsx
import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import InfoIcon from '@mui/icons-material/Info';

interface DeletePopupProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void | Promise<void>;
  label : string;
  text : string;
  actionType? : string;
}

const DeletePopup: FC<DeletePopupProps> = ({ isOpen, onClose, onDelete, label, text, actionType = 'delete' }) => {
  return (
    <>
       <Modal className="delete-modal-cover" show={isOpen} onHide={onClose} centered>
        <Modal.Header className="border-0" >
          
        </Modal.Header>
        <Modal.Body className="pt-0 position-relative" >

              {/* <button type="button" className="btn-close text-right  btn-option-cover" aria-label="Close" onClick={onClose}></button> */}
              <div className="text-center mb-4">
                { actionType === 'delete' ? (<svg className="mt-4" width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.66748 48C7.20081 48 5.94526 47.4778 4.90081 46.4333C3.85637 45.3889 3.33415 44.1333 3.33415 42.6667V8H0.66748V2.66667H14.0008V0H30.0008V2.66667H43.3341V8H40.6675V42.6667C40.6675 44.1333 40.1453 45.3889 39.1008 46.4333C38.0564 47.4778 36.8008 48 35.3341 48H8.66748ZM35.3341 8H8.66748V42.6667H35.3341V8ZM14.0008 37.3333H19.3341V13.3333H14.0008V37.3333ZM24.6675 37.3333H30.0008V13.3333H24.6675V37.3333Z" fill="#EE3030"/>
                </svg>) : (<InfoIcon className='fs-60 text-info' />)}                
              </div>
              <div className="delete-title">{label}</div>
              <div className="sure-delete">{text}</div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center mb-3 pt-0">
              <button className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info" onClick={onClose}>
                No
              </button>
              <button className={`fw-semibold fs-13 text-white mw-65  mt-2 btn ${actionType=='delete' ? 'btn-danger' :'btn-primary'}`} onClick={onDelete}>
                Yes
              </button>
          
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeletePopup;