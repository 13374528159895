import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import AddCircle from "../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import constants, { pageSizeModel } from "../../constants/constants";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewPdfIcon from "@mui/icons-material/PictureAsPdf";
import { deleteLibraryApi, getLibraryList } from "../../services/libraryService";
import ViewLibraryPopup from "./View";
import BlockIcon from "@mui/icons-material/Block";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

interface RoleOption {
  key: string;
  value: string;
}
interface IPostalCode {
  id?: number;
  code?: string;
  createdAt: string;
  updatedAt: string;
  postalCodeUsers: any;
}
const LibrariesList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null | any>(null);

  const [isViewPopupOpen, setViewPopupOpen] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);


  const handleNewLibrary = () => {
    navigate("add");
  }; 

  const handleVersionHistory = () => {
    navigate("version-history");
  }; 

  const handleEditClick = async (id: GridRowId) => {
    navigate(`edit/${id}`);
  };
  const handleViewClick = async (id: GridRowId) => {
    setViewPopupOpen(true);
    setDeletedId(id);
  };

  const handleDeleteClick = (id: GridRowId) => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setViewPopupOpen(false);
    setDeletedId(null);
    setIsMutation(!isMutation);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteLibraryApi(deletedId)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        setDeletePopupOpen(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = (defaultParams: any) => {
    getLibraryList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  type UserDetails = {
    full_name: string;
    id: number;
  };

  type UserArrayElement = {
    user_id: number;
    userDetails: UserDetails;
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", flex: 1, sortable: true,hideable:false },
    {
      field: "description",
      headerName: "Description",     
      flex: 2,
      sortable: true,      
    },
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.LIBRARY
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.LIBRARY
    );    
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.LIBRARY
    );    

    if (checkEditAbilityCondition || checkDeleteAbilityCondition || checkViewAbilityCondition) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: .5,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          const gridActions = [];          
         if (row.document && checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View PDF"
                onClick={(event) => handleViewClick(id)}
                showInMenu
                icon={<ViewPdfIcon />}
              />
            );
          }

          if (checkEditAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Edit"
                onClick={(event) => handleEditClick(id)}
                showInMenu
                icon={<EditIcon />}
              />
            );
          }

          if (checkDeleteAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                onClick={(event) => handleDeleteClick(id)}
                showInMenu
                icon={<DeleteIcon />}
              />
            );
          }
          return gridActions;
        },
      });
    } else {
      
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
                label={`${row.document ? "View PDF" : "No Action"}`}
                title={`${row.document ? "View PDF" : "No Action"}`}
                onClick={(event) => handleViewClick(id)}
                showInMenu={false}
                disabled={!row.document}
                className="text-primary"
                icon={row.document ? (<ViewPdfIcon />) : (<BlockIcon />)}
              />,
            // <GridActionsCellItem
            //   label="No Action"
            //   icon={<BlockIcon />}
            //   showInMenu
            //   // You can handle the click event if needed
            //   onClick={() => {}}
            // />,
          ];
        },
      });
    }
  };
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);
  
  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Library</h1>
              </div>
              <div>
              {checkAbility(
                  PERMISSION_ACCESS.LIST,
                  PERMISSION_MODULE.LIBRARY_VERSION_HISTORY
                ) && (
                 <Button
                    className="fw-semibold fs-12 text-white  btn btn-info me-2"
                    variant="info"
                    type="button"
                    onClick={() => handleVersionHistory()}
                  >
                    <HistoryToggleOffIcon className="me-2"/>
                    Library Version Histroy
                  </Button>                  
                )}   
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.LIBRARY
                ) && (
                 <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => handleNewLibrary()}
                  >
                    <img
                      src={AddCircle}
                      className="me-2 fs-15"
                      alt="File Upload Icon"
                    />
                    Add New Document
                  </Button>                  
                )}               
              </div>
            </div>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" border-0 w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  pageSizeOptions={pageSizeModel}
                  sortingMode="server"
                  disableRowSelectionOnClick

                />                            

                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Library Document"
                  text="Are you sure you want to delete this Library Document?"
                />
              </div>
            </Card>
          </div>
          {isViewPopupOpen && (
            <ViewLibraryPopup
              isOpen={isViewPopupOpen}
              onClose={handleClose}              
              libraryId={deletedId}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default LibrariesList;
