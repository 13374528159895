import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Card } from "react-bootstrap";

export default function CountLabelSkeleton() {
  return (
    <div className="p-1">
     <Card className="mb-2 p-3 rounded-3">
     <Stack spacing={2}>
        <Skeleton variant="rounded" width="100%" height={80} />
        <Skeleton variant="rounded" width="100%" height={80} className="mt-3" />
      </Stack>
     </Card>
    </div>
  );
}
