import { axios } from "../services/axios";
import {
  PERMISSION_LIST,
  ROLE_LIST,
  CREATE_ROLE,
  UPDATE_ROLE,
  ROLE_DELETE,
} from "../constants/paths";

export type RoleListDTO = {
  role: string;
  created_at: string;
};

export type CreateRoleDTO = {
  role: string;
  description: string;
  permission: [];
};

export const getRolesList = (formData: any): Promise<any> => {
  return axios.get(ROLE_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const getPermission = (): Promise<any> => {
  return axios.get(PERMISSION_LIST);
};

export const createRoleApi = (data: CreateRoleDTO): Promise<any> => {
  return axios.post(CREATE_ROLE, data);
};

/**
 * @param {id: string } params
 * @returns ROLES details
 */
export const getRolesByIdApi = (id: string): Promise<any> => {
  return axios.get(ROLE_LIST + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateRoleApi = (
  id: string,
  formData: CreateRoleDTO
): Promise<any> => {
  return axios.put(UPDATE_ROLE + "/" + id, formData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteRoleApi = (id: string | number | null): Promise<any> => {
  return axios.delete(ROLE_DELETE + "/" + id);
};
