import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { deleteRoleApi } from "../../services/roleService";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/Email";
import FileUploadIcon from "../../assets/images/file-upload.svg";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from '@mui/icons-material/Delete';
import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import {
  deleteEmployee,
  getEmployeeList,
  updateEmployeeStatusApi,
} from "../../services/employeeService";
import InputSwitchBox from "../../components/common/InputSwitchBox";
import { Card } from "react-bootstrap";
import AddCircle from "../../assets/images/add_circle.svg";
import { pageSetting, pageSizeModel } from "../../constants/constants";
import { checkAbility, getTagClass } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import ExportData from "./exportData";
import AssociateUser from "./associateUser";

const Roles: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [exportPopup,setExportPopup] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [associatedUserPopupOpen, setAssociatedUserPopupOpen] = useState(false);
  const [associatedUserData, setAssociatedUserData] = useState<any>(null);
  const [managerName, setManagerName] = useState<string>("");

  const addEmployee = () => {
    navigate("add-employee");
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/employees/edit-employee/${id}`);
  };

  /**
   * View employee click handler
   */
  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/employees/view-employee/${id}`);
  };
  
  const handleSendEmail = (id: GridRowId) => () => {
    navigate(`/employees/send-email/${id}`);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteEmployee(deletedId)
      .then((response) => {
        if(response.status === false){
          setDeletePopupOpen(false);
          setAssociatedUserData(response.data);
          setAssociatedUserPopupOpen(true);
          setManagerName(response.managerName);
        }else{
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          setDeletePopupOpen(false);
          setIsMutation(!isMutation);
        }
       
      })
      .catch((error) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getEmployeeList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  
  const handleStatusChange = (data: any) => {
    const checkPermision = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );
    if (checkPermision) {
      const id = data.row.id;
      const formData = {
        status: data.row.status,
      };
      updateEmployeeStatusApi(id, formData)
        .then((response) => {
          setIsMutation(!isMutation);
          toast(response.msg, { type: toast.TYPE.SUCCESS });
        })
        .catch(() => {
          setIsMutation(!isMutation);
        })
        .finally(() => {
          setIsMutation(!isMutation);
        });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "$employeeDetails.employment_id$",
      headerName: "Employee ID",
      flex: 1,
      sortable: true,
      hideable: false,
     
      renderCell(params) {
        return <span>{params.row.employeeDetails.employment_id}</span>;
      },
    },

    { field: "first_name", headerName: "First Name", flex: 1, sortable: true },
    { field: "last_name", headerName: "Family Name", flex: 1, sortable: true },
    { field: "email", headerName: "Email", flex: 2, sortable: true },
    {
      field: "$employeeDetails.designation$",
      headerName: "Designation",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.employeeDetails.designation}>
            {params.row.employeeDetails.designation}
          </span>
        );
      },
    },
    {
      field: "$employeeDetails.department$",
      headerName: "Department",
      flex: 2,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.employeeDetails.department}>
            {params.row.employeeDetails.department}
          </span>
        );
      },
    },
    {
      field: "$employeeDetails.reporting_manager$",
      headerName: "Reporting Manager",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.employeeDetails?.manager?.full_name}>
            {params.row.employeeDetails?.manager?.full_name}
          </span>
        );
      },
    },

    {
      field: "phone_no",
      headerName: "Contact No.",
      flex: 1,
      sortable: true,
    },
    {
      field: "$employeeDetails.employee_type$",
      headerName: "Type",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.employeeDetails.employee_type}>
            {params.row.employeeDetails.employee_type}
          </span>
        );
      },
    },

    {
      field: "$employeeDetails.employment_status$",
      headerName: "Status",
      flex: 2,
      sortable: true,
      renderCell(params) {
        return (
          <span
            title={params.row.employeeDetails.employment_status}
            className={`py-1 px-2 rounded-1 ${getTagClass(
              params.row.employeeDetails.employment_status
            )} d-inline-block fs-11`}
          >
            {params.row.employeeDetails.employment_status}
          </span>
        );
      },
    },
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );

    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );


    if (checkEditAbilityCondition) {
      columns.push({
        field: "status",
        headerName: "Active",
        flex: 1,
        sortable: true,
        renderCell(params) {
          return (
            <InputSwitchBox
              handleChange={(event: any) => {
                handleStatusChange(params);
              }}
              controlId="status"
              placeholder="status"
              label=""
              value={params.row.status == "active" ? true : false}
            />
          );
        },
      });
    }

    if (checkEditAbilityCondition || checkViewAbilityCondition || checkDeleteAbilityCondition) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          if (checkEditAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Send Email"
                onClick={handleSendEmail(id)}
                showInMenu
                icon={<EmailIcon />}
              />,
              <GridActionsCellItem
                label="Edit"
                icon={<EditIcon />}
                showInMenu
                onClick={handleEditClick(id)}
              />
            );
          }
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                icon={<ViewIcon />}
                showInMenu
                onClick={handleViewClick(id)}
              />
            );
          }
           if(checkDeleteAbilityCondition){
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                showInMenu
                onClick={() => {
                  setDeletedId(id);
                  setDeletePopupOpen(true);
                }}
              />
            );
          }  
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }

    
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  // useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     pageInfo?.totalRowCount !== undefined
  //       ? pageInfo?.totalRowCount
  //       : prevRowCountState,
  //   );
  // }, [pageInfo?.totalRowCount, setRowCountState]);
  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {exportPopup && (
            <ExportData
              isOpen={exportPopup}
              onClose={toggleExportPopup}
              exportType="employee"
              label="Export to Excel"
              text="Export to Excel"
            />
          )}
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">All Employee</h1>
              </div>
              <div>
              <button
                  className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                  onClick={toggleExportPopup}
                >
                  <img src={FileUploadIcon} alt="File Upload Icon" />
                </button>
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.ALL_EMPLOYEE
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => addEmployee()}
                  >
                    <img
                      src={AddCircle}
                      className="me-2 fs-15"
                      alt="File Upload Icon"
                    />
                    Add New Employee
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                  
                />
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Employee"
                  text="Before deleting this user, please ensure they do not have any Meetings, Assets, Fleet or uncleared Expenses associations. If any of these items are associated with the user, reassign them to another user before proceeding with the deletion. Once you have confirmed there are no associations, you may proceed with deleting the user."
                />
                {associatedUserPopupOpen && (
                  <AssociateUser 
                    isOpen={associatedUserPopupOpen}
                    onClose={() => setAssociatedUserPopupOpen(false)}
                    manager={managerName}
                    associatedUserData={associatedUserData}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Roles;
