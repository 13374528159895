import React, { useEffect, useState } from "react";
import { Card, Col, Form, Image, Row, Spinner } from "react-bootstrap";
import projectDummy from "../../../assets/images/project-dummy-icon.png";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Formik } from "formik";
import CustomButton from "../../../components/common/CustomButton";
import InputCheckBox from "../../../components/common/InputCheckBox";
import InputDatePicker from "../../../components/common/InputDatePicker";
import FormSelect from "../../../components/common/FormSelect";
import InputTextArea from "../../../components/common/InputTextArea";
import * as yup from "yup";
import { TimePicker } from "@mui/x-date-pickers/TimePicker/TimePicker";
import InputTimePicker from "../../../components/common/InputTimePicker";
import moment from "moment";
import constants from "../../../constants/constants";
import { toast } from "react-toastify";
import { initiateSurvey } from "../../../services/project.service";
import { INVALID_DATE } from "../../../validations/ValidationErrors";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import { getFormTemplates } from "../../../services/formbuilder/fieldsheet.services";
import { getSurveyDataByContractId } from "../../../services/survey/survey.service";
import { set } from "lodash";
import Loader from "../../../components/common/Loader";
import { getFirstLetters } from "../../../utility/common";

interface SurveyInitiateProps {
  projectData: Record<string, any>; // You can replace 'any' with the actual type of employeeDetails
  kinDetails: Record<string, any>; // You can replace 'any' with the actual type of kinDetails
  employeeBankDetails: Record<string, any>; // You can replace 'any' with the actual type of employeeBankDetails
}

const SurveyInitiate: React.FC<SurveyInitiateProps> = ({
  projectData,
  kinDetails,
  employeeBankDetails,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const  [data,setData] = useState<any>([]);
  useEffect(() => {
     if(projectData && projectData.id){
       fetchData();
     }else{
      setLoading(false)
     }
  }
  , []);

  const fetchData = () => {
    setLoading(true)
    const contractId = projectData.id;
    getSurveyDataByContractId(contractId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false)
      }).finally(() => {
        setLoading(false)
      });
  };

  /**
   * Submit Method to call when vendor save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    const projectId = projectData.id;
    const formData = {
      ...values,
      survey_date: moment(values.survey_date).format(
        constants.dateFormats.databaseFormat
      ),
      survey_time: moment(values.survey_time).format("HH:mm"),
    };    
    initiateSurvey(projectId, formData)
      .then((response: any) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setBtnLoading(false);
      })
      .catch((error: any) => {
        setSubmitting(false);
        setBtnLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  // Function to calculate time difference in HH:mm format
    const calculateTimeDifference = (dateTimeString: string): string => {
       // Parse input datetime string
    const inputDate = moment(dateTimeString);

    // Add 48 hours to the input date
    const futureDate = inputDate.add(48, 'hours');

    // Get the current date and time
    const currentDate = moment();

    // Calculate the difference between future date and current date
    const duration = moment.duration(futureDate.diff(currentDate));

    // Get remaining hours and minutes
    const remainingHours = Math.floor(duration.asHours());
    const remainingMinutes = duration.minutes();

    // Format the remaining time in HH:mm format
    const formattedRemainingTime = `${String(remainingHours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;

    return formattedRemainingTime;
    };

  const SurveySchema = yup.object({
    survey_date: yup
      .date()
      .required()
      .typeError("Please enter valid date")
      .label("Survey Date"),
    survey_time: yup.string().required().label("Survey Time"),
    template_id: yup.object().required().label("Survey Template"),
  });

  const initialValues: any = {
    client_connected: data?.client_connected ? data?.client_connected : false,
    survey_date: data?.survey_date? moment(data?.survey_date) : null,
    survey_time: data?.survey_time ? moment(data?.survey_date+' '+data?.survey_time) : null,
    template_id: data.template_id ? data.template_id :null,
    notes: data?.notes ? data?.notes :""
  };

  const getFormTemplate = (params: any) => {
    return new Promise((resolve, reject) => {
      getFormTemplates(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err)
        });
    });
  };

  return (
    <React.Fragment>
      {loading ?
      <Loader isLoading={loading} />:
      
      <Formik
        validationSchema={SurveySchema}
        initialValues={initialValues}
        onSubmit={(values: any, actions) => {
          submitForm(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldError,
          setFieldValue,
        }) => (
          <Form className="" noValidate onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-md-9">
                <Card className="p-4 w-100 pageContentInner mb-2 border-radius-2">
                  <h3 className="fs-18 fw-bold info-text">Survey Initiation</h3>

                  <Row>
                    <Col className="survey-input-cover">
                      <InputCheckBox
                        controlId="client_connected"
                        label="Connected With Client"
                        touched={touched.client_connected}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.client_connected}
                        value={values.client_connected}
                      />
                      <p className="survery-left-text fs-12 text-gray">
                        if not then connect with client within { calculateTimeDifference(projectData.project_converted_date)} Hrs?
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col-md-12">
                      <Row className="g-3">
                        <div className="col-md-3">
                          <InputDatePicker
                            name="survey_date"
                            label="Survey Date"
                            slotProps={{
                              textField: {
                                size: "small",
                                placeholder: "Select",
                                readOnly: true,
                                className: "form-control",
                                id: "survey_date",
                              },
                            }}
                            value={values.survey_date}
                            onChange={(name, newValue) =>
                              setFieldValue(name, newValue)
                            }
                            touched={touched.survey_date}
                            errors={errors.survey_date}
                          />
                        </div>
                        <div className="col-md-3">
                          <InputTimePicker
                            name="survey_time"
                            label="Survey Time"
                            slotProps={{
                              textField: {
                                size: "small",
                                placeholder: "Select",
                                readOnly: true,
                                className: "form-control",
                                id: "survey_time",
                              },
                            }}
                            value={values.survey_time}
                            onChange={(name, newValue) =>
                              setFieldValue(name, newValue)
                            }
                            touched={touched.survey_time}
                            errors={errors.survey_time}
                          />
                        </div>
                        <div className="col-md-6">
                          {/* <FormSelect
                            placeholder="Select"
                            label="Survey Template"
                            name="template_id"
                            options={templateData}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            value={values.template_id}
                            error={errors.template_id}
                            touched={touched.template_id}
                          /> */}
                          <FormAsyncSelect
                            id="template_id"
                            name="template_id"
                            label="Survey Template"
                            isAsync
                            isClearable
                            getOptions={getFormTemplate}
                            value={values.template_id}
                            onChange={(name: any, value: any) => {
                              setFieldValue(name, value);
                            }}
                            error={errors.template_id}
                            touched={touched.template_id}
                          />
                        </div>

                        <div className="col-md-12">
                          <InputTextArea
                            className="max-height300 resize-none"
                            controlId="notes"
                            label="Additional Notes "
                            placeholder="Enter additional notes"
                            touched={touched.notes}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.notes}
                            value={values.notes}
                          />
                        </div>
                      </Row>
                    </div>
                  </Row>
                </Card>
              </div>
              <div className="col-md-3">
                <Card className="p-4 w-100 pageContentInner mb-2 border-radius-2">
                  <div className="align-items-center media">
                    <div className="project-profile">
                    <span className="fs-3 green h-75 m-0 mt-2 p-0 rounded-5 ticket-user-tag w-75 pt-1">
                      {getFirstLetters(
                        projectData?.name
                      )}
                    </span>  
                      {/* <img
                        className=""
                        src={projectDummy}
                        alt="Generic placeholder image"
                      /> */}
                    </div>
                    <div className="media-body">
                      <label className="fs-16 fw-bold">
                        {projectData?.name}
                      </label>
                      <div className="fs-14 control-label">
                        {projectData?.address}
                      </div>
                    </div>
                  </div>
                  <hr className="border-light" />
                  <p className="mb-1">
                    <PhoneIcon className="me-2 text-primary fs-15" />
                    {projectData.phone}
                  </p>
                  <p className="mb-0">
                    <AlternateEmailIcon className="me-2 text-primary fs-15" />
                    {projectData.email}
                  </p>
                </Card>
              </div>
            </div>
           {data?.project?.status!='ip' && <div className="row">
              <div className="col-md-12">
                <div className="text-end my-3">
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    disabled={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Initiate Survey
                  </CustomButton>
                </div>
              </div>
            </div> }
          </Form>
        )}
      </Formik>}
    </React.Fragment>
  );
};

export default SurveyInitiate;
