import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { getDashboardPendingDispatchApi } from "../../../services/warehouseService";
import { checkAbility, getLocalDateFormat } from "../../../utility/common";
import { Link } from "react-router-dom";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";

export const DashboardPendingDispatch = () => {
  const [pendingDispatch, setPendingDispatch] = useState<any>([]);

  const getDashboardPendingDispatch = () => {
    // setLoading(true);
    getDashboardPendingDispatchApi()
       .then((response) => {
         setPendingDispatch(response.data);
        // setLoading(false);
       })
       .catch((error) => {
       //  setLoading(false);
       })
       .finally(() => {
        // setLoading(false);
       });
   };
  useEffect(() => {
    getDashboardPendingDispatch();
  }, []);
  return (
    <div className="d-flex flex-column pageContainer w-100 pb-sm-1 h-100">
      <h5 className="fs-20 info-text fw-bold">Pending Dispatch Orders</h5>
      <Card className="p-3 w-100 pageContentInner warehouse-box-blue max-h-250">
        <Table className="table-custom">
          <thead>
            <tr>
              <th>Dispatch Order No.</th>
              <th>Customer</th>
              <th>Delivery Location</th>
              <th>Deadline</th>
            </tr>
          </thead>
          <tbody>
            {pendingDispatch?.length > 0 ? (
              pendingDispatch.map(
                (dpData: {
                  id: any;
                  project: any;
                  deadline_date: any;
                  dispatch_number_str: string | null | undefined;
                  address: string | null | undefined;
                  due_date: any;
                }) => (
                  <tr>
                    <td>
                      {checkAbility(
                        PERMISSION_ACCESS.VIEW,
                        PERMISSION_MODULE.DISPATCH_ORDER
                      ) ? (
                        <Link
                          target="_blank"
                          className="blue-text text-decoration-none fw-semibold"
                          to={`/dispatch-orders/view/${dpData?.id}`}
                        >
                          {dpData?.dispatch_number_str}
                        </Link>
                      ) : (
                        dpData?.dispatch_number_str
                      )}
                    </td>
                    <td>{dpData?.project?.name}</td>
                    <td>{dpData?.address ?? "-"}</td>
                    <td>
                      {dpData?.deadline_date
                        ? getLocalDateFormat(dpData?.deadline_date)
                        : "-"}
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan={3}>No Records Available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
