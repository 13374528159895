import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Card } from "react-bootstrap";
import { DateRangeInterface } from "../Dashboard/dashboardInterface";
import { getOnboardingChartData } from "../../services/hrdashboard.service";
import moment from "moment";
 import BarChartSkeleton from "../../components/common/Skeleton/BarChart.skeleton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OnboardingChart : React.FC<DateRangeInterface> = ({orderDateRange}) => {
  const [loading, setLoading] = React.useState(true);
  const [chartData, setChartData] = React.useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, [orderDateRange]);

  const fetchData = (signal: any) => {
    getOnboardingChartData(orderDateRange, signal).then((res) => {     
      setChartData(res.data);       
      setLoading(false);
      }
  ).catch((err) => {
      setLoading(false);
  })
  };

  const data = {
    labels:  chartData.map((item: any)=>moment(item.month,'MM-YYYY').format('MMM-YY')),
    datasets: [
      {
        label: "Onboarding",
        data:   chartData.map((data: any)=> data.data), // Data from API
        backgroundColor: "rgba(33, 172, 89, 1)", // Green color
        borderRadius: 20, // Rounded top corners
        barThickness: 20, // Bar width
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,

      },
      datalabels: {
        display: false, // Disable datalabels for this specific chart
      },

    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: "Day of the Week",
        },
        grid: {
          display: false, // Hide the grid lines on the x-axis
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "No. of Onboardings",
          color: "#A3A3A3",
        },
        grid: {
          display: false, // Hide the grid lines on the x-axis
        },
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false
  };

  return (
    <div className="h-100">
       
      <Card className="p-3 w-100   rounded-2  mb-3 ">
        {loading ? (
         <BarChartSkeleton />
        ) : (
          <>
         <div className="w-100"  style={{height:'315px'}}> 
          <Bar   data={data} options={options}    />
          </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default OnboardingChart;
