import React, { useEffect, useState } from "react";
import { Card, Col, Row, Image, Spinner } from "react-bootstrap";
import { MdHeight } from "react-icons/md";

import { ErrorMessage, Formik } from "formik";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import ProfileDummy from "../../../assets/images/profile-dummy.png";
import ProfileEditIcon from "../../../assets/images/profile-edit-icon.svg";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../components/common/InputText";
import { ticketSchema } from "./validation";
import FormSelect from "../../../components/common/FormSelect";
import constants, {
  departmentOptions,
} from "../../../constants/constants";
import CustomButton from "../../../components/common/CustomButton";

import Loader from "../../../components/common/Loader";
import FileUploader from "../../../components/common/FileUploader";
import InputDatePicker from "../../../components/common/InputDatePicker";
import { getAllManagerList } from "../../../services/authService";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import InputTextArea from "../../../components/common/InputTextArea";
import GeneralFileUploader from "../../../components/common/GeneralFileUploader";
import { FILE_SIZE_ERROR, FILE_TYPE_ERROR } from "../../../validations/ValidationErrors";
import moment from "moment";
import { createTicketApi, getTicketDetailsApi, updateTicketApi, uploadTicketImageApi } from "../../../services/ticketService";
import { TicketDataDto } from "./ticket.type";

export const AddInternalTicket = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [ticketData, setTicketData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ];
  const maxFileSizeMB = 1;

  let { id } = useParams();

  /**
   * Submit Method to call when vendor save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: TicketDataDto, { setSubmitting }: any) => {
    const formData = {
      ...values,
      due_date: moment(values.due_date).format(constants.dateFormats.databaseFormat)
    };
    delete formData.filename;
    delete formData.filesSignedURL;
    console.log(formData);
  //  return;
    setBtnLoading(true);
    if (id) {
      updateTicketApi(id, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/tickets");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createTicketApi(formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/tickets");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getTicketsDetails = (id: string) => {
    setLoading(true);
    getTicketDetailsApi(id)
      .then((response) => {
        setTicketData(response.data);
        if (response.vendorImage) {
          setImagePreview(response.vendorImage);
        }
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/tickets");

        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/tickets");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/tickets");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getTicketsDetails(id);
    } else {
      setLoading(false);
    }
  }, []);

  const handleCancel = () => {
    navigate(-1);
  };

  const uploadFile = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    //console.log("file", imageFile);

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setImageLoading(true);
      

      // Create FormData object
      const formData = new FormData();
      let filesData:any = [];
      filesData.push(imageFile);
      // Append the file to the FormData object
      formData.append("files", imageFile);

      uploadTicketImageApi(formData)
        .then((response) => {
          setImageLoading(false);
          setFieldValue("files", response.data.files);
          setFieldValue("filename", imageFile.name);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };

  const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      const transformParam = {
        ...params
      };
      getAllManagerList(transformParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };  

  const initialValues: any = {
    title: ticketData?.title ? ticketData?.title : "",
    due_date: ticketData?.due_date ? moment(ticketData?.due_date) : null,
    assigned_to: ticketData?.assigned_to ? {...ticketData?.assignedUser, name:ticketData?.assignedUser?.full_name} : null,
    description: ticketData?.description ? ticketData?.description : null,
    department: ticketData?.department
      ? departmentOptions.find((item) => item.key == ticketData?.department)
      : null,    
    files: [],
    filename: ticketData?.files?.length ? ticketData?.files[0] : "",
    filesSignedURL: ticketData?.filesSignedURL?.length ? ticketData?.filesSignedURL[0] : "",
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">{id ? "Edit" : "Create New"} Ticket</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={ticketSchema}
              initialValues={initialValues}
              onSubmit={(values: TicketDataDto, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <div className="col-md-8">
                            <Row>
                              <Col className="col-md-6">
                                <InputText
                                  controlId="title"
                                  label="Subject"
                                  placeholder="Enter Subject"
                                  touched={touched.title}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.title}
                                  value={values.title}
                                />
                              </Col>
                              <Col className="col-md-6">
                              <FormSelect
                                  placeholder="Select"
                                  label="Department"
                                  name="department"
                                  options={departmentOptions}
                                  getOptionLabel={(option: any) => option.value}
                                  getOptionValue={(option: any) => option.key}
                                  onChange={(name: string, value: string) => {
                                    setFieldValue(name, value);
                                  }}
                                  value={values.department}
                                  error={errors.department}
                                  touched={touched.department}
                                />
                              </Col>
                              <Col className="col-md-6">
                              <FormAsyncSelect
                                id="assigned_to"
                                name="assigned_to"
                                label="Assignee"
                                isAsync
                                isClearable
                                getOptions={getProjectOwner}
                                value={values.assigned_to}
                                error={errors.assigned_to}
                                touched={touched.assigned_to}
                                onChange={(name: any, value: any) => {
                                  setFieldValue(name, value);
                                }}
                              />
                              </Col>
                              <Col className="col-md-6">
                              <InputDatePicker
                                name="due_date"
                                label="Due Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "due_date",
                                  },
                                }}
                                value={values.due_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.due_date}
                                errors={errors.due_date}
                              />
                              </Col>                              
                              <Col className="col-md-12">
                                <GeneralFileUploader
                                      uploadFile={(e) =>
                                        uploadFile(e, setFieldValue)
                                      }
                                      imageLoading={imageLoading}
                                      label="Attachment"
                                      filename={values.filename}   
                                      acceptFormat=".jpeg, .png,.jpg,.svg"            
                                      fileurl={values.filesSignedURL}               
                                />
                                <ErrorMessage
                                      name='filename'
                                      component="div"
                                      className="text-danger fs-14"
                                  />
                              </Col>                              
                            </Row>
                          </div>
                          <div className="col-md-4">
                          <InputTextArea
                              controlId="description"
                              label="Description"
                              placeholder="Enter Description"
                              touched={touched.description}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.description}
                              name="description"
                              value={values.description}
                              className="form-control textarea-height"
                            />
                          </div>
                        </Row>
                        <Row></Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <div
                            className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                            onClick={handleCancel}
                          >
                            Cancel
                          </div>

                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={imageLoading || btnLoading}
                            className="fw-semibold fs-13 text-white mw-90 mt-2"
                            variant="primary"
                          >
                            {id ? "Update" : "Add"}
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
