import { Card } from "react-bootstrap";
import { Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputText from "../../../components/common/InputText";
import { LoginSchema } from "../../../validations/Auth";
import AddCircle from "../../../assets/images/add_circle.svg";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ProductReceived from "../../Product/View/product-received";
import { checkAbility, currencyMask, getLocalDateFormat } from "../../../utility/common";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import Loader from "../../../components/common/Loader";
import { Scrollbar } from "react-scrollbars-custom";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import FirstDayCheckList from "../FirstDayCheckList";
import LastDayCheckList from "../LastDayCheckList";
import MediaUpload from "../MediaUpload";
import { getProjectDetails, getProjectDocumentsAPi, getProjectUploadedSignedUrl } from "../../../services/project.service";
import { getInstallationTaskDetails } from "../../../services/installation.service";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import OrderRecievedDetails from './Order-Received/index';
export const ViewInstallationDetail = () => {
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState<any>({});
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [imagePreview, setImagePreview] = useState<any>(AddPhotoAlternate);
  const [installationStatus, setInstallationStatus] = useState<string>("order_received");
  const [checkPermission, setCheckPermission] = useState<boolean>(false);
  const [allDispatchReceived,setAllDispatchReceived] = useState(false)

  let { id } = useParams();
  const navigate = useNavigate();

  const fetchInstallationTaskDetails = (id: string) => {
    return new Promise((resolve, reject) => {
      getInstallationTaskDetails(id)
        .then((response) => {
          setProjectData(response.data);
          setLoading(false);
          resolve(true);
          //(projectData?.payment_milestone.length ==0) && (projectData.payment_milestone[0].installation_continue_status ==false) 
          if(response.data?.payment_milestone.length> 0){
            if(response.data.payment_milestone && response.data.payment_milestone.some((element: any) =>element.received_date!=null && element.amount!='0' )){
              setCheckPermission(true)
            }
            
          }else{
            setCheckPermission(false)
          }
        })  
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/installation");
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
            navigate("/installation");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/installation");
          }
        })
        .finally(() => {
          setLoading(false);
        });    
    });
    
  };

  const fetchDocument = (id: string | number | undefined) => {
    //  setLoading(true);
    if(id){
      return new Promise((resolve, reject) => {
        getProjectDocumentsAPi(id)
          .then((response) => {
            let docData = response.data;    
             //docData = docData.filter((obj: { key: string; isFileExists: boolean; }) => !(obj.key === "other" && obj.isFileExists === false));
            let updatedDoc = docData.filter((doc: { key: string; isFileExists: boolean; }) => doc.key != "deposit" && doc.key != "contract"    && doc.isFileExists !== false);
            
            
            setSelectedDocuments(updatedDoc);
            setLoading(false);
            resolve(true);
          })
          .catch((error) => {
            setLoading(false);
            reject(error);
          });
      })

    }
  };

  useEffect(() => {
    setLoading(true);
    if (id) {
      fetchInstallationTaskDetails(id).then(() => {
        fetchDocument(id);
        // Call this function after fetchData completes
      });      
    } else {
      setLoading(false);
    }
  }, []);

   /**
   * Download Document click handler
   */
   const handleDownloadClick = (docType: string, docId: any = null) => {
    getProjectUploadedSignedUrl(docType, id, docId)
      .then((response: any) => {
        const signedFileUrl = response.data.file;
        // window.open(signedFileUrl, '_blank');
        window.open(signedFileUrl, "_blank");
        //console.log(signedFileUrl);
      })
      .catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {});
  };

  const handleActivePage = (status: string) => {
    setInstallationStatus(status);
  }
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">
                  Installation Task Details
                </h1>
              </Col>
              <Col className="text-end col-md-12 col-xl-8">
                <button className={`form-builder-link  previous`}>
                  First Day
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06634 10.0666L10.7663 5.36659L9.83301 4.43325L6.06634 8.19992L4.16634 6.29992L3.23301 7.23325L6.06634 10.0666ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4916 4.39967 13.1416C3.58856 12.7916 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20801 10.411 0.858008 9.59992C0.508008 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508008 5.21103 0.858008 4.39992C1.20801 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20825 4.39967 0.858252C5.21079 0.508252 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508252 9.59967 0.858252C10.4108 1.20825 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7913 3.58881 13.1413 4.39992C13.4913 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4913 8.78881 13.1413 9.59992C12.7913 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7916 9.59967 13.1416C8.78856 13.4916 7.9219 13.6666 6.99967 13.6666Z"
                      fill="#21AC59"
                    />
                  </svg>
                </button>
                <button className={`form-builder-link`}>
                  In Progress
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06634 10.0666L10.7663 5.36659L9.83301 4.43325L6.06634 8.19992L4.16634 6.29992L3.23301 7.23325L6.06634 10.0666ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4916 4.39967 13.1416C3.58856 12.7916 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20801 10.411 0.858008 9.59992C0.508008 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508008 5.21103 0.858008 4.39992C1.20801 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20825 4.39967 0.858252C5.21079 0.508252 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508252 9.59967 0.858252C10.4108 1.20825 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7913 3.58881 13.1413 4.39992C13.4913 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4913 8.78881 13.1413 9.59992C12.7913 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7916 9.59967 13.1416C8.78856 13.4916 7.9219 13.6666 6.99967 13.6666Z"
                      fill="#21AC59"
                    />
                  </svg>
                </button>
                <button className={`form-builder-link  active`}>
                  Last Day
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06634 10.0666L10.7663 5.36659L9.83301 4.43325L6.06634 8.19992L4.16634 6.29992L3.23301 7.23325L6.06634 10.0666ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4916 4.39967 13.1416C3.58856 12.7916 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20801 10.411 0.858008 9.59992C0.508008 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508008 5.21103 0.858008 4.39992C1.20801 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20825 4.39967 0.858252C5.21079 0.508252 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508252 9.59967 0.858252C10.4108 1.20825 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7913 3.58881 13.1413 4.39992C13.4913 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4913 8.78881 13.1413 9.59992C12.7913 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7916 9.59967 13.1416C8.78856 13.4916 7.9219 13.6666 6.99967 13.6666Z"
                      fill="#21AC59"
                    />
                  </svg>
                </button>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Row className="g-3">
              <Col className="col-md-8">
                <Card className=" p-3 w-100  mb-3 rounded-3">
                  <Row>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Project Order Number
                      </label>
                      <div className="fs-14 mb-3  link-text fw-semibold">
                        {projectData.project_no_str}
                      </div>
                    </Col>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Installation Date
                      </label>
                      <div className="fs-14 mb-3">
                        {getLocalDateFormat(
                          projectData.projectDetails.planned_start_date
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Customer Name
                      </label>
                      <div className="fs-14 mb-3">{projectData.name}</div>
                    </Col>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Address
                      </label>
                      <div className="fs-14 mb-3">{projectData.address}</div>
                    </Col>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Phone Number
                      </label>
                      <div className="fs-14 mb-3">{projectData.phone}</div>
                    </Col>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Project Owner
                      </label>
                      <div className="fs-14 mb-3">
                        {projectData?.project_owner_id
                          ? projectData?.projectOwner?.full_name
                          : "-"}
                      </div>
                    </Col>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Surveyor Name
                      </label>
                      <div className="fs-14 mb-3 ">
                        {projectData?.surveyDetails?.updatedBy?.full_name}
                      </div>
                    </Col>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Interim Payment Status
                      </label>
                      <div className="fs-14 mb-3">
                        {projectData?.payment_milestone?.length > 0 && projectData?.payment_milestone[0].received_date ? (
                          <span className="text-primary">Received</span>
                        ) : (
                          <span className="text-danger">Pending</span>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <label className="control-label fs-14 fw-bold ">
                        Survey Report
                      </label>
                      <div className="fs-14 mb-3 link-text fw-semibold">
                        {projectData.surveyDetails?.survey_pdf && (
                          <a
                            href={projectData.surveyDetails?.survey_pdf}
                            target="_blank"
                            className="fs-14 link-text fw-semibold text-decoration-none">
                            View 
                              <span className="ms-2"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.99967 9.66927L9.66634 7.0026L6.99967 4.33594L6.06634 5.26927L7.13301 6.33594H4.33301V7.66927H7.13301L6.06634 8.73594L6.99967 9.66927ZM6.99967 13.6693C6.07745 13.6693 5.21079 13.4943 4.39967 13.1443C3.58856 12.7943 2.88301 12.3193 2.28301 11.7193C1.68301 11.1193 1.20801 10.4137 0.858008 9.6026C0.508008 8.79149 0.333008 7.92483 0.333008 7.0026C0.333008 6.08038 0.508008 5.21372 0.858008 4.4026C1.20801 3.59149 1.68301 2.88594 2.28301 2.28594C2.88301 1.68594 3.58856 1.21094 4.39967 0.860937C5.21079 0.510937 6.07745 0.335938 6.99967 0.335938C7.9219 0.335938 8.78856 0.510937 9.59967 0.860937C10.4108 1.21094 11.1163 1.68594 11.7163 2.28594C12.3163 2.88594 12.7913 3.59149 13.1413 4.4026C13.4913 5.21372 13.6663 6.08038 13.6663 7.0026C13.6663 7.92483 13.4913 8.79149 13.1413 9.6026C12.7913 10.4137 12.3163 11.1193 11.7163 11.7193C11.1163 12.3193 10.4108 12.7943 9.59967 13.1443C8.78856 13.4943 7.9219 13.6693 6.99967 13.6693Z" fill="#297FCE"/>
                              </svg></span>
                            
                          </a>
                        )}
                      </div>
                    </Col>
                    <Col md={12}>
                      <label className="control-label fs-14 fw-bold ">
                        Task Description
                      </label>
                      <div className="fs-14 mb-3">{projectData.surveyDetails?.notes}</div>
                    </Col>
                    <Col md={12}>
                      <label className="control-label fs-14 fw-bold ">
                        Documents:
                      </label>
                      <div className="fs-14">
                        {selectedDocuments.map((document: any, index: any) => (
                          <>
                          <Button
                            key={`docuemnt${index}`}
                            className="px-0 pt-0 install-documents-link text-decoration-none fs-14"
                            onClick={() =>
                              handleDownloadClick(document.key, document?.id)
                            }
                            variant="link"
                          >
                            <span className="link-text fw-semibold ps-0 ">{document?.label}</span>
                            <span className="px-2 body-text">|</span>
                          </Button>
                          
                          </>
                        ))}
                        
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="col-md-4">
                <Card className=" p-3 w-100  mb-3 rounded-3 install-card-hight">
                  <h3 className="fs-18 fw-bold info-text">Tasks</h3>
                  <div className="table-responsive">
                  <Scrollbar          
            className="tast-list-scroll"
          >
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="ps-0">Nam5e</th>
                          <th className="w-80 text-nowrap">Qty.</th>
                        </tr>
                      </thead>
                      <tbody>
                      {projectData.contract_products.map((cproduct: any, index: any) => (   
                        <tr key={`cproduct_${index}`}>
                        <td className="ps-0" title={`${cproduct?.product?.hs_sku} - ${ cproduct?.product?.name}`}>{cproduct?.product?.hs_sku} - { cproduct?.product?.name}</td>
                        <td className="w-80">{ cproduct?.updated_qty}</td>
                      </tr>             
                      ))}                        
                      </tbody>
                    </table>
                    </Scrollbar>
                  </div>
                </Card>
              </Col>
            </Row>
            
             
              <Row>
                <Col className=" col-md-12 ">
                  <ul className="mb-4 emp-tabs mt-2 nav nav-tabs installer-tabs">
                    <li>
                    <button className={`nav-link  ${installationStatus=='order_received' && 'active'}`} onClick={()=>handleActivePage('order_received')}>
                      Order Receive details
                    </button>
                    </li>
                    <li>
                    <button className={`nav-link  ${installationStatus=='first_day' && 'active'}`} onClick={()=>handleActivePage('first_day')}> 

                      First day installation process
                    </button>
                    </li>
                    <li>
                    <button className={`nav-link  ${installationStatus=='last_day' && 'active'} ${!checkPermission?'grid-action-cell-item-disabled disabled':''}`} onClick={()=>handleActivePage('last_day')}>
                      Last day installation process
                    </button>
                    </li>
                    <li>
                    <button className={`nav-link  ${installationStatus=='upload_media' && 'active'}`} onClick={()=>handleActivePage('upload_media')}>
                      Upload Media</button>
                      </li>
                  </ul>
                  
                </Col>
              </Row>
              <Row>
                {id && <>
                {installationStatus == "order_received" && (<>
                  <OrderRecievedDetails projectId={id} projectData={projectData} setAllDispatchReceived={setAllDispatchReceived}/>
                </>)}
                {installationStatus == "first_day" && (<>
                   <FirstDayCheckList projectId={id} projectData={projectData} />
                </>)}
                {installationStatus == "last_day" && (<>
                   <LastDayCheckList projectId={id}  projectData={projectData}  allDispatchReceived={allDispatchReceived}/>
                </>)}
                {installationStatus == "upload_media" && (<>
                   <MediaUpload projectId={id}/>
                </>)}
                </>}


                
              </Row>
           
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
