import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import CustomButton from "../../../components/common/CustomButton";
import Loader from "../../../components/common/Loader";
import { getPoList, getPoNumberListApi } from "../../../services/warehouseService";
import { useNavigate } from "react-router-dom";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";

interface IPostalCode {
  postalCodeUser: any;
  codes?: any;
}

const addSchema = yup.object({
  po_number: yup.object().required().label("Project Owner"),
});

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ProjectOption {
  id: string;
  po_number_str: string;
}

interface FormValues {  
  po_number: any;
}
const PoListPopup: React.FC<AddProps> = ({ isOpen, onClose }) => {
  const [projectOwnerOption, setProjectOwnerOption] = useState([]);
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const initialValues = {    
    po_number: [],
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    getPoList({})
      .then((response) => {
        const modifiyOption = response.data.resultSet.map((item: ProjectOption) => ({
          key: item.id,
          value: item.po_number_str,
        }));
        setProjectOwnerOption(modifiyOption);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
       // onClose();
      });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setBtnLoading(true);
    const formData: any = {
      ...values,    
    }; 
    

    const poId = formData.po_number?.id;

    navigate(`add-grn/${poId}`);
    
    return;
  };

  const getPoNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params
      }
      //console.log(requestParam);
      getPoNumberListApi(requestParam, 1)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal assign-modal" show={isOpen} onHide={onClose} centered>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative pb-0">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Add Good Inward Notes
                  </h4>                  
                  <div className="row">
                    <div className="col-md">
                    <FormAsyncSelect
                          id="po_number"
                          name="po_number"
                          label="PO Number"
                          isAsync
                          isClearable
                          getOptions={getPoNumber}
                          value={values.po_number}
                          onChange={(name: any, value: any) => {
                            setFieldValue(name, value);
                          }}
                        />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-65 mt-2"
                    variant="primary"
                  >
                    Submit
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default PoListPopup;
