import { axios } from "./axios";
import {
  INSTALLATION_TASK,

} from "../constants/paths";

export type CreateProductDTO = {
  contract_id: AsyncSelectType;  
  receivedProducts: Array<{    
      inspected_by: number;
      balance_qty: number;
      productDetail : object;
      purchase_order_product_id: number,
      product_id: number,
      qty_received: number,
      qty_returned: number,
      qty_discard: number,
      amount: number,
      quality_status: FormSelectType,
      return_remarks: string,
      images: any;  
      imagesPreview: any;  
      isEdit:boolean;
      is_return_approved:boolean;
  }>;
};

export interface FormSelectType{
  key: number | string;
  value: string;
};
export interface AsyncSelectType{
  id: number | string;
  name: string;
};

export const getInstallationTaskList = (formData: any): Promise<any> => {
  return axios.get(`${INSTALLATION_TASK}/task-list`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      type: formData.type,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};


export const getInstallationTaskDetails = (projectId: string | number | null): Promise<any> => {
  return axios.get(`${INSTALLATION_TASK}/view-task/${projectId}`);
};

export const getInstallationCheckList = (projectId: string | number | null): Promise<any> => {
  return axios.get(`${INSTALLATION_TASK}/installation-checklist/${projectId}`);
}

export const saveInstallationFirstStep = (formData: any): Promise<any> => {
  return axios.post(`${INSTALLATION_TASK}/save-first-day-checklist`, formData);
}

export const saveInstallationLastStep = (formData: any): Promise<any> => {
  return axios.post(`${INSTALLATION_TASK}/save-last-day-checklist`, formData);
}

export const uploadInstallationMediaApi = (formData: any): Promise<any> => {
  return axios.post(`${INSTALLATION_TASK}/upload-installation-media`, formData);
}

export const getInstallationMediaImagesApi = (projectId: string | number | null | undefined): Promise<any> => {
  return axios.get(`${INSTALLATION_TASK}/installation-media-images/${projectId}`);
}

export const saveInstallationMediaImagesApi = (projectId: string | number | null | undefined, formData: any): Promise<any> => {
  return axios.post(`${INSTALLATION_TASK}/save-installation-media-images/${projectId}`, formData);
}
export const getInstallationTaskOrdersReceived = (projectId: string | number | null): Promise<any> => {
  return axios.get(`${INSTALLATION_TASK}/get-order-received/${projectId}`);
};

export const uploadOrderReceivedImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${INSTALLATION_TASK}/upload-image`, data, config);
};

export const saveInstallationTaskOrdersReceived = (projectId: string | number | null,formData: CreateProductDTO): Promise<any> => {
  const orderProductsArray = formData.receivedProducts.filter((item: { qty_received: number; }) => item.qty_received > 0).map(({ productDetail, imagesPreview, amount, isEdit,is_return_approved,  ...rest }) => ({
    ...rest,
    quality_status: rest.quality_status?.key
  }));
  const transformData = {
    ...formData,      
    receivedProducts :orderProductsArray 
  };  
  return axios.post(`${INSTALLATION_TASK}/save-received-orders/${projectId}`, transformData);
};

export const saveInstallationInterimPaymentApi = (projectId: string | number | null | undefined, formData: any): Promise<any> => {
  return axios.post(`${INSTALLATION_TASK}/save-interim-payment-status/${projectId}`, formData);
}

export const getInstallationInterimPaymentApi = (projectId: string | number | null | undefined): Promise<any> => {
  return axios.get(`${INSTALLATION_TASK}/get-interim-payment-status/${projectId}`);
}
export const uploadCustomerSign = (formData: any):Promise<any> =>{
  return axios.post(`${INSTALLATION_TASK}/upload-signature`, formData);
}