import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment, { Moment } from "moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Form } from "react-bootstrap";
import constants from "../../constants/constants";

interface MyTimePickerProps {
  name: string;
  label?: string;
  className?: string;
  slotProps?: { textField: any };
  value?: Date | null;
  onChange: (name: string, newValue: Date | null) => void;
  touched?: any;
  errors?: any | undefined;
  minTime ?: any;
  maxTime ?: any;

}

const InputTimePicker: React.FC<MyTimePickerProps> = ({
  name,
  label,
  className = "",
  slotProps,
  value,
  onChange,
  touched,
  errors,
  minTime,
  maxTime
}) => {
  return (
    <>
      <Form.Label className="lh-sm control-label fs-14 fw-bold">
        {label}
      </Form.Label>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          name={name}
          className={`form-control ${
            touched && errors ? "is-invalid" : ""
          } ${className}`}
          slotProps={slotProps}
          value={value}
          //set min and max time
          minTime={minTime}
          maxTime={maxTime}
          onChange={(newValue) => onChange(name, newValue)}
        />
      </LocalizationProvider>
      <Form.Control.Feedback type="invalid">
                    {errors}
      </Form.Control.Feedback>
    </>
  );
};

export default InputTimePicker;
