import { axios } from "../axios";
import {
  ADD_SUB_SECTION,
  SUB_SECTION_DETAILS,
  UPDATE_SUB_SECTION,
  DELETE_SUB_SECTION,
  DELETE_FIELD,
  SUB_SECTION_DETAILS_ID

} from "../../constants/paths";

//Add section data
export const addSectionAPICall = async (data) => {
  return await axios.post(ADD_SUB_SECTION, data);
};

//Get Section data list by field list api
export const getSectionApiCall = async (id) => {
  return await axios.get(`${SUB_SECTION_DETAILS}/${id}`);
};

//Get a section  data by id
export const getSectionByIdApiCall = async (id) => {
  return await axios.get(
    `${SUB_SECTION_DETAILS_ID}/${id}`
    //{ headers: loginTokenHeader() }
  );
};

//Update a field sheet data by id
export const updateSectionAPICall = async (id, data) => {
  return await axios.patch(
    `${UPDATE_SUB_SECTION}/${id}`,
    data
    //{ headers: loginTokenHeader() }
  );
};

//Delete section by id

export const deleteSubSectionApiCall = async (id) => {
  return await axios.delete(
    `${DELETE_SUB_SECTION}/${id}`
    //  { headers: loginTokenHeader() }
  );
};

export const deleteFieldApiCall = async (fieldid) => {
  return await axios.delete(
    `${DELETE_FIELD}/${fieldid}`
    //  { headers: loginTokenHeader() }
  );
};
