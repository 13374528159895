import { kMaxLength } from "buffer";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import InfoIcon from "@mui/icons-material/Info";

interface MyCurrencyTextProps {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  value?: string | number;
  isInteger?: boolean;
  isDisabled?: boolean;
  onChange: (name: string, value: string | null | undefined) => void;
  touched?: any;
  errors?: any | undefined;
  maxLength?: number;
  handleChange?: any;
  handleBlur?: any;
  tooltip?: string;
}

const InputCurrencyText: React.FC<MyCurrencyTextProps> = ({
  name,
  label,
  placeholder,
  className = "",
  value,
  onChange,
  touched,
  errors,
  isInteger = false,
  isDisabled = false,
  maxLength = 16,
  handleChange, handleBlur, tooltip
}) => {
  return (
    <>
    <Form.Group className="mb-3 custom-from-group">
    { label && (
      <Form.Label className="lh-sm control-label fs-14 fw-bold">      
        {label}
        { tooltip && (
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip className="p-0 bg-primary">
              <p className="mb-0 p-0 fs-13 fw-bold ">
              { tooltip }
              </p>
            </Tooltip>
          }
        >
          <Button
            className="align-baseline d-inline-block ms-1 p-0 text-light"
            variant="link"
          >
            <InfoIcon className="fs-20 me-1 text-primary" />
          </Button>
        </OverlayTrigger>
        )}
      </Form.Label>
      )}      
      <CurrencyInput
        id={name}
        name={name}
        className={`form-control ${touched && errors ? "is-invalid" : ""} ${className}`}
        placeholder={placeholder}
        value={value}
        disabled={isDisabled}
        decimalsLimit={2}
        allowDecimals={!isInteger}
        maxLength={maxLength}   
        onBlur={handleBlur} onChange={handleChange}     
        onValueChange={(value: any, name) => {
          onChange(value, name);
        }}
      />
      {
        touched && errors && (
          <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
        )
      }
      </Form.Group>
    </>
  );
};

export default InputCurrencyText;
