import { axios } from "./axios";
import {   
  HOLIDAY_LIST,
  HOLIDAY_ADD,
  HOLIDAY_DELETE,
  HOLIDAY_DETAIL,
  HOLIDAY_EDIT,
} from "../constants/paths";

export type createHolidayDTO = {
  event_name: string;
  event_date: string;
};
export const getHolidayList = (utype:string| undefined,formData: any): Promise<any> => {
  return axios.get(`${HOLIDAY_LIST}/${utype}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const createHolidayApi = (data: createHolidayDTO): Promise<any> => {
  const transformData = {
    ...data,
  };

  return axios.post(HOLIDAY_ADD, transformData);
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getHolidayByIdApi = (id: string | number): Promise<any> => {
  return axios.get(HOLIDAY_DETAIL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateHolidayApi = (
  id: string | number | undefined,
  data: createHolidayDTO
): Promise<any> => {
  const transformData = {
    ...data    
  };
  return axios.put(HOLIDAY_EDIT + "/" + id, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteHolidayApi = (id: string | number | null): Promise<any> => {
  return axios.delete(HOLIDAY_DELETE + "/" + id);
};