import { axios } from "../services/axios";
import {  
  TICKET_COMMENTS,
  TICKET_COMMENTS_ADD,
  TICKET_CREATE,
  TICKET_DETAIL,
  TICKET_EXPORT,
  TICKET_LIST,
  TICKET_MARK_COMPLETE,
  TICKET_UPDATE,
  TICKET_UPLOAD_FILE,
  SUPPORT_TICKET_COMMENTS,
  SUPPORT_TICKET_COMMENTS_ADD,
  SUPPORT_TICKET_CREATE,
  SUPPORT_TICKET_DETAIL,
  SUPPORT_TICKET_LIST,
  SUPPORT_TICKET_MARK_COMPLETE,
  SUPPORT_TICKET_UPDATE,
  SUPPORT_TICKET_UPLOAD_FILE,
  SERVICE_COMPLAINT_LIST,
  SERVICE_COMPLAINT_COMMENTS_ADD,
  SERVICE_COMPLAINT_COMMENTS,
  SERVICE_COMPLAINT_EXPORT,
  SERVICE_COMPLAINT_MARK_COMPLETE,
  SERVICE_COMPLAINT_UPLOAD_FILE,
  SERVICE_COMPLAINT_UPDATE,
  SERVICE_COMPLAINT_DETAIL,
  SERVICE_COMPLAINT_CREATE,
  SERVICE_COMPLAINT_NUMBER,
} from "../constants/paths";



export const getTicketList = (formData: any): Promise<any> => {
  return axios.get(TICKET_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};


export const createTicketApi = (data: any): Promise<any> => {
  const transformData = {
    ...data,
    assigned_to: data?.assigned_to?.id,
    department: data?.department?.key
  };
  
  return axios.post(TICKET_CREATE, transformData);
};

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const getTicketDetailsApi = (id: string): Promise<any> => {
  return axios.get(TICKET_DETAIL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateTicketApi = (id: string, data: any): Promise<any> => {
  const transformData = {
    ...data,
    assigned_to: data?.assigned_to?.id,
    department: data?.department?.key  
  };
  return axios.post(TICKET_UPDATE + "/" + id, transformData);
};

export const uploadTicketImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${TICKET_UPLOAD_FILE}`, data, config);
};

export const updateTicketStatusApi =(id: string | number | undefined): Promise<any> => {
  return axios.patch(TICKET_MARK_COMPLETE + "/" + id, {});
};

export const exportTicketData = (params:any = {}) : Promise<any> => {
  return axios.get(TICKET_EXPORT,{params,responseType: 'arraybuffer',});
}

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const getTicketCommentsApi = (id: string | number | undefined): Promise<any> => {
  return axios.get(TICKET_COMMENTS + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const saveTicketCommentsApi = (ticketId: string | number | undefined, data:any): Promise<any> => {
  const transformData = {
    ...data,
  };

  delete transformData.filename;
  
  return axios.post(`${TICKET_COMMENTS_ADD}/${ticketId}`, transformData);
};

export const getSupportTicketList = (formData: any): Promise<any> => {
  return axios.get(SUPPORT_TICKET_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};


export const createSupportTicketApi = (data: any): Promise<any> => {
  const transformData = {
    ...data,   
    ticket_type: data?.ticket_type?.key
  };
  
  return axios.post(SUPPORT_TICKET_CREATE, transformData);
};

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const getSupportTicketDetailsApi = (id: string): Promise<any> => {
  return axios.get(SUPPORT_TICKET_DETAIL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateSupporTicketApi = (id: string, data: any): Promise<any> => {
  const transformData = {
    ...data,
    ticket_type: data?.ticket_type?.key  
  };
  return axios.post(SUPPORT_TICKET_UPDATE + "/" + id, transformData);
};

export const uploadSupportTicketImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${SUPPORT_TICKET_UPLOAD_FILE}`, data, config);
};

export const updateSupportTicketStatusApi =(id: string | number | undefined): Promise<any> => {
  return axios.patch(SUPPORT_TICKET_MARK_COMPLETE + "/" + id, {});
};

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const getSupportTicketCommentsApi = (id: string | number | undefined): Promise<any> => {
  return axios.get(SUPPORT_TICKET_COMMENTS + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const saveSupportTicketCommentsApi = (ticketId: string | number | undefined, data:any): Promise<any> => {
  const transformData = {
    ...data,
  };

  delete transformData.filename;
  
  return axios.post(`${SUPPORT_TICKET_COMMENTS_ADD}/${ticketId}`, transformData);
};


export const getServiceComplaintList = (formData: any): Promise<any> => {
  return axios.get(SERVICE_COMPLAINT_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * Get Complaint number
 * @returns Complaint number
 */
export const getServiceComplaintNumber = (): Promise<any> => {
  return axios.get(SERVICE_COMPLAINT_NUMBER);
};


export const createServiceComplaintApi = (data: any): Promise<any> => {
  const transformData = {
    ...data,
    contract_id: data?.contract_id?.id,
    assigned_to: data?.assigned_to?.id,
    task_owner_id: data?.task_owner_id?.id,
    department: data?.department?.key
  };

  delete transformData.ticket_no;
  
  return axios.post(SERVICE_COMPLAINT_CREATE, transformData);
};

/**
 * @param {id: string } params
 * @returns Service Complaint details
 */
export const getServiceComplaintDetailsApi = (id: string): Promise<any> => {
  return axios.get(SERVICE_COMPLAINT_DETAIL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateServiceComplaintApi = (id: string, data: any): Promise<any> => {
  const transformData = {
    ...data,
    contract_id: data?.contract_id?.id,
    assigned_to: data?.assigned_to?.id,
    task_owner_id: data?.task_owner_id?.id,
    department: data?.department?.key
  };

  delete transformData.ticket_no;
  return axios.post(SERVICE_COMPLAINT_UPDATE + "/" + id, transformData);
};

export const uploadServiceComplaintImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${SERVICE_COMPLAINT_UPLOAD_FILE}`, data, config);
};

export const updateServiceComplaintStatusApi =(id: string | number | undefined): Promise<any> => {
  return axios.patch(SERVICE_COMPLAINT_MARK_COMPLETE + "/" + id, {});
};

export const exportServiceComplaintData = (params:any = {}) : Promise<any> => {
  return axios.get(SERVICE_COMPLAINT_EXPORT,{params,responseType: 'arraybuffer',});
}

/**
 * @param {id: string } params
 * @returns Service Complaint details
 */
export const getServiceComplaintCommentsApi = (id: string | number | undefined): Promise<any> => {
  return axios.get(SERVICE_COMPLAINT_COMMENTS + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Service Complaint details
 */
export const saveServiceComplaintCommentsApi = (ticketId: string | number | undefined, data:any): Promise<any> => {
  const transformData = {
    ...data,
  };

  delete transformData.filename;
  
  return axios.post(`${SERVICE_COMPLAINT_COMMENTS_ADD}/${ticketId}`, transformData);
};