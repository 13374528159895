import React from "react";
import { FC } from "react";
import { Card, Col, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import { ITypeProps } from "./surveyInterface";
import CommonNotes from "./commonNotes";
import { conditionsOperator } from "../../../constants/constants";

const TypeText: FC<ITypeProps> = ({
  field,
  index,
  fieldIndex,
  subIndex,
  templateData,
  setTemplateData,
  errors,
  values,
  setFieldValue,
  handleBlur,
  touched,
  fieldConditions
}) => {

  const evaluateCondition = (conditionValue: any,operator: string, value: any) => {
    let visibility = false;
    switch (operator) {
      case 'Equal':
        visibility = conditionValue == value;
        break;
      case 'Not Equal':
        visibility = conditionValue != value;
        break;
      case 'Greater Than':
        visibility = conditionValue > value;
        break;
      case 'Less Than':
        visibility = conditionValue < value;
        break;
      default:
        visibility = false;
    }
    return visibility;
   };
  
  //the method is different for text
  const changeConditions = (  value: any, field_id:number) => {
    console.log("fielddd id", field_id)
    if(fieldConditions.length > 0){  
        fieldConditions.map((item: any) => {
          if(item.conditions.length > 0){
             item.conditions.map((condition: any) => {
               if(condition.field.key === field_id){
                      console.log("condii======", condition.value.toLowerCase(), condition.condition.value, value.toLowerCase())
                     if(evaluateCondition(condition.value.toLowerCase(), condition.condition.value, value.toLowerCase()))
                     {
                       if(item.actions.length > 0 ){
                         item.actions.map((action: any)=>{                                                                                                         
                           let fieldSheetTextTemp = [...templateData];
                           //update the visibility of the field where fieldSheetTextTemp find the section id an sub section id and field id
                             fieldSheetTextTemp.map((section: any, index: number) => {
                              console.log("section.section_id",section.section_id)
                              console.log("item.section_id",item.section_id)
                               if(section.section_id === item.section_id){
                                 section.subsections.map((subSection: any, subIndex: number) => {
                                   if(subSection.subsection_id === item.subsection_id){
                                     subSection.fields.map((field: any, fieldIndex: number) => {
                                       if(field.field_id === action.field.key){     
                                         console.log("field.field_id",field.field_id)
                                         console.log("action.field.key",action.field.key)                                   
                                         field.visibility = action.action.key == 'Show' ? true : false;
                                       } 
                                     })
                                   }
                                 })
                               }
                             }
                           )
                           setTemplateData(fieldSheetTextTemp);
                           //  fieldSheetTextTemp[index].subsections[subIndex].fields.find((item: any) => item.field_id === action.field.key).visibility = action.action.key == 'Show' ? true : false;
                           //  setTemplateData(fieldSheetTextTemp); 
                        })  
                       }                                  
                     }
                     //set else with reset the visibility of the field
                     else{
                       if(item.actions.length > 0 ){
                         item.actions.map((action: any)=>{                                                                                                         
                           let fieldSheetTextTemp = [...templateData];
                           //update the visibility of the field where fieldSheetTextTemp find the section id an sub section id and field id
                             fieldSheetTextTemp.map((section: any, index: number) => {
                               if(section.section_id === item.section_id){
                                 section.subsections.map((subSection: any, subIndex: number) => {
                                   if(subSection.subsection_id === item.subsection_id){
                                     subSection.fields.map((field: any, fieldIndex: number) => {
                                      
                                       if(field.field_id === action.field.key){                                                                                    
                                         field.visibility = false //action.action.key == 'Show' ? true : false;
                                       } 
                                     })
                                   }
                                 })
                               }
                             }
                           )
                           setTemplateData(fieldSheetTextTemp);
                           //  fieldSheetTextTemp[index].subsections[subIndex].fields.find((item: any) => item.field_id === action.field.key).visibility = action.action.key == 'Show' ? true : false;
                           //  setTemplateData(fieldSheetTextTemp); 
                        })
                       }
                     }
               }
             }
           )
           }
         }
       )
   }
 }

  const handleInputChangeSelect = (
    value: string,
    index: number,
    subIndex: number,
    fieldIndex: number,
    name: string,
    setFieldValue: any
  ) => {
    setFieldValue(name, value);
    let fieldSheetTextTemp = [...templateData];
    fieldSheetTextTemp[index].subsections[subIndex].fields[fieldIndex].value =
      value;
    setTemplateData(fieldSheetTextTemp);
    changeConditions( value, field.field_id);
  };
  return (
    <div>
      <Card className="p-3 w-100 pageContentInner mb-3">
        <div className="d-flex flex-column h-100 rounded-0 ">
          <Row className="d-flex">
            <Col className="col-md-11">
              <p className="fs-14 mb-2">
                {field.title} {field.require && "*"}
              </p>
              <div className="w-100 survery-input">
                <InputText
                  controlId={field.name}
                  label={field.label}
                  placeholder={field.label}
                  touched={touched[field.name]}
                  handleBlur={handleBlur}
                  handleChange={(e: any) =>
                    handleInputChangeSelect(
                      e.target.value,
                      index,
                      subIndex,
                      fieldIndex,
                      field.name,
                      setFieldValue
                    )
                  }
                  errorsField={errors[field.name]}
                  value={values[field.name]}
                />
              </div>
            </Col>
            <Col className="col-md-1 text-end">
              <CommonNotes
                field={field}
                index={index}
                fieldIndex={fieldIndex}
                subIndex={subIndex}
                touched={touched}
                errors={errors}
                values={values}
                templateData={templateData}
                setTemplateData={setTemplateData}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default TypeText;
