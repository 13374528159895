import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import InputDateRangePicker from "../../../components/common/InputDateRangePicker";
import { FinanceDashboardProjectPayment } from "./Project-Payment";
import { FinanceDashboardInvoiceForecast } from "./Invoice-forecast";
import { FinanceDashboardNewProjectOnboard } from "./New-Project-Onboard";
import { FinanceDashboardExpenses } from "./Expenses";
import FinancePendingAssetApproval from "./Pending-Assets";
import FinancePendingInvoiceCreation from "./Pending-Invoice-Creation";
import moment from "moment";
import constants from "../../../constants/constants";
import FinancePendingOutstandingInvoices from "./Outstanding-Invoice";
import FinancePendingIncomeExpenses from "./Income-Expenses";
import InputCurrencyText from "../../../components/common/InputCurrencyText";
import { getLabourCostApi, setLabourCostApi } from "../../../services/project.service";
import Invoice from "../../Dashboard/Invoice";


export const FinanceDashboard = () => {
  const [orderDateRange, setOrderDateRange] = useState({
    dateFrom: moment()
      .subtract("12", "months")
      .format(constants.dateFormats.databaseFormat),
    dateTo: moment().format(constants.dateFormats.databaseFormat),
  });
  const [loading, setLoading] = useState(true);
  const [labourCost, setLabourCost] = useState(0);
  const [isMutation, setMutation] = useState(false);

  const orderDateFilter = (
    data: any,
    fieldName: string,
    setFieldValue: any
  ) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(
        constants.dateFormats.databaseFormat
      );
      const endDate = moment(dataRange[1]).format(
        constants.dateFormats.databaseFormat
      );
      setOrderDateRange({
        dateFrom: startDate,
        dateTo: endDate,
      });
    } else {
      setOrderDateRange({
        dateFrom: moment()
          .subtract("12", "months")
          .format(constants.dateFormats.databaseFormat),
        dateTo: moment().format(constants.dateFormats.databaseFormat),
      });
    }
  };

  const getLabourCost = () => {    
    getLabourCostApi()
      .then((response) => {  
        setLabourCost(response.data.labour_cost)     
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetLabourCost = () => {    
    const requestParam = {
      labour_cost : labourCost
    }
    setLabourCostApi(requestParam)
      .then((response) => {  
        setMutation(!isMutation)
      })
      .catch((error) => {})
      .finally(() => {});
  };

  useEffect(() => {
    getLabourCost();
  }, []);

  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 py-2 my-1">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="fw-bold h4 mt-2 mb-0">Dashboard</h1>
          </div>

          <div className="dashboard-right-date d-flex w-auto">
            <div className="me-2">
              <InputCurrencyText
                name="price"
                label=""
                placeholder="Enter Labour Cost"
                className="me-2 w-150"
                value={labourCost}
                touched={false}
                onChange={(value:any, name) => {
                  console.log("change", value);
                  setLabourCost(value);
                }} // isDisabled={values?.category?.is_mileage_type}
                // onChange={}
                handleBlur={(value:any) => {
                  handleSetLabourCost();                 
                }}
              />
            </div>
            <InputDateRangePicker
              controlId="date"
              label=""
              placeholder="Date Range"
              touched={false}
              handleBlur={false}
              handleChange={orderDateFilter}
              handleClose={orderDateFilter}
              errorsField={false}
              
              position="auto"
              defaultValue={[
                new Date(orderDateRange.dateFrom),
                new Date(orderDateRange.dateTo),
              ]}
            />
          </div>
        </div>
      </div>

      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <>
            <Row className="mt-0 gx-2">
              <Col md={8}>
                <FinancePendingOutstandingInvoices dateRange={orderDateRange} />
              </Col>
              <Col md={4}>
                <FinanceDashboardProjectPayment dateRange={orderDateRange} isMutation={isMutation} />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FinanceDashboardInvoiceForecast dateRange={orderDateRange} />
              </Col>
            </Row>
            <Row className="mt-2 gx-2">
              <Col md={6}>
                <FinanceDashboardNewProjectOnboard dateRange={orderDateRange} />
              </Col>
              <Col md={6}>
                <FinancePendingInvoiceCreation dateRange={orderDateRange} />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FinancePendingIncomeExpenses dateRange={orderDateRange} isMutation={isMutation} />
              </Col>
            </Row>
            <Row className="mt-2">
              <Invoice orderDateRange={orderDateRange} />
            </Row>
            <Row className="mt-2">
              <Col>
                <FinancePendingAssetApproval />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={12}>
                <FinanceDashboardExpenses dateRange={orderDateRange} />
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};
