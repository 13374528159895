import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/common/CustomButton";
import Loader from "../../../../components/common/Loader";
import AddIcon from "@mui/icons-material/AddCircle";
import { getInvociesByIdApi, printFinanceInvoicesApi } from "../../../../services/financeService";
import { currencyMask, getLocalDateFormat } from "../../../../utility/common";
import { invoiceStatus } from "../../../../constants/constants";
import { getCompanyDetail } from "../../../../services/authService";
import PrintIcon from "@mui/icons-material/Print";

interface Item {
  description: string;
  qty?: number | null;
  price?: number | null;
  tax_percentage?: number;
  amount?: number | string;
  tax_amount?: number;
}

export const ViewInvoice = () => {
  const [companyData, setCompanyData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [totalAmount, setTotalAmount] = useState<any>({
    subtotal: 0,
    tax: 0,
    total: 0,
  });
  const navigate = useNavigate();
  let { id } = useParams();

  const getInvoiceDetail = (id: string) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getInvociesByIdApi(id)
        .then((response) => {
          const dpObj = response.data;
          setInvoiceData(dpObj);
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/finance/invoices/");
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
            navigate("/finance/invoices");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/finance/invoices");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    //console.log("121212");
    Promise.all([getCompanyDetailsData()]).then(() => {
      if (id) {
        Promise.all([getInvoiceDetail(id)]).then(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, [id]);

  useEffect(() => {
    if (invoiceData) {
      if (invoiceData.contract_id) {
        calculateTotalAmount(invoiceData.invDetails);
      }
    }
  }, [invoiceData.contract_id]);

  const handleCancel = () => {
    navigate(-1);
  };

  const getCompanyDetailsData = async () => {
    return new Promise((resolve, reject) => {
      getCompanyDetail().then((response) => {
        setCompanyData(response?.data);
        return resolve(true);
      });
    });
  };

  // Function to calculate the sum of all amounts
  const calculateTotalAmount = (
    invoiceDetails: any[],
    indexNumber?: number,
    invoiceData?: any,
    setFieldValue?: any
  ) => {
    const updatedData: Item[] = [...invoiceDetails];
    if (typeof indexNumber === "number" && indexNumber !== undefined) {
      const qty: number = invoiceData?.qty ? invoiceData.qty : 0;
      const price: number = invoiceData?.price ? invoiceData.price : 0;
      const tax_percentage: number = invoiceData?.tax_percentage
        ? invoiceData.tax_percentage
        : 0;
      updatedData[indexNumber].qty = qty;
      updatedData[indexNumber].price = price;
      updatedData[indexNumber].tax_percentage = tax_percentage;
      const amt = parseFloat(qty.toString()) * parseFloat(price.toString());
      updatedData[indexNumber].amount = (
        amt +
        (amt * tax_percentage) / 100
      ).toFixed(2);
      setFieldValue("invoiceDetails", updatedData);
    }
    // Calculate subtotal, total tax, and grand total
    let subtotal: number = 0;
    let totalTax: number = 0;

    updatedData.forEach((item: Item) => {
      const { qty, price, tax_percentage } = item;
      let amt = 0;
      if (qty && price) {
        amt = parseFloat(qty.toString()) * parseFloat(price.toString());
        subtotal += amt;
        if (tax_percentage) {
          totalTax +=
            (amt * (parseFloat(tax_percentage.toString()) || 0)) / 100;
        }
      }
    });
    const grandTotal: number = subtotal + totalTax;
    setTotalAmount({
      subtotal: subtotal.toFixed(2),
      tax: totalTax.toFixed(2),
      total: grandTotal.toFixed(2),
    });
  };

  const renderStatusDiv = (
    statusType: string,
    checkedVal: number | string | undefined
  ) => {
    const statsuArr: any = invoiceStatus;
    const statusName = statsuArr.find(
      (item: { key: string | number | undefined }) => item.key == checkedVal
    );
    return (
      <span
        className={`${statusName?.tagClass} py-1 px-2 ms-2 rounded-1 d-inline-block fs-14 vertical-middle`}
      >
        {statusName?.value}
      </span>
    );
  };

  /**
   * Download Document click handler
   */
  const handlePrint = () => {
    printFinanceInvoicesApi(id)
      .then((response: any) => {
        const signedFileUrl = response.data;
        // window.open(signedFileUrl, '_blank');
        window.open(signedFileUrl, "_blank");
        //console.log(signedFileUrl);
      })
      .catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {});
  };

  // console.log(initialValues, productArray);
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  Invoice {invoiceData?.inv_number_str}
                  {renderStatusDiv("status", invoiceData.status)}
                </h1>
              </Col>              
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <div>
              <Card className="p-3 w-100 pageContentInner mb-3">
                <div>
                  <Row>
                    <Col className="col-md-8">
                      <div className="fw-bold fs-14">
                        {" "}
                        {invoiceData?.customer_name}
                      </div>
                      <div className="fs-14"> {invoiceData?.address}</div>
                    </Col>
                    <Col className="col-md-2">
                      <div className="fw-bold fs-14">VAT Number</div>
                      <div className="fs-14"> {companyData?.VATNumber}</div>
                    </Col>
                    <Col className="col-md-2">
                      <div className="fw-bold fs-14">Invoice Date</div>
                      <div className="fs-14"> {getLocalDateFormat(invoiceData?.inv_date)}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
              <Card className="p-3 w-100 pageContentInner mb-3">
                <div>
                  <Row>
                    <Col className="col-md-12">
                      <div>
                        <Row>
                          <Col className="fw-bold col-md-7  fs-14">
                            Description
                          </Col>
                          <Col className="fw-bold col-md-1  fs-14">
                            Qty
                          </Col>
                          <Col className="fw-bold col-md-1  fs-14">
                            Unit Price
                          </Col>
                          <Col className="fw-bold col-md-1  fs-14">
                            VAT
                          </Col>
                          <Col className="fw-bold col-md-2  text-end fs-14">
                            Amount(£)
                          </Col>
                        </Row>
                        <hr className="mb-0"/>
                        {invoiceData?.invDetails.map(
                          (entry: any, index: any) => (
                            <div
                              className="border-bottom py-2"
                              key={`invoiceDetails_${index}`}
                            >
                              <Row className="mb-0">
                                <Col className=" col-md-7  fs-14">
                                  <div className="col-6">
                                    {entry.description}
                                  </div>
                                </Col>
                                <Col className=" col-md-1  fs-14">
                                  {entry.qty}
                                </Col>
                                <Col className=" col-md-1  fs-14">
                                  {entry.price}
                                </Col>
                                <Col className=" col-md-1  fs-14">
                                  {entry.tax_percentage}
                                </Col>
                                <Col className=" col-md-2  text-end fs-14">
                                  {entry.amount}
                                </Col>
                              </Row>
                            </div>
                          )
                        )}
                      </div>
                      <Row className="mt-4">
                        <Col className=" col-md-9 fs-14">
                          <div>
                            <div className="fs-14 fw-bold">
                              <span className="text-danger"> Due Date </span>:{" "}
                              <span className="">{getLocalDateFormat(invoiceData.due_date)}</span>
                            </div>
                            <div className="fw-14 my-1">
                              {companyData?.companyName}
                            </div>
                            <div className="fw-14">
                              <span className="fw-bold">Acct No.: </span>{" "}
                              {companyData?.acct_no}
                            </div>
                            <div className="fw-14">
                              <span className="fw-bold">Sort Code: </span>{" "}
                              {companyData?.sort_code}
                            </div>
                          </div>
                        </Col>
                        <Col className=" col-md-3  fs-14">
                          <Row>
                            <Col className="d-flex col-12">
                              <div className="text-start w-100">Subtotal</div>
                              <div className="text-end w-100">
                                {currencyMask(totalAmount.subtotal)}
                              </div>
                            </Col>
                            <Col className="d-flex col-12 mt-2">
                              <div className="text-start w-100">
                                Total Excempt
                              </div>
                              <div className="text-end w-100">
                                {currencyMask(totalAmount.tax)}
                              </div>
                            </Col>
                            <hr className="mb-2 mt-2"/>
                            <Col className="d-flex col-12">
                              <div className="text-start w-100 fw-bold text-black fs-18">
                                Total (£)
                              </div>
                              <div className="text-end w-100 fs-18">
                                {currencyMask(totalAmount.total)}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Card>
                <Card className="p-3 w-100 pageContentInner mb-3">
                  <h3 className="fs-18 mb-0 info-text fw-bold d-inline-block">
                    Payment Advice
                  </h3>
                  <Row className="mt-3">
                    <Col className="col-4">
                      <label className=" fs-16 fw-bold">
                        { companyData.companyName}
                      </label>
                      <div className="fs-14 mb-1">
                      { companyData.address1} <br /> { companyData.address2 }
                      </div>
                    </Col>
                    <Col className="col-2">
                      <label className="fs-14 fw-bold">
                        Customer
                      </label>
                      <div className="fs-14 mb-1">
                        {invoiceData?.customer_name}
                      </div>
                    </Col>
                    <Col className="col-2">
                      <label className=" fs-14 fw-bold">
                        Invoice Number
                      </label>
                      <div className="fs-14 mb-1">{invoiceData?.inv_number_str}</div>
                    </Col>
                    <Col className="col-2">
                      <label className=" fs-14 fw-bold">
                        Amount Due
                      </label>
                      <div className="fs-14 mb-1">{ invoiceData?.status == 1 ? "0.00" : currencyMask(totalAmount.total)}</div>
                    </Col>
                    <Col className="col-2">
                      <label className=" fs-14 fw-bold">
                        Due Date
                      </label>
                      <div className="fs-14 mb-1">{getLocalDateFormat(invoiceData?.due_date)}</div>
                    </Col>
                  </Row>
                </Card>              
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
