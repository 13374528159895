import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { addSchema } from "./validation";
import { toast } from "react-toastify";
import InputText from "../../../components/common/InputText";
import { Card, Col, Row } from "react-bootstrap";
import { getRolesList } from "../../../services/roleService";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import {
  allowedFileTypes,
  maxFileSizeMB,
  rolesDefaultParams,
} from "../../../constants/constants";
import FormSelect from "../../../components/common/FormSelect";
import FileUploader from "../../../components/common/FileUploader";
import {
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
} from "../../../validations/ValidationErrors";
import {
  uploadSiteImageApi,
  createExternalApi,
  getExternalByIdApi,
  updateExternalApi,
} from "../../../services/externalService";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";

type FormValues = {
  site_name: string;
  site_url: string;
  site_icon: string;
  role: string[] | any;
};

interface RoleOption {
  key: string;
  value: string;
}

export default function AddExternal() {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [externalData, setExternalData] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams();

  /**
   * Get external-link details from server
   */
  const getExternalDtails = async (id: string) => {
    setLoading(true);
    await getExternalByIdApi(id)
      .then((response: any) => {
        setExternalData(response.data);
        setLoading(false);
        setImagePreview(response.site_icon);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };

  // fetch roles data to set in the listing
  const fetchRolls = async () => {
    return new Promise((resolve, reject) => {
      getRolesList(rolesDefaultParams).then((response) => {
        const roleModifyOption = response.data?.resultSet.map((item: any) => ({
          key: String(item.id),
          value: item.role,
        }));
        setRoleOptions(roleModifyOption);
        return resolve(true);
      });
    });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    if (id) {
      const formData = {
        ...values,
      };
      updateExternalApi(id, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/external-link");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      const formData = {
        ...values,
      };
      createExternalApi(formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/external-link");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  // cancel redirection
  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchRolls().then(() => {
      if (id) {
        getExternalDtails(id);
      }else{
        setLoading(false)
      }
    });
  }, []);

  const uploadProfileImage = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast(FILE_TYPE_ERROR, { type: toast.TYPE.ERROR });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(FILE_SIZE_ERROR, { type: toast.TYPE.ERROR });
      return;
    } else {
      setImageLoading(true);
      if (imageFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(imageFile);
      } else {
        setImagePreview(null);
      }
      // Create FormData object
      const formData = new FormData();
      // Append the file to the FormData object
      formData.append("site_icon", imageFile);
      uploadSiteImageApi(formData)
        .then((response) => {
          setImageLoading(false);
          setFieldValue("site_icon", response.data);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };

  const filteredRoles = (role_id: any) => {
    const roleData = roleOptions.filter((option: any) =>
      role_id.some((roleId: any) => roleId.id === option.key)
    );
    return roleData;
  };

  const initialValues = {
    site_name: externalData?.site_name ? externalData?.site_name : "",
    site_url: externalData?.site_url ? externalData?.site_url : "",
    site_icon: externalData?.site_icon ? externalData?.site_icon : "",
    role: externalData.role_id ? filteredRoles(externalData.role_id) : "",
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">{id ? "Edit" : "Add"} Link</h1>
              </Col>
            </Row>
          </div>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
                  <Card className="p-3 w-100 pageContentInner">
                    <div>
                      <Row>
                        <div className="col-md-4">
                          <InputText
                            controlId="site_name"
                            label="Site Name"
                            placeholder="Enter site name"
                            touched={touched.site_name}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.site_name}
                            value={values.site_name}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputText
                            controlId="site_url"
                            label="URL"
                            placeholder="Http://"
                            touched={touched.site_url}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.site_url}
                            value={values.site_url}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-4">
                          <FormSelect
                            placeholder="Role"
                            label="Role"
                            name="role"
                            options={roleOptions}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            multi={true}
                            allowSelectAll={true}
                            value={values.role}
                            error={errors.role}
                            touched={touched.role}
                          />
                        </div>
                        <div className="col-md-4">
                          <FileUploader
                            uploadProfileImage={(e) =>
                              uploadProfileImage(e, setFieldValue)
                            }
                            imageLoading={imageLoading}
                            imagePreview={imagePreview}
                            label="Site Icon"
                          />

                          {errors.site_icon && (
                            <p className="text-danger">Site icon is required</p>
                          )}
                        </div>
                      </Row>
                    </div>
                  </Card>
                </div>

                <div className="text-end my-3 px-3">
                 <CustomButtonCancel label="Cancel" />
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    {id ? "Update" : "Add"}
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </React.Fragment>
  );
}
