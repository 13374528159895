import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import FormSelect from "../../components/common/FormSelect";
import { getAllManagersApiCall } from "../../services/authService";
import CustomButton from "../../components/common/CustomButton";
import {  
  assingProjectOwnerApi,
} from "../../services/postalcode.service";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";

interface IPostalCode {
  postalCodeUser: any;
  codes?: any;
}

const addSchema = yup.object({
  project_owner: yup.object().required().label("Project Owner"),
});

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  codes: string[];
}

interface ProjectOption {
  id: string;
  full_name: string;
}

interface FormValues {
  codes: string[];
  project_owner: any;
}
const ProjectOwnerAssign: React.FC<AddProps> = ({ isOpen, onClose, codes }) => {
  const [projectOwnerOption, setProjectOwnerOption] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const initialValues = {
    codes: codes,
    project_owner: [],
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    getAllManagersApiCall()
      .then((response) => {
        const modifiyOption = response.data.map((item: ProjectOption) => ({
          key: item.id,
          value: item.full_name,
        }));
        setProjectOwnerOption(modifiyOption);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onClose();
      });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    const postalCodeIds = values?.codes.map((item:any) => item.id)
    setBtnLoading(true);
    const formData: any = {
      ...values,
      postal_code_ids:postalCodeIds
    };
 
    assingProjectOwnerApi(formData).then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose()

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal" show={isOpen} onHide={onClose}>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Assign Project Owner
                  </h4>
                  <div className="row">
                    <div className="col-md mb-2">
                        Assign Project Owner for following Postal Codes 
                        <div className="fw-bold">                        
                            {codes.map((item:any) => item.code).join(', ')}
                        </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md">
                      <FormSelect
                        placeholder="Assign Project Owner"
                        label="Assign Project Owner"
                        name="project_owner"
                        options={projectOwnerOption}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.key}
                        onChange={(name: string, value: string) => {
                          setFieldValue(name, value);
                        }}
                        value={values.project_owner}
                        error={errors.project_owner}
                        touched={touched.project_owner}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Submit
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default ProjectOwnerAssign;
