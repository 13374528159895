import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridSingleSelectOperators
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Row } from "react-bootstrap";
import { checkAbility } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import constants, { LeaveStatus, pageSizeModel } from "../../../constants/constants";
import moment from "moment";
import ViewIcon from "@mui/icons-material/Visibility";
import { getEmployeeLeaveHistoryList } from "../../../services/leavesService";
import LogoutIcon from "@mui/icons-material/Logout";
import InputDatePicker from "../../../components/common/InputDatePicker";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LeaveHistoryData from "./LeaveHistoryData";

const EmployeeLeaveHistoryList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("date_from");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [selectedYear, setSelectedYear] = useState<any>(null);
  const [leaveHistroyData, setLeaveHistroyData] = useState<any | null>([]);
  const [leaveAvailableData, setLeaveAvailableData] = useState<any | null>(0);


  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const handleApplyLeave = () => {
    navigate("/leaves/apply");
  };
  
  const handleViewClick = async (id: GridRowId) => {
    navigate(`/leaves/view/${id}`);
  };  

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    year:moment(selectedYear).isValid() ? moment(selectedYear).format("YYYY"): "",
    ...(search ? { search } : {}),
  };


  const fetchData = (defaultParams: any) => {    
    getEmployeeLeaveHistoryList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
        setLeaveHistroyData(response.data?.leavesSummary);
        setLeaveAvailableData(response.data?.remainingLeaves?.leaves ? response.data?.remainingLeaves?.leaves : 0);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "$leaveType.name$",
      headerName: "Leave Type",
      flex: 1,
      sortable: true,
      hideable: false,
      renderCell(params) {
        const orderDate = new Date(params.row.date_from);
        return (
          <span title={params.row.leaveType.name}>
            {params.row.leaveType.name}
          </span>
        );
      },
    },
    {
      field: "leaves_count",
      headerName: "No. of Days",
      type: "number",
      flex: 0.5,
      sortable: true,
      renderCell(params) {        
        return params.row.leaves_count == '0.5' ?  `${params.row.leaves_count} (${params.row.session == '1' ? 'AM' : 'PM'})`  : params.row.leaves_count;
      },
    },
    {
      field: "date_from",
      headerName: "From",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date_from),
      renderCell(params) {
        const orderDate = new Date(params.row.date_from);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)} { params.row.is_appointment_leave && (`(${params.row.appointment_time})`)}
          </span>
        );
      },
    },
    {
      field: "date_to",
      headerName: "To",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date_to),
      renderCell(params) {
        const orderDate = new Date(params.row.date_to);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)} { params.row.is_appointment_leave && (`(${moment(params.row.appointment_time, "HH:mm:ss").add('2', 'hours').format('HH:mm:ss')})`)}
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Reason",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      valueOptions: LeaveStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),      
      renderCell(params) {
        if (params.row.status) {
          const statusName = LeaveStatus.find(
            (item) => item.key == params.row.status
          );
          return (
            <span
              title={statusName?.value}
              className={`py-1 px-2 ms-0 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
            >
              {statusName?.value}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.LEAVES
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.LEAVES
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.LEAVES
    );

    if (
      checkEditAbilityCondition ||
      checkDeleteAbilityCondition ||
      checkViewAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 0.5,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                onClick={(event) => handleViewClick(id)}
                showInMenu
                icon={<ViewIcon />}
              />
            );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="View"
              onClick={(event) => handleViewClick(id)}
              showInMenu={false}
              className="text-primary"
              icon={<ViewIcon />}
            />,
            // <GridActionsCellItem
            //   label="No Action"
            //   icon={<BlockIcon />}
            //   showInMenu
            //   // You can handle the click event if needed
            //   onClick={() => {}}
            // />,
          ];
        },
      });
    }
  };
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  handleEditViewActionPermission();
  
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="d-flex justify-content-between align-items-center">
              <Col className="col-md-6">
                <h1 className="fw-bold h4 my-2">Leaves</h1>
              </Col>
              <Col className="align-items-start col d-flex justify-content-end text-end">
                <InputDatePicker
                  name="selectedYear"
                  className="w-200"
                  label=""
                  slots={{ openPickerIcon: ExpandMoreIcon }}
                  slotProps={{
                    textField: {
                      clearable: true,
                      size: "small",
                      placeholder: "Select Year",
                      readOnly: true,
                      className: "form-control",
                      id: "selectedYear",
                    },
                  }}
                  maxDate={moment().add('1','year')}
                  value={selectedYear}
                  view={["year"]}
                  format="YYYY"
                  onChange={(name, newValue) => {
                    setSelectedYear(newValue);
                    setIsMutation(!isMutation);
                  }}
                />
                <Button
                  className="fw-semibold h-100 fs-12 ms-2 text-white "
                  variant="primary"
                  type="button"
                  onClick={() => handleApplyLeave()}
                >
                  <LogoutIcon className="me-2" />
                  Apply Leave
                </Button>
              </Col>
            </Row>
          </div>
          
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <div className="">
              {leaveHistroyData && leaveAvailableData && (
                <LeaveHistoryData
                  leaveHistroyData={leaveHistroyData}
                  leaveAvailableData={leaveAvailableData}
                />
              )}
            </div>
            <Card className="border-0 w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  pageSizeOptions={pageSizeModel}
                  sortingMode="server"
                  disableRowSelectionOnClick
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeeLeaveHistoryList;
