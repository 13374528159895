import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";

import Loader from "../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import {
  downloadFormTemplate,
  getLetterTemplateById,
} from "../../services/letterTemplateService";


export default function ViewLetterTemplate() {
  const [loading, setLoading] = useState(true);
  const [letterTemplate, setLetterTemplate] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams<string>();

  /**
   * Get existing letter template from server
   * @async
   * @param {id} ID of template
   */
  const getLetterTemplate = async (id: string) => {
    setLoading(true);
    await getLetterTemplateById(id)
      .then((response: any) => {
        setLetterTemplate(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  /**
   * Get Form Template Signed URL from s3
   * @async
   * @param {id} ID of template
   */
  const downloadFormTemplateHandler = async () => {
    setLoading(true);
    await downloadFormTemplate(id).then((response:any) => {
        const signedFileUrl = response.file;
        window.open(signedFileUrl, '_blank');
    }).catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      }).finally(() => {
        setLoading(false);
      });
  }

  /**
   * Cancel handler - redirection to listing
   * @returns {*}
   */
  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if(id){
        getLetterTemplate(id);
    }
    else{
        navigate(-1);
    }
  }, []);

  const sanitizeHTML = (html: string) => {
    return { __html: html };
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">View Template</h1>
              </Col>
            </Row>
          </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
                <Card className="p-3 w-100 pageContentInner">
                <div>
                    <Row>
                      <div className="col-md-4 col-xl-3">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                            Title
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{ letterTemplate.title }</p>
                          </div>
                        </div>
                          
                      </div>
                      <div className="col-md-4 col-xl-3">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Type
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{ letterTemplate.type }</p>
                          </div>
                        </div>
                          
                      </div>
                    </Row>
                    
                    <Row>
                      <div className="col-md-12 col-xl-12">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Description
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{ letterTemplate.description }</p>
                          </div>
                        </div>
                          
                      </div>
                      
                    </Row>
                    {letterTemplate.type === 'Email Template' && (<><Row>
                    <div className="col-md-4 col-xl-3">
                        
                        <label className="lh-sm control-label fs-14 fw-bold form-label">
                        Email Subject
                        </label>
                        <div className="position-relative ">
                            <p className="fs-14"> { letterTemplate.email_subject }</p>
                        </div>
                       
                    </div>
                    
                    </Row>
                    <Row>
                    <div className="col-md-12 col-xl-12">
                      <label className="lh-sm control-label fs-14 fw-bold form-label">Email Subject</label>
                      <div className="position-relative ql-editor-box ql-editor p-20 w-100 br-10">
                            <p className="fs-14"><div dangerouslySetInnerHTML={sanitizeHTML(letterTemplate.email_body)} /></p>
                      </div>
                    </div>
                    
                    </Row></>
                    )}
                    {letterTemplate.type === 'Form Template' && <Row>
                    <div className="col-md-4 col-xl-3">
                        <button className="view-template-btn" onClick={downloadFormTemplateHandler}>
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="22" height="22" rx="4" fill="#297FCE" fill-opacity="0.15"/>
                          <mask id="mask0_325_15782"  maskUnits="userSpaceOnUse" x="5" y="5" width="12" height="12">
                          <rect x="5" y="5" width="12" height="12" fill="#D9D9D9"/>
                          </mask>
                          <g mask="url(#mask0_325_15782)">
                          <path d="M7.5 16.1358C7.225 16.1358 6.98958 16.0353 6.79375 15.8341C6.59792 15.633 6.5 15.3912 6.5 15.1088V7.91961C6.5 7.63717 6.59792 7.39539 6.79375 7.19427C6.98958 6.99314 7.225 6.89258 7.5 6.89258H14.5C14.775 6.89258 15.0104 6.99314 15.2063 7.19427C15.4021 7.39539 15.5 7.63717 15.5 7.91961V15.1088C15.5 15.3912 15.4021 15.633 15.2063 15.8341C15.0104 16.0353 14.775 16.1358 14.5 16.1358H7.5ZM8.5 14.0818H12V13.0547H8.5V14.0818ZM8.5 12.0277H13.5V11.0007H8.5V12.0277ZM8.5 9.97366H13.5V8.94663H8.5V9.97366Z" fill="#297FCE"/>
                          </g>
                          </svg>
                          <span className="me-3 ms-2">{letterTemplate.file_original_name}</span>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="mask0_325_15774"  maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                          <rect width="16" height="16" fill="#D9D9D9"/>
                          </mask>
                          <g mask="url(#mask0_325_15774)">
                          <path d="M8.00033 10.666L4.66699 7.33268L5.60033 6.36602L7.33366 8.09935V2.66602H8.66699V8.09935L10.4003 6.36602L11.3337 7.33268L8.00033 10.666ZM4.00033 13.3327C3.63366 13.3327 3.31977 13.2021 3.05866 12.941C2.79755 12.6799 2.66699 12.366 2.66699 11.9993V9.99935H4.00033V11.9993H12.0003V9.99935H13.3337V11.9993C13.3337 12.366 13.2031 12.6799 12.942 12.941C12.6809 13.2021 12.367 13.3327 12.0003 13.3327H4.00033Z" fill="#21AC59"/>
                          </g>
                          </svg>

                        </button>
                    </div>
                    </Row>}
                </div>
                </Card>
            </div>
        </div>
      )}
    </React.Fragment>
  );
}
