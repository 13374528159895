import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getDayChecklistApi } from "../../../services/task.service";
import Loader from "../../../components/common/Loader";
import CheckListTemplate from "./CheckListTemplate";
import { getProjectPaymentMilestoneAPi } from "../../../services/project.service";
import { Card, Col, Form, Row } from "react-bootstrap";
import { getLocalDateFormat } from "../../../utility/common";
import moment from "moment";
import InputRadio from "../../../components/common/InputRadio";
import { Formik } from "formik";
import InputDatePicker from "../../../components/common/InputDatePicker";
import InputTextArea from "../../../components/common/InputTextArea";
import CustomButton from "../../../components/common/CustomButton";
import * as yup from "yup";
import InfoIcon from '@mui/icons-material/Info';
import {
  MAXIMUM_255_CHAR,
  MINIMUM_3_CHAR,
} from "../../../validations/ValidationErrors";
import constants from "../../../constants/constants";
import { toast } from "react-toastify";
import {
  getInstallationInterimPaymentApi,
  saveInstallationInterimPaymentApi,
} from "../../../services/installation.service";

interface IInterimPaymentCheck {  
  projectId: string | number | undefined;
  setMutate : () => void;
}

const addSchema = yup.object({
  installation_continue_status: yup
    .string()
    .required("Please select an option"),
  installation_continue_reason: yup
    .string()
    .when("installation_continue_status", {
      is: (status: string) => status && status === "1",
      then: (schema) =>
        schema
          .required()
          .min(3, MINIMUM_3_CHAR)
          .max(255, MAXIMUM_255_CHAR)
          .label("Reason"),
      otherwise: (schema) =>
        schema
          .optional()
          .min(3, MINIMUM_3_CHAR)
          .max(255, MAXIMUM_255_CHAR)
          .label("Reason"),
    }),
  due_date: yup.date().when("installation_continue_status", {
    is: (status: string) => status && status === "1",
    then: (schema) => schema.required().label("Due Date"),
    otherwise: (schema) => schema.optional().nullable().label("Due Date"),
  }),
});

const InterimPaymentCheck: React.FC<IInterimPaymentCheck> = ({  
  projectId,
  setMutate
}) => {
  const [data, setPaymentData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getInstallationInterimPaymentApi(projectId)
      .then((response) => {
        let paymentMilestoneData = response.data;
        console.log(paymentMilestoneData);
        setPaymentData(paymentMilestoneData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const submitForm = async (values: any, { setSubmitting }: any) => {
    const formData = {
      ...values,
      due_date:
        values.installation_continue_status === "1"
          ? moment(values.due_date).format(constants.dateFormats.databaseFormat)
          : moment(data.due_date).format(constants.dateFormats.databaseFormat),
      installation_continue_status:
        values.installation_continue_status === "1" ? true : false,
      installation_continue_reason:
        values.installation_continue_status === "1"
          ? values.installation_continue_reason
          : "",
    };

   // console.log(formData);
    //  return;
    setBtnLoading(true);
    if (projectId) {
      saveInstallationInterimPaymentApi(projectId, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          fetchData();
          setMutate();
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const initialValues = {
    installation_continue_status:
      typeof data?.installation_continue_status == "boolean"
        ? data.installation_continue_status
          ? "1"
          : "0"
        : null,
    due_date: data?.due_date ? moment(data.due_date) : null,
    installation_continue_reason: data?.installation_continue_reason ?? "",
  };

  return (
    <div>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              submitForm,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between align-items-center">
                  <Row>
                    <Col className="fw-bold">Interim Payment</Col>
                  </Row>
                </div>
                <div>
                  {data ? (
                    <>
                      <Row className="mt-3">
                        <Col className="col-md-3">
                          <div
                            className={`card p-4 mb-3 text-white asset-card asset-card-price`}
                          >
                            <div className="fs-16">Amount</div>
                            <div className="fs-25 fw-bold">{data?.amount}</div>
                          </div>
                        </Col>
                        <Col className="col-md-3">
                          {data?.received_date || Number(data?.amount) == 0 ? (
                            <div
                              className={`card p-4 mb-3 text-white asset-card asset-card-open-ticket`}
                            >
                              <div className="fs-16">Received Date</div>
                              <div className="fs-25 fw-bold">
                                {getLocalDateFormat(
                                  (Number(data?.amount) > 0 ? data?.received_date : data?.due_date) ?? moment()
                                )}
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`card p-4 mb-3 text-white asset-card asset-card-overdue-ticket`}
                            >
                              <div className="fs-16">Due Date</div>
                              <div className="fs-25 fw-bold">
                                {getLocalDateFormat(data?.due_date ?? moment())}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                      {!data?.received_date && Number(data?.amount) > 0 && (
                        <>
                          <Row>
                            <Col>
                              <Card className="p-3 w-100 pageContentInner mb-3 install-detail-card">
                                <Row className="form-hrmc-statment">
                                  <Col md={8} className="text-danger fw-bold ps-0 fs-italic">
                                    <InfoIcon className="fs-14 me-2"/>
                                    Note : We have not received the interim
                                    payment for the project. Should we still
                                    proceed?{" "}
                                  </Col>
                                  <Col md={4} className="text-end payment-yes-no pe-0">
                                    <InputRadio
                                      controlId="installation_continue_status"
                                      placeholder=""
                                      label=""
                                      name="installation_continue_status"
                                      data={[
                                        { key: "1", name: "Yes" },
                                        { key: "0", name: "No" },
                                      ]}
                                      handleChange={(event: any) => {
                                        setFieldValue(
                                          "installation_continue_status",
                                          event.target.value
                                        );
                                      }}
                                      value={
                                        values.installation_continue_status
                                      }
                                      errorsField={
                                        errors.installation_continue_status
                                      }
                                      touched={
                                        touched.installation_continue_status
                                      }
                                    />
                                  </Col>
                                </Row>
                                {values.installation_continue_status == "1" && (
                                  <>
                                    <Row className="pt-3">
                                      <Col md={4}>
                                        <InputDatePicker
                                          name="due_date"
                                          label="Next Due Date"
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              placeholder: "Select",
                                              className: "form-control",
                                              id: "due_date",
                                            },
                                          }}
                                          minDate={moment(values.due_date)}
                                          value={values.due_date}
                                          onChange={(name, newValue) =>
                                            setFieldValue(name, newValue)
                                          }
                                          touched={touched.due_date}
                                          errors={errors.due_date}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="pt-3">
                                      <Col md={10}>
                                        <InputTextArea
                                          controlId="installation_continue_reason"
                                          label="Reason"
                                          placeholder="Enter Reason"
                                          touched={
                                            touched.installation_continue_reason
                                          }
                                          handleBlur={handleBlur}
                                          handleChange={handleChange}
                                          errorsField={
                                            errors.installation_continue_reason
                                          }
                                          value={
                                            values.installation_continue_reason
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end mb-2">
                                <CustomButton
                                  type="submit"
                                  loading={btnLoading}
                                  disabled={btnLoading}
                                  className="fw-semibold fs-13 text-white mw-65 mt-2"
                                  variant="primary"
                                >
                                  Approve
                                </CustomButton>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="text-center">No Data Found</div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default InterimPaymentCheck;
