import React, { useState } from "react";
import { FC } from "react";
import { Card, Col, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import { ITypeProps } from "./surveyInterface";
import CommonNotes from "./commonNotes";
import Loader from "../../../components/common/Loader";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ViewIcon from "@mui/icons-material/Visibility";

import {
  FILE_LIMIT_10,
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
} from "../../../validations/ValidationErrors";
import { toast } from "react-toastify";
import { uploadImagesApi } from "../../../services/survey/survey.service";
import ImagesPreview from "./imagesPreview";
import { getIn } from "formik";

const TypeMediaUpload: FC<ITypeProps> = ({
  field,
  index,
  fieldIndex,
  subIndex,
  templateData,
  setTemplateData,
  errors,
  values,
  setFieldValue,
  handleBlur,
  touched,
  survey_id,
  setFieldError
}) => {
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageSet, setImageSet] = useState<any>([]);
  const handleViewImages = (imageSet: any) => {
    setImageSet(imageSet);
    setShowImageModal(true);
  };
  const hideImageModel = () => {
    setShowImageModal(false);
    setImageSet([]);
  };
  

  const mediaUploadHandler = (
    e: any,
    index: number,
    subIndex: number,
    fieldIndex: number
   ) => {
    //set a validation for all the files shoulde not more then 10 and also size should not more then 1 MB and file type only ".jpeg, .jpg, .png, .svg , .gif"
    const files = e.target.files;
    if (files.length > 10) {
      toast.error(FILE_LIMIT_10);
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].size >= 5000000) {
        toast.error(FILE_SIZE_ERROR);
        return;
      }
    }

    // validate file formate
    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
    ];
    for (let i = 0; i < files.length; i++) {
      console.log("files[i].type", files[i].type);
      if (!allowedFileTypes.includes(files[i].type)) {
        toast.error(FILE_TYPE_ERROR);
        return;
      }
    }
    const form_data = new FormData();
    for (let i = 0; i < files.length; i++) {
      form_data.append("image", files[i]);
    }
    //add survey_id in the form data
    form_data.append("survey_id", survey_id);
    setFileUploadLoading(true);
    uploadImagesApi(form_data)
      .then((response: any) => {
        console.log("response", response.data);
        let fieldSheetTextTemp = [...templateData];
        fieldSheetTextTemp[index].subsections[subIndex].fields[
          fieldIndex
        ].value =  'Media Uploaded Successfully'


        const imageSet =
          fieldSheetTextTemp[index].subsections[subIndex].fields[fieldIndex]
            ?.image_set;
        fieldSheetTextTemp[index].subsections[subIndex].fields[
          fieldIndex
        ].image_set = imageSet
          ? [...imageSet, ...response.data]
          : response.data;

          
        //add value in the fieldSheetTextTemp

      
         
        setTemplateData(fieldSheetTextTemp);
        setFileUploadLoading(false);
      })
      .catch((error: any) => {
        toast.error(error.response.data.message);
        setFileUploadLoading(false);
      });
  };

  return (
    <div>
      {fileUploadLoading && <Loader isLoading={fileUploadLoading} />}
      {showImageModal && (
        <ImagesPreview
          show={showImageModal}
          onHide={hideImageModel}
          images={imageSet}
        />
      )}
      <Card className="p-3 w-100 pageContentInner mb-3">
        <div className="d-flex flex-column h-100 rounded-0 ">
          <Row className="d-flex">
            <Col className="col-md-11 d-flex pe-0 justify-content-between align-items-center">
              <p className="fs-14 mb-2">
                {field.title} {field.require && "*"}
              </p>
              
              <div className="d-flex align-items-center">
                 
                <label className="browse-cover ms-2 survey-browse">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      mediaUploadHandler(e, index, subIndex, fieldIndex);
                    }}
                    multiple
                  />
                  <span className="btn btn-outline-info fw-bold fs-14 text-no-wrap">
                    <FileUploadIcon />{" "}
                    {field.data_set[0]?.button_title
                      ? field.data_set[0]?.button_title
                      : " Add Media"}
                  </span>
                </label>

              
              </div>
            </Col>
            <Col className="col-md-1 text-end">
              <CommonNotes
                field={field}
                index={index}
                fieldIndex={fieldIndex}
                subIndex={subIndex}
                touched={touched}
                errors={errors}
                values={values}
                templateData={templateData}
                setTemplateData={setTemplateData}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </Col>
            {field.image_set && field.image_set.length > 0 && (
              
                                  <div
                    title="Preview Images"
                    className="cursor-pointer ms-2 text-primary"
                    onClick={() => handleViewImages(field.image_set)}
                  >
                   Preview Image
                  </div>
                   
                )}

               
                {getIn(errors, field.name) && getIn(touched, field.name) ? (
                  <div className="text-danger">{getIn(errors, field.name)}</div>
                ) : null}
                
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default TypeMediaUpload;
