import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,  
  VALID_EMAIL,
  MAXIMUM_500_CHAR,
  INVALID_DATE,
} from "../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../validations/regex";

export const ticketSchema = yup.object({
  title: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Subject"),
  description: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .label("Description"),  
  assigned_to: yup
    .object()
    .required()    
    .label("Assignee"),
  due_date: yup
    .date()
    .required()    
    .typeError(INVALID_DATE)
    .label("Due Date"),
  department: yup
    .object()
    .required()    
    .label("Department"),
  filename: yup
    .string()    
    .optional()    
    .label("Attachment"),  
});
