import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import constants, { DpStatus } from "../../../../constants/constants";
import Loader from "../../../../components/common/Loader";
import {
  getDispatchOrderById,
  returnDispatchItemToStockApi,
} from "../../../../services/warehouseService";
import moment from "moment";
import AddPhotoAlternate from "../../../../assets/images/add_photo_alternate.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PrintCommapnyHeader from "../../../../components/common/PrintHeader";
import CustomButton from "../../../../components/common/CustomButton";
import PrintIcon from "@mui/icons-material/Print";

export const ViewDispatchOrder = () => {
  const [loading, setLoading] = useState(true);
  const [dpData, setDpData] = useState<any>({});
  const [dpProductData, setDpProductData] = useState<any>([]);
  const navigate = useNavigate();
  let { id } = useParams();

  const getDpDetail = (id: any) => {
    setLoading(true);
    getDispatchOrderById(id)
      .then((response) => {
        const dpDataRes = response.data;
        dpDataRes.status_arr = DpStatus.find(
          (item) => item.key == dpDataRes.status
        );
        dpDataRes.dispatchProducts = dpDataRes.dispatchProducts.map(
          (item: { dispatchReceivedProducts: { qty_returned: number } }) => ({
            ...item,
            status: item.dispatchReceivedProducts
              ? item.dispatchReceivedProducts.qty_returned > 0
              : null,
          })
        );

        // console.log(dpDataRes,DpStatus)
        //  setDpProductData(result)
        setDpData(dpDataRes);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/good-inward");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/good-inward");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/good-inward");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getDpDetail(id);
    } else {
      navigate("/purchase-orders");
    }
  }, [id]);

  const handleAddQtyToStock = (productId: any) => {
    setLoading(true);
    returnDispatchItemToStockApi(productId)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        getDpDetail(id);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          // navigate("/good-inward");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          // navigate("/good-inward");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          // navigate("/good-inward");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateProductShowStatus = (productId: any, status = false) => {
    setDpData((prevState: { dispatchProducts: any[] }) => {
      const updatedDispatchProducts = prevState.dispatchProducts.map(
        (item: { id: any }) => {
          if (item.id === productId) {
            return { ...item, status };
          }
          return item;
        }
      );

      return {
        ...prevState,
        dispatchProducts: updatedDispatchProducts,
      };
    });
  };
  const handlePrint = () => {
    window.print();
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <PrintCommapnyHeader className="d-none d-print-flex" />
            <Row className="align-items-center d-flex justify-content-between">
              <Col>
                <h1 className="fw-bold h4 my-2">View Dispatch Order</h1>
              </Col>
              <Col className="d-print-none text-end">
                <CustomButton
                  type="button"
                  className="fw-semibold fs-13 text-white mt-2 ms-2"
                  variant="info"
                  onClick={() => handlePrint()}
                >
                  <PrintIcon className="fs-16" />
                </CustomButton>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="p-3 w-100 pageContentInner mb-3">
              <Row className="purchase-top-form">
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold ">
                    Dispatch Number
                  </label>
                  <div className="fs-14 mb-3">{dpData.dispatch_number_str}</div>
                </Col>

                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">
                    Project Order Number
                  </label>
                  <div className="fs-14 mb-3">
                    {dpData?.contract_id ? (
                      <Link
                        target="_blank"
                        className="blue-text text-decoration-none fw-semibold"
                        to={`/projects/view/${dpData?.contract_id}`}
                      >
                        {" "}
                        {dpData?.project?.project_no}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">
                    Ref. Number
                  </label>
                  <div className="fs-14 mb-3">
                    {dpData.ref_number ? dpData.ref_number : "-"}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">
                    Purchase Order Date{" "}
                  </label>
                  <div className="fs-14 mb-3">
                    {moment(dpData?.project?.date).format(
                      constants.dateFormats.slashSeparatedDate
                    )}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">
                    Current Status
                  </label>
                  <div className="fs-14 mb-3">{dpData?.status_arr?.value}</div>
                </Col>
              </Row>
              <Row className="purchase-top-form">
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">
                    Dispatch Date
                  </label>
                  <div className="fs-14 mb-3">
                    {dpData.dispatched_date
                      ? moment(dpData.dispatched_date).format(
                          constants.dateFormats.slashSeparatedDate
                        )
                      : "-"}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">
                    Received Date
                  </label>
                  <div className="fs-14 mb-3">
                    {dpData?.received_date
                      ? moment(dpData?.received_date).format(
                          constants.dateFormats.slashSeparatedDate
                        )
                      : "-"}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">Driver</label>
                  <div className="fs-14 mb-3">{dpData?.driver?.name}</div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">
                    Customer
                  </label>
                  <div className="fs-14 mb-3">{dpData?.project?.customer_name}</div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">
                    Installer
                  </label>
                  <div className="fs-14 mb-3">
                    {dpData?.installer?.user?.full_name} 
                    <p>{dpData?.installer?.user?.phone_no}</p>
                  </div>
                </Col>
              </Row>
              <Row className="purchase-top-form">
                <Col className="col-md-12">
                  <label className="control-label fs-14 fw-bold">
                    Site Address
                  </label>
                  <div className="fs-14 mb-3">
                    {dpData?.address} this is one ont the geirfnsd fjdsakf hsf
                    hjf jsadf jdfkdsfj fhag{" "}
                  </div>
                </Col>
              </Row>
            </Card>

            <Row className="purchase-top-form">
              <Col className="col-md-6">
                <Card className="p-3 w-100 pageContentInner mb-3">
                  <Row>
                  <table className="table">
  <thead>
    <tr>
      <th className="fs-18 fw-bold info-text mt-2" colSpan={4}>
        Product
      </th>
    </tr>
    <tr>
      <th className="fw-bold">Name</th>
      <th className="fw-bold text-nowrap">Qty.</th>
      <th className="fw-bold d-print-none">Return Qty.</th>
      <th className="fw-bold d-print-none"></th>
    </tr>
  </thead>
  <tbody>
    {dpData.dispatchProducts &&
      dpData.dispatchProducts.map((productItem: any, index: any) => (
        <React.Fragment key={`productlist_${index}`}>
          <tr className="border-bottom mb-2 py-2">
            <td>{productItem?.product?.name}</td>
            <td>{productItem?.qty}</td>
            <td className="d-print-none">
              {productItem?.dispatchReceivedProducts?.qty_returned ?? 0}
            </td>
            <td className="d-print-none">
              {productItem?.dispatchReceivedProducts?.qty_returned > 0 && (
                <span className="ms-2">
                  {productItem?.status ? (
                    <Button
                      type="button"
                      className="rounded-5 btn btn-primary text-white w-32 h-32 p-0"
                      onClick={() => updateProductShowStatus(productItem.id, false)}
                    >
                      <ExpandLessIcon />
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      className="rounded-5 btn btn-secondary text-white w-32 h-32 p-0 arrow-gray"
                      onClick={() => updateProductShowStatus(productItem.id, true)}
                    >
                      <ExpandMoreIcon />
                    </Button>
                  )}
                </span>
              )}
            </td>
          </tr>
          {!productItem.status && productItem?.dispatchReceivedProducts?.qty_returned > 0 && (
            <tr className="d-print-none">
              <td colSpan={4}>
                <div className="bg-body-secondary p-3 rounded">
                  <label className="control-label fs-14 fw-bold w-100">
                    Return Reason
                  </label>
                  <span>
                    {productItem?.dispatchReceivedProducts?.return_remarks}
                  </span>
                </div>
                <h3 className="fs-18 fw-bold info-text mt-2">
                  Products Photos
                </h3>
                <div className="d-flex good-inward-box-cover">
                   {productItem && productItem?.dispatchReceivedProducts && productItem?.dispatchReceivedProducts?.imagesSigned &&  productItem?.dispatchReceivedProducts?.imagesSigned.length  && productItem?.dispatchReceivedProducts?.imagesSigned.map(
                    (imagePath: any, imageIndex: any) => (
                      <div key={`returnImage_${index}_${imageIndex}`}>
                        <label className="file-box good-inward-box-cover me-3">
                          <span>
                            <Image
                              className="img-fluid"
                              src={imagePath ? imagePath : AddPhotoAlternate}
                              alt="Logo"
                            />
                          </span>
                        </label>
                      </div>
                    )
                  )}
                </div>
                {!productItem?.dispatchReceivedProducts?.is_return_approved ? (
                  <div>
                    <div className="fw-bold">
                      On clicking this button, all returned quantities will be
                      approved and added back to the stock.
                    </div>
                    <Button
                      type="button"
                      disabled={loading}
                      className="btn btn-primary text-white mt-2 float-end"
                      onClick={() => handleAddQtyToStock(productItem.id)}
                    >
                      Approve Return
                    </Button>
                  </div>
                ) : (
                  <span className="info-tag float-end py-1 px-2 ms-2 rounded-1 d-inline-block fs-14 vertical-middle">
                    Added To Stock
                  </span>
                )}
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
  </tbody>
</table>

                  </Row>
                </Card>
              </Col>
              <Col className="col-md-6">
                <Card className="p-3 w-100 pageContentInner mb-3">
                  <Row>
                    <Col className="col-md-12">
                      <h3 className="fs-18 fw-bold info-text mt-2">
                        Products Photos
                      </h3>
                    </Col>
                    <Col className="col-md-12 d-flex good-inward-box-cover">
                      {dpData.imagesSigned
                        ? dpData.imagesSigned.map(
                            (imagePath: any, index: any) => (
                              <div key={`images${index}`}>
                                <label className="file-box me-3">
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={imagePath}
                                      alt="Logo"
                                    />
                                  </span>
                                </label>
                              </div>
                            )
                          )
                        : "Photos not available"}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Card className="p-3 w-100 pageContentInner mb-3">
              <Row>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">Remarks</label>
                  <div className="fs-14 mb-3">
                    {dpData?.remarks ? dpData?.remarks : "-"}
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
