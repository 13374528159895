import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import moreIcon from "../../../../assets/images/more_horiz.svg";
import { toast } from "react-toastify";
import { getContractPdfAndSurveyForm } from "../../../services/survey/survey.service";
import Loader from "../../../components/common/Loader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getContractFile, getProjectPaymentMilestoneAPi, uploadContractRevised } from "../../../services/project.service";
import Dropzone from "react-dropzone";
import { EmployeeDocumentTypeForDropZone } from "../../../constants/constants";
import EditProjectPaymentMilestone from "../../Finance/Projects/View/paymentMilestone";

const RevisedSurvey: FC<any> = ({  
  contract_id,
  setIsMutation
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [pdfloader, setPdfloader] = useState(false);
  const [paymentData, setPaymentData] = useState<any>({});
  const [milestonePopup, setMilestonePopup] = useState(false);

  useEffect(() => {
    fetchData();
    fetchPaymentMilestoneDate();
  }, []);

  const fetchData = () => {
    getContractFile(contract_id).then((res) => {
        setLoading(false);
        setData(res.data);
        }).catch((error) => {
         setLoading(false);
    }
    );
   };

  const markReviewComparativePage = () => {
     if(contract_id){
     //check

     }
  }

  const handleFileUpload = (event: any) => {
     const files = event[0];
     console.log("files",files)
    
    //only pdf allow 
    if (files.type !== "application/pdf") {
      toast.error("Only PDF files are allowed");
      return;
    }
    //file size should not be more then 5MB
    if (files.size > 5000000) {
      toast.error("File size should not be more than 5MB");
      return;
    }
    const imageFile = files
    const formData = new FormData();
    formData.append("file", imageFile);
    setPdfloader(true);

    uploadContractRevised(contract_id,formData).then((response)=>{
      console.log("response",response);
        setPdfloader(false);
        toast.success(response.msg);
        setIsMutation(true);
         
      }).catch((error)=>{
        console.log("error",error);
        toast.error(error.response.data.msg);
        setPdfloader(false);

      })


  }

  const fetchPaymentMilestoneDate = () => {
    return new Promise((resolve, reject) => {
      getProjectPaymentMilestoneAPi(contract_id)
        .then((response) => {
          let paymentMilestoneData = response.data;
          // if(viewType == 'F'){
          //   paymentMilestoneData = paymentMilestoneData.filter((obj: { key: string; isFileExists: boolean; }) => !(obj.key === "other" && obj.isFileExists === false));
          // }
          setPaymentData(paymentMilestoneData);
           
          resolve(true);
        })
        .catch((error) => {
          
          reject(error);
        });
    });
  };
  const toggleMilestonePopup = (event?: any) => {
     
    if (event) {
      event.stopPropagation();
    }
    setMilestonePopup(!milestonePopup);
  };
  const handleMilestoneClosePopup = (isReload?: boolean) => {
    if (isReload) {
      fetchPaymentMilestoneDate();
    }
    setMilestonePopup(false);
     
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        
        <div className="">
                      <div className="align-items-center d-flex justify-content-between mb-3">
                          <div className="fs-24 fw-bold info-text">Revise Contract</div>
                         
                      </div>
                      <Row>
                        <Col md={6}>
                          <Card className="p-3 w-100 pageContentInner mb-3">
                            <h3 className="fs-18 info-text fw-bold d-inline-block mb-3">Upload Revise Contract*</h3>
                            <div className="w-100">
                            <div className="drag-drop">
                              <Dropzone multiple={false} accept={EmployeeDocumentTypeForDropZone}
                                onDrop={(e) =>
                                  handleFileUpload(e)
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {/* loader */}
                                    {pdfloader ?   <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> : 
                                   <><div className="upload-image">
                                      <svg
                                        width="33"
                                        height="25"
                                        viewBox="0 0 33 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.2222 24.1666H8.65975C6.44794 24.1666 4.55819 23.401 2.99048 21.8697C1.42277 20.3385 0.638916 18.4669 0.638916 16.2551C0.638916 14.3593 1.2101 12.6701 2.35246 11.1874C3.49482 9.70478 4.98961 8.75686 6.83683 8.34367C7.44447 6.10756 8.65975 4.29679 10.4827 2.91138C12.3056 1.52596 14.3716 0.833252 16.6806 0.833252C19.5243 0.833252 21.9367 1.8237 23.9176 3.80461C25.8985 5.78551 26.8889 8.19784 26.8889 11.0416C28.566 11.236 29.9575 11.9591 31.0634 13.2109C32.1693 14.4626 32.7222 15.927 32.7222 17.6041C32.7222 19.427 32.0842 20.9765 30.8082 22.2525C29.5321 23.5286 27.9827 24.1666 26.1597 24.1666H18.1389V13.7395L20.4722 15.9999L22.5139 13.9583L16.6806 8.12492L10.8472 13.9583L12.8889 15.9999L15.2222 13.7395V24.1666Z"
                                          fill="#889EAE"
                                        />
                                      </svg>
                                    </div>
                                    <div className="drag-text">
                                      Drag & drop the document here or
                                    </div>                                    
                                    <div className="upload-text">
                                      Upload Contract Document
                                    </div>
                                    </>
                                }
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                            </div>
                          </Card>
                          <div className="d-flex justify-content-between">
                            <div className="fs-14 text-black">Update Payment Milestone according to New Contract</div>
                            <div className=""><a href="javascript:void(0)" className="link-text text-decoration-none fw-bold" onClick={toggleMilestonePopup} >Update Milestone</a></div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <Card className="p-3 w-100 pageContentInner mb-3">
                            <div className="d-flex justify-content-between">
                              <h3 className="fs-18 info-text fw-bold d-inline-block mb-3">Preview</h3>
                             
                            </div>
                            <div className="border rounded-1">
                            {data.contract_file ? (
                    <object
                      data={`${data?.contract_file}#toolbar=0`}
                      width="100%"
                      height="500"
                    ></object>
                  ) : (
                    <h3>No Contract PDF Available</h3>
                  )}
                            </div>
                           
                           
                          </Card>
                         
                        </Col>
                      </Row>
                     
                </div>
      )}
      {milestonePopup && (
            <EditProjectPaymentMilestone
              isOpen={milestonePopup}
              projectId={contract_id}
              paymentData={paymentData}
              onClose={handleMilestoneClosePopup}
              label="Edit Payment Milestone"
              text="Edit Payment Milestone"
            />
          )}
    </>
  );
};

export default RevisedSurvey;
