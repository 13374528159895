import React,{FC} from "react";
import {  useSelector } from 'react-redux';
import { selectAuthentication } from "../../features/Auth/authSlice";
import { SUPER_ADMIN } from "../../constants/constants";
import EditProfile from "./EditProfile";
import EditAdminProfile from "./EditAdminProfile";

const ProfileUpdate:FC = () => {
    const {user }:any = useSelector(selectAuthentication);
    return (
        <React.Fragment>
            {user.role.role !== SUPER_ADMIN && <EditProfile />}
            {user.role.role === SUPER_ADMIN && <EditAdminProfile />}
        </React.Fragment>
    )
};

export default ProfileUpdate;