import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import moment from "moment";
import constants, { expensesCategories, expensesStatus } from "../../constants/constants";
import { getExpenseByIdApi } from "../../services/expenseService";
import { currencyMask } from "../../utility/common";
import { Link } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  expensesId: string |number | null | undefined;
  viewType: string;
}

const ViewExpensesPopup: React.FC<AddProps> = ({ isOpen, onClose, expensesId, viewType}) => {  
  const [loading, setLoading] = useState(true);
  const [expenseData, setExpenseData] = useState<any>({}); 

  useEffect(()=>{
    fetchData()
   },[])
 
   const fetchData = () =>{         
      if(expensesId){
        setLoading(true)
        getExpenseByIdApi(expensesId, viewType).then((response)=>{
          const expData = response?.data;
          expData.categoryData = expensesCategories.find(
            (item: { key: string | number | undefined; }) => item.key == expData?.category
          );
          expData.statusData = expensesStatus.find(
            (item: { key: string | number | undefined; }) => item.key == expData?.status
          );
          console.log(expData)
          setExpenseData(response?.data);
          setLoading(false)  
        }).catch((error)=>{
          onClose();
          setLoading(false)
        })
      }
      else{
        setLoading(false)
      }  
   } 

   const renderStatusDiv = ( checkedVal: number | string |undefined) => {
    const statsuArr:any = expensesStatus
    const statusName = statsuArr.find(
      (item: { key: string | number | undefined; }) => item.key == checkedVal
    );
    return <span className={`${statusName?.tagClass} py-1 px-2 ms-2 rounded-1 d-inline-block fs-14 vertical-middle`}>{statusName?.value}</span>
  }

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="export-excel-modal"
          show={isOpen}
          onHide={onClose}
          centered
        >
          <Modal.Body className="position-relative">
            <button
              type="button"
              className="btn-close text-right  btn-option-cover"
              aria-label="Close"
              onClick={onClose}
            ></button>
            <h4 className="fw-bold info-text mb-3">
              View Expense {renderStatusDiv(expenseData?.status)}
            </h4>
            <Row>
              <Col className="col-md-6">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Expense Title :{" "}
                  </label>
                  <p className="fs-14">{expenseData?.title}</p>
                </div>
              </Col>
              <Col className="col-md-6">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Created By :{" "}
                  </label>
                  <p className="fs-14">{expenseData?.user?.full_name}</p>
                </div>
              </Col>
              <Col className="col-md-6">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Category :{" "}
                  </label>
                  <p className="fs-14">
                    {expenseData?.category?.name}{" "}
                    {expenseData?.sub_category_id && (
                      <>
                        ({expenseData?.sub_category?.name}{" "}
                        {viewType == "fm" &&
                          "-" + expenseData?.sub_category?.xero_code}
                        )
                      </>
                    )}
                  </p>
                </div>
              </Col>
              <Col className="col-md-6">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Project :{" "}
                  </label>
                  <p className="fs-14">
                    {expenseData?.contract_id
                      ? expenseData?.project?.project_no
                      : "-"}
                  </p>
                </div>
              </Col>
              {expenseData?.categoryData?.key == 2 && (
                <Col className="col-md-6">
                  <div className="mb-3 custom-from-group">
                    <label className="lh-sm control-label fs-14 fw-bold form-label">
                      Distance :{" "}
                    </label>
                    <p className="fs-14">{expenseData?.qty}</p>
                  </div>
                </Col>
              )}
              <Col className="col-md-6">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Amount :{" "}
                  </label>
                  <p className="fs-14">{currencyMask(expenseData?.amount)}</p>
                </div>
              </Col>
              <Col className="col-md-6">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Vendor :{" "}
                  </label>
                  <p className="fs-14">{expenseData?.vendor_name}</p>
                </div>
              </Col>
              <Col className="col-md-6">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Date :{" "}
                  </label>
                  <p className="fs-14">
                    {expenseData?.date
                      ? moment(expenseData?.date).format(
                          constants.dateFormats.slashSeparatedDate
                        )
                      : "-"}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-md-12">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Notes :{" "}
                  </label>
                  <p className="fs-14">{expenseData?.remarks}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-md-12">
                <div className="mb-3 custom-from-group">
                  <div className="fs-14">
                    {expenseData?.file && (
                      <Link
                        target="_blank"
                        to={expenseData?.fileSignedURL}
                        className="link-text text-decoration-none fw-bold"
                      >
                        <DownloadIcon />
                        Download Invoice Document
                      </Link>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
            <button
              className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
              onClick={onClose}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default ViewExpensesPopup;