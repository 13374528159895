import { Formik } from 'formik';
import { Button, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ResetPasswordSchema } from '../../../validations/Auth';
import { AuthHeader } from '../authHeader/AuthHeader';
import InputText from '../../../components/common/InputText';
import {  useNavigate, useParams } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { FaRegEye } from "react-icons/fa";
import LogoBanner from '../../../assets/images/login-right-banner.png';
import { resetPasswordAPI } from '../../../services/authService';
import { toast } from 'react-toastify';
import { useState } from 'react';
export const CreatePassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { token } = useParams();

  const submitForm = async (password:string) => {
    setLoading(true);

    resetPasswordAPI({ password: password, token: token ? token : "" })
      .then((response) => {
        setLoading(false);
        toast.success(response.message);
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  return (
    <Formik
      validationSchema={ResetPasswordSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        submitForm(values.password);
      }}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <div className='container-fluid'>
            <div className='row bg-white'>
              <div className='col-md-6 col-xl-4'>
                <div className="d-flex  h-100 w-100">
                    <div className="align-content-between d-flex flex-wrap min-vh-100 login-left-mobile mw-350 mx-auto py-4 w-100">
                      <AuthHeader />
                      <div className="d-inline-block mt-5  w-100">
                        <Form className="" noValidate onSubmit={handleSubmit}>
                          <h6 className="fw-bold fs-32  mb-0">Create Password</h6>
                          <p className='fs-13'>Enter new password</p>
                          <InputText
                            controlId="password"
                            label='New Password'
                            placeholder="Enter Password"
                            touched={touched.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.password}
                            value={values.password}
                            
                            //isPasswordHintVisible={true}
                          />
                          <InputText
                            controlId="confirmPassword"
                            label='Confirm Password'
                            type='password'
                            placeholder="Enter Confirm Password"
                            touched={touched.confirmPassword}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.confirmPassword}
                            value={values.confirmPassword}
                           
                            //isPasswordHintVisible={true}
                          />
                          <Stack direction="horizontal" gap={3}>
                            <Button className="fw-semibold fs-13 text-white mw-60 mt-2" variant="primary" type="submit">Submit</Button>
                            
                          </Stack>
                        </Form>
                      </div>
                      <div className='min-h-30 w-100'></div>
                    </div>
                </div>

              </div>
              <div className='col-md-6 col-xl-8 login-right'>
                <div className='left-0 login-right-box lh-38 p-3 px-4 position-absolute text-white top-30 z-2 fw-300 mw-366 fs-28'><strong className='fw-bold'>Enabling & restoring independence</strong> in your own home</div>
                <Image className="img-fluid " src={LogoBanner} alt="Logo Banner"  />
              </div>
          </div>
        </div>
      )}
    </Formik>
  )
}
