import React, { useState } from "react";
import { Card } from "react-bootstrap";
import editIcon from "../../../assets/images/formbuilderedit.svg";
import deleteIcon from "../../../assets/images/formbuilderdelete.svg";
import { deleteFieldApiCall } from "../../../services/formbuilder/section.services";
import { toast } from "react-toastify";
import DeletePopup from "../../../components/common/deletePopup";

export default function FieldContent({
  keyVal,
  val,
  fieldSheetId,
  editFieldModel,
  setIsMutation,
  isMutation,
  conditionData
  
}) {
 const [fieldId,setFieldId]=  useState(null)
 const [deleteFieldPopup,setDeleteFieldPopup ] = useState(false);


 const checkFieldHasCondition = (fieldcondition,fieldId) => {
  const hasCondition = fieldcondition.some(entry => {
    return (
      entry.conditions.some(condition => condition.field.key === fieldId) ||
      entry.actions.some(action => action.field.key === fieldId)
    );
  });
  return hasCondition;
}

  const handleDelete = (id) => {
    let  hasCondition = false;
    if(conditionData && conditionData.field_conditions && conditionData.field_conditions.length > 0){
      hasCondition = checkFieldHasCondition(conditionData.field_conditions,id);
    }

    if(hasCondition){
      toast.error("Field has condition, Please delete the condition first")
      return false;
    }
    setDeleteFieldPopup(true);
    setFieldId(id)  
  };

 

  const handleDeleteField = () => {
   
    deleteFieldApiCall(fieldId).then((response) => {
      toast.success(response.msg)
      setIsMutation(!isMutation)
      setDeleteFieldPopup(false);
       setFieldId(null);
    });
  };

  const handleCloseFieldDelete = () => {
    setDeleteFieldPopup(false);
    setFieldId(null);
  }
  const getTypeName = (type) => { 
    switch(type){
      case "text":
        return "Text Box";
        break;
      case "dropdown":
        return "Dropdown";
        break;
      case "radio_button":
        return "Radio Button";
        break;
      case "message":
        return "Message";
        break;
      case "button":
        return "Media Upload Button";
        break;
      case "slider":
        return "Slider";
        break;
    }
  }
    

  return (
    <>
    {deleteFieldPopup &&  <DeletePopup
                isOpen={deleteFieldPopup}
                onClose={handleCloseFieldDelete}
                onDelete={handleDeleteField}
                label="Delete Field"
                text="you won't be able to revert this!"
              />
              }

             

      <div eventKey={keyVal}>
        {val.fields && val.fields.length > 0 ? (
          <div>
            <div>
              <table className="table custom-table text-left">
                <thead>
                  <tr>                    
                    <th>Title</th>
                    <th>Type</th>
                    <th>Visibile</th>
                    <th>Order</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {val.fields.length > 0 &&
                    val.fields.map((item, srKey) => {
                      return (
                        <tr key={item.id}>
                         <td>
                            {item.title}
                          </td>
                          <td className="text-capitalize">
                          
                           {getTypeName(item.type)}
                          </td>
                          <td className="text-capitalize">
                           {item.visibility?"Visible":"Invisible"}
                          </td>
                          <td>{item.order} </td>                         
                        
                          <td className="action-data"> 
                          <div className="section-action d-flex align-items-center">
                          
                            <ul className="m-0 actions">                              
                                <li title="Edit Field" className="p-0" onClick={() =>editFieldModel(val.id,item.field_id,fieldSheetId,val.fields.length) }> 
                                  <img src={editIcon} />
                                </li>
                                <li title="Delete Field" className="p-0" onClick={() =>
                                   handleDelete(item.field_id ) }>
                                  <img src={deleteIcon} />
                                </li>
                            </ul> 
                            </div>                            
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>No Fields added</div>
        )}
      </div>
    </>
  );
}
