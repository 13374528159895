import { axios } from "./axios";
import { FLEET_ALERTS, FLEET_CREATE, FLEET_DELETE, FLEET_DETAIL, FLEET_DRIVER_GET, FLEET_DRIVER_SAVE, FLEET_LIST, FLEET_MILEAGE_SAVE, FLEET_NOTES, FLEET_NOTES_SAVE, FLEET_UPDATE, FLEET_UPLOAD_FILE } from "../constants/paths";



export const getFleetList = (utype:string | undefined,formData: any): Promise<any> => {
  return axios.get(`${FLEET_LIST}/${utype}`, {

    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};


export const createFleetApi = (data: any): Promise<any> => {
  const transformData = {
    ...data,
    active: data?.active?.key,
    asset_type: data?.asset_type?.key,
    category: data?.category?.key,
    department: data?.department?.key,
    fuel_type: data?.fuel_type?.key,
    type: data?.type?.key,
  };
  
  return axios.post(FLEET_CREATE, transformData);
};

/**
 * @param {id: string } params
 * @returns Fleet details
 */
export const getFleetDetailsApi = (id: string, isDriverUser: any = ""): Promise<any> => {
  return axios.get(FLEET_DETAIL + "/" + id + "/" + isDriverUser);
};

/**
 * @param {id: string } params
 * @returns Fleet details
 */
export const getFleetDriverHistoryApi = (id: string | number | undefined): Promise<any> => {
  return axios.get(FLEET_DRIVER_GET + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateFleetDriverHistoryApi = (id: string | number | null | undefined, data: any): Promise<any> => {
  const transformData = {
    ...data,
    driver_id: data?.driver_id?.id,
   
  };
  return axios.post(FLEET_DRIVER_SAVE + "/" + id, transformData);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateFleetMileageApi = (id: string | number | null | undefined, data: any, isDriverUser: any): Promise<any> => {
  const transformData = {
    ...data   
  };
  return axios.post(FLEET_MILEAGE_SAVE + "/" + id + "/" + isDriverUser, transformData);
};

/**
 * @param {id: string } params
 * @returns Fleet details
 */
export const getFleetAlertsApi = (id: string | number | undefined): Promise<any> => {
  return axios.get(FLEET_ALERTS + "/" + id);
};


/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateFleetApi = (id: string, data: any): Promise<any> => {
  const transformData = {
    ...data,
    active: data?.active?.key,
    asset_type: data?.asset_type?.key,
    category: data?.category?.key,
    department: data?.department?.key,
    fuel_type: data?.fuel_type?.key,
    type: data?.type?.key,  
  };
  return axios.put(FLEET_UPDATE + "/" + id, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteFleetApi = (id: string | number | null): Promise<any> => {
  return axios.delete(FLEET_DELETE + "/" + id);
};

export const uploadFleetImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${FLEET_UPLOAD_FILE}`, data, config);
};


/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const getFleetNotesApi = (id: string | number | undefined): Promise<any> => {
  return axios.get(FLEET_NOTES + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const saveFleetNotesApi = (fleetId: string | number | undefined, data:any): Promise<any> => {
  const transformData = {
    ...data,
  };

  delete transformData.filename;
  
  return axios.post(`${FLEET_NOTES_SAVE}/${fleetId}`, transformData);
};