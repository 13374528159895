import { axios } from "./axios";
import {
  ANNOUNCEMENT_LIST,
  ANNOUNCEMENT_ADD,
  ANNOUNCEMENT_DELETE,
  ANNOUNCEMENT_DETAIL,
  ANNOUNCEMENT_EDIT,
} from "../constants/paths";

export type createAnnouncementDTO = {
  title: string;
  description: string;
};
export const getAnnouncementList = (formData: any): Promise<any> => {
  return axios.get(ANNOUNCEMENT_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const createAnnouncementApi = (data: createAnnouncementDTO): Promise<any> => {
  const transformData = {
    ...data,
  };

  return axios.post(ANNOUNCEMENT_ADD, transformData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getAnnouncementByIdApi = (id: string | number): Promise<any> => {
  return axios.get(ANNOUNCEMENT_DETAIL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateAnnouncementApi = (
  id: string | number | undefined,
  data: createAnnouncementDTO
): Promise<any> => {
  const transformData = {
    ...data,
  };
  return axios.put(ANNOUNCEMENT_EDIT + "/" + id, transformData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteAnnouncementApi = (id: string | number | null): Promise<any> => {
  return axios.delete(ANNOUNCEMENT_DELETE + "/" + id);
};
