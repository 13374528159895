import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,    
  MINIMUM_3_CHAR,
  INVALID_DATE,
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";
export const addSchema = yup.object({
  title: yup
    .string()
    .trim()    
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .required()
    .label("Title"),
  announcement_type: yup.object().required().label("Type"),
  department: yup.object().required().label("Department"),
  description: yup.string().trim().required().label("Description"),
  announcement_date: yup
    .date()
    .nullable()
    .typeError(INVALID_DATE)
    .label("Announcement Date"),
  file: yup.string().optional().label("Announcement File")
});
