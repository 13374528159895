// DeletePopup.tsx
import { FC, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ErrorMessage, FieldArray, Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import constants from "../../../../constants/constants";
import InputText from "../../../../components/common/InputText";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import {
  getOrderNumberListApi
} from "../../../../services/dealService";
import { updatePaymentMilestoneApi } from "../../../../services/project.service";
import InputCurrencyText from "../../../../components/common/InputCurrencyText";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import { currencyMask } from "../../../../utility/common";
import { AddCircle } from "@mui/icons-material";
import DeleteWhiteIcon from "../../../../assets/images/delete-white.svg";
import Loader from "../../../../components/common/Loader";
import paymentMilestoneSchema from "./validation";
import CustomButton from "../../../../components/common/CustomButton";
import DeletePopup from "../../../../components/common/deletePopup";

interface ExportDataPopupProps {
  isOpen: boolean;
  onClose: (isReload?:boolean) => void;
  label: string;
  text: string;
  projectId: string;
  paymentData: any;
}

const EditProjectPaymentMilestone: FC<ExportDataPopupProps> = ({
  isOpen,
  onClose,
  label,
  text,
  projectId,
  paymentData,
}) => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  useEffect(() => {
    if (paymentData && isOpen) {
      // console.log(paymentData);
      if (paymentData.payment_milestone) {
        calculateTotalAmount(paymentData.payment_milestone);
      }
      setLoading(false);
    }
  }, [isOpen]);
  

  // Function to calculate the sum of all amounts
  const calculateTotalAmount = (paymentMilestone: any[], indexNumber?: number, amount?: number | string) => {
    // console.log(paymentMilestone, indexNumber, amount);
    const updatedData = [...paymentMilestone];
    if(typeof indexNumber === 'number' && indexNumber !== undefined){
      updatedData[indexNumber].amount = amount;
    }
    const sum = updatedData.reduce((acc, item) => {     
        // Convert blank or null values to 0 before adding
        const amount = !item.received_date ? parseFloat(item.amount) || 0 : 0;
        return acc + amount;     
    }, 0);
    setTotalAmount(sum);
  };
  
  const handleSubmit = (values: any) => {
    

    let totalMilestoneValue:any = parseFloat(paymentData.paid_amount) || 0;    
    const sum = values.milestones.reduce((acc: number, item: { received_date: any; amount: string; }) => {     
      // Convert blank or null values to 0 before adding
      const amount = parseFloat(item.amount) || 0 ;
      return acc + amount;     
    }, 0);
    totalMilestoneValue += sum;
    if(parseFloat(totalMilestoneValue) > parseFloat(paymentData.amount)){
      // console.log(paymentData.paid_amount, sum, totalMilestoneValue);
      setDeletePopupOpen(true);
      return;
    }
    updatePaymentMilestone(values);
    return;
    
  };

  const updatePaymentMilestone = async (values: any) => {
    setDeletePopupOpen(false);
    const milestones = values.milestones.map(
      (paymentMilestone: { due_date: moment.MomentInput; type: any; }) => {
        const dueDateMoment = moment(paymentMilestone.due_date).format(constants.dateFormats.databaseFormat);

        // Create a new object with the Moment object assigned
        return {
          ...paymentMilestone,
          due_date: dueDateMoment,
          type: paymentMilestone.type.toString()
        };
      }
    )

    const transformedValues = {
      ...values,     
      milestones : milestones 
    };

    setBtnLoading(true);
     updatePaymentMilestoneApi(projectId,transformedValues)
      .then((response) => {
        setBtnLoading(false);
        onClose(true);
      })
      .catch((error) => {
        setBtnLoading(false);
        if (error.response.status == " 404") {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`No records available for export.`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  }

  const initialValues = {    
    milestones: paymentData.payment_milestone.length
      ? paymentData.payment_milestone.map(
          (paymentMilestone: {
            received_date: moment.MomentInput ; due_date: moment.MomentInput 
}) => {
            const dueDateMoment = !paymentMilestone.due_date && paymentMilestone.received_date ? moment(paymentMilestone.received_date) : moment(paymentMilestone.due_date);

            // Create a new object with the Moment object assigned
            return {
              ...paymentMilestone,
              due_date: dueDateMoment,
            };
          }
        )
      : [
        {
          name: "Interim",
          due_date: null,
          amount: "",
          type:1
        },
        {
          name: "Final",
          due_date: null,
          amount: "",
          type:2
        }
      ],
  };

 //console.log(initialValues);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          <Formik validationSchema={paymentMilestoneSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Modal
                className="export-excel-modal"
                show={isOpen}
                onHide={() => onClose()}
                centered
              >
                
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Modal.Body className="position-relative">
                  <button type="button" className="btn-close  btn-option-cover" onClick={() => onClose()} data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="fs-18 fw-bold mb-3 info-text">{label}</div>                 
                    <Row>
                      <Col className="col-md-3">
                        <div className="bg-warning p-2 pt-4 d-block minh-145 w-100 text-white  payment-orange">
                          <p className="mb-2"><WatchLaterIcon className="opacity-25 fs-32"/></p>
                          <span className="fs-16">Pending Amount</span>
                          <div className="fw-bold fs-25">{currencyMask(totalAmount)}</div>
                        </div>
                      </Col>
                      <Col className="col-md-9">
                        {values?.milestones.map((entry: any, index: any) => (
                          <div key={`milestonnePayment_${index}`}>
                            {(entry.type == 1 || entry.type == 2) && (
                              <Row>
                                <Col className="mb-3">
                                  <InputCurrencyText
                                    name={`milestones[${index}].amount`}
                                    label={`${
                                      entry.type == 1
                                        ? "Interim Payment"
                                        : "Final Payment"
                                    } (£)`}
                                    placeholder={`Enter ${
                                      entry.type == 1
                                        ? "Interim Payment"
                                        : "Final Payment"
                                    }`}
                                    value={values.milestones[index]?.amount}
                                    isDisabled={values.milestones[index]?.received_date}
                                    onChange={(value, name) => {
                                      setFieldValue(
                                        `milestones[${index}].amount`,
                                        value
                                      );
                                      calculateTotalAmount(values.milestones, index, value)
                                    }}
                                  />
                                  <ErrorMessage
                                      name={`milestones[${index}].amount`}
                                      component="div"
                                      className="text-danger fs-14"
                                    />
                                </Col>
                                <Col className="mb-3">
                                  <InputDatePicker
                                    name={`milestones[${index}].due_date`}
                                    label="Due Date"
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        placeholder: "Select",
                                        className: "form-control",
                                        id: `milestones_${index}_due_date`,
                                      },
                                    }}
                                    isDisable={values.milestones[index]?.received_date}
                                    value={values.milestones[index]?.due_date}
                                    onChange={(name, newValue) =>
                                      setFieldValue(name, newValue)
                                    }
                                  />
                                  <ErrorMessage
                                      name={`milestones[${index}].due_date`}
                                      component="div"
                                      className="text-danger fs-14"
                                    />
                                </Col>
                              </Row>
                            )}
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <hr />
                   
                    <FieldArray
                      name="milestones"
                      render={(arrayHelpers) => (
                        <div>
                          <Row>
                            <Col>
                              <button
                                type="button"
                                className="btn btn-link ms-0 ps-0 text-decoration-none"
                                onClick={() =>
                                  arrayHelpers.push({
                                    name: "",
                                    due_date: null,
                                    amount: null,
                                    type:3
                                  })
                                }
                              >
                                <AddCircle className="text-info fs-20 me-1" />
                                Additional Payment
                              </button>
                            </Col>
                          </Row>                          
                          {values?.milestones.map((entry: any, index: any) => (
                            <div key={`milestonnePayment_${index}`}>
                              {entry.type == 3 && (
                                <Row className="mb-3">
                                  <Col>
                                    <InputCurrencyText
                                      name={`milestones[${index}].amount`}
                                      label="Amount (£)"
                                      placeholder="Enter amount"
                                      value={values.milestones[index]?.amount}
                                      isDisabled={values.milestones[index]?.received_date}
                                      onChange={(value, name) => {
                                        setFieldValue(
                                          `milestones[${index}].amount`,
                                          value
                                        );
                                        calculateTotalAmount(values.milestones, index, value)
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`milestones[${index}].amount`}
                                      component="div"
                                      className="text-danger fs-14"
                                    />
                                  </Col>
                                  <Col>
                                    <InputText
                                      controlId={`milestones[${index}].name`}
                                      label="Name"
                                      placeholder="Enter name"                                      
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      isDisable={values.milestones[index]?.received_date}
                                      mbClass="mb-3"
                                      value={values.milestones[index]?.name}
                                    />
                                    <ErrorMessage
                                      name={`milestones[${index}].name`}
                                      component="div"
                                      className="text-danger fs-14"
                                    />
                                  </Col>
                                  <Col>
                                    <InputDatePicker
                                      name={`milestones[${index}].due_date`}
                                      label="Due Date"
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                          placeholder: "Select",
                                          className: "form-control",
                                          id: `milestones_${index}_due_date`,
                                        },
                                      }}
                                      value={values.milestones[index]?.due_date}
                                      isDisable={values.milestones[index]?.received_date}
                                      onChange={(name, newValue) =>
                                        setFieldValue(name, newValue)
                                      }
                                    />
                                    <ErrorMessage
                                      name={`milestones[${index}].due_date`}
                                      component="div"
                                      className="text-danger fs-14"
                                    />
                                  </Col>
                                  <Col className="col-md-1 col ps-0">
                                    {!values.milestones[index]?.received_date && (
                                    <button type="button"
                                      className="fw-bold bg-danger text-white mt-4 border-0 p-0  p-2 rounded"
                                      onClick={() => {arrayHelpers.remove(index);calculateTotalAmount(values.milestones,index, 0)}}
                                    >
                                      <img src={DeleteWhiteIcon} className="w-24" />
                                    </button>

                                    )}
                                  </Col>
                                </Row>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </Modal.Body>
                  <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                    <button
                      type="button"
                      className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </button>
                    <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                   Save
                  </CustomButton>                   
                  </Modal.Footer>
                  <DeletePopup
                        isOpen={isDeletePopupOpen}
                        onClose={() => setDeletePopupOpen(false)}
                        onDelete={() => updatePaymentMilestone(values)}
                        actionType="info"
                        label="Are You Sure"
                        text={`are you sure the total payment values exceed the order value of ${currencyMask(paymentData.amount)}?`}
                      />
                </Form>
              </Modal>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default EditProjectPaymentMilestone;
