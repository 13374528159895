import { Route, Routes } from "react-router-dom";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { AnnouncementAdd } from "./Add";
import AnnouncementsList from ".";


export const Announcements = () => {
  return (
    <Routes>
      <Route path="/list" element={<AnnouncementsList />} />
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.ANNOUNCEMENTS) && (
        <Route path="/" element={<AnnouncementsList />} />
      )}
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.ANNOUNCEMENTS) && (
        <Route path="/add" element={<AnnouncementAdd />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.ANNOUNCEMENTS) && (
        <Route path="/edit/:id" element={<AnnouncementAdd />} />
      )}
    </Routes>
  );
};
