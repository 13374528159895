import * as yup from "yup";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  ALPHA_NUMERIC_ALLOW
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";

export const approveSchema = (id: string | number | undefined) => {
  return yup.object({    
    approve_reject_notes :  yup.string().trim().required().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).required().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).label("Approve / Denial Notes")
  });
}
