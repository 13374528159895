import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import CustomButton from "../../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import moment, { Moment } from "moment";
import constants, { LeaveStatus, MEETING_SOURCE_PAGES } from "../../../constants/constants";
import { getLeaveDetailById, updateLeaveStatusApi } from "../../../services/leavesService";
import InputTextArea from "../../../components/common/InputTextArea";
import AddEmployeeMeeting from "../../Employees/View/Meetings/Add";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../features/Auth/authSlice";
import * as yup from "yup";

interface AddProps {
  isOpen: boolean;
  leaveId: number | string | undefined;
  approveType: string | undefined;
  onClose: (isReload?:boolean) => void;
}

interface FormValues {
  reject_reason: string;
}



const ApproveLeave: React.FC<AddProps> = ({
  isOpen,
  onClose,
  leaveId,
  approveType,
}) => {
  const [leaveData, setLeaveData] = useState<any>();
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNewMeetingPopupOpen, setNewMeetingPopupOpen] = useState(false);
  const statusType = approveType == 'approve' ? "Approve" : approveType == 'cancel' ? "Cancel" : "Denial";
  const {user }:any = useSelector(selectAuthentication);
  const initialValues = {
    reject_reason: leaveData && leaveData.reject_reason ? leaveData.reject_reason : ""
  };

  const id = leaveId;
  let addSchema: any = "";
  if(approveType == 'reject'){
    addSchema = yup.object({  
      reject_reason: yup
      .string()
      .trim()
      .required()
      .label("Reject Reason")  
      });
  }
  /**
     * Add New meeting click handler
     */
  const addNewMeeting = () => {   
    setNewMeetingPopupOpen(!isNewMeetingPopupOpen);
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setBtnLoading(true);
    const leaveApproveType = leaveData?.user?.employeeDetails?.reporting_manager == user.user.id && approveType == 'approve' ? 'approve_lm' :  approveType;
    const formData = {
      ...values,
      status: leaveApproveType,
    };
   // console.log(formData);
   // return;
    if (id) {
      updateLeaveStatusApi(id, formData)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        onClose(true);
      })
      .catch((error) => {        
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
        setBtnLoading(false);
      });      
    }
  };

  const getLeaveDetail = async (id: number | string | undefined) => {
    setLoading(true);
    await getLeaveDetailById(id)
      .then((response: any) => {
        setLeaveData(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    if (id) {
      getLeaveDetail(id);
    } else {
      setLoading(false);
    }
  }, []);

  const handleClose = () => {
    setNewMeetingPopupOpen(false);
    getLeaveDetail(id);
  };

  const renderStatusDiv = ( checkedVal: number | string |undefined) => {
    const statsuArr:any = LeaveStatus;
    const statusName = statsuArr.find(
      (item: { key: string | number | undefined; }) => item.key == checkedVal
    );
    return <span className={`${statusName?.tagClass} py-1 px-2 rounded-1 d-inline-block fs-11 vertical-middle`}>{statusName?.value}</span>
  }

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="export-excel-modal leave-approve-modal"
          show={isOpen}
          onHide={() => onClose()}
          centered
        >
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative pb-0">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={() => onClose()}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Leave - {statusType}
                  </h4>
                  <Row>
                    <Col className="col-md-3 col-xl-3">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">
                          No. of Days
                        </label>
                        <div className="position-relative ">
                          <p className="fs-14">
                            {leaveData.leaves_count == "0.5"
                              ? `${leaveData.leaves_count} (${
                                  leaveData.session == "1" ? "AM" : "PM"
                                })`
                              : leaveData.leaves_count}{" "}
                            (
                            {moment(leaveData.date_from).format(
                              constants.dateFormats.slashSeparatedDate
                            )}{" "}
                            -{" "}
                            {moment(leaveData.date_to).format(
                              constants.dateFormats.slashSeparatedDate
                            )}
                            )
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-md-3 col-xl-3">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Applied Date
                        </label>
                        <div className="position-relative ">
                          <p className="fs-14">
                            {moment(leaveData.date_applied).format(
                              constants.dateFormats.slashSeparatedDate
                            )}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-md-3 col-xl-3">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Leave in Last 3 Months
                        </label>
                        <div className="position-relative ">
                          <p className="fs-14">{leaveData.leaves_3_months}</p>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-md-3 col-xl-3">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Current Status
                        </label>
                        <div className="position-relative ">
                          {renderStatusDiv(leaveData.status)}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {leaveData.status == "reject" && (
                    <Row>
                      <div className="col-md-12 col-xl-12">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                            Reject Reason
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{leaveData.reject_reason}</p>
                          </div>
                        </div>
                      </div>
                    </Row>
                  )}
                  <Row>
                    <div className="col-md-12 col-xl-12">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Description
                        </label>
                        <div className="position-relative ">
                          <p className="fs-14">{leaveData.description}</p>
                        </div>
                      </div>
                    </div>
                  </Row>
                  {approveType == "reject" && (
                    <Row className="row">
                      <Col className="col-md-12">
                        <InputTextArea
                          controlId="reject_reason"
                          label="Reason For Denial (If Denying)"
                          placeholder="Enter reason"
                          touched={touched.reject_reason}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.reject_reason}
                          value={values.reject_reason}
                        />
                      </Col>
                    </Row>
                  )}
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  {approveType != "cancel" && leaveData.leaveMeetings.length == 0 && (
                    <Button
                      className="fw-semibold fs-12 btn btn-info me-2 mt-2"
                      variant="info"
                      type="button"
                      onClick={addNewMeeting}
                    >
                      Create Review Meeting
                    </Button>
                  )}
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={() => onClose()}
                  >
                    {approveType == "cancel" ? "Close" : "Cancel"}
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-60 mt-2"
                    variant="primary"
                  >
                    {approveType == "approve"
                      ? "Approve"
                      : approveType == "cancel"
                      ? "Cancel Leave"
                      : "Deny"}
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
          {isNewMeetingPopupOpen && (
            <AddEmployeeMeeting
              isOpen={isNewMeetingPopupOpen}
              onClose={handleClose}
              employeeID={leaveData?.user_id}
              meetingType="other"
              leaveId={id}
              source={
                leaveData?.user?.employeeDetails?.reporting_manager ==
                user.user.id
                  ? MEETING_SOURCE_PAGES.reporting_manager
                  : MEETING_SOURCE_PAGES.hr
              }
              loggedInName={user.user?.full_name}
              loggedInID={user.user.id}
            />
          )}
        </Modal>
      )}
    </>
  );
};
export default ApproveLeave;
