import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "../../../assets/images/file-upload.svg";

import DeletePopup from "../../../components/common/deletePopup";
import { toast } from "react-toastify";
import {
  getVendorList,
  updateVendorStatusApi,
  deleteVendorApi,
} from "../../../services/vendorService";
import InputSwitchBox from "../../../components/common/InputSwitchBox";
import { Card, Col, Row } from "react-bootstrap";
import AddCircle from "../../../assets/images/add_circle.svg";
import { pageSetting, pageSizeModel } from "../../../constants/constants";
import { checkAbility, currencyMask } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block"
import { exportOpeningStocks, getOpeningStocks, getOpeningStocksTemplateSignedUrl, importOpeningStocks } from "../../../services/warehouseService";
import { FileUpload } from "@mui/icons-material";
import FileDropUploader from "../../../components/common/FileDropUploader";

const OpeningStockList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDocLoading, setDocLoading] = useState(false);
  const [isImportStockPopupOpen, setImportStockPopupOpen] = useState(false);
 

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getOpeningStocks(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "$product.hs_sku$",
      headerName: "SKU No.",
      flex: 1,
      sortable: true,
      hideable: false,
      renderCell(params) {
        if (params.row.product?.hs_sku) {
          return (
            <span title={params.row.product?.hs_sku}>
              {params.row.product?.hs_sku}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "$product.name$",
      headerName: "SKU Name",
      flex: 2,
      sortable: true,
      renderCell(params) {
        if (params.row.product?.name) {
          return (
            <span title={params.row.product?.name}>
              {params.row.product?.name}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "$product.category$",
      headerName: "Category",
      flex: 0.6,
      sortable: true,
      renderCell(params) {
        if (params.row.product?.category) {
          return (
            <span title={params.row.product?.category}>
              {params.row.product?.category}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "qty",
      headerName: "Qty",
      type:"number",
      flex: 0.5,
      sortable: true,
      renderCell(params) {
        if (params.row.qty) {
          return (
            <span title={params.row.qty}>
              {params.row.qty}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 0.5,
      type:"number",
      sortable: true,
      renderCell(params) {
        if (params.row.price) {
          return (
            <span title={currencyMask(params.row.price)}>
              {currencyMask(params.row.price)}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "amount",
      headerName: "Valuation",
      type:"number",
      flex: 0.5,
      sortable: true,
      renderCell(params) {
        if (params.row.amount) {
          return (
            <span title={currencyMask(params.row.amount)}>
              {currencyMask(params.row.amount)}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setLimit(newPaginationModel.pageSize);
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const handleDownloadOpeningStock = () => {   
  exportOpeningStocks()
      .then((response) => {
        // Create a Blob object from the response data
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()} _opening_stocks.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
        // onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Import click handler
   */
  const handleImportStock = () => {    
    setImportStockPopupOpen(true);
  };  
  /**
   * Import click handler
   */
  const downloadSampleTemplate = () => {  
    getOpeningStocksTemplateSignedUrl()
      .then((response: any) => {
        const signedFileUrl = response.data;
        // window.open(signedFileUrl, '_blank');
        window.open(signedFileUrl, "_blank");
        //console.log(signedFileUrl);
      })
      .catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {});
  };  

  /**
   * Import Pop close handler
   */
  const handleImportStockClose = () => {    
    setImportStockPopupOpen(false);
    setIsMutation(!isMutation);
  };

  /**
   * Import stock handler
   */
  const handleImportOpeningStock = (uploadedFile : any) => {
    // Implement your upload logic here using formData
   // console.log('Uploading file for ID:', formData);
   setDocLoading(true);
    const formData = new FormData();
    formData.append('file', uploadedFile);
    importOpeningStocks(formData)
        .then((response) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          handleImportStockClose();
          setDocLoading(false);
        })
        .catch(() => {
          handleImportStockClose();
          setDocLoading(false);
        });     
  }

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);
  
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Opening Stock</h1>
              </Col>
              <Col className="text-end">
                {/* <button className='fw-semibold fs-12 text-white  btn btn-info me-2 px-2' onClick={handleShow}><img src={FileUploadIcon}  alt="File Upload Icon"/></button> */}
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.OPENING_STOCK
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary me-2 px-2"
                    variant="primary"
                    type="button"
                    title="Download Current stocks"
                    onClick={() => handleDownloadOpeningStock()}
                  >
                    <DownloadIcon />                  
                  </Button>
                )}
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.OPENING_STOCK
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-info px-2"
                    variant="info"
                    type="button"
                    disabled={data.length > 0}
                    title="Upload Opening stocks"
                    onClick={() => handleImportStock()}
                  >
                    <FileUpload />     
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" w-100 pageContentInner">
              <div className="d-flex flex-column rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  disableRowSelectionOnClick
                  localeText={{ noRowsLabel: "No Record found" }}
                  className="border-0 rounded-2"
                />                
              </div>
            </Card>
          </div>
        </div>
        <FileDropUploader isOpen={isImportStockPopupOpen}
            onClose={handleImportStockClose}
            onUpload={handleImportOpeningStock}
            docLoading={isDocLoading} 
            mainTitle="Import Opening Stock"
            maxFileSize="20"
            allowFormat="import"
        >
          {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.OPENING_STOCK
                ) && (
                  <Button
                    className="btn btn-link float-end blue-text text-decoration-none fw-semibold me-1"
                    variant="link"
                    type="button"
                    title="Download sample template"
                    onClick={() => downloadSampleTemplate()}
                  >
                    <DownloadIcon /> 
                    Download sample template    
                  </Button>
                )}          
          </FileDropUploader>
        </>
      )}
    </React.Fragment>
  );
};

export default OpeningStockList;
