import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridFilterModel
} from "@mui/x-data-grid";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useNavigate } from "react-router-dom";
import {
  getAssets,
  getAssetsCount,
  AssetCountType
} from "../../services/assetService";
import Loader from "../../components/common/Loader";
import { toast } from "react-toastify";
import AddCircle from "../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";
import {
  pageSetting,
  pageSizeModel
} from "../../constants/constants";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { checkAbility } from "../../utility/common";
import { ASSET_ALLOCATION_NOT_ALLOWED } from "../../validations/ValidationErrors";

const Assets: React.FC = () => {
  const [data, setData] = useState([]);
  const [assetsCount, setAssetsCount] = useState<AssetCountType>({});
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });

  /**
   * Add asset click handler
   */
  const addAsset = () => {
    navigate('/assets/add-asset');
  };

  /**
   * Edit asset click handler
   */
  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/assets/edit-asset/${id}`);
  };

  /**
   * Edit asset click handler
   */
  const handleAllocateClick = (id: GridRowId, statusKey: string) => () => {
    if(statusKey === 'rejected' || statusKey === 'pending_for_approval'){
      toast(ASSET_ALLOCATION_NOT_ALLOWED, { type: toast.TYPE.ERROR });
    }
    else{
      navigate(`/assets/allocate-asset/${id}`); 
    }
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  /**
   * Fetch assets from server
   * @param {defaultParams}
   */
  const fetchData = (defaultParams: any) => {
    getAssets(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Fetch asset counts
   */
  const fetchAssetsCount = () => {
    getAssetsCount()
      .then((response) => {
        setAssetsCount(response.data);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'asset_id',
      headerName: 'Asset ID',
      flex: 0.7,
      sortable: true
    },
    {
      field: "name",
      headerName: "Asset Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "serial_number",
      headerName: "Serial Number",
      flex: 1,
      sortable: true,
    },
    {
      field: "type",
      headerName: "Asset Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell(params) {
        if(params.row.status){
          const className = params.row.status.toLowerCase().replace(/\s+/g, '-');
          return <span className={`asset-status-${className}`}>{params.row.status}</span>
        }else{
          return '-';
        }
      },
    },
    {
      field: "$lastAllocatedTo.full_name$",
      headerName: "Allocated To",
      flex: 1,
      sortable: true,
      renderCell(params) {
        if(params.row.lastAllocatedTo?.full_name){
          return  <span title={params.row.lastAllocatedTo.full_name}>{params.row.lastAllocatedTo.full_name}</span>;
        }
        else{
          return '-';
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      flex: 0.5,
      cellClassName: "actions",
      getActions: (params) => {
        return params.row.status_key == 'rejected' ? (
          [<div>-</div>]
        ) : 
        (
          [
          
          <div title="Allocate Asset" className="table-tags d-flex">
          {checkAbility(
            PERMISSION_ACCESS.EDIT,
            PERMISSION_MODULE.ASSET_MANAGEMENT
          ) && !(params.row.status_key === 'rejected' || params.row.status_key === 'pending_for_approval') && (
            <div className="cursor-pointer" onClick={handleAllocateClick(params.id, params.row.status_key)}>
               <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.09886 13.9108L6.58482 20.0702C6.75338 20.3681 6.64758 20.7496 6.34978 20.9182L5.16403 21.5893C4.86619 21.7578 4.48463 21.6521 4.31607 21.3542L0.83016 15.1947C0.661644 14.8969 0.767394 14.5153 1.06519 14.3468L2.25089 13.6757C2.54874 13.5072 2.9303 13.6129 3.09886 13.9108ZM15.6041 0.392631L17.3093 1.3771C15.4881 2.42855 13.6669 3.48001 11.8458 4.53146V6.88041C11.8458 6.96648 11.8884 7.04035 11.963 7.08338L13.0786 7.72754C13.2354 7.81801 13.4302 7.70555 13.4302 7.52457V5.44627L18.8937 2.29191L20.5989 3.27638C20.748 3.36244 20.8333 3.51019 20.8333 3.68232V9.44977C20.8333 9.6219 20.748 9.76965 20.5989 9.85571L15.6041 12.7394C15.4551 12.8255 15.2844 12.8255 15.1354 12.7394L10.1407 9.85571C9.9916 9.76965 9.90628 9.6219 9.90628 9.44977V3.68232C9.90628 3.51019 9.9916 3.36244 10.1407 3.27638L15.1354 0.392678C15.2844 0.306615 15.455 0.306615 15.6041 0.392631ZM4.30505 14.5193L6.49008 18.3801L6.80419 18.1988C6.89325 18.1476 6.9946 18.1372 7.08661 18.1618L12.8578 19.707C13.7109 19.9356 14.1996 19.7859 14.9538 19.4015L22.8837 14.5186C23.2937 14.2662 23.3478 13.5792 23.1048 13.1876C22.5721 12.3293 21.1068 13.0359 20.492 13.3939L16.1078 15.9475C15.1323 16.5156 14.4179 16.4166 13.3616 16.2449L10.4246 15.469C10.2248 15.4156 10.1061 15.2103 10.1595 15.0105C10.2128 14.8107 10.4181 14.692 10.6179 14.7454C11.5667 14.9996 12.507 15.3051 13.4689 15.5039C13.9643 15.5646 14.3736 15.4062 14.511 14.8936C14.6359 14.4272 14.3421 13.9873 13.9013 13.8351C12.5855 13.5944 11.3077 13.1324 10.303 12.7692C8.8426 12.2411 8.44927 12.12 7.00978 12.9534L4.30505 14.5193ZM2.29955 14.834C2.06822 14.8679 1.9081 15.0828 1.94189 15.3142C1.97569 15.5455 2.19066 15.7057 2.42203 15.6718C2.65336 15.638 2.81349 15.4231 2.77969 15.1917C2.74585 14.9604 2.53088 14.8002 2.29955 14.834Z" fill="#21AC96"/>
                </svg>
  
  
            </div>
          )}
        </div>,
        <div className="table-tags d-flex">
        {checkAbility(
          PERMISSION_ACCESS.EDIT,
          PERMISSION_MODULE.ASSET_MANAGEMENT
        ) && (
          <div title="Edit Asset" className="ms-3 cursor-pointer" onClick={handleEditClick(params.id)}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 18V13.75L13.2 0.575C13.4 0.391667 13.6208 0.25 13.8625 0.15C14.1042 0.05 14.3583 0 14.625 0C14.8917 0 15.15 0.05 15.4 0.15C15.65 0.25 15.8667 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.7708 2.4 17.8625 2.65C17.9542 2.9 18 3.15 18 3.4C18 3.66667 17.9542 3.92083 17.8625 4.1625C17.7708 4.40417 17.625 4.625 17.425 4.825L4.25 18H0ZM14.6 4.8L16 3.4L14.6 2L13.2 3.4L14.6 4.8Z"
                fill="#21AC96"
              />
            </svg>
          </div>
        )}
      </div>,
        ]
        )
    },
    },
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []); 
    const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  useEffect(() => {
    fetchAssetsCount();
  },[]);

  const historyDataColorClass = [ 'asset-card-meter', 'asset-card-overdue-ticket', 'asset-card-close-ticket','asset-card-computer','asset-card-laptop', 'asset-card-all-ticket']

  const assetCountBlock = Object.keys(assetsCount).length > 0 && 
    
      <div className="asset-count row">
        {Object.keys(assetsCount).map((asset: string, index:number) => (
          <div className="col-md-3" key={asset}>
            <div className= {`card p-4 mb-2 text-white asset-card ${historyDataColorClass[index] ?? 'asset-card-meter'}`}>
              <div className="fs-16">{`${asset}s`}</div> 
              <div className="fs-25 fw-bold">{assetsCount[asset]}</div>
            </div>
          </div>
        ))}
      
    </div>;
  
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Asset Management</h1>
              </div>
              <div>
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.ASSET_MANAGEMENT
                ) && <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => addAsset()}
                >
                  <img
                    src={AddCircle}
                    className="me-2 fs-15"
                    alt="File Upload Icon"
                  />
                  Add New Asset
                </Button>}
              </div>
            </div>
          </div>
          
          

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            
              {assetCountBlock}
            
            <h1 className="fw-bold h4 my-2">All Asset </h1>
            <Card className=" w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Assets;
