import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  VALID_EMAIL,
} from "../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../validations/regex";

export const projectSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Product Name"),
  desc: yup
    .string()
    .trim()
    .optional()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Product Description"),
  category: yup.object().required().label("Category Type"),
  shelf: yup.object().required().label("Shelf"),
  vendor: yup.object().required().label("Preferred Vendor"),
  cost: yup
    .number()
    .positive()
    .typeError("Cost must be a number")
    .required()
    .label("Cost"),
  margin_on_cost: yup
    .number()    
    .typeError("Margin Cost must be a number")
    .required()
    .label("Margin Cost"),
  min_qty: yup
    .number()
    .positive()
    .typeError("Min Stock Level must be a number")
    .integer('Min Stock Level must be an integer')
    .max(yup.ref('max_qty'), 'Max Stock Level must be greater than Min Stock Level')
    .required()
    .label("Min Stock Level"),
  max_qty: yup
    .number()
    .positive()
    .integer('Max Stock Level must be an integer')
    .typeError("Max Stock Level must be a number")
    .min(yup.ref('min_qty'), 'Max Stock Level must be greater than Min Stock Level')
    .required()
    .label("Max Stock Level"),
  is_fmp: yup.object().required().label("Fast Moving Item"),
});
