import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  VALID_EMAIL,
  INVALID_DATE,
} from "../../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../../validations/regex";

interface QualityStatus {
  key: string;
  // Other properties as needed
}

const commonSchema = yup.object({  
  milestones: yup.array().of(
    yup.object({
      amount: yup
      .number()
      .required()
      .when("type", {
        is: (status: number) => status != 1,
        then: (schema) => schema.positive(),
        otherwise: (schema) => schema,
      })      
      .typeError("Amount must be a number")
      .label("Amount"),      
      due_date: yup
      .date()
      .required() 
      .typeError(INVALID_DATE)
      .label("Due Date"),      
      name: yup.string().when("type", {
        is: (status: number) => status == 3,
        then: (schema) => schema.required().min(3, MINIMUM_3_CHAR)
        .max(30, MAXIMUM_30_CHAR).label("Name"),
        otherwise: (schema) => schema.optional().min(3, MINIMUM_3_CHAR)
        .max(255, MAXIMUM_255_CHAR).label("Name"),
      }),      
    })
  ),
});

const paymentMilestoneSchema = (context: string | undefined | null) => {
  if (context) {}

  // If it's an add context, return the common schema
  return commonSchema;
};

export default paymentMilestoneSchema;
