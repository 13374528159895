import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../../constants/permissionUser";
import { checkAbility } from "../../../../utility/common";
import DocumentNeedFromEmployee from "./NeedFromEmployeeList";
import DocumentSentsToEmployee from "./SentToEmployeeList";

const EmployeeDocuments: React.FC<Record<string, number>> = ({employeeID}) => {  
  return (
    <React.Fragment>      
        <Row className="mb-3">
          <Col>
            <DocumentNeedFromEmployee employeeID = {employeeID}/>
          </Col>
        </Row>
        <Row>
          <Col>
          <DocumentSentsToEmployee employeeID = {employeeID}/>
          </Col>
        </Row>
      
    </React.Fragment>
  );
};

export default EmployeeDocuments;
