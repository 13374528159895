import { axios } from "./axios";
import {   HR_DASHBARD } from "../constants/paths";
 interface DateRangeInterface {
  orderDateRange: {
    dateFrom: string;
    dateTo: string;
  };
}
//get dashboard chart count api call
const getEmployeeLeaveChartCount = (orderDateRange: DateRangeInterface['orderDateRange'],visibleDepartments: any, signal: any): Promise<any> => {
  return axios.get(`${HR_DASHBARD}/employee-leave-chart`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
      visibleDepartments : visibleDepartments
    },
    signal: signal
  });
}; 

const getExpireDocumentList = (signal: any) : Promise<any> => {
   return axios.get(`${HR_DASHBARD}/expire-document-list`,{
    signal: signal
   })
}
 
const getDelayMeetingList = (signal: any) : Promise<any> => {
  return axios.get(`${HR_DASHBARD}/delay-meeting-list`,{
    signal: signal
  })
}

const getPendingTaskForHr = (signal: any) : Promise<any> => {
  return axios.get(`${HR_DASHBARD}/employee-pending-task`,{
    signal: signal
  })
}

const getOnboardingChartData =  (orderDateRange: DateRangeInterface['orderDateRange'], signal: any): Promise<any> => {
  return axios.get(`${HR_DASHBARD}/onboarding-progress-data`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
}; 


const getAssetCounts = (signal: any) : Promise<any> => {
  return axios.get(`${HR_DASHBARD}/pending-asset`, {
    signal: signal
  })
}  


const getLeaveAndTenureData =  (orderDateRange: DateRangeInterface['orderDateRange'], signal: any): Promise<any> => {
  return axios.get(`${HR_DASHBARD}/pending-leave-and-avg-tenure`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
}; 


const  getDocumentVerificationChart = (orderDateRange: DateRangeInterface['orderDateRange'], signal: any): Promise<any> => {
  return axios.get(`${HR_DASHBARD}/employee-document-verification-chart`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
}; 

const getOnboardCount = (orderDateRange: DateRangeInterface['orderDateRange'], signal: any): Promise<any> => {
  return axios.get(`${HR_DASHBARD}/pending-onboarding-count`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
}; 



export {
  getEmployeeLeaveChartCount,
  getExpireDocumentList,
  getDelayMeetingList,
  getPendingTaskForHr,
  getOnboardingChartData,
  getAssetCounts,
  getLeaveAndTenureData,
  getDocumentVerificationChart,
  getOnboardCount
};
