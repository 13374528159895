import moment from "moment";
import react, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import constants from "../../constants/constants";
import { Card, Col, Row } from "react-bootstrap";
import Loader from "../../components/common/Loader";
import InputDateRangePicker from "../../components/common/InputDateRangePicker";
import OnboardingChart from "./OnboradingChart";
import EmployeeLeaveChart from "./EmployeeLeaveChart";
import ExpireDocuments from "./ExpireDocuments";
import MeetingDelayList from "./MeetingDelayList";
import PendingTaskHR from "./PendingTaskHR";
import AssetAndLeaveCount from "./AssetAndLeaveCounts";
import DocumentVerificationChart from "./DocumentVerificationChart";
import OnboardingCounts from "./OnboardingCount";

const HRDASHBOARD: React.FC = () => {
  const dummeyD = [
    {
      label: "Completed",
      status_count: 50,
      status_percent: 50.0,
      color: "#21AC59",
    },
    {
      label: "Pending",
      status_count: 30,
      status_percent: 30.0,
      color: "#EFA73C",
    },
    {
      label: "Delayed",
      status_count: 20,
      status_percent: 20.0,
      color: "#EE3030",
    },
  ];
  const [projectData, setProjectData] = useState<any[]>([]);
  const [dealData, setDealData] = useState<any[]>(dummeyD);
  const [plannerData, setPlannerData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [orderDateRange, setOrderDateRange] = useState({
    dateFrom: moment()
      .subtract("12", "months")
      .format(constants.dateFormats.databaseFormat),
    dateTo: moment().format(constants.dateFormats.databaseFormat),
  });

  useEffect(() => {
    setLoading(false);
  }, [orderDateRange]);

  const orderDateFilter = (
    data: any,
    fieldName: string,
    setFieldValue: any
  ) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(
        constants.dateFormats.databaseFormat
      );
      const endDate = moment(dataRange[1]).format(
        constants.dateFormats.databaseFormat
      );
      setOrderDateRange({
        dateFrom: startDate,
        dateTo: endDate,
      });
    } else {
      setOrderDateRange({
        dateFrom: moment()
          .subtract("12", "months")
          .format(constants.dateFormats.databaseFormat),
        dateTo: moment().format(constants.dateFormats.databaseFormat),
      });
    }
  };

  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 py-2 my-1">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="fw-bold h4 my-2">Dashboard</h1>
          </div>

          <div className="dashbord-input-rang">
            <InputDateRangePicker
              controlId="date"
              label=""
              placeholder="Date Range"
              touched={false}
              handleBlur={false}
              handleChange={orderDateFilter}
              handleClose={orderDateFilter}
              errorsField={false}
              defaultValue={[
                new Date(orderDateRange.dateFrom),
                new Date(orderDateRange.dateTo),
              ]}
              position="auto"
            />
          </div>
        </div>
      </div>

      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <>
            <Row className="gx-3 ">
              <Col md={3} className="mb-3">
                <OnboardingCounts orderDateRange={orderDateRange} />
              </Col>
              <Col md={9} className="mb-3">
                <EmployeeLeaveChart orderDateRange={orderDateRange} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <h2 className="fs-20 info-text fw-bold">
                  Notification & Reminder
                </h2>
              </Col>
              <Col md={4}>
                <ExpireDocuments />
              </Col>
              <Col md={8}>
                <MeetingDelayList />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <h2 className="fs-20 info-text fw-bold">Actionable Insights</h2>
                <AssetAndLeaveCount orderDateRange={orderDateRange} />
              </Col>
              <Col md={6}>
                <h2 className="fs-20 info-text fw-bold">
                  Document Verification Status
                </h2>
                <DocumentVerificationChart orderDateRange={orderDateRange} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <h2 className="fs-18 info-text fw-bold">Pending Task</h2>
                <PendingTaskHR />
              </Col>
              <Col md={6}>
                <h2 className="fs-18 info-text fw-bold">Onboarding Progress</h2>
                <OnboardingChart orderDateRange={orderDateRange} />
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default HRDASHBOARD;
