import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import FormSelect from "../../../components/common/FormSelect";
import { useCallback, useEffect, useState } from "react";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import {
  getDashboardInventoryTrendsChartApi,
  getDashboardProductListApi,
  getProductListAsync,
} from "../../../services/warehouseService";
import {
  Box,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import InputText from "../../../components/common/InputText";
import BarChartSkeleton from "../../../components/common/Skeleton/BarChart.skeleton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Product {
  hs_sku: any;
  id: number;
  name: string;
}

export const DashboardInventoryTrend = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [listLoading, setListLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [inventroyTrendsData, setInventoryTrendsData] = useState<any>({
    labels: ["January"],
    datasets: [
      {
        product_id: 0,
        data: [0],
        borderColor: "blue",
        backgroundColor: "blue",
        pointBackgroundColor: "blue",
      },
    ],
  });
  const [totalPages, setTotalPages] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [selectFilter, setSelectFilter] = useState<any>({
    key: 6,
    value: "Last 6 Months",
  });

  const fetchProducts = useCallback(async () => {
    setListLoading(true);
    try {
      const requestParam = {
        page,
        limit: 10,
        search,
      };
      getDashboardProductListApi(requestParam)
        .then((response) => {
          if (page === 1) {
            setProducts(response.data.resultSet);
          } else {
            setProducts((prevProducts) => [
              ...prevProducts,
              ...response.data.resultSet,
            ]);
          }
          setTotalPages(response.data.totalResults);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    } catch (error) {
      console.error("Failed to fetch products", error);
    }
    setListLoading(false);
  }, [page, search]);

  useEffect(() => {
    fetchProducts();
  }, [page, search, fetchProducts]);

  useEffect(() => {
    //console.log(products);
    if (products.length && !selectedProducts.length) {
      const top5ProductIds = products.slice(0, 5).map((product) => product.id);
      setSelectedProducts(top5ProductIds);
    }
  }, [products]);

  useEffect(() => {
    //console.log(products);
    if (selectedProducts.length > 0) {
      getDashboardInventoryTrendsData();
    }
  }, [selectedProducts, selectFilter]);

  const handleToggle = (id: number) => () => {
    const currentIndex = selectedProducts.indexOf(id);
    const newSelected = [...selectedProducts];

    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedProducts(newSelected);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(1); // Reset to first page on search
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (
      scrollHeight - scrollTop === clientHeight &&
      page < totalPages &&
      !listLoading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const options: any = {
    responsive: true,
  //  aspectRatio: 3,
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "start" as const,
        fullSize: true,
        labels: {          
          boxWidth: 20,
          padding: 5,
          usePointStyle:true,
          pointStyle:'rectRounded',
          font: {
            size: "10",
          },
        },
      },
      title: {
        display: false,
        text: "Line Chart",
      },
      datalabels: {
        display: false, // Disable datalabels for this specific chart
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          display: false
        },
      },
      x: {
        grid:{
          drawOnChartArea: false
        }
      }  
    },
    elements: {
      point: {
        pointStyle: false,
        radius: 4,
      },
    },
  };

  const generateMonthlyArray = (
    numberOfMonths: number
  ): { key: number; value: string }[] => {
    const monthsArray = [];

    for (let i = 2; i <= numberOfMonths; i++) {
      monthsArray.push({ key: i, value: `Last ${i} month${i > 1 ? "s" : ""}` });
    }

    return monthsArray;
  };
  // Generate array for 12 months
  const monthlyArray = generateMonthlyArray(12);
  const getDashboardInventoryTrendsData = () => {
    setLoading(true);
    const selectQuarter = selectFilter?.key;
    const requestParam = {
      duration: selectQuarter,
      productsIds: selectedProducts,
    };
    getDashboardInventoryTrendsChartApi(requestParam)
      .then((response) => {
        convertToChartData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to convert API response to Chart.js data
  const convertToChartData = (apiResponse: any) => {
    //console.log(apiResponse);
    const labels = apiResponse.labels;
    const data = apiResponse.datasets;

    setInventoryTrendsData({
      labels,
      datasets: data,
    });
    // console.log({
    //   labels,
    //   datasets: data,
    // })
  };

  return (
    <div className="d-flex flex-column pageContainer h-100 w-100 pb-sm-1">
      <Card className="w-100 pageContentInner h-100 rounded-3">
        <Row >
          <Col md={8}>
          <div className="p-3 pe-0">
          <Col md={6}>
                    <h5 className="fs-18 fw-bold info-text mb-0">Inventory Trend</h5>
                  </Col>
            {loading ? (
               <Card className="p-3 w-100 pageContentInner rounded-3 h-100">
               <BarChartSkeleton heigth={300} numbers={30} />
             </Card>
            ) : (
              <>
                <Row className="align-items-center">
                 
                  <Col md={6 } className="warehouse-dashbord-select-month">
                    <FormSelect
                      placeholder=""
                      label=""
                      name="filter"
                      options={monthlyArray}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setSelectFilter(value);
                      }}
                      value={selectFilter}
                    />
                  </Col>
                </Row>
                <div >
                  <Line data={inventroyTrendsData} options={options} />
                </div>
              </>
            )}
            </div>
          </Col>
          <Col md={4} className="ware-dashboard-border-left ps-0">
            <div className="pt-3">
              <h5 className="fs-18 fw-bold info-text p-3 pt-0 mb-0">Products</h5>
              <Box mb={0} className="warehouse-product-input px-2">
                <InputText
                  controlId="name"
                  label=""
                  className="border-0 border-bottom"
                  placeholder="Search By Name or Sku"
                  handleChange={handleSearchChange}
                  value={search}
                />
              </Box>
              <Box
                onScroll={handleScroll}
                style={{
                  height: "280px",
                  overflowY: "auto",
                  borderRadius: "0px",
                  padding: "10px",
                }}
              >
                {listLoading && page === 1 ? (
                  <CircularProgress />
                ) : (
                  <List className="p-0">
                    {products.map((product) => (
                      <ListItem
                        className="p-0"
                        key={product.id}
                        title={`${product.hs_sku} - ${product.name}`}
                        button
                        disabled={
                          !(selectedProducts.indexOf(product.id) !== -1) &&
                          selectedProducts.length >= 5
                        }
                        onClick={handleToggle(product.id)}
                      >
                        <Checkbox
                          edge="start"
                          checked={selectedProducts.indexOf(product.id) !== -1}
                          disabled={
                            !(selectedProducts.indexOf(product.id) !== -1) &&
                            selectedProducts.length >= 5
                          }
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText
                          className="text-wrap-ellipsis"
                          primary={`${product.hs_sku} - ${product.name}`}
                        />
                      </ListItem>
                    ))}
                    {listLoading && page > 1 && <CircularProgress />}
                  </List>
                )}
              </Box>
              </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
