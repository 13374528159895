import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import { ErrorMessage, FieldArray, Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../../components/common/InputText";
import { InvoiceDataDTO } from "./invoice.type";
import CustomButton from "../../../../components/common/CustomButton";
import Loader from "../../../../components/common/Loader";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import moment from "moment";
import grnSchema from "./validation";
import FormAsyncSelect from "../../../../components/common/AsyncSelect/FormAsyncSelect";
import { getOrderNumberListApi } from "../../../../services/dealService";
import AddIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Close";
import InputCurrencyText from "../../../../components/common/InputCurrencyText";
import {
  createInvociesApi,
  getInvociesByIdApi,
  getInvoiceNumberApi,
  updateInvociesApi,
} from "../../../../services/financeService";
import FormSelect from "../../../../components/common/FormSelect";
import { getProjectPaymentMilestoneAPi } from "../../../../services/project.service";
import { currencyMask } from "../../../../utility/common";
import constants from "../../../../constants/constants";
import { getCompanyDetail } from "../../../../services/authService";

interface Item {
  description: string;
  qty?: number | null;
  price?: number | null;
  tax_percentage?: number;
  amount?: number | string;
  tax_amount?: number;
}

export const AddNewInvoice = () => {
  const [companyData, setCompanyData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [milestoneData, setMilestoneData] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>({
    subtotal: 0,
    tax: 0,
    total: 0,
  });
  const navigate = useNavigate();
  let { id } = useParams();

  /**
   * Submit Method to call when grn save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: InvoiceDataDTO, { setSubmitting }: any) => {
    const formData: any = {
      ...values,
      inv_date: moment(values.inv_date).isValid()
        ? moment(values.inv_date).format(constants.dateFormats.databaseFormat)
        : null,
      due_date: moment(values.due_date).isValid()
        ? moment(values.due_date).format(constants.dateFormats.databaseFormat)
        : null,
    };

    setBtnLoading(true);
    if (id) {
      updateInvociesApi(id, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/finance/invoices");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createInvociesApi(formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/finance/invoices");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType: "project",
        showAll : true
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const getInvoiceDetail = (id: string) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getInvociesByIdApi(id)
        .then((response) => {
          const dpObj = response.data;
          setInvoiceData(dpObj);
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/dispatch-orders");
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
            navigate("/dispatch-orders");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/dispatch-orders");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    //console.log("121212");
    Promise.all([getCompanyDetailsData()]).then(() => {
      if (id) {
        Promise.all([getInvoiceDetail(id)]).then(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, [id]);

  useEffect(() => {
    if (invoiceData) {
      if (invoiceData.contract_id) {
        getProductPaymentMilestone(invoiceData.contract_id);
        calculateTotalAmount(invoiceData.invDetails);
      }
    }
  }, [invoiceData.contract_id]);

  const handleCancel = () => {
    navigate(-1);
  };

  const getProductPaymentMilestone = async (
    projectId: string | number | undefined,
    invoiceValues?: any,
    setFieldValue?: any
  ) => {
    //  setLoading(true);
    if (projectId) {
      getProjectPaymentMilestoneAPi(projectId).then((response) => {
        const paymentMilestonesData = response?.data;
        const paymentMilestonesArr = [];
        if (paymentMilestonesData.paid_amount) {
          paymentMilestonesArr.push({
            key: 0,
            value: "Deposit",
            amount: paymentMilestonesData.paid_amount as Number,
          });
        }
        const milestoneData = paymentMilestonesData?.payment_milestone?.map(
          (item: { id: any; name: any; amount: Number }) => ({
            key: item.id,
            value: item.name,
            amount: item.amount as Number,
          })
        );
        setMilestoneData([...paymentMilestonesArr, ...milestoneData]);
        if (setFieldValue) setFieldValue("payment_milestone_id", null);
        const invoiceDetailsArray = invoiceValues ? [...invoiceValues] : [];
        if (invoiceDetailsArray.length && setFieldValue) {
          const invoiceDetails: Item = {
            description: "",
            qty: 0,
            price: 0,
            tax_percentage: 0,
            amount: 0,
          };
          invoiceDetailsArray[0] = invoiceDetails;
          setFieldValue("invoiceDetails", invoiceDetailsArray);
        }
        setLoading(false);
      });
    } else {
      setMilestoneData([]);
      if (setFieldValue) setFieldValue("payment_milestone_id", null);
    }
  };

  const updateInvoiceDetails = async (
    milestoneId: any,
    formValues?: any,
    setFieldValue?: any
  ) => {
    const invoiceDetailsArray: Item[] = [...formValues.invoiceDetails];
    if (milestoneId) {
      if (invoiceDetailsArray.length) {
        const invoiceDetails: Item = {
          description: `Project ${formValues?.contract_id.name} ${milestoneId?.value}`,
          qty: 1,
          price: parseFloat(milestoneId?.amount),
          tax_percentage: 0,
          amount: parseFloat(milestoneId?.amount),
        };
        invoiceDetailsArray[0] = invoiceDetails;
        setFieldValue("invoiceDetails", invoiceDetailsArray);
      }
      calculateTotalAmount(invoiceDetailsArray);
    } else {
      // setMilestoneData([])
    }
  };

  const getCompanyDetailsData = async () => {
    return new Promise((resolve, reject) => {
      getCompanyDetail().then((response) => {
        setCompanyData(response?.data);
        return resolve(true);
      });
    });
  };

  const handleDeleteInvoiceDetail = (
    formValues: any,
    productIndex: number,
    setFieldValue: any
  ) => {
    const productArray = [...formValues.invoiceDetails];
    productArray.splice(productIndex, 1);
    setFieldValue("invoiceDetails", productArray);
    calculateTotalAmount(productArray);
  };

  // Function to calculate the sum of all amounts
  const calculateTotalAmount = (
    invoiceDetails: any[],
    indexNumber?: number,
    invoiceData?: any,
    setFieldValue?: any
  ) => {
    const updatedData: Item[] = [...invoiceDetails];
    if (typeof indexNumber === "number" && indexNumber !== undefined) {
      const qty: number = invoiceData?.qty ? invoiceData.qty : 0;
      const price: number = invoiceData?.price ? invoiceData.price : 0;
      const tax_percentage: number = invoiceData?.tax_percentage
        ? invoiceData.tax_percentage
        : 0;
      updatedData[indexNumber].qty = qty;
      updatedData[indexNumber].price = price;
      updatedData[indexNumber].tax_percentage = tax_percentage;
      const amt = parseFloat(qty.toString()) * parseFloat(price.toString());
      updatedData[indexNumber].amount = (
        amt +
        (amt * tax_percentage) / 100
      ).toFixed(2);
      setFieldValue("invoiceDetails", updatedData);
    }
    // Calculate subtotal, total tax, and grand total
    let subtotal: number = 0;
    let totalTax: number = 0;

    updatedData.forEach((item: Item) => {
      const { qty, price, tax_percentage } = item;
      let amt = 0;
      if (qty && price) {
        amt = parseFloat(qty.toString()) * parseFloat(price.toString());
        subtotal += amt;
        if (tax_percentage) {
          totalTax +=
            (amt * (parseFloat(tax_percentage.toString()) || 0)) / 100;
        }
      }
    });
    const grandTotal: number = subtotal + totalTax;
    setTotalAmount({
      subtotal: subtotal.toFixed(2),
      tax: totalTax.toFixed(2),
      total: grandTotal.toFixed(2),
    });
  };

  //console.log(invoiceData);
  const initialValues: any = {
    // dp_number: id ? invoiceData?.dispatch_number_str : dpNumber,
    inv_date: invoiceData?.inv_date ? moment(invoiceData?.inv_date) : null,
    due_date: invoiceData?.due_date ? moment(invoiceData?.due_date) : null,
    contract_id: invoiceData?.contract_id ? invoiceData?.project : null,
    payment_milestone_id: invoiceData?.payment_milestone_id
      ? {
          key: invoiceData?.payment_milestone_id,
          value: invoiceData?.payment_milestone.name,
        }
      : null,
    invoiceDetails: invoiceData?.invDetails
      ? invoiceData?.invDetails
      : [{ description: "", qty: 0, price: 0, tax_percentage: 0, amount: 0 }],
  };
  // console.log(initialValues, productArray);
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {id ? "Update" : "Create"} Invoice{" "}
                  {invoiceData?.inv_number
                    ? ` - ${invoiceData?.inv_number_str}`
                    : ""}
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={grnSchema(id)}
              initialValues={initialValues}
              onSubmit={(values: InvoiceDataDTO, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-4">
                            <FormAsyncSelect
                              id="contract_id"
                              name="contract_id"
                              label="Project Order No."
                              isAsync
                              isClearable
                              getOptions={getOrderNumber}
                              value={values.contract_id}
                              error={errors.contract_id}
                              touched={touched.contract_id}
                              onChange={(name: any, value: any) => {
                                setFieldValue(name, value);
                                getProductPaymentMilestone(
                                  value?.id,
                                  values.invoiceDetails,
                                  setFieldValue
                                );
                              }}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Payment Milestone"
                              name="payment_milestone_id"
                              options={milestoneData}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                                updateInvoiceDetails(
                                  value,
                                  values,
                                  setFieldValue
                                );
                              }}
                              value={values.payment_milestone_id}
                              error={errors.payment_milestone_id}
                              touched={touched.payment_milestone_id}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputDatePicker
                              name="inv_date"
                              label="Invoice Date"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  placeholder: "Select",
                                  className: "form-control",
                                  id: `inv_date`,
                                },
                              }}
                              value={values.inv_date}
                              onChange={(name, newValue) =>
                                setFieldValue(name, newValue)
                              }
                              touched={touched.inv_date}
                              errors={errors.inv_date}
                            />
                          </Col>
                        </Row>
                        {id && invoiceData?.contract_id && (
                          <>
                            <div className="fs-16 fw-bold text-black">
                              {" "}
                              {invoiceData?.customer_name}
                            </div>
                            <div className="fs-14 text-black"> {invoiceData?.address}</div>
                          </>
                        )}
                      </div>
                    </Card>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-12">
                            <FieldArray
                              name="invoiceDetails"
                              render={(arrayHelpers) => (
                                <div>
                                  <Row className="align-items-center">
                                    <Col className="fw-bold col-lg-5 col-md-4  fs-14">
                                      Description
                                    </Col>
                                    <Col className="fw-bold col-md-1  fs-14">
                                      Qty
                                    </Col>
                                    <Col className="fw-bold col-md-2  fs-14">
                                      Unit Price
                                    </Col>
                                    <Col className="fw-bold col-md-2 fs-14 add-invoice-vat">
                                      VAT
                                    </Col>
                                    <Col className="fw-bold col-md-2 fs-14 add-invoice-vat">
                                      Amount(£)
                                    </Col>
                                    <Col className="fw-bold col-md-1  fs-14">
                                      <Button
                                        className="fw-semibold fs-12  btn btn-primary mt-0 w-40 h-40"
                                        variant="custom"
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            description: "",
                                            qty: null,
                                            price: null,
                                            tax_percentage: "",
                                            amount: "",
                                          })
                                        }
                                      >
                                        <AddIcon className="fs-16 text-white" />
                                      </Button>
                                    </Col>
                                  </Row>
                                  <hr className="mt-1 mb-2" />
                                  {values?.invoiceDetails.map(
                                    (entry: any, index: any) => (
                                      <div key={`invoiceDetails_${index}`}>
                                        <Row className="mb-2">
                                          <Col className="col-lg-5 col-md-4 fs-14">
                                            <div className="col-md-10">
                                              <InputText
                                                controlId={`invoiceDetails[${index}].description`}
                                                label=""
                                                placeholder="Enter description"
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                mbClass=""
                                                value={
                                                  values.invoiceDetails[index]
                                                    ?.description
                                                }
                                              />
                                            </div>
                                            <ErrorMessage
                                              name={`invoiceDetails[${index}].description`}
                                              component="div"
                                              className="text-danger fs-14"
                                            />
                                          </Col>
                                          <Col className="col-md-1  fs-14">
                                            {index == 0 ? (
                                              entry.qty
                                            ) : (
                                              <>
                                                <InputCurrencyText
                                                  name={`invoiceDetails[${index}].qty`}
                                                  label=""
                                                  placeholder="Qty."
                                                  value={
                                                    values.invoiceDetails[index]
                                                      ?.qty
                                                  }
                                                  isInteger={true}
                                                  onChange={(value, name) => {
                                                    setFieldValue(
                                                      `invoiceDetails[${index}].qty`,
                                                      value
                                                    );
                                                    calculateTotalAmount(
                                                      values.invoiceDetails,
                                                      index,
                                                      {
                                                        qty: value,
                                                        price:
                                                          values.invoiceDetails[
                                                            index
                                                          ]?.price,
                                                        tax_percentage:
                                                          values.invoiceDetails[
                                                            index
                                                          ]?.tax_percentage,
                                                      },
                                                      setFieldValue
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name={`invoiceDetails[${index}].qty`}
                                                  component="div"
                                                  className="text-danger fs-14"
                                                />
                                              </>
                                            )}
                                          </Col>
                                          <Col className="col-md-2  fs-14">
                                            {index == 0 ? (
                                              entry.price ? (
                                                entry.price
                                              ) : (
                                                "-"
                                              )
                                            ) : (
                                              <>
                                                <InputCurrencyText
                                                  name={`invoiceDetails[${index}].price`}
                                                  label=""
                                                  placeholder="Price"
                                                  value={
                                                    values.invoiceDetails[index]
                                                      ?.price
                                                  }
                                                  onChange={(value, name) => {
                                                    setFieldValue(
                                                      `invoiceDetails[${index}].price`,
                                                      value
                                                    );
                                                    calculateTotalAmount(
                                                      values.invoiceDetails,
                                                      index,
                                                      {
                                                        qty: values
                                                          .invoiceDetails[index]
                                                          ?.qty,
                                                        price: value,
                                                        tax_percentage:
                                                          values.invoiceDetails[
                                                            index
                                                          ]?.tax_percentage,
                                                      },
                                                      setFieldValue
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name={`invoiceDetails[${index}].price`}
                                                  component="div"
                                                  className="text-danger fs-14"
                                                />
                                              </>
                                            )}
                                          </Col>
                                          <Col className="col-md-2 add-invoice-vat  fs-14">
                                            <InputCurrencyText
                                              name={`invoiceDetails[${index}].tax_percentage`}
                                              label=""
                                              placeholder="VAT"
                                              value={
                                                values.invoiceDetails[index]
                                                  ?.tax_percentage
                                              }
                                              onChange={(value, name) => {
                                                setFieldValue(
                                                  `invoiceDetails[${index}].tax_percentage`,
                                                  value
                                                );
                                                calculateTotalAmount(
                                                  values.invoiceDetails,
                                                  index,
                                                  {
                                                    qty: values.invoiceDetails[
                                                      index
                                                    ]?.qty,
                                                    price:
                                                      values.invoiceDetails[
                                                        index
                                                      ]?.price,
                                                    tax_percentage: value,
                                                  },
                                                  setFieldValue
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`invoiceDetails[${index}].tax_percentage`}
                                              component="div"
                                              className="text-danger fs-14"
                                            />
                                          </Col>
                                          <Col className="col-md-2 add-invoice-vat  fs-14">
                                            <InputCurrencyText
                                              name={`invoiceDetails[${index}].amount`}
                                              label=""
                                              placeholder="Amount"
                                              isDisabled
                                              value={
                                                values.invoiceDetails[index]
                                                  ?.amount
                                              }
                                              onChange={(value, name) => {
                                                setFieldValue(
                                                  `invoiceDetails[${index}].amount`,
                                                  value
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`invoiceDetails[${index}].amount`}
                                              component="div"
                                              className="text-danger fs-14"
                                            />
                                          </Col>
                                          <Col className="col-md-1  fs-14">
                                            {index != 0 && (
                                              <Button
                                                className="fw-semibold fs-12 p-0  btn btn-danger text-white btn btn-custom w-40 h-40"
                                                variant="custom"
                                                type="button"
                                                onClick={() =>
                                                  handleDeleteInvoiceDetail(
                                                    values,
                                                    index,
                                                    setFieldValue
                                                  )
                                                }
                                              >
                                                <DeleteIcon className="fs-20" />
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  )}
                                  <hr className="mt-2 mb-3" />
                                </div>
                              )}
                            />
                            <Row>
                              <Col className="col-lg-8 col-md-7  fs-14">
                                <div className="col-lg-3 col-md-5">
                                  <InputDatePicker
                                    name="due_date"
                                    label="Invoice Date"
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        placeholder: "Select",
                                        className: "form-control",
                                        id: `due_date`,
                                      },
                                    }}
                                    value={values.due_date}
                                    onChange={(name, newValue) =>
                                      setFieldValue(name, newValue)
                                    }
                                    touched={touched.due_date}
                                    errors={errors.due_date}
                                  />
                                </div>
                                <div>
                                  <div className="fw-16 mt-4">{companyData?.companyName}</div>
                                  <div className="fw-16">
                                    <span className="fw-bold">Acct No.: </span>{" "}
                                    {companyData?.acct_no}
                                  </div>
                                  <div className="fw-16">
                                    <span className="fw-bold">Sort Code: </span>{" "}
                                    {companyData?.sort_code}
                                  </div>
                                </div>
                              </Col>
                              <Col className=" col-md-4 invoice-sub-cover text-black  fs-14">
                                <Row>
                                  <Col className="d-flex col-12">
                                    <div className="text-end w-100 mb-2">
                                      Subtotal
                                    </div>
                                    <div className="text-end w-100">
                                      {currencyMask(totalAmount.subtotal)}
                                    </div>
                                  </Col>
                                  <Col className="d-flex col-12">
                                    <div className="text-end w-100 mb-2">
                                      Total Excempt
                                    </div>
                                    <div className="text-end w-100">
                                      {currencyMask(totalAmount.tax)}
                                    </div>
                                  </Col>
                                  <hr className="mb-2" />
                                  <Col className="d-flex col-12">
                                    <div className="text-end w-100 fw-bold fs-18 ">
                                      Total (£)
                                    </div>
                                    <div className="text-end w-100 fs-18">
                                      {currencyMask(totalAmount.total)}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    {!id && (
                      <div className="text-danger">
                        *Enter Zero(0) if You Want To Exampt VAT
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <div
                            className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                            onClick={handleCancel}
                          >
                            Cancel
                          </div>
                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={btnLoading}
                            className="fw-semibold fs-13 text-white mw-65 mt-2"
                            variant="primary"
                          >
                            {id ? "Update" : "Create Invoice"}
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
