import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { checkAbility, currencyMask } from "../../../utility/common";
import { FC, useEffect, useState } from "react";
import { IndeterminateCheckBox } from "@mui/icons-material";
import { getDashboardDispatchReturnProductQtyApi } from "../../../services/warehouseService";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
interface MinStockAlert {
  id: number;
  hs_sku: string;
  name: string;
  min_qty: number;
  stock_qty: number;
}

interface StockAndValuationData {
  total_qty: string;
  total_value: string;
}

interface InventoryDataProps {
  productAlertData: {
    minStockAlert: MinStockAlert[];
    stockAndValuationData: StockAndValuationData;
  };
}

export const DashboardStockLevel: FC<InventoryDataProps> = ({
  productAlertData,
}) => {
  const [dispatchReturnQty, setDispatchReturnQty] = useState<any>([]);

  const getDispatchReturnProductQty = () => {
 
    getDashboardDispatchReturnProductQtyApi()
      .then((response) => {
        //setPoRaisedData(response.data);
        setDispatchReturnQty(response.data);      
      })
      .catch((error) => {
       // setLoading(false);
      })
      .finally(() => {
       // setLoading(false);
      });
  };
  useEffect(() => {
    getDispatchReturnProductQty();
  }, []);

  return (
    <div className="d-flex flex-column pageContainer  w-100 pb-sm-1">
      <Card className="p-4 w-100 pageContentInner">
        <Row>
          <Col md={3}>
            <div className="bg-light p-3 rounded-2">
              <div className="fs-16 text-black">Total Stock Levels</div>
              <div className="fs-24 fw-bold info-text">
                {productAlertData?.stockAndValuationData?.total_qty} Units
              </div>
            </div>
            <div className="bg-primary mt-2 p-3 rounded-2 text-white">
              <div className="fs-16">Total Stock Values</div>
              <div className="fs-24 fw-bold">
                {currencyMask(
                  productAlertData?.stockAndValuationData?.total_value
                )}
              </div>
            </div>
          </Col>
          <Col md={4} className="max-h-250">
            <Row>
              <Col>
                <h3 className="fs-20 fw-bold text-black mb-0">
                  Dispatch Return
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="border p-3 rounded-3 mt-2">
                  <Table className="table-custom">
                    <thead>
                      <tr>
                        <th>Project Order No</th>
                        <th>Dispatch No</th>
                        <th>Quantity</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dispatchReturnQty?.dispatchInventoryData?.length > 0 ? (
                        dispatchReturnQty.dispatchInventoryData.map(
                          (productItem: any, index: any) => (
                            <tr key={`returnstock_${IndeterminateCheckBox}`}>
                              <td>
                                {checkAbility(
                                  PERMISSION_ACCESS.VIEW,
                                  PERMISSION_MODULE.PROJECTS
                                ) ? (
                                  <Link
                                    target="_blank"
                                    className="blue-text text-decoration-none fw-semibold"
                                    to={`/projects/view/${productItem.dispatchOrder.project.id}`}
                                  >
                                    {" "}
                                    {
                                      productItem.dispatchOrder.project
                                        .project_no_str
                                    }
                                  </Link>
                                ) : (
                                  productItem.dispatchOrder.project
                                    .project_no_str
                                )}
                              </td>
                              <td>
                                {productItem.dispatchOrder.dispatch_number_str}
                              </td>
                              <td>{productItem.total_qty}</td>
                              <td>
                                {checkAbility(
                                  PERMISSION_ACCESS.VIEW,
                                  PERMISSION_MODULE.DISPATCH_ORDER
                                ) ? (
                                  <Link
                                    to={`/dispatch-orders/view/${productItem.dispatch_order_id}`}
                                  >
                                    <ViewIcon />
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={3}>No Records Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="max-h-250">
            <Row>
              <Col md={8}>
                <h3 className="fs-20 fw-bold text-black mb-0">
                  MOQ (Min Order Qty) Alert
                </h3>
                <span className="fs-12 fs-italic text-dard-gray">
                  Goods reached minimum Reorder Level
                </span>
              </Col>
              <Col className="text-end">
                {checkAbility(
                  PERMISSION_ACCESS.LIST,
                  PERMISSION_MODULE.PRODUCT_MASTER
                ) && (
                  <Link
                    to="/product/minstock"
                    className="link-text text-decoration-none fs-12"
                  >
                    View All
                  </Link>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="border p-3 rounded-3 mt-2">
                  <Table className="table-custom">
                    <thead>
                      <tr>
                        <th>Products</th>
                        <th className="text-nowrap text-end">Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productAlertData?.minStockAlert?.length > 0 ? (
                        productAlertData.minStockAlert.map(
                          (productItem: MinStockAlert, index: any) => (
                            <tr key={`stockmin_${IndeterminateCheckBox}`}>
                              <td className="text-wrap-ellipsis" title={`${productItem.hs_sku} - ${productItem.name}`}>
                                {productItem.hs_sku} - {productItem.name}
                              </td>
                              <td className="text-end">{productItem.stock_qty}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={2}>No Records Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
