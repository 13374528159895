import { FC } from 'react';
import { Button, Form, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaInfoCircle } from 'react-icons/fa';

interface IQuill {
    controlId: string;
    label?: string;
    placeholder?: string;
    modules?: object;
    formats?: [];
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    name?: any;
    className?: string;
}

const defaultModules = {
    toolbar: {
        container: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            [{ align: ["right", "center", "justify"] }],
            ['clean'],
            [{ 'font': [] }],
            [{ 'size': [] }],
        ],
    },
};

const defaultFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color','background','align',
    'font', 'size'
];

const QuillEditor: FC<IQuill> = ({
    controlId, label, placeholder, modules = defaultModules, formats = defaultFormats, handleChange, handleBlur, errorsField, touched, value, name,className
}) => {

    const handleQuillChange = (content:any, delta: any, source: any, editor:any) => {
        if (handleChange) {
            handleChange(name, content, editor);
        }
    };

    const isInvalid =!!errorsField && touched;
    if(isInvalid){
        className = `${className} quill-custom-error is-invalid`;
    }

    return (
        <Form.Group className="mb-3 custom-from-group" controlId={controlId}>
            {label ? (
                <Form.Label className="lh-sm control-label fs-14 fw-bold">
                    {label}
                </Form.Label>
            ) : null}
            <div className={`position-relative`}>
                <ReactQuill 
                    theme="snow" 
                    modules={modules} 
                    formats={formats}
                    value={value}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    onChange={handleQuillChange}
                    className={className}
                ></ReactQuill>
                <Form.Control.Feedback type="invalid">
                    {errorsField}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default QuillEditor;
