import { Card } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import { getProjectDetails } from "../../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import ProjectReleventDetailsAndDates from "../../../Projects/View/relaventDetailDates";
import ProjectDocumentList from "../../../Projects/View/documentList";
import ProjectBasicDetail from "../../../Projects/View/basicDetail";
import ProjectPaymentDetail from "./paymentDetail";
import { PROJECT_PREFIX } from "../../../../constants/constants";
export const ViewFinanceProject = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [projectOwnerOpen, setProjectOwnerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState<any>({});
  const [projectControls, setProjectControl] = useState<any>({});

  useEffect(() => {
    setLoading(true);
    fetchProjectDetail(id).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (projectControls?.subsection) {
      const notSubmitData = projectControls?.subsection.find((control: any) => {
        return !control.value;
      });     
    }
  }, [projectControls]);

  const fetchProjectDetail = (id: any) => {
    return new Promise((resolve, reject) => {
      getProjectDetails(id)
        .then((response) => {
          setProjectData(response.data.project);
          getProjectControl(response.data.project);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/projects");
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
            navigate("/projects");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/projects");
          }
        })
        .finally(() => {
          setLoading(false);
          resolve(true);
        });
    });
  };

  const getProjectControl = (projectDataObj: any) => {
    const projectControls = projectDataObj?.project_page_control.find(
      (item: any) => item.key == "PROJECT_ONBOARDING"
    );   
    setProjectControl(projectControls);
  };
  

  const handleDetailReload = () => {
    fetchProjectDetail(id);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col className="col-md-12 col-xl-4">
                <h1 className="fw-bold h4 mt-2 mb-0">
                  {PROJECT_PREFIX}{projectData?.project_no}
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <div className="w-100 pageContentInner">
              <Row>
                <Col className="col-md-6 col-xl-3 project-box1">
                  <Card className=" p-3 w-100 pageContentInner mb-3 rounded-3">
                    {projectData && (
                      <ProjectBasicDetail id={id} projectsData={projectData} />
                    )}
                    <div className="d-flex fs-14 mb-0 justify-content-between">
                      Project Owner{" "}
                      <span className="fw-bold ">
                        {projectData?.project_owner_id
                          ? projectData?.projectOwner?.full_name
                          : "-"}
                      </span>
                    </div>
                  </Card>
                </Col>
                <Col className="col-md-6 col-xl-6 project-box2">
                  {projectData && (
                    <ProjectReleventDetailsAndDates
                      id={id}
                      projectsData={projectData}
                    />
                  )}
                </Col>
                <Col className="col-md-6 col-xl-3 project-box1">
                  <Card className=" p-3 w-100 pageContentInner mb-3">
                    <ProjectDocumentList
                      id={id}
                      viewType="F"
                      projectsData={projectData} 
                      onUploadDoc={handleDetailReload}
                    />
                  </Card>
                  <Card className=" p-3 w-100 pageContentInner mb-3">
                    {projectData && (
                      <ProjectPaymentDetail
                        id={id}
                        projectsData={projectData}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
              <div className="row"></div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
