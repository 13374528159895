import React from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Label, InputProps } from "reactstrap";

interface FormInputProps extends InputProps {
  name: string;
  label: string;
  error?: any;
  touched?: any;
  onChange?: any;
  isAsync?: boolean;
  isMulti?: boolean;
  formGroupClasses?: string;
  placeholder?: string;
  defaultFilter?: any;
  value?: any;
}

const FormAsyncSelect: React.FC<FormInputProps> = ({
  id,
  name,
  label,
  value,
  error,
  touched,
  isMulti,
  onChange,
  getOptions,
  options,
  formGroupClasses,
  placeholder,
  defaultFilter,
  isClearable = false,
  getOptionLabel = (row: any) => row.name,
  getOptionValue = (row: any) => row.id,
}) => {
  // const [value, setValue] = useState<any>("");

  async function loadOptions(
    search: any,
    loadedOptions: any,
    { page }: any,
    getOptions: any,
    defaultFilter: any,
  ) {
    const response = await getOptions({ search, page, pageSize: 10, ...defaultFilter });
    return {
      options: response.data.resultSet,
      hasMore: response.data.page < response.data.totalPages,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <div className={formGroupClasses || "mb-3"}>
      {label &&   <label
            className="lh-sm control-label fs-14 fw-bold form-label"
            htmlFor={name}
          
          >{label}</label>}

      <div>
      
      <AsyncPaginate
            className={`react-select ${touched && error ? "is-invalid" : ""}`}
            classNamePrefix={"react-select"}
            placeholder={placeholder}
            value={value}
            loadOptions={(search, loadedOptions, { page }: any) =>
              loadOptions(search, loadedOptions, { page }, getOptions, defaultFilter)
            }
            onChange={(value) => {
              onChange(name, value);
            }}
            additional={{
              page: 1,
            }}
            
            isClearable={isClearable}
            isMulti={isMulti}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
          />
      </div>

      {touched && error && <small className='text-danger fw-medium'>{error}</small>}
    </div>
  );
};

export default FormAsyncSelect;
