import { axios } from "./axios";
import {
  PROJECT_LIST,
  PROJECT_ASSIGN_PROJECT_OWNER,
  PROJECT_ASSIGN_TEAM,
  PROJECT_GET_POSTAL_CODES,
  PROJECT_DOCUMENT_DELETE,
  PROJECT_DOCUMENT_INFO_SAVE,
  PROJECT_DOCUMENT_LIST,
  PROJECT_DOCUMENT_UPLOAD,
  PROJECT_DETAILS,
  PROJECT_SURVEY_INITIATE,
  PROJECT_DELETE,
  PROJECT_UPDATE,
  PROJECT_DOWNLOADURL,
  PROJECT_QUICKUPDATE,
  PROJECT_PROCUREMENT_LIST,
  PROJECT_PROCUREMENT_POLIST,
  PROJECT_FINANCE_EXPORT,
  PROJECT_FINANCE_PAYMENT_MILESTONE,
  PROJECT_FINANCE_PAYMENT_RECIEVED,
  PROJECT_CONTROL_MARK_COMPLETE,
  UPDATE_REVIEW_COMPARATIVE_STUDY,
  GET_CONTRACT_PRODUCT,
  SAVE_COMPARATIVE_STUDY,
  GET_CONTRACT_PDF,
  UPLOAD_CONTRACT_PDF,
  AUDIT_TRIAL_DATA,
  PROJECT_PERSONALINFOUPDATE,
  GET_LABOUR_COST,
  SET_LABOUR_COST
   

} from "../constants/paths";
import { AxiosResponse } from "axios";

interface Milestone {
  contract_id: number;
  name: string;
  due_date: string;
  received_date: string | null;
  amount: string;
  payment_file: string;
  deleted_at: string;
  deletedAt: string;
  installation_continue_reason: string;
  installation_continue_status: string;
  xero_invoice_id: string;
  xero_updated_at: string;
}

export type createDealDTO = {
  order_no: string;
  name: string;
  contract_type: string[] | any;
  status: string[] | any;
  order_date: string;
  amount: string;
  address: string;
  postal_code: string[] | any;
  phone: string;
  remarks: string;
  contract_signee: string;
  contract_date: string;
  contract_file: string;
  payment_deposited_by: string;
  payment_date: string;
  paid_amount: string;
  project_type: string[] | any;
  payment_file: string;
  is_cdm_completed: string[] | any;  
  is_install_date_confirmed: string[] | any;
  is_interim_payment_ready: string[] | any;
  is_warranty_cert_posted: string[] | any;
  contract_posted_date: string;
  contract_received_date: string;
  survey_plan_date: string;
  survey_complete_date: string;
  planned_start_date: string;
  planned_completion_date: string;
  warranty_expiry_date: string;
  sales_rep: string[] | any;
};

export type ProjectType = {
  key: string;
  value: string;
};

export type AssignProjectOwnerDTO = {
  project_ids: number[];
  project_owner: any;
};

export const getProjectList = (formData: any): Promise<any> => {
  return axios.get(PROJECT_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const assingProjectOwnerApi = (
  data: AssignProjectOwnerDTO
): Promise<any> => {
  const transformData = {
    project_ids: data?.project_ids,
    project_owner_id: data?.project_owner?.key,
  };
  return axios.post(PROJECT_ASSIGN_PROJECT_OWNER, transformData);
};

export const assingTeamApi = (data: any, projectId: number): Promise<any> => {
  return axios.post(`${PROJECT_ASSIGN_TEAM}/${projectId}`, data.userids);
};

export const getProjectAssignTeamAPi = (projectId: number | any): Promise<any> => {
  return axios.get(`${PROJECT_ASSIGN_TEAM}/${projectId}`);
};

export const getProjectDetails = (projectId: string | number | null): Promise<any> => {
  return axios.get(`${PROJECT_DETAILS}/${projectId}`);
};

export const projectControlSectionMarkComplete = (controlKey: string,projectId: string | number | undefined): Promise<any> => {
  return axios.patch(`${PROJECT_CONTROL_MARK_COMPLETE}/${controlKey}/${projectId}`);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteProject = (id: string | number | null, remarks:any): Promise<any> => {
  const transformData:any = {
    archive_remarks: remarks
  }
  return axios.delete(PROJECT_DELETE + "/" + id, {data: transformData});
};

export const getProjectDocumentsAPi = (projectId: number | string): Promise<any> => {
  return axios.get(`${PROJECT_DOCUMENT_LIST}/${projectId}`);
};

export const saveProjectDocumentInfo = (
  projectId: number,
  docType: string,
  data: any
): Promise<any> => {
   const transformData: any = {
    ...data,    
  };

  if (transformData.documentType === "contract") {
    // Remove payment-related fields
    delete transformData.payment_deposited_by;
    delete transformData.payment_date;
    delete transformData.paid_amount;
    delete transformData.payment_file;
  } else if (transformData.documentType === "deposit") {
    // Remove contract-related fields
    delete transformData.contract_signee;
    delete transformData.contract_date;
    delete transformData.contract_file;
  }
  delete transformData.documentType;  

  return axios.post(
    `${PROJECT_DOCUMENT_INFO_SAVE}/${docType}/${projectId}`,
    transformData
  );
};

export const deleteDocument = (
  docType: string | null,
  projectId: string | number | null,
  docId: string | number | null
): Promise<any> => {
  return axios.delete(`${PROJECT_DOCUMENT_DELETE}/${docType}/${projectId}/${docId}`);
};

// get project documents 
export const getProjectUploadedSignedUrl = ( docType: string | null,
  projectId: string | number | null | undefined,
  docId: string | number | null): Promise<any> => {
  return axios.get(`${PROJECT_DOWNLOADURL}/${docType}/${projectId}/${docId}`);
};

export const uploadProjectDocApi = (
  docType: string | number | null,
  id: string | number | null,
  data: any
): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(
    `${PROJECT_DOCUMENT_UPLOAD}/${docType}/${id}`,
    data,
    config
  );
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateProjectApi = (
  id: string,
  data: createDealDTO
): Promise<any> => {
  const optionalFields = [    
    "completion_payment_collected",    
  ]
  const transformData: any = {
    ...data,
    project_type_id: data.project_type?.key,
    postal_code_id: data.postal_code?.id,
    contract_type: data.contract_type?.key,
    is_cdm_completed: data.is_cdm_completed?.key,
    is_install_date_confirmed: data.is_install_date_confirmed?.key,
    is_interim_payment_ready: data.is_interim_payment_ready?.key,
    is_warranty_cert_posted: data.is_warranty_cert_posted?.key,
    sales_rep_id: data.sales_rep?.id,
    status: data.status?.key,
  };

  optionalFields.forEach(fieldName => {
    if(!transformData[fieldName]){
      transformData[fieldName] = null;
    }
    
  });

  delete transformData.sales_rep;
  delete transformData.contract_date;
  delete transformData.postal_code;
  delete transformData.project_type;
  delete transformData.completion_payment_collected;
  delete transformData.scheduled_days_to_complete;
  delete transformData.day_since_order_date;
  return axios.put(PROJECT_UPDATE + "/" + id, transformData);
};
/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const quickUpdateProjectApi = (
  id: string,
  data: createDealDTO
): Promise<any> => {  
  const transformData: any = {
    ...data,    
  };
  return axios.put(PROJECT_QUICKUPDATE + "/" + id, transformData);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateProjectCustomerInfoApi = (
  id: string,
  data: createDealDTO
): Promise<any> => {  
  const transformData: any = {
    ...data,    
  };
  return axios.put(PROJECT_PERSONALINFOUPDATE + "/" + id, transformData);
};

export const getProjectProcurementList = (projectId:string | number | undefined, formData: any): Promise<any> => {
  return axios.get(`${PROJECT_PROCUREMENT_LIST}/${projectId}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const getProjectProcurementPoList = (projectId:string | number | undefined,formData: any): Promise<any> => {
  return axios.get(`${PROJECT_PROCUREMENT_POLIST}/${projectId}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const getPostalCodeList = (
  data: AssignProjectOwnerDTO
): Promise<any> => {
  const transformData = {
    project_ids: data?.project_ids,
  };
  return axios.post(PROJECT_GET_POSTAL_CODES, transformData);
};

export const initiateSurvey = (
  projectId: number,
  data: any
): Promise<any> => {
   const transformData: any = {
    ...data,   
    template_id : data?.template_id?.id
  };

  return axios.post(
    `${PROJECT_SURVEY_INITIATE}/${projectId}`,
    transformData
  );
};

export const exportFinanceProjectApi = (params:any) : Promise<any> => {
  return axios.get(PROJECT_FINANCE_EXPORT,{params,responseType: 'arraybuffer',});
}

export const getProjectPaymentMilestoneAPi = (projectId: number | string | undefined): Promise<any> => {
  return axios.get(`${PROJECT_FINANCE_PAYMENT_MILESTONE}/${projectId}`);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updatePaymentMilestoneApi = (id: string, data:{milestones: Milestone[]}): Promise<any> => {
  const milestones = data.milestones.map(({ contract_id,received_date,payment_file,deleted_at,deletedAt,installation_continue_reason,installation_continue_status,xero_invoice_id,xero_updated_at,  ...rest }) => ({
    ...rest,    
  }));
  const transformData = {
    ...data,   
    milestones
     
  };
  return axios.put(PROJECT_FINANCE_PAYMENT_MILESTONE + "/" + id, transformData);
};

export const recievePaymentMilestoneApi = (projectId: string,milestoneId: string, data:any): Promise<any> => {
  
  const transformData = {
    ...data,
  };
  delete transformData.filename;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${PROJECT_FINANCE_PAYMENT_RECIEVED}/${projectId}/${milestoneId}`, transformData, config);
};


//update review comparative page as done mark

export const updateReviewComparative = (project_id : string|undefined|any): Promise<any> => {
  return axios.patch(`${UPDATE_REVIEW_COMPARATIVE_STUDY}/${project_id}`);
};

//get products items by contract id

export const getContractProjectItems = (contract_id : string|undefined|any): Promise<AxiosResponse> => {
  return axios.get(`${GET_CONTRACT_PRODUCT}/${contract_id}`);
}

//comparative stude save 
export const saveComparativeStudyApi = (project_id : string|undefined|any, data:any): Promise<any> => {  

  return axios.post(`${SAVE_COMPARATIVE_STUDY}/${project_id}`, data);
}

 //GET_CONTRACT_PDF by contract id
export const getContractFile = (contract_id : string|undefined|any): Promise<AxiosResponse> => {
  return axios.get(`${GET_CONTRACT_PDF}/${contract_id}`);
}

//update or revise contract pdf
 
export const uploadContractRevised = (contract_id : string|undefined|any, data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${UPLOAD_CONTRACT_PDF}/${contract_id}`, data, config);
};

//get audit trial data 
export const getAuditTrialData = (project_id : string|undefined|any): Promise<any> => {
  return axios.get(`${AUDIT_TRIAL_DATA}/${project_id}`);
};

export const markProjectControlSurveyComplete = (projectId: string | number | undefined): Promise<any> => {
  return axios.patch(`${PROJECT_CONTROL_MARK_COMPLETE}/${projectId}`);
}

//get Labour cost 
export const getLabourCostApi = (): Promise<any> => {
  return axios.get(`${GET_LABOUR_COST}`);
};

export const setLabourCostApi = (data:any): Promise<any> => {
  return axios.post(`${SET_LABOUR_COST}`,data);
}
