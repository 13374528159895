import { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FormAsyncSelect from "../../components/common/AsyncSelect/FormAsyncSelect";
import { Formik } from "formik";
import FormSelect from "../../components/common/FormSelect";
import { toast } from "react-toastify";
import {    
  getProductListAsync,
} from "../../services/warehouseService";
import {  
  getAllVendorsName,
} from "../../services/vendorService";
import { exportProductData, getAllProductCategory } from "../../services/productService";
import InputCurrencyText from "../../components/common/InputCurrencyText";

interface ExportDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  exportType: string;
  text: string;
}

interface ProjectOption {
  id: string;
  vendor_name: string;
  contact_person: string;
}

const ExportProductData: FC<ExportDataPopupProps> = ({
  isOpen,
  onClose,
  label,
  text,
  exportType,
}) => {
  const [vendorOption, setVendorsOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([fetchCategories(), fetchData()]).then(() => {});
  }, [isOpen]);  

  const fetchData = () => {
    setLoading(true);
    getAllVendorsName()
      .then((response) => {
        const modifiyOption = response.data.map((item: ProjectOption) => ({
          key: item.id,
          value: item.vendor_name,
        }));
        setVendorsOption(modifiyOption);
        const modifiyContactPersonOption = response.data.map(
          (item: ProjectOption) => ({
            key: item.id,
            value: item.contact_person,
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onClose();
      });
  };

  const fetchCategories = async () => {
    return new Promise((resolve, reject) => {
      getAllProductCategory().then((response) => {
        const modifiyOption = response.data.map((item: any) => ({
          key: item.category,
          value: item.category,
        }));
        setCategoryOption(modifiyOption);
        return resolve(true);
      });
    });
  };

  const handleSubmit = (values: any) => {
    const transformedValues = {
      ...values,
      vendor_id: values.vendor_name?.key,      
      id: values.product_id?.id,
      category: values.category?.key,
      status: values.status?.key,
      //  order_date:orderDateRange
    };

    delete transformedValues.product_id;
    delete transformedValues.inspection_date_arr;
    delete transformedValues.received_date_arr;
    delete transformedValues.vendor_name;

    //  console.log(transformedValues);
    //  return;
    exportProductData(transformedValues)
      .then((response) => {
        // Create a Blob object from the response data
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()}_product_data.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
        onClose();
      })
      .catch((error) => {
        if (error.response.status == " 404") {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`No records available for export.`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const getProductsList = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
      };
      //console.log(requestParam);
      getProductListAsync(requestParam)
        .then((res) => {          
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          vendor_name: null,          
          category: null,
          product_id: null,
          stock_qty: "",
          price: "",
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            className="export-excel-modal"
            show={isOpen}
            onHide={onClose}
            centered
          >
            <Form className="" noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <div className="fs-24 fw-bolder mb-3 info-text">
                  Export to Excel
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <FormAsyncSelect
                      id="productSelect"
                      name="product_id"
                      placeholder="All"
                      label="SKU / Product Name"
                      isAsync
                      isClearable                     
                      getOptions={getProductsList}
                      value={values.product_id}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="All"
                      label="Category Type"
                      name="category"
                      options={categoryOption}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.category}
                      error={errors.category}
                      touched={touched.category}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="All"
                      label="Vendor"
                      name="vendor_name"
                      options={vendorOption}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.vendor_name}
                      error={errors.vendor_name}
                      touched={touched.vendor_name}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputCurrencyText
                      name="stock_qty"
                      label="Available Quantity"
                      placeholder="Enter Qty."
                      value={values.stock_qty}
                      onChange={(value, name) => {
                        setFieldValue("stock_qty", value);
                      }}
                      isInteger={true}                     
                    />
                  </div>
                  <div className="col-md-6">
                    <InputCurrencyText
                      name="stock_qty"
                      label="Price"
                      placeholder="Enter Price"
                      value={values.price}
                      onChange={(value, name) => {
                        setFieldValue("price", value);
                      }}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                <button
                  className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <Button
                  type="submit"
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Export
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ExportProductData;
