import { Route, Routes } from 'react-router-dom';
import { AddInternalTicket } from './Add';
import { ViewInternalTicket } from './View';
import { checkAbility } from '../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../constants/permissionUser';
import AllInternalTickets from './index';

export const InternalTickets = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.INTERNAL) && (
                <Route path="/" element={<AllInternalTickets />} />
            )}
            {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.INTERNAL) && (
                <Route path="/Add" element={<AddInternalTicket />} />
            )}
            {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.INTERNAL) && (
                <Route path="/edit/:id" element={<AddInternalTicket />} />
            )}                                 
            <Route path="/view/:id" element={<ViewInternalTicket />} />
        </Routes>
    )
};