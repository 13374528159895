import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function BarChartSkeleton({numbers=25,heigth=200}) {
  return (
    <Stack spacing={1}>
      <div className="d-flex justify-content-between  ">
        <div className=" barChartSkeleton d-flex justify-content-around" style={{height:heigth}}>
        {Array.from({ length: numbers }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              width={15}
              height={Math.floor(Math.random() * 150) + 70} // Random height for demonstration
            />
          ))}
        </div>
         
      </div>
    </Stack>
  );
}
