import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "../src/features/store";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import Loader from "./components/common/Loader";
import ability from './role-manager/ability'; // Import the Ability instance
import { AbilityContext } from "./role-manager/can";



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const baseUrl: any = process.env.REACT_APP_BASE_NAME;

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <React.Suspense fallback={<Loader isLoading={true} />}>
    <AbilityContext.Provider value={ability}>
      <Router basename={baseUrl}>
        <App />
      </Router>
      </AbilityContext.Provider>
    </React.Suspense>
  
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
