import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridCallbackDetails,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../router/protected";

import Loader from "../../components/common/Loader";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import AddCircle from "../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";

import { getRolesList } from "../../services/roleService";
import {
  deletePostalCodeApi,
  getPostalcodeList,
} from "../../services/postalcode.service";
import ability from "../../role-manager/ability";
import { Can } from "../../role-manager/can";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import constants, {
  LeaveStatus,
  pageSizeModel,
} from "../../constants/constants";
import moment from "moment";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewIcon from "@mui/icons-material/Visibility";
import {
  deleteHolidayApi,
  getHolidayList,
} from "../../services/holidaysService";
import {
  deleteLibraryApi,
  getLibraryList,
} from "../../services/libraryService";
import { getEmployeeLeaveListByHr, updateLeaveStatusApi } from "../../services/leavesService";
import DeleteWithReasonPopup from "../../components/common/deleteWithReasonPopup";
import ApproveLeave from "./View/approveLeave";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../features/Auth/authSlice";

interface RoleOption {
  key: string;
  value: string;
}
interface IPostalCode {
  id?: number;
  code?: string;
  createdAt: string;
  updatedAt: string;
  postalCodeUsers: any;
}
const LeavesList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("date_from");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [deletedId, setDeletedId] = useState<GridRowId | null | any>(null);
  const [editableData, setEditableData] = useState<IPostalCode | null>();
  const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);

  const [isViewPopupOpen, setViewPopupOpen] = useState(false);
  const {user }:any = useSelector(selectAuthentication);
  const userId = user.user.id;

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isApprovePopupOpen, setApprovePopupOpen] = useState(false);
  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [approveType, setApproveType] = useState("");

  const handleViewClick = async (id: GridRowId, empId: any) => {
    navigate(`/leaves/view/${id}/${empId}`);
  };

  const handleRejectClick = (id: GridRowId) => {
    setRejectPopupOpen(true);
    setApproveType('reject');
    setDeletedId(id);
  };
  const handleApproveClick = (id: GridRowId) => {
    setRejectPopupOpen(true);
    setApproveType('approve');
    setDeletedId(id);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const handleClose = (isReload?: boolean) => {    
    setRejectPopupOpen(false);    
    setDeletedId(null);
    console.log(isReload);
    if(isReload){
      setIsMutation(!isMutation);
    }
  };

  const fetchData = (defaultParams: any) => {
    getEmployeeLeaveListByHr(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  type UserDetails = {
    full_name: string;
    id: number;
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "$user.full_name$",
      headerName: "Employee Name",
      flex: 1,
      sortable: true,
      hideable: false,
      renderCell(params) {
        return (
          <span title={params.row.user.full_name}>
            {params.row.user.full_name}
          </span>
        );
      },
    },
    {
      field: "$leaveType.name$",
      headerName: "Leave Type",
      flex: 1,
      sortable: true,
      hideable: false,
      renderCell(params) {
        const orderDate = new Date(params.row.date_from);
        return (
          <span title={params.row.leaveType.name}>
            {params.row.leaveType.name}
          </span>
        );
      },
    },
    {
      field: "date_applied",
      headerName: "Date Applied",
      type: "date",
      flex: 0.5,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date_applied),
      renderCell(params) {
        const orderDate = new Date(params.row.date_applied);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "date_from",
      headerName: "From",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date_from),
      renderCell(params) {
        const orderDate = new Date(params.row.date_from);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)} { params.row.is_appointment_leave && (`(${params.row.appointment_time})`)}
          </span>
        );
      },
    },
    {
      field: "date_to",
      headerName: "To",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date_to),
      renderCell(params) {
        const orderDate = new Date(params.row.date_to);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)} { params.row.is_appointment_leave && (`(${moment(params.row.appointment_time, "HH:mm:ss").add('2', 'hours').format('HH:mm:ss')})`)}
          </span>
        );
      },
    },
    {
      field: "leaves_count",
      headerName: "No. of Days",
      type: "number",
      flex: 1,
      sortable: true,
      renderCell(params) {        
        return params.row.leaves_count == '0.5' ?  `${params.row.leaves_count} (${params.row.session == '1' ? 'AM' : 'PM'})`  : params.row.leaves_count;
      },
    },    
    {
      field: "leaves_remaining",
      headerName: "Days Remaining",
      type: "number",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      valueOptions: LeaveStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        if (params.row.status) {
          const statusName = LeaveStatus.find(
            (item) => item.key == params.row.status
          );
          return (
            <span
              title={statusName?.value}
              className={`py-1 px-2 ms-0 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
            >
              {statusName?.value}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.LEAVES
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.LEAVES
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.LEAVES
    );

    if (
      checkEditAbilityCondition ||
      checkDeleteAbilityCondition ||
      checkViewAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        headerAlign: 'left',
        cellClassName: "actions justify-content-between",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if (checkEditAbilityCondition && row.user.id != userId) {
            if (row.status == "pending") {
              gridActions.push(
                <GridActionsCellItem
                  label="Reject"
                  onClick={(event) => handleRejectClick(id)}
                  showInMenu={false}
                  className="text-danger"
                  icon={<CancelIcon />}
                />,
                <GridActionsCellItem
                  label="Approve"
                  onClick={(event) => handleApproveClick(id)}
                  showInMenu={false}
                  className="text-primary"
                  icon={<CheckedIcon />}
                />
              );
            }
          }
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View Leave"
                onClick={(event) => handleViewClick(id, row.user.id)}
                showInMenu={false}
                className="text-primary"
                icon={<ViewIcon />}
              />
            );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const moveToLeaveHistory = () => {
    navigate("/leaves/history");
  };

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Leaves</h1>
              </div>
              <div className="d-flex align-items-center p-2 bg-white rounded-2">
                <div className="surver-tab active">Applied Leave</div>
                <div className="surver-tab" onClick={moveToLeaveHistory}>
                  Leave History{" "}
                </div>
              </div>
              <div>&nbsp;</div>
            </div>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" border-0 w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  pageSizeOptions={pageSizeModel}
                  sortingMode="server"
                  disableRowSelectionOnClick
                />
                
                { isRejectPopupOpen && (<ApproveLeave
                  isOpen={isRejectPopupOpen}
                  onClose={handleClose}            
                  leaveId={deletedId}
                  approveType={approveType}
                />) }

                

              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LeavesList;
