import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {  
  getProjectDocumentsAPi,
  getProjectUploadedSignedUrl,
} from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../Loader";
import DescriptionIcon from '@mui/icons-material/Description';
import DepositePaid from "../../../assets/images/deposit-paid-new.svg";
import ContactComplicatd from "../../../assets/images/contact-complicatd-new.svg";
import FileUploadIcon from "../../../assets/images/download-green.svg";



interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  projectsData: string[] | any;
}

const ProjectDocumentListPopup: React.FC<AddProps> = ({
  isOpen,
  onClose,
  projectsData,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      return new Promise((resolve, reject) => {
        getProjectDocumentsAPi(projectsData?.id)
          .then((response) => {
            const uploadedDoc = response.data.filter((option: any) =>
              option.isFileExists
            );         
            setSelectedDocuments(uploadedDoc);
            setLoading(false);
            resolve(true);
          })
          .catch((error) => {
            setLoading(false);
            reject(error);
          });
      });
    });
  };

  /**
   * Download Document click handler
   */
  const handleDownloadClick = (docType: string, docId: any = null) => {
    getProjectUploadedSignedUrl(docType, projectsData?.id, docId)
      .then((response: any) => {
        const signedFileUrl = response.data.file;
        // window.open(signedFileUrl, '_blank');
        window.open(signedFileUrl, "_blank");
        //console.log(signedFileUrl);
      })
      .catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {});
  };

  const getExtension = (fileName: string) => {
    const extension:any = fileName.split('.').pop()?.toLowerCase();  
    if (extension === 'pdf') {
      return <img
      src={ContactComplicatd}
      alt="Icon"
      className="me-2"
    />
    } else if (['png', 'jpeg', 'jpg', 'svg'].includes(extension)) {
      return <img
      src={DepositePaid}
      alt="Icon"
      className="me-2"
    />
    } else {
      return <DescriptionIcon className="me-2 ms-0 py-1 rounded-1 Onboarding-tag" />;
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="export-excel-modal project-owner-modal"
          aria-labelledby="Assign modal owner"
          centered
          show={isOpen}
          onHide={onClose}
        >
          <Modal.Body className="position-relative project-assign-modal pb-0">
            <button
              type="button"
              className="btn-close text-right  btn-option-cover"
              aria-label="Close"
              onClick={onClose}
            ></button>
            <h4 className="fw-bold info-text mb-3">
              Documents For {projectsData.project_no_str}
            </h4>            
            {
            selectedDocuments.length > 0 ? 
            selectedDocuments.map((document: any, index: any) => (
              <>
                <Row
                  key={`document_row_${index}`}>
                  {document?.isFileExists && (
                    <Col>
                      <div
                        key={`document${index}`}
                        className="d-flex fs-14 mb-2 justify-content-between pb-2"
                      >
                        <div className="">
                          <span className="me-2">
                          {getExtension(document.file)}
                          </span>
                          <span>{document.label}</span>
                        </div>
                        <button
                          type="button"
                          title="Download Document"
                          className="fw-bold bg-white border-0 p-0"
                          onClick={() =>
                            handleDownloadClick(document.key, document?.id)
                          }
                        >
                          <img
                                      src={FileUploadIcon}
                                      className="ms-3"
                                    />
                        </button>
                      </div>
                    </Col>
                  )}
                </Row>
              </>
            )) : 
            <Row>
              <Col>
                  <span className="mt-2 mb-2">No Uploaded Document found</span>
              </Col>
            </Row>          
          }
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center mb-3 pt-0"> </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default ProjectDocumentListPopup;
