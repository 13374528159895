import react, { FC, useEffect, useState } from "react";
import { Card, Row, Table } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { getDelayMeetingList } from "../../services/hrdashboard.service";
import { useNavigate } from "react-router-dom";
import { MEETING_TYPES } from "../../constants/constants";
import TableSkeleton from "../../components/common/Skeleton/Table.skeleton";
import moment from "moment";


const MeetingDelayList = () => {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, []);
  const fetchData =(signal: any) =>{
    getDelayMeetingList(signal).then((res)=>{
        setListData(res.data);

    }).catch((err)=>{

    }).finally(()=>{
        setLoading(false)
    })
  }
  const handleRedirect = () =>{
    navigate('/hr-dashboard/delay-meeting')
  }
  return (
    <>
      <Card  className="p-3 w-100 pageContentInner rounded-2">
        {loading ? (
          <TableSkeleton/>
        ) : (
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="fs-18 info-text fw-bold">Meeting Status </h2>
                {listData.length > 3 && <p onClick={handleRedirect} className="link-text fw-bold"> View all <MdKeyboardArrowRight />
                    </p>}
                
            </div>
            <div>
            {listData.length > 0 ?
                        <Table className="p-3 dash-custom-table">
                           
                <thead>
                    <tr>
                     
                    <th>Employee Name</th>
                    <th>Reporting Manager</th>
                    <th>Meeting Type</th>
                    <th>Meeting Date</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {listData.length > 0 && listData.map((item: any, index : number)=>{
                        if(index<=5){
                            return <>
                            <tr>
                        <td>{item?.employee?.full_name}</td>
                        <td>{item?.employee?.employeeDetails?.manager?.full_name}</td>
                        <td>{item?.type}</td>
                        <td>{moment(item?.date).format('DD/MM/YYYY')}</td>
                        <td><span className="asset-status-rejected">Delay</span></td>
                        </tr>
                            </>  
                        }
                                  
                    })}
                     
                </tbody>
                </Table> : 
                <div className="text-center">No Delay Meeting Found</div>}
            </div>
          </div>
        )}
      </Card>
    </>
  );
};
export default MeetingDelayList;
