import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,    
  MINIMUM_3_CHAR,
  INVALID_DATE,
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";
export const addSchema = yup.object({
  title: yup
    .string()
    .trim()    
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .required()
    .label("Title"),
  description: yup.string().trim().required().label("Description"),
  file: yup.string().optional().label("Document")
});
