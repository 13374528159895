import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  VALID_EMAIL,
  INVALID_DATE,
} from "../../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../../validations/regex";

const commonSchema = yup.object({
  due_date: yup.date().required().typeError(INVALID_DATE).label("Due Date"),
  vendor_id: yup.object().required().label("Vendor"),
  status: yup.object().required().label("Current Status"),
  ref_number: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Reference Number"),
  orderProducts: yup
    .array()
    .min(1, "Please add atleast one product")
    .required(),
  bill_address: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Billing Address"),
  shipping_address: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Shipping Address"),
});

const poSchema = (context: string | undefined | null) => {
  if (context) {
    // If it's an edit context, add/edit specific validation rules
    return commonSchema.shape({
      // Additional validation rules for edit
      // For example, you might not require certain fields in edit
      pay_status:yup.object().required().label("Payment Status"),
      // Add other edit-specific fields here
    });
  }

  // If it's an add context, return the common schema
  return commonSchema;
};

export default poSchema;
