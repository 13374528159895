import react, { FC, useEffect, useState } from "react";
import { Card, Row, Table } from "react-bootstrap";
import { DateRangeInterface } from "../Dashboard/dashboardInterface";
import {
  getAssetCounts,
  getLeaveAndTenureData,
} from "../../services/hrdashboard.service";
import { Link } from "react-router-dom";
import CountLabelSkeleton from "../../components/common/Skeleton/CountLabel.skeleton";
type AssetData = {
  pendingAssetProcurementCount?: number | string;
  availableAssets?: number | string;
};
type LeaveData = {
  pendingLeave?: number | string;
  avgTenure?: number | string;
};
const AssetAndLeaveCount: React.FC<DateRangeInterface> = ({
  orderDateRange,
}) => {
  const [loading, setLoading] = useState(true);
  const [assetData, setAssetData] = useState<AssetData>({});
  const [leaveData, setLeaveData] = useState<LeaveData>({});
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    fetchLeaveData(signal);
    return () => {
      controller.abort();
    };
    
  }, []);
  const fetchData = (signal: any) => {
    getAssetCounts(signal)
      .then((res) => {
        setAssetData(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };
  const fetchLeaveData = (signal: any) => {
    getLeaveAndTenureData(orderDateRange, signal)
      .then((res) => {
        setLeaveData(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="">
      {loading ? (
        <CountLabelSkeleton />
      ) : (
        <>
          <Card className="mb-2 p-4 rounded-3">
            <div className="d-flex align-items-cetner justify-content-between">
              <p className="fs-14">Pending Procurement Request : </p>
              <Link
                to="/assets"
                className="link-text fw-bold text-decoration-none"
              >
                {assetData?.pendingAssetProcurementCount ?? "N/A"}
              </Link>
            </div>
            <div className="d-flex align-items-cetner justify-content-between">
              <p className="mb-0 fs-14">Available Assets : </p>
              <Link
                to="/assets"
                className="link-text fw-bold text-decoration-none"
              >
                {assetData?.availableAssets ?? "N/A"}
              </Link>
            </div>
          </Card>
          <Card className="p-4 rounded-3">
            <div className="d-flex align-items-cetner justify-content-between">
              <p className="fs-14">Pending Leave Requests : </p>
              <Link
                to="/leaves"
                className="link-text fw-bold text-decoration-none"
              >
                {leaveData?.pendingLeave}
              </Link>
            </div>
            <div className="d-flex align-items-cetner justify-content-between">
              <p className="mb-0 fs-14"> Average Employee Tenure :</p>
              <b className=" fw-bold text-decoration-none">
                {leaveData?.avgTenure}
              </b>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};
export default AssetAndLeaveCount;
