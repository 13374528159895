import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { Image } from "react-bootstrap";
import HomeIcon1 from "../../assets/images/Admin.svg";
import HomeIcon2 from "../../assets/images/Project.svg";
import HomeIcon3 from "../../assets/images/warehouse.svg";
import HomeIcon4 from "../../assets/images/HR.svg";
import HomeIcon5 from "../../assets/images/Finance.svg";
import HomeIcon6 from "../../assets/images/Installer.svg";

import HotsportLogo from "../../assets/images/hotsport-logo.svg";
import XeroLogo from "../../assets/images/xero-logo.svg";
import DextLogo from "../../assets/images/dext-logo.svg";
import { HeaderLandingPage } from "../../components/layouts/Header/HeaderLandingPage";
import { useSelector } from "react-redux";
import {
  selectAuthentication,
  selectedTile,
} from "../../features/Auth/authSlice";
import storage from "../../helpers/storage";
import { useDispatch } from "react-redux";
import { titleRoles } from "../../constants/constants";

interface IAuthSelector {
  user: any;
}

export const LandingPage = () => {
  const { user }: IAuthSelector = useSelector(selectAuthentication);
  const department = user?.user?.employeeDetails?.department ? user?.user?.employeeDetails?.department : '-';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDesignation , setUserDesignation] = useState<string | null>(null)
  useEffect(() => {
    if (storage.getToken() == null) {
      navigate("/");
    }
    setUserDesignation(user.user?.employeeDetails?.designation)


  }, []);

  const getImage = (title: string) => {
    switch (title) {
      case "HR":
        {
          return {
            icon: HomeIcon4,
            title: "HR",
          };
        }
        break;
      case "WAREHOUSE":
        {
          return {
            icon: HomeIcon3,
            title: "Warehouse Manager",
          };
        }
        break;
      case "Surveyor":
        {
          return {
            icon: HomeIcon2,
            title: "Surveyor",
          };
        }
        break;
      case "FINANCE":
        {
          return {
            icon: HomeIcon5,
            title: "Finance",
          };
        }
        break;
      case "INSTALLATION_MANAGER":
        {
          return {
            icon: HomeIcon6,
            title: "Installer",
          };
        }
        break;
      case "PROJECT_MANAGER":
        {
          return {
            icon: HomeIcon2,
            title: "Project Management",
          };
        }
      default:
        {
          return {
            icon: HomeIcon1,
            title: "Administrator",
          };
        }
        break;
    }
  };

  const handleRedirection = (item:string) =>{
    switch(item){
      case "HR":
        {
          return "/hr-dashboard"
        }
        break;
      case "WAREHOUSE":
        {
          return "/warehouse"
        }
        break;
      
      case "FINANCE":
        {
          return "/finance/dashboard"
        }
        break;
      case "INSTALLATION_MANAGER":
        {
          return "/installation"
        }
        break;
      case "PROJECT_MANAGER":
        {
          return "/dashboard-master"
        }
      default:
        {
          return "/dashboard"
        }
        break; 
    }
  }
  const changeTile = (tile: string) => {
    console.log("tile", tile);

    //set title in the store with selectedTile  by use of dispatch
    //set in local storage
    localStorage.setItem("selectedTile", tile);
    dispatch(selectedTile(tile));
  };
  return (
    <div className="d-flex flex-column  pageContainer px-sm-1 w-100 pb-sm-1 h-100 ">
      <HeaderLandingPage />
      {userDesignation &&  <div className="text-end m-3">
        <Link to="/expenses/log-expenses" className=" mt-3 fw-semibold fs-12 text-white  btn btn-primary text-end">
        Log Expenses
        </Link>
        
      </div>}
       
      <div className="flex-grow-1 px-3 pb-3 overflow-auto pageContent ">
        <div className="pageHeader px-3 py-2 my-1">
          <Row className="align-items-center">
            <Col>
              <h1 className="fw-semibold h4 my-2 text-center">
                Choose The Role
              </h1>
            </Col>
          </Row>
        </div>

          <Row className=" text-center justify-content-center">
           {user &&
            user.mainTiles &&
            user.mainTiles.length > 0 &&
            user.mainTiles.map((item: string, index: number) => {
              return (
                <Col className="col-md-3 col-xl-2 col mb-3">
                  <Link
                    to={handleRedirection(item)}
                    onClick={() => changeTile(item)}
                    className="text-decoration-none h-100"
                  >
                    <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                      <div className="mb-3">
                        <Image
                          className="img-fluid text-blue"
                          src={getImage(item).icon}
                          alt="Logo"
                        />
                      </div>
                      <div className="d-flex align-items-center min-h-50">
                        <h5 className="fw-bold mb-0">{getImage(item).title}</h5>
                      </div>
                    </Card>
                  </Link>
                </Col>
              );
            })}

          {/* {department === "Operations" &&  (
             <Col className="col-md-3 col-xl-2 col mb-3">
             <Link
               to="/dashboard"
               onClick={() => changeTile(titleRoles.A)}
               className="text-decoration-none h-100"
             >
               <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                 <div className="mb-3">
                   <Image
                     className="img-fluid text-blue"
                     src={HomeIcon1}
                     alt="Logo"
                   />
                 </div>
                 <div className="d-flex align-items-center min-h-50">
                   <h5 className="fw-bold mb-0">Administrator</h5>
                 </div>
               </Card>
             </Link>
           </Col>
          )} */}
          {user && !user.mainTiles && (
            <>
              <Col className="col-md-3 col-xl-2 col mb-3">
                <Link
                  to="/dashboard-master"
                  onClick={() => changeTile(titleRoles.A)}
                  className="text-decoration-none h-100"
                >
                  <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                    <div className="mb-3">
                      <Image
                        className="img-fluid text-blue"
                        src={HomeIcon1}
                        alt="Logo"
                      />
                    </div>
                    <div className="d-flex align-items-center min-h-50">
                      <h5 className="fw-bold mb-0">Administrator</h5>
                    </div>
                  </Card>
                </Link>
              </Col>
              <Col className="col-md-3 col-xl-2 col mb-3">
                <Link
                  to="/dashboard-master"
                  onClick={() => changeTile(titleRoles.PM)}
                  className="text-decoration-none h-100"
                >
                  <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                    <div className="mb-3">
                      <Image
                        className="img-fluid text-blue"
                        src={HomeIcon2}
                        alt="Logo"
                      />
                    </div>
                    <div className="d-flex align-items-center min-h-50">
                      <h5 className="fw-bold mb-0">Project Management</h5>
                    </div>
                  </Card>
                </Link>
              </Col>
              <Col className="col-md-3 col-xl-2 col mb-3">
                <Link
                  to="/warehouse"
                  onClick={() => changeTile(titleRoles.WM)}
                  className="text-decoration-none h-100"
                >
                  <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                    <div className="mb-3">
                      <Image
                        className="img-fluid text-blue"
                        src={HomeIcon3}
                        alt="Logo"
                      />
                    </div>
                    <div className="d-flex align-items-center min-h-50">
                      <h5 className="fw-bold mb-0">
                        Warehouse
                        <br /> Manager
                      </h5>
                    </div>
                  </Card>
                </Link>
              </Col>
              <Col className="col-md-3 col-xl-2 col mb-3">
                <Link
                  to="/hr-dashboard"
                  onClick={() => changeTile(titleRoles.HR)}
                  className="text-decoration-none h-100"
                >
                  <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                    <div className="mb-3">
                      <Image
                        className="img-fluid text-blue"
                        src={HomeIcon4}
                        alt="Logo"
                      />
                    </div>
                    <div className="d-flex align-items-center min-h-50">
                      <h5 className="fw-bold mb-0">HR</h5>
                    </div>
                  </Card>
                </Link>
              </Col>
              <Col className="col-md-3 col-xl-2 col mb-3">
                <Link
                  to="/finance/dashboard"                 
                  onClick={() => changeTile(titleRoles.FM)}
                  className="text-decoration-none h-100"
                >
                  <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                    <div className="mb-3">
                      <Image
                        className="img-fluid text-blue"
                        src={HomeIcon5}
                        alt="Logo"
                      />
                    </div>
                    <div className="d-flex align-items-center min-h-50">
                      <h5 className="fw-bold mb-0">Finance</h5>
                    </div>
                  </Card>
                </Link>
              </Col>
              <Col className="col-md-3 col-xl-2 col mb-3">
                <Link
                  to="/installation"
                  onClick={() => changeTile(titleRoles.IM)}
                  className="text-decoration-none h-100"
                >
                  <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                    <div className="mb-3">
                      <Image
                        className="img-fluid text-blue"
                        src={HomeIcon6}
                        alt="Logo"
                      />
                    </div>
                    <div className="d-flex align-items-center min-h-50">
                      <h5 className="fw-bold mb-0">Installer</h5>
                    </div>
                  </Card>
                </Link>
              </Col>
            </>
          )}
        </Row>

        {user.useFullLinks && user.useFullLinks.length > 0 && (
          <div className="pageHeader px-3 py-2 my-1 mt-4">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-semibold h4 my-2 text-center">
                  Other Useful Links
                </h1>
              </Col>
            </Row>
          </div>
        )}

        <Row className="text-center justify-content-center">
          {user.useFullLinks &&
            user.useFullLinks.length > 0 &&
            user.useFullLinks.map((item: any, index: number) => {
              return (
                <Col className="col-md-3 col-xl-2 col mb-3 col mt-4">
                  <a
                    href={`${item.site_url}`}
                    className="text-decoration-none h-100"
                    target="_blank"
                  >
                    <Card className="d-flex align-items-center h-100 p-3 py-5 w-100 pageContentInner rounded text-center">
                      <div className="mb-3 min-h-88 d-flex customIcon">
                        <Image
                          className="img-fluid text-blue"
                          src={item.site_icon}
                          alt={item.side_name}
                          loading="lazy"
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <h5 className="fw-bold mb-0">{item.site_name}</h5>
                      </div>
                    </Card>
                  </a>
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
};
