import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { addSchema } from "./validation";
import InputText from "../../../components/common/InputText";
import FormSelect from "../../../components/common/FormSelect";
import { getAllManagersApiCall } from "../../../services/authService";
import { setLocale } from "yup";
import CustomButton from "../../../components/common/CustomButton";
import { createPostalcodeApi, updatePostalcodeApi } from "../../../services/postalcode.service";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";

interface IPostalCode {
  id?: number;
  code?: string;
  createdAt: string;
  updatedAt: string;
  postalCodeUsers: any;
}

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  editableData?: IPostalCode | null;
}

interface ProjectOption {
  id:string;
  full_name:string;
}

interface FormValues {
  code : string;
  project_owner : string[];
}
const PostalcodeAdd: React.FC<AddProps> = ({ isOpen, onClose,editableData }) => {
  const [data, setData] = useState()
  const [projectOwnerOption , setProjectOwnerOption] = useState([]);
  const [btnLoading,setBtnLoading] = useState(false)
  const [loading, setLoading] = useState(true)

  const filterOptions = (editableOptions: any) =>{
    
    const filteredArray = projectOwnerOption.filter((itemTwo:any) =>
  editableOptions.some((itemOne:any) => itemOne.user_id === itemTwo.key)
  )
  
return filteredArray

  }
  const initialValues = {
    code : editableData && editableData.code ? editableData.code : "",
    project_owner: editableData && editableData.postalCodeUsers ? filterOptions(editableData.postalCodeUsers) :[]
  }

  const id  = editableData && editableData.id ? editableData.id :"" 

 

  useEffect(()=>{
   fetchData()
  },[])

  const fetchData = () =>{
    setLoading(true)
    getAllManagersApiCall().then((response)=>{
      const  modifiyOption = response.data.map((item : ProjectOption) => ({ key: item.id, value: item.full_name }));
      setProjectOwnerOption(modifiyOption);
      setLoading(false)

    }).catch((error)=>{
      setLoading(false)
      onClose()

    })
  }
 
   /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
   const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setBtnLoading(true);
    const formData = {
      ...values
     };
 
     if(id){

      updatePostalcodeApi(id,formData).then((response)=>{
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        onClose()
  
      }).catch((error)=>{
        setBtnLoading(false);
  
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      })
     }else{
    createPostalcodeApi(formData).then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose()

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })}

   }

  return <>
  {loading? <Loader isLoading={loading} />:
  <Modal className="export-excel-modal modal-middle-size" show={isOpen} onHide={onClose} centered>
  <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
      <Modal.Body className="position-relative pb-0" >
            <button type="button" className="btn-close text-right  btn-option-cover" aria-label="Close" onClick={onClose}></button>
            <h4 className="fw-bold info-text mb-3">{id?"Edit":"Add"} Postal Code</h4>
           
            
                
                <div className="row">
                  <div className="col-md-6">
                    <InputText
                      controlId="code"
                      label="Postal Code"                      
                      placeholder="Enter Postal Code"
                      touched={touched.code}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.code}
                      value={values.code}
                    />
                  </div>

                  <div className="col-md-6">
                  <FormSelect
                            placeholder="Select"
                            label="Project Owner"
                            name="project_owner"
                            options={projectOwnerOption}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            multi={true}
                            value={values.project_owner}
                            error={errors.project_owner}
                            touched={touched.project_owner}
                          />
                  </div>
                  
                </div>
             
            
            
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
            <button className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info" onClick={onClose}>
              Cancel
            </button>
            <button
              className="fw-semibold fs-13 mw-65 mt-2 btn btn-info"
              type="submit"
            >
              {btnLoading ? "Loading..." : "Save"}
            </button>
        
        
      </Modal.Footer>
      </Form>
     
      )}
      </Formik>
    </Modal>
}
  </>
}
export default PostalcodeAdd;