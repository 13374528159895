// DeletePopup.tsx
import React, { FC } from "react";
import Modal from "react-bootstrap/Modal";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import auditProfile from "../../assets/images/profile-dummy.png";
import contractEdit from "../../assets/images/contract_edit.svg";
import article from "../../assets/images/article.svg";
import { Avatar } from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";


interface AssociateDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  manager: string;
  associatedUserData : any;
}

const AssociateUser: FC<AssociateDataPopupProps> = ({
  isOpen,
  onClose,
  manager,
  associatedUserData
}) => {

  function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  const  stringAvatar=(name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }


  return (
    <>
      
          <Modal
            className="recieve-payment-modal "
            show={isOpen}
            onHide={onClose}
            centered
          >
              <Modal.Body className="position-relative">
                <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                <div className="fs-18 fw-bold mb-3 info-text">
                  Action Required  
                </div>
                <p className="fs-14 gray-text">We have identified that the employee you are attempting to delete, <b className="text-black">{manager}</b>, is currently the reporting manager for the following users:</p>
                <div className="">
                <Scrollbar            
            className="audit-list-scroll"
          >
                  {associatedUserData.map((data: any, index: number) => {
                    return ( <div className={`mb-3 ${index==associatedUserData.length-1?'':'audit-modal-list'} d-flex`}>
                      <div className="audit-modal-img me-4">
                        <span><Avatar {...stringAvatar(data.user.full_name)} /></span>
                        
                      </div>
                      <div className="">
                        <h3 className="fs-14 fw-semibold">{data.user.full_name}</h3>
                        <p className="fs-14 gray-text">{`${data.department} - ${data.designation}`}</p>
                      </div>
                    </div>)
                    
                  })}
                   </Scrollbar>
                </div>
                
              </Modal.Body>
             
            
          </Modal>
        
    </>
  );
};

export default AssociateUser;
