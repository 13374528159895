import { Route, Routes } from "react-router-dom";
import EmployeeList from "./index";
import ViewEmplopyee from "./View";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { Login } from "../Auth/Login";

export const MyTeam = () => {
  return (
    <Routes>
      <Route path="/" element={<EmployeeList />} />
      <Route path="/view/:id/:tabKey?" element={<ViewEmplopyee />} />
    </Routes>
  );
};
