import React from "react";
import { Card, Row, Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import moment from "moment";

import constants from "../../constants/constants";

interface props {
  hmrcData: any;
  signatureUrl: string | null;
}

const ViewHmrc: React.FC<props> = ({ hmrcData, signatureUrl }) => {
  return (
    <>
      
      <Card className="p-3 w-100 pageContentInner mb-2">
        <h3 className="fs-20 fw-bold info-text mb-3">Employee's Personal Details</h3>
        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Last Name
              </label>
              <div className="position-relative">
                <label>{hmrcData.last_name}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                First Name
              </label>
              <div className="position-relative">
                <label>{hmrcData.first_name}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">Date of Birth</label>
              <div className="position-relative">
                <label>
                  {moment(hmrcData.dob).format(
                    constants.dateFormats.slashSeparatedDate
                  )}
                </label>
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Home Address
              </label>
              <div className="position-relative">
                <label>{hmrcData.home_address}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Postal Code
              </label>
              <div className="position-relative">
                <label>{hmrcData.postal_code}</label>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                What is your sex?
              </label>
              <div className="position-relative">
                <label>
                  {hmrcData.gender.charAt(0).toUpperCase() +
                    hmrcData.gender.slice(1)}
                </label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Country
              </label>
              <div className="position-relative">
                <label>{hmrcData.country.title}</label>
              </div>
            </Form.Group>
          </div>
        </Row>

        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                National Insurance Number (If Any)
              </label>
              <div className="position-relative">
                <label>{hmrcData?.ni_number ? hmrcData?.ni_number : "-"}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Employment Start Date
              </label>
              <div className="position-relative">
                <label>
                  {moment(hmrcData.start_date).format(
                    constants.dateFormats.slashSeparatedDate
                  )}
                </label>
              </div>
            </Form.Group>
          </div>
        </Row>
      </Card>

      
      <Card className="p-3 w-100 pageContentInner mb-2">
        <h3 className="fs-20 fw-bold info-text mb-3">Employee's Statement</h3>
        <p className="fs-14 control-label">These questions will help you to choose the statement that matches your circumstances. The statement you choose helps your employer to apply the correct tax code.</p>
        <div className="form-hrmc-statment">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              Do you have another job?
            </label>
            <div className="position-relative">
              <label>
                {hmrcData.employee_statement.another_job === "1" ? "Yes" : "No"}
              </label>
            </div>
          </Form.Group>
        </Row>
        </div>
        {hmrcData.employee_statement?.another_job === "1" && (
          <div className="form-hrmc-statment">
          <Row>
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Do you receive payments from a State, workplace or private
                pension?
              </label>
              <div className="position-relative">
                <label>
                  {hmrcData.employee_statement?.other_payments === "1"
                    ? "Yes"
                    : "No"}
                </label>
              </div>
            </Form.Group>
          </Row>
          </div>
        )}
        {hmrcData.employee_statement?.other_payments === "1" && (
          <div className="form-hrmc-statment">
          <Row>
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                <div>Since 6 April have you received payments from:</div>
                <ul className="fw-normal mt-2 ps-4">
                  <li>
                    Another job which has ended or any of the following taxable
                    benifts
                  </li>
                  <li>Jobseeker's Allowance (JSA)</li>
                  <li>Employment and Support Allowance (ESA)</li>
                  <li>Incapacity Benefit</li>
                </ul>
              </label>
              <div className="position-relative">
                <label>
                  {hmrcData.employee_statement?.other_payments_since_apr6 ===
                  "1"
                    ? "Yes"
                    : "No"}
                </label>
              </div>
            </Form.Group>
          </Row>
          </div>
        )}
      </Card>

      
      <Card className="p-3 w-100 pageContentInner mb-2">
        <h3 className="fs-20 fw-bold info-text mb-3">Student Loans</h3>
        <div className="form-hrmc-statment">
        <Row>
          <Form.Group className="mb-3 custom-from-group">
            <label className="lh-sm control-label fs-14 fw-bold">
              Do you have a student or postgraduate loan?
            </label>
            <div className="position-relative">
              <label>
                {hmrcData.student_loans.student_loan === "1" ? "Yes" : "No"}
              </label>
            </div>
          </Form.Group>
        </Row>
        </div>
        {hmrcData.student_loans?.student_loan === "1" && (
          <div className="form-hrmc-statment">
          <Row>
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                <span>Do any of the following statements apply:</span>
                <ul className="fw-normal mt-2 ps-4">
                  <li>
                    You're still studying on a course that your student loan
                    relates to
                  </li>
                  <li>
                    You completed or left your course after the start of the
                    current tax year, which start on 6 April
                  </li>
                  <li>You've already repaid your loan in full</li>
                  <li>
                    You're paying the Student Loans Company by Direct Debit from
                    your bank to manage your end of loan repayments
                  </li>
                </ul>
              </label>
              <div className="position-relative">
                <label>
                  {hmrcData.student_loans?.apply_statements === "1"
                    ? "Yes"
                    : "No"}
                </label>
              </div>
            </Form.Group>
          </Row>
          </div>
        )}
        {hmrcData.student_loans?.apply_statements === "0" && (
          <div className="form-hrmc-statment">
          <Row>
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                To avoid repaying more than you need to, tick the correct
                student loan or loans that you have
              </label>
              <div className="position-relative">
                {hmrcData.student_loans?.correct_student_loan && hmrcData.student_loans?.correct_student_loan.map(
                  (element: string) => {
                    return (
                      <div className={`position-relative`}>
                        <label>{element}</label>
                      </div>
                    );
                  }
                )}
              </div>
            </Form.Group>
          </Row>
          </div>
        )}
      </Card>

     
      <Card className="p-3 w-100 pageContentInner mb-2">
        <h3 className="fs-20 fw-bold info-text mb-3">Declaration</h3>
        <p className="fs-14 control-label">I confirm that the information I’ve given on this form is correct.</p>
        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                FULL NAME
              </label>
              <div className="position-relative">
                <label>{hmrcData.declaration_full_name}</label>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">Date</label>
              <div className="position-relative">
                <label>
                  {moment(hmrcData.declaration_date).format(
                    constants.dateFormats.slashSeparatedDate
                  )}
                </label>
              </div>
            </Form.Group>
          </div>
        </Row>
        <Row>
          <div className="col-md-4">
            <Form.Group className="mb-3 custom-from-group">
              <label className="lh-sm control-label fs-14 fw-bold">
                Signature
              </label>
              {signatureUrl && (
                <Image className="img-fluid" src={signatureUrl} alt="Logo" />
              )}
            </Form.Group>
          </div>
        </Row>
      </Card>
    </>
  );
};

export default ViewHmrc;
