import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo.svg";

interface iPrintPopup {
  className?: string;
}

const PrintCommapnyHeader: React.FC<iPrintPopup> = ({ className }) => {
  return (
    <Card className={`${className} py-2`}>
      <Row className={className}>
        <Col className="col-6">
          <Image className="img-fluid mt-4" src={Logo} alt="Logo" />
        </Col>
        <Col className="col-6">
          <h1 className="fs-28">EA Mobility</h1>
          <div>Hereford House, Hereford Way</div>
          <div>Kings Lynn</div>
          <div>PE30 4JD</div>
        </Col>
      </Row>
    </Card>
  );
};

export default PrintCommapnyHeader;
