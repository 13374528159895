import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,  
  GridFilterModel,
  GridActionsCellItem
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import constants, { pageSetting } from "../../../constants/constants";
import { checkAbility } from "../../../utility/common";
import moment from "moment";
import { getFinanceDashboardPendingAssets } from "../../../services/financeService";
import BlockIcon from "@mui/icons-material/Block";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import TableSkeleton from "../../../components/common/Skeleton/Table.skeleton";


interface AddProps {}

const FinancePendingAssetApproval: React.FC<AddProps> = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });

  const [orderDateRange, setOrderDateRange] = useState<any>(null);

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    date:orderDateRange,
    ...(search ? { search } : {}),
  };
  
  const handleApproveClick = (id: GridRowId) => () => {
    navigate(`/assets/approve-asset/${id}`);
  };

  const fetchData = (defaultParams: any) => {
    getFinanceDashboardPendingAssets(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S. No.",
      sortable: false,
      filterable: false,
      hideable:false
    },
    {
      field: "name",
      headerName: "Asset Name",
      flex: 1,
      sortable: true,
      hideable: false, 
    },
    {
      field: "type",
      headerName: "Asset Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_at",
      headerName: "Request Date",
      type: "date",
      flex: 1,
      sortable: true,
      filterable: false,
      valueGetter: (params) => new Date(params?.row?.created_at),
      renderCell(params) {
        const orderDate = new Date(params?.row?.created_at);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },  
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.description}>{params.row.description}</span>
        )
      }
    }, 
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        if(params.row.status){
          const className = params.row.status.toLowerCase().replace(/\s+/g, '-');
          return <span className={`asset-status-${className}`}>{params.row.status}</span>
        }else{
          return '-';
        }
      },
    },   
  ];
 
  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.VENDOR_MANAGEMENT
    );

    if (
      checkEditAbilityCondition 
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          if (checkEditAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Approve"
                icon={<ViewIcon />}
                showInMenu={false}
                className="text-primary"
                onClick={handleApproveClick(id)}
              />
            );
          }          
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu={false}
              className="text-primary"
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };


  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection, orderDateRange]);

  handleEditViewActionPermission();

  return (
    <React.Fragment>
      {loading ? (
         <Card className="p-3 w-100 pageContentInner rounded-2 mb-0">
          <TableSkeleton />
         </Card>
      ) : (
        <div className="flex-grow-1 pageContent">
            <Card className="p-3 w-100 pageContentInner rounded-2 mb-0">
            <Row className=" mb-2">
                <Col className="col-12">
                  <h5 className="fs-18 fw-bold info-text mb-2">Pending Assets Approval</h5>
                </Col>               
              </Row>
              
              <Row>
                <Col>
                <DataGrid
                  rows={data.map((row: any, index: number) => ({
                    ...row,
                    serialNumber: index + 1,
                  }))}                 
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2 table-custom"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"   
                  disableRowSelectionOnClick             
                  pageSizeOptions={[5]}
                  localeText={{ noRowsLabel: "No Pending Request" }}
                /> 
                </Col>
              </Row>
            </Card>
          </div>        
      )}
    </React.Fragment>
  );
};

export default FinancePendingAssetApproval;
