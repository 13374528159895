import { axios } from "./axios";
import {
    CREATE_ASSET,
    GET_ASSETS,
    UPDATE_ASSET,
    GET_ASSET_TYPES,
    GET_ASSETS_COUNT,
    MANAGE_ASSET_STATUS,
    GET_ASSET_HISTORY
} from "../constants/paths";

export interface FormSelectType{
  key: number | string;
  value: string;
};

export interface FormSelectEmployeeType{
  key: number | string;
  value: string;
  empDetails: any;
};

export interface AssetCountType{
  [category: string]: string;
};

export type createAssetDTO = {
  name: string;
  type: FormSelectType | string;
  serial_number: string;
  amount: string;
  description: string;
  invoice_number?: string;
  vendor_id: FormSelectType | string;
  asset_image?: string | File;
};

export type assetStatusDTO = {
  status: string;
  note: string;
  employee_id?: FormSelectEmployeeType | string | number;
};

export type assetApproveStatusDTO = {
  status: string;
  approve_reject_notes: string;
};

/**
 * Get all assets from server
 * @param {formData: any }
 * @returns Assets
 */
export const getAssets = (formData: any): Promise<any> => {
  return axios.get(GET_ASSETS, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * Create a new asset
 * @param {data: createAssetDTO } 
 */
export const createAsset = (data: createAssetDTO): Promise<any> => {
  const transformData = {
    ...data,
    type: (typeof data.type === 'object') ? data.type.key : data.type,
    vendor_id: (typeof data.vendor_id === 'object') ? data.vendor_id.key : data.vendor_id,
  };

  transformData.asset_image = data.asset_image;
  return axios.post(CREATE_ASSET, transformData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * Get a single Asset by ID
 * @param {id: string } params
 * @returns asset
 */
export const getAssetById = (id: string): Promise<any> => {
  return axios.get(GET_ASSETS + "/" + id);
};

/**
 * Get asset types
 * @returns Asset types
 */
export const getAssetTypes = (): Promise<any> => {
  return axios.get(GET_ASSET_TYPES);
};

/**
 * Update existing asset
 * @param {id: string, data: createAssetDTO } params
 * @returns Promise<any>
 */
export const updateAsset = (
  id: string,
  data: createAssetDTO
): Promise<any> => {
  const transformData = {
    ...data,
    type: (typeof data.type === 'object') ? data.type.key : data.type,
    vendor_id: (typeof data.vendor_id === 'object') ? data.vendor_id.key : data.vendor_id,
  };

  transformData.asset_image = data.asset_image;
  return axios.put(`${UPDATE_ASSET}/${id}`, transformData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * Get asset types count
 * @returns Asset counts
 */
export const getAssetsCount = (): Promise<any> => {
  return axios.get(GET_ASSETS_COUNT);
};

/**
 * Change asset status
 * @param {id: string, data: assetStatusDTO } 
 */
export const manageAssetStatus = (id: string, data: assetStatusDTO): Promise<any> => {
  const transformData = {
    ...data
  };
  
  if(data.status === 'allocated'){
    transformData.employee_id = typeof data.employee_id === 'object' ? data.employee_id.key : '';
  }else{
    delete transformData.employee_id;
  }
  

  return axios.post(`${MANAGE_ASSET_STATUS}/${id}`, transformData);
};

/**
 * Get history of assets from server
 * @param {formData: any }
 * @returns Assets
 */
export const getAssetHistory = (assetId: number, formData: any): Promise<any> => {
  return axios.get(`${GET_ASSET_HISTORY}/${assetId}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};
