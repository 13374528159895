import react, { useState, useEffect, useRef } from "react";
import React, { FC } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { ITypePropsCanvas } from "./surveyInterface";
import { Formik } from "formik";
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import CustomButton from "../../../components/common/CustomButton";
import { getSignedUrlApi, uploadSignatureApi } from "../../../services/survey/survey.service";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";
import { SIGNATURE_UPLOADED, SOMETHING_WENT_WRONG } from "../../../validations/ValidationErrors";

const CanvasSignature: FC<ITypePropsCanvas> = ({
  survey_id,
  showSign,
  handleSignModalClose,
  setSignData,
  handleCustomerSign,
  index,
  subIndex,
  fieldIndex,
  field
  

}) => {
  const [data, setData] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [signedImages, setSignedImages] = useState<string[]>([]);
  const SignatureSchema = Yup.object().shape({
    sign: Yup.string().required().label("Signature"),
  });
  const initialValuesSign = {
    sign: "",
  };

  useEffect(() => { 
    if (field.signature) {
      fetchImages(field.signature)
    }else{
      setLoading(false);
    
    }
  }
  , [ ]);

  const fetchImages = (image : string) => {
    setLoading(true);
    getSignedUrlApi({ images: [image] })
      .then((res) => {
        console.log("ress", res)
        setSignedImages(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err")
        setLoading(false);
      });
  };
  const sigCanvas = useRef<SignatureCanvas>(null);
  function setSignatureOnChange(setFieldValue: any) {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL("image/png");
      setFieldValue("sign", dataUrl);
      setData(dataUrl);
    }
  }

  const handleSubmit = (values: any) => {
    console.log("data", values);

    const formData = {
      survey_id,
      sign: values.sign,
    };
    setLoading(true);

    uploadSignatureApi(formData)
      .then((res) => {
        console.log("res", res.data);
        setSignData(res.data);
        toast.success(SIGNATURE_UPLOADED);
        handleCustomerSign(res.data, index, subIndex, fieldIndex);
        handleSignModalClose();
      })
      .catch((err) => {
        toast.error(SOMETHING_WENT_WRONG);
        setLoading(false);

      });

    // handleSignModalClose();
  };

  const resetCanvas = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };
  return (
     
   <React.Fragment>
     <Modal
        show={showSign}
        onHide={handleSignModalClose}
        centered
        dialogClassName="modal-400w"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-18 fw-bold info-text">My Signature </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={SignatureSchema}
            initialValues={initialValuesSign}
            onSubmit={(values, actions) => {
              handleSubmit(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form className="text-start" noValidate onSubmit={handleSubmit}>
                <div className="sigContainer">
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{ className: "sigPad" }}
                    onEnd={(e: any) => {
                      setSignatureOnChange(setFieldValue);
                    }}
                  />
                  <p>Sign here using MOUSE or TOUCH screen if using a tablet.</p>
                  {errors.sign && touched.sign ? (
                    <div className="error-message text-danger">{errors.sign}</div>
                  ) : null}
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <div>
                    {signedImages.length > 0 && (
                      <div className="d-flex flex-wrap" style={{width:'90%' }}>
                        {signedImages.map((image, index) => {
                          return (
                            <div key={index} className="">
                              <img
                                src={image}
                                alt="preview"
                                loading="lazy"
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )  
                    }
                  </div>
                  <div
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={resetCanvas}
                  >
                    Reset
                  </div>
  
                  <button
                    className="fw-semibold fs-13  mw-90 me-2 mt-2 btn btn-outline-info"
                    onClick={handleSignModalClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Submit
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    {loading && <Loader isLoading={loading} />}  
    </React.Fragment>
  );
};
export default CanvasSignature;
