import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import Loader from "../../components/common/Loader";
import { getMedicalQuestionnaire, getIllnessList } from "../../services/medicalService";

import FormMedical from "./FormMedical";
import ViewMedical from "./ViewMedical";

const EmployeeMedicalQuestionnaire: React.FC<Record<string, number>> = () => {
  const [loading, setLoading] = useState(true);
  const [medicalData, setMedicalData] = useState<any>(null);
  const [signatureUrl, setSignatureUrl] = useState(null);
  const [isMutation, setIsMutation] = useState(false);
  const [illnessData, setIllnessData] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
  }, [isMutation]);

  const fetchData = () => {
    setLoading(true);
    getMedicalQuestionnaire()
      .then((response) => {
        setMedicalData(response?.data);
        setSignatureUrl(response?.signatureUrl);
      })
      .catch((error) => {
        getIllnessList().then((response:any) => {
          const responseData = response.data;
          setIllnessData(responseData.map((name:string) => ({ key: name, name: name })));
        }).catch((error) => {
          toast(error.response.data.message, { type: toast.TYPE.ERROR });
        })
      }).finally(() => {
        setLoading(false);
      });
  };

  const mutationUpdate = () => {
    setIsMutation(!isMutation);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Medical Questionnaire</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            {medicalData === null && (
              <FormMedical illnessData={illnessData} setIsMutation={mutationUpdate} />
            )}
            {medicalData && (
              <ViewMedical
                medicalData={medicalData}
                signatureUrl={signatureUrl}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeeMedicalQuestionnaire;
