import React, { FC, useEffect, useState } from "react";
import { Col, Collapse, Image, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { NavItems , NavItemsAdministrator, NavItemsEmployee, NavItemsEmployeeExpenses, NavItemsFinance, NavItemsForAllEmployee, NavItemsForDriver, NavItemsHR, NavItemsInstallationEngineer, NavItemsProjectManager, NavItemsReportingManager, NavItemsReportingManagerExpenses, NavItemsWarehouse, commonWithAdminAndEmoloyee} from "./NavItems";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./sidebar.scss";
import Logo from "../../../assets/images/logo.svg";
import LogoSmall from "../../../assets/images/EA-Logo-Small.svg";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../features/Auth/authSlice";
import { Reporting_Manager, Employee, SUPER_ADMIN, titleRoles, departmentCheck, desginationIsEmployee, OperationDesignation } from "../../../constants/constants";
import { checkAbility } from "../../../utility/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import { NavItemsEmployee } from "./NavItemsEmployee";
interface ISidebar {
  isActiveSidebar: boolean;
}

/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 1:59:33 PM
 *
 * @param {{ isActiveSidebar: any; }} {
  isActiveSidebar
}
 * @returns {*}
 */
export const Sidebar: FC<ISidebar> = ({ isActiveSidebar }) => {
  const { user,selectedTile }: any = useSelector(selectAuthentication);
  const permission = user.role.permission;
  const department = user?.user?.employeeDetails?.department ? user?.user?.employeeDetails?.department : '-';
  const userDesignation = user?.user?.employeeDetails?.designation ? user?.user?.employeeDetails?.designation : SUPER_ADMIN;
  const isReportingManager = user?.isReportingManager;
  const isProjectOwner = user?.isProjectOwner;
  const [expanded, setExpanded] = useState<string | null>(null);
  const [renderSidebar, setRenderSidebar] = useState<any>([]);
  const [renderSidebarStatic, setRenderSidebarStatic] = useState<any>([]);
  const toggleCollapse = (collapseKey: string) => {
    setExpanded((prevExpanded) =>
      prevExpanded === collapseKey ? null : collapseKey
    );
  };  
  useEffect(() =>{
    let merged: any[] = [...NavItemsAdministrator,...commonWithAdminAndEmoloyee];
    let staticSidebar: any[] = [];
    //set sidebar as per selectedTile

    if(userDesignation!=SUPER_ADMIN){
      staticSidebar = [...NavItemsForAllEmployee,...commonWithAdminAndEmoloyee]
    } else{
      setRenderSidebar([...NavItemsAdministrator,...commonWithAdminAndEmoloyee])
    }

    
    
    if(selectedTile==titleRoles.A){
        
      if(userDesignation==OperationDesignation.PM){
        merged = [...merged];
        }
  
        if(isReportingManager){
          staticSidebar = [...staticSidebar, ...NavItemsReportingManager];
        }

        if(isReportingManager || isProjectOwner){
          staticSidebar = [...staticSidebar, ...NavItemsReportingManagerExpenses];
        }
        else if(userDesignation!=SUPER_ADMIN){
          staticSidebar = [...staticSidebar, ...NavItemsEmployeeExpenses];
        }

        if(userDesignation==OperationDesignation.DS){
          //merged = [...NavItemsForAllEmployee,...merged];
          staticSidebar = [...staticSidebar, ...NavItemsForDriver];

        }

        /* if(userDesignation==OperationDesignation.IE){
          merged = [...NavItemsForAllEmployee,...merged];

        } */
        setRenderSidebar(merged)
    }
  
    setRenderSidebarStatic(staticSidebar);

    if(selectedTile==titleRoles.HR){
      setRenderSidebar(NavItemsHR)
    }

    if(selectedTile==titleRoles.WM){
      setRenderSidebar(NavItemsWarehouse)
    }

    if(selectedTile==titleRoles.PM){
      setRenderSidebar(NavItemsProjectManager)
    }

    if(selectedTile==titleRoles.FM){
      setRenderSidebar(NavItemsFinance)
    }

    if(selectedTile==titleRoles.IM ){
      setRenderSidebar(NavItemsInstallationEngineer)
    }

  },[selectedTile])
  return (
    <Col
      xs="auto"
      className={`sidebarMenu d-print-none h-100 ${isActiveSidebar ? "sidebarAction" : ""}`}
    >
      <Navbar
        bg="white"
        variant="light"
        expand="xxl"
        className="w-100 h-100 p-0"
      >
        <div className="d-flex flex-column w-100 h-100">
          <div className="d-flex py-2 px-3 align-items-center sidebarLogo ">
            <Link to="/" className="d-inline-block py-1">
              <Image
                className="img-fluid showInSidebar"
                src={Logo}
                alt="Logo"
                width={185}
                height={38}
              />
              <Image
                className="img-fluid showInSmallSidebar"
                src={LogoSmall}
                alt="Logo"
                width={33}
                height={33}
              />
            </Link>
          </div>

   
 
          <div className="overflow-x-hidden overflow-y-auto sidebarList p-2">             
            {/* {(userDesignation!=Reporting_Manager && userDesignation != Employee )  &&  */}
           
            {selectedTile==titleRoles.A &&  (
             <Nav defaultActiveKey="/dashboard" as="ul" className="flex-column">
              {renderSidebarStatic.map((elem: any) => {
                const { id, menuName, menuIcon, path, moduleName, subMenu } =
                  elem;
                const collapseKey = `collapse-${id}`;
                const hasSubMenu = subMenu && subMenu.length > 0;

                return (
                  <React.Fragment key={`employee_route_${id}`}>
                    { !subMenu && (
                        <Nav.Item as="li">
                          <Nav.Link
                            as={NavLink}
                            to={path}
                            className="align-items-center rounded-1 d-flex px-0 sidebarLink"
                          >
                            <span className="py-1 text-center min-w-48 sidebarIcon">
                              {menuIcon}
                            </span>
                            <span className="hideInSmallSidebar fw-bold">
                              {menuName}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {/* check Ability of sub menu */}
                   { hasSubMenu && (
                      <React.Fragment key={id}>
                        <Nav.Item as="li">
                          <Nav.Link
                            onClick={() => toggleCollapse(collapseKey)}
                            className="align-items-center rounded-1 d-flex px-0 sidebarLink text-nowrap"
                          >
                            <span className="py-1 text-center min-w-48 sidebarIcon">
                              {menuIcon}
                            </span>
                            <span className="hideInSmallSidebar fw-bold">
                              {menuName}
                            </span>
                            <span
                              className={`ms-auto ${
                                expanded === collapseKey ? "open" : ""
                              }`}
                            >
                              <ExpandMoreIcon />{" "}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Collapse in={expanded === collapseKey}>
                          <ul className="nav flex-column">
                            {subMenu.map((subElem: any) => {
                              const { subId, menuName, path, moduleName } =
                                subElem;
                              return ((
                                  <Nav.Item as="li" key={subId}>
                                    <Nav.Link
                                      as={NavLink}
                                      to={path}
                                      className="dropdown-item align-items-center fw-bold px-3 d-flex sidebarLink text-nowrap nav-link"
                                    >
                                      {menuName}
                                    </Nav.Link>
                                  </Nav.Item>
                                )
                              );
                            })}
                          </ul>
                        </Collapse>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );                
              })}
              
            </Nav>
            )}

 
<Nav defaultActiveKey="/dashboard" as="ul" className="flex-column">
              {renderSidebar.length > 0 && renderSidebar.map((elem: any) => {
                const { id, menuName, menuIcon, path, moduleName, subMenu } =
                  elem;
                const collapseKey = `collapse-${id}`;
                const allowedSubMenus = subMenu && subMenu.length > 0 && subMenu.filter((subElem: any) => {
                  const { moduleName } = subElem;
                  return checkAbility('List', moduleName);
                });
                const hasSubMenu = allowedSubMenus && allowedSubMenus.length > 0;

                return (
                  <React.Fragment key={id}>
                    {checkAbility("List", moduleName) &&
                      moduleName !== "all" &&
                      !hasSubMenu && (
                        <Nav.Item as="li">
                          <Nav.Link
                            as={NavLink}
                            to={path}
                            className="align-items-center rounded-1 d-flex px-0 sidebarLink "
                          >
                            <span className="py-1 text-center min-w-48 sidebarIcon">
                              {menuIcon}
                            </span>
                            <span className="hideInSmallSidebar fw-bold">
                              {menuName}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {/* check Ability of sub menu */}
                   { hasSubMenu && (
                      <React.Fragment key={id}>
                        <Nav.Item as="li">
                          <Nav.Link
                            onClick={() => toggleCollapse(collapseKey)}
                            className={`align-items-center rounded-1 d-flex px-0 sidebarLink text-nowrap ${
                              expanded === collapseKey ? "open" : "lolo"
                            }`}
                          >
                            <span className="py-1 text-center min-w-48 sidebarIcon">
                              {menuIcon}
                            </span>
                            <span className="hideInSmallSidebar fw-bold">
                              {menuName}
                            </span>
                            <span
                              className={`ms-auto ${
                                expanded === collapseKey ? "open" : ""
                              }`}
                            >
                              <ExpandMoreIcon />{" "}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Collapse in={expanded === collapseKey}>
                          <ul className="nav flex-column">
                            {subMenu.map((subElem: any) => {
                              const { subId, menuName, path, moduleName } =
                                subElem;
                              return (
                                checkAbility("List", moduleName) &&
                                moduleName !== "all" && (
                                  <Nav.Item as="li" key={subId}>
                                    <Nav.Link
                                      as={NavLink}
                                      to={path}
                                      className="dropdown-item align-items-center fw-bold px-3 d-flex sidebarLink  nav-link"
                                    >
                                      {menuName}
                                    </Nav.Link>
                                  </Nav.Item>
                                )
                              );
                            })}
                          </ul>
                        </Collapse>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
            </Nav>
            
             {/* } */}
          </div>
        </div>
      </Navbar>
    </Col>
  );
};
