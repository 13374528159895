import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import FormSelect from "../FormSelect";
import { getAllManagersApiCall } from "../../../services/authService";
import CustomButton from "../CustomButton";
import {  
  assingProjectOwnerApi,
} from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../Loader";
import InputCheckBox from "../InputCheckBox";

const addSchema = yup.object({
  project_owner: yup.object().required().label("Project Owner"),
});

interface AddProps {
  isOpen: boolean;
  onClose: (isReload?:boolean) => void;
  projectsData: string[];
  selectedPostalCodeOwners: string[];
  selectedProjectOwner?: ProjectOption;
}

interface ProjectOption {
  id: string;
  full_name: string;
}

interface FormValues {
  projectsData: string[];
  selectedPostalCodeOwners: string[];
  project_owner: any;
  populate_all: boolean;
}
const ProjectOwnerAssignToProjects: React.FC<AddProps> = ({ isOpen, onClose, projectsData, selectedPostalCodeOwners, selectedProjectOwner }) => {
  const [projectOwnerOption, setProjectOwnerOption] = useState([]);
  const [projectOwnerList, setProjectOwnerList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const initialValues = {
    selectedPostalCodeOwners: selectedPostalCodeOwners,
    projectsData: projectsData,
    project_owner: selectedProjectOwner ? {key:selectedProjectOwner.id,value:selectedProjectOwner.full_name} : [],
    populate_all: false,
  };

  useEffect(() => {
    fetchData().then(() => {      
       // Call this function after fetchData completes      
    });
  }, []);

  useEffect(() => {    
    if(projectOwnerList.length){      
      updateProjectOwnerOptions();
    }
  }, [projectOwnerList]);
  
  const fetchData = async() => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getAllManagersApiCall()
        .then((response) => {
          const modifiedOption = response.data.map((item: ProjectOption) => ({
            key: item.id,
            value: item.full_name,
          }));
          //console.log(modifiedOption);
          setProjectOwnerList(modifiedOption);
          setLoading(false);
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);
          onClose();
          reject(error);
        });
    });
  };
  
  const updateProjectOwnerOptions = (checkedValue: boolean = false) => {
    if (!checkedValue) {
      const filteredOptions = projectOwnerList.filter((item: any) =>
        selectedPostalCodeOwners.includes(item?.key)
      );    
      setProjectOwnerOption(filteredOptions);
    } else {
      setProjectOwnerOption(projectOwnerList);
    }
   // setFieldValue('')
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
   // console.log(values);
   // return;
    const projectIds = values?.projectsData.map((item:any) => item.id)
    setBtnLoading(true);
    const formData: any = {
      ...values,
      project_ids:projectIds
    };
   // console.log(projectIds,formData)
   // return;
 
    assingProjectOwnerApi(formData).then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose(true)

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal project-owner-modal" aria-labelledby="Assign modal owner"
        centered show={isOpen} onHide={onClose}>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={() => onClose()}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Assign Project Owner
                  </h4>                  
                  <div className="row">
                    <div className="col-md">
                      <FormSelect
                        placeholder="Select"
                        label="Project Owner"
                        name="project_owner"
                        options={projectOwnerOption}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.key}
                        onChange={(name: string, value: string) => {
                          setFieldValue(name, value);
                        }}
                        value={values.project_owner}
                        error={errors.project_owner}
                        touched={touched.project_owner}
                      />
                    </div>
                  </div>
                  <Row>
                    <Col>
                    <InputCheckBox
                                controlId="populate_all"
                                label="Populate All"
                                touched={touched.populate_all}
                                handleBlur={handleBlur}
                                className="form-check-label-size  fw-semibold"
                                handleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldValue("project_owner", "");
                                  setFieldValue("populate_all", e.target.checked);
                                  updateProjectOwnerOptions(e.target.checked);
                                }}
                                errorsField={errors.populate_all}
                                value={values.populate_all}
                              />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Apply
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default ProjectOwnerAssignToProjects;
