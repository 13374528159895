import { axios } from "./axios";
import {  
  LEAVE_DETAIL,  
  LEAVE_EMP_ADD,
  LEAVE_EMP_BUSINESS_DAYS,
  LEAVE_EMP_LIST,
  LEAVE_EMP_LIST_BY_HR,
  LEAVE_EMP_LIST_DROPDOWN,
  LEAVE_EMP_TYPES,
  LEAVE_RM_ADD,
  LEAVE_RM_TYPES,
  LEAVE_STATUS
} from "../constants/paths";

export type applyLeaveDTO = {
  leave_type: any;
  description: string;
  date_from: string;
  date_to: string;
};
export const getEmployeeLeaveHistoryList = (formData: any, employeeId: string | number | undefined=""): Promise<any> => {
  return axios.get(`${LEAVE_EMP_LIST}/${employeeId}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      year: formData.year,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });

};
export const getEmployeeLeaveListByHr = (formData: any,type:string = 'applied'): Promise<any> => {
  return axios.get(`${LEAVE_EMP_LIST_BY_HR}/${type}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const getLeaveTypeList = (uType?:string) : Promise<any> => {
  return axios.get(`${ uType == 'rm' ? LEAVE_RM_TYPES : LEAVE_EMP_TYPES}`);
};

export const getBusinessDaysCountApi = (employeeId: any = "",data: { date_from: string; date_to: string; }): Promise<any> => {
  const transformData = {
    ...data,
  };

  return axios.post(`${LEAVE_EMP_BUSINESS_DAYS}/${employeeId}`, transformData);
};

export const applyLeaveApi = (data: applyLeaveDTO): Promise<any> => {
  const transformData = {
    ...data,
  };

  return axios.post(LEAVE_EMP_ADD, transformData);
};

export const applyLeaveByRmApi = (employeeId:any,data: applyLeaveDTO): Promise<any> => {
  const transformData = {
    ...data,
  };

  return axios.post(`${LEAVE_RM_ADD}/${employeeId}`, transformData);
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getLeaveDetailById = (id: string | number | undefined, employeeId?: string | number): Promise<any> => {
  return axios.get(`${LEAVE_DETAIL}/${id}/${employeeId ? employeeId : ""}`);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateLeaveStatusApi =(id: string | number | undefined, data: any,  employeeId?: string | number): Promise<any> => {  
  return axios.patch(`${LEAVE_STATUS}/${id}/${employeeId ? employeeId : ""}`, data);
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getEmployeeLeavesList = (employeeId?: string | number): Promise<any> => {
  return axios.get(`${LEAVE_EMP_LIST_DROPDOWN}/${employeeId ? employeeId : ""}`);
};