import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import FormSelect from "../../../components/common/FormSelect";
import { useCallback, useEffect, useState } from "react";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import {
  getDashboardInventoryTrendsChartApi,
  getDashboardProductListApi,
  getProductListAsync,
} from "../../../services/warehouseService";
import {
  Box,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import InputText from "../../../components/common/InputText";
import { getFinanceDashboardExpensesChart } from "../../../services/financeService";
import moment from "moment";
import constants from "../../../constants/constants";
import { BorderAllRounded } from "@mui/icons-material";
import BarChartSkeleton from "../../../components/common/Skeleton/BarChart.skeleton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Product {
  hs_sku: any;
  id: number;
  name: string;
}

interface AddProps {
  dateRange: any;
}

export const FinanceDashboardExpenses: React.FC<AddProps> = ({ dateRange }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inventroyTrendsData, setInventoryTrendsData] = useState<any>({
    labels: ["Jan"],
    datasets: [
      {
        data: [0],
        borderColor: "#21AC59",
        label: "Employee Expenses",
        backgroundColor: "#21AC59",
        pointBackgroundColor: "#21AC59",
      },
      {
        data: [0],
        borderColor: "#EFA73C",
        label: "Procurement Expenses",
        backgroundColor: "#EFA73C",
        pointBackgroundColor: "#EFA73C",
      },
    ],
  });

  const getDashboardNewOnboardProjects = () => {
    // setLoading(true);
    // console.log(selectFilter);
    const requestData = dateRange;
    getFinanceDashboardExpensesChart(requestData)
      .then((response) => {
        // setPoRaisedData(response.data);
        convertToChartData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to convert API response to Chart.js data
  const convertToChartData = (apiResponse: any[]) => {
    const labels = apiResponse.map((item) => item.month);
    const empExpData = apiResponse.map((item) => item.emp_expense);
    const procExpData = apiResponse.map((item) => item.procurement_expense);
    /*  const backgroundColor = [
      "#4ECB71",
      "#FFA133",
      "#5299DB"
    ]; */

    setInventoryTrendsData({
      labels,
      datasets: [
        {
          data: empExpData,
          borderColor: "#21AC59",
          label: "Employee Expenses",
          backgroundColor: "#21AC59",
          pointBackgroundColor: "#999",
          pointBorderWidth: 0,
        },
        {
          data: procExpData,
          borderColor: "#EFA73C",
          label: "Procurement Expenses",
          backgroundColor: "#EFA73C",
          pointBackgroundColor: "#999",
          pointBorderWidth: 0,
        },
      ],
    });
  };

  useEffect(() => {
    getDashboardNewOnboardProjects();
  }, [dateRange]);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          color: "#889EAE",
          font: {
            size: "14",
            family: "Nunito Sans, sans-serif",
          },
          text: "New Projects", // Label for the y-axis
        },
        ticks: {
          color: "#000000",
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        align: "end" as const,
        fullSize: true,
        labels: {
          boxWidth: 16,
          boxHeight: 8,
          padding: 10,
          radius: 4,
          color: "#000000",

          font: {
            weight: "600",
            size: "14",
            family: "Nunito Sans, sans-serif",
          },
        },
      },
      title: {
        display: false,
        text: "Line Chart",
      },
      datalabels: {
        display: false, // Disable datalabels for this specific chart
      },
    },
    elements: {
      point: {
        pointStyle: "circle",
        radius: 6,
      },
    },
  };

  return (
    <div className="d-flex flex-column pageContainer px-sm-1 w-100 pb-sm-1">
      <Card className="p-3 w-100 pageContentInner rounded-2">
        <Row>
          <Col md={7}>
            <h5 className="fs-18 fw-bold info-text mb-0">Expenses</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            {loading ? (
              <BarChartSkeleton heigth={300} numbers={60} />
            ) : (
              <>
                <div className="finance-dashboard-forecast-grap mt-3">
                  <Line data={inventroyTrendsData} options={options} />
                </div>
              </>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};
