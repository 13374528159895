import { FC, ReactNode } from 'react';
import { Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

interface ICustomRadio {
    controlId: string;
    label?: string | ReactNode;
    labelTooltip?: any;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    data?: any;
    placeholder?: string
    id?: string;
    rightIcon?: any;
    size?: any;
    name?: any;
}

/**
 * Custom Select Reusable Component
 * @date 4/13/2023 - 1:57:15 PM
 *
 * @param {{ controlId: any; label: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; data: any; placeholder: any; id: any; rightIcon: any; size: any; name: any; }} {
    controlId, label, handleChange, handleBlur, errorsField, touched, value, data, placeholder, id, rightIcon, size, name
}
 * @returns {*}
 */

const InputRadio: FC<ICustomRadio> = ({
    controlId, label,labelTooltip, handleChange, handleBlur, errorsField, touched, value, data, placeholder, id, rightIcon, size, name
}) => {
    return (
        <Form.Group className="mb-3" controlId={controlId}>
            {label ? (
                <Form.Label className="lh-sm fs-14 fw-bold control-label mb-2">{label}{labelTooltip || ''}</Form.Label>
            ) : null}
            <div className={`position-relative`}>
                <>
                    {data.map((row: any, index: number) => {
                        const key = row.key
                        return <div key={index} className={`mb-3  ${errorsField ? 'is-invalid': ''}`}>
                        <Form.Check
                          type="radio"
                          id={`${name}-${key}`}
                          name={name}
                          label={row.name}
                          value={key}
                          checked={value === key}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errorsField && touched}
                        />
                      </div>
                    })}
                </>
                {touched && <Form.Control.Feedback type="invalid">
                    {errorsField}
                </Form.Control.Feedback>}
            </div>
        </Form.Group>
    );
}

export default InputRadio