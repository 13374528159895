import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { Card, Col, Row } from "react-bootstrap";
import constants, { pageSetting, pageSizeModel, projectStatus } from "../../constants/constants";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block"
import { getInstallationTaskList } from "../../services/installation.service";
import moment from "moment";
import InstallationInProgressTask from "./in-progess-task";

const InstallationTaskList: React.FC<Record<string, string>> = ({
  type
}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);
  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/installation/view/${id}`);
  };
  
  console.log(type);
  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    type:type,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    setLoading(true);
    getInstallationTaskList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);


  const columns: GridColDef[] = [
    {
      field: "project_no",
      headerName: "Project Order",
      flex: 1,
      sortable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params){
        return `${params.row.project_no_str}`
      }
    },    
    { field: "name", headerName: "Customer Name", flex: 1, sortable: true }, 
    { field: "address", headerName: "Address", flex: 1, sortable: true }, 
    { field: "phone", headerName: "Phone", flex: 1, sortable: true }, 
    {
      field: "$projectOwner.full_name$",
      headerName: "Project Owner ",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row.project_owner_id ? (
          <span title={params.row.projectOwner.full_name}>
            {params.row.projectOwner.full_name}
          </span>
        ) : (
          "-"
        );
      },
    },      
  ];

  const handleEditViewActionPermission = () => {

    if(type == 'today'){
      columns.push({
        field: "status",
        headerName: "Status",
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell(params) {        
          return (
            <span
              title={params.row.status}
              className={`py-1 px-2 ms-0 rounded-1 
                ${params.row.status == "First Day" && "primary-tag" }
                ${params.row.status == "Last Day" && "info-tag"} 
                d-inline-block fs-11`}
            >
              {params.row.status}
            </span>
          );
        },
      })
    }
    else{
      columns.splice(1, 0, {
        field: "$projectDetails.planned_start_date$",
        headerName: "Installation Date",
        type: "date",
        flex: 1,
        sortable: true,
        valueGetter: (params) =>
          new Date(params.row.projectDetails.planned_start_date),
        renderCell(params) {
          const orderDate = new Date(
            params.row.projectDetails.planned_start_date
          );        
          return (
            <span>
              {params.row.projectDetails.planned_start_date ? moment(orderDate).format(constants.dateFormats.slashSeparatedDate): "-"}            
            </span>
          );
        },
      });      
    }
   
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.INSTALLATIOIN_TASK
    );

    if (      
      checkViewAbilityCondition 
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                icon={<ViewIcon />}
                showInMenu={false}
                onClick={handleViewClick(id)}
              />
            );
          }
          
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setLimit(newPaginationModel.pageSize);
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const moveToPreviousJobs = () => {
    navigate("/installation/history");
  };

  const moveToCurrentJobs = () => {
    navigate("/installation");
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection, type]);

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Installation Task</h1>
              </div>
              <div className="d-flex align-items-center p-2 bg-white rounded-2">
                <div className={`surver-tab ${type == 'today' && 'active'}`} onClick={moveToCurrentJobs}>Pending Jobs</div>
                <div className={`surver-tab ${type == 'history' && 'active'}`} onClick={moveToPreviousJobs}>
                  Previous Jobs{" "}
                </div>
              </div>
              <div>&nbsp;</div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
          <Row>
            <Col>
              <h6 className="fs-18 fw-bold mb-3 info-text">{type == 'today' ? `Today's Task` : "Task History" }
              {
                type == 'today' && (
                <span className={`py-1 px-2 rounded-1 info-tag d-inline-block fs-11`}>
                { moment().format(constants.dateFormats.slashSeparatedDate)}
                </span>
                )
              }
              </h6>
            </Col>
          </Row>
        
            <Card className="w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  className="border-0 rounded-2"
                  pageSizeOptions={pageSizeModel}
                />               
              </div>
            </Card>
            {
              type == 'today' && (
                <div className="mt-3">
                <InstallationInProgressTask />
                </div>
              )
            }
          </div>
          
        </div>
      )}
    </React.Fragment>
  );
};

export default InstallationTaskList;
