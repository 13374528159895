import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../../components/common/InputText";
import { dealSchema } from "./validation";
import FormSelect from "../../../components/common/FormSelect";
import CustomButton from "../../../components/common/CustomButton";
import Loader from "../../../components/common/Loader";
import constants, {
  EmployeeDocumentTypeExtension,
  EmployeeDocumentTypeForDropZone,
  commonMasking,
  dealContractType,
  dealStatus,
  formTemplateMaxFileSizeMB,
} from "../../../constants/constants";
import { phoneMask } from "../../../utility/common";
import {
  createDealApi,
  getDealByIdApi,
  getProductTypeList,
  updateDealApi,
  uploadPDFApi,
} from "../../../services/dealService";
import { getPostalCodes } from "../../../services/postalcode.service";
import InputTextArea from "../../../components/common/InputTextArea";
import InputDatePicker from "../../../components/common/InputDatePicker";
import { toast } from "react-toastify";
import moment, { Moment } from "moment";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";
import Dropzone from "react-dropzone";

import DepositePaid from "../../../assets/images/deposit-paid-new.svg";
import ContactComplicatd from "../../../assets/images/contact-complicatd-new.svg";
import FileUploadIcon from "../../../assets/images/download-green.svg";
import deleteIconRed from "../../../assets/images/awesome-trash-red.svg";
import DeletePopup from "../../../components/common/deletePopup";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import { DOCUMENT_TYPE_ERROR } from "../../../validations/ValidationErrors";
import InputCurrencyText from "../../../components/common/InputCurrencyText";

export const AddDeals = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [dealData, setDealData] = useState<any>({});
  const [dealFormData, setDealFormData] = useState<any>({});
  const [productTypeData, setProductTypeData] = useState<any>({});
  const [postalCodeData, setPostalCodeData] = useState<any>({});
  const [contractFileData, setContractFileData] = useState<any>(undefined);
  const [paymentFileData, setPaymentFileData] = useState<any>(undefined);
  const [isSubmitConfirm, setIsSubmitConfirm] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();

  /**
   * Submit Method to call when user save button
   * @async
   * @param values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    //  const values = dealData;
    //  console.log(values);
    const formData = {
      ...values,
      order_date: moment(values.order_date).format(
        constants.dateFormats.databaseFormat
      ),
      contract_date: moment(values.contract_date).isValid()
        ? moment(values.contract_date).format(
            constants.dateFormats.databaseFormat
          )
        : "",
      payment_date: moment(values.payment_date).isValid()
        ? moment(values.payment_date).format(
            constants.dateFormats.databaseFormat
          )
        : "",
    };
    // return;
   // console.log(values);
  //  return;
    setDealFormData(formData);
    if (values?.contract_file && values?.payment_file) {
      setIsSubmitConfirm(true);
    } else {
      addOrUpdateDeals(formData);
    }
    return;
    // return;
  };

  const addOrUpdateDeals = async (values: any = {}) => {
   // console.log(values?.order_no);
    if (!values?.order_no) {
      values = {
        ...dealFormData,
      };
    }
    const formData = {
      ...values,
    };
    // console.log(formData);
    setBtnLoading(true);
   // setLoading(true);
    if (id) {
      updateDealApi(id, formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/deals");
         // setLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
         // setLoading(false);
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createDealApi(formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/deals");
         // setLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
         // setLoading(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const handleClose = () => {
    setIsSubmitConfirm(false);
  };

  useEffect(() => {
    fetchProductType().then(() => {
        if (id) {
          getDealDetails(id);
        } else {
          setLoading(false);
        }
      });    
  }, []);

  const getDealDetails = async (id: any) => {
    setLoading(true);
    getDealByIdApi(id)
      .then((response) => {
        setDealData(response.data?.deals);
        if (response.data?.contractFile) {
          setContractFileData(response.data?.contractFile);
        }

        if (response.data?.paymentFile) {
          setPaymentFileData(response.data?.paymentFile);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // fetch product type data to set in the listing
  const fetchProductType = async () => {
    return new Promise((resolve, reject) => {
      getProductTypeList().then((response) => {
        const productTypeOptions = response.data?.map((item: any) => ({
          key: String(item.id),
          value: item.name,
        }));
        setProductTypeData(productTypeOptions);
        return resolve(true);
      });
    });
  };

  // fetch postal codes to set in the listing
  const fetchPostalCodes = async () => {
    return new Promise((resolve, reject) => {
      getPostalCodes({}).then((response) => {
        const postalCodeOptions = response.data?.resultSet?.map((item: any) => ({
          key: String(item.id),
          value: item.name,
        }));
        setPostalCodeData(postalCodeOptions);
        return resolve(true);
      });
    });
  };

  const getPostalCode = (params: any) => {
    return new Promise((resolve, reject) => {
      getPostalCodes(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };
  const allowedFileTypes = ["application/pdf"];
  const maxFileSizeMB = 2;
  const handleFileUpload = (event: any, setFieldValue: any, type: string) => {
 //   console.log("file", event);
    const pdfFile = event[0];
    // return;
    // Check file type
    if (!pdfFile?.type || !EmployeeDocumentTypeExtension.includes(pdfFile?.type)) {
      toast(DOCUMENT_TYPE_ERROR, {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (pdfFile.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${formTemplateMaxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setDocLoading(true);
      // Create FormData object
      const formData = new FormData();
      const PreviouscontractFileData = contractFileData;
      const PreviouspaymentFileData = paymentFileData;
     // console.log(PreviouscontractFileData);
      // Append the file to the FormData object
      if (type == "contractFile") {
      setContractFileData(null);
      }

      if (type == "paymentFile") {
        setPaymentFileData(null);
      }
      formData.append("file", pdfFile);
      const reader = new FileReader();
      uploadPDFApi(formData, type)
        .then((response) => {
          setDocLoading(false);
         // console.log(type);
          if (type == "paymentFile") {
            setFieldValue("payment_file", response.data);
            // Create a FileReader to read the file and get a data URL

            reader.onloadend = () => {
              setPaymentFileData(reader.result);
            };

            if (pdfFile) {
              reader.readAsDataURL(pdfFile);
            }
          }

          if (type == "contractFile") {
            setFieldValue("contract_file", response.data);
            reader.onloadend = () => {
              setContractFileData(reader.result);
            };

            if (pdfFile) {
              reader.readAsDataURL(pdfFile);
            }
          }
        })
        .catch(() => {
          setDocLoading(false);
          setContractFileData(PreviouscontractFileData);
          setPaymentFileData(PreviouspaymentFileData);
        });
    }
  };

  const initialValues = {
    order_no: dealData?.order_no ? dealData?.order_no : "",
    name: dealData?.name ? dealData?.name : "",
    contract_type: dealData?.contract_type
      ? dealContractType.find((item) => item.key == dealData?.contract_type)
      : null,
    status: dealData?.status
      ? dealStatus.find((item) => item.key == dealData?.status)
      : null,
    order_date: dealData?.order_date ? moment(dealData?.order_date) : null,
    amount: dealData?.amount ? dealData?.amount : "",
    project_type: dealData?.project_type
      ? productTypeData.find(
          (item: any) => item.key == dealData?.project_type_id
        )
      : null,
    postal_code: dealData?.postal_code
      ? dealData?.postal_code
      : null,
    // postal_code: dealData?.postal_code ? dealData?.postal_code : "",
    address: dealData?.address ? dealData?.address : "",
    phone: dealData?.phone ? dealData?.phone : "",
    remarks: dealData?.remarks ? dealData?.remarks : "",
    contract_signee: dealData?.contract_signee ? dealData?.contract_signee : "",
    contract_date: dealData?.contract_date
      ? moment(dealData?.contract_date)
      : null,
    contract_file: dealData?.contract_file ? dealData?.contract_file : "",
    payment_deposited_by: dealData?.payment_deposited_by
      ? dealData?.payment_deposited_by
      : "",
    payment_date: dealData?.payment_date
      ? moment(dealData?.payment_date)
      : null,
    paid_amount: dealData?.paid_amount ? dealData?.paid_amount : "",
    payment_file: dealData?.payment_file ? dealData?.payment_file : "",
  };

  const acceptedFileTypes: any= {
    "application/pdf": [".pdf"],
  };
  const removeDocument = (type: string, setFieldValue: any) => {
    if (type == "payment") {
      setFieldValue("payment_file", "");
      setPaymentFileData("");
    }

    if (type == "contract") {
      setFieldValue("contract_file", "");
      setContractFileData("");
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {id ? "Edit" : "Add"} New Deal
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={dealSchema}
              initialValues={initialValues}
              onSubmit={(values: any, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Card className="p-3 w-100 pageContentInner">
                    <div>
                      <Row>
                        <div className="col-md-4">
                          <InputText
                            controlId="order_no"
                            isDisable={id ? true : false}
                            label="Order No."
                            placeholder="Enter order no."
                            touched={touched.order_no}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.order_no}
                            value={values.order_no}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputText
                            controlId="name"
                            label="Customer"
                            placeholder="Enter customer name"
                            touched={touched.name}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.name}
                            value={values.name}
                          />
                        </div>
                        <div className="col-md-4">
                          <FormSelect
                            placeholder="Select"
                            label="Contract Type"
                            name="contract_type"
                            options={dealContractType}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            value={values.contract_type}
                            error={errors.contract_type}
                            touched={touched.contract_type}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-4">
                          <FormSelect
                            placeholder="Select"
                            label="Status"
                            name="status"
                            options={dealStatus}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            value={values.status}
                            error={errors.status}
                            touched={touched.status}
                          />
                        </div>
                        <div className="col-md-4 date-icon1">
                          <InputDatePicker
                            name="order_date"
                            label="Order Date"
                            slotProps={{
                              textField: {
                                size: "small",
                                placeholder: "Please Enter Order Date",
                                readOnly: true,
                                className: "form-control",
                                id: "order_date",
                              },
                            }}
                            value={values.order_date}
                            onChange={(name, newValue) =>
                              setFieldValue(name, newValue)
                            }
                            touched={touched.order_date}
                            errors={errors.order_date}
                          />                          
                        </div>
                        <div className="col-md-4">
                        <InputCurrencyText
                              name="amount"
                              label="Order Value (£)"
                              placeholder="Enter order value"
                              value={values.amount}
                              onChange={(value, name) => {
                                setFieldValue("amount", value);
                              }}
                              touched={touched.amount}
                              errors={errors.amount}
                            />
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-12">
                              <FormSelect
                                placeholder="Select"
                                label="Project Type"
                                name="project_type"
                                options={productTypeData}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.project_type}
                                error={errors.project_type}
                                touched={touched.project_type}
                              />
                            </div>
                            <div className="col-md-12">
                              <InputText
                                controlId="phone"
                                label="Phone Number"
                                placeholder="Enter Phone Number"
                                touched={touched.phone}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.phone}
                                value={phoneMask(values.phone)}
                                mask={commonMasking}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 custom-from-group">
                            <div className="position-relative ">
                              <InputTextArea
                                controlId="address"
                                label=" Address"
                                placeholder="Enter complete address"
                                touched={touched.address}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.address}
                                name="address"
                                value={values.address}
                                className="form-control textarea-height"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">

                        <FormAsyncSelect
                          id="postal_code"
                          name="postal_code"
                          label="Postal Code"
                          isAsync
                          isClearable
                          getOptions={getPostalCode}
                          value={values.postal_code}  
                          error={errors.postal_code}
                          touched={touched.postal_code}                     
                          onChange={(name: any, value: any) => {
                            setFieldValue(name, value);
                          }}
                        />
                         
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-8">
                          <div className="mb-3 custom-from-group">
                            <div className="position-relative ">
                              <InputTextArea
                                controlId="remarks"
                                label=" Remarks"
                                placeholder="Enter remarks"
                                touched={touched.remarks}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.remarks}
                                name="remarks"
                                value={values.remarks}
                                className="form-control remark-textarea"
                              />
                            </div>
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-lg-6">
                          <div className="drag-div-cover">
                            <div className="drag-title">
                              Upload Manual Contract
                            </div>
                            <div className="d-flex">
                              <div className="left-one">
                                <InputText
                                  controlId="contract_signee"
                                  label="Contract signee"
                                  placeholder="Enter contract signee name"
                                  touched={touched.contract_signee}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.contract_signee}
                                  value={values.contract_signee}
                                />
                              </div>
                              <div className="right-one date-icon1">
                                <InputDatePicker
                                  name="contract_date"
                                  label="Contract Date"
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      placeholder: "Select",
                                      className: "form-control",
                                      readOnly: true,
                                      id: "contract_date",
                                    },
                                  }}
                                  value={values.contract_date}
                                  onChange={(name, newValue) =>
                                    setFieldValue(name, newValue)
                                  }
                                  touched={touched.contract_date}
                                  errors={errors.contract_date}
                                />
                              </div>
                            </div>
                            {contractFileData && (
                              <div className="d-flex mt-2 justify-content-between">
                                <p className="file-size-text mb-1">
                                  This will overwrite the existing document with
                                  the newly uploaded file
                                </p>
                              </div>
                            )}
                            <div className="drag-drop">
                              <Dropzone multiple={false} accept={EmployeeDocumentTypeForDropZone}
                                onDrop={(e) =>
                                  handleFileUpload(
                                    e,
                                    setFieldValue,
                                    "contractFile"
                                  )
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="upload-image">
                                      <svg
                                        width="33"
                                        height="25"
                                        viewBox="0 0 33 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.2222 24.1666H8.65975C6.44794 24.1666 4.55819 23.401 2.99048 21.8697C1.42277 20.3385 0.638916 18.4669 0.638916 16.2551C0.638916 14.3593 1.2101 12.6701 2.35246 11.1874C3.49482 9.70478 4.98961 8.75686 6.83683 8.34367C7.44447 6.10756 8.65975 4.29679 10.4827 2.91138C12.3056 1.52596 14.3716 0.833252 16.6806 0.833252C19.5243 0.833252 21.9367 1.8237 23.9176 3.80461C25.8985 5.78551 26.8889 8.19784 26.8889 11.0416C28.566 11.236 29.9575 11.9591 31.0634 13.2109C32.1693 14.4626 32.7222 15.927 32.7222 17.6041C32.7222 19.427 32.0842 20.9765 30.8082 22.2525C29.5321 23.5286 27.9827 24.1666 26.1597 24.1666H18.1389V13.7395L20.4722 15.9999L22.5139 13.9583L16.6806 8.12492L10.8472 13.9583L12.8889 15.9999L15.2222 13.7395V24.1666Z"
                                          fill="#889EAE"
                                        />
                                      </svg>
                                    </div>
                                    <div className="drag-text">
                                      Drag & drop the document here or
                                    </div>                                    
                                    <div className="upload-text">
                                      Upload Contract Document
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                            <div className="d-flex mt-2 justify-content-between">
                              <p className="file-size-text">
                                File Size: 8 MB max
                              </p>
                              <p className="file-size-text">
                                Upload signed contract Document
                              </p>
                            </div>
                            {contractFileData && (
                              <div className="d-flex bg-sec-light pt-2 ps-2 pe-2 justify-content-between">
                                <p className="fs-14 mb-2">
                                  <img
                                    src={ContactComplicatd}
                                    alt="Icon"
                                    className="me-2"
                                  />
                                  Contract Completed
                                </p>
                                <p className="file-size-text mb-2">
                                  <img
                                    src={deleteIconRed}
                                    onClick={() =>
                                      removeDocument("contract", setFieldValue)
                                    }
                                  />
                                  <a
                                    href={contractFileData}
                                    download="Contract Completed"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={FileUploadIcon}
                                      className="ms-3"
                                    />
                                  </a>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="drag-div-cover">
                            <div className="drag-title">
                              Manage Offline Payment Status
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <InputText
                                  controlId="payment_deposited_by"
                                  label="Depositor Name"
                                  placeholder="Depositor name"
                                  touched={touched.payment_deposited_by}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.payment_deposited_by}
                                  value={values.payment_deposited_by}
                                />
                              </div>
                              <div className="col-md-4 date-icon1">
                              <InputDatePicker
                                  name="payment_date"
                                  label="Payment Date"
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      placeholder: "Select",
                                      className: "form-control",
                                      readOnly: true,
                                      id: "payment_date",
                                    },
                                  }}
                                  value={values.payment_date}
                                  onChange={(name, newValue) =>
                                    setFieldValue(name, newValue)
                                  }
                                  touched={touched.payment_date}
                                  errors={errors.payment_date}
                                />
                              </div>
                              <div className="col-md-4">
                                <InputCurrencyText
                                  name="paid_amount"
                                  label="Deposit Amount"
                                  placeholder="Enter Deposit Amount"
                                  value={values.paid_amount}
                                  onChange={(value, name) => {
                                    setFieldValue("paid_amount", value);
                                  }}
                                  touched={touched.paid_amount}
                                  errors={errors.paid_amount}
                                />                                 
                              </div>
                            </div>
                            {paymentFileData && (
                              <div className="d-flex mt-2 justify-content-between">
                                <p className="file-size-text mb-1">
                                  This will overwrite the existing document with
                                  the newly uploaded file
                                </p>
                              </div>
                            )}
                            <div className="drag-drop">
                              <Dropzone multiple={false} accept={EmployeeDocumentTypeForDropZone}
                                onDrop={(e) =>
                                  handleFileUpload(
                                    e,
                                    setFieldValue,
                                    "paymentFile"
                                  )
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="upload-image">
                                      <svg
                                        width="33"
                                        height="25"
                                        viewBox="0 0 33 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.2222 24.1666H8.65975C6.44794 24.1666 4.55819 23.401 2.99048 21.8697C1.42277 20.3385 0.638916 18.4669 0.638916 16.2551C0.638916 14.3593 1.2101 12.6701 2.35246 11.1874C3.49482 9.70478 4.98961 8.75686 6.83683 8.34367C7.44447 6.10756 8.65975 4.29679 10.4827 2.91138C12.3056 1.52596 14.3716 0.833252 16.6806 0.833252C19.5243 0.833252 21.9367 1.8237 23.9176 3.80461C25.8985 5.78551 26.8889 8.19784 26.8889 11.0416C28.566 11.236 29.9575 11.9591 31.0634 13.2109C32.1693 14.4626 32.7222 15.927 32.7222 17.6041C32.7222 19.427 32.0842 20.9765 30.8082 22.2525C29.5321 23.5286 27.9827 24.1666 26.1597 24.1666H18.1389V13.7395L20.4722 15.9999L22.5139 13.9583L16.6806 8.12492L10.8472 13.9583L12.8889 15.9999L15.2222 13.7395V24.1666Z"
                                          fill="#889EAE"
                                        />
                                      </svg>
                                    </div>
                                    <div className="drag-text">
                                      Drag & drop the document here or
                                    </div>                                    
                                    <div className="upload-text">
                                      Upload Payment Document
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                            <div className="d-flex mt-2 justify-content-between">
                              <p className="file-size-text">
                                File Size: 8 MB max
                              </p>
                              <p className="file-size-text">
                                Upload Payment receipt document
                              </p>
                            </div>
                            {paymentFileData && (
                              <div className="d-flex bg-sec-light pt-2 ps-2 pe-2 justify-content-between">
                                <p className="fs-14 mb-2">
                                  <img
                                    src={DepositePaid}
                                    alt="Icon"
                                    className="me-2"
                                  />
                                  Deposit Paid Slip
                                </p>
                                <p className="file-size-text mb-2">
                                  <img
                                    src={deleteIconRed}
                                    onClick={() =>
                                      removeDocument("payment", setFieldValue)
                                    }
                                  />
                                  <a
                                    href={paymentFileData}
                                    download="Deposit Paid Slip"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={FileUploadIcon}
                                      className="ms-3"
                                    />
                                  </a>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Card>
                  <div className="text-end my-3">
                    <CustomButtonCancel label="Cancel" />
                    <CustomButton
                      type="submit"
                      loading={btnLoading}
                      className="fw-semibold fs-13 text-white mw-60 mt-2 btn btn-primary"
                      variant="primary"
                      disabled={docLoading}
                    >
                      Submit
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
            {/* this popup will use for convert to project */}
            <DeletePopup
              isOpen={isSubmitConfirm}
              onClose={handleClose}
              onDelete={addOrUpdateDeals}
              label="Are you sure?"
              text="Both contract and payment document uploaded so it will convert this deal to project"
              actionType="info"
            />
          </div>
        </div>
      )}
    </>
  );
};
