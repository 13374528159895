import { axios } from "../services/axios";
import {
  GET_COUNTRIES,
  EMPLOYEE_FILL_MEDICAL_QUESTIONNAIRE,
  EMPLOYEE_GET_MEDICAL_QUESTIONNAIRE,
  EMPLOYEE_DOWNLOAD_MEDICAL_QUESTIONNAIRE,
  DOWNLOAD_BLANK_MEDICAL_QUESTIONNAIRE,
  ILLNESS_LIST
} from "../constants/paths";

export interface HealthQuestions {
  employment_ever_terminated: string | null;
  sickness_duration_last_12_months: string | null;
  sickness_duration_prior_to_last_12_months: string | null;
  alcohol_units: string | null;
  smoke: string | null;
  medicine: string | null;
  under_care: string | null;
  last_consult_gp: string | null;
  illness: string[] | null;
  details_of_illness: string | null;
  disabilities: string | null;
}

export type medicalDto = {
  post_offered: string;
  surname: string;
  forename: string;
  telephone: string;
  mobile: string;
  address: string;
  gp_name: string;
  gp_address: string;
  health_questions: HealthQuestions;
  declaration_full_name: string | null;
  declaration_date: Date | null;
  sign: string;
};

export const getIllnessList = (params:any|null = {}):Promise<any> =>{
  return axios.get(ILLNESS_LIST,{params});
};


/**
 * Post Medical details for an employee
 * @param {data: any (formdata) } params
 */
export const fillMedicalQuestionnaire = (data: any): Promise<any> => {
  const transformData = {
    ...data,
  };

  return axios.post(EMPLOYEE_FILL_MEDICAL_QUESTIONNAIRE, transformData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * Get Medical details by employee ID
 * @param {id: string } params
 */
export const getMedicalQuestionnaire = (
  id?: string | number | undefined | null
): Promise<any> => {
  return axios.get(
    id
      ? `${EMPLOYEE_GET_MEDICAL_QUESTIONNAIRE}/${id}`
      : EMPLOYEE_GET_MEDICAL_QUESTIONNAIRE
  );
};

/**
 * Download PDF containing Medical data for an employee
 * @param {id: string } params
 * @returns asset
 */
export const downloadMedicalQuestionnaire = (
  id: string | number
): Promise<any> => {
  return axios.get(`${EMPLOYEE_DOWNLOAD_MEDICAL_QUESTIONNAIRE}/${id}`, {
    responseType: "arraybuffer",
  });
};

/**
 * Download Blank Medical PDF
 * @param {id: string } params
 * @returns asset
 */
export const downloadBlankMedicalQuestionnaire = (): Promise<any> => {
  return axios.get(DOWNLOAD_BLANK_MEDICAL_QUESTIONNAIRE, {
    responseType: "blob",
  });
};
