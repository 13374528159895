import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import { ErrorMessage, Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../../components/common/InputText";
import { PoDataDTO } from "./po.type";
import FormSelect from "../../../../components/common/FormSelect";
import constants, {  
  PoStatus,
  PoPaymentStatus,
  PURCHASE_ORDER_STATUS,
} from "../../../../constants/constants";
import CustomButton from "../../../../components/common/CustomButton";
import {
  getAllVendorsName
} from "../../../../services/vendorService";
import Loader from "../../../../components/common/Loader";
import {
  createNewPo,
  getPODetailById,
  getPoNumber,
  getProductListAsync,
  updatePo,
} from "../../../../services/warehouseService";
import FormAsyncSelect from "../../../../components/common/AsyncSelect/FormAsyncSelect";
import { getOrderNumberListApi } from "../../../../services/dealService";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import InputTextArea from "../../../../components/common/InputTextArea";
import AddIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Close";
import moment from "moment";
import poSchema from "./validation";
import InputCurrencyText from "../../../../components/common/InputCurrencyText";
import { currencyMask } from "../../../../utility/common";

export const AddNewPo = () => {
  const [poNumber, setPoNumbers] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [poData, setPoData] = useState<any>({});
  const [vendors, setVendors] = useState<any[]>([]);
  const [imageLoading, setImageLoading] = useState(false);
  const navigate = useNavigate();

  let { id } = useParams();

  /**
   * Submit Method to call when PO save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: PoDataDTO, { setSubmitting }: any) => {
    const formData: any = {
      ...values,  
      due_date : moment(values.due_date).format(constants.dateFormats.databaseFormat)
    };
    delete formData.po_number;
    delete formData.product_id;
    delete formData.product_price;
    delete formData.product_qty;
    //console.log(formData);
    //  return;
    setBtnLoading(true);
    if (id) {
      updatePo(id, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/purchase-orders");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createNewPo(formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/purchase-orders");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getPoDetail = (id: string) => {
    setLoading(true);
    getPODetailById(id)
      .then((response) => {
        setPoData(response.data);        
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/purchase-orders");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/purchase-orders");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/purchase-orders");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    Promise.all([fetchPoNumber(),fetchVendors()])
    .then(() => {
      if (id) {
        getPoDetail(id);
      }else{
        setLoading(false)
      }
    });
  }, []);    

  const handleCancel = () => {
    navigate(-1);
  };

  const fetchPoNumber = async () => {
    return new Promise((resolve, reject) => {
      if(id){
        return resolve(true);
      }
      else{
        getPoNumber().then((response) => {
          setPoNumbers(response?.data?.po_number)
          return resolve(true);
        });
      }
    });
  }

  /**
   * fetch vendors to list in the vendor drop down
   * @async
   */
  const fetchVendors = async () => {
    return new Promise((resolve, reject) => {
      getAllVendorsName().then((response) => {
        const vendorOptions = response.data.map(
          (item: { id: number; vendor_name: string }) => ({
            key: item.id,
            value: item.vendor_name,
          })
        );
        setVendors(vendorOptions);
        return resolve(true);
      });
    });
  };

  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType: "project",
        showAll : true,
        showCp: 'No'
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const getProductsList = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,        
      };
      //console.log(requestParam);
      getProductListAsync(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const handleProducts = (
    formValues: any,
    setFieldValue: any
  ) => {
  
    const productArray = [...formValues.orderProducts]   
    if(formValues.product_id?.id && formValues.product_qty > 0 && formValues.product_price > 0){
      const foundProduct = productArray.find(product => product.product_id === formValues.product_id?.id);
      if(foundProduct)
      {
        toast(`Error: Product with SKU ${formValues.product_id?.name} already added.`, {
          type: toast.TYPE.ERROR,
        });
        return;
      }
      const fieldDataObj = {
        product_id: formValues.product_id?.id,
        qty: formValues.product_qty,
        price: formValues.product_price,
        amount: Number(parseInt(formValues.product_qty)*parseFloat(formValues.product_price)),
        product:{
          name : formValues.product_id?.name
        }
      }
      productArray.push(fieldDataObj);
      setFieldValue('orderProducts',productArray);
      setFieldValue('product_id',null);
      setFieldValue('product_qty',"");
      setFieldValue('product_price',"");
    }  
    else{
      toast(`Error: Please ensure you have selected a product, provided the quantity (Qty), and entered a valid price.`, {
        type: toast.TYPE.ERROR,
      });
      return;
    } 
   
  };

  const handleDeleteProduct = (formValues: any, productIndex: number, setFieldValue: any) => {
    const productArray = [...formValues.orderProducts];
    productArray.splice(productIndex, 1);
    setFieldValue('orderProducts',productArray);
    // setShelfNumbers((prevShelfNumbers: any) => {
    //   const updatedShelfNumbers = [...prevShelfNumbers];
    //   updatedShelfNumbers.splice(shelfIndex, 1);
    //   return updatedShelfNumbers;
    // });
  };
 // console.log(poData);
  const initialValues: any = {
    due_date: poData?.due_date ? moment(poData?.due_date) : null,
    po_number: id ? poData.po_number : poNumber,
    contract_id: poData?.contract_id ? poData?.project : null,
    vendor_id: poData?.vendor_id ? vendors.find((item) => item.key == poData?.vendor_id) : null,
    ref_number: poData?.ref_number ? poData?.ref_number : "",
    status: poData?.status ? PoStatus.find((item) => item.key == poData?.status) : PoStatus.find((item) => item.key == PURCHASE_ORDER_STATUS.INITIATED) ,
    pay_status: poData?.pay_status ? PoPaymentStatus.find((item) => item.key == poData?.pay_status)  : null,
    product_id: null,
    product_qty: "",
    product_price: "",
    pay_terms: poData?.pay_terms ? poData?.pay_terms : "",
    description: poData?.description ? poData?.description : "",
    bill_address: poData?.bill_address ? poData?.bill_address : "",
    shipping_address: poData?.shipping_address ? poData?.shipping_address : "",
    orderProducts:poData.orderProducts?.length ? poData.orderProducts : []
  };  
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {id ? "Edit" : "Create"} Purchase Order
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={poSchema(id)}
              initialValues={initialValues}
              onSubmit={(values: PoDataDTO, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-4">
                            <InputText
                              controlId="po_number"
                              label="PO Number"
                              placeholder="Enter PO Number"
                              isDisable
                              touched={touched.po_number}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.po_number}
                              value={values.po_number}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormAsyncSelect
                              id="contract_id"
                              name="contract_id"
                              label="Project Order Number"
                              isAsync
                              isClearable
                              getOptions={getOrderNumber}
                              value={values.contract_id}
                              onChange={(name: any, value: any) => {
                                setFieldValue(name, value);
                              }}
                            />
                          </Col>

                          <Col className="col-md-4">
                            <InputDatePicker
                              name="due_date"
                              label="Due Date"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  placeholder: "Select",
                                  className: "form-control",
                                  readOnly: true,
                                  id: "due_date",
                                },
                              }}
                              value={values.due_date}
                              onChange={(name, newValue) =>
                                setFieldValue(name, newValue)
                              }
                              touched={touched.due_date}
                              errors={errors.due_date}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select Vendor"
                              label="Vendor"
                              name="vendor_id"
                              options={vendors}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.vendor_id}
                              error={errors.vendor_id}
                              touched={touched.vendor_id}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="ref_number"
                              label="Reference Number"
                              placeholder="Enter ref no."
                              touched={touched.ref_number}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.ref_number}
                              value={values.ref_number}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Current Status"
                              name="status"
                              options={PoStatus}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.status}
                              error={errors.status}
                              touched={touched.status}
                            />
                          </Col>
                          {
                            id && (
                              <Col className="col-md-4">
                                <FormSelect
                                  placeholder="Select"
                                  label="Payment Status"
                                  name="pay_status"
                                  options={PoPaymentStatus}
                                  getOptionLabel={(option: any) => option.value}
                                  getOptionValue={(option: any) => option.key}
                                  onChange={(name: string, value: string) => {
                                    setFieldValue(name, value);
                                  }}
                                  value={values.pay_status}
                                  error={errors.pay_status}
                                  touched={touched.pay_status}
                                />
                              </Col>
                            )
                          }
                          
                        </Row>
                      </div>
                    </Card>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-12">
                            <h6 className="fs-18 fw-bold mb-3 info-text">
                              Products
                            </h6>
                            {/* { JSON.stringify(values)} */}
                            <Row>
                              <Col className="col-md-4 col-xl-4">
                                <FormAsyncSelect
                                  id="productSelect"
                                  name="product_id"
                                  label="Product Name"
                                  isAsync
                                  isClearable
                                  getOptions={getProductsList}
                                  value={values.product_id}
                                  onChange={(name: any, value: any) => {
                                    setFieldValue(name, value);
                                    setFieldValue("product_price", value.cost > 0 ? value.cost : value.price);
                                  }}
                                />
                              </Col>
                              <Col className="col-md-3 col-xl-1">
                              <InputCurrencyText
                                  name="product_qty"
                                  label="Quantity"
                                  placeholder="Qty."                          
                                  value={values.product_qty}
                                  isInteger={true}
                                  onChange={(value, name) =>{
                                    setFieldValue("product_qty", value);
                                  }}
                                  touched={touched.product_qty}
                                  errors={errors.product_qty}
                                />
                              </Col>
                              <Col className="col-md-4 col-xl-3">
                              <InputCurrencyText
                                  name="product_price"
                                  label="Price"
                                  placeholder="Enter Price"  
                                  isDisabled                          
                                  value={values.product_price}
                                  onChange={(value, name) =>{
                                    setFieldValue("product_price", value);
                                  }}
                                  touched={touched.product_price}
                                  errors={errors.product_price}
                                /> 
                              </Col>
                              <Col className="col-md-1 col-xl-1">
                                <Button
                                  className="fw-semibold fs-12  btn btn-outline-primary mt-4 w-40 h-40"
                                  variant="custom"
                                  type="button"
                                  onClick={() => handleProducts(values,setFieldValue)}
                                  // onClick={(name: any, value: any) => {
                                  //   handleProducts(value, setFieldValue);
                                  // }}                              
                                >
                                <AddIcon className="fs-15" />                            
                                </Button>
                              </Col>                              
                            </Row>
                            {/* {JSON.stringify(errors)} */}
                            <Row>
                              <Col className="col-md-12 col-xl-9">
                              <div className="table-responsive purchase-order-product-table">
                               <table className="table">
                                { values.orderProducts && (

                               <thead>
                    
                                  <tr key="productHeader0" >
                                  <th className="info-text fs-14">
                                    Name
                                  </th>
                                  <th className="info-text fs-14">
                                    Price 
                                  </th>
                                  <th className="info-text fs-14">
                                    Qty.
                                  </th>
                                  <th className="info-text fs-14">
                                    Sub- Total
                                  </th>
                                  <th className="info-text fs-14">                            
                                  </th>
                                </tr>                               
                                </thead>
                                )}
                            {values.orderProducts.map((orderProduct: any, index: any) => (
                            
                                <tbody key={`productlist_${index}`}>
                                  
                                  <tr>
                                  <td className="fs-14">
                                    {orderProduct?.product?.name}
                                  </td>
                                  <td className="fs-14 text-nowrap">
                                  {currencyMask(orderProduct?.price)} 
                                  </td>
                                  <td className="fs-14 text-nowrap">
                                  {orderProduct?.qty} 
                                  </td>
                                  <td className="fs-14 text-nowrap">
                                  {currencyMask(orderProduct?.amount)} 
                                  </td>
                                  <td className="fs-14">
                                    {
                                      !(orderProduct?.received_qty || orderProduct?.qty_returned) && (
                                        <Button
                                      className="fw-semibold fs-12 p-0  btn btn-link text-danger"
                                      variant="custom"
                                      type="button"
                                      onClick={() => handleDeleteProduct(values, index,setFieldValue)}                                                                  
                                    >
                                    <DeleteIcon />                            
                                    </Button>
                                      )
                                    }
                                    
                                  </td>
                                </tr>
                                  
                                </tbody>
                                
                               
                          ))}
                           </table>
                           <ErrorMessage
                                name="orderProducts"
                                component="div"
                                className="text-danger fs-14"
                                />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-6">
                            <InputTextArea
                              controlId="pay_terms"
                              label="Payment Terms"
                              placeholder="Enter Payment Terms"
                              touched={touched.pay_terms}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.pay_terms}
                              name="pay_terms"
                              value={values.pay_terms}
                              className="form-control textarea-height"
                            />
                          </Col>
                          <Col className="col-md-6">
                            <InputTextArea
                              controlId="description"
                              label="Description"
                              placeholder="Enter Description"
                              touched={touched.description}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.description}
                              name="description"
                              value={values.description}
                              className="form-control textarea-height"
                            />
                          </Col>
                          <Col className="col-md-6">
                            <InputText
                              controlId="shipping_address"
                              label="Shipping Address"
                              placeholder="Enter Shipping Address"
                              touched={touched.shipping_address}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.shipping_address}
                              name="shipping_address"
                              value={values.shipping_address}
                              
                            />
                          </Col>
                          <Col className="col-md-6">
                            <InputText
                              controlId="bill_address"
                              label="Billing Address"
                              placeholder="Enter Billing Address"
                              touched={touched.bill_address}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.bill_address}
                              name="bill_address"
                              value={values.bill_address}
                              
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-1">
                          <div
                            className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                            onClick={handleCancel}
                          >
                            Cancel
                          </div>

                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={imageLoading || btnLoading}
                            className="fw-semibold fs-13 text-white mw-60 mt-2"
                            variant="primary"
                          >
                            {id ? "Update" : "Add"}
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
