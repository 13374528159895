import { axios } from "../../services/axios";
import {
  ADD_SECTION,
  SECTION_DETAILS,
  UPDATE_SECTION,
  DELETE_SECTION,
  DELETE_FIELD,
  SECTION_DETAILS_ID

} from "../../constants/paths";

//Add section data
export const addSectionAPICall = async (data) => {
  return await axios.post(ADD_SECTION, data);
};

//Get Section data list by field list api
export const getSectionApiCall = async (id) => {
  return await axios.get(`${SECTION_DETAILS}/${id}`);
};

//Get a section  data by id
export const getSectionByIdApiCall = async (id) => {
  return await axios.get(
    `${SECTION_DETAILS_ID}/${id}`
    //{ headers: loginTokenHeader() }
  );
};

//Update a field sheet data by id
export const updateSectionAPICall = async (id, data) => {
  return await axios.patch(
    `${UPDATE_SECTION}/${id}`,
    data
    //{ headers: loginTokenHeader() }
  );
};

//Delete section by id

export const deleteSectionApiCall = async (id) => {
  return await axios.delete(
    `${DELETE_SECTION}/${id}`
    //  { headers: loginTokenHeader() }
  );
};

export const deleteFieldApiCall = async (fieldid) => {
  return await axios.delete(
    `${DELETE_FIELD}/${fieldid}`
    //  { headers: loginTokenHeader() }
  );
};
