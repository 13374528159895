import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import moreIcon from "../../../../assets/images/more_horiz.svg";
import SurveyFindingPage from "./surveyFindingPage";
import { ITypePropsReviewAndSubmit } from "../surveyInterface";
import { getContractPdfAndSurveyForm } from "../../../../services/survey/survey.service";
import Loader from "../../../../components/common/Loader";
import { updateReviewComparative } from "../../../../services/project.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PreviewSurveyPage: FC<ITypePropsReviewAndSubmit> = ({
  survey_id,
  comparePageSubmit,
  setComparePageSubmit,
  onlyView=false,
  contract_id,
  setIsMutation,
  currentTabIsDone
}) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getContractPdfAndSurveyForm(survey_id)
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((error) => {
       navigate(-1);
         setLoading(false);
      });
  };

  const markReviewComparativePage = () => {
     if(contract_id){
      updateReviewComparative(contract_id).then((res) => {        
          toast.success(res.msg);
          setIsMutation(true); //to refresh the survey section in the project list
      }
      ).catch((error) => {
        toast.error(error.response.data.msg);
      });

     }
  }

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="reviewSurveyWrapper">
          <Col className="col-md-12 ">
            <div className="d-flex justify-content-between pb-3 ">
              <div>
                <h3 className="fs-20 fw-bold info-text mb-0">
                  Review Product Item
                </h3>
              </div>
            </div>
          </Col>
          <Row>
            <Col className="col-md-6">
              <Card className="p-3 w-100  mb-3 survey-finding">
                <h3 className="fs-20 fw-bold info-text mb-3">Sales Contract</h3>
                <Card className="p-3 w-100  mb-3">
                  {data?.survey?.project?.contract_file ? (
                    <object
                      data={`${data?.survey?.project?.contract_file}#toolbar=0`}
                      width="100%"
                      height="500"
                    ></object>
                  ) : (
                    <h3>No Contract PDF Available</h3>
                  )}
                </Card>
              </Card>
            </Col>
            <Col className="col-md-6">
              <SurveyFindingPage
                data={data?.template}
                survey_id={survey_id}
                comparePageSubmit={comparePageSubmit}
                setComparePageSubmit={setComparePageSubmit}
                onlyView={onlyView}
              />
            </Col>
          </Row>
          {!currentTabIsDone &&  (onlyView && (  
          <div className="col-md-12">
          <div className="text-end my-3">
            
            <Button className="fw-semibold fs-13 text-white mw-90 mt-2 cursor-pointer" onClick={markReviewComparativePage}>
              Review Comparative Study
            </Button>
          </div>
        </div>
          )  
          )}
        </div>
      )}
    </>
  );
};

export default PreviewSurveyPage;
