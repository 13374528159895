import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { addSchema } from "./validation";
import InputText from "../../../components/common/InputText";
import FormSelect from "../../../components/common/FormSelect";
import { getMileageCost } from "../../../services/authService";
import CustomButton from "../../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import InputDatePicker from "../../../components/common/InputDatePicker";
import moment, { Moment } from "moment";
import constants, { formTemplateMaxFileSizeMB, pdfFileTypes } from "../../../constants/constants";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import { getOrderNumberListApi } from "../../../services/dealService";
import InputCurrencyText from "../../../components/common/InputCurrencyText";
import { currencyMask } from "../../../utility/common";
import InputTextArea from "../../../components/common/InputTextArea";
import GeneralFileUploader from "../../../components/common/GeneralFileUploader";
import { FORM_TEMPLATE_SIZE_ERROR, PDF_TYPE_ERROR } from "../../../validations/ValidationErrors";
import { createExpenseApi, getExpenseCategoriesApi, uploadExpenseFileApi } from "../../../services/expenseService";

interface IHolidays {
  id?: number;
  title?: string;
  category?: string;
  contract_id?: string;
  price?: number;
  qty?: number;
  amount?: number;
  vendor_name?: string;
  date?: string;
  remarks?: string;
  file?: string;
  createdAt: string;
  updatedAt: string;
}

interface AddProps {
  isOpen: boolean;
  hId?: number | string | undefined;
  onClose: () => void;
}

interface ProjectOption {
  id: string;
  full_name: string;
}

interface FormValues {
  title: string;
  category: any;
  contract_id: any;
  price: number | string | undefined;
  qty: number | string | undefined;
  amount: number | string | undefined;
  vendor_name:string;
  remarks:string;
  file:string;
  filename:string;
  date: any;
  origin_place:string;
  destination_place:string;
}
const LogExpensesPopup: React.FC<AddProps> = ({ isOpen, onClose, hId }) => {
  const [editableData, setEditableData] = useState<IHolidays>();
  const [projectOwnerOption, setProjectOwnerOption] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [settingData, setSettingsData] = useState<any>();
  const [categoryData,setCategoryData] = useState<any>([])
  const filterOptions = (editableOptions: any) => {
    const filteredArray = projectOwnerOption.filter((itemTwo: any) =>
      editableOptions.some((itemOne: any) => itemOne.user_id === itemTwo.key)
    );

    return filteredArray;
  };
  const initialValues = {
    title: "",
    category: null,
    contract_id: null,
    price : "",
    qty: "",
    amount: 0,
    vendor_name: "",
    remarks: "",
    file: "",
    filename: "No file selected",
    date:
      editableData && editableData.date
        ? moment(editableData.date)
        : null,    
    origin_place : "",
    destination_place : "",
    submitType : "",
  };

  const id = hId;
  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting, resetForm }: any) => {
    const formData = {
      ...values,      
      date: moment(values.date).format(
        constants.dateFormats.databaseFormat
      ),
    };
    
    console.log(values.category,formData);
   // return;
    setBtnLoading(true);

    createExpenseApi(formData)
    .then((response) => {
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      if(formData.submitType == "savenew"){
        resetForm();
        setBtnLoading(false);
      }
      else{
        onClose();
      }
    })
    .catch((error) => {
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, {
          type: toast.TYPE.ERROR,
        });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, {
          type: toast.TYPE.ERROR,
        });
      }
    });
    
  };

  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType: "project",
        showAll:"true"
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  /**
   * Upload form template PDF/Word file
   * @param {events, setFieldValue}
   * @returns {*}
   */
  const uploadFile = (event: any, setFieldValue: any) => {
    const file = event.target.files[0];
    if(file){
      // Check file type
      if (!pdfFileTypes.includes(file.type)) {
        toast(PDF_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (file.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
        toast(FORM_TEMPLATE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } 
      else {
        setImageLoading(true);        
  
        // Create FormData object
        const formData = new FormData();
  
        // Append the file to the FormData object
        formData.append("file", file);
  
        uploadExpenseFileApi(formData)
          .then((response) => {
            setImageLoading(false);
            setFieldValue('file', response.data);
            setFieldValue('filename',file.name);            
          })
          .catch(() => {
            setImageLoading(false);
          });
      }
    }
  };

  const handleTotalAmount = (qty: any, perItemCost:any, setFieldValue: any) => { 
    qty = qty ? qty : 0;
    perItemCost = perItemCost ? perItemCost : 0;
    const totalAmount = Number.parseFloat(qty)* Number.parseFloat(perItemCost);
    setFieldValue('amount',totalAmount);
  }

  const getMileageCostSetting = async () => {
    return new Promise((resolve, reject) => {
      getMileageCost().then((response) => {
        setSettingsData(response?.data);
        return resolve(true);
      });
    });
  };

  const updateKeys = (arr: any[]) => {
    return arr.map((item: { id: any; name: any; parent_id: any; is_mileage_type: any; options: any; }) => {
      // Create a new object with the updated keys
      let newItem = {
        ...item,
        key: item.id,
        value: item.name        
      };
      
      // If the item has options, recursively update the keys in the options array
      if (item.options) {
        newItem.options = updateKeys(item.options);
      }
  
      return newItem;
    });
  };

  const getMileageCategories = async () => {
    return new Promise((resolve, reject) => {
      getExpenseCategoriesApi().then((response) => {
       // setSettingsData(response?.data);
       const catData = response.data;
       const categoryOptions = updateKeys(catData);

     // console.log(categoryOptions);
        setCategoryData(categoryOptions);
        return resolve(true);
      });      

      
     // return resolve(true);
      /* getMileageCost().then((response) => {
        setSettingsData(response?.data);
      }); */
    });
  };

  

  useEffect(() => {
    Promise.all([getMileageCategories()]).then(() => {
      setLoading(false);
    });
    
  }, []);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="modal-middle-size"
          show={isOpen}
          onHide={onClose}
          centered
        >
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative pb-0">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">Log Expenses</h4>
                  <Row className="row g-3">
                    <Col className="col-md-6">
                      <InputText
                        controlId="title"
                        label="Expense Title"
                        placeholder="Enter expense title"
                        touched={touched.title}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.title}
                        value={values.title}
                      />
                    </Col>
                    <Col className="col-md-6">
                      <FormSelect
                        placeholder="Select"
                        label="Category"
                        name="category"
                        options={categoryData}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.key}
                        onChange={(name: string, value: any) => {
                          setFieldValue(name, value);  
                        }}
                        value={values.category}
                        error={errors.category}
                        touched={touched.category}
                      />
                    </Col>                    
                    <Col className="col-md-6">
                      <FormAsyncSelect
                        id="contract_id"
                        name="contract_id"
                        label="Project Order Number"
                        isAsync
                        isClearable
                        getOptions={getOrderNumber}
                        value={values.contract_id}
                        error={errors.contract_id}
                        touched={touched.contract_id}
                        onChange={(name: any, value: any) => {
                          setFieldValue(name, value);
                        }}
                      />
                    </Col>                    
                    {/* { !values?.category?.is_mileage_type && ( */}
                    <Col className="col-md-3">
                      <InputCurrencyText
                        name="price"
                        label={ values?.category?.is_mileage_type ? "Price Per Mile" : "Per Item Cost"}                        
                        tooltip={ values?.category?.is_mileage_type && "Please enter a correct value to calculate and approve this expense."}                        
                        placeholder="Enter"
                        value={values.price}
                        // isDisabled={values?.category?.is_mileage_type}
                        onChange={(value, name) => {
                          setFieldValue("price", value);
                          handleTotalAmount(values.qty, value, setFieldValue);
                        }}
                        handleBlur={handleBlur}
                        touched={touched.price}
                        errors={errors.price}
                      />
                    </Col>
                    {/* )}                     */}
                    <Col className="col-md-3">
                      <InputCurrencyText
                        name="qty"
                        label={ values?.category?.is_mileage_type ? "Distance" : "Quantity"}
                        placeholder={ values?.category?.is_mileage_type ? "Enter Distance" : "Enter Quantity"}
                        value={values.qty}
                        isInteger
                        onChange={(value, name) => {
                          setFieldValue("qty", value);
                          handleTotalAmount(
                            value,
                            values.price,
                            setFieldValue
                          );
                        }}
                        handleBlur={handleBlur}
                        touched={touched.qty}
                        errors={errors.qty}
                      />
                    </Col>
                    <Col className="col-12 mt-0">
                      <div className="text-end">
                        <span className="fs-14  me-2">
                          Total Amount:
                        </span>{" "}
                        <span className="fw-bold">{currencyMask(values.amount)}</span>
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <InputText
                        controlId="vendor_name"
                        label="Vendor"
                        placeholder="Enter Vendor name"
                        touched={touched.vendor_name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.vendor_name}
                        value={values.vendor_name}
                      />
                    </Col>
                    <Col className="col-md-6">
                      <InputDatePicker
                        name="date"
                        label="Date"
                        slotProps={{
                          textField: {
                            size: "small",
                            placeholder: "Select",
                            readOnly: true,
                            className: "form-control",
                            id: "date",
                          },
                        }}
                        value={values.date}
                        onChange={(name, newValue) =>
                          setFieldValue(name, newValue)
                        }
                        disableFuture
                        touched={touched.date}
                        errors={errors.date}
                      />
                    </Col>
                    <Col className="col-md-12">
                      <InputTextArea
                        controlId="remarks"
                        label="Notes"
                        placeholder="Enter notes"
                        touched={touched.remarks}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.remarks}
                        name="remarks"
                        value={values.remarks}
                        className="form-control textarea-height"
                      />
                    </Col>
                    <Col className="col-md-12">
                      <GeneralFileUploader
                        uploadFile={(e) => uploadFile(e, setFieldValue)}
                        imageLoading={imageLoading}
                        acceptFormat=".pdf"
                        label="Upload Invoice"
                        filename={values.filename}
                      />

                      <ErrorMessage
                        name="file"
                        component="div"
                        className="text-danger fs-14"
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    type="button"
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    disabled={btnLoading || imageLoading}
                    className="fw-semibold fs-13 text-white mw-60 mt-2"
                    variant="primary"
                    onClick={() => {
                      setFieldValue("submitType", "save");
                    }}
                  >
                    Submit
                  </CustomButton>
                  <CustomButton
                    type="submit"                    
                    loading={btnLoading}
                    disabled={btnLoading || imageLoading}
                    className="fw-semibold fs-13 text-white mw-60 mt-2"
                    variant="primary"
                    onClick={() => {
                      setFieldValue("submitType", "savenew");
                    }}
                  >
                    Save and Add New
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default LogExpensesPopup;
