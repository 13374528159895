import { Card, Col, Row } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
 import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarEventModal from "../Planner/CalendarEventModal";
import { getMyTeamTodayTask } from "../../services/task.service";
import moment from "moment";
 
 const MyTask = () => {
    const [data, setData] = useState({} as any);
    const [loading, setLoading] = useState(true);
    const [isOpenEventModal, setIsOpenEventModal] = useState(false);
    const [modalEventContent, setModalEventContent] = useState({} as any);

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
      getMyTeamTodayTask().then((res) => {
            setData(res.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        }
        );
            
    }

    const handleEventModal = (row: any) => {

      const data = {event: {extendedProps : row, title : row.title, end : row.end_date, start: row.start_date, project: row.project}  }
       
        setModalEventContent(data);
        setIsOpenEventModal(true);
    }
    const columns: GridColDef[] = [
      {
        field: "serialNumber",
        headerName: "S. No.",
        sortable: false,
        filterable: true,
        hideable:false,
        width: 40
      },
      {
        field :'title',
        headerName: 'Task Name',
        flex: 1,
        filterable: true,

      },
      {
        field : 'assigned_to',
        headerName: 'Assigned To',
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
               {params.row.assignee.full_name}
            </div>
          );
        },
        valueGetter: (params) => {
          return params.row.assignee.full_name;
        }

      },
      {
        field :'job_type',
        headerName: 'Task Type',
        flex: 1,
        filterable: true,
      },
      {
        field :'project',
        headerName: 'Project Order No',
        flex: 1,
        filterable: true,
        renderCell: (params) => {
          return (
            <div title={params.row.project.project_no_str}>
               {params.row.project.project_no_str}
            </div>
          );
        },
        valueGetter: (params) => {
          return params.row.project.project_no_str;
        }
        
      },
      {
        field:'start_date',
        headerName: 'Task Date',
        filterable: true,
        flex: 1,
      },
      {
        field :'start_time',
        headerName: 'Task Time',
        flex: 1,
        renderCell: (params) => { 
          return (
            <div>
               {moment(params.row.start_date+' '+params.row.start_time).format('hh:ss A')} - {moment(params.row.end_date+' '+params.row.end_time).format('hh:ss A')}
            </div>
          );
        }
      },
      {
        field : 'status',
        headerName: 'Task Status',
        flex: 1,
        renderCell(params) {
          if(params.row.status){
            const className = params.row.status.toLowerCase().replace(/\s+/g, '-');
            return <span className={`asset-status-${className}`}>{params.row.status}</span>
          }else{
            return '-';
          }
        }
      },
      {
        field : 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params : any) => {
          return (
            <div>
              <a  href="javascript:void(0)" onClick={()=>handleEventModal(params.row)} className="text-primary"><VisibilityIcon/></a>
            </div>
          );
        }
      }
      ];


      
      
      
  return (
    <>
      <Row>
      <CalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        modalEventContent={modalEventContent}
        onlyEmployeeView={true}
      />
        <Col md={12} className="mb-3">
          <h2 className="fs-20 info-text fw-bold">Today’s Tasks</h2>
           <Card className="p-0 w-100 ">
           <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
            <DataGrid
                  rows={data.length ? data.map((row: any, index: number) => ({
                    ...row,
                    serialNumber: index + 1,
                  })):[]}               
                  columns={columns}  
                  pageSizeOptions={[5]}
                  rowSelection={false}
              />
              </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MyTask;
