import * as Yup from "yup";
import { MAXIMUM_255_CHAR, MAXIMUM_30_CHAR, MINIMUM_3_CHAR } from "../../../validations/ValidationErrors";

const validationSchema = Yup.object({
  title: Yup.string()
  .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Title"),
  description: Yup.string()
  .trim()
    .required()
    .min(3,MINIMUM_3_CHAR)
    .max(255,MAXIMUM_255_CHAR)
    .label("Description")
    
 
});

export { validationSchema };
