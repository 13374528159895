import { axios } from "../../services/axios";
import {
  SURVEY_LIST,
  SURVEY_VIEW,
  GET_SURVEY_TEMPLATE,
  UPDATE_SURVEY_TEMPLATE,
  SURVEY_IMAGES_UPLOAD,
  GET_SIGNED_IMAGES,
  UPLOAD_SIGNATURE_CANVAS,
  SURVEY_PRODUCT,
  SURVEY_PRODUCT_LIST_ASYNC,
  SURVEY_PRODUCT_SAVE,
  GET_CONTRACT_PDF_SURVEY_FORM,
  SURVEY_COMPLETE,
  SURVEY_DATA
} from "../../constants/paths";
import { AxiosResponse } from "axios";
import { AnyARecord } from "dns";

//survey list api call
export const getSurveyListApi = (formData: any): Promise<any> => {
  return axios.get(SURVEY_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
      ...(formData.type ? { type: formData.type } : {}),
    },
  });
};

//survey view api call
export const getSurveyApi = (
  id: string | undefined
): Promise<AxiosResponse> => {
  return axios.get(`${SURVEY_VIEW}/${id}`);
};

//get Survey Template List Api
export const getSurveyTemplateAPI = (id: string): Promise<AxiosResponse> => {
  return axios.get(`${GET_SURVEY_TEMPLATE}/${id}`);
};

//update Survey Template Api
export const updateSurveyTemplateAPI = (
  id: string,
  formData: any
): Promise<AxiosResponse> => {
  return axios.put(`${UPDATE_SURVEY_TEMPLATE}/${id}`, formData);
};

//mulitple image upload api
export const uploadImagesApi = (formData: any): Promise<AxiosResponse> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(SURVEY_IMAGES_UPLOAD, formData, config);
};

//get signed url  images for survey
export const getSignedUrlApi = (data: any): Promise<AxiosResponse> => {
  return axios.post(`${GET_SIGNED_IMAGES}`, data);
};

//upload signature canvas
export const uploadSignatureApi = (formData: any): Promise<AxiosResponse> => {
  return axios.post(`${UPLOAD_SIGNATURE_CANVAS}`, formData);
};

//get products for survey submit
export const getProductsForSurveySubmit = (
  survey_id: string
): Promise<AxiosResponse> => {
  return axios.get(`${SURVEY_PRODUCT}/${survey_id}`);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const getProductListAsync = (
  params: any | null
): Promise<AxiosResponse> => {
  return axios.get(SURVEY_PRODUCT_LIST_ASYNC, { params });
};


//survey Product customize save
export const saveSurveyProductApi = (data: any): Promise<AxiosResponse> => {
  return axios.post(SURVEY_PRODUCT_SAVE, data);
}

//get contract pdf and survey form by survey id
export const getContractPdfAndSurveyForm = (survey_id:  string | undefined ): Promise<AxiosResponse> => {
  return axios.get(`${GET_CONTRACT_PDF_SURVEY_FORM}/${survey_id}`);
}


// survey complete api
export const surveyCompleteApi = (data : any): Promise<any> => {
  return axios.post(`${SURVEY_COMPLETE}`, data);
};

//get survey data by contract id 
export const getSurveyDataByContractId = (contract_id: string | undefined): Promise<AxiosResponse> => {
    return axios.get(`${SURVEY_DATA}/${contract_id}`);
}