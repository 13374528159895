import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridFilterModel,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import constants, { PoStatus, pageSetting } from "../../../constants/constants";
import { currencyMask } from "../../../utility/common";
import { getProductRecievedList } from "../../../services/productService";
import moment from "moment";
import { getProjectProcurementList } from "../../../services/project.service";

interface AddProps {
  projectId: string | number | undefined;
  projectsData: any;
  setMutate : () => void;
}

const ProjectProcurementProducts: React.FC<AddProps> = ({
  projectId,
  projectsData,
  setMutate
}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getProjectProcurementList(projectId, defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
        if(response?.data?.refreshProjectData){
          setMutate();
        }
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const prodcurementStatus = [
    { key: "Procured", value: "Procured", tagClass: "primary-tag" },
    { key: "PO Raised", value: "PO Raised", tagClass: "info-tag" },
    { key: "Out of Stock", value: "Out of Stock", tagClass: "danger-tag" },
  ];

  const columns: GridColDef[] = [
    {
      field: "$product.name$",
      headerName: "Product",
      flex: 2,
      hideable: false,
      renderCell(params) {
        return (
          <span title={params.row.product.name}>{params.row.product.name}</span>
        );
      },
    },
    {
      field: "qty",
      headerName: "Quantity Needed",
      type: "number",
      flex: 1,
      renderCell(params) {
        return <span title={params.row?.qty}>{params.row?.qty}</span>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 1,
      renderCell(params) {
        return (
          <span title={currencyMask(params.row?.price)}>
            {currencyMask(params.row?.price)}
          </span>
        );
      },
    },
    {
      field: "stock_status",
      headerName: "Stock Available",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return params.row?.qty <= params.row?.product.stock_qty ? "Yes" : "No";
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type: "singleSelect",
      valueOptions: prodcurementStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf"
      ),
      renderCell(params) {
        if (params.row.status) {
          const statusName = prodcurementStatus.find(
            (item) => item.key == params.row.status
          );
          return (
            <span
              title={statusName?.value}
              className={`py-1 ms-0 px-2 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
            >
              {statusName?.value}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  return (
    <React.Fragment>
      <div className="flex-grow-1 pageContent pb-2">
        <Card className=" p-3 w-100 pageContentInner mb-3">
          <Row>
            <Col>
              {loading ? (
                <span className="align-content-center d-block text-center">
                  <Spinner />
                </span>
              ) : (
                // <Spinner isLoading={loading} />
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  disableRowSelectionOnClick
                  pageSizeOptions={[5]}
                />
              )}
            </Col>
          </Row>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ProjectProcurementProducts;
