import React from 'react';
import { Image } from 'react-bootstrap';
import AddPhotoAlternate from "../../assets/images/add_photo_alternate.svg";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

interface FileUploadProps {
  imageLoading: boolean;
  label? : string;
  uploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filename?: string;
  acceptFormat?: string;
  downloadExisting?: boolean;
  fileurl?: string | null;
  isMultiple ?: boolean;
}

const GeneralFileUploader: React.FC<FileUploadProps> = ({ imageLoading, label, uploadFile, filename, acceptFormat = ".pdf, .doc, .docx", downloadExisting = false, fileurl = null , isMultiple=false}) => {
  return (
    <>
    {label && <label className="lh-sm control-label fs-14 fw-bold form-label">
    {label}
  </label>}
    <div className="position-relative">
      {imageLoading ? (
        <label className="file-box">
          <span className='h-100'>
            <Spinner />
          </span>
        </label>
      ) : (
        

      <label className="browse-cover">
        <input type="file" name="image" onChange={uploadFile} accept={acceptFormat} multiple={isMultiple} />
        <span className="btn btn-outline-primary  fw-bold me-2">
          <span className="me-2"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.2501 11.8753V14.2503H4.75008V11.8753H3.16675V14.2503C3.16675 15.1212 3.87925 15.8337 4.75008 15.8337H14.2501C15.1209 15.8337 15.8334 15.1212 15.8334 14.2503V11.8753H14.2501ZM5.54175 7.12533L6.658 8.24158L8.70841 6.19908V12.667H10.2917V6.19908L12.3422 8.24158L13.4584 7.12533L9.50008 3.16699L5.54175 7.12533Z" fill="#21AC96"/>
          </svg></span>Browse
        </span>
        {downloadExisting === false && <span className="fs-14">{filename}</span>}
        {downloadExisting === true && fileurl && <Link to={fileurl} target="_blank">{filename}</Link>}
      </label>
      )}
    </div>
    </>
  );
};

export default GeneralFileUploader;
