
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Card, Col, Image, Row, Spinner } from "react-bootstrap";

import EditIcon from "@mui/icons-material/Edit";
import Button from "react-bootstrap/Button";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InputSwitchBox from "../../../components/common/InputSwitchBox";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  getReportingManagerEmployeeDetailsApi,
  updateEmployeeStatusApi,
} from "../../../services/employeeService"
import Loader from "../../../components/common/Loader";
import EmployeeDetails from '../../Employees/View/EmployeeDetails';
import { checkAbility, getTagClass } from "../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import EmployeeSentEmails from "../../Employees/View/EmployeeSentEmails";
import EmployeeMeetings from "../../Employees/View/Meetings";
import EmployeeDocuments from "../../Employees/View/Documents";
import EmployeeInduction from "../../Employees/View/EmployeeInduction";
import EmployeeAllocatedAssets from "../../Employees/View/EmployeeAllocatedAssets";
import EmployeeLeaves from "../../Employees/View/EmployeeLeaves";
import {
  MEETING_SOURCE_PAGES
} from "../../../constants/constants";

export default function ViewEmployee() {
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState<any>({});
  const navigate = useNavigate();
  const [isMutation, setIsMutation] = useState(false);
  const [checkEditPermission, setCheckEditPErmission] = useState(() => {
    return checkAbility(PERMISSION_ACCESS.EDIT,PERMISSION_MODULE.ALL_EMPLOYEE)
  });
  let { id, tabKey } = useParams<string>();
  if(tabKey === undefined){
    tabKey = 'home';
  }

  const employeeDetails = employee?.employeeDetails ? employee?.employeeDetails : {};
  const kinDetails = employee?.kinDetails ? employee?.kinDetails : {};
  const employeeBankDetails = employee?.emplyeeBankDetails ? employee?.emplyeeBankDetails : {};

  /**
   * Get employee details from server
   * @async
   * @param {id} ID of employee
   */
  const getEmployeeDetail = async (id: string) => {
    setLoading(true);
    await getReportingManagerEmployeeDetailsApi(id)
      .then((response: any) => {
        setEmployee(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
        navigate('/my-team');
      });
  };

  // This is handler which gets called when a tab selected.
  const handleTabSelect = (key: string | null) => {
    navigate(`/my-team/view/${id}/${key}`);
  };

  useEffect(() => {
    if (id) {
      getEmployeeDetail(id);
    }
    else {
      navigate(-1);
    }
  }, [isMutation]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">{`${employee.first_name} ${employee.last_name}`}
                  <span title="Conditional Onboarding" className="py-1 px-2 rounded-1 Onboarding-tag d-inline-block fs-11">{employeeDetails.designation}</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" w-100 pageContentInner">
              <div className="d-flex flex-column h-100 rounded-0">
                <div className="d-flex justify-content-between border-bottom p-3 align-items-center">

                  <div className="d-flex">
                    <div className="fs-14 me-3">Status:
                    <span
                      title={employeeDetails.employment_status} className={`py-1 px-2 rounded-1 ${getTagClass(employeeDetails.employment_status)} d-inline-block fs-11`}>
                      {employeeDetails.employment_status}
                    </span>
                    </div>
                  </div>


                </div>
                <div className="p-3 ">
                  <div className="row">
                    <div className="col-md-2">
                      <label className="control-label">Department</label>
                      <p className="fs-14 mb-2">{employeeDetails.department}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="control-label">Email </label>
                      <p className="fs-14 mb-2">{employee.email}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="control-label">Contact No. </label>
                      <p className="fs-14 mb-2">{employee.phone_no}</p>
                    </div>
                    <div className="col-md-6">
                      <label className="control-label">Address</label>
                      <p className="fs-14 mb-2">{`${employeeDetails.address}, ${employeeDetails.city}, ${employeeDetails.state}, ${employeeDetails.postal_code}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <Tabs
              defaultActiveKey={tabKey}
              id="uncontrolled-tab-example"
              className="mb-3 emp-tabs mt-3"
              activeKey={tabKey}
              onSelect={(k: string | null) => handleTabSelect(k)}
            >
              <Tab eventKey="home" title="Details">
              {tabKey === 'home' && <EmployeeDetails source={MEETING_SOURCE_PAGES.reporting_manager} employeeDetails={employeeDetails} kinDetails={kinDetails} employeeBankDetails={employeeBankDetails} />}
              </Tab>
              {/* <Tab eventKey="document" title="Documents">              
              {tabKey === 'document' && <EmployeeDocuments employeeID = {employee.id} uType="rm" />}
              </Tab> */}
              <Tab eventKey="induction" title="Induction" >
                {tabKey === 'induction' && <EmployeeInduction employeeID = {employee.id} />}
              </Tab>
              <Tab eventKey="meetings" title="Meetings" >
                {tabKey === 'meetings' && <EmployeeMeetings employeeID = {employee.id} source={MEETING_SOURCE_PAGES.reporting_manager} />}
              </Tab>

              <Tab eventKey="leaves" title="Leaves & History" >
               {tabKey === 'leaves' && <EmployeeLeaves employeeID = {employee.id} rtype="rm" />}
              </Tab>
              <Tab eventKey="emails" title="Emails" >
                {tabKey === 'emails' && <EmployeeSentEmails employeeID = {employee.id} />}
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
