import { Route, Routes } from "react-router-dom";
import ViewProfile from "./ViewProfile";
import ProfileUpdate from "./Profile";
import ChangePassword from "./ChangePassword";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { Login } from "../Auth/Login";

export const Profile = () => {
  return (
    <Routes>
        <Route path="/view" element={<ViewProfile />} />
        <Route path="/edit" element={<ProfileUpdate />} />
        <Route path="/change-password" element={<ChangePassword />} />
    </Routes>
  );
};
