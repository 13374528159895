export const LOGIN_API = `/auth/login`;
export const FORGOT_API = `/auth/forgot-password`;
export const RESET_API = `/auth/reset-password`;
export const REFRESH_TOKEN = `/auth/refresh-tokens`;
export const VERIFICATION = `/auth/mfa`;
export const LOGOUT = `/auth/logout`;
export const RESEND_CODE = `/auth/mfa-resend`;
export const ROLE_LIST = '/roles/role-list'
export const UPLOAD_SITE_ICON = '/external/uploadSiteIcon'
export const CREATE_EXTERNAL_LINK = '/external/createExternal'
export const EXTERNAL_LIST = '/external/external-list'
export const UPDATE_EXTERNAL = '/external/updateExternal'
export const EXTERNAL_DELETE = '/external/delete-external'
export const UPDATE_ADMIN_PROFILE = '/auth/update-profile';
export const CHANGE_PASSWORD = '/auth/change-password';

export const CREATE_DEAL = '/deals/createDeal'
export const DEAL_LIST = '/deals/list'
export const UPDATE_DEAL = '/deals/update'
export const VIEW_DEAL = '/deals/details'
export const DEAL_DELETE = '/deals/delete'
export const DEAL_PDF_UPLOAD = '/deals/upload'
export const DEAL_PAYMENT_UPLOAD = '/deals/upload/paymentFile'
export const CONVERT_TO_PROJECT = '/deals/convertToProject'
export const PRODUCT_TYPE_LIST = '/general/project-types'
export const COMPANY_DETAIL = '/general/company-details'
export const GENERAL_DETAIL = '/general'

export const PROJECT_LIST = '/projects/list'
export const PROJECT_ASSIGN_PROJECT_OWNER = '/projects/assignProjectOwner'
export const PROJECT_ASSIGN_TEAM = '/projects/assignMembers'
export const PROJECT_ASSIGN_TEAM_AND_DETAILS = '/projects/assignMembersAndProjectDates'
export const PROJECT_DELETE = '/projects/delete'
export const PROJECT_UPDATE = '/projects/update'
export const PROJECT_QUICKUPDATE = '/projects/quick-update'
export const PROJECT_PERSONALINFOUPDATE = '/projects/customer-update'
export const PROJECT_DOWNLOADURL = '/projects/doc'
export const PROJECT_DOCUMENT_LIST = '/projects/uploaded-doc-list'
export const PROJECT_DOCUMENT_INFO_SAVE = '/projects/doc-info'
export const PROJECT_DOCUMENT_DELETE = '/projects/doc'
export const PROJECT_DOCUMENT_UPLOAD = '/projects/upload'
export const PROJECT_GET_POSTAL_CODES = '/projects/getPostalCodes'
export const PROJECT_DETAILS = '/projects/getProjectDetail'
export const PROJECT_SURVEY_INITIATE = '/projects/survey-initiate'
export const PROJECT_PROCUREMENT_LIST = '/projects/procurement/list'
export const PROJECT_PROCUREMENT_POLIST = '/projects/procurement/po-list'
export const PROJECT_FINANCE_EXPORT = '/projects/project-export-finance'
export const PROJECT_FINANCE_PAYMENT_MILESTONE = '/projects/payment-milestone'
export const PROJECT_FINANCE_PAYMENT_RECIEVED = '/projects/receive-payment-milestone'
export const PROJECT_CONTROL_MARK_COMPLETE = '/projects/page-control-section-mark-complete'

export const CREATE_POSTALCODE = "/postalcode/createPostalCode";
export const POSTALCODE_LIST = "/postalcode/postalcode-list";
export const UPDATE_POSTALCODE = "/postalcode/updatePostalcode";
export const DELETE_POSTALCODE = "/postalcode/delete-postalcode";
export const GET_POSTALCODE = "/postalcode/get-postalcode-list";
export const ASSIGN_PROJECT_OWNER = "/postalcode/assingProjectOwner";
export const ALL_USER_LIST = "/users/user-list";
export const ALL_PROJECT_MANAGER_LIST = "/users/project-manager-list";
export const ALL_MANAGER_LIST = "/users/getManagerList";
export const ALL_REPORTING_MANAGER_LIST = "/users/getReportingManagerList";
export const ADD_FIELDSHEET = "/fieldsheet/createFieldSheet";
export const FIELDSHEET_LIST = "/fieldsheet/list";
export const UPDATE_FIELDSHEET = "/fieldsheet/field-sheet-update";
export const FIELDSHEET_DETAILS = "fieldsheet/field-sheet"

export const ADD_FIELD = "field/createField"
export const FIELD_LIST = "field/field"
export const SECTION_DETAILS = "section/section-field-id"
export const UPDATE_FIELD = "field/field-update"
export const FORM_DELETE = "fieldsheet/delete-fieldsheet"
export const ADD_SECTION = "section/createSection"
export const UPDATE_SECTION = "section/section-update"
export const DELETE_SECTION = "section/section-delete"
export const DELETE_FIELD = "field/field-delete"
export const SECTION_DETAILS_ID = "section/section"
export const GET_ALL_FIELD = "field/getAllFields" 

export const ADD_CONDITION = "field/createCondition"
export const FORM_TEMPLATE_LIST = "fieldsheet/template-list" 

export const ADD_SUB_SECTION = "subsection/createSection"
export const UPDATE_SUB_SECTION = "subsection/section-update"
export const DELETE_SUB_SECTION = "subsection/section-delete"
export const SUB_SECTION_DETAILS_ID = "subsection/section"
export const SUB_SECTION_DETAILS = "subsection/section-field-id"
export const UPDATE_SECTION_ORDER = "section/section-order-update"



export const PERMISSION_LIST = '/roles/roles-permission'
export const CREATE_ROLE = '/roles/createRoles'
export const UPDATE_ROLE = '/roles/updateRoles'
export const ROLE_DELETE = '/roles/delete-role'
export const EMPLOYEE_LIST = '/employee/employee-list'
export const REPORTING_MANAGER_EMPLOYEE_LIST = '/employee/reporting-managers-employee-list'
export const CREATE_EMPLOYEE = '/employee/createEmployee'
export const UPLOAD_EMPLOYEE_IMAGES = '/employee/uploadEmployeeImage'
export const UPDATE_EMPLOYEE = '/employee/updateEmployee'
export const UPDATE_EMPLOYEE_STATUS = '/employee/updateEmployeeStatus'
export const GET_EMPLOYEE_INDUCTION_LIST = '/employee/induction/list'
export const EMPLOYEE_INDUCTION_ADD = '/employee/induction/add'
export const GET_EMPLOYEE_INDUCTION_DETAIL = '/employee/induction/get'
export const EMPLOYEE_INDUCTION_EDIT = '/employee/induction/edit'
export const EMPLOYEE_INDUCTION_APPROVE = '/employee/induction/approve'
export const GET_EMPLOYEE_EMAIL_LIST = '/employee/employee-email-list'
export const SEND_EMAIL_TEMPLATE='/employee/send-email'
export const SENT_EMAIL_TEMPLATE_LIST='/employee/employee-sent-emails'
export const GET_SENT_EMAIL_TEMPLATE = '/employee/employee-sent-email'
export const ALLOCATED_ASSET_LIST='/employee/employee-allocated-assets'
export const EMPLOYEE_MEETINGS='/employee/employee-meeting/list'
export const MANAGER_MEETINGS='/employee/manager-meeting/list'
export const CREATE_EMPLOYEE_MEETING = '/employee/employee-meeting/create'
export const GET_EMPLOYEE_MEETING = '/employee/employee-meeting'
export const FILL_NOTES = '/employee/employee-meeting/fill-notes'
export const UPDATE_EMPLOYEE_MEETING = '/employee/employee-meeting/update'
export const GET_MEETING_NOTES_QUESTIONS='/employee/employee-meeting/questions'
export const EMPLOYEE_DOC_LIST = '/employee/employee-doc/list-doc'
export const EMPLOYEE_DOC_REQUEST_ADD = '/employee/employee-doc/request'
export const EMPLOYEE_DOC_REQUEST_DETAIL = '/employee/employee-doc/details'
export const EMPLOYEE_DOC_REQUEST_EDIT = '/employee/employee-doc/request-edit'
export const EMPLOYEE_DOC_UPLOAD = '/employee/employee-doc/upload-doc'
export const EMPLOYEE_DOC_SENDTOHR_ADD = '/employee/employee-doc/sent-add'
export const EMPLOYEE_DOC_SENDTOHR_DETAIL = '/employee/employee-doc/details'
export const EMPLOYEE_DOC_SENDTOHR_EDIT = '/employee/employee-doc/sent-edit'
export const EMPLOYEE_DOC_GET_DOWNLOAD_URL = '/employee/employee-doc/file'
export const EMPLOYEE_DOC_DELETE = '/employee/employee-doc/delete'
export const EMPLOYEE_DOC_APPROVE = '/employee/employee-doc/approve'
export const EMPLOYEE_FILL_HMRC_FORM = '/employee/fill-hmrc-form'
export const EMPLOYEE_GET_HMRC_FORM = '/employee/hmrc-form'
export const EMPLOYEE_DOWNLOAD_HMRC_FORM = '/employee/hmrc-form-download'
export const DOWNLOAD_BLANK_HMRC_FORM = '/employee/blank-hmrc-form-download'
export const CORRECT_STUDENT_LOAN_LIST = '/employee/hmrc-form/correct-student-loan'

export const GET_PROFILE = '/auth/get-profile';
export const UPDATE_EMPLOYEE_PROFILE_REQUEST = '/auth/update-profile-request';
export const UPDATE_EMPLOYEE_PROFILE_ACTION = '/employee/update-employee-profile-action';
export const GET_EMPLOYEE_PROFILE_UPDATE_REQUEST = '/employee/get-employee-profile-update-request';
export const PROFILE_CHANGE_REQUEST_ACTION = '/employee/update-employee-profile-action';

export const EMPLOYEE_FILL_MEDICAL_QUESTIONNAIRE = '/employee/fill-medical-questionnaire'
export const EMPLOYEE_GET_MEDICAL_QUESTIONNAIRE = '/employee/medical-questionnaire'
export const EMPLOYEE_DOWNLOAD_MEDICAL_QUESTIONNAIRE = '/employee/medical-questionnaire-download'
export const DOWNLOAD_BLANK_MEDICAL_QUESTIONNAIRE = '/employee/blank-medical-questionnaire-download'
export const ILLNESS_LIST = '/employee/medical-questionnaire/illness'


export const VENDOR_LIST = '/vendor/vendor-list'
export const CREATE_VENDOR = '/vendor/createVendor'
export const UPLOAD_VENDOR_IMAGES = '/vendor/uploadVendorImage'
export const UPDATE_VENDOR = '/vendor/updateVendor'
export const VENDOR_DELETE = '/vendor/deleteVendor'
export const UPDATE_VENDOR_STATUS = '/vendor/updateVendorStatus'
export const GET_ALL_VENDOR_NAMES = '/vendor/get-all-vendors-name'
export const VENDOR_RELATED_PRODUCTS = '/vendor/product-listing'
export const VENDOR_PO_HISTORY = '/vendor/po-history-listing'
export const VENDOR_EXPORT = '/vendor/export-vendors'
export const PRODUCT_LIST = '/products/list'
export const PRODUCT_RECIEVED_LIST = '/products/product-received'
export const PRODUCT_DISPATCH_LIST = '/products/product-dispatch'
export const PRODUCT_DETAIL = '/products/details'
export const PRODUCT_EDIT = '/products/update'
export const PRODUCT_SYNC = '/products/sync'
export const PRODUCT_ASSIGN_VENDOR = '/products/assign-vendor'
export const PRODUCT_UPLOAD_IMAGE = '/products/upload-image'
export const PRODUCT_CATEGORY_LIST = '/products/category-list'
export const PRODUCT_EXPORT = '/products/export-product'
export const WAREHOUSE_DETAIL = '/warehouse/getWarehouseDetail'
export const WAREHOUSE_UPDATE = '/warehouse/updateWarehouse'
export const WAREHOUSE_PHOTO_UPLOAD = '/warehouse/uploadPhoto'
export const WAREHOUSE_LIST_OPENING_STOCK = '/warehouse/list-opening-stock'
export const WAREHOUSE_IMPORT_OPENING_STOCK = '/warehouse/import-opening-stock'
export const WAREHOUSE_EXPORT_OPENING_STOCK = '/warehouse/export-current-stock'
export const WAREHOUSE_TEMPLATE_OPENING_STOCK = '/warehouse/opening-stock-template'
export const WAREHOUSE_PRODUCT_LIST_ASYNC= '/products/async-list'
export const WAREHOUSE_PO_LIST= '/warehouse/list'
export const WAREHOUSE_PO_ADD= '/warehouse/add-po'
export const WAREHOUSE_PO_EDIT= '/warehouse/edit-po'
export const WAREHOUSE_PO_DELETE= '/warehouse/po-delete'
export const WAREHOUSE_PO_COMPLETE= '/warehouse/po-complete'
export const WAREHOUSE_PO_DETAIL= '/warehouse/get-po'
export const WAREHOUSE_PO_NUMBER= '/warehouse/get-po-number'
export const WAREHOUSE_PO_SEARCH= '/warehouse/search-po-number'
export const WAREHOUSE_PO_EXPORT= '/warehouse/export-po'
export const WAREHOUSE_GRN_LIST= '/warehouse/grn-list'
export const WAREHOUSE_GRN_DETAIL= '/warehouse/grn-details'
export const WAREHOUSE_GRN_GETNO= '/warehouse/get-gr-number'
export const WAREHOUSE_GRN_IMAGE= '/warehouse/upload-image'
export const WAREHOUSE_GRN_ADD= '/warehouse/add-grn'
export const WAREHOUSE_GRN_EXPORT= '/warehouse/export-grn'
export const WAREHOUSE_GRN_UPDATENOTE= '/warehouse/grn-update-note'
export const WAREHOUSE_SHELVES_LIST = '/warehouse/get-shelves'
export const WAREHOUSE_DP_LIST = '/warehouse/dispatch-order-listing'
export const WAREHOUSE_DP_ADD = '/warehouse/add-dispatch-order'
export const WAREHOUSE_DP_EDIT = '/warehouse/edit-dispatch-order'
export const WAREHOUSE_DP_DETAIL = '/warehouse/get-dispatch-order'
export const WAREHOUSE_DP_GETNO = '/warehouse/get-dispatch-order-number'
export const WAREHOUSE_DP_GETPRODUCT = '/warehouse/get-product-to-dispatch'
export const WAREHOUSE_DP_EXPORT = '/warehouse/dispatch-order-export'
export const WAREHOUSE_DASHBOARD = '/warehouse/dashboard'
export const WAREHOUSE_RETRUN_DISPATCH_QTY = '/warehouse/dispatch-product-return-approve'


export const ORDER_NUMBER_LIST = '/deals/getOrderNumber' 
export const DEAL_EXPORT = '/deals/exportDeal'
export const DEAL_SYNC = '/deals/sync'
export const CREATE_LETTER_TEMPLATE = '/letter-templates/create'
export const GET_LETTER_TEMPLATE_TYPES = '/letter-templates/get-types'
export const GET_LETTER_TEMPLATES = '/letter-templates'
export const DELETE_LETTER_TEMPLATE = '/letter-templates/delete'
export const DOWNLOAD_LETTER_FORM_TEMPLATE = '/letter-templates/get-form-template-file'
export const CHECK_LETTER_TEMPLATE_EXISTS = '/letter-templates/check-template-exists'
export const GET_EMAIL_TEMPLATES = '/letter-templates/email-templates'

export const CREATE_ASSET = '/assets/create'
export const UPDATE_ASSET = '/assets/update'
export const GET_ASSETS = '/assets'
export const GET_ASSET_TYPES = '/assets/get-types'
export const GET_ASSETS_COUNT = '/assets/get-count'
export const MANAGE_ASSET_STATUS = '/assets/manage-asset-status'
export const GET_ASSET_HISTORY = '/assets/asset-history'
export const ASSET_DASHBOARD_PENDING = '/assets/dashboard/pending-assets'

export const HOLIDAY_ADD = '/holidays/create'
export const HOLIDAY_LIST = '/holidays/list'
export const HOLIDAY_EDIT = '/holidays/update'
export const HOLIDAY_DELETE = '/holidays/delete'
export const HOLIDAY_DETAIL = '/holidays/detail'

export const FINANCE_INVOICE_NUMBER = '/invoices/get-invoice-number'
export const FINANCE_INVOICE_ADD = '/invoices/add'
export const FINANCE_INVOICE_LIST = '/invoices/list'
export const FINANCE_INVOICE_EDIT = '/invoices/edit'
export const FINANCE_INVOICE_DELETE = '/invoices'
export const FINANCE_INVOICE_EXPORT = '/invoices/export'
export const FINANCE_INVOICE_DETAIL = '/invoices/get-invoice-details'
export const FINANCE_INVOICE_PRINT = '/invoices/print'
export const FINANCE_INVOICE_SYNC = '/invoices/sync-xero-invoices'
export const FINANCE_EXTERNAL_PROCUREMENT = '/external-procurement'
export const FINANCE_DASHBOARD_INVOICE = '/invoices/dashboard'
export const FINANCE_DASHBOARD_PROJECT = '/projects/dashboard'
export const FINANCE_DASHBOARD_EXPENSES = '/expenses/dashboard'


export const LIBRARY_ADD = '/libraries/create'
export const LIBRARY_LIST = '/libraries/list'
export const LIBRARY_VERSION = '/libraries/version-histroy'
export const LIBRARY_EDIT = '/libraries/update'
export const LIBRARY_DELETE = '/libraries/delete'
export const LIBRARY_DETAIL = '/libraries/detail'

export const LEAVE_EMP_TYPES = '/employee/leaves/get-emp-leaves-type'
export const LEAVE_RM_TYPES = '/employee/leaves/get-leaves-type'
export const LEAVE_EMP_BUSINESS_DAYS = '/employee/leaves/get-business-days'
export const LEAVE_EMP_ADD = '/employee/leaves/add'
export const LEAVE_RM_ADD = '/employee/leaves/add-by-rm'
export const LEAVE_EMP_LIST = '/employee/leaves/list'
export const LEAVE_EMP_VIEW = '/employee/leaves/view'
export const LEAVE_STATUS = '/employee/leaves/update-status'
export const LEAVE_EMP_LIST_BY_HR = '/employee/leaves/list-by-hr'
export const LEAVE_DETAIL = '/employee/leaves/view'
export const LEAVE_EMP_LIST_DROPDOWN = '/employee/leaves/emp-leaves-list'

export const ANNOUNCEMENT_ADD = '/announcement/create'
export const ANNOUNCEMENT_LIST = '/announcement/list'
export const ANNOUNCEMENT_EDIT = '/announcement/update'
export const ANNOUNCEMENT_DELETE = '/announcement/delete'
export const ANNOUNCEMENT_DETAIL = '/announcement/detail'

export const EXPENSE_LOG_ADD = '/expenses/add'
export const EXPENSE_LOG_LIST = '/expenses/list'
export const EXPENSE_EDIT = '/expenses/update'
export const EXPENSE_DELETE = '/expenses/delete'
export const EXPENSE_FINANCE_DELETE = '/expenses/delete-by-fm'
export const EXPENSE_DETAIL = '/expenses/details'
export const EXPENSE_UPLOAD_FILE = '/expenses/upload-file'
export const EXPENSE_EXPORT = '/expenses/export'
export const EXPENSE_TEAM_LIST = '/expenses/list-by-rm'
export const EXPENSE_TEAM_DETAIL = '/expenses/details-by-rm'
export const EXPENSE_FINANCE_DETAIL = '/expenses/details-by-fm'
export const EXPENSE_TEAM_STATUS_UPDATE = '/expenses/update-status-by-rm'
export const EXPENSE_ASK_QUERY = '/expenses/add-comments'
export const EXPENSE_QUERY_LIST = '/expenses/comments'
export const EXPENSE_FINANCE_LIST = '/expenses/list-by-fm'
export const EXPENSE_FINANCE_EXPORT = '/expenses/export-fm'
export const EXPENSE_FINANCE_INVOICE_ADD = '/expenses/invoice/add'
export const EXPENSE_CATEGORY_LIST = '/expenses/expense-category-list'

export const SURVEY_LIST = '/survey/survey-list'
export const SURVEY_VIEW = '/survey/view-survey'
export const GET_SURVEY_TEMPLATE = '/survey/get-survey-template'   
export const UPDATE_SURVEY_TEMPLATE = '/survey/update-survey-template'
export const SURVEY_IMAGES_UPLOAD = '/survey/upload-images' 
export const GET_SIGNED_IMAGES = '/survey/get-signed-images'
export const UPLOAD_SIGNATURE_CANVAS = '/survey/upload-signature-canvas'
export const SURVEY_PRODUCT = '/survey/get-products'
export const SURVEY_PRODUCT_LIST_ASYNC= '/products/async-list'
export const SURVEY_PRODUCT_SAVE = '/survey/save-survey-products'
export const GET_CONTRACT_PDF_SURVEY_FORM = '/survey/get-contract-pdf'
export const SURVEY_COMPLETE = '/survey/survey-complete'

export const TICKET_LIST = '/tickets/list'
export const TICKET_CREATE = '/tickets/create'
export const TICKET_UPLOAD_FILE = '/tickets/upload-file'
export const TICKET_UPDATE = '/tickets/update'
export const TICKET_DETAIL = '/tickets/details'
export const TICKET_MARK_COMPLETE = '/tickets/mark-completed'
export const TICKET_EXPORT = '/tickets/export'
export const TICKET_COMMENTS = '/tickets/comments'
export const TICKET_COMMENTS_ADD = '/tickets/add-comments'

export const FLEET_LIST = '/fleet/list'
export const FLEET_CREATE = '/fleet/create'
export const FLEET_UPDATE = '/fleet/update'
export const FLEET_DETAIL = '/fleet/detail'
export const FLEET_ALERTS = '/fleet/get-alerts'
export const FLEET_DRIVER_GET = '/fleet/get-driver-history'
export const FLEET_DRIVER_SAVE = '/fleet/save-driver-history'
export const FLEET_MILEAGE_SAVE = '/fleet/save-mileage'
export const FLEET_DELETE = '/fleet/delete'
export const FLEET_UPLOAD_FILE = '/fleet/upload-file'
export const FLEET_NOTES_SAVE = '/fleet/add-notes'
export const FLEET_NOTES = '/fleet/notes'


export const SUPPORT_TICKET_LIST = '/support/list'
export const SUPPORT_TICKET_CREATE = '/support/create'
export const SUPPORT_TICKET_UPLOAD_FILE = '/support/upload-file'
export const SUPPORT_TICKET_UPDATE = '/support/update'
export const SUPPORT_TICKET_DETAIL = '/support/details'
export const SUPPORT_TICKET_MARK_COMPLETE = '/support/mark-completed'
export const SUPPORT_TICKET_COMMENTS = '/support/comments'
export const SUPPORT_TICKET_COMMENTS_ADD = '/support/add-comments'

export const SERVICE_COMPLAINT_LIST = '/service-warranty/list'
export const SERVICE_COMPLAINT_CREATE = '/service-warranty/create'
export const SERVICE_COMPLAINT_NUMBER = '/service-warranty/get-complaint-number'
export const SERVICE_COMPLAINT_UPLOAD_FILE = '/service-warranty/upload-file'
export const SERVICE_COMPLAINT_UPDATE = '/service-warranty/update'
export const SERVICE_COMPLAINT_DETAIL = '/service-warranty/details'
export const SERVICE_COMPLAINT_MARK_COMPLETE = '/service-warranty/mark-completed'
export const SERVICE_COMPLAINT_EXPORT = '/service-warranty/export'
export const SERVICE_COMPLAINT_COMMENTS = '/service-warranty/comments'
export const SERVICE_COMPLAINT_COMMENTS_ADD = '/service-warranty/add-comments'
export const SURVEY_DATA ='survey/get-survey-details'
export const UPDATE_REVIEW_COMPARATIVE_STUDY = '/projects/update-review-comparative-study'
export const GET_CONTRACT_PRODUCT = '/projects/get-contract-products'
export const SAVE_COMPARATIVE_STUDY = '/projects/save-comparative-study'
export const GET_CONTRACT_PDF = '/projects/get-contract-pdf'
export const UPLOAD_CONTRACT_PDF = '/projects/upload-revised-pdf'    
export const GET_COUNTRIES = '/general/countries';

export const INSTALLATION_TASK = '/installation'
export const TASK = '/tasks'

export const EMPLOYEE_DASHBOARD = '/employee/dashboard'
export const AUDIT_TRIAL_DATA = '/projects/get-audit-trail'
export const GET_LABOUR_COST = '/projects/get-labour-cost'
export const SET_LABOUR_COST = '/projects/save-labour-cost'
export const EMPLOYEE_EXPORT = '/employee/export'   
 
export const EMPLOYEE_DELETE = '/employee/deleteEmployee'
export const DASHBOARD_MASTER = '/master-dashboard'
export const HR_DASHBARD = '/hr-dashboard'