import { Route, Routes } from 'react-router-dom';
import InstallationTaskList from './index';
import { ViewInstallationDetail } from './View';
import { checkAbility } from '../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../constants/permissionUser';

export const Installer = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.INSTALLATIOIN_TASK) && (
                <Route path="/" element={<InstallationTaskList type="today" />} />
            )}           
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.INSTALLATIOIN_TASK) && (
                <Route path="/history" element={<InstallationTaskList type="history" />} />
            )}           
            {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.INSTALLATIOIN_TASK) && (
                <Route path="/view/:id" element={<ViewInstallationDetail />} />
            )}                      
        </Routes>
    )
};