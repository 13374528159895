import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { getEmployeeInductionDetail } from "../../../../services/employeeService";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import moment from "moment";
import constants from "../../../../constants/constants";
import { Link } from "react-router-dom";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  employeeID: number;
  inductionId?: string |number | null | undefined;
}

const ViewEmployeeIndcutionPopup: React.FC<AddProps> = ({ isOpen, onClose, employeeID, inductionId }) => {  
  const [loading, setLoading] = useState(true);
  const [inductionData, setInductionData] = useState<any>({}); 

  useEffect(()=>{
    fetchData()
   },[])
 
   const fetchData = () =>{         
      if(inductionId){
        setLoading(true)
        getEmployeeInductionDetail(inductionId,employeeID).then((response)=>{
          setInductionData(response?.data);
          setLoading(false)  
        }).catch((error)=>{
          setLoading(false)
        })
      }
      else{
        setLoading(false)
      }  
   } 

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal" show={isOpen} onHide={onClose} centered>          
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    View Schedule Induction Meeting
                  </h4> 
                  <Row>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Induction Process Name : </label> 
                        <p className="fs-14">{inductionData?.name}</p>
                      </div>
                    </Col>                    
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Induction Date : </label> 
                        <p className="fs-14">{inductionData?.date ? moment(inductionData?.date).format(constants.dateFormats.slashSeparatedDate) : "-"}</p>
                      </div>
                    </Col>                                      
                  </Row>       
                  <Row>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Induction Manager : </label> 
                        <p className="fs-14">{inductionData['manager.full_name']? inductionData['manager.full_name'] : "-"}</p>
                      </div>
                    </Col> 
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Induction Document : </label> 
                        <p className="fs-14">{inductionData?.document ?  <Link to={inductionData?.fileurl} target="_blank">{inductionData?.document}</Link> :"-"}</p>
                      </div>
                    </Col>                   
                  </Row>                  
                  <Row>
                    <Col className="col-md-12">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Description : </label> 
                        <p className="fs-14">{inductionData?.remarks}</p>
                      </div>
                    </Col>                   
                  </Row>                  
                  <Row>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Is Completed : </label> 
                        <p className="fs-14">{inductionData?.status ? 'Yes' : 'No'}</p>
                      </div>
                    </Col>  
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Is Approved : </label> 
                        <p className="fs-14">{inductionData?.is_approved ? 'Yes' : 'No'}</p>
                      </div>
                    </Col>                   
                  </Row>                 
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>                 
                </Modal.Footer>              
        </Modal>
      )}
    </>
  );
};
export default ViewEmployeeIndcutionPopup;