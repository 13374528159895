import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { addSchema } from "./validation";
import InputText from "../../../components/common/InputText";
import FormSelect from "../../../components/common/FormSelect";
import CustomButton from "../../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import InputDatePicker from "../../../components/common/InputDatePicker";
import moment, { Moment } from "moment";
import constants, {
  announcementTypes,
  departmentOptions,
  employeeTypeOptions,
  formTemplateMaxFileSizeMB,
  pdfFileTypes,
} from "../../../constants/constants";
import { useNavigate, useParams } from "react-router-dom";
import InputTextArea from "../../../components/common/InputTextArea";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";
import GeneralFileUploader from "../../../components/common/GeneralFileUploader";
import {
  FORM_TEMPLATE_SIZE_ERROR,
  PDF_TYPE_ERROR,
} from "../../../validations/ValidationErrors";
import {
  createAnnouncementApi,
  getAnnouncementByIdApi,
  updateAnnouncementApi,
} from "../../../services/announcementService";
import InputCheckBox from "../../../components/common/InputCheckBox";

interface IAnnouncement {
  id?: number;
  title?: string;
  description?: string;  
  announcement_file?: any;
  announcement_date?: any;
  department:string;
  announcement_type:number;
  send_now:boolean;
  createdAt: string;
  updatedAt: string;
}

interface FormValues {
  title: string;
  description: any;
  department: any;
  announcement_type: any;
  announcement_date: any;
  send_now: boolean;
  filename: any;
  file: any;
}
export const AnnouncementAdd = () => {
  const [editableData, setEditableData] = useState<IAnnouncement>();
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();
  const allDepartmentOption = [...[{key:"All", value:"All"}],...departmentOptions];
  const annoucementTypeOptions = [...[{key:"0", value:"All", aKey:0}],...employeeTypeOptions];
  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    const formData: any = {
      ...values,
      announcement_date : values.send_now ? moment().format(constants.dateFormats.databaseFormat) : moment(values.announcement_date).isValid() ? moment(values.announcement_date).format(constants.dateFormats.databaseFormat) : null,
      announcement_type : values.announcement_type?.aKey,
      department : values.department?.key,
    };
    
    if (typeof values.file === "string") {
      delete formData.file;
    }
    
    delete formData.filename;
    console.log(formData);
    //return;
    setBtnLoading(true);
    if (id) {
      updateAnnouncementApi(id, formData)
        .then((response) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/announcement");
          setBtnLoading(false);
        })
        .catch((error) => {
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createAnnouncementApi(formData)
        .then((response) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/announcement");
          setBtnLoading(false);
        })
        .catch((error) => {
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getAnnouncementDetail = async (id: number | string) => {
    setLoading(true);
    await getAnnouncementByIdApi(id)
      .then((response: any) => {
        setEditableData(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(`Error: ${error.response.data.message}`, {
          type: toast.TYPE.ERROR,
        });
        navigate("/announcement");

      });
  };

  useEffect(() => {
    if (id) {
      getAnnouncementDetail(id);
    } else {
      setLoading(false);
    }
  }, []);

  const initialValues = {
    title: editableData && editableData.title ? editableData.title : "",
    description: editableData && editableData.description ? editableData.description : "",
    filename: editableData?.announcement_file ? editableData?.announcement_file : "",
    announcement_type: typeof editableData?.announcement_type == 'number' ? annoucementTypeOptions.find((item)=>item.aKey==editableData.announcement_type) : null,
    announcement_date: editableData?.announcement_date ? moment(editableData?.announcement_date) : null,
    department: editableData?.department ? allDepartmentOption.find((item)=>item.key==editableData.department) : null,
    send_now: editableData?.send_now ? editableData?.send_now : false,
    file: editableData?.announcement_file ? editableData?.announcement_file : "",
  };

  /**
   * Upload form template PDF file
   * @param {events, setFieldValue}
   * @returns {*}
   */
  const uploadFile = (event: any, setFieldValue: any) => {
    const file = event.target.files[0];
    if (file) {
      // Check file type
      if (!pdfFileTypes.includes(file.type)) {
        toast(PDF_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (file.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
        toast(FORM_TEMPLATE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        setFieldValue("file", file);
        setFieldValue("filename", file.name);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {id ? "Edit" : "Add New"} Announcement
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={addSchema}
              initialValues={initialValues}
              onSubmit={(values: FormValues, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    {/* {JSON.stringify(values)} */}
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-4">
                            <InputText
                              controlId="title"
                              label="Title"
                              placeholder="Enter Title"
                              touched={touched.title}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.title}
                              value={values.title}
                            />
                          </Col>
                          <Col className="col-4">
                          <FormSelect
                              placeholder="Select"
                              label="Type"
                              name="announcement_type"
                              options={annoucementTypeOptions}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.announcement_type}
                              error={errors.announcement_type}
                              touched={touched.announcement_type}
                            />                            
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-4 mb-3 annoucement-date-picker">
                              <InputDatePicker
                                name="announcement_date"
                                label="Announcement Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "announcement_date",
                                  },
                                }}  
                                isDisable={values.send_now}                          
                                value={values.send_now ? moment() : values.announcement_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.announcement_date}
                                errors={errors.announcement_date}
                              />
                            <div className="mt-3 annoucement-send-now">
                            <InputCheckBox
                              controlId="send_now"
                              label="Send Now"
                              touched={touched.send_now}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.send_now}
                              value={values.send_now}
                            />

                            </div>
                          </Col>
                          <Col className="col-4">
                          <FormSelect
                                placeholder="Select"
                                label="Department"
                                name="department"
                                options={allDepartmentOption}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.department}
                                error={errors.department}
                                touched={touched.department}
                              />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-md-12">
                            <GeneralFileUploader
                              uploadFile={(e) => uploadFile(e, setFieldValue)}
                              imageLoading={imageLoading}
                              label="Upload Announcement File"
                              filename={values.filename}
                              acceptFormat=".pdf"
                            />
                            <ErrorMessage
                              name="file"
                              component="div"
                              className="text-danger fs-14"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-8">
                            <InputTextArea
                              controlId="description"
                              label="Description"
                              placeholder="Enter Description"
                              touched={touched.description}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.description}
                              value={values.description}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <CustomButtonCancel label="Cancel" />
                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={btnLoading}
                            className="fw-semibold fs-13 text-white mw-90 mt-2"
                            variant="primary"
                          >
                            Submit
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
