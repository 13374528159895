import { Route, Routes } from "react-router-dom";
import EmployeeMedicalQuestionnaire from "./index";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { Login } from "../Auth/Login";
import Home from "./index";

export const  Planner = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

     
    </Routes>
  );
};
