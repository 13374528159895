import React, { useEffect, useId, useState } from "react";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Scrollbar } from "react-scrollbars-custom";
import { Card, Col, Form, Row } from "react-bootstrap";
import { departmentCheck } from "../../constants/constants";
import { getEmployeeLeaveChartCount } from "../../services/hrdashboard.service";
import { DateRangeInterface } from "../Dashboard/dashboardInterface";
import moment from "moment";
import LineSkeleton from "../../components/common/Skeleton/LineCheck.skeleton";
import BarChartSkeleton from "../../components/common/Skeleton/BarChart.skeleton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController
);

interface VisibleDepartments {
  Sales: boolean;
  Operations: boolean;
  "HR Department": boolean;
  "Finance Department": boolean;
}

const departmentKeyMap: { [key: string]: keyof VisibleDepartments } = {
  OP: "Operations",
  SA: "Sales",
  HR: "HR Department",
  FD: "Finance Department",
};

const EmployeeLeaveChart: React.FC<DateRangeInterface> = ({
  orderDateRange,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [chartData, setChartData] = React.useState([]);
  const [averageAbsenteeism, setAverageAbsenteeism] = useState(0);
  const [chartLoading, setChartLoading] = useState(false);
  const [visibleDepartments, setVisibleDepartments] =
    useState<VisibleDepartments>({
      Sales: true,
      Operations: true,
      "HR Department": true,
      "Finance Department": true,
    });
  const idPrefix = useId(); // Generate a unique ID prefix

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, [orderDateRange, visibleDepartments]);

  const fetchData = (signal: any) => {
    setChartLoading(true);
    getEmployeeLeaveChartCount(orderDateRange, visibleDepartments, signal)
      .then((res) => {
        setChartData(res.data.processedData);
        setAverageAbsenteeism(res.data.averageAbsenteeism);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {
        setChartLoading(false);
      });
  };

  const data = {
    labels: chartData.map((item: any) =>
      moment(item.month, "MM-YYYY").format("MMM-YY")
    ),
    datasets: [
      {
        type: "line" as const, // Explicitly cast type

        data: chartData.map((item: any) => item.absenteeism),
        borderColor: "rgba(255, 0, 0, 1)",
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        tension: 0.4, // Smoothing for the curve
        fill: false,
        pointRadius: 0,
      },
      {
        type: "bar" as const, // Explicitly cast type

        data: chartData.map((item: any) => item.count),
        backgroundColor: "rgba(33, 66, 107, 1)",
        color: "rgba(33, 66, 107, 1)",
        borderRadius: 20,
        barThickness: 20,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart - Multi Axis",
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false, // Disable datalabels for this specific chart
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false, // Hide the grid lines on the x-axis
        },
        title: {
          display: false,
          text: "Month",
        },
      },
      y: {
        display: true,
        grid: {
          display: false, // Hide the grid lines on the x-axis
        },
        title: {
          display: true,
          text: "No. of Leaves",
          color: "#A3A3A3",
        },
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false,
  };

  const handleCheckboxChange = (department: keyof VisibleDepartments) => {
    setVisibleDepartments((prev) => ({
      ...prev,
      [department]: !prev[department],
    }));
  };

  return (
    <div className="">
      <Card className=" w-100 pageContentInner rounded-2 ">
        {loading ? (
          <LineSkeleton />
        ) : (
          <Row>
            <Col className="col-md-9">
              <div className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fs-18 info-text fw-bold mb-3">
                    Leaves in the month
                  </h5>
                  <p className="gray-text fs-13 fw-bold">
                    Absenteeism YTD: {averageAbsenteeism}%
                  </p>
                </div>
                {chartLoading ? (
                 <div className="mb-3"> <BarChartSkeleton /></div>
                ) : (
                  <div className="w-100">
                    <Chart
                      type="bar"
                      data={data}
                      options={options}
                      height="200px"
                      width="200px"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col className="col-md-3 dashboard-border-left ps-0">
              <div className="">
                <h5 className="fs-18 info-text fw-bold mb-3 py-3 px-3 border-bottom">
                  Departments
                </h5>
                <ul className="dashboard-department-list">
                  <Scrollbar className="hrdashboard-deartment-list-scroll">
                    {Object.keys(departmentCheck).map((key, index) => {
                      const uniqueId = `${idPrefix}-${key}-${index}`; // Create a unique ID for each item

                      return (
                        <li
                          key={key}
                          className={`d-flex dashboard-department-color dashboard-department-color-${index}`}
                        >
                          <Form.Check
                            type="checkbox"
                            id={uniqueId}
                            className="me-2"
                            checked={visibleDepartments[departmentKeyMap[key]]}
                            onChange={() =>
                              handleCheckboxChange(departmentKeyMap[key])
                            }
                          />
                          <label htmlFor={uniqueId}>
                            {departmentCheck[key]}
                          </label>
                        </li>
                      );
                    })}
                  </Scrollbar>
              </ul>
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
};

export default EmployeeLeaveChart;
