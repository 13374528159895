import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";
import moment from 'moment';

import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import {
    getSentEmailTemplate
} from "../../../services/employeeService";
import datetimeFormats from "../../../constants/constants";


export default function ViewLetterTemplate() {
  const [loading, setLoading] = useState(true);
  const [sentEmailDetail, setSentEmailTemplate] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams<string>();

  /**
   * Get existing letter template from server
   * @async
   * @param {id} ID of template
   */
  const getSentEmailTemplateDetail = async (id: string) => {
    setLoading(true);
    await getSentEmailTemplate(id)
      .then((response: any) => {
        setSentEmailTemplate(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  /**
   * Cancel handler - redirection to listing
   * @returns {*}
   */
  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if(id){
        getSentEmailTemplateDetail(id);
    }
    else{
        navigate(-1);
    }
  }, []);

  const sanitizeHTML = (html: string) => {
    return { __html: html };
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">View Sent Email Detail</h1>
              </Col>
            </Row>
          </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
                <Card className="p-3 w-100 pageContentInner">
                <div>
                    <Row>
                      <div className="col-md-4 col-xl-4">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                            Receiver
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{ `${sentEmailDetail.receiver.full_name} (${sentEmailDetail.receiver.email})` }</p>
                          </div>
                        </div>
                          
                      </div>
                      <div className="col-md-4 col-xl-4">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                          CC Receivers
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{ sentEmailDetail.cc_receivers }</p>
                          </div>
                        </div>
                          
                      </div>
                    </Row>

                    <Row>
                      <div className="col-md-4 col-xl-4">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                            Sender
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{ `${sentEmailDetail.sender.full_name} (${sentEmailDetail.sender.email})` }</p>
                          </div>
                        </div>
                          
                      </div>
                      <div className="col-md-4 col-xl-4">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Date
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{moment(sentEmailDetail.created_at).format(datetimeFormats.dateFormats.slashSeparatedDate)}</p>
                          </div>
                        </div>
                          
                      </div>
                    </Row>
                    
                    <Row>
                      <div className="col-md-12 col-xl-12">
                        <div className="mb-3 custom-from-group">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                          Email Subject
                          </label>
                          <div className="position-relative ">
                            <p className="fs-14">{ sentEmailDetail.email_subject }</p>
                          </div>
                        </div>
                          
                      </div>
                      
                    </Row>

                    <Row>
                    <div className="col-md-12 col-xl-12">
                      <label className="lh-sm control-label fs-14 fw-bold form-label">Email Body</label>
                      <div className="position-relative ql-editor-box ql-editor p-20 w-100 br-10">
                            <p className="fs-14"><div dangerouslySetInnerHTML={sanitizeHTML(sentEmailDetail.email_body)} /></p>
                      </div>
                    </div>
                    
                    </Row>
                </div>
                </Card>
            </div>
        </div>
      )}
    </React.Fragment>
  );
}
