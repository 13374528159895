import { Card, Col, Row } from "react-bootstrap";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamAssignToProjects from "../../../components/common/Projects/assign-team";
import { getProjectAssignTeamAPi } from "../../../services/project.service";
import React from "react";
import Loader from "../../../components/common/Loader";
import { checkAbility } from "../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
interface AddProps {
  id : any,
  onAssignTeam : () =>void;
  projectData?: any;
}
const AssignTeam: React.FC<AddProps> = ({
  id, onAssignTeam, projectData
}) => {
  //let { id } = useParams();
  const navigate = useNavigate();
  const [projectOwnerOpen, setProjectOwnerOpen] = useState(false);
  const [teamAssignOpen, setTeamAssignOpen] = useState(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(true);
  

  const toggleTeamAssign = () => {
    setTeamAssignOpen(!teamAssignOpen);
    onAssignTeam();
    fetchData();
  };

  useEffect(() => {
    fetchData().then(() => {
      // Call this function after fetchData completes
    });
  }, [projectData]);

  const fetchData = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getProjectAssignTeamAPi(id)
        .then((response) => {
          setSelectedTeamMembers(response.data);
          setLoading(false);   
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);         
          reject(error);
        });
    });
  }
 
  const assignTeamPopup = () => {
    const defaultParams: any = [8];

    const projectIds: any = [
      {
        id: id,
      },
    ];
    setSelectionModel(projectIds);
    setTeamAssignOpen(!teamAssignOpen);
  };

  return (
    <>
    {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
      <Card className="mb-3 p-3 w-100 flex-wrap">
        <Row className="d-flex flex-row justify-content-between">
          <Col className="col-md-12 d-flex flex-row justify-content-between align-items-center">
            <label className="fs-18 info-text fw-bold d-inline-block">
              Assign Team
            </label>
            {checkAbility(
            PERMISSION_ACCESS.EDIT,
            PERMISSION_MODULE.PROJECTS
          ) && projectData?.status != 'cp' && (
            <button
              type="button"
              className="bg-white border-0 p-0"
              onClick={() => assignTeamPopup()}
            >
              <AddCircleIcon className="text-primary fs-20 " />
            </button>
          )}
          </Col>
        </Row>        
        {selectedTeamMembers.map((teamMember: any, index: any) => (
                        <Row key={index}>
                          <div className="col-md-12 d-flex flex-row justify-content-between align-items-center">
                            <div className="shelves-list-cover">
                              <div className="fs-14 mw-120 ">
                               {teamMember?.category ? teamMember?.category : teamMember?.user?.employeeDetails?.designation ? teamMember?.user?.employeeDetails?.designation : "-"}
                              </div>
                              
                            </div>
                            <div className="cursor-pointer fw-bold fs-14 text-end">
                              {teamMember?.user?.full_name} ({teamMember?.user?.employeeDetails?.designation})
                              </div>
                          </div>
                        </Row>
                      ))}
        <Row>
          <Col>
          </Col></Row>     
      </Card>
      { teamAssignOpen && (
        <TeamAssignToProjects
          isOpen={teamAssignOpen}
          onClose={toggleTeamAssign}
          projectsData={selectionModel}
          selectedTeamMembers={selectedTeamMembers}
        />
      )}
      </>
      )
}
    </>
  );
};
export default AssignTeam;
