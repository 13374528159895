import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { addSchema } from "./validation";
import InputText from "../../../components/common/InputText";
import CustomButton from "../../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { formTemplateMaxFileSizeMB, pdfFileTypes } from "../../../constants/constants";
import { useNavigate, useParams } from "react-router-dom";
import InputTextArea from "../../../components/common/InputTextArea";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";
import GeneralFileUploader from "../../../components/common/GeneralFileUploader";
import { FORM_TEMPLATE_SIZE_ERROR, PDF_TYPE_ERROR } from "../../../validations/ValidationErrors";
import { createLibraryApi, getLibraryByIdApi, updateLibraryApi } from "../../../services/libraryService";

interface IHolidays {
  id?: number;
  title?: string;
  description?: string;
  file?: any;
  document?: any;
  createdAt: string;
  updatedAt: string;
}


interface FormValues {
  title: string;
  description: any;
  filename: any;
  file: any;
}
export const LibraryAdd = () => {
  const [editableData, setEditableData] = useState<IHolidays>();
  const [projectOwnerOption, setProjectOwnerOption] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();
  const filterOptions = (editableOptions: any) => {
    const filteredArray = projectOwnerOption.filter((itemTwo: any) =>
      editableOptions.some((itemOne: any) => itemOne.user_id === itemTwo.key)
    );

    return filteredArray;
  };
  const initialValues = {
    title: editableData && editableData.title ? editableData.title : "",
    description:
      editableData && editableData.description ? editableData.description : "",
    filename: editableData?.document ? editableData?.document : "",
    file:""
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setBtnLoading(true);
    const formData: any = {
      ...values,
    };


    if(typeof values.file === 'string'){
      delete formData.file;
    }

    delete formData.filename;

    if (id) {
      updateLibraryApi(id, formData)
        .then((response) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/library");
          setBtnLoading(false);
        })
        .catch((error) => {
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createLibraryApi(formData)
        .then((response) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/library");
          setBtnLoading(false);
        })
        .catch((error) => {
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getLibraryDetail = async (id: number | string) => {
    setLoading(true);
    await getLibraryByIdApi(id)
      .then((response: any) => {
        setEditableData(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    if (id) {
      getLibraryDetail(id);
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Upload form template PDF file
   * @param {events, setFieldValue}
   * @returns {*}
   */
  const uploadFile = (event: any, setFieldValue: any) => {
    const file = event.target.files[0];
    if(file){
      // Check file type
      if (!pdfFileTypes.includes(file.type)) {
        toast(PDF_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (file.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
        toast(FORM_TEMPLATE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        setFieldValue('file', file);
        setFieldValue('filename',file.name);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {id ? "Edit" : "Add New"} Library
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={addSchema}
              initialValues={initialValues}
              onSubmit={(values: FormValues, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    {/* { JSON.stringify(values)} */}
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-3">
                            <InputText
                              controlId="title"
                              label="Title"
                              placeholder="Enter Title"
                              touched={touched.title}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.title}
                              value={values.title}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-8">
                            <InputTextArea
                              controlId="description"
                              label="Description"
                              placeholder="Enter Description"
                              touched={touched.description}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.description}
                              value={values.description}
                            />
                          </Col>
                        </Row>
                        <Row>
                      <div className="col-md-12">
                          <GeneralFileUploader
                            uploadFile={(e) =>
                              uploadFile(e, setFieldValue)
                            }
                            imageLoading={imageLoading}
                            label="Upload Document"
                            filename={values.filename}
                            acceptFormat=".pdf"
                          />
                          
                        </div>
                      </Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <CustomButtonCancel label="Cancel" />
                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={btnLoading}
                            className="fw-semibold fs-13 text-white mw-90 mt-2"
                            variant="primary"
                          >
                            {id ? "Update" : "Add"}
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
