import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState, useRef } from "react";
import { FieldArray, Formik, getIn } from "formik";
import { validationSchema } from "./validations";

import {
  addFieldAPICall,
  updateFieldAPICall,
  getFieldByIdApiCall,
} from "../../../services/formbuilder/field.services";
import { toast } from "react-toastify";
import {
  Button,
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Card,
  Badge,
  FormGroup,
  Form,
} from "react-bootstrap";

import InputText from "../../../components/common/InputText";
import Loader from "../../../components/common/Loader";
import FormSelect from "../../../components/common/FormSelect";
import InputCheckBox from "../../../components/common/InputCheckBox";
import {
  formTypeOption,
  visibilityOptions,
} from "../../../constants/constants";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";
import CustomButton from "../../../components/common/CustomButton";
import addIcon from "../../../assets/images/formbuilderAdd.svg";
import deleteIcon from "../../../assets/images/formbuilderdelete.svg";
import { useParams } from "react-router-dom";

export default function AddField(props) {
  const [fieldData, setFieldData] = useState(null);
  const [formLoader, setFormLoader] = useState(true);
  const [labelModel, setLabelModel] = useState(false);
  const [totalRow, setTotalRow] = useState();
  const [rowName, setRowName] = useState();
  const [dataSetValidation, setDataValidation] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const divRef = useRef(null);

  let id = props.fieldId;
  let fieldSheetId = props.fieldSheetId;
  
  useEffect(() => {
    if (id) {
      getFieldByIdApiCall(id).then((response) => {
        setFieldData(response.data);

        setFormLoader(false);
      });
    } else {
      setFormLoader(false);
    }
  }, []);

  const handleAddValue = (rows, setFieldValue, name) => {
    setLabelModel(!labelModel);
    setTotalRow(rows);
    setRowName(name);
    setDataValidation(setFieldValue);
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const formatedValues = {
      ...values,
      type: values.type.key,
      order: parseInt(values.order),
      visibility: values.visibility.key == "Visible" ? true : false,
    };
    if (id) {
      updateFieldAPICall(id, formatedValues)
        .then((response) => {
          toast.success(response.msg);
          props.handleFieldModalClose();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.response.data.msg);
        });
    } else {
      addFieldAPICall(formatedValues)
        .then((response) => {
          //navigate(adminRoutes.manageFaq.path);
          toast.success(response.msg);
          props.handleFieldModalClose();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.response.data.msg);
        });
    }
  };
  return (
    <div id="section">
      {formLoader ? (
        <Loader />
      ) : (
        <Modal
          show={props.showFieldModel}
          onHide={props.handleFieldModalClose}
          centered
          backdrop="static"
          dialogClassName="modal-590w"
        >
          <Modal.Body>
            <button
              type="button"
              className="btn-close text-right  btn-option-cover"
              aria-label="Close"
              onClick={props.handleFieldModalClose}
            ></button>
            <h4 className="fw-bold info-text mb-3">
              {" "}
              {props.title ? props.title : "Add New Field"}
            </h4>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                type: fieldData
                  ? formTypeOption.find((item) => item.key === fieldData.type)
                  : "",
                title: fieldData ? fieldData.title : "",
                order: fieldData
                  ? parseInt(fieldData.order)
                  : parseInt(props.orderLength),
                subsection_id: props.subSectionId,
                fieldsheet_id: fieldSheetId,
                require: fieldData ? fieldData.require : false,
                default_value:
                  fieldData && fieldData.default_value != null
                    ? fieldData.default_value
                    : "",
                data_sets: fieldData ? fieldData.data_set : [],
                visibility: fieldData
                  ? visibilityOptions.find(
                      (item) =>
                        item.key ===
                        (fieldData.visibility ? "Visible" : "Invisible")
                    )
                  : "",
                  min_length : fieldData ? fieldData.min_length : 0,
                // from :"",
                // to:""
              }}
              onSubmit={handleSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                setFieldError,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Row>
                          <Col sm={6}>
                            <InputText
                              controlId="title"
                              label="Field Title"
                              placeholder="Enter field title"
                              touched={touched.title}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.title}
                              value={values.title}
                            />
                          </Col>
                          <Col sm={6}>
                            <label
                              class="lh-sm control-label fs-14 fw-bold form-label"
                              for="title"
                            >
                              Order
                            </label>
                            <select
                              name="order"
                              onChange={handleChange}
                              error={errors.order}
                              value={values.order}
                              touched={touched.order}
                              disabled={props.isView}
                              className={`form-control form-select mb-3 ${
                                errors.order && touched.order
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              {/* <option value="0">Select Order</option> */}
                              {Array.from(
                                { length: props.orderLength },
                                (_, index) => (
                                  <option key={index + 1} value={index + 1}>
                                    {index + 1}
                                  </option>
                                )
                              )}
                            </select>
                            {touched.order && errors.order && (
                              <p className="fs-11 text-danger pt-1 mb-0">
                                {errors.order}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <FormSelect
                              placeholder="Type"
                              label="Field Type"
                              name="type"
                              options={formTypeOption}
                              getOptionLabel={(option) => option.value}
                              getOptionValue={(option) => option.key}
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                               
                                setFieldError("data_sets", "");
                                setFieldValue("default_value", "");

                                if (value.key == "dropdown" ||value.key == "radio_button"  ) {
                                  setFieldValue("data_sets", [{ label: "" }]);
                                }
                                if (value.key == "slider") {
                                  setFieldValue("data_sets", [
                                    { from: "", to: "" },
                                  ]);
                                  setFieldError("data_sets", "");
                                }
                                if(value.key=="text"){
                                  setFieldValue("default_value", "");
                                }
                                if(value.key=="button"){
                                  setFieldValue("data_sets",[{button_message:"",button_title:""}]);
                                }
                              }}
                              value={values.type}
                              error={errors.type}
                              touched={touched.type}
                            />
                          </Col>
                          <Col sm={6}>
                            <FormSelect
                              placeholder="Select Visibility"
                              label="Visibility"
                              name="visibility"
                              options={visibilityOptions}
                              getOptionLabel={(option) => option.value}
                              getOptionValue={(option) => option.key}
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                              }}
                              value={values.visibility}
                              error={errors.visibility}
                              touched={touched.visibility}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <FormGroup className="">
                        
                          {/* Slider button  */}
                          {values.type.key === "slider" && (
                            <>
                              <FieldArray
                                name="data_sets"
                                render={(arrayHelpers) => (
                                  <>
                                    <Col md={12}>
                                      {values.data_sets.map(
                                        (data_set, index) => (
                                          <Row key={index}>
                                            <Col sm={6}>
                                              <Row className="g-2">
                                                <Col sm={6}>
                                                  <InputText
                                                    name={`data_sets.${index}.from`}
                                                    controlId={`data_sets.${index}.from`}
                                                    label="Range"
                                                    placeholder="From"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={getIn(
                                                      values,
                                                      `data_sets[${index}].from`
                                                    )}
                                                    errorsField={getIn(
                                                      errors,
                                                      `data_sets[${index}].from`
                                                    )}
                                                    touched={getIn(
                                                      touched,
                                                      `data_sets[${index}].from`
                                                    )}
                                                  />
                                                </Col>

                                                <Col sm={6}>
                                                  <InputText
                                                    name={`data_sets.${index}.to`}
                                                    controlId={`data_sets.${index}.to`}
                                                    label=" "
                                                    placeholder="To"
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    value={getIn(
                                                      values,
                                                      `data_sets[${index}].to`
                                                    )}
                                                    errorsField={getIn(
                                                      errors,
                                                      `data_sets[${index}].to`
                                                    )}
                                                    touched={getIn(
                                                      touched,
                                                      `data_sets[${index}].to`
                                                    )}
                                                  />
                                                </Col>
                                              </Row>
                                            </Col>
                                            <Col sm={6}>
                                              <InputText
                                                name={`data_sets.${index}.default_value`}
                                                controlId={`data_sets.${index}.default_value`}
                                                label="Default Value "
                                                placeholder="default value"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={getIn(
                                                  values,
                                                  `data_sets[${index}].default_value`
                                                )}
                                                errorsField={getIn(
                                                  errors,
                                                  `data_sets[${index}].default_value`
                                                )}
                                                touched={getIn(
                                                  touched,
                                                  `data_sets[${index}].default_value`
                                                )}
                                              />
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                    </Col>
                                  </>
                                )}
                              />{" "}
                            </>
                          )}

                          {/* Message  */}
                          {(values.type.key === "message") && (
                            <>
                              {" "}
                              <Col sm={12}>
                                <InputText
                                  name="default_value"
                                  controlId="default_value"
                                  label={
                                    values.type.key == "message"
                                      ? "Default Message"
                                      : "Default Value"
                                  }
                                  placeholder={
                                    values.type.key == "message"
                                      ? "Enter Default Message"
                                      : "Enter Default Value"
                                  }
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  value={values.default_value}
                                  errorsField={errors.default_value}
                                  touched={touched.default_value}
                                />
                              </Col>
                            </>
                          )}

                          {/* Textbox */}
                          {(values.type.key === "text") && (
                            <>
                              {" "}
                              <Col sm={6}>
                                <InputText
                                  name="default_value"
                                  controlId="default_value"
                                  label={
                                    values.type.key == "message"
                                      ? "Default Message"
                                      : "Default Value"
                                  }
                                  placeholder={
                                    values.type.key == "message"
                                      ? "Enter Default Message"
                                      : "Enter Default Value"
                                  }
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  value={values.default_value}
                                  errorsField={errors.default_value}
                                  touched={touched.default_value}
                                />
                              </Col>
                            </>
                          )}
                          {/* Dropdown  and Radio button */}
                          {(values.type.key == "dropdown" ||
                            values.type.key == "radio_button") && (
                            <Row>
                              <FieldArray
                                name="data_sets"
                                render={(arrayHelpers) => (
                                  <>
                                    <Col md={12}>
                                      <label className="fs-16 info-text mb-2 fw-bold p-0 mt-2 ">
                                        Values
                                      </label>
                                    </Col>
                                    <Col md={12}>
                                      {errors.data_sets &&
                                        typeof errors.data_sets == "string" && (
                                          <p className="text-danger">
                                            {errors.data_sets}
                                          </p>
                                        )}

                                      {values.data_sets.map(
                                        (data_set, index) => (
                                          <Row key={index}>
                                            <div className="field-sheet-add-filed-default mb-2 d-flex ">
                                              <div className="field-sheet-add-filed-default-left mt-0 modal-field-sheet-input-cover">
                                                <InputText
                                                  controlId={`data_sets.${index}.label`}
                                                  name={`data_sets.${index}.label`}
                                                  placeholder="Enter Label"
                                                  handleChange={handleChange}
                                                  handleBlur={handleBlur}
                                                  value={getIn(
                                                    values,
                                                    `data_sets[${index}].label`
                                                  )}
                                                  errorsField={getIn(
                                                    errors,
                                                    `data_sets[${index}].label`
                                                  )}
                                                  touched={getIn(
                                                    touched,
                                                    `data_sets[${index}].label`
                                                  )}
                                                  disabled={props.isView}
                                                />
                                              </div>
                                              {index == 0 ? (
                                                <div
                                                  className="fw-semibold modal-plus-icon mt-0"
                                                  onClick={() => {
                                                    arrayHelpers.push({
                                                      label: "",
                                                    });
                                                  }}
                                                >
                                                  <img src={addIcon} />
                                                </div>
                                              ) : (
                                                <div
                                                  className="fw-semibold modal-plus-icon mt-0"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                >
                                                  <img src={deleteIcon} />
                                                </div>
                                              )}
                                            </div>
                                          </Row>
                                        )
                                      )}
                                    </Col>

                                    <Col md={6}></Col>
                                  </>
                                )}
                              />{" "}
                            </Row>
                          )}

                          {/* Media Upload Button  */}
                          {values.type.key === "button" && (
                            <>
                             
                             <FieldArray
                                name="data_sets"
                                render={(arrayHelpers) => (
                                  <>
                                    <Col md={12}>
                                      {values.data_sets.map(
                                        (data_set, index) => (
                                          <Row key={index}>
                                            

                                            <Col sm={6}>
                                              <InputText
                                                name={`data_sets.${index}.button_message`}
                                                controlId={`data_sets.${index}.button_message`}
                                                label="Buton Message"
                                                placeholder="Enter button message"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={getIn(
                                                  values,
                                                  `data_sets[${index}].button_message`
                                                )}
                                                errorsField={getIn(
                                                  errors,
                                                  `data_sets[${index}].button_message`
                                                )}
                                                touched={getIn(
                                                  touched,
                                                  `data_sets[${index}].button_message`
                                                )}
                                              />
                                            </Col>
                                            <Col sm={6}>
                                              <InputText
                                                name={`data_sets.${index}.button_title`}
                                                controlId={`data_sets.${index}.button_title`}
                                                label="Button Title"
                                                placeholder="Enter button title"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={getIn(
                                                  values,
                                                  `data_sets[${index}].button_title`
                                                )}
                                                errorsField={getIn(
                                                  errors,
                                                  `data_sets[${index}].button_title`
                                                )}
                                                touched={getIn(
                                                  touched,
                                                  `data_sets[${index}].button_title`
                                                )}
                                              />
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                    </Col>
                                  </>
                                )}
                              />{" "}

                            </>
                          )}
                        
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <FormGroup className=" mb-3">
                        <Row>
                          <Col sm={6}>
                            <InputCheckBox
                              controlId="require"
                              label="Require"
                              touched={touched.require}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.require}
                              value={values.require}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    {values.type.key === "button" && values.require && (
                    
                    <Col md={6}>
                      <FormGroup className=" mb-3">
                        <Row>
                          <Col sm={6}>
                          <InputText
                                  name="min_length"
                                  controlId="min_length"
                                  label="Min Images"
                                  placeholder="Enter Minimum Images to upload"
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  value={values.min_length}
                                  errorsField={errors.min_length}
                                  touched={touched.min_length}
                                  type="number"
                                />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                   
                  )}
                  </Row>

                 

                  <div className="text-end ">
                    <div
                      className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                      onClick={props.handleFieldModalClose}
                    >
                      Cancel
                    </div>

                    <CustomButton
                      type="submit"
                      loading={btnLoading}
                      className="fw-semibold fs-13 text-white mw-90 mt-2"
                      variant="primary"
                    >
                      {props.field_btn_title}
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
