import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { getProjectList } from "../../../services/project.service";
import Loader from "../../../components/common/Loader";
import FileUploadIcon from "../../../assets/images/file-upload.svg";
import BlockIcon from "@mui/icons-material/Block";
import PaymentsIcon from "@mui/icons-material/Payments";
import PlaceIcon from "@mui/icons-material/Place";
import { Button, Card, Col, Row } from "react-bootstrap";
import constants, {
  PROJECT_PREFIX,
  invoiceStatus,
  pageSetting,
  pageSizeModel,
} from "../../../constants/constants";
import ViewAgenda from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddCircle from "../../../assets/images/add_circle.svg";
import { checkAbility, currencyMask } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import moment from "moment";
import ExportFinanceProjectsData from "./exportData";
import {
  deleteInvociesApi,
  getInvociesList,
  syncInvoicesFromXeroApi,
} from "../../../services/financeService";
import DeletePopup from "../../../components/common/deletePopup";
import { toast } from "react-toastify";

const FinanceInvoiceList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [exportPopup, setExportPopup] = useState(false);

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`view/${id}`);
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`edit/${id}`);
  };

  const handleAddClick = () => {
    navigate(`add`);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteInvociesApi(deletedId)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getInvociesList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if (filterModel.items[0].field === "inv_number") {
        filterModel.items[0].value = filterModel.items[0].value.replace(
          /[INV-]/g,
          ""
        );
      }      
      if (filterModel.items[0].field === "$project.project_no$") {
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');        
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "inv_number",
      headerName: "Invoice No.",
      flex: 1,
      sortable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell(params) {
        return (<span className="blue-text fw-bold">{params.row.inv_number_str}</span>);
      },
    },
    { field: "customer_name", headerName: "Customer", flex: 1, sortable: true },
    { field: "phone_no", headerName: "Phone", flex: 1, sortable: true },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row?.address} className="text-primary text-center">
            <PlaceIcon />
          </span>
        );
      },
    },
    {
      field: "$project.project_no$",
      headerName: "Project Order No.",
      flex: 1,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell(params) {
        return checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.FINANCE_PROJECTS) ? (<Link
          target="_blank"
          className="blue-text text-decoration-none fw-semibold"
          to={`/finance/projects/view/${params.row.contract_id}`}
        >
          {" "}
          <span title={params.row.project.project_no}>{params.row.project.project_no}</span>
        </Link>) : params.row.project.project_no
      }
    },
    {
      field: "milestone_name",
      headerName: "Payment Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "inv_date",
      headerName: "Invoice Date",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.inv_date),
      renderCell(params) {
        const orderDate = new Date(params.row.inv_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.due_date),
      renderCell(params) {
        const orderDate = new Date(params.row.due_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "total_amount",
      type: "number",
      headerName: "Amount",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row?.total_amount}>
            {currencyMask(params.row?.total_amount)}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type: "singleSelect",
      valueOptions: invoiceStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf"
      ),
      renderCell(params) {
        const statusName = invoiceStatus.find(
          (item) => item.key == params.row.status
        );
        return (
          <span
            title={statusName?.value}
            className={`py-1 px-2 ms-0 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
          >
            {statusName?.value}
          </span>
        );
      },
    },
  ];

  const handleEditViewActionPermission = () => {
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.FINANCE_INVOCIES
    );
    

    if (
      checkViewAbilityCondition 
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                icon={<ViewAgenda />}
                showInMenu={false}
                className="text-primary"
                onClick={handleViewClick(id)}
              />
            );
          }

          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu={false}
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  };

  const syncInvoicesFromXero = () => {   
    syncInvoicesFromXeroApi()
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });        
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });   
  };

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {exportPopup && (
            <ExportFinanceProjectsData
              isOpen={exportPopup}
              onClose={toggleExportPopup}
              exportType="project"
              label="Export Invoices"
              text="Export Invoices"
            />
          )}
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Invoices</h1>
              </Col>
              <Col className="text-end">
              <button onClick={() => syncInvoicesFromXero()} className="fw-semibold fs-12  btn btn-outline-info me-2 px-2 btn-outline-secondary-hover">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50002 3.16675V0.791748L6.33335 3.95842L9.50002 7.12508V4.75008C12.1204 4.75008 14.25 6.87967 14.25 9.50008C14.25 10.2997 14.0521 11.0597 13.6959 11.7167L14.8517 12.8726C15.4692 11.8988 15.8334 10.743 15.8334 9.50008C15.8334 6.00092 12.9992 3.16675 9.50002 3.16675ZM9.50002 14.2501C6.8796 14.2501 4.75002 12.1205 4.75002 9.50008C4.75002 8.7005 4.94794 7.9405 5.30419 7.28342L4.14835 6.12758C3.53085 7.10133 3.16669 8.25717 3.16669 9.50008C3.16669 12.9992 6.00085 15.8334 9.50002 15.8334V18.2084L12.6667 15.0417L9.50002 11.8751V14.2501Z"
                      fill="#21426B"
                    />
                  </svg>
                </button>
                <button
                  className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                  onClick={toggleExportPopup}
                >
                  <img src={FileUploadIcon} alt="File Upload Icon" />
                </button>                
              </Col>
            </Row>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="w-100 pageContentInner">
              <div className="d-flex flex-column rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  disableRowSelectionOnClick
                  localeText={{ noRowsLabel: "No Projects found" }}
                  className="border-0 rounded-2"
                />
              </div>
            </Card>
          </div>
          <DeletePopup
            isOpen={isDeletePopupOpen}
            onClose={handleClose}
            onDelete={handleDelete}
            label="Delete Invoice"
            text="Are you sure you want to delete this Invoice?"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FinanceInvoiceList;
