import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import { currencyMask } from "../../../utility/common";
import { label } from "yet-another-react-lightbox";
import { getFinanceDashboardProjectsOnboardStats } from "../../../services/financeService";
import moment from "moment";
import constants from "../../../constants/constants";
import BarChartSkeleton from "../../../components/common/Skeleton/BarChart.skeleton";

interface AddProps {
  dateRange: any;
}

export const FinanceDashboardNewProjectOnboard: React.FC<AddProps> = ({ dateRange }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const [selectFilter, setSelectFilter] = useState<any>({
    key: 6,
    value: "Last 6 Months",
  });
  const [loading, setLoading] = useState(true);
  const getDashboardNewOnboardProjects = () => {
    // setLoading(true);
    // console.log(selectFilter);
    const requestData = dateRange;
    getFinanceDashboardProjectsOnboardStats(requestData)
      .then((response) => {
       // setPoRaisedData(response.data);
        convertToChartData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getDashboardNewOnboardProjects();
  }, [dateRange]);
  const labels = [
    "Jan"
  ];

  const POdata = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [0],
        backgroundColor: "#FFA133",
        borderRadius: 5,
      },
    ],
  };

  // Function to convert API response to Chart.js data
  const convertToChartData = (apiResponse: any[]) => {
    const labels = apiResponse.map((item) => item.project_month);
    const data = apiResponse.map((item) => item.project_count);
    /*  const backgroundColor = [
      "#4ECB71",
      "#FFA133",
      "#5299DB"
    ]; */

    setPoRaisedData({
      labels,
      datasets: [
        {
          label : "New Projects",
          data,
          backgroundColor: "#FFA133",
          borderRadius: 5,
        },
      ],
    });
  };

  const [poRaisedData, setPoRaisedData] = useState<any>(POdata);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          color: "#889EAE",
          font: {
            size: "14",
            family: "Nunito Sans, sans-serif"
          },
          
          text: "New Projects", // Label for the y-axis
        },
        ticks: {
          color: "#000000",          
        },
        grid:{
          drawOnChartArea: false
        }
      },
      x: {
        barThickness: 20, // Fixed bar width
        grid:{
          drawOnChartArea: false
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      }
    },
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column pageContainer px-sm-1 w-100 pb-sm-1">
        <Card className="p-3 w-100 h pageContentInner rounded-2">
          <h5 className="fs-18 fw-bold info-text mb-0">
            New Project Onboarded
          </h5>
          {loading ? (
             <BarChartSkeleton heigth={320}/>
            
          ) : (
            <div className="finance-dashboard-forecast-grap mt-3">
              <Bar data={poRaisedData} options={options} />
            </div>
          )}
        </Card>
      </div>
    </React.Fragment>
  );
};
