import { axios } from "./axios";
import { EXPENSE_ASK_QUERY, EXPENSE_CATEGORY_LIST, EXPENSE_DELETE, EXPENSE_DETAIL, EXPENSE_EDIT, EXPENSE_EXPORT, EXPENSE_FINANCE_DELETE, EXPENSE_FINANCE_DETAIL, EXPENSE_FINANCE_EXPORT, EXPENSE_FINANCE_INVOICE_ADD, EXPENSE_FINANCE_LIST, EXPENSE_LOG_ADD, EXPENSE_LOG_LIST, EXPENSE_QUERY_LIST, EXPENSE_TEAM_DETAIL, EXPENSE_TEAM_LIST, EXPENSE_TEAM_STATUS_UPDATE, EXPENSE_UPLOAD_FILE } from "../constants/paths";

export type createExpenseDTO = {
  title: string;
  contract_id: any;
  category: any;
  item_cost?: string;
  qty?: string;
  filename?: string;
  submitType?: string;
};
export const getExpenseList = (formData: any): Promise<any> => {
  return axios.get(`${EXPENSE_LOG_LIST}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const uploadExpenseFileApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${EXPENSE_UPLOAD_FILE}`, data, config);
};

export const createExpenseApi = (data: createExpenseDTO): Promise<any> => {
  const transformData = {
    ...data,
    contract_id:data?.contract_id?.id ?? null,
    category_id: data?.category?.parent_id ?? data.category.key,
    sub_category_id:data?.category?.parent_id ? data.category.key : null,

  };
  delete transformData.filename;
  delete transformData.category;
  delete transformData.submitType;

  return axios.post(EXPENSE_LOG_ADD, transformData);
};

/**
 * @returns Category list
 */
export const getExpenseCategoriesApi = (): Promise<any> => {
  return axios.get(EXPENSE_CATEGORY_LIST);
};

/**
 * @param {id: string } params
 * @returns Expense details
 */
export const getExpenseByIdApi = (id: string | number, viewType: string): Promise<any> => {
  const apiPath = viewType == 'rm' ? EXPENSE_TEAM_DETAIL : viewType == 'fm' ? EXPENSE_FINANCE_DETAIL : EXPENSE_DETAIL;
  return axios.get(apiPath + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateExpenseApi = (
  id: string | number | undefined,
  data: createExpenseDTO
): Promise<any> => {
  const transformData = {
    ...data    
  };
  return axios.put(EXPENSE_EDIT + "/" + id, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteExpenseApi = (id: string | number | null, viewType?: string): Promise<any> => {
  const apiPath = viewType == 'fm' ? EXPENSE_FINANCE_DELETE :  EXPENSE_DELETE;
  
  return axios.delete(apiPath + "/" + id);
};

export const updateStatusExpenseApi = (id: string | number | null, status: any): Promise<any> => {
  const transformData = {
    status : status=='Accept' ? "1" : "2"
  };
  return axios.patch(EXPENSE_TEAM_STATUS_UPDATE + "/" + id, transformData);
};

export const exportExpensesLogData = (params:any = {}) : Promise<any> => {
  return axios.get(EXPENSE_EXPORT,{params,responseType: 'arraybuffer',});
}

export const getTeamExpenseList = (formData: any): Promise<any> => {
  return axios.get(`${EXPENSE_TEAM_LIST}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getTeamExpenseByIdApi = (id: string | number): Promise<any> => {
  return axios.get(EXPENSE_TEAM_DETAIL + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const getExpensesCommentsApi = (id: string | number | undefined): Promise<any> => {
  return axios.get(EXPENSE_QUERY_LIST + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Ticket details
 */
export const saveExpensesCommentsApi = (ticketId: string | number | undefined | null, data:any): Promise<any> => {
  const transformData = {
    ...data,
  };

  delete transformData.filename;
  
  return axios.post(`${EXPENSE_ASK_QUERY}/${ticketId}`, transformData);
};

export const getFinancExpenseList = (formData: any): Promise<any> => {
  return axios.get(`${EXPENSE_FINANCE_LIST}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const exportFinanceExpensesApi = (params:any) : Promise<any> => {
  return axios.get(EXPENSE_FINANCE_EXPORT,{params,responseType: 'arraybuffer',});
}

export const saveFinanceInvoiceApi = (expensesIds:any): Promise<any> => {
  const transformData = {
    expense_ids: expensesIds,
  };
  
  return axios.post(`${EXPENSE_FINANCE_INVOICE_ADD}`, transformData);
};