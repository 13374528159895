import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  employeeMeetingsList,
  managerMeetingsList,
  yearType,
  meetingTypes,
  deleteEmployeeMeeting,
} from "../../../../services/employeeService";
import Loader from "../../../../components/common/Loader";
import Email from "@mui/icons-material/Email";
import Message from "@mui/icons-material/Message";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import EmailIcon from "@mui/icons-material/Email";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Card } from "react-bootstrap";
import datetimeFormats, {
  smallPageSetting,
  smallPageSizeModel,
  Employee,
  Reporting_Manager,
  MEETING_INITIAL_YEAR,
  MEETING_SOURCE_PAGES
} from "../../../../constants/constants";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../../constants/permissionUser";
import { checkAbility } from "../../../../utility/common";
import "./Meetings.scss";
import FormSelect from "../../../../components/common/FormSelect";
import DeletePopup from "../../../../components/common/deletePopup";

const years: yearType[] = [];

for (let year = MEETING_INITIAL_YEAR; year <= moment().year() + 1; year++) {
  years.push({ key: year, value: year });
}

interface EmployeeMeetingTableProp {
  employeeID: number;
  meetingType: string;
  meetingLabel: string;
  year: number;
  onChangeYear: (selectedYear: yearType) => void;
  onAdd?: (meetingType: string) => void;
  onEdit?: (meetingId: GridRowId, meetingType: string, empDetail?: any) => void;
  onView: (meetingId: GridRowId) => void;
  onOpenMeetingNotes?: (meetingId: GridRowId, meetingType: string) => void;
  isMutation: meetingTypes;
  loggedInID?: number;
  source: string;
}

const EmployeeMeetingsTable: React.FC<EmployeeMeetingTableProp> = ({
  employeeID,
  meetingType,
  meetingLabel,
  year,
  onChangeYear,
  onAdd,
  onEdit,
  onView,
  onOpenMeetingNotes,
  isMutation,
  loggedInID,
  source,
}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(smallPageSetting.defaultPage);
  const [limit, setLimit] = useState(smallPageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [isLocalMutation, setIsLocalMutation] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  /**
   * Close delete confirmation modal
   */
  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  /**
   * Delete meeting click handler
   */
  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  /**
   * Delete meeting: Delete the meeting from server
   */
  const handleDelete = () => {
    setLoading(false);
    deleteEmployeeMeeting(deletedId)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsLocalMutation(!isLocalMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const convertDataToObjects = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      date: new Date(item.date),
    }));
  };

  /**
   * Fetch sent emails from server
   * @param {defaultParams}
   */
  const fetchData = (defaultParams: any) => {
    const meetingRequestList =
      (source === MEETING_SOURCE_PAGES.reporting_manager || source === MEETING_SOURCE_PAGES.reporting_manager_team) && loggedInID
        ? managerMeetingsList(
            loggedInID,
            meetingType,
            year,
            defaultParams,
            (source === MEETING_SOURCE_PAGES.reporting_manager ? employeeID : null)
          )
        : employeeMeetingsList(employeeID, meetingType, year, defaultParams);

    meetingRequestList
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet);
        setData(convertData);
      })
      .catch((error) => {
        toast(error.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  /**
   * Click handler for send email option
   */
  const handleSendEmail = (id: GridRowId) => {
    navigate(`/employees/send-email/${employeeID}/${id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S. No.",
      sortable: true,
      filterable: false,
    },
    {
      field: "title",
      headerName: "Meeting Title",
      flex: 1.5,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      sortable: true,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      sortable: true,
      type: "date",
      renderCell(params) {
        const dateValue = new Date(params.row.date);
        return (
          <span>
            {moment(dateValue).format(
              datetimeFormats.dateFormats.slashSeparatedDate
            )}
          </span>
        );
      },
    },
  ];

  // Add reporting manager column for Employee and HR
  const reportingManagerColumn = () => {
    columns.push({
      field: "$manager.full_name$",
      headerName: "Reporting Manager",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.manager.full_name}>
            {params.row.manager.full_name}
          </span>
        );
      },
    });
  };

  // Add Employee column for Reporting manager teams meetings
  const employeeColumn = () => {
    columns.push({
      field: "$employee.full_name$",
      headerName: "Employee",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.employee.full_name}>
            {params.row.employee.full_name}
          </span>
        );
      },
    });
  };

  // Action and status column for HR
  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );

    columns.push(
      {
        field: "status",
        headerName: "Status",
        flex: 1.2,
        sortable: true,
        renderCell(params) {
          if (params.row.status) {
            const className = params.row.status
              .toLowerCase()
              .replace(/\s+/g, "-");
            return (
              <span className={`meeting-status-${className}`}>
                {params.row.status}
              </span>
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 0.8,
        cellClassName: "actions",
        getActions: (params) => {
          const gridActions = [];
          if(meetingType === "one_on_one"){
            gridActions.push(
              <GridActionsCellItem
                label="Notes"
                title="Notes"
                onClick={() => onView(params.row.id)}
                icon={<Message />}
                className={`${
                  params.row.status_key === "pending_from_colleague"
                    ? "notes-pending-from-colleague"
                    : "notes"
                }`}
              />,
              
            )
          }
          if (checkEditAbilityCondition) {
            gridActions.push(<GridActionsCellItem
              label="Send Email"
              onClick={() => handleSendEmail(params.row.id)}
              showInMenu
              icon={<Email />}
            />,)
            if(onEdit !== undefined){
              gridActions.push(
                <GridActionsCellItem
                  label="Edit"
                  onClick={() => onEdit(params.row.id, meetingType)}
                  showInMenu
                  icon={<EditIcon />}
                />
              );
            }
          }
          if (checkDeleteAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                onClick={handleDeleteClick(params.row.id)}
                showInMenu
                icon={<DeleteIcon />}
              />
            );
          }
          return gridActions;
        },
      }
    );
  };

  // Action and status columns for Employee
  const handleEditViewActionPermissionForEmployee = () => {
    columns.push(
      {
        field: "status",
        headerName: "Status",
        flex: 1.2,
        sortable: true,
        renderCell(params) {
          if (params.row.status === "Pending from Colleague") {
            const className = params.row.status
              .toLowerCase()
              .replace(/\s+/g, "-");
            return (
              <span className={`employee-meeting-status-${className}`}>
                Pending
              </span>
            );
          } else if (params.row.status === "Done") {
            return <span className={`employee-meeting-status-done`}>Done</span>;
          } else {
            return (
              <span className={`employee-meeting-status-pending-meeting`}>
                Pending Meeting
              </span>
            );
          }
        },
      },      
    );

    if(meetingType === 'one_on_one'){
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 0.8,
        cellClassName: "actions",
        getActions: (params) => {
          const gridActions = [
            <GridActionsCellItem
              label="Notes"
              onClick={() => {
                if (
                  params.row.status_key === "pending_from_colleague" &&
                  onOpenMeetingNotes !== undefined
                ) {
                  return onOpenMeetingNotes(params.row.id, meetingType);
                } else {
                  return onView(params.row.id);
                }
              }}
              icon={<Message />}
              className={`${
                params.row.status_key === "pending_from_colleague"
                  ? "notes-pending-from-colleague"
                  : "notes"
              }`}
            />,
          ];

          return gridActions;
        },
      })
    }
  };

  // Action and status columns for Reporting manager
  const handleEditViewActionPermissionForReportingManager = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.MY_TEAM
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.MY_TEAM
    );

    columns.push(
      {
        field: "status",
        headerName: "Status",
        flex: 1.2,
        sortable: true,
        renderCell(params) {
          if (params.row.status) {
            const className = params.row.status
              .toLowerCase()
              .replace(/\s+/g, "-");
            return (
              <span className={`meeting-status-${className}`}>
                {params.row.status}
              </span>
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 0.8,
        cellClassName: "actions",
        getActions: (params) => {
          const gridActions = [];
          if(meetingType === "one_on_one"){
            gridActions.push(<GridActionsCellItem
              label="Notes"
              onClick={() => {
                if (
                  params.row.status_key === "pending_from_manager" &&
                  onOpenMeetingNotes !== undefined
                ) {
                  return onOpenMeetingNotes(params.row.id, meetingType);
                } else {
                  return onView(params.row.id);
                }
              }}
              icon={<Message />}
              className={`${
                params.row.status_key === "pending_from_colleague"
                  ? "notes-pending-from-colleague"
                  : "notes"
              }`}
            />)
          }   

          if(checkEditAbilityCondition){
            gridActions.push( <GridActionsCellItem
              label="Send Email"
              onClick={() => handleSendEmail(params.row.id)}
              showInMenu
              icon={<Email />}
            />,)
          }
          

          // If meeting created by manager himself, he can edit and delete that too
          if (
            checkEditAbilityCondition &&
            params.row.created_by === loggedInID &&
            onEdit !== undefined
          ) {
            gridActions.push(
              <GridActionsCellItem
                label="Edit"
                onClick={() => onEdit(params.row.id, meetingType, params.row.employee)}
                showInMenu
                icon={<EditIcon />}
              />
            );
          }
          if (
            checkDeleteAbilityCondition &&
            params.row.created_by === loggedInID
          ) {
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                onClick={handleDeleteClick(params.row.id)}
                showInMenu
                icon={<DeleteIcon />}
              />
            );
          }
          return gridActions;
        },
      }
    );
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [
    year,
    page,
    paginationModel,
    isMutation[meetingType as keyof meetingTypes],
    isLocalMutation,
    search,
    sortColumn,
    sortDirection,
  ]);

  /**
   * Based on source, we need to render action and status columns
   */
  if (source === MEETING_SOURCE_PAGES.employee) {
    reportingManagerColumn();
    handleEditViewActionPermissionForEmployee();
  } else if (source === MEETING_SOURCE_PAGES.reporting_manager || source === MEETING_SOURCE_PAGES.reporting_manager_team) {
    if(source === MEETING_SOURCE_PAGES.reporting_manager_team){
      employeeColumn();
    }
    handleEditViewActionPermissionForReportingManager();
  } else {
    reportingManagerColumn();
    handleEditViewActionPermission();
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column pageContainer px-sm-1 w-100 pb-sm-3">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>
              <h1 className="fw-bold h4 my-2 info-text fs-20">
                {meetingLabel} Meetings
              </h1>
            </div>
            <div className="d-flex  align-items-start emp-year-select">
              {meetingType === "one_on_one" && (
                <FormSelect
                  name="year"
                  options={years}
                  getOptionLabel={(option: any) => option.value}
                  getOptionValue={(option: any) => option.key}
                  onChange={(name: string, value: yearType) => {
                    onChangeYear(value);
                  }}
                  value={{ key: year, value: year }}
                />
              )}

              {/** A reporting manager and HR operations permitted person can only add the meeting */}
              {(source == MEETING_SOURCE_PAGES.hr || source === MEETING_SOURCE_PAGES.reporting_manager) &&
                onAdd !== undefined &&
                (checkAbility(
                  PERMISSION_ACCESS.EDIT,
                  PERMISSION_MODULE.ALL_EMPLOYEE
                ) ||
                  checkAbility(
                    PERMISSION_ACCESS.EDIT,
                    PERMISSION_MODULE.MY_TEAM
                  )) && (
                  <Button
                    className="fw-semibold fs-14 text-white  btn btn-primary text-nowrap ms-2 py-2"
                    variant="primary"
                    type="button"
                    onClick={() => onAdd(meetingType)}
                  >
                    <AddCircleIcon className="me-2 fs-16 d-inline-block v-align-bottom " />
                    Add {meetingLabel} meeting
                  </Button>
                )}
            </div>
          </div>

          <Card className="p-0 w-100 pageContentInner">
            <div className="d-flex flex-column  rounded-0 dataGridListMain emp-table">
              <DataGrid
                rows={data}
                {...data}
                columns={columns}
                rowCount={totalRow}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                onSortModelChange={handleSortModelChange}
                loading={loading}
                sortingMode="server"
                pageSizeOptions={smallPageSizeModel}
                className="border-0 rounded-2"
              />
              <DeletePopup
                isOpen={isDeletePopupOpen}
                onClose={handleClose}
                onDelete={handleDelete}
                label="Delete Meeting"
                text="Are you sure you want to delete this meeting?"
              />
            </div>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeeMeetingsTable;
