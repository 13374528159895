import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  getEmployeeInductionList,
  getSentEmailTemplate,
  updateEmployeeInductionApproveStatus,
} from "../../../services/employeeService";
import Loader from "../../../components/common/Loader";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Print from "@mui/icons-material/Print";
import { toast } from "react-toastify";
import EmailIcon from "@mui/icons-material/Email";
import AddCircle from "../../../assets/images/add_circle.svg";
import { Card, Form } from "react-bootstrap";
import datetimeFormats, {
  pageSetting,
  pageSizeModel,
} from "../../../constants/constants";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import { checkAbility } from "../../../utility/common";
import constants from "../../../constants/constants";
import AddInduction from "./Induction/Add";
import AddEmployeeIndcutionPopup from "./Induction/Add";
import InputCheckBox from "../../../components/common/InputCheckBox";
import InputSwitchBox from "../../../components/common/InputSwitchBox";
import ViewEmployeeIndcutionPopup from "./Induction/View";
import BlockIcon from "@mui/icons-material/Block";

const EmployeeInduction: React.FC<Record<string, number>> = ({
  employeeID,
}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  // This state is used to add a special class for print action button when we are fetching signed url for PDF. Adding that class will make that button disabled when a request of getting signed url is in processing.
  const [loadingRows, setLoadingRows] = useState<GridRowId[]>([]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isNewInductionPopupOpen, setNewInductionPopupOpen] = useState(false);
  const [isViewInductionPopupOpen, setViewInductionPopupOpen] = useState(false);
  const [inductionId, setIndcutionId] = useState<GridRowId | null>(null);

  /**
   * Add New Induction click handler
   */
  const addNewInduction = () => {
    setIndcutionId(null);
    setNewInductionPopupOpen(!isNewInductionPopupOpen);
  };

    /**
   * New Induction click handler
   */
    const handleNewInductionClose = () => {
      setNewInductionPopupOpen(false);
      setViewInductionPopupOpen(false);
      setIsMutation(!isMutation);
      setIndcutionId(null);
      // navigate(`/employees/send-email/${employeeID}`);
    };

  /**
   * View sent email template click handler
   */
  const handleViewClick = (id: GridRowId) => () => {
    setIndcutionId(id);
    setViewInductionPopupOpen(!isViewInductionPopupOpen);
  };

  /**
   * Download sent email template click handler
   */
  const handleEditClick = async (id: GridRowId) => {
    setIndcutionId(id);
    setNewInductionPopupOpen(!isNewInductionPopupOpen);
  };
  const updateIsApproveStatus = (data: any) => {
  // console.log(e);
   const checkPermision = checkAbility(
    PERMISSION_ACCESS.EDIT,
    PERMISSION_MODULE.ALL_EMPLOYEE
  );
  if (checkPermision) {
    const inductionId = data.row.id;
    const formData = {
      is_approved: !data.row.is_approved,
    };
    updateEmployeeInductionApproveStatus(inductionId, employeeID, formData)
      .then((response) => {
        setIsMutation(!isMutation);
        toast(response.msg, { type: toast.TYPE.SUCCESS });
      })
      .catch((error) => {
        console.log(error)
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
        setIsMutation(!isMutation);
      })
      .finally(() => {
        setIsMutation(!isMutation);
      });
  }
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const convertDataToObjects = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      created_at: new Date(item.created_at),
    }));
  };

  /**
   * Fetch sent emails from server
   * @param {defaultParams}
   */
  const fetchData = (defaultParams: any) => {
    getEmployeeInductionList(employeeID, defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet);
        setData(convertData);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    console.log("filterModel", filterModel);

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);
  console.log(getGridSingleSelectOperators())
  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S. No.",
      sortable: false,
      filterable: false,
      hideable:false
    },
    {
      field: "name",
      headerName: "Induction Checklist",
      flex: 1.5,
      sortable: true,
      renderCell(params) {
        return (          
            params.row.name ? params.row.name : "-"
        );
      },
    },
    {
      field: "date",
      headerName: "Induction Date",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.date),
      renderCell(params) {
        const iData = new Date(params.row.date);
        return (          
            params.row.date ? (<span>
              {moment(iData).format(constants.dateFormats.slashSeparatedDate)}
            </span>) : "-"
        );
      },
    },
    {
      field: "$manager.full_name$",
      headerName: "Induction Manager",
      flex: 2,
      sortable: true,
      renderCell(params) {
        return (params.row.manager_id ? (<span title={params.row.manager.full_name}>
              {params.row.manager.full_name}
            </span>) : "-"          
        );
      },
    },
    {
      field: "is_approved",
      headerName: "Approved by HR",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      valueOptions: [{ value: false, label: 'No' },
      { value: true, label: 'Yes' }],
      renderCell(params) {
        return (
          checkAbility(
            PERMISSION_ACCESS.EDIT,
            PERMISSION_MODULE.ALL_EMPLOYEE
          ) ? (
          <InputCheckBox
            handleChange={(event: any) => {
              updateIsApproveStatus(params);
            } }
            controlId="is_approved"
            placeholder="is_approved"
            label=""
            value={params.row.is_approved ? true : false} handleBlur={undefined} errorsField={undefined} touched={undefined} /> ) : params.row.is_approved ? "Yes" : "No"
        )
      },
    },
    {
      field: "status",
      headerName: "Status",
      type:"singleSelect",
      valueOptions: [{ value: 0, label: 'Pending' },
      { value: 1, label: 'Completed' }],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span
            title={params.row.status}
            className={`py-1 px-2 rounded-1 ${
              params.row.status ? "Permanent-tag" : "Probation-tag"
            } d-inline-block fs-11`}
          >
            {params.row.status ? "Completed" : "Pending"}
          </span>
        );
      },
    }
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.ALL_EMPLOYEE
    );    

    if (checkEditAbilityCondition || checkViewAbilityCondition) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                onClick={handleViewClick(id)}
                showInMenu={false}
                className="text-primary"
                icon={<ViewIcon />}
              />
            );
          }
          if (checkEditAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Edit"
                onClick={(event) => handleEditClick(id)}
                showInMenu={false}
                icon={<EditIcon />}
                className="text-primary"
              />
            );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);
  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>
              <h1 className="fw-bold h4 my-2 info-text fs-20">Induction</h1>
            </div>
            <div>
              {checkAbility(
                PERMISSION_ACCESS.EDIT,
                PERMISSION_MODULE.ALL_EMPLOYEE
              ) && (
                <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => addNewInduction()}
                >
                  <img src={AddCircle} className="me-2 fs-15 vertical-top" />
                  Add Induction
                </Button>
              )}
            </div>
          </div>

          <Card className="p-0 w-100 pageContentInner">
            <div className="d-flex flex-column  rounded-0 dataGridListMain">
              <DataGrid
                rows={data.map((row: any, index: number) => ({
                  ...row,
                  serialNumber: index + 1,
                }))}
                {...data}
                columns={columns}
                rowCount={totalRow}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                onSortModelChange={handleSortModelChange}
                loading={loading}
                sortingMode="server"
                disableRowSelectionOnClick
                localeText={{ noRowsLabel: "No induction meetings scheduled" }}
                pageSizeOptions={pageSizeModel}
                className="border-0 rounded-2"
              />
            </div>
          </Card>
          {isNewInductionPopupOpen && (
            <AddEmployeeIndcutionPopup
              isOpen={isNewInductionPopupOpen}
              onClose={handleNewInductionClose}
              employeeID={employeeID}
              inductionId={inductionId}
            />
          )}
          {isViewInductionPopupOpen && (
            <ViewEmployeeIndcutionPopup
              isOpen={isViewInductionPopupOpen}
              onClose={handleNewInductionClose}
              employeeID={employeeID}
              inductionId={inductionId}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeeInduction;
