import { Route, Routes } from "react-router-dom";
import LogExpensesList from "./index";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import TeamExpensesList from "./team-expenses";

export const Expenses = () => {
  return (
    <Routes>
      {/* {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.FINANCE_EXPENSES) && (
      <Route path="/" element={<LogExpensesList />} />
      )} */}

    <Route path="/log-expenses" element={<LogExpensesList  />} />
    <Route path="/team-expenses" element={<TeamExpensesList  />} />

    </Routes>
  );
};
