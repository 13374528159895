import { Route, Routes } from "react-router-dom";
import Assets from "./index";
import AddAsset from "./Add";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import AllocateAsset from "./Allocate";
import { Login } from "../Auth/Login";
import ApproveRejectAsset from "./Approve";

export const Asset = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.ASSET_MANAGEMENT) && (
        <Route path="/" element={<Assets />} />
      )}
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.ASSET_MANAGEMENT) && (
        <Route path="/add-asset" element={<AddAsset />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.ASSET_MANAGEMENT) && (
        <Route path="/edit-asset/:id" element={<AddAsset />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.ASSET_MANAGEMENT) && (
        <Route path="/allocate-asset/:id" element={<AllocateAsset />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.ASSET_MANAGEMENT) && (
        <Route path="/approve-asset/:id" element={<ApproveRejectAsset />} />
      )}

      <Route path="*" element={<Login />} />
     
    </Routes>
  );
};
