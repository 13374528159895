import * as yup from "yup";
import {
  CONFIRM_PASSWORD_REQUIRED,
  EMAIL_REQUIRED,
  MAXIMUM_30_CHAR,
  MINIMUM_15_CHAR,
  MINIMUM_6_CHAR,
  PASSWORD_MUST_MATCH,
  PASSWORD_REGEX,
  PASSWORD_REQUIRED,
  VALID_EMAIL,
} from "./ValidationErrors";



/**
 * LoginSchema for Validation
 * @date 4/11/2023 - 4:57:16 PM
 *
 * @type {*}
 */

export const LoginSchema = yup.object().shape({
  email: yup.string().required(EMAIL_REQUIRED).email(VALID_EMAIL),
  password: yup.string().trim().required(PASSWORD_REQUIRED),
});

/**
 * ResetPasswordSchema for validation
 * @type {*}
 */
export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .min(6, MINIMUM_6_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z]).+$/,
      PASSWORD_REGEX
    ),
  confirmPassword: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], PASSWORD_MUST_MATCH),
});



/**
 * ForgotPasswordSchema for validation
 * @date 4/12/2023 - 10:13:56 AM
 *
 * @type {*}
 */
export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().required(EMAIL_REQUIRED).email(VALID_EMAIL),
});
