import { Route, Routes } from "react-router-dom";
import TaskList from "./index";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { Login } from "../Auth/Login";
import PreviousTask from "./PreviouseTask";
import MyPreviousTask from "./MyPreviouseTask";
import React from "react";

export const Tasks = () => {
  return (
    <Routes>
      
        <React.Fragment>
          <Route path="/" element={<TaskList />} />
          <Route path="/previous-task" element={<PreviousTask />} />
          <Route path="/my-previous-task" element={<MyPreviousTask />} />
        </React.Fragment>
      

      <Route path="*" element={<Login />} />
    </Routes>
  );
};
