import { axios } from "./axios";
import { DASHBOARD_MASTER } from "../constants/paths";
interface DateRangeInterface {
  orderDateRange: {
    dateFrom: string;
    dateTo: string;
  };
}
//get dashboard chart count api call
const getDashboardChartCount = (orderDateRange: DateRangeInterface['orderDateRange'], signal: any): Promise<any> => {
  return axios.get(`${DASHBOARD_MASTER}/chart-counts`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal : signal
  });
}; 


//get project stage count
const getProjectStageCount = (orderDateRange: DateRangeInterface['orderDateRange'], signal:any): Promise<any> => {
  return axios.get(`${DASHBOARD_MASTER}/projects-stage-count`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
};

//get installationCount
const getInstallationCount =  (orderDateRange: DateRangeInterface['orderDateRange'],signal:any): Promise<any> => {
  return axios.get(`${DASHBOARD_MASTER}/installation-count`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
};

 

//get installation chart data
const getInstallationChartData = (signal:any,orderDateRange: DateRangeInterface['orderDateRange'],): Promise<any> => {
  return axios.get(`${DASHBOARD_MASTER}/installation-chart-data`,{
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
}; 
const getTopInstallerData = (): Promise<any> => {
  return axios.get(`${DASHBOARD_MASTER}/top-installer-user`);
};  
//get purchase order chard data
const getPurchaseOrderChartData = (orderDateRange: DateRangeInterface['orderDateRange'], signal: any): Promise<any> => {
  return axios.get(`${DASHBOARD_MASTER}/purchase-order-chart`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal:signal
  });
}; 

//get purchase order delayed data
const getPurchaseOrderDelayedData =(orderDateRange: DateRangeInterface['orderDateRange'], signal:any): Promise<any> => {
    return axios.get(`${DASHBOARD_MASTER}/purchase-order-delayed-list`, {
      params: {
        dateFrom: orderDateRange.dateFrom,
        dateTo: orderDateRange.dateTo,
      },
      signal: signal
    });
  }; 

//get getInvoiceChartData
const getInvoiceChartData =(orderDateRange: DateRangeInterface['orderDateRange'], signal:any): Promise<any> => {
  return axios.get(`${DASHBOARD_MASTER}/invoice-data-count`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
}; 

//get ticket management data
const getTicketManagementData = (orderDateRange: DateRangeInterface['orderDateRange'],signal: any): Promise<any> => {
  return axios.get(`${DASHBOARD_MASTER}/ticket-management-data-count`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });
}; 
//get service warranty data
const getServiceWarrantyData =(orderDateRange: DateRangeInterface['orderDateRange'], signal: any): Promise<any> => { 
  return axios.get(`${DASHBOARD_MASTER}/service-warranty-data-count`, {
    params: {
      dateFrom: orderDateRange.dateFrom,
      dateTo: orderDateRange.dateTo,
    },
    signal: signal
  });

}; 
export {
  getDashboardChartCount,
  getProjectStageCount,
  getInstallationCount,
  getInstallationChartData,
  getTopInstallerData,
  getPurchaseOrderChartData,
  getPurchaseOrderDelayedData,
  getInvoiceChartData,
  getTicketManagementData,
  getServiceWarrantyData
};
