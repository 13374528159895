import { Card, Image } from "react-bootstrap";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import { getProjectDetails } from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ProjectOrderRecievedDetails from "./OrderRecevied";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ProjectInstallMediaFilesPopup from "./AdditionalMediaFiles";
import DayProcess from "./DayProcess";
import InterimPaymentCheck from "./InterimPayment";
import ActiveIcon from "../../../assets/images/currentactive.svg";
import { getInstallationInterimPaymentApi } from "../../../services/installation.service";
 
export const ProjectInstallation = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [additionalFilesOpen, setAdditionalFilesOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState<any>({});
  const [projectControls, setProjectControl] = useState<any>({});
  const [mainProjectControls, setMainProjectControl] = useState<any>({});
  const [isNextEnable, setIsNextEnable] = useState<boolean>(false);
  const [isMutation, setIsMutation] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>("RECEIVED");
  const [interimPaymentData, setInterimPaymentData] = useState<any>();

  useEffect(() => {
    setLoading(true);
    fetchInterimPaymentDetails(id);
    fetchProjectDetail(id).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (projectControls?.subsection) {
      const notSubmitData = projectControls?.subsection.find((control: any) => {
        return !control.value;
      });
      const submitNextPage = notSubmitData ? false : true;
      setIsNextEnable(submitNextPage);
    }
  }, [projectControls]);

  const fetchInterimPaymentDetails = (id:any) => {
    getInstallationInterimPaymentApi(id)
      .then((response) => {
        let paymentMilestoneData = response.data;
        console.log(paymentMilestoneData);
        const paymentData = paymentMilestoneData;
        setInterimPaymentData(paymentData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const fetchProjectDetail = (id: any) => {
    return new Promise((resolve, reject) => {
      getProjectDetails(id)
        .then((response) => {
          setProjectData(response.data.project);
          getProjectControl(response.data.project);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/projects");
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
            navigate("/projects");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/projects");
          }
        })
        .finally(() => {
          setLoading(false);
          resolve(true);
        });
    });
  };

  const getProjectControl = (projectDataObj: any) => {
    const projectControls = projectDataObj?.project_page_control.find(
      (item: any) => item.key == "INSTALL"
    );
    setMainProjectControl(projectDataObj?.project_page_control);
    setProjectControl(projectControls);
  };

  const handleNextPhaseClick = () => {
    navigate(`/projects/installation/${id}`);
  };

  const handleAdditionalFiles = () => {
    setAdditionalFilesOpen(true)
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  }
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer  w-100 pb-sm-1">
          <div className="flex-grow-1  pageContent  pb-2">
            <Card className=" p-3 w-100 pageContentInner mb-3 project-shadow rounded-4">
              <Row className="align-items-center">
                <Col className="col-md-9">
                  {projectControls?.subsection &&
                    projectControls?.subsection.map(
                      (controls: any, index: any) => (
                        <div
                          className={`d-inline-block fs-14 fw-semibold me-4 ${
                            controls.key == 'PAYMENT' && (interimPaymentData?.installation_continue_status && interimPaymentData?.amount > 0) ? 'project-next' : 
                            controls.key == 'PAYMENT' && (Number(interimPaymentData?.amount) == 0) ? 'project-active' : 
                            controls.value
                              ? "project-active"
                              : "project-default"
                          }`}
                          onClick={() => handleTabChange(controls.key)}
                        >
                          {selectedTab === controls.key ? (
                            <Image
                              src={ActiveIcon}
                              alt="active"
                              className="me-2"
                            />
                          ) : (
                            <span></span>
                          )}
                          {controls.label}
                        </div>
                      )
                    )}
                </Col>
                <Col className="text-end col-md-3">
                  {isNextEnable ? (
                    <Button
                      className={`fw-semibold fs-12 fs-14 fw-bold btn btn-primary me-2 text-white`}
                      variant="primary"
                      type="button"
                      onClick={handleNextPhaseClick}
                    >
                      {projectControls?.subsection.every(
                        (control: any) => control.value
                      )
                        ? "Complete"
                        : "Next Phase"}
                      Next Phase
                      <ChevronRightIcon />
                    </Button>
                  ) : (
                    <Button
                      className={`fw-semibold fs-12 fs-14 control-label fw-bold btn btn-secondary me-2`}
                      variant="secondary"
                      type="button"
                    >
                      Next Phase
                      <ChevronRightIcon />
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
            <div className="w-100 pageContentInner">
              <Row className="align-items-center">
                <Col className="col-md-8">
                  <h1 className="fw-bold h4 mt-2 info-text mb-0 fs-24">
                    Installation Progress
                  </h1>
                </Col>
                <Col className="fw-bold text-end">
                  <Button
                    type="button"
                    className="text-decoration-none blue-text fw-semibold"
                    variant="link"
                    onClick={() => handleAdditionalFiles()}
                  >
                    <FileCopyIcon className="me-2 fs-18" />
                    Additional Files
                  </Button>
                </Col>
              </Row>
              {selectedTab === "RECEIVED" && (
                <Row className="align-items-center">
                  <Col>
                    <ProjectOrderRecievedDetails projectId={id} />
                  </Col>
                </Row>
              )}
              {selectedTab === "FIRST_INTALL" && (
                <Row>
                  <Col>
                    <DayProcess
                      selectedTab={selectedTab}
                      projectId={id}
                      type="first_day"
                      title="Installation (First Day)"
                    />
                  </Col>
                </Row>
              )}
              {selectedTab === "PAYMENT" && (
                <Row>
                  <Col>
                    <InterimPaymentCheck
                      projectId={id}
                      setMutate={() => fetchProjectDetail(id)}
                    />
                  </Col>
                </Row>
              )}
              {selectedTab === "LAST_INTALL" && (
                <Row>
                  <Col>
                    <DayProcess
                      selectedTab={selectedTab}
                      projectId={id}
                      type="last_day"
                      title="Installation (Last Day)"
                    />
                  </Col>
                </Row>
              )}
            </div>
          </div>
          {additionalFilesOpen && (
            <ProjectInstallMediaFilesPopup
              isOpen={additionalFilesOpen}
              onClose={() => setAdditionalFilesOpen(false)}
              projectId={id}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};
