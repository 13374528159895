import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getDashboardDispatchOrderChartApi } from "../../../services/warehouseService";
import React, { useEffect, useState } from "react";
import FormSelect from "../../../components/common/FormSelect";
import PieChartSkeleton from "../../../components/common/Skeleton/PieChart.skeleton";
ChartJS.register(ArcElement, Tooltip, Legend);

export const DashboardOrderReceived = () => {
  const [selectFilter, setSelectFilter] = useState<any>( { key: 6, value : "Last 6 Months"});
  const [loading, setLoading] = useState(true);
  const getDashboardPoRaised = () => {
    // setLoading(true);
   // console.log(selectFilter);
   
    getDashboardDispatchOrderChartApi()
      .then((response) => {
        //setPoRaisedData(response.data);
        convertToChartData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getDashboardPoRaised();
  }, [selectFilter]);
  // Function to convert API response to Chart.js data
  const convertToChartData = (apiResponse: any[]) => {
    const chartData = apiResponse.filter((item) => item.status_count> 0);
    const labels = chartData.map((item) => item.label);
    const data = chartData.map((item) => item.status_count);
    const dataPercentage = chartData.map((item) => item.status_percent);
    const backgroundColor = chartData.map((item) => item.color);
   /*  const backgroundColor = [
      "#4ECB71",
      "#FFA133",
      "#5299DB"
    ]; */

    setPoRaisedData({
      labels,
      datasets: [
        {
          data,
          dataPercentage,
          backgroundColor: backgroundColor.slice(0, labels.length), // Ensuring we have the right number of colors
          hoverOffset: 4,
        },
      ],
    });
  };
  const dpData = {
    labels: ["Completed", "Pending", "Delayed"],
    datasets: [
      {
        data: [0, 0, 0],
        hoverOffset: 4,
      },
    ],
  };

  const [poRaisedData, setPoRaisedData] = useState<any>(dpData);

  const options: any = {
    plugins: {
      legend: {
        position: "right" as const,
        labels: {
          boxWidth: 20,
          padding: 20,
          color: "#000",
          usePointStyle:true,
          pointStyle:'rectRounded',
          font: {
            weight: "600",
            size: "14",
            family: "Nunito Sans, sans-serif"
          },
        },

      },
      datalabels: {
        color: "#FFFFFF",
        anchor: "middle",
        align: "middle",
        borderWidth: 0,
        borderColor: "#36A2EB",
        borderRadius: 25,        
        backgroundColor: (context: { dataset: { backgroundColor: any } }) => {
          return context.dataset.backgroundColor;
        },
        font: {
          weight: "normal",
          size: "14",
        },
        formatter: (value: any,context:any) => {
         // console.log(context.dataset.dataPercentage, context.dataIndex)
          return context.dataset?.dataPercentage[context.dataIndex] && `${context.dataset?.dataPercentage[context.dataIndex]}%`;
        },
      },
    },
  };
  const ORdata = {
    labels: ['Completed', 'Pending', 'Delayed'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [30, 5, 55],
        backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
        hoverOffset: 4
      }
    ]
  };

  const generateMonthlyArray = (numberOfMonths: number): { key: number, value: string }[] => {
    const monthsArray = [];
  
    for (let i = 1; i <= numberOfMonths; i++) {
      monthsArray.push({ key: i, value: `Last ${i} month${i > 1 ? 's' : ''}` });
    }
  
    return monthsArray;
  };
  
  // Generate array for 12 months
  const monthlyArray = generateMonthlyArray(12); 


  
  return (
    <React.Fragment>
      {loading ? (
        <Card className="p-3 w-100 pageContentInner rounded-3 h-100">
          <PieChartSkeleton />
        </Card>
      ) : (
        <div className="d-flex flex-column pageContainer  w-100 pb-sm-1 h-100">
          <Card className="p-3 w-100 pageContentInner rounded-3 h-100">
            

            <Row className="align-items-center">
              <Col md={6}>
                <h5 className="fs-18 fw-bold info-text mb-0">Order Received</h5>
              </Col>
              
            </Row>

            <Doughnut data={poRaisedData} options={options} />
          </Card>
        </div>
      )
    }
    </React.Fragment>
  );
};
