import * as yup from "yup";
import {
  MINIMUM_3_CHAR,
  MAXIMUM_30_CHAR,
  MAXIMUM_500_CHAR,
  MAXIMUM_255_CHAR,
  MAXIMUM_100_CHAR,
  ALPHA_NUMERIC_ALLOW,
  CAPITAL_LETTERS,
  SELECT_ONE_OPTION,
  INVALID_DATE,
  ALPHABETS_ALLOW
} from "../../validations/ValidationErrors";
import {
  ALPHANUMERIC_WITH_SPACE,
  CAPITAL_LETTER_REGEX,
  ONLY_ALPHABET
} from "../../validations/regex";

const employeeStatement = {
  another_job: yup.string().required(SELECT_ONE_OPTION),
  other_payments: yup.string().when("another_job", {
    is: "1",
    then: (schema) => yup.string().required(SELECT_ONE_OPTION),
    otherwise: (schema) => yup.string().nullable(),
  }),
  other_payments_since_apr6: yup.string().when("other_payments", {
    is: "1",
    then: (schema) => yup.string().required(SELECT_ONE_OPTION),
    otherwise: (schema) => yup.string().nullable(),
  }),
};

const studentLoans = {
  student_loan: yup.string().required(SELECT_ONE_OPTION),
  apply_statements: yup.string().when("student_loan", {
    is: "1",
    then: (schema) => yup.string().required(SELECT_ONE_OPTION),
    otherwise: (schema) => yup.string().nullable(),
  }),
  correct_student_loan: yup.array().when("apply_statements", {
    is: "0",
    then: (schema) =>
      yup.array().min(1, SELECT_ONE_OPTION).required(SELECT_ONE_OPTION),
    otherwise: (schema) => yup.array().nullable(),
  }),
};

export const hmrcSchema = () => {
  return yup.object({
    first_name: yup
      .string()
      .trim()
      .required()
      .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("First Name"),
    last_name: yup
      .string()
      .trim()
      .required()
      .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Last Name"),
    dob: yup.date().required().typeError(INVALID_DATE).label("Date of Birth"),
    home_address: yup
      .string()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("Home Address"),
    postal_code: yup
      .string()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Postal code"),
    country_id: yup.object().required().label("Country"),
    gender: yup.string().required().label("Gender"),
    ni_number: yup
      .string()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("National Insurance Number"),
    start_date: yup
      .date()
      .required()
      .min(yup.ref('dob'),'Employment Start Date must be after Date of Birth.')
      .typeError(INVALID_DATE)
      .label("Employment Start Date"),
    employee_statement: yup.object(employeeStatement),
    student_loans: yup.object(studentLoans),
    declaration_full_name: yup
      .string()
      .trim()
      .required()
      .matches(CAPITAL_LETTER_REGEX, CAPITAL_LETTERS)
      .min(3, MINIMUM_3_CHAR)
      .max(100, MAXIMUM_100_CHAR)
      .label("Full Name"),
    declaration_date: yup
      .date()
      .required()
      .typeError(INVALID_DATE)
      .label("Date"),
    sign: yup.string().required().label("Signature"),
  });
};
