import React from "react";
import { FC } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ITypeProps } from "./surveyInterface";
import CommonNotes from "./commonNotes";
import Slider from "@mui/material/Slider";
import { getIn } from "formik";

const TypeRangeSlider: FC<ITypeProps> = ({
  field,
  index,
  fieldIndex,
  subIndex,
  templateData,
  setTemplateData,
  errors,
  values,
  setFieldValue,
  handleBlur,
  touched,
  fieldConditions
}) => {
  const evaluateCondition = (conditionValue: any,operator: string, value: any) => {
    let visibility = false;
    switch (operator) {
      case 'Equal':
        visibility = conditionValue == value;
        break;
      case 'Not Equal':
        visibility = conditionValue != value;
        break;
      case 'Greater Than':
        visibility = conditionValue > value;
        break;
      case 'Less Than':
        visibility = conditionValue < value;
        break;
      default:
        visibility = false;
    }
    return visibility;
   };
    //the method is different for text
    const changeConditions = (  value: any, field_id:number) => {
      console.log("fielddd id", field_id,value)
      if(fieldConditions.length > 0){  
          fieldConditions.map((item: any) => {
            if(item.conditions.length > 0){
               item.conditions.map((condition: any) => {
                 if(condition.field.key === field_id){
                        if(evaluateCondition(condition.value.toLowerCase(), condition.condition.value, value))
                       {
                         if(item.actions.length > 0 ){
                           item.actions.map((action: any)=>{                                                                                                         
                             let fieldSheetTextTemp = [...templateData];
                             //update the visibility of the field where fieldSheetTextTemp find the section id an sub section id and field id
                               fieldSheetTextTemp.map((section: any, index: number) => {
                                console.log("section.section_id",section.section_id)
                                console.log("item.section_id",item.section_id)
                                 if(section.section_id === item.section_id){
                                   section.subsections.map((subSection: any, subIndex: number) => {
                                     if(subSection.subsection_id === item.subsection_id){
                                       subSection.fields.map((field: any, fieldIndex: number) => {
                                         if(field.field_id === action.field.key){     
                                           console.log("field.field_id",field.field_id)
                                           console.log("action.field.key",action.field.key)                                   
                                           field.visibility = action.action.key == 'Show' ? true : false;
                                         } 
                                       })
                                     }
                                   })
                                 }
                               }
                             )
                             setTemplateData(fieldSheetTextTemp);
                             //  fieldSheetTextTemp[index].subsections[subIndex].fields.find((item: any) => item.field_id === action.field.key).visibility = action.action.key == 'Show' ? true : false;
                             //  setTemplateData(fieldSheetTextTemp); 
                          })  
                         }                                  
                       }
                       //set else with reset the visibility of the field
                       else{
                         if(item.actions.length > 0 ){
                           item.actions.map((action: any)=>{                                                                                                         
                             let fieldSheetTextTemp = [...templateData];
                             //update the visibility of the field where fieldSheetTextTemp find the section id an sub section id and field id
                               fieldSheetTextTemp.map((section: any, index: number) => {
                                 if(section.section_id === item.section_id){
                                   section.subsections.map((subSection: any, subIndex: number) => {
                                     if(subSection.subsection_id === item.subsection_id){
                                       subSection.fields.map((field: any, fieldIndex: number) => {
                                        
                                         if(field.field_id === action.field.key){                                                                                    
                                           field.visibility = false //action.action.key == 'Show' ? true : false;
                                         } 
                                       })
                                     }
                                   })
                                 }
                               }
                             )
                             setTemplateData(fieldSheetTextTemp);
                             //  fieldSheetTextTemp[index].subsections[subIndex].fields.find((item: any) => item.field_id === action.field.key).visibility = action.action.key == 'Show' ? true : false;
                             //  setTemplateData(fieldSheetTextTemp); 
                          })
                         }
                       }
                 }
               }
             )
             }
           }
         )
     }
   }

   

  const handleInputChangeRange = (
    value: number,
    index: number,
    subIndex: number,
    fieldIndex: number,
    name: string,
    setFieldValue: any
  ) => {
    setFieldValue(name, value);
    let fieldSheetTextTemp = [...templateData];
    fieldSheetTextTemp[index].subsections[subIndex].fields[fieldIndex].value =
      value;
    setTemplateData(fieldSheetTextTemp);
    changeConditions( value, field.field_id);

  };

  const getInitivalValues = (data: any) => {
    let marks: any = [];
    marks = [
      {
        value: data[0].from,
        label: `${data[0].from}`,
      },
      {
        value: data[0].to,
        label: `${data[0].to}`,
      },
    ];
    console.log("marks", marks);
    return marks;
  };

  function valuetext(value: number) {
    return `${value}`;
  }
  return (
    <div>
      <Card className="p-3 w-100 pageContentInner mb-3">
        <div className="d-flex flex-column h-100 rounded-0 ">
          <Row className="d-flex">
            <Col className="col-md-11">
              <p className="fs-14 mb-2">
                {field.title} {field.require && "*"}
              </p>
              <div className="w-100 survery-input">
                 <Slider
                  aria-label="Always visible"
                  defaultValue={
                    field.value
                      ? field.value
                      : field.default_value
                      ? field.default_value
                      : 0
                  }
                  getAriaValueText={valuetext}
                  step={1}
                  max={field.data_set[0].to}
                  min={parseInt(field.data_set[0].from)} 
                  marks={getInitivalValues(field.data_set)}
                  valueLabelDisplay="on"
                  onChange={(e: any, value: any) => {
                    handleInputChangeRange(
                      value,
                      index,
                      subIndex,
                      fieldIndex,
                      field.name,
                      setFieldValue
                    );
                  }}
                />
                {getIn(errors, field.name) && getIn(touched, field.name) ? (
                  <div className="text-danger">{getIn(errors, field.name)}</div>
                ) : null}
              </div>
            </Col>
            <Col className="col-md-1 text-end">
              <CommonNotes
                field={field}
                index={index}
                fieldIndex={fieldIndex}
                subIndex={subIndex}
                touched={touched}
                errors={errors}
                values={values}
                templateData={templateData}
                setTemplateData={setTemplateData}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default TypeRangeSlider;
