import React, { ReactNode, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import {
  EmployeeDocumentTypeExtension,
  EmployeeDocumentTypeForDropZone,
  ImageTypeForDropZone,
  allowedFileTypes,
  allowedImportFilesTypes,
  formTemplateMaxFileSizeMB,
  importMaxFileSize,
  importTypeForDropZone,
  maxFileSizeMB,
} from "../../constants/constants";
import { DOCUMENT_TYPE_ERROR, FILE_TYPE_ERROR, IMPORT_FILE_TYPE_ERROR } from "../../validations/ValidationErrors";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (formData: any) => void;
  docLoading: boolean;
  mainTitle : string;
  maxFileSize : string | number | any;
  allowFormat : string;
  children?: ReactNode;
}

const FileDropUploader: React.FC<AddProps> = ({
  isOpen,
  onClose,
  onUpload,
  docLoading,mainTitle,maxFileSize,allowFormat,
  children
}) => {
  const fileFormatArray: any = {
    document : {
      allowTypeExtension : EmployeeDocumentTypeExtension,
      allowTypeExtensionDropZone : EmployeeDocumentTypeForDropZone,
      maxFileSize : formTemplateMaxFileSizeMB,
      errorToastMessage : DOCUMENT_TYPE_ERROR
    },
    image : {
      allowTypeExtension : allowedFileTypes,
      allowTypeExtensionDropZone : ImageTypeForDropZone,
      maxFileSize : maxFileSizeMB,
      errorToastMessage : FILE_TYPE_ERROR
    },
    import : {
      allowTypeExtension : allowedImportFilesTypes,
      allowTypeExtensionDropZone : importTypeForDropZone,
      maxFileSize : importMaxFileSize,
      errorToastMessage : IMPORT_FILE_TYPE_ERROR
    }
  }
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileTypeArrayInfo, setFileTypeArrayInfo] = useState<any | null>(fileFormatArray[allowFormat]);
  // const [loading, setLoading] = useState(false);

  const handleFileChange = (files: File[]) => {
    setSelectedFile(files[0]);
  };
  
  const handleFileUpload = (event: any) => {
    const pdfFile = event[0];
   // console.log(pdfFile,fileTypeArrayInfo);
    if (
      !pdfFile?.type ||
      !fileTypeArrayInfo.allowTypeExtension.includes(pdfFile?.type)
    ) {
      toast(fileTypeArrayInfo.errorToastMessage, {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (pdfFile.size > fileTypeArrayInfo.maxFileSize * 1024 * 1024) {
      toast(`File size must be less than ${fileTypeArrayInfo.maxFileSize} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      // Pass the selected file to the parent component for upload
      onUpload(pdfFile);

      // Reset the selected file
      setSelectedFile(null);
    }
  };

  return (
    <>
      <Modal
        className="export-excel-modal"
        show={isOpen}
        onHide={onClose}
        backdrop="static"
      >
        <Modal.Body className="position-relative">
          <button
            type="button"
            disabled={docLoading}
            className="btn-close text-right  btn-option-cover"
            aria-label="Close"
            onClick={onClose}
          ></button>
          <h4 className="fw-bold info-text mb-3">{mainTitle}</h4>
          <div className="drag-drop">
            {docLoading ? (
              <label className="file-box1">
                <span>
                  <Spinner />
                </span>
              </label>
            ) : (
              <Dropzone
                multiple={false}
                accept={fileTypeArrayInfo.allowTypeExtensionDropZone}
                onDrop={(e) => handleFileUpload(e)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-image">
                      <svg
                        width="33"
                        height="25"
                        viewBox="0 0 33 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2222 24.1666H8.65975C6.44794 24.1666 4.55819 23.401 2.99048 21.8697C1.42277 20.3385 0.638916 18.4669 0.638916 16.2551C0.638916 14.3593 1.2101 12.6701 2.35246 11.1874C3.49482 9.70478 4.98961 8.75686 6.83683 8.34367C7.44447 6.10756 8.65975 4.29679 10.4827 2.91138C12.3056 1.52596 14.3716 0.833252 16.6806 0.833252C19.5243 0.833252 21.9367 1.8237 23.9176 3.80461C25.8985 5.78551 26.8889 8.19784 26.8889 11.0416C28.566 11.236 29.9575 11.9591 31.0634 13.2109C32.1693 14.4626 32.7222 15.927 32.7222 17.6041C32.7222 19.427 32.0842 20.9765 30.8082 22.2525C29.5321 23.5286 27.9827 24.1666 26.1597 24.1666H18.1389V13.7395L20.4722 15.9999L22.5139 13.9583L16.6806 8.12492L10.8472 13.9583L12.8889 15.9999L15.2222 13.7395V24.1666Z"
                          fill="#889EAE"
                        />
                      </svg>
                    </div>
                    <div className="drag-text">
                      Drag & drop the file here or
                    </div>
                    <div className="upload-text">Click here to upload file</div>
                  </div>
                )}
              </Dropzone>
            )}
          </div>
          <div className="d-flex mt-2 justify-content-between">
            <p className="file-size-text">
              File Size: {fileTypeArrayInfo.maxFileSize} MB max
            </p>
            <p className="file-size-text">
              {mainTitle} ({fileTypeArrayInfo.errorToastMessage})
            </p>
          </div>
          <div>{children}</div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
          <button
            disabled={docLoading}
            className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
            onClick={onClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default FileDropUploader;
