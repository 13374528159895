import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridFilterModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import {
    getAssetHistory
} from "../../../services/assetService";
import Loader from "../../../components/common/Loader";
import CustomTooltip from "../../../components/common/CustomTooltip";
import InfoIcon from '@mui/icons-material/Info';
import { Card } from "react-bootstrap";
import datetimeFormats, {
  pageSetting,
  pageSizeModel,
} from "../../../constants/constants";

const AssetsHistory: React.FC<Record<string, number>> = ({assetId}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const convertDataToObjects = (data:any) => {
    return data.map((item:any) => ({
      ...item,
      created_at: new Date(item.created_at),
    }));
  };

  /**
   * Fetch allocated assets from server
   * @param {defaultParams}
   */
  const fetchData = (assetId: number, defaultParams: any) => {
    getAssetHistory(assetId,defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet)
        setData(convertData);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    console.log("filterModel", filterModel);

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    { 
        field: "created_at", 
        headerName: "Status & Date", 
        flex: 3, 
        sortable: true,
        type: 'date',
        renderCell(params) {
            let action;
            //
            switch(params.row.action){
                case 'allocated': 
                    action = `Allocated to ${params.row.employee.full_name}`;
                    break;
                case 'in_inventory': 
                    action = 'Added in Inventory';
                    break;
                case 'dead_inventory': 
                    action = 'Added in Dead Inventory';
                    break;
            }
            return <span className="fs-14 fw-semibold text-222">{action} <span className="w-100 d-block control-label fw-normal">{moment(params.row.created_at).format(datetimeFormats.dateFormats.slashSeparatedDate)}</span></span>
        }
    },
    {
        field: "$actionBy.full_name$",
        headerName: "Verified By",
        flex: 2,
        sortable: true,
        renderCell(params) {
          return  <span title={params.row.actionBy.full_name}>{params.row.actionBy.full_name}</span>;
        },
    },
    {
        field: "note",
        headerName: "Note",
        flex: 1,
        sortable: false,
        renderCell(params) {
            return (params.row.note ? <CustomTooltip 
                maxWidth="300" fontSize= "13px" backgroundColor="white" color="#222" fontWeight="500" border="1px solid #ddd"
                tooltipIconSize = "24px"
                disableFocusListener 
                title={params.row.note}
                placement="top" arrow>
            <InfoIcon className='note-infoicon primary-text' />
          </CustomTooltip> : <InfoIcon className='note-infoicon primary-text' />);
        }
    }
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []); 
    const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(assetId, defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <h1 className="fw-bold h4 my-2 info-text fs-20">Allocation History</h1>
              </div>
            </div>
         
            <Card className="p-0 w-100 pageContentInner border-0">
              <div className="d-flex flex-column  rounded-0 dataGridListMain allocation-table">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                />
              </div>
            </Card>
          
        </div>
      )}
    </React.Fragment>
  );
};

export default AssetsHistory;
