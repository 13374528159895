import { useEffect, useState } from 'react'
import { Footer } from './Footer/Footer'
import { Header } from './Header/Header'
import { Sidebar } from './Sidebar/Sidebar'
import { Outlet } from "react-router";
import { Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; 
export const MainLayout = () => {
  const [isActiveSidebar, setIsActiveSidebar] = useState(false);
  const toggleSidebarButton = (event: any) => {
    setIsActiveSidebar(current => !current);
  };
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
      console.log("location",path)
      if(path=='/survey/start-survey' || path=='/planner'){
        setIsActiveSidebar(true)
      }else{
        setIsActiveSidebar(false)
      }
  }, [path]);
  return (
    <main className="mainContentBox">
      <div className="vh-100 d-flex position-relative overflow-hidden">
        <Sidebar isActiveSidebar={isActiveSidebar} />
        <Col xs className="mw-100 h-100 mw-1">
          {isActiveSidebar ?
            <div onClick={toggleSidebarButton} className="backdrop bg-black bg-opacity-25 bottom-0 position-fixed start-0 w-100"></div>
            : null
          }
          <div className="d-flex flex-column h-100 w-100">
            <Header isActiveSidebar={isActiveSidebar} toggleSidebarButton={toggleSidebarButton} />
            <div className="flex-grow-1 fs-13 overflow-hidden w-100">
              <Outlet />
            </div>
            
          </div>
        </Col>
      </div>
    </main>
  )
}
