import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import moment from "moment";
import constants, {
  expensesCategories,
  expensesStatus,
} from "../../constants/constants";
import { getExpenseByIdApi, getExpensesCommentsApi, saveExpensesCommentsApi } from "../../services/expenseService";
import { currencyMask } from "../../utility/common";
import { Link } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import { Formik } from "formik";
import * as yup from "yup";
import InputText from "../../components/common/InputText";
import CustomButton from "../../components/common/CustomButton";
import { Scrollbar } from 'react-scrollbars-custom';
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../features/Auth/authSlice";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  expensesId: string | number | null | undefined;
  viewType: string;
}

interface FormValues {
  message: string;
}

const addSchema = yup.object({
  message: yup.string().required().label("Comment"),
});

const ViewExpenseQueryPopup: React.FC<AddProps> = ({
  isOpen,
  onClose,
  expensesId,
  viewType,
}) => {
  const [loading, setLoading] = useState(true);
  const [expenseData, setExpenseData] = useState<any>({});
  const [commentData, setCommentData] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const {user }:any = useSelector(selectAuthentication);

  const  userId = user.user.id;

  const getExpensesComment = async () => {
    return new Promise((resolve, reject) => {  
      if(expensesId){
        getExpensesCommentsApi(expensesId).then((response) => {   
          
          setCommentData(response.data.queryData); 
          setLoading(false);          
          return resolve(true);
        })
        .catch(() => {
          setLoading(false);
        });
      }    
    });
  };
  useEffect(() => {
    if(expensesId){
      fetchData();
      getExpensesComment();
    }   
  }, []);

  const submitForm = async (values: FormValues, { setSubmitting, resetForm }: any) => {
    // console.log(values);
    // return;
    // const projectIds = values?.projectsData.map((item:any) => item.id)
    // setBtnLoading(true);
    const formData: any = {
      ...values,
    };
    // console.log(projectIds,formData)
    // return;

     saveExpensesCommentsApi(expensesId,formData).then((response)=>{
     // toast(response.msg, { type: toast.TYPE.SUCCESS });
      resetForm();
      getExpensesComment();      

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

  const fetchData = () => {
    if (expensesId) {
      setLoading(true);
      getExpenseByIdApi(expensesId, viewType)
        .then((response) => {
          const expData = response?.data;
          expData.categoryData = expensesCategories.find(
            (item: { key: string | number | undefined }) =>
              item.key == expData?.category
          );
          expData.statusData = expensesStatus.find(
            (item: { key: string | number | undefined }) =>
              item.key == expData?.status
          );
          console.log(expData);
          setExpenseData(response?.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const initialValues = {
    message: "",
  };

  const commentsContainerRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollToBottom();
    }
  };
  useEffect(() => {
    // Scroll to the last comment on load
    if (commentsContainerRef.current) {
      scrollToBottom();
    }
  }, [expenseData]);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="modal-middle-size"
          show={isOpen}
          onHide={onClose}
          centered
        >
          <Modal.Body className="position-relative">
            <button
              type="button"
              className="btn-close text-right  btn-option-cover"
              aria-label="Close"
              onClick={onClose}
            ></button>
            <h4 className="fw-bold info-text mb-3">Ask Query</h4>
            <Row>
              <Col className="col-md-4">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Expense Title {" "}
                  </label>
                  <p className="fs-14">{expenseData?.title}</p>
                </div>
              </Col>
              <Col className="col-md-4">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Date {" "}
                  </label>
                  <p className="fs-14">
                    {expenseData?.date
                      ? moment(expenseData?.date).format(
                          constants.dateFormats.slashSeparatedDate
                        )
                      : "-"}
                  </p>
                </div>
              </Col>
              <Col className="col-md-4">
                <div className="mb-3 custom-from-group">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Amount {" "}
                  </label>
                  <p className="fs-14">{currencyMask(expenseData?.amount)}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="flex-grow-1 pageContent p-0 rounded-2">
                  <Scrollbar
                    ref={commentsContainerRef}
                    className="ticket-comment-scroll  task-query-box"
                  >
                    <div className="p-3">                      
                      {(commentData).map((ticketObj: any, index: any) => (
                          <Row key={`ticket_data${index}`}>                            
                            <Col className={`col-12 ${ticketObj?.user?.id === userId ? 'text-end' : "" }`}>                             
                            <div className="control-label fs-12 fw-semibold">{ ticketObj?.user?.id !== userId && ticketObj?.user?.full_name} {" "} { moment(ticketObj?.createdAt).format(`${constants.datetimeFormats.hyphenSeparatedDateTime}`)}</div>
                            <div className={`p-2 card d-inline-flex p-2 text-start w-50 fs-14 mb-2 ${ticketObj?.user?.id !== userId ? "bg-primary-opacity text-black" : ""}`}>{ticketObj?.message}</div>
                            </Col>
                          </Row>

                      ))}   
                    </div>
                  </Scrollbar>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
            <Row className="w-100">
              <Col className="">
                <Formik
                  validationSchema={addSchema}
                  initialValues={initialValues}
                  onSubmit={(values: FormValues, actions) => {
                    submitForm(values, actions);
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    setFieldValue,
                    touched,
                    isValid,
                    errors,
                  }) => (
                    <Form className="query-comment-bottom" noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col className="col-md-12 ps-0">
                          <InputText
                            controlId="message"
                            label=""
                            placeholder="Enter comment"
                            touched={touched.message}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.message}
                            value={values.message}
                          />
                        </Col>
                        <Col className="col-auto">
                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            className="fw-semibold fs-13 text-white w-40 h-40"
                            variant="primary"
                          >
                            <SendIcon className="fs-16" />
                          </CustomButton>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default ViewExpenseQueryPopup;
