import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import CustomButton from "../../../../components/common/CustomButton";
import { getEmployeeInductionDetail, saveEmployeeInduction, updateEmployeeInduction } from "../../../../services/employeeService";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import InputText from "../../../../components/common/InputText";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import InputTextArea from "../../../../components/common/InputTextArea";
import {InductionValidationSchema} from "./AddValidation";
import moment from "moment";
import constants, { formTemplateAllowedFileTypes, formTemplateMaxFileSizeMB, pdfFileTypes } from "../../../../constants/constants";
import GeneralFileUploader from "../../../../components/common/GeneralFileUploader";
import { FORM_TEMPLATE_SIZE_ERROR, FORM_TEMPLATE_TYPE_ERROR, PDF_TYPE_ERROR } from "../../../../validations/ValidationErrors";
import FormSelect from "../../../../components/common/FormSelect";
import { getAllUsersApiCall } from "../../../../services/authService";
import InputCheckBox from "../../../../components/common/InputCheckBox";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  employeeID: number;
  inductionId?: string |number | null | undefined;
}
interface ProjectOption {
  id:string;
  full_name:string;
}


const AddEmployeeIndcutionPopup: React.FC<AddProps> = ({ isOpen, onClose, employeeID, inductionId }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [documentRequestData, setDocumentRequestData] = useState<any>({}); 
  const [managerData, setManagerData] = useState<any>({});
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(()=>{
    fetchData()
   },[])
 
   const fetchData = () =>{
    getAllUsersApiCall().then((response)=>{
      const  modifiyOption = response.data.map((item : ProjectOption) => ({ key: item.id, value: item.full_name }));
      setManagerData(modifiyOption);
      if(inductionId){
        setLoading(true)
        getEmployeeInductionDetail(inductionId,employeeID).then((response)=>{
          setDocumentRequestData(response?.data);
          setLoading(false)  
        }).catch((error)=>{
          setLoading(false)
         // onClose()
    
        })
      }
      else{
        setLoading(false)
      }

    }).catch((error)=>{
      setLoading(false)

    })
    
   }

   const initialValues = {    
    name: documentRequestData?.name ? documentRequestData?.name : "",
    date: documentRequestData?.date ? moment(documentRequestData?.date) : null,
    manager_id: documentRequestData?.manager_id ? managerData.find((item:any) => item.key == documentRequestData?.manager_id)
    : null,
    remarks: documentRequestData?.remarks ? documentRequestData?.remarks : "",
    complete_status: documentRequestData?.status ? true : false,
    filename: documentRequestData?.document ? documentRequestData?.document : "",
     file:"",
     fileurl: documentRequestData?.fileurl ? documentRequestData?.fileurl: null
  };
  /**
   * Submit Method to call when user save button
   * @async
   * @param  values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
   
    setBtnLoading(true);
    const formData: any = {
      ...values,
      date: moment(values.date).isValid()
        ? moment(values.date).format(
            constants.dateFormats.databaseFormat
          )
        : "",
    };

    if(typeof values.file === 'string'){
      delete formData.file;
    }

    delete formData.filename;
    delete formData.fileurl;
    

    
    const saveOrUpdateDocumentSendToHr = () => {
      // Provide the appropriate API request function
      return inductionId
        ? updateEmployeeInduction(inductionId, employeeID, formData)
        : saveEmployeeInduction(employeeID, formData);
    };
 
    saveOrUpdateDocumentSendToHr().then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose()

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

   /**
   * Upload form template PDF file
   * @param {events, setFieldValue}
   * @returns {*}
   */
   const uploadFile = (event: any, setFieldValue: any) => {
    const file = event.target.files[0];
    if(file){
      // Check file type
      if (!pdfFileTypes.includes(file.type)) {
        toast(PDF_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (file.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
        toast(FORM_TEMPLATE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        setFieldValue('file', file);
        setFieldValue('filename',file.name);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal" show={isOpen} onHide={onClose} centered>
          <Formik
            validationSchema={InductionValidationSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Schedule Induction Meeting
                  </h4> 
                  <Row>
                    <Col>
                    <InputText
                      controlId="name"
                      label="Induction Process Name"                      
                      placeholder="Enter Induction Process Name"
                      touched={touched.name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.name}
                      value={values.name}
                    />
                    </Col>                    
                    <Col>
                    <InputDatePicker
                            name="date"
                            label="Induction Date"
                            slotProps={{
                              textField: {
                                size: "small",
                                placeholder: "Select",
                                className: "form-control",
                                readOnly: true,
                                id: "date",
                              },
                            }}
                            value={values.date}
                            onChange={(name, newValue) =>
                              setFieldValue(name, newValue)
                            }
                            touched={touched.date}
                            errors={errors.date}
                          /> 
                    </Col>                    
                  </Row>       
                  <Row>
                    <Col className="col-md-6">
                    <FormSelect
                        placeholder="Select"
                        label="Manager"
                        name="manager_id"
                        options={managerData}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.key}
                        onChange={(name: string, value: string) => {
                          setFieldValue(name, value);
                        }}
                        value={values.manager_id}
                        error={errors.manager_id}
                        touched={touched.manager_id}
                      />
                    </Col>   
                    <Col md={6}>
                    
                          <GeneralFileUploader
                            uploadFile={(e) =>
                              uploadFile(e, setFieldValue)
                            }
                            imageLoading={imageLoading}
                            label="Upload Document"
                            filename={values.filename}
                            downloadExisting={true}
                            fileurl={values.fileurl}
                          />
                          
                       
                    </Col>                
                  </Row>                  
                  <Row>
                    <Col>
                    <InputTextArea
                        controlId="remarks"
                        label="Description"
                        placeholder="Enter Description"
                        touched={touched.remarks}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.remarks}
                        value={values.remarks}
                      />
                    </Col>                   
                  </Row>
                  { inductionId && (
                  <Row>
                    <Col>
                    <InputCheckBox
                                controlId="complete_status"
                                label="Mark as Complete"
                                className="fs-14"
                                touched={touched.complete_status}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.complete_status}
                                value={values.complete_status}
                              />
                    </Col>                   
                  </Row>
                  )}
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    disabled={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Schedule
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default AddEmployeeIndcutionPopup;