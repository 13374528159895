import { FieldArray, Formik, getIn } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import Actions from "./action";
import { addFieldConditionApi, getAllFieldByFieldSheetId } from "../../../services/formbuilder/field.services";
import Loader from "../../../components/common/Loader";
import Conditions from "./condition";
import { actionValidationSchema } from "./validation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FORMBUILDER_FINISH } from "../../../validations/ValidationErrors";
import plusIcon from "../../../assets/images/formbuilderAdd.svg";
import deleteIcon from "../../../assets/images/formbuilderdelete.svg";

const Condition = ({ fieldsheetId ,fieldConditionsData,handleBackToField}) => {
  const [fieldData, setFieldData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFieldData();
  }, []);
  const fetchFieldData = () => {
    getAllFieldByFieldSheetId(fieldsheetId)
      .then((response) => {
        const manipulatedArr = response.data.map((item) => {
          return {
            value: `${item.title} (${item.subsection_title})`, //item.field_id,
            key: item.field_id, 
            subsection_id : item.subsection_id,
            section_id : item.section_id
          };
        });
        setFieldData(manipulatedArr);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleSubmit = (values) => {   
    const transformData = {
      field_sheet_id :  fieldsheetId,
      field_conditions : values.field_conditions
    }
    addFieldConditionApi(fieldsheetId, transformData)
        .then((response) => {
          toast.success(FORMBUILDER_FINISH);
          navigate("/formbuilder");       
        })
        .catch((error) => {
          toast.error(error.response.data.message); }); 
  };

  return (
    <div>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Formik
          initialValues={{
            field_conditions: fieldConditionsData ? fieldConditionsData.field_conditions : [
              {
                conditions: [{ field: "", condition: "", value: "" }],
                actions: [{ field: "", action: "" }],
              },
            ],
          }}
          validationSchema={actionValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            setFieldError,
          }) => (
            <Form className="" noValidate onSubmit={handleSubmit}>
              <FieldArray
                name="field_conditions"
                render={(arrayHelpers) => (
                  <div>
                    {values.field_conditions &&
                    values.field_conditions.length > 0 ? (
                      values.field_conditions.map((friend, index) => (
                        <div key={index}>
                          {index === 0 && (
                             <Row className="align-items-center mb-3">
                             <div className="col-md-6">
                                       <div className="">
                                         <div className="fs-20 fw-bold info-text">
                                         Field Conditions
                                         </div>
                                       </div>
                                     </div>
                                     <div className="col-md-6 text-end">
                                     <button
                                             className="createSectionBtn bg-transparent fs-14 fw-bold border-0"
                                             onClick={() =>
                                 
                                              arrayHelpers.push({ 
                                                conditions: [{ field: "", condition: "", value: "" }],
                                                actions: [{ field: "", action: "" }],
                                              })  
                                            }
                                           >
                                             <img src={plusIcon} className="plus-icon me-2 v-text-bottom" />
                                            Add Section
                                           </button>
                                     </div>
                                     </Row>

                          
                          )}
                          <div className="card innerCard p-0 mb-3  border-0">
                            <div className="card-body">
                              <Row>
                                <div className="col-md-6">
                                <div className="fs-20 fw-bold info-text mb-3">
                                         Action
                                         </div>
                                </div>
                                <div className="col-md-6 text-end">
                                <div
                                className="d-flex justify-content-end bg-transparent fs-14 fw-bold border-0 cursor-pointer"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              >
                                <img src={deleteIcon} className="cursor-pointer plus-icon me-2 v-text-bottom" />
                                Delete Condition
                              </div>
                                  </div>
                              </Row>
                             

                              <Row className="align-items-center ">
                             
                                <Actions
                                  fieldData={fieldData}
                                  keyIndex={index}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />


<div className="fs-20 fw-bold info-text mb-3">
                                         Conditions
                                         </div>
                                <Conditions
                                  fieldData={fieldData}
                                  keyIndex={index}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />
                              </Row>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                    
                       

                        <Row className="align-items-center mb-3">
                             <div className="col-md-6">
                                       <div className="">
                                         <div className="fs-20 fw-bold info-text">
                                         Field Conditions
                                         </div>
                                       </div>
                                     </div>
                                     <div className="col-md-6 text-end">
                                     <button
                                             className="createSectionBtn bg-transparent fs-14 fw-bold border-0"
                                             onClick={() => arrayHelpers.insert(0, {
                                              conditions: [{ field: "", condition: "", value: "" }],
                                              actions: [{ field: "", action: "" }],
                                            })}
                                           >
                                             <img src={plusIcon} className="plus-icon me-2 v-text-bottom" />
                                            Add Section
                                           </button>
                                     </div>
                                     </Row>

                     
                    )}
                                   <div className="col-md-12 mb-5 pt-3 d-flex justify-content-between">
                 <button
                   type="submit"
                   onClick={handleBackToField}
                   className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info "
                 >
                   Back
                 </button>
                 <button type="submit" className="fw-semibold fs-13 text-white mw-90 mt-2 btn btn-primary" >Finish</button>

              
               </div>

                    
                  </div>
                )}
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
export default Condition;
