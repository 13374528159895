import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FieldArray, Formik, getIn } from "formik";
import { toast } from "react-toastify";
import InputText from "../../../components/common/InputText";
import { Card, Col, Row } from "react-bootstrap";
import InputTextArea from "../../../components/common/InputTextArea";
import { getRolesByIdApi } from "../../../services/roleService";
import InputCheckBox from "../../../components/common/InputCheckBox";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import EditCircle from '../../../assets/images/profile-edit-icon.svg';
import EditIcon from '@mui/icons-material/Edit';
type TPermission = {
  module_id: number;
  title: string;
  display_name: string;
  permission: {
    title: string;
    permission_id: number;
   
  }[];
};

type FormValues = {
  role: string;
  permission: string[] | any;
  description: string;
};

export default function ViewRole() {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [permissionData, setPermissionData] = useState<TPermission[]>([]);
  const [roleData, setRoleData] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams();

  /**
   * Get roles-permission details from server
   */
  const getRolesetails = async (id: string) => {
    setLoading(true);
    await getRolesByIdApi(id)
      .then((response: any) => {
        setRoleData(response.data);
        setPermissionData(response.data.permission);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);

        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };

  const getPermissionIcon = (title: string) => {
    switch (title) {
      case "Add":
        {
          return (
            <svg
              className="me-1"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.33331 10.833H7.66665V8.16634H10.3333V6.83301H7.66665V4.16634H6.33331V6.83301H3.66665V8.16634H6.33331V10.833ZM6.99998 14.1663C6.07776 14.1663 5.21109 13.9913 4.39998 13.6413C3.58887 13.2913 2.88331 12.8163 2.28331 12.2163C1.68331 11.6163 1.20831 10.9108 0.858313 10.0997C0.508313 9.28856 0.333313 8.4219 0.333313 7.49967C0.333313 6.57745 0.508313 5.71079 0.858313 4.89967C1.20831 4.08856 1.68331 3.38301 2.28331 2.78301C2.88331 2.18301 3.58887 1.70801 4.39998 1.35801C5.21109 1.00801 6.07776 0.833008 6.99998 0.833008C7.9222 0.833008 8.78887 1.00801 9.59998 1.35801C10.4111 1.70801 11.1166 2.18301 11.7166 2.78301C12.3166 3.38301 12.7916 4.08856 13.1416 4.89967C13.4916 5.71079 13.6666 6.57745 13.6666 7.49967C13.6666 8.4219 13.4916 9.28856 13.1416 10.0997C12.7916 10.9108 12.3166 11.6163 11.7166 12.2163C11.1166 12.8163 10.4111 13.2913 9.59998 13.6413C8.78887 13.9913 7.9222 14.1663 6.99998 14.1663Z"
                fill="#21AC96"
              />
            </svg>
          );
        }
        break;
      case "Edit":
        {
          return (
            <svg
              className="me-1"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_405_7980"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="17"
              >
                <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_405_7980)">
                <path
                  d="M2 14.5V11.6667L10.8 2.88333C10.9333 2.76111 11.0806 2.66667 11.2417 2.6C11.4028 2.53333 11.5722 2.5 11.75 2.5C11.9278 2.5 12.1 2.53333 12.2667 2.6C12.4333 2.66667 12.5778 2.76667 12.7 2.9L13.6167 3.83333C13.75 3.95556 13.8472 4.1 13.9083 4.26667C13.9694 4.43333 14 4.6 14 4.76667C14 4.94444 13.9694 5.11389 13.9083 5.275C13.8472 5.43611 13.75 5.58333 13.6167 5.71667L4.83333 14.5H2ZM11.7333 5.7L12.6667 4.76667L11.7333 3.83333L10.8 4.76667L11.7333 5.7Z"
                  fill="#21AC96"
                />
              </g>
            </svg>
          );
        }
        break;

      case "Delete":
        {
          return (
            <svg
              className="me-1"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.66669 12.5C2.30002 12.5 1.98613 12.3694 1.72502 12.1083C1.46391 11.8472 1.33335 11.5333 1.33335 11.1667V2.5H0.666687V1.16667H4.00002V0.5H8.00002V1.16667H11.3334V2.5H10.6667V11.1667C10.6667 11.5333 10.5361 11.8472 10.275 12.1083C10.0139 12.3694 9.70002 12.5 9.33335 12.5H2.66669ZM4.00002 9.83333H5.33335V3.83333H4.00002V9.83333ZM6.66669 9.83333H8.00002V3.83333H6.66669V9.83333Z"
                fill="#EE3030"
              />
            </svg>
          );
        }
        break;
      case "View":
        {
          return (
            <svg
              className="me-1"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_405_7975"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="17"
              >
                <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_405_7975)">
                <path
                  d="M8.00002 11.167C8.83335 11.167 9.54169 10.8753 10.125 10.292C10.7084 9.70866 11 9.00033 11 8.16699C11 7.33366 10.7084 6.62533 10.125 6.04199C9.54169 5.45866 8.83335 5.16699 8.00002 5.16699C7.16669 5.16699 6.45835 5.45866 5.87502 6.04199C5.29169 6.62533 5.00002 7.33366 5.00002 8.16699C5.00002 9.00033 5.29169 9.70866 5.87502 10.292C6.45835 10.8753 7.16669 11.167 8.00002 11.167ZM8.00002 9.96699C7.50002 9.96699 7.07502 9.79199 6.72502 9.44199C6.37502 9.09199 6.20002 8.66699 6.20002 8.16699C6.20002 7.66699 6.37502 7.24199 6.72502 6.89199C7.07502 6.54199 7.50002 6.36699 8.00002 6.36699C8.50002 6.36699 8.92502 6.54199 9.27502 6.89199C9.62502 7.24199 9.80002 7.66699 9.80002 8.16699C9.80002 8.66699 9.62502 9.09199 9.27502 9.44199C8.92502 9.79199 8.50002 9.96699 8.00002 9.96699ZM8.00002 13.167C6.3778 13.167 4.90002 12.7142 3.56669 11.8087C2.23335 10.9031 1.26669 9.68921 0.666687 8.16699C1.26669 6.64477 2.23335 5.43088 3.56669 4.52533C4.90002 3.61977 6.3778 3.16699 8.00002 3.16699C9.62224 3.16699 11.1 3.61977 12.4334 4.52533C13.7667 5.43088 14.7334 6.64477 15.3334 8.16699C14.7334 9.68921 13.7667 10.9031 12.4334 11.8087C11.1 12.7142 9.62224 13.167 8.00002 13.167Z"
                  fill="#21AC96"
                />
              </g>
            </svg>
          );
        }
        break;
      case "List":
        {
          return (
            <svg
              className="me-1"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_405_7975"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="17"
              >
                <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_405_7975)">
                <path
                  d="M8.00002 11.167C8.83335 11.167 9.54169 10.8753 10.125 10.292C10.7084 9.70866 11 9.00033 11 8.16699C11 7.33366 10.7084 6.62533 10.125 6.04199C9.54169 5.45866 8.83335 5.16699 8.00002 5.16699C7.16669 5.16699 6.45835 5.45866 5.87502 6.04199C5.29169 6.62533 5.00002 7.33366 5.00002 8.16699C5.00002 9.00033 5.29169 9.70866 5.87502 10.292C6.45835 10.8753 7.16669 11.167 8.00002 11.167ZM8.00002 9.96699C7.50002 9.96699 7.07502 9.79199 6.72502 9.44199C6.37502 9.09199 6.20002 8.66699 6.20002 8.16699C6.20002 7.66699 6.37502 7.24199 6.72502 6.89199C7.07502 6.54199 7.50002 6.36699 8.00002 6.36699C8.50002 6.36699 8.92502 6.54199 9.27502 6.89199C9.62502 7.24199 9.80002 7.66699 9.80002 8.16699C9.80002 8.66699 9.62502 9.09199 9.27502 9.44199C8.92502 9.79199 8.50002 9.96699 8.00002 9.96699ZM8.00002 13.167C6.3778 13.167 4.90002 12.7142 3.56669 11.8087C2.23335 10.9031 1.26669 9.68921 0.666687 8.16699C1.26669 6.64477 2.23335 5.43088 3.56669 4.52533C4.90002 3.61977 6.3778 3.16699 8.00002 3.16699C9.62224 3.16699 11.1 3.61977 12.4334 4.52533C13.7667 5.43088 14.7334 6.64477 15.3334 8.16699C14.7334 9.68921 13.7667 10.9031 12.4334 11.8087C11.1 12.7142 9.62224 13.167 8.00002 13.167Z"
                  fill="#21AC96"
                />
              </g>
            </svg>
          );
        }
        break;

      default:
        {
          return (
            <svg
              className="me-1"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.33331 10.833H7.66665V8.16634H10.3333V6.83301H7.66665V4.16634H6.33331V6.83301H3.66665V8.16634H6.33331V10.833ZM6.99998 14.1663C6.07776 14.1663 5.21109 13.9913 4.39998 13.6413C3.58887 13.2913 2.88331 12.8163 2.28331 12.2163C1.68331 11.6163 1.20831 10.9108 0.858313 10.0997C0.508313 9.28856 0.333313 8.4219 0.333313 7.49967C0.333313 6.57745 0.508313 5.71079 0.858313 4.89967C1.20831 4.08856 1.68331 3.38301 2.28331 2.78301C2.88331 2.18301 3.58887 1.70801 4.39998 1.35801C5.21109 1.00801 6.07776 0.833008 6.99998 0.833008C7.9222 0.833008 8.78887 1.00801 9.59998 1.35801C10.4111 1.70801 11.1166 2.18301 11.7166 2.78301C12.3166 3.38301 12.7916 4.08856 13.1416 4.89967C13.4916 5.71079 13.6666 6.57745 13.6666 7.49967C13.6666 8.4219 13.4916 9.28856 13.1416 10.0997C12.7916 10.9108 12.3166 11.6163 11.7166 12.2163C11.1166 12.8163 10.4111 13.2913 9.59998 13.6413C8.78887 13.9913 7.9222 14.1663 6.99998 14.1663Z"
                fill="#21AC96"
              />
            </svg>
          );
        }
        break;
    }
  };
  const handleEditClick = () =>  {
    navigate(`/roles/edit-role/${id}`);
  };



  useEffect(() => {
    if (id) {
      getRolesetails(id);
    }
  }, []);
  const initialValues = {
    role: roleData.role ? roleData.role : "",
    description: roleData.description ? roleData.description : "",
    permission: permissionData,
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">View Role</h1>
              </Col>
              <div>
                <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => handleEditClick()}
                >
                  <EditIcon className='me-2 fs-15'></EditIcon>
                  Edit New Role
                </Button>
               
              </div>
              </div>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Card className=" p-3 w-100 pageContentInner mb-3">
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3 custom-from-group">
                    <label className="lh-sm control-label fs-14 fw-bold form-label">
                      Title
                    </label>
                    <div className="position-relative ">
                      <p className="fs-14">{initialValues.role}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <label className="lh-sm control-label fs-14 fw-bold form-label">
                    Description
                  </label>
                  <div className="position-relative ">
                    <p className="fs-14">{initialValues?.description}</p>
                  </div>
                </div>
              </div>
            </Card>
            <Card className=" p-3 w-100 pageContentInner">
              <label className="title-right">Rights</label>
              <div className="col-md-12">
              {initialValues.permission.length > 0 &&
  initialValues.permission.map((item) => {
    if (item.permission.some((permission: any) => permission.checked)) {
      // Using a condition to check if at least one permission is checked

      return (
        <div key={item.title} className="deal-cover d-flex">
          <div className="me-3">
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* SVG path here */}
            </svg>
          </div>
          <div>
            <div className="deal-title">{item.display_name}</div>
            <div>
              {item.permission.length &&
                item.permission.map((roles: any) => {
                  if (roles?.checked === true) {
                    return (
                      <a key={roles.title} className="me-3">
                        {getPermissionIcon(roles.title)}
                        {roles.title}
                      </a>
                    );
                  }
                  return null; // Ensure to return something for each iteration
                })}
            </div>
          </div>
        </div>
      );
    }
    return null; // Ensure to return something for each iteration
  })}

              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
