import * as yup from "yup";
import { 
  MAXIMUM_30_CHAR,
  MINIMUM_2_CHAR,
  ALPHABETS_ALLOW,

} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";
export const addSchema = yup.object({
  code : yup.string().trim().matches(ALPHANUMERIC_WITH_SPACE, ALPHABETS_ALLOW).min(2,MINIMUM_2_CHAR).max(30,MAXIMUM_30_CHAR).required().label('Postal Code'), 
  project_owner : yup.array().min(1).required().label("Project Owner"),
});
