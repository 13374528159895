import { Route, Routes } from "react-router-dom";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import LibrariesList from "./index";
import { LibraryAdd } from "./Add";
import LibrariesVersionHistory from "./version-history";

export const Libraries = () => {
  return (
    <Routes>
      <Route path="/list" element={<LibrariesList />} />
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.LIBRARY) && (
        <Route path="/" element={<LibrariesList />} />
      )}
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.LIBRARY_VERSION_HISTORY) && (
        <Route path="/version-history" element={<LibrariesVersionHistory />} />
      )}
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.LIBRARY) && (
        <Route path="/add" element={<LibraryAdd />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.LIBRARY) && (
        <Route path="/edit/:id" element={<LibraryAdd />} />
      )}
    </Routes>
  );
};
