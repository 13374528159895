import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  VALID_EMAIL,
  INVALID_DATE,
} from "../../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../../validations/regex";

interface QualityStatus {
  key: string;
  // Other properties as needed
}

const commonSchema = yup.object({
  project_id: yup.object().required().label("Project Order Number"),
  driver_id: yup.object().required().label("Driver"),
  dispatched_date: yup
    .date()
    .label("Dispatch Date")
    .when("status", {
      is: true,
      then: (schema) => schema.required().typeError(INVALID_DATE),
      otherwise: (schema) =>
        schema.optional().nullable().typeError(INVALID_DATE),
    }),
  images: yup
    .array()
    .optional(),
  dispatchProducts: yup.array()
    .min(1, 'Please add at least one product')    
    .required('Dispatch products are required'),
  remarks: yup
    .string()
    .trim()
    .optional()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Remarks"),
  address: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Site Address"),  
});

const grnSchema = (context: string | undefined | null) => {
  if (context) {
    // // If it's an edit context, add/edit specific validation rules
    // return commonSchema.shape({
    //   // Additional validation rules for edit
    //   // For example, you might not require certain fields in edit
    //   pay_status:yup.object().required().label("Payment Status"),
    //   // Add other edit-specific fields here
    // });
  }

  // If it's an add context, return the common schema
  return commonSchema;
};

export default grnSchema;
