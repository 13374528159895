import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../router/protected";
import {
  getLetterTemplates,
  deleteLetterTemplate
} from "../../services/letterTemplateService";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ViewIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block"
import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import AddCircle from "../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";
import {
  pageSetting,
  pageSizeModel
} from "../../constants/constants";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { checkAbility } from "../../utility/common";

const LetterTemplates: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  /**
   * Add letter template click handler
   */
  const addLetterTemplate = () => {
    navigate(nameBasedProtectedRoutes.addLetterTemplate.path);
  };

  /**
   * Edit letter template click handler
   */
  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/letter-templates/edit-letter-template/${id}`);
  };

  /**
   * View letter template click handler
   */
  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/letter-templates/view-letter-template/${id}`);
  };

  /**
   * Delete letter template click handler
   */
  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  /**
   * Delete letter template: Delete the letter template from server
   */
  const handleDelete = () => {
    setLoading(false);
    deleteLetterTemplate(deletedId)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Close delete confirmation modal
   */
  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  /**
   * Fetch letter templates from server
   * @param {defaultParams}
   */
  const fetchData = (defaultParams: any) => {
    getLetterTemplates(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    console.log("filterModel", filterModel);

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'serialNumber',
      headerName: 'S. No.',
      sortable: true,
      filterable: false,
      hideable:false
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      sortable: true,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      sortable: true,
    }
  ];

  const handleActionPermission = () =>{
    
    const checkEditAbilityCondition = checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.LETTER_TEMPLATE);
    const checkViewAbilityCondition = checkAbility(PERMISSION_ACCESS.VIEW,PERMISSION_MODULE.LETTER_TEMPLATE);
    const checkDeleteAbilityCondition = checkAbility(PERMISSION_ACCESS.DELETE,PERMISSION_MODULE.LETTER_TEMPLATE);
    
    if(checkEditAbilityCondition || checkViewAbilityCondition || checkDeleteAbilityCondition){
      columns.push(
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 1,
        cellClassName: "actions", 
        getActions: ({ id }) => {
          const gridActions = [];
          if(checkEditAbilityCondition){
            gridActions.push(
              <GridActionsCellItem
                label="Edit"
                icon={<EditIcon />}
                showInMenu
                onClick={handleEditClick(id)}
              />
            )
          }
          if(checkDeleteAbilityCondition){
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                onClick={handleDeleteClick(id)}
                showInMenu
              />,
            )
          }
          if(checkViewAbilityCondition){
            gridActions.push(
              <GridActionsCellItem
                label="View"
                onClick={handleViewClick(id)}
                showInMenu
                icon={<ViewIcon />}
              />,
            )
          }
          return gridActions;
        }
      })
    }
    else{
      columns.push(
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 1,
        cellClassName: "actions", 
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
            label="No Action"
            icon={<BlockIcon />}
            showInMenu
            // You can handle the click event if needed
            onClick={() => {}}
          />,
          ]
        }
      })
    }
  }

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []); 
    const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  handleActionPermission();

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Letter Templates</h1>
              </div>
              <div>
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.LETTER_TEMPLATE
                ) && <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => addLetterTemplate()}
                >
                  <img
                    src={AddCircle}
                    className="me-2 fs-15"
                    alt="File Upload Icon"
                  />
                  Add New Letter Template
                </Button>}
              </div>
            </div>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                />
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Letter Template"
                  text="Are you sure you want to delete this letter template?"
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LetterTemplates;
