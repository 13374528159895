import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Slider } from "@mui/material";
import InfoIcon from  '../../../assets/images/note_sticky.svg'
import LightboxGallery from "../../../components/common/LightboxGallery";

const CheckListTemplate: React.FC<{ data: any }> = ({ data }) => {
    const getInitivalValues = (data: any) => {
        let marks: any = [];
        marks = [
          {
            value: data[0].from,
            label: `${data[0].from}`,
          },
          {
            value: data[0].to,
            label: `${data[0].to}`,
          },
        ];
        console.log("marks", marks);
        return marks;
      };
      const [currentIndex, setCurrentIndex] = useState(0);
      const [imagesSet, setImagesSet] = useState<any>([]);
      const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
      const handleClose = () => {
        setImagesSet([])
        setCurrentIndex(0)
        setLightboxIsOpen(false);
      };
      const openLightbox = (fileObj: any, imageIndex: any) => {
        const updatedImgSet = fileObj.map((item: any) => {
          return { ...item, src: item };
        });
         setImagesSet(updatedImgSet)
        setCurrentIndex(imageIndex);
        setLightboxIsOpen(true);
      };
       
  return (
    
      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        {data.map((item: any, index: number) => {
          if (item.subsections.length) {
            return (
              <>
               
                {item.subsections.map((subItem: any, subIndex: number) => {
                  if (subItem.fields.length) {
                    return (
                      <>
                        {
                          <>
                            {subItem.fields.length &&
                              subItem.fields.map(
                                (field: any, fieldIndex: number) => {
                                  if (field.visibility) {
                                    return (
                                      <Card className="p-3 w-100  mb-3">
                                        <div className="d-flex flex-column rounded-0 ">
                                          <Row className="d-flex">
                                            {/* type text */}
                                            {field.type == "text" && (
                                              <Col className="col-md-12">
                                                <p className="fs-14 mb-2">
                                                  {field.title}{" "}
                                                  {field.require && "*"}
                                                </p>
                                                <div className="w-100 survery-input">
                                                  <p className="fw-bold mb-0">
                                                    {field.value}
                                                  </p>
                                                </div>
                                              </Col>
                                            )}

                                            {/* type dropdown */}
                                            {field.type == "dropdown" && (
                                              <Col className="col-md-12">
                                                <p className="fs-14 mb-2">
                                                  {field.title}{" "}
                                                  {field.require && "*"}
                                                </p>
                                                <div className="w-100 survery-input">
                                                  <p className="fw-bold mb-0">
                                                    {field.value.value}
                                                  </p>
                                                </div>
                                              </Col>
                                            )}

                                            {/* type slider */}
                                            {field.type == "slider" && (
                                              <Col className="col-md-12">
                                                <p className="fs-14 mb-2">
                                                  {field.title}{" "}
                                                  {field.require && "*"}
                                                </p>
                                                <div className="w-100 survery-input">
                                                  <Slider
                                                    aria-label="Always visible"
                                                    defaultValue={field.value}
                                                    step={0}
                                                    marks={getInitivalValues(
                                                      field.data_set
                                                    )}
                                                    max={field.data_set[0].to}
                                                    disabled
                                                    valueLabelDisplay="on"
                                                  />
                                                </div>
                                              </Col>
                                            )}

                                            {/* type radio_button */}
                                            {field.type == "radio_button" && (
                                              <Col className="col-md-12">
                                                <p className="fs-14 mb-2">
                                                  {field.title}{" "}
                                                  {field.require && "*"}
                                                </p>
                                                <div className="w-100 survery-input">
                                                  <p className="fw-bold mb-0">
                                                    {field.value}
                                                  </p>
                                                </div>
                                              </Col>
                                            )}

                                            {/* type message */}
                                            {field.type == "message" && (
                                              <Col className="col-md-12">
                                                <p className="fs-14 mb-2">
                                                  {field.default_value}{" "}
                                                </p>
                                              </Col>
                                            )}

                                            {/* type button */}
                                            {field.type == "button" && (
                                              <Col className="col-md-12 pe-0">
                                                <p className="fs-14 mb-2 pb-2 border-bottom">
                                                  {field.title}{" "}
                                                  {field.require && "*"}
                                                </p>
                                                <label className="browse-cover   survey-browse">
                                                  <div className="d-flex">
                                                    {field.signed_image_set &&
                                                      field.signed_image_set
                                                        .length &&
                                                      field.signed_image_set.map(
                                                        (
                                                          image: any,
                                                          imageIndex: number
                                                        ) => {
                                                          return (
                                                            <div className="survey-image p-0 m-1">
                                                              <img
                                                                src={image}
                                                                height={80}
                                                                width={80}
                                                                className="imageFitScal cursor-pointer"
                                                                onClick={()=>openLightbox(field.signed_image_set,imageIndex)}
                                                              />
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                </label>
                                              </Col>
                                            )}

                                            
                                          </Row>

                                          {field.notes && (
                                            <Row>
                                              <Col className="col-md-12">
                                                <p
                                                  className="fw-bold mb-0 "
                                                  title={field.notes}
                                                >
                                                   <img src={InfoIcon} />
                                                  {field.notes}{" "}
                                                </p>
                                              </Col>
                                            </Row>
                                          )}
                                        </div>
                                      </Card>
                                    );
                                  }
                                }
                              )}
                          </>
                        }
                      </>
                    );
                  }
                })}
              </>
            );
          }
        })}
                   <LightboxGallery images={imagesSet} currentIndex={currentIndex} open={lightboxIsOpen} onClose={handleClose} />

      </div>
    
  );
};

export default CheckListTemplate;
