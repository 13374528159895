import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { getProjectStageCount } from "../../services/dashboardMaster.service";
import { DateRangeInterface } from "./dashboardInterface";
import ProjectCountSkeleton from "../../components/common/Skeleton/ProjectCountSkeleton";

const ProjectCount: React.FC<DateRangeInterface> = ({ orderDateRange }) => {
 
    const [loading, setLoading] = useState(true);
    const [projectData, setProjectData] = useState({} as any);
useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
        controller.abort();
      };
}, [orderDateRange]);
const fetchData = (signal: any) => {
    getProjectStageCount(orderDateRange, signal).then((res) => {
        console.log(res.data);
        setLoading(false);
        setProjectData(res.data);
    
    }).catch((err) => {
        setLoading(false);
    })
}
    return (
        <div>
            <Card className="p-3 w-100 pageContentInner rounded-2 mt-3 mb-3">
                <h5 className="fs-18 info-text fw-bold mb-3">Projects</h5>
                {loading ? <ProjectCountSkeleton/> : 
                 <div className="row d-flex justify-content-arround">
                    <Col >
                        <div className="card py-3 px-4 mb-2 text-white asset-card dashboard-card-Onboarding">
                            <div className="fs-16">Onboarding</div>
                            <div className="fs-25 fw-bold">{projectData.onboarding ?projectData.onboarding:'-' }</div> 
                        </div>
                    </Col>
                    <Col >
                        <div className="card py-3 px-4 mb-2 text-white asset-card dashboard-card-Survey"> 
                            <div className="fs-16">Survey Stage</div>
                            <div className="fs-25 fw-bold">{projectData.survey ?projectData.survey:'-' }</div>
                        </div>
                    </Col>
                    <Col >
                        <div className="card py-3 px-4 mb-2 text-white asset-card dashboard-card-Procurement"> 
                            <div className="fs-16">Procurement Stage</div>
                            <div className="fs-25 fw-bold">{projectData.procurment ?projectData.procurment:'-' } </div>
                        </div>
                    </Col>
                    <Col >
                        <div className="card py-3 px-4 mb-2 text-white asset-card dashboard-card-Installation"> 
                            <div className="fs-16">Installation Stage</div>
                            <div className="fs-25 fw-bold">{projectData.installation ?projectData.installation:'-' }</div>
                        </div>
                    </Col>
                    <Col >
                        <div className="card py-3 px-4 mb-2 text-white asset-card dashboard-card-Complated"> 
                            <div className="fs-16">Completed</div>
                            <div className="fs-25 fw-bold">{projectData.completed ?projectData.completed:'-' }</div> 
                        </div>
                    </Col>
                    

                 </div> }
            </Card>
        </div>
    )
}

export default ProjectCount;