import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  VALID_EMAIL,
  INVALID_DATE,
} from "../../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../../validations/regex";

interface QualityStatus {
  key: string;
  // Other properties as needed
}

const commonSchema = yup.object({
  contract_id: yup.object().required().label("Project Order No."),  
  payment_milestone_id: yup.object().required().label("Milestone"),  
  inv_date: yup
    .date()
    .label("Invoice Date")
    .required().typeError(INVALID_DATE),
  due_date: yup
    .date()
    .label("Due Date")
    .required().typeError(INVALID_DATE), 
  invoiceDetails: yup.array().of(
      yup.object({
        qty: yup
        .number()
        .integer().positive().typeError("Qty. must be a number").required()
        .label("Qty"),      
        price: yup
        .number()
        .positive().typeError("Price must be a number").required()
        .label("Price"),      
        tax_percentage: yup
        .number()
        .moreThan(-1, 'Enter valid VAT').typeError("VAT must be a number").required()
        .label("VAT"),      
        description: yup
        .string()
        .required()         
        .label("Description"),     
        amount: yup
        .number()
        .required()         
        .label("Amount"),     
             
      })
    ),  
});

const grnSchema = (context: string | undefined | null) => {
  if (context) {
    // // If it's an edit context, add/edit specific validation rules
    // return commonSchema.shape({
    //   // Additional validation rules for edit
    //   // For example, you might not require certain fields in edit
    //   pay_status:yup.object().required().label("Payment Status"),
    //   // Add other edit-specific fields here
    // });
  }

  // If it's an add context, return the common schema
  return commonSchema;
};

export default grnSchema;
