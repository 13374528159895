import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Select from "react-select";


const FormSelect: React.FC<Partial<any>> = ({
  className,
  async,
  placeholder,
  options,
  value,
  allowSelectAll,
  selectAllLabel = "Select All",
  isLoading,
  rightCallToAction,
  disabledClass,
  ...props
}) => {
  const SelectToRender = Select;
  const everyOptionSelected =
    value && options && value.length === options.length;
  const thereIsMoreThanOneOption =
    (value && options && options.length - value.length > 1) || !value;
  const selectAllOption = {
    value: selectAllLabel,
    key: "formSelect_selectAll",
  };
  let optionsToShow = options;
  let disabledClassName = disabledClass ? disabledClass : "";

  if (allowSelectAll) {
    if (!everyOptionSelected && thereIsMoreThanOneOption) {
      optionsToShow = [selectAllOption, ...(options || [])];
    }
  }

  const selectAllOptionMachine = {
    label: 'Select All', value: 'All Machine'
  };

  if (props.machineSelect) {
    if (!everyOptionSelected && options.length > 0) {
      optionsToShow = [selectAllOptionMachine, ...(options || [])];
    }
  }

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.value}</span>
    </div>
  );

  return (
    <div className={`${className} default-form-box ${disabledClassName}`}>
      {props.label && (
        <div className="flex">
          <label
            className="lh-sm control-label fs-14 fw-bold form-label"
            htmlFor={props.name}
          
          >
            {props.label}
          </label>
          {rightCallToAction}
        </div>
      )}

      <SelectToRender
        {...props}
        inputId={props.name}
        placeholder={placeholder}
        className={`${props.touched && props.error ? "is-error is-invalid" : ""}`}
        isLoading={isLoading}
        classNamePrefix="react-select"
       // noResultsText={isLoading ? "Loading" : "No Result Found"}
        options={optionsToShow}
        value={value}
        isMulti={props.multi?props.multi:false}        
        isDisabled={props.isDisabled?props.isDisabled:false}  
        isClearable = { props.isClearable?props.isClearable:false}      
        menuPortalTarget={document.body}
        formatGroupLabel={formatGroupLabel}
        onChange={(selectedOption) => {
          if (selectedOption) {
            if (
              allowSelectAll &&
              selectedOption.length > 0 &&
              selectedOption[selectedOption.length - 1].value ===
                selectAllOption.value
            ) {
              props.onChange(props.name, options);
            } else {
              props.onChange(props.name, selectedOption);
            }
          } else {
            props.onChange(props.name);
          }
        }}
        onBlur={props.onBlur}
      />

      {props.help && (
        <div className="flex">
          <div>
            {props.help || ""}
          </div>
        </div>
      )}

      {props.touched && props.error && (
        <div className="flex invalid-feedback">
          <span className="text-danger" >
            {props.touched && props.error ? props.error : "no error"}
          </span>
        </div>
      )}
    </div>
  );
};

FormSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelType: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
  ]),
  onBlur: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  allowSelectAll: PropTypes.bool,
  selectAllLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  help: PropTypes.string,
  maxWidth: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  minHeight: PropTypes.string,
  multi: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  machineSelect: PropTypes.bool,
rightCallToAction: PropTypes.node,
  disabledClass: PropTypes.string,
};

export default styled(FormSelect)`
  outline: none;
  margin-bottom: 1rem;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  width: ${(props) => props.width || "100%"};
  ${(props) => {
    if (props.margin) {
      return `margin: ${props.margin}px;`;
    }
  }}
  min-height: ${(props) => props.minHeight || "60px"};
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .Select-control {
    border: 1px solid #dbdbdb !important;
    box-shadow: none !important;
  }
  .Select-menu-outer {
    z-index: 999999;
  }
  ${(props) =>
    props.touched &&
    !!props.error &&
    css`
      .Select-control {
        border-color: var(--error-color) !important;
      }
    `};
  ${(props) =>
    props.multi &&
    css`
      .Select-value {
        border-radius: 4px;
        background-color: #0080de;
        border: none;
        color: white;
        display: inline-flex;
        flex-direction: row-reverse;
        & > span {
          border: none;
        }
      }
    `};
`;
