import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function PieChartSkeleton() {
  return (
    <div className="p-1">
      <div className=" d-flex align-items-center overflow-hidden">
        <div>
          <Skeleton
            animation="wave"
            variant="circular"
            width={300}
            height={300}
          />
        </div> 
        <div className="ms-5">
          <Stack spacing={2}>
            <Skeleton variant="rounded" width={250} height={35} />
            <Skeleton variant="rounded" width={250} height={35} />
            <Skeleton variant="rounded" width={250} height={35} />
            <Skeleton variant="rounded" width={250} height={35} />
          </Stack>
        </div>

      </div>
    </div>
  );
}
