import { Route, Routes } from 'react-router-dom';
import { checkAbility } from '../../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../../constants/permissionUser';
import { ViewFinanceProject } from './View';
import FinanceProjectList from './index';


export const FinanceProjects = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.FINANCE_PROJECTS) && (
                <Route path="/" element={<FinanceProjectList />} />
            )}
                   
            {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.FINANCE_PROJECTS) && (
                <Route path="/view/:id" element={<ViewFinanceProject />} />
            )}                  
        </Routes>
    )
};