import { Route, Routes } from "react-router-dom";
import PostalcodeList from "./index";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";

export const Postalcode = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.POSTAL_CODE) && (
      <Route path="/" element={<PostalcodeList />} />
      )}

    </Routes>
  );
};
