import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { pageSetting } from "../../../constants/constants";
import { checkAbility, getLocalDateFormat } from "../../../utility/common";
import { getFinanceDashboardPendingInvoiceCreation } from "../../../services/financeService";
import { Scrollbar } from "react-scrollbars-custom";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import ViewAgenda from '@mui/icons-material/Visibility';
import TableSkeleton from "../../../components/common/Skeleton/Table.skeleton";

interface AddProps {
  dateRange: any;
}

const FinancePendingInvoiceCreation: React.FC<AddProps> = ({ dateRange }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    getFinanceDashboardPendingInvoiceCreation(dateRange)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  return (
    <React.Fragment>
        <div className="d-flex flex-column pageContainer px-sm-1 w-100 h-100">
          <Card className="p-3 w-100 pageContentInner rounded-2 h-100">
            <Row>
              <Col>
                <h5 className="fs-18 fw-bold info-text mb-2">Pending Invoice Creation</h5>
              </Col>
            </Row>
          {loading ? (
             <TableSkeleton />
          ) : (
            <Scrollbar className="pending-invoice-creation-scroll">
              
                  <Table className="payment-custom-table">
                    <thead>
                      <tr>
                        <th>Project Order No.</th>
                        <th>Milestone</th>
                        <th>Due Date</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        data.map(
                          (pData: {
                            project: any;
                            name: any;
                            due_date: any;
                          }) => (
                            <tr>
                              <td>
                                {checkAbility(
                                  PERMISSION_ACCESS.VIEW,
                                  PERMISSION_MODULE.FINANCE_PROJECTS
                                ) ? (
                                  <Link
                                    target="_blank"
                                    className="blue-text text-decoration-none fw-semibold"
                                    to={`/finance/projects/view/${pData?.project?.id}`}
                                  >
                                    {" "}
                                    <span title={pData?.project?.project_no}>
                                      {pData?.project?.project_no}
                                    </span>
                                  </Link>
                                ) : (
                                  pData?.project?.project_no
                                )}
                              </td>
                              <td>{pData?.name}</td>
                              <td>
                                {pData?.due_date
                                  ? getLocalDateFormat(pData?.due_date)
                                  : "-"}
                              </td>
                              <td className="text-center">{checkAbility(
                                  PERMISSION_ACCESS.VIEW,
                                  PERMISSION_MODULE.FINANCE_PROJECTS
                                ) ? (
                                  <Link
                                    target="_blank"
                                    className="text-decoration-none text-primary fw-semibold"
                                    to={`/finance/projects/view/${pData?.project?.id}`}
                                  >
                                    <ViewAgenda />
                                  </Link>
                                ) : (
                                  pData?.project?.project_no
                                )}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={4}>No Records Available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                
            </Scrollbar>
          )}
          </Card>
        </div>
    </React.Fragment>
  );
};

export default FinancePendingInvoiceCreation;
