import { Route, Routes } from "react-router-dom";

import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import Index from "./index";
import ExpiryDoc from "./ExpireDocumentFullList";
import DelayMeeting from "./MeetingDelayFullList";
import { Login } from "../Auth/Login";
 
export const HR_DASHBOARD = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.HR_DASHBOARD) && (
        <>
          <Route path="/" element={<Index />} />
          <Route path="/expiry-doc" element={<ExpiryDoc />} />
          <Route path="/delay-meeting" element={<DelayMeeting />} />
        </>
      )}
      <Route path="*" element={<Login />} />
    </Routes>
  );
};
