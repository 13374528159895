import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../router/protected";

import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import AddCircle from "../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";

import { getRolesList } from "../../services/roleService";
import PostalcodeAdd from "./Add";
import {
  deletePostalCodeApi,
  getPostalcodeList,
} from "../../services/postalcode.service";
import ability from "../../role-manager/ability";
import { Can } from "../../role-manager/can";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import ProjectOwnerAssign from "./project-owner";
import { pageSizeModel } from "../../constants/constants";

interface RoleOption {
  key: string;
  value: string;
}
interface IPostalCode {
  id?: number;
  code?: string;
  createdAt: string;
  updatedAt: string;
  postalCodeUsers: any;
}
const Postalcode: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [editableData, setEditableData] = useState<IPostalCode | null>();
  const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);

  const [postalCodeOpen, setPostalCodeOpen] = useState(false);
  const [projectOwnerOpen, setProjectOwnerOpen] = useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  const addLink = () => {};

  const togglePostalCode = () => {
    setEditableData(null);

    setPostalCodeOpen(!postalCodeOpen);
  };
  
  const toggleProjectOwner = () => {   
    setProjectOwnerOpen(!projectOwnerOpen);
    setIsMutation(!isMutation);
  };

  const handleEditClick = (data: any) => () => {
    setEditableData(data)
    setPostalCodeOpen(true)
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };
  // const fetchRolls = async () => {
  //   return new Promise((resolve, reject) => {
  //     getRolesList(rolesDefaultParams).then((response) => {
  //       const roleModifyOption = response.data?.resultSet.map((item: any) => ({
  //         key: String(item.id),
  //         value: item.role,
  //       }));
  //       setRoleOptions(roleModifyOption);
  //       return resolve(true);
  //     });
  //   });
  // };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const handleDelete = () => {
    setLoading(false);
    deletePostalCodeApi(deletedId)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        setDeletePopupOpen(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = (defaultParams: any) => {
    getPostalcodeList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  type UserDetails = {
    full_name: string;
    id: number;
  };

  type UserArrayElement = {
    user_id: number;
    userDetails: UserDetails;
  };

  const getManagerName = (data: UserArrayElement[]) => {
    const commaName = data
      .map((item: UserArrayElement) => {
        return item?.userDetails?.full_name ? item?.userDetails?.full_name : "";
      })
      .join(", ");
    return commaName;
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  // Function to filter roles based on role_id
  const filteredRoles = (role_ids: number[]) => {
    const roleData = roleOptions.filter((option: any) =>
      role_ids.some((roleId: any) => roleId.id === option.key)
    );

    return roleData.map((item) => item.value).join(", ");
  };

  const columns: GridColDef[] = [
    { field: "code", headerName: "Postal Code", flex: 1, sortable: true,hideable:false },
    {
      field: "project_owner",
      headerName: "Project Owner",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return   <span title={params?.row?.postalCodeUsers && getManagerName(params.row.postalCodeUsers)}>{params?.row?.postalCodeUsers && getManagerName(params?.row?.postalCodeUsers)}</span>       
       }
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.5,
      cellClassName: "actions",
      getActions: (params) => [
        <div className="table-tags d-flex">
          {checkAbility(
            PERMISSION_ACCESS.EDIT,
            PERMISSION_MODULE.POSTAL_CODE
          ) && (
            <div className="me-3" onClick={handleEditClick(params.row)}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 18V13.75L13.2 0.575C13.4 0.391667 13.6208 0.25 13.8625 0.15C14.1042 0.05 14.3583 0 14.625 0C14.8917 0 15.15 0.05 15.4 0.15C15.65 0.25 15.8667 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.7708 2.4 17.8625 2.65C17.9542 2.9 18 3.15 18 3.4C18 3.66667 17.9542 3.92083 17.8625 4.1625C17.7708 4.40417 17.625 4.625 17.425 4.825L4.25 18H0ZM14.6 4.8L16 3.4L14.6 2L13.2 3.4L14.6 4.8Z"
                  fill="#21AC96"
                />
              </svg>
            </div>
          )}{" "}
          {checkAbility(
            PERMISSION_ACCESS.DELETE,
            PERMISSION_MODULE.POSTAL_CODE
          ) && (
            <div onClick={handleDeleteClick(params.row.id)}>
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                  fill="#21AC96"
                />
              </svg>
            </div>
          )}
        </div>,
      ],
    },
  ];
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search,postalCodeOpen, sortColumn, sortDirection]);

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
   console.log(data);
    const filteredArray:any = data.filter((itemTwo:any) =>
    rowSelectionModel.some((itemOne:any) => itemOne === itemTwo.id)
  ).map((item : any) => ({ id: item.id, code: item.code }))
 // console.log(filteredArray);
    setSelectionModel(filteredArray);
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Manage Postal Code</h1>
              </div>
              <div>
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.POSTAL_CODE
                ) && (
                  <>
                   <button
                  className={`fw-semibold fs-12 me-2 btn ${selectionModel?.length ? 'btn-primary text-white' : 'btn-secondary text-black '}`}                 
                  type="button"
                  disabled={selectionModel?.length ? false : true}
                  onClick={() => toggleProjectOwner()}
                >
                <AssignmentInd  className="me-2 fs-18"/>                 
                  Assign Project owner
                </button>
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => togglePostalCode()}
                  >
                    <img
                      src={AddCircle}
                      className="me-2 fs-15"
                      alt="File Upload Icon"
                    />
                    Add New Postal Code
                  </Button>
                  </>
                )}

                {/* {JSON.stringify(ability.can("delete", "User"))} check */}
              </div>
            </div>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  pageSizeOptions={pageSizeModel}
                  sortingMode="server"
                  className="border-0 rounded-2"
                  onRowSelectionModelChange={handleSelectionModelChange}
                  checkboxSelection
                />
                {postalCodeOpen && (
                  <PostalcodeAdd
                    isOpen={postalCodeOpen}
                    onClose={togglePostalCode}
                    editableData={editableData}
                  />
                )}
                {projectOwnerOpen && (
                  <ProjectOwnerAssign
                    isOpen={projectOwnerOpen}
                    onClose={toggleProjectOwner}
                    codes={selectionModel}                    
                  />
                )}

                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Postalcode"
                  text="Are you sure you want to delete this postalcode?"
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Postalcode;
