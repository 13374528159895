import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface CaptchaProps {
    onChangeCaptchaCode: (value: string | null) => void;
}

function Captcha({ onChangeCaptchaCode }: CaptchaProps) {
    const recaptchaRef = useRef<any>(null);
    const cpatchaKey: string | any = process.env.REACT_APP_CPATCHA_KEY;


    return (
        <div>
            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={cpatchaKey}
                              onChange={onChangeCaptchaCode}
                              className='recaptcha overflow-x-auto overflow-y-hidden'
                           />
        </div>
    );
}

export default Captcha;