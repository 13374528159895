import { axios } from "./axios";
import {
    CREATE_LETTER_TEMPLATE,
    GET_LETTER_TEMPLATES,
    DELETE_LETTER_TEMPLATE,
    DOWNLOAD_LETTER_FORM_TEMPLATE,
    GET_LETTER_TEMPLATE_TYPES,
    CHECK_LETTER_TEMPLATE_EXISTS,
    GET_EMAIL_TEMPLATES
} from "../constants/paths";

export interface TemplateType{
  key: string;
  value: string;
};

export type createLetterTemplateDTO = {
  title: string;
  type: TemplateType | string;
  description: string;
  email_subject?: string;
  email_body?: string;
  file?: string | File;
};

export type checkTemplateExistDTO = {
  title: string; 
  id?: number | string;
};


export type emailTemplatesDTO = {
  id: number;
  title: string;
  email_subject?: string;
  email_body?: string;
};

/**
 * Get all letter templates from server
 * @param {formData: any }
 * @returns Letter Templates
 */
export const getLetterTemplates = (formData: any): Promise<any> => {
  return axios.get(GET_LETTER_TEMPLATES, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * Create a new letter template
 * @param {data: createLetterTemplateDTO } 
 */
export const createLetterTemplate = (data: createLetterTemplateDTO): Promise<any> => {
  const transformData = {
    ...data,
    type: (typeof data.type === 'object') ? data.type.key : data.type,
  };

  transformData.file = data.file;
  return axios.post(CREATE_LETTER_TEMPLATE, transformData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * Get a single letter template by ID
 * @param {id: string } params
 * @returns letter template
 */
export const getLetterTemplateById = (id: string): Promise<any> => {
  return axios.get(GET_LETTER_TEMPLATES + "/" + id);
};

/**
 * Get letter template types
 * @returns Letter template types
 */
export const getLetterTemplateTypes = (): Promise<any> => {
  return axios.get(GET_LETTER_TEMPLATE_TYPES);
};

/**
 * Update existing letter template
 * @param {id: string, data: createLetterTemplateDTO } params
 * @returns Promise<any>
 */
export const updateLetterTemplate = (
  id: string,
  data: createLetterTemplateDTO
): Promise<any> => {
  const transformData = {
    ...data,
    type: (typeof data.type === 'object') ? data.type.key : data.type,
  };
  return axios.put(GET_LETTER_TEMPLATES + "/update/" + id, transformData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * Delete existing letter template
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteLetterTemplate = (id: string | number | null): Promise<any> => {
  return axios.delete(DELETE_LETTER_TEMPLATE + "/" + id);
};

/**
 * Get S3 signed url of form template PDF or Doc file
 * @param {id: string } params
 * @returns Promise<any>
 */
export const downloadFormTemplate = (id: any): Promise<any> => {
  return axios.get(DOWNLOAD_LETTER_FORM_TEMPLATE + "/" + id);
};

/**
 * Check existance of letter template by title
 * @param {data: createLetterTemplateDTO } 
 */
export const CheckLetterTemplateExists = (data: checkTemplateExistDTO): Promise<any> => {
  return axios.post(CHECK_LETTER_TEMPLATE_EXISTS, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * Get all email templates
 * @returns Email Templates
 */
export const getEmailTemplates = (): Promise<any> => {
  return axios.get(GET_EMAIL_TEMPLATES);
};
