import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { addSchema } from "./validation";
import InputText from "../../../components/common/InputText";
import FormSelect from "../../../components/common/FormSelect";
import CustomButton from "../../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import InputDatePicker from "../../../components/common/InputDatePicker";
import moment from "moment";
import constants from "../../../constants/constants";
import { useNavigate, useParams } from "react-router-dom";
import InputTextArea from "../../../components/common/InputTextArea";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";
import {
  applyLeaveApi,
  applyLeaveByRmApi,
  getBusinessDaysCountApi,
  getLeaveTypeList,
} from "../../../services/leavesService";
import InputTimePicker from "../../../components/common/InputTimePicker";
import DeletePopup from "../../../components/common/deletePopup";

interface IHolidays {
  id?: number;
  title?: string;
  description?: string;
  file?: any;
  document?: any;
  createdAt: string;
  updatedAt: string;
}

interface AddLeaveProps {
  uType?: string;
  onClose: (isReload?:boolean) => void;
  employeeID?: any;
}

interface FormValues {
  leave_type: any;
  date_from: any;
  date_to: any;
  appointment_time: any;
  leave_count_same_day: any;
  leaves_count: string | number | undefined;
  description: string;
}
export const ApplyLeave: React.FC<AddLeaveProps> = ({
  employeeID,
  uType = "all",
  onClose,
}) => {
  const [leaveTypesData, setLeaveTypesData] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();

  const initialValues = {
    leave_type: null,
    date_from: null,
    date_to: null,
    appointment_time: null,
    leaves_count: "",
    description: "",
    leave_count_same_day: null,
  };

  const fullHalfDayOptions = [
    { key: 1, value: "Morning (AM)", session: '1', leave_count : 0.5 },
    { key: 2, value: "Afternoon (PM)", session: '2', leave_count : 0.5 },
    { key: 3, value: "Full Day", session: '0', leave_count : 1 },
  ];

  const updateLeavesCount = async (
    date_to: any,
    date_from: any,
    leave_type:any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    let noDays = 0;
    let leaveBalance = 0;

    if (date_from && date_to) {
      // console.log(leave_type, "===========", date_to);
      // setLoading(true);
      const requestData = {
        date_from: moment(date_from).format(
          constants.dateFormats.databaseFormat
        ),
        date_to: leave_type?.is_appointment_type ? moment(date_from).format(
          constants.dateFormats.databaseFormat
        ) : moment(date_to).format(constants.dateFormats.databaseFormat),
      };      
      await getBusinessDaysCountApi(employeeID,requestData)
        .then((response: any) => {
          noDays = response.data.business_days;
          leaveBalance = response.data.leave_balance;
          if(noDays > 0){
            setFieldValue("leaves_count", noDays);
            setFieldValue("leave_count_same_day", { key: 3, value: "Full Day", session: "0", leave_count : 1 });
            leave_type?.is_appointment_type && setFieldValue("date_to", date_from);
          }
          else{
            setFieldValue("date_from", null);
            setFieldValue("date_to", null);
            toast("Leave cannot be marked for this day.", { type: toast.TYPE.ERROR });
          }
         // console.log(noDays, +leaveBalance)
          const updatedLeaves = leaveTypesData.map((leave: { is_unpaid_leave: any; }) => ({
            ...leave,
            isDisabled: noDays <= +leaveBalance && leave.is_unpaid_leave
          }));
          setLeaveTypesData(updatedLeaves);
          if(noDays <= +leaveBalance && leave_type.is_unpaid_leave){           
            setFieldValue("leave_type", null);
          }
         
          //setLeaveTypesData(leaveTypesOptions);
          // setLoading(false);
        })
        .catch((error: any) => {
          //  setLoading(false);
          toast(error.data.msg, { type: toast.TYPE.ERROR });
        });
      // const startMoment = moment(date_from);
      // const endMoment = moment(date_to);

      // if (endMoment.isBefore(startMoment)) {
      //   noDays = 0;
      // } else {
      //   let current = startMoment.clone();
      //   while (current.isSameOrBefore(endMoment)) {
      //     const day = current.day();
      //     if (day !== 0 && day !== 6) { // Exclude weekends (0 is Sunday, 6 is Saturday)
      //       noDays++;
      //     }
      //     current.add(1, 'day');
      //   }
      // }
    }
  };
  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    if(uType != 'rm'){
      const dateDiff = moment(values.date_from).startOf('day').diff(moment().startOf('day'), 'days');
      // console.log(dateDiff, moment(values.date_from).startOf('day'), moment().startOf('day'));
      if(dateDiff <= 30 && dateDiff >= 0){       
        setDeletePopupOpen(true)
        return;
      }
    }
    saveEmployeeLeave(values);
  };

  const saveEmployeeLeave = async(values: FormValues) => {   
    const formData: any = {
      ...values,
      date_from: moment(values.date_from).format(
        constants.dateFormats.databaseFormat
      ),
      date_to: moment(values.date_to).format(
        constants.dateFormats.databaseFormat
      ),
      appointment_time: values.appointment_time ? moment(values.appointment_time).format("HH:mm") : null,
      leave_type_id: values.leave_type?.key,
      leaves_count:
        values.leaves_count == 1
          ? values.leave_count_same_day?.leave_count
          : values.leaves_count,
      session: values.leave_count_same_day?.session
    };

    delete formData.leave_type;
    delete formData.leave_count_same_day;
    // console.log(uType, formData);
    //return;
    setBtnLoading(true);
    if(uType == 'rm')
    {
      empLeaveApplyByRm(formData);
    }
    else{
      empLeaveApply(formData);
    }
  }

  const empLeaveApply = async (formData: any) => {
    applyLeaveApi(formData)
    .then((response) => {
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      navigate("/leaves/leave-history");
      setBtnLoading(false);
    })
    .catch((error) => {
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, {
          type: toast.TYPE.ERROR,
        });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, {
          type: toast.TYPE.ERROR,
        });
      }
    });
  }
  const empLeaveApplyByRm = async (formData: any) => {
    applyLeaveByRmApi(employeeID,formData)
    .then((response) => {
      toast(response.msg, { type: toast.TYPE.SUCCESS });
     // navigate("/leaves/leave-history");
      onClose(true);
      setBtnLoading(false);
    })
    .catch((error) => {
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, {
          type: toast.TYPE.ERROR,
        });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, {
          type: toast.TYPE.ERROR,
        });
      }
    });
  }

  const fetchLeavesType = async () => {
    setLoading(false);
    await getLeaveTypeList(uType)
      .then((response: any) => {
        const leaveTypesOptions = response.data?.map((item: any) => ({
          ...item,
          key: String(item.id),
          value: item.name,
        }));
        setLeaveTypesData(leaveTypesOptions);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    fetchLeavesType().then(() => {
      setLoading(false);
    });
  }, [employeeID]);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div
            className={`${
              uType == "rm" ? "px-0" : "px-3 py-2 my-1"
            } pageHeader`}
          >
            {uType != "rm" && (
              <Row className="align-items-center">
                <Col>
                  <h1 className="fw-bold h4 my-2">Apply Leave</h1>
                </Col>
              </Row>
            )}
          </div>
          <div
            className={`${
              uType == "rm" ? "px-0" : "overflow-auto px-3"
            } flex-grow-1 pageContent pb-3`}
          >
            <Formik
              validationSchema={addSchema}
              initialValues={initialValues}
              onSubmit={(values: FormValues, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>                  
                    <Card
                      className={`${
                        uType == "rm" ? "p-0 border-0 m-0" : "mb-3 p-3"
                      } w-100 pageContentInner`}
                    >
                      <div>
                        <Row>
                          <Col
                            md={uType == "rm" ? 6 : 4}
                            className={`${uType != "rm" && "mb-3"}`}
                          >
                            <InputDatePicker
                              name="date_from"
                              label="From"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  placeholder: "Select",
                                  className: "form-control",
                                  readOnly: true,
                                  id: "date_from",
                                },
                              }}
                              maxDate={values.date_to ?? null}
                              value={values.date_from}
                              disabledWeekend
                              onChange={(name, newValue) => {
                                setFieldValue(name, newValue);
                                ((!values.date_to || (values.date_to && moment(values.date_to).year() != moment(newValue).year())) || 
                                  values.leave_type?.is_appointment_type) &&
                                  setFieldValue("date_to", newValue);                              
                                updateLeavesCount(
                                  values.date_to ? values.date_to : newValue,
                                  newValue,
                                  values.leave_type,
                                  setFieldValue
                                );
                              }}
                              touched={touched.date_from}
                              errors={errors.date_from}
                            />
                          </Col>
                          <Col
                            md={uType == "rm" ? 6 : 4}
                            className={`${uType != "rm" && "mb-3"}`}
                          >
                            <InputDatePicker
                              name="date_to"
                              label="To"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  placeholder: "Select",
                                  className: "form-control",
                                  readOnly: true,
                                  id: "date_to",
                                },
                              }}
                              minDate={values.date_from ?? null}
                              maxDate={values.date_from ? moment(values.date_from).endOf('year') : moment().endOf('year')}
                              disabledWeekend
                              value={values.date_to}
                              isDisable={values.leave_type?.is_appointment_type}
                              onChange={(name, newValue) => {
                                setFieldValue(name, newValue);
                                updateLeavesCount(
                                  newValue,
                                  values.date_from,
                                  values.leave_type,
                                  setFieldValue
                                );
                              }}
                              touched={touched.date_to}
                              errors={errors.date_to}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={uType == "rm" ? 6 : 4}
                            className={`${uType != "rm" && "mb-3"}`}
                          >
                            <FormSelect
                              placeholder="Select"
                              label="Leave Type"
                              name="leave_type"
                              options={leaveTypesData}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: any) => {
                                setFieldValue(name, value);
                                value?.is_appointment_type &&
                                  setFieldValue("date_to", values.date_from);
                                updateLeavesCount(
                                  values.date_to,
                                  values.date_from,
                                  value,
                                  setFieldValue
                                );
                              }}
                              value={values.leave_type}
                              error={errors.leave_type}
                              touched={touched.leave_type}
                            />
                          </Col>
                          <Col md={uType == "rm" ? 6 : 4}>
                            {values.leave_type?.is_appointment_type ? (
                              <>
                                {" "}
                                <InputTimePicker
                                  name="appointment_time"
                                  label="Appointment Time"
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      placeholder: "Select",
                                      readOnly: true,
                                      className: "form-control",
                                      id: "appointment_time",
                                    },
                                  }}
                                  value={values.appointment_time}
                                  onChange={(name, newValue) =>
                                    setFieldValue(name, newValue)
                                  }
                                  touched={touched.appointment_time}
                                  errors={errors.appointment_time}
                                />
                                <span className="text-muted">
                                  Note: this will create a 2 hours appointment
                                  from selected time slot
                                </span>
                              </>
                            ) : (
                              <>
                                {values.leaves_count == 1 ? (
                                  <FormSelect
                                    placeholder="Select Full/ Half Day"
                                    label="Full/ Half Day"
                                    name="leave_count_same_day"
                                    options={fullHalfDayOptions}
                                    getOptionLabel={(option: any) =>
                                      option.value
                                    }
                                    getOptionValue={(option: any) => option.key}
                                    onChange={(name: string, value: string) => {
                                      setFieldValue(name, value);
                                    }}
                                    value={values.leave_count_same_day}
                                    error={errors.leave_count_same_day}
                                    touched={touched.leave_count_same_day}
                                  />
                                ) : (
                                  <InputText
                                    controlId="leaves_count"
                                    label="No. of Days"
                                    placeholder="0"
                                    isDisable
                                    touched={touched.leaves_count}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    errorsField={errors.leaves_count}
                                    value={values.leaves_count}
                                  />
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={uType == "rm" ? 12 : 8}
                            className={`${uType != "rm" && "mb-3"}`}
                          >
                            <InputTextArea
                              controlId="description"
                              label="Description"
                              placeholder="Enter Description"
                              touched={touched.description}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.description}
                              value={values.description}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          {uType == "rm" ? (
                            <button
                              type="button"
                              className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                              onClick={() => onClose(true)}
                            >
                              Cancel
                            </button>
                          ) : (
                            <CustomButtonCancel label="Cancel" />
                          )}
                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={btnLoading}
                            className="fw-semibold fs-13 text-white mw-60 mt-2"
                            variant="primary"
                          >
                            Submit
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DeletePopup
                        isOpen={isDeletePopupOpen}
                        onClose={() => setDeletePopupOpen(false)}
                        onDelete={() => saveEmployeeLeave(values)}
                        actionType="info"
                        label="Are You Sure?"
                        text="Please confirm that you are applying for leave within the next 30 days. This leave request will be reviewed and either approved or rejected by your line manager."
                      />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
