import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Loader from "./components/common/Loader";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import AppRoutes from "./router";
import { ToastContainer } from "react-toastify";
import "./assets/css/List.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthentication,
  login,
  refreshTokenRequest,
  refreshTokenFailure,
} from "./features/Auth/authSlice";
import { nameBasedProtectedRoutes } from "./router/protected";
import { refreshTokenCALL } from "./services/authService";
import { nameBasePublicRouotes } from "./router/public";
import storage from "./helpers/storage";
import { RootState } from "./features/store";
import { refreshToken } from "./features/Auth/authSlice";
import ability, { buildAbilities, buildAbilitiesForSuperAdmin } from "./role-manager/ability";
import { SUPER_ADMIN } from "./constants/constants";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useSelector(selectAuthentication);
  const token = storage.getRefreshToken();
  const refreshTokenFn = () => {
    const tokenObj = {
      refreshToken: token || "",
    };

    dispatch(refreshTokenRequest());
    refreshTokenCALL(tokenObj)
      .then((response) => {
       
        dispatch(refreshToken(response.data));
        if(response.data.role.role==SUPER_ADMIN){
          ability.update(buildAbilitiesForSuperAdmin())
          
        }else{
          ability.update(buildAbilities(response.data.role.permission))
        }
        // setLoading(false);
        // dispatch(login(response.drefreshTokenata));
        storage.setToken(response.data.tokens.access?.token);
        storage.setRefreshToken(response.data.tokens.refresh?.token);
        //navigate(nameBasedProtectedRoutes.dashborad.path);
      })
      .catch((error) => {
        dispatch(refreshTokenFailure())
        // setLoading(false);
        // toast.error(error.response.data.message);
        //navigate(nameBasePublicRouotes.login.path);
      });
  };
  useEffect(() => {
    if (storage.getToken() !== null) {
      refreshTokenFn();
    } else {
      dispatch(refreshTokenFailure())
      // navigate(nameBasePublicRouotes.login.path);
    }

    return () => {
      // clearInterval(refreshInterval)
    };
  }, []);
  const baseUrl: any = process.env.REACT_APP_BASE_NAME;
  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          <AppRoutes />
          <ToastContainer limit={1} position="bottom-left" draggable/>
        </>
      )}
    </>
  );
}

export default App;
