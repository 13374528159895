import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,  
  GridFilterModel,
  GridActionsCellItem
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import constants, { pageSetting } from "../../../constants/constants";
import { checkAbility, currencyMask } from "../../../utility/common";
import moment from "moment";
import { getFinanceDashboardIncomeExpenses, getFinanceDashboardOutstandingInvoices, getFinanceDashboardPendingAssets } from "../../../services/financeService";
import BlockIcon from "@mui/icons-material/Block";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import TableSkeleton from "../../../components/common/Skeleton/Table.skeleton";

interface AddProps {
  dateRange: any;
  isMutation: boolean;
}

const FinancePendingIncomeExpenses: React.FC<AddProps> = ({dateRange, isMutation}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
 // const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });

  const [orderDateRange, setOrderDateRange] = useState<any>(null);

  const defaultParams = {
    ...dateRange,
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  

  const fetchData = (defaultParams: any) => {
    //console.log(defaultParams, dateRange)
    getFinanceDashboardIncomeExpenses(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "project_no_str",
      headerName: "Project Order No.",
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell(params) {
        return checkAbility(
          PERMISSION_ACCESS.VIEW,
          PERMISSION_MODULE.FINANCE_PROJECTS
        ) ? (
          <Link
            target="_blank"
            className="blue-text text-decoration-none fw-semibold"
            to={`/finance/projects/view/${params.row?.id}`}
          >
            {" "}
            <span title={params.row.project_no_str}>
              {params.row.project_no_str}
            </span>
          </Link>
        ) : (
          params.row.project_no_str
        );
      },
    },
    {
      field: "amount",
      headerName: "Order Value",
      flex: 1,
      sortable: false,
      filterable: false,
      type: "number",
      renderCell(params) {
        return (
          <span>
            {params.row.amount ? currencyMask(params.row.amount) : "-"}
          </span>
        );
      },
    },
    {
      field: "po_amount",
      headerName: "PO",
      flex: 1,
      sortable: false,
      filterable: false,
      type: "number",
      renderCell(params) {
        return (
          <span>
            {params.row.po_amount ? currencyMask(params.row.po_amount) : "-"}
          </span>
        );
      },
    },
    {
      field: "external_procurement_amount",
      headerName: "Procurement Expenses",
      flex: 1,
      sortable: false,
      filterable: false,
      type: "number",
      renderCell(params) {
        return (
          <span>
            {params.row.external_procurement_amount
              ? currencyMask(params.row.external_procurement_amount)
              : "-"}
          </span>
        );
      },
    },
    {
      field: "expense_amount",
      headerName: "Employee Expenses",
      flex: 1,
      sortable: false,
      filterable: false,
      type: "number",
      renderCell(params) {
        return (
          <span>
            {params.row.expense_amount
              ? currencyMask(params.row.expense_amount)
              : "-"}
          </span>
        );
      },
    },
    {
      field: "labour_cost",
      headerName: "Labour Cost",
      flex: 1,
      sortable: false,
      filterable: false,
      type: "number",
      renderCell(params) {
        return (
          <span>
            {params.row.labour_cost
              ? currencyMask(params.row.labour_cost)
              : "-"}
          </span>
        );
      },
    },
    {
      field: "gross_profit_loss",
      headerName: "GP1",
      flex: 1,
      sortable: false,
      filterable: false,
      type: "number",
      renderCell(params) {
        return params.row.gross_profit_loss ? (
          <span className={`fw-bold ${params.row.gross_profit_loss >= 0 ? 'text-primary': 'text-danger'}`}>{currencyMask(Math.abs(params.row.gross_profit_loss))} ({Math.abs(params.row.profit_loss_percentage)}%)</span>
        ) : (
          "-"
        );
      },
    },
  ];  

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };


  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection, dateRange]);

  return (
    <React.Fragment>
      {loading ? (
         <Card className="p-3 w-100 pageContentInner rounded-2">
          <TableSkeleton />
         </Card>
      ) : (
        <div className="flex-grow-1 pageContent">
            <Card className="p-3 w-100 pageContentInner rounded-2">
            <Row>
                      <Col>
                      <h5 className="fs-18 fw-bold info-text mb-2">Income/Expenses</h5>
                      </Col>                      
                    </Row>              
              <Row>
                <Col>
                <DataGrid
                  rows={data}               
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2 table-custom"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"   
                  disableRowSelectionOnClick             
                  pageSizeOptions={[5,10]}
                  localeText={{ noRowsLabel: "No Record Found" }}
                /> 
                </Col>
              </Row>
            </Card>
          </div>        
      )}
    </React.Fragment>
  );
};

export default FinancePendingIncomeExpenses;
