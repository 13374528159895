import { axios } from "../../services/axios";
import {
  ADD_FIELD,
  FIELD_LIST,
  SECTION_DETAILS,
  UPDATE_FIELD,
  GET_ALL_FIELD,
  ADD_CONDITION

} from "../../constants/paths";

//Add section data
export const addFieldAPICall = async (data) => {
  return await axios.post(ADD_FIELD, data);
};

//Get Section data list by field list api
export const getFieldByIdApiCall = async (id) => {
  return await axios.get(`${FIELD_LIST}/${id}`);
};

//Get a section  data by id
export const getSectionByIdApiCall = async (id) => {
  return await axios.get(
    `${SECTION_DETAILS}/${id}`
    //{ headers: loginTokenHeader() }
  );
};

//Update a field sheet data by id
export const updateFieldAPICall = async (id, data) => {
  return await axios.patch(
    `${UPDATE_FIELD}/${id}`,
    data
    //{ headers: loginTokenHeader() }
  );
};


//Get Section data list by field list api
export const getAllFieldByFieldSheetId = async (id) => {
  return await axios.get(`${GET_ALL_FIELD}/${id}`);
};


export const addFieldConditionApi = async (id,data) => {
  return await axios.post(`${ADD_CONDITION}/${id}`, data);
}