import { Card } from "react-bootstrap";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import React from "react";
import { getProjectDetails } from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ProjectProcurementProducts from "./procurement-products";
import ProcurementPoRaised from "./procurement-po";

interface IViewProject {
  setActiveTab?: any;
  activeTabisDone?: string | number;
}

export const ProjectProcurement : FC<IViewProject> = ({
  setActiveTab,
  activeTabisDone,
}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [projectOwnerOpen, setProjectOwnerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState<any>({});
  const [projectControls, setProjectControl] = useState<any>({});
  const [mainProjectControls, setMainProjectControl] = useState<any>({});
  const [isNextEnable, setIsNextEnable] = useState<boolean>(false);
  const [isMutation, setIsMutation] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchProjectDetail(id).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (projectControls?.subsection) {
      const notSubmitData = projectControls?.subsection.find((control: any) => {
        return !control.value;
      });
      const submitNextPage = notSubmitData ? false : true;
      setIsNextEnable(submitNextPage);
    }
  }, [projectControls]);

  const fetchProjectDetail = (id: any) => {
    return new Promise((resolve, reject) => {
      getProjectDetails(id)
        .then((response) => {
          setProjectData(response.data.project);
          getProjectControl(response.data.project);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/projects");
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
            navigate("/projects");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/projects");
          }
        })
        .finally(() => {
          setLoading(false);
          resolve(true);
        });
    });
  };

  const getProjectControl = (projectDataObj: any) => {
    const projectControls = projectDataObj?.project_page_control.find(
      (item: any) => item.key == "PROCUREMENT"
    );
    setMainProjectControl(projectDataObj?.project_page_control);
    setProjectControl(projectControls);
  };

  const handleNextPhaseClick = () => {
    console.log("1212");
    setActiveTab("INSTALL");
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {/*<div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col className="col-md-12 col-xl-4">
                <h1 className="fw-bold h4 mt-2 mb-0">
                  {projectData?.order_no}
                </h1>
              </Col>
              
            </Row>
          </div>*/}
          <div className="flex-grow-1  pageContent  pb-2">
            <Card className=" p-3 w-100 pageContentInner mb-3 project-shadow rounded-4">
              <Row className="align-items-center">
                <Col>
                  {projectControls?.subsection &&
                    projectControls?.subsection.map(
                      (controls: any, index: any) => (
                        <div
                          className={`d-inline-block fs-14 fw-semibold me-4 ${
                            controls.value == 2
                              ? "project-active"
                              : controls.value == 1
                              ? "project-next"
                              : "project-default"
                          }`}
                        >
                          <span></span>
                          {controls.label}
                        </div>
                      )
                    )}
                </Col>
                <Col className="text-end">
                  {isNextEnable ? (
                    <Button
                      className={`fw-semibold fs-12 fs-14 fw-bold btn btn-primary me-2 text-white`}
                      variant="primary"
                      type="button"
                      onClick={handleNextPhaseClick}
                    >
                      Next Phase
                      <ChevronRightIcon />
                    </Button>
                  ) : (
                    <Button
                      className={`fw-semibold fs-12 fs-14 control-label fw-bold btn btn-secondary me-2`}
                      variant="secondary"
                      type="button"
                      onClick={handleNextPhaseClick}
                    >
                      Next Phase
                      <ChevronRightIcon />
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
            <div className="w-100 pageContentInner">
              <Row className="align-items-center">
                <Col>
                  <h1 className="fw-bold h4 mt-2">Procurement</h1>
                </Col>
              </Row>
              <Row>
                <Col className="col-md-12 col-xl-12 ">
                  {projectData && (
                    <ProjectProcurementProducts
                      projectId={id}
                      projectsData={projectData}
                      setMutate={() => fetchProjectDetail(id)}
                    />
                  )}
                </Col>
                <Col className="col-md-12 col-xl-12 ">
                  {projectData && (
                    <ProcurementPoRaised
                      projectId={id}
                      projectsData={projectData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
