import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import {
  sentEmailTemplateList,
  getSentEmailTemplate
} from "../../../services/employeeService";
import Loader from "../../../components/common/Loader";
import ViewIcon from "@mui/icons-material/Visibility";
import Print from "@mui/icons-material/Print";
import { toast } from "react-toastify";
import EmailIcon from '@mui/icons-material/Email';
import AddCircle from "../../../assets/images/add_circle.svg";
import { Card } from "react-bootstrap";
import datetimeFormats, {
  pageSetting,
  pageSizeModel,

} from "../../../constants/constants";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import { checkAbility } from "../../../utility/common";

const EmployeeSentEmails: React.FC<Record<string, number>> = ({employeeID}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  // This state is used to add a special class for print action button when we are fetching signed url for PDF. Adding that class will make that button disabled when a request of getting signed url is in processing.
  const [loadingRows, setLoadingRows] = useState<GridRowId[]>([]); 

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  /**
   * Send email template click handler
   */
  const sendEmailTemplate = () => {
    navigate(`/employees/send-email/${employeeID}`);
  };

  /**
   * View sent email template click handler
   */
  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/employees/sent-email-detail/${id}`);
  };

  /**
   * Download sent email template click handler
   */
  const handlePrintClick = async (id: GridRowId) => {
    setLoadingRows((prevLoadingRows) => [...prevLoadingRows, id]);
    await getSentEmailTemplate(id).then((response:any) => {
        const signedFileUrl = response.file;
        window.open(signedFileUrl, '_blank');
        //console.log(signedFileUrl);
    }).catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      }).finally(() => {
        setLoadingRows((prevLoadingRows) => prevLoadingRows.filter((rowId) => rowId !== id));
      });
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const convertDataToObjects = (data:any) => {
    return data.map((item:any) => ({
      ...item,
      created_at: new Date(item.created_at),
    }));
  };

  /**
   * Fetch sent emails from server
   * @param {defaultParams}
   */
  const fetchData = (defaultParams: any) => {
    sentEmailTemplateList(employeeID,defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet)
        setData(convertData);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    console.log("filterModel", filterModel);

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'serialNumber',
      headerName: 'S. No.',
      sortable: true,
      filterable: false,
    },
    {
      field: "email_subject",
      headerName: "Subject",
      flex: 1.5,
      sortable: true,
    },
    { 
      field: "created_at", 
      headerName: "Date", 
      flex: 1, 
      sortable: true,
      type: 'date',
      renderCell(params) {
        return (
          <span>
            {moment(params.row.created_at).format(datetimeFormats.dateFormats.slashSeparatedDate)}
          </span>
        );
      }
    },
    {
      field: "cc_receivers",
      headerName: "CC",
      flex: 2,
      sortable: true,
    },
    {
      field: "$sender.full_name$",
      headerName: "Sender",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return  <span title={params.row.sender.full_name}>{params.row.sender.full_name}</span>
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.5,
      cellClassName: "actions",
      getActions: ({ id }) => [
        <div className="table-tags d-flex ">
            <div className={`cursor-pointer me-3 ${loadingRows.includes(id) ? 'grid-action-cell-item-disabled':''}`} onClick={(event) => handlePrintClick(id)}>
              <Print className="text-primary" />
            </div>
            <div className="me-3 cursor-pointer" onClick={handleViewClick(id)}>
              <ViewIcon className="text-primary" />
            </div>
        </div>
      ],
    },
  ];
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []); 
    const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <h1 className="fw-bold h4 my-2 info-text fs-20">Emails</h1>
              </div>
              <div>
              {checkAbility(PERMISSION_ACCESS.EDIT,PERMISSION_MODULE.ALL_EMPLOYEE) && <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => sendEmailTemplate()}
                >
                  
                  <EmailIcon className="me-2 fs-15 d-inline-block v-align-bottom"/>
                  Send Email
                </Button>}
              </div>
            </div>
         

          
            <Card className="p-0 w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                />
              </div>
            </Card>
          
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeeSentEmails;
