// ShelfComponent.js
import React from 'react';
import { Card, Col, Row, Image, Spinner } from "react-bootstrap";
import InputText from '../../../components/common/InputText';

interface ShelfComponentProps {
  shelfNumbers: { shelf_number: string }[];
  deleteShelf: (shelf: { shelf_number: string }, index: number) => void;
  shelfNumber: string;
  touched: any;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void; // Update the type here
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorsField: string | undefined;
  handleShelve: () => void;
}

const ShelfComponent: React.FC<ShelfComponentProps> = (
  { shelfNumbers, deleteShelf, shelfNumber,
    touched=false,
    handleBlur,
    handleChange,
    errorsField,
    handleShelve}) => {
  return (
    <Card className="p-3 w-100 pageContentInner mb-3">
                      <h6 className="fs-18 fw-bold mb-3 info-text">
                        Add Shelves
                      </h6>
                      <Row>
      <div className="col-md-3 area-width">
        <InputText
          controlId="shelf_number"
          label="Enter Shelf Number"
          placeholder="Enter Shelf Number"
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          errorsField={errorsField}
          value={shelfNumber}
        />
      </div>
      <div className="green-bg cursor-pointer" onClick={handleShelve}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0418 10.2913H10.2918V15.0413H8.7085V10.2913H3.9585V8.70801H8.7085V3.95801H10.2918V8.70801H15.0418V10.2913Z"
            fill="white"
          />
        </svg>
      </div>
    </Row>
                      <h6 className="fs-18 fw-bold mb-3 info-text">Shelves</h6>
                      {shelfNumbers.map((shelfNumberObj: any, index: any) => (
                        <Row key={index}>
                          <div className="col-md-3 border-bottom">
                            <div className="shelves-list-cover">
                              <div className="fs-14">
                                {shelfNumberObj.shelf_number}
                              </div>
                              {
                                !shelfNumberObj?.has_products && (
                                  <div className="cursor-pointer"
                                    onClick={() =>
                                      deleteShelf(shelfNumberObj, index)
                                    }
                                  >
                                    <svg
                                      width="10"
                                      height="10"
                                      viewBox="0 0 10 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.4 10.0004L0 8.60039L3.6 5.00039L0 1.42539L1.4 0.0253906L5 3.62539L8.575 0.0253906L9.975 1.42539L6.375 5.00039L9.975 8.60039L8.575 10.0004L5 6.40039L1.4 10.0004Z"
                                        fill="#889EAE"
                                      />
                                    </svg>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        </Row>
                      ))}
                    </Card> 
  );
};

export default ShelfComponent;
