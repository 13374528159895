import { Route, Routes } from 'react-router-dom';
import { AddVendor } from './Add';
import AllVendor from './index';
import { ViewVendor } from './View';
import { checkAbility } from '../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../constants/permissionUser';

export const Vendor = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.VENDOR_MANAGEMENT) && (
                <Route path="/" element={<AllVendor />} />
            )}
            {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.VENDOR_MANAGEMENT) && (
                <Route path="/Add" element={<AddVendor />} />
            )}
            {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.VENDOR_MANAGEMENT) && (
                <Route path="/edit/:id" element={<AddVendor />} />
            )}
            {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.VENDOR_MANAGEMENT) && (
                <Route path="/view/:id" element={<ViewVendor />} />
            )}                      
        </Routes>
    )
};