import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {getProjectList} from "../../../services/project.service";
import Loader from "../../../components/common/Loader";
import FileUploadIcon from "../../../assets/images/file-upload.svg";
import BlockIcon from "@mui/icons-material/Block";
import { Card, Col, Row } from "react-bootstrap";
import constants, {
  PROJECT_PREFIX,
  pageSetting,
  pageSizeModel,
  projectStatus,
} from "../../../constants/constants";
import ViewAgenda from '@mui/icons-material/Visibility';

import { checkAbility, currencyMask } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import moment from "moment";
import ExportFinanceProjectsData from "./exportData";

const FinanceProjectList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [exportPopup,setExportPopup] = useState(false);

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`view/${id}`);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getProjectList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model   
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if(filterModel.items[0].field === "project_no"){
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);


  const columns: GridColDef[] = [
    {
      field: "project_no",
      headerName: "Project Order No.",
      flex: 1,
      sortable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params){
        return `${params.row.project_no_str}`
      }
    },
    { field: "name", headerName: "Customer", flex: 1, sortable: true }, 
    {
      field: "order_date",
      headerName: "Order Date",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.order_date),
      renderCell(params) {
        const orderDate = new Date(params.row.order_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "amount",
      type: "number",
      headerName: "Order Value",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row?.amount}>
            {currencyMask(params.row?.amount)}
          </span>
        );
      },
    },
    {
      field: "milestone_amount",
      type: "number",
      headerName: "Payment Milestone Value",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row?.milestone_amount}>
            {currencyMask(params.row?.milestone_amount)}
          </span>
        );
      },
    },    
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      valueOptions: projectStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        const statusName = projectStatus.find(
          (item) => item.key == params.row.status
        );
        return (
          <span
            title={statusName?.value}
            className={`py-1 px-2 rounded-1 ${
              params.row.status == "ob"
                ? "Permanent-tag" :
                params.row.status == "cp"
                ? "info-tag"
                : "Probation-tag"
            } d-inline-block fs-11`}
          >
            {statusName?.value}
          </span>
        );
      },
    }
  ];

  const handleEditViewActionPermission = () => {
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.FINANCE_PROJECTS
    );   

    if (
      checkViewAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if(checkViewAbilityCondition){
            gridActions.push(<GridActionsCellItem
              label="View"
              icon={<ViewAgenda className="fs-16" />}
              className="text-primary"
              showInMenu={false}
              onClick={handleViewClick(id)}
            />)
          }          
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              className="text-primary"
              showInMenu={false}
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]); 
  
  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {exportPopup && (
            <ExportFinanceProjectsData
              isOpen={exportPopup}
              onClose={toggleExportPopup}
              exportType="project"
              label="Export Projects"
              text="Export Projects"
            />
          )}
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Projects</h1>
              </Col>
              <Col className="text-end">
                <button
                  className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                  onClick={toggleExportPopup}
                >
                  <img src={FileUploadIcon} alt="File Upload Icon" />
                </button>               
              </Col>
            </Row>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  disableRowSelectionOnClick                  
                  localeText={{ noRowsLabel: "No Projects found" }}
                  className="border-0 rounded-2"
                />                
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FinanceProjectList;
