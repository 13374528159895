import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FieldArray, Formik, getIn } from "formik";
import { addRolesSchema } from "./validation";
import { toast } from "react-toastify";
import InputText from "../../../components/common/InputText";
import { Card, Col, Row } from "react-bootstrap";
import InputTextArea from "../../../components/common/InputTextArea";
import { getPermission, createRoleApi,getRolesByIdApi,updateRoleApi } from "../../../services/roleService";
import InputCheckBox from "../../../components/common/InputCheckBox";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";

type TPermission = {
  module_id: number;
  title: string;
  permission: {
    title: string;
    permission_id: number;
  }[];
};

type FormValues = {
  role: string;
  permission: string[] | any;
  description : string;
};

export default function AddRoles() {
  const [loading, setLoading] = useState(true);
  const [btnLoading,setBtnLoading] = useState(false)
  const [permissionData, setPermissionData] = useState<TPermission[]>([]);
  const [roleData, setRoleData] =  useState<any>({});
  const navigate = useNavigate()
  let { id } = useParams();
  /**
   * Get roles-permission details from server
   */
  const getPermissionDetails = async () => {
    await getPermission()
      .then((response: any) => {
        setPermissionData(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };


   /**
   * Get roles-permission details from server
   */
   const getRolesDetails = async (id: string) => {
    setLoading(true);
    await getRolesByIdApi(id)
      .then((response: any) => {
     
        setRoleData(response.data)
        setPermissionData(response.data.permission)
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);

        toast(error.data.msg, { type: toast.TYPE.ERROR });
      });
  };

    /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
    const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    
      const formData = {
       ...values
      };
  
      setSubmitting(true);
      setBtnLoading(true);
      if (id) {
        updateRoleApi(id, formData)
          .then((response: any) => {
           
            toast(response.msg, { type: toast.TYPE.SUCCESS });
            navigate('/roles');
            setBtnLoading(false)
          })
          .catch((error: any) => {
            setSubmitting(false);
            setBtnLoading(false);
            if (error.response) {
              // The request was made, but the server responded with a status code that falls out of the range of 2xx
              toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
            } else if (error.request) {
              // The request was made but no response was received
              toast("No response from the server", { type: toast.TYPE.ERROR });
            } else {
              // Something happened in setting up the request that triggered an Error
              toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
            }
          });
      } else
       {
        createRoleApi(formData)
          .then((response: any) => {
            toast(response.msg, { type: toast.TYPE.SUCCESS });
            navigate('/roles');
            setBtnLoading(false)
          })
          .catch((error: any) => {
            setSubmitting(false);
            setBtnLoading(false);
            if (error.response) {
              // The request was made, but the server responded with a status code that falls out of the range of 2xx
              toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
            } else if (error.request) {
              // The request was made but no response was received
              toast("No response from the server", { type: toast.TYPE.ERROR });
            } else {
              // Something happened in setting up the request that triggered an Error
              toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
            }
          });
      }
    };
const handleCancel = () =>{
  navigate(-1)
}

  useEffect(() => {
    if(id){
      getRolesDetails(id)
    }else{
      getPermissionDetails();
    }    
  }, []);
  const initialValues = {
    role: roleData.role?roleData.role:"",
    description: roleData.description?roleData.description:"",
    permission: permissionData,
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">{id?'Edit':'Add New'}  Role</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={addRolesSchema}
              initialValues={initialValues}
              onSubmit={(values: FormValues, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Card className=" p-3 w-100 pageContentInner mb-3">
                    <div className="row">
                      <div className="col-md-4">
                        <InputText
                          controlId="role"
                          label="Title"
                          placeholder="Enter Role Title"
                          touched={touched.role}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.role}
                          name="role"
                          value={values.role}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <InputTextArea
                          controlId="description"
                          label="Description"
                          placeholder="Enter Description"
                          touched={touched.description}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.description}
                          name="description"
                          value={values.description}
                          
                        />
                      </div>
                    </div>
                  </Card>
                  <Card className=" p-3 w-100 pageContentInner">
                  <label className="title-right">Rights</label>

                    <div className="row">
                      <div className="col-md-8">
                        <div className="table-responsive bg-white-table">
                          <table className="table">
                            <thead>
                              <tr className="table-border">
                                <th className="lh-sm control-label fs-14 fw-bold form-label">
                                  Access
                                </th>
                                <th className="lh-sm control-label fs-14 fw-bold form-label">
                                  Create
                                </th>
                                <th className="lh-sm control-label fs-14 fw-bold form-label">
                                  Edit
                                </th>
                                <th className="lh-sm control-label fs-14 fw-bold form-label">
                                  Delete
                                </th>
                                <th className="lh-sm control-label fs-14 fw-bold form-label">
                                  View
                                </th>
                                <th className="lh-sm control-label fs-14 fw-bold form-label">
                                  List
                                </th>
                                <th className="lh-sm control-label fs-14 fw-bold form-label">
                                  Show Sensitive Data
                                </th>
                              
                              </tr>
                            </thead>

                            <FieldArray
                              name="permission"
                              render={(arrayHelpers) => (
                                <tbody>
                                  {values.permission &&
                                    values.permission.length > 0 &&
                                    values.permission.map(
                                      (item: any, index: number) => {
                                        return (
                                          <tr >
                                            <td >
                                              {item.display_name} 
                                            </td>{" "}
                                            {item.permission.map(
                                              (model: any, key: number) => {
                                                return (
                                                  <td className="check-main-cover"> 
                                                                                                          
                                                      <InputCheckBox
                                                      controlId={`permission.${index}.permission.${key}.title`}
                                                      label=""
                                                      placeholder={model.title}
                                                      touched={getIn(
                                                        touched,
                                                        `permission.${index}.permission.${key}.title`
                                                      )}
                                                      disabled={item ?item.action_show ? item.action_show.includes(item?.permission[key]?.title)?false:true:false:false}
                                                      handleBlur={handleBlur}
                                                      handleChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                      ) => {
                                                        setFieldValue(
                                                          `permission.${index}.permission.${key}.checked`,
                                                          e.target.checked
                                                        );
                                                      }}
                                                      value={getIn(
                                                        values,
                                                        `permission.${index}.permission.${key}.checked`
                                                      )}
                                                      errorsField={getIn(
                                                        errors,
                                                        `permission.${index}.permission.${key}.title`
                                                      )}
                                                    />
                                                    {/*  <label className="check-main">
                                                      {item?.permission[key]?.title}
                                                    </label>  */}
                                                  </td>
                                                );
                                              }
                                            )}{" "}
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              )}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <div className="row">
              <div className="col-md-12">
                <div className="text-end my-3">
                  <div className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info" onClick={handleCancel}>
                    Cancel
                  </div>
                 
                  <CustomButton 
                            type="submit"
                            loading={btnLoading}
                            className="fw-semibold fs-13 text-white mw-90 mt-2"
                            variant="primary"
                            >
                                  {id?'Update':'Add'} Role
                          </CustomButton>
                </div>
              </div>
            </div>
                </Form>
              )}
            </Formik>
           

           
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
