import { FC } from 'react';
import { Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaInfoCircle } from 'react-icons/fa';

interface IInputText {
    controlId: string;
    label?: string;
    labelTooltip?: any;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    rightIcon?: any;
    size?: any;
    name?: any;
    isPasswordHintVisible?: boolean;
    isDisable? : boolean
    type? : string;
    mask? : string;
    className? : string
    mbClass? : string
}

/**
 * Input Type Text Reusable Component
 * @date 4/13/2023 - 1:57:35 PM
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; rightIcon: any; size: any; name: any; isPasswordHintVisible: any; type:string;}} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, rightIcon, size, name, isPasswordHintVisible
}
 * @returns
 */

const InputText: FC<IInputText> = ({
    controlId, label,labelTooltip, placeholder, handleChange, handleBlur, errorsField, touched, value, rightIcon, size, name, isPasswordHintVisible,isDisable=false,type="text",mask,mbClass = "mb-3",className
}) => {
    const tooltipMessage = [
        'Must contain 1 Capital letter.',
        'Must contain 1 lower case letter.',
        'Must contain at least 2 numbers.',
        'Must contain at least 1 of the following special characters !$%^()_+*#',
        'Must be a minimum of 15 characters.',
        'No spaces.',
        'Must be different than previous passwords.'
    ]
    const renderTooltip = (props: any) => (
        <Tooltip {...props}>
            <ListGroup as="ul" className="text-start tooltipDottedList fs-12 mb-1">
                {tooltipMessage.map((msg, index)=>{
                    return(
                        <ListGroup.Item key={index} as="li" className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white">{msg}</ListGroup.Item>
                    )
                })}
            </ListGroup>
        </Tooltip>
    );
    return (
        <Form.Group className={`${mbClass} custom-from-group`} controlId={controlId}>
            {label ? (
                <Form.Label className="lh-sm control-label fs-14 fw-bold">
                    {label}
                    {labelTooltip || ''}
                    {isPasswordHintVisible ?
                    <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip}
                    >
                        <Button className="align-baseline d-inline-block ms-1 p-0 text-light" variant="link">
                            <FaInfoCircle />
                        </Button>
                    </OverlayTrigger> : null}
                </Form.Label>
            ) : null}
            <div className={`position-relative ${rightIcon ? 'form-right-icon' : ''}`}>
                <Form.Control className={className} value={value} type={type} placeholder={placeholder} onBlur={handleBlur} onChange={handleChange} isInvalid={!!errorsField && touched} size={size} name={name} disabled={isDisable}/>
                {rightIcon ? (
                    <span className={`align-items-center d-flex end-0 justify-content-center pe-none position-absolute px-3 text-light top-0 ${size ? 'h-35' : 'h-40'}`}>
                        {rightIcon}
                    </span>
                ) : null}
                <Form.Control.Feedback type="invalid">
                    {errorsField}
                </Form.Control.Feedback>
            </div>

        </Form.Group>
    );
}

export default InputText