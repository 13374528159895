import React from "react";

interface PDFViewerProps {
  pdfUrl: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ pdfUrl }) => {
  return (
    <div>
      <object data={`${pdfUrl}`} width="100%" height="600" type="application/pdf"></object>
    </div>
  );
};

export default PDFViewer;
