import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../features/Auth/authSlice";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getInstallationTaskOrdersReceived } from "../../../services/installation.service";
import { ReceivedProduct } from "../../Installer/View/Order-Received/po.type";
import { currencyMask } from "../../../utility/common";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const ProjectOrderRecievedDetails: React.FC<any> = ({ projectId }) => {
  const { user }: any = useSelector(selectAuthentication);
  const userId = user.user.id;
  const [loading, setLoading] = useState(true);
  const [dpData, setDpData] = useState<any>(null);
  const [productArray, setProductArray] = useState<ReceivedProduct[]>([]);
  const [isMutation, setIsMutation] = useState(false);
  const navigate = useNavigate();

  const updateRecevicedProductArray = (dpData: any) => {
    // Create initial values based on products
    const initialPoProducts = dpData ? dpData : [];
    const initialReceivedProducts: any[] = initialPoProducts
      .map((productDe: any) => ({
        inspected_by: userId,
        balance_qty: productDe.qty - (productDe.received_qty | 0),        
        productDetail: productDe.product,
        dispatch_order_product_id: productDe.id,
        dispatch_order_id: productDe.dispatch_order_id,
        product_id: productDe.product_id,
        id: productDe?.dispatchReceivedProducts?.id ?? null,
        isEdit: productDe?.dispatchReceivedProducts?.id ? false : true,
        qty_received: productDe?.dispatchReceivedProducts?.qty_received ?? 0,
        qty_returned: productDe?.dispatchReceivedProducts?.qty_returned ?? 0,
        qty_discard: productDe?.dispatchReceivedProducts?.qty_discard ?? 0,
        quality_status: productDe?.dispatchReceivedProducts?.quality_status
          ? {
              key: productDe?.dispatchReceivedProducts?.quality_status,
              value: productDe?.dispatchReceivedProducts?.quality_status,
            }
          : null,
        return_remarks:
          productDe?.dispatchReceivedProducts?.return_remarks ?? "",
        images: productDe?.dispatchReceivedProducts?.images ?? [],
        imagesPreview: productDe?.dispatchReceivedProducts?.imagesSigned ?? [],
      }))
      .filter(
        (receivedProduct: ReceivedProduct) => receivedProduct.balance_qty !== 0
      );
    console.log(initialReceivedProducts);
    setProductArray(initialReceivedProducts);
    setLoading(false);
  };

  const getDispatchOrderDetails = (id: string) => {
    setLoading(true);
    getInstallationTaskOrdersReceived(id)
      .then((response) => {
        setDpData(response.data);
        updateRecevicedProductArray(response.data);
       })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          // navigate("/good-inward");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          //  navigate("/good-inward");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          //  navigate("/good-inward");
        }
      })
      .finally(() => {
       // setLoading(false);
      });
  };

  useEffect(() => {
    if (projectId) {
      getDispatchOrderDetails(projectId);
    } else {
      setLoading(false);
    }
  }, [projectId, isMutation]);

  useEffect(() => {
    if (dpData) {
      // console.log(dpData);
      updateRecevicedProductArray(dpData);
    }
  }, [dpData]);
  // console.log(initialValues, productArray);
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
        <p className="fw-bold fs-14 ">Goods Received</p>
        
        <div className="d-flex flex-column  pageContainer  w-100 ">
         
          <div>
            {productArray && productArray.length > 0 ?
              productArray.map((entry: any, index: any) => (
                <div key={`order_products_grn${index}`}>
                  <Card className="py-3 px-4 w-100 pageContentInner mb-3 install-detail-card border-radius-10">
                    <Row>
                      <Col md={4} className="">
                        <label className="control-label fs-14 fw-bold">
                          Name
                        </label>
                        <div className="fs-14 mb-3">
                          {entry?.productDetail?.name}
                        </div>
                      </Col>
                      
                      <Col className="col-md-2">
                        <label className="control-label fs-14 fw-bold text-nowrap">
                          Qty Ordered
                        </label>
                        <div className="fs-14 mb-3">{entry?.balance_qty}</div>
                      </Col>
                     
                      <Col className="col-md-2">
                        <label className="control-label fs-14 fw-bold">
                          Qty. Received
                        </label>
                        <div className="fs-14 mb-3">{entry?.qty_received}</div>
                      </Col>
                      <Col className="col-md-2 ">
                        <label className="control-label fs-14 fw-bold">
                          Quality Check
                        </label>
                        <div className="fs-14 mb-3">
                          {entry?.quality_status?.value}
                        </div>
                      </Col>
                      <Col className="col-md-2 text-end fs-14 d-flex align-items-start justify-content-end">
                        <span className="ms-2">
                          {productArray[index]?.quality_status?.key ===
                          "Fail" && (
                            <Button
                              type="button"
                              className="rounded-5 btn btn-primary text-white w-32 h-32 p-0 mt-2 arrow-pink"
                            >
                              <ExpandLessIcon />
                            </Button>
                          ) /* : (
                            <Button
                              type="button"
                              className="rounded-5 btn btn-secondary text-white w-32 h-32 p-0 arrow-gray mt-2"
                            >
                              <ExpandMoreIcon />
                            </Button>
                          ) */}
                        </span>
                      </Col>
                    </Row>
                    {productArray[index]?.quality_status?.key === "Fail" && (
                      <>
                      <Row className="pt-2 pb-0">
                        <Col className="col-auto w-105">
                          <label className="control-label fs-14 fw-bold">
                            Qty. Returned
                          </label>
                          <div className="fs-14 mb-3">
                            {entry?.qty_returned}
                          </div>
                        </Col>
                        <Col className="col-auto w-105">
                          <label className="control-label fs-14 fw-bold">
                            Qty. Discard
                          </label>
                          <div className="fs-14 mb-3">{entry?.qty_discard}</div>
                        </Col>
                      </Row>
                      <Row className="pt-2 pb-0">
                        <Col className="col-md-10 pb-2">
                          <div className="bg-body-secondary p-3 rounded">
                            <label className="control-label fs-14 fw-bold w-100">
                              Return Reason
                            </label>
                            <span>{productArray[index]?.return_remarks}</span>
                          </div>
                        </Col>
                        <Col className="col-md-12">
                          <h3 className="fs-18 fw-bold info-text mt-2">
                            Products Photos
                          </h3>
                        </Col>
                        <Col className="col-md-12 d-flex good-inward-box-cover">
                          {productArray[index].imagesPreview.map(
                            (imagePath: any, imageIndex: any) => (
                              <div key={`returnImage_${index}_${imageIndex}`}>
                                <label className="file-box good-inward-box-cover me-3">
                                  <span>
                                    {" "}
                                    <Image
                                      className="img-fluid"
                                      src={
                                        imagePath
                                          ? imagePath
                                          : AddPhotoAlternate
                                      }
                                      alt="Logo"
                                    />
                                  </span>
                                </label>
                              </div>
                            )
                          )}
                        </Col>
                      </Row>
                      </>
                    )}
                  </Card>
                </div>
              ))
            :
            (
              <div className="fw-bold">No Products Received.</div>
            )
            }
          </div>
        </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ProjectOrderRecievedDetails;
