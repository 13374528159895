import { axios } from "../services/axios";
import {
  POSTALCODE_LIST,
  CREATE_POSTALCODE,
  UPDATE_POSTALCODE,
  DELETE_POSTALCODE,
  GET_POSTALCODE,
  ASSIGN_PROJECT_OWNER
} from "../constants/paths";

export type CreatePostalcodeDTO = {
  code: string;  
  project_owner: string[];
};

export type AssignProjectOwnerDTO = {
  postal_code_ids: number[];  
  project_owner: any;
};
export const createPostalcodeApi = (data: CreatePostalcodeDTO): Promise<any> => {
  return axios.post(CREATE_POSTALCODE, data);
};

export const getPostalcodeList = (formData: any): Promise<any> => {
  return axios.get(POSTALCODE_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};


/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updatePostalcodeApi = (
  id: number,
  formData: CreatePostalcodeDTO
): Promise<any> => {
  return axios.put(UPDATE_POSTALCODE + "/" + id, formData);
};


/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deletePostalCodeApi = (id: string | number | null): Promise<any> => {
  return axios.delete(DELETE_POSTALCODE + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const getPostalCodes = (params:any|null ): Promise<any> => {
  return axios.get(GET_POSTALCODE,{params});
};

export const assingProjectOwnerApi = (data: AssignProjectOwnerDTO): Promise<any> => {
  const transformData = {
    postal_code_ids: data?.postal_code_ids,
    project_owner_id: data?.project_owner?.key    
  };
  return axios.post(ASSIGN_PROJECT_OWNER, transformData);
};
