import { axios } from "../services/axios";
import {
    PROJECT_ASSIGN_TEAM_AND_DETAILS,
    TASK,
  } from "../constants/paths";
 

//create task api call
export const addTaskApiCall = (formData: any): Promise<any> => {
    return axios.post(`${TASK}`, formData);
};


//get task api call
export const getTaskApiCall = (params: any): Promise<any> => {
    return axios.get(`${TASK}`, { params });  
};

//update task api call
export const updateTaskApiCall = (formData: any, id: any): Promise<any> => {
    return axios.put(`${TASK}/${id}`, formData);
};


//get team member by project id api call
export const getProjectAssignTeamAndDetailsAPi = (projectId: number | any): Promise<any> => {
    return axios.get(`${PROJECT_ASSIGN_TEAM_AND_DETAILS}/${projectId}`);
  };

//get my team today task api
export const getMyTeamTodayTask = (): Promise<any> => {
    return axios.get(`${TASK}/my-team-today-task`);
};

// get my team task in progress api
export const getMyTeamInProgressTask = (dateRange?:string): Promise<any> => {
    return axios.get(`${TASK}/my-team-inprogress-task`, { params: { dateRange } });
};

// get my team previous task api
export const getMyTeamPreviousTask = (dateRange?:string): Promise<any> => {
    return axios.get(`${TASK}/my-team-previous-task`, { params: { dateRange } });
}

export const getProjectLocationsApi = ():Promise<any> =>{
    return axios.get(`${TASK}/get-project-location`);
}

export const getDayChecklistApi = (id: any):Promise<any> =>{
    return axios.get(`${TASK}/get-day-checklist/${id}`);
}

//get my previous task
export const getMyPreviousTask = (dateRange?:string): Promise<any> => {
    return axios.get(`${TASK}/my-previous-task`, { params: { dateRange } });
};


//delete task api call  
export const deleteTaskApiCall = (id: any): Promise<any> => {
    return axios.delete(`${TASK}/${id}`);
};

//update mark as complete task api call
export const markAsCompleteTaskApiCall = (id: any): Promise<any> => {
    return axios.put(`${TASK}/mark-as-complete/${id}`);
};

export const getEmployeeUnderPM =(): Promise<any> => {
    return axios.get(`${TASK}/get-all-employee-userpm`);
} 