
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {  useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { Card, Col, Image, Row, Spinner } from "react-bootstrap";

import EditIcon from "@mui/icons-material/Edit";
import Button from "react-bootstrap/Button";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CallIcon from '@mui/icons-material/Call';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InputSwitchBox from "../../components/common/InputSwitchBox";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  getEmployeeDetailsApi,
  updateEmployeeStatusApi,
} from "../../services/employeeService";
import { getProfileAPI } from "../../services/authService";
import Loader from "../../components/common/Loader";
import { selectAuthentication } from "../../features/Auth/authSlice";
import EmployeeDetails from './../Employees/View/EmployeeDetails';
import UserPlaceholderImage from "../../assets/images/user-placeholder-image.png";

export default function ViewProfile() {
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState<any>({});
  const [userPhoto, setUserPhoto] = useState(null);
  const navigate = useNavigate();
  const { user }: any = useSelector(selectAuthentication);

  let { id, tabKey } = useParams<string>();
  if(tabKey === undefined){
    tabKey = 'home';
  }

  const employeeDetails = employee?.employeeDetails ? employee?.employeeDetails : {};
  const kinDetails = employee?.kinDetails ? employee?.kinDetails : {};
  const employeeBankDetails = employee?.emplyeeBankDetails ? employee?.emplyeeBankDetails : {};

  /**
   * Get employee details from server
   * @async
   * @param {id} ID of employee
   */
  const getEmployeeDetail = async (id: string) => {
    setLoading(true);
    await getProfileAPI(id)
      .then((response: any) => {
        setEmployee(response.data);
        setUserPhoto(response.usePhoto);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  // Edit handler to navigate to edit employee page
  const handleEditClick = (id: string | undefined) => () => {
    navigate(`/profile/edit`);
  };

  useEffect(() => {
    getEmployeeDetail(user.user.id);
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">My Profile</h1>
              </div>
              <Button
                className="fw-semibold fs-12 text-white  btn btn-primary"
                variant="primary"
                type="button"
                onClick={handleEditClick(id)}
              >
                <EditIcon className="me-2 fs-15" />
                Edit Profile
              </Button>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" w-100 pageContentInner mb-3">
              <div className="d-flex flex-column h-100 rounded-0">
                <div className="p-3 ">
                  <div className="row">
                    <div className="col-auto">
                      <div className="file-box"><span>
                      {userPhoto ? (
                        <Image className="img-fluid admin-profile-box" src={userPhoto} alt="Employee Image"  />
                        ) : (
                        <Image className="img-fluid admin-profile-box" src={UserPlaceholderImage} alt="No Image" />)
                        }
                        </span></div>
                      </div>
                      <div className="col-md-10">
                        <div className="row">
                          <div>
                            <h1 className="fw-bold h4 my-2">{`${employee.first_name} ${employee.last_name}`}
                              <span title="Conditional Onboarding" className="py-1 px-2 rounded-1 Onboarding-tag d-inline-block fs-11">{employeeDetails.designation}</span>
                            </h1>
                          </div>
                          <div className="col-md-2">
                            <label className="control-label fw-bold">Department</label>
                            <p className="fs-14 mb-2">{employeeDetails.department}</p>
                          </div>
                          <div className="col-md-2">
                            <label className="control-label fw-bold">Reporting Manager </label>
                            <p className="fs-14 mb-2">{employeeDetails?.manager?.full_name}</p>
                          </div>
                          <div className="col-md-4">
                            <label className="control-label fw-bold">Address</label>
                            <p className="fs-14 mb-2">{`${employeeDetails.address}, ${employeeDetails.city}, ${employeeDetails.state}, ${employeeDetails.postal_code}`}</p>
                          </div>
                          <div className="col-md-2">
                            <label className="control-label fw-bold">Contact No. </label>
                            <p className="fs-14 mb-2">{employee.phone_no}</p>
                          </div>
                          <div className="col-md-10">
                            <label className="control-label fw-bold">Job Description</label>
                            <p className="fs-14 mb-2">{employeeDetails.job_description}</p>
                          </div>
                        </div>
                      </div>
                  </div>
                  
                </div>
              </div>
            </Card>
            
            <EmployeeDetails employeeDetails={employeeDetails} kinDetails={kinDetails} employeeBankDetails={employeeBankDetails} source={'profile'} />

          </div>
        </div>
      )}
    </React.Fragment>
  );
}
