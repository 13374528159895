import * as yup from "yup";
import { useParams } from "react-router-dom";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  MAXIMUM_500_CHAR,
  ALPHA_NUMERIC_ALLOW
} from "../../../validations/ValidationErrors";
import {
  CheckLetterTemplateExists,
  checkTemplateExistDTO
} from "../../../services/letterTemplateService";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";

// This function is going to validate the uniqueness of title. This is a curried function.
const uniqueValidation = (id: string | number | undefined) => async (value:string) => {
  let checkTemplatePayload:checkTemplateExistDTO;
  if (id) {
    checkTemplatePayload = { title: value, id: id };
  } else {
    checkTemplatePayload = { title: value };
  }
  const responses = await CheckLetterTemplateExists(checkTemplatePayload);
  const exists = responses.exists;
  return !exists;
};

export const addSchema = (id: string | number | undefined) => {
  return yup.object({
    title : yup.string().trim().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).required().label('Title').test('unique', 'Template with this title already exist.', uniqueValidation(id)),
    type: yup.object().required().label("Type"),
    description :  yup.string().trim().required().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).required().min(3,MINIMUM_3_CHAR).max(500,MAXIMUM_500_CHAR).label("Description"),
    email_subject: yup.string().when('type.key', {
      is: 'Email Template',
      then: (schema) => yup.string().trim().required().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(500,MAXIMUM_500_CHAR).label('Email Subject'),
      otherwise: (schema) => yup.string(),
    }),
    email_body: yup.string().when('type.key', {
      is: 'Email Template',
      then: (schema) => yup.string().required('Email Body is required'),
      otherwise: (schema) => yup.string(),
    }),
    file: yup.string().when('type.key', {
      is: 'Form Template',
      then: (schema) => yup.string().required('Form Template is required'),
      otherwise: (schema) => yup.string(),
    })
  });
}
