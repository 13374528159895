
import { CreatePassword } from "../pages/Auth/CreatePassword";
import { ForgetPassword } from "../pages/Auth/ForgetPassword";
import { Login } from "../pages/Auth/Login";
import { ResetPassword } from "../pages/Auth/ResetPassword";
// import { MultifactorAuth } from "../pages/Auth/MultifactorAuth";


/**
 * Public Routes Available in App
 * @type {{}}
 */
export const publicRoutes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "forgot-password",
    element: <ForgetPassword />,
  },
  {
    path: "reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "create-password/:token",
    element: <CreatePassword />,
  },
];

export const nameBasePublicRouotes = {
   login : {
    path: "/login",
    element: <Login />,
  },
}