import { Route, Routes } from "react-router-dom";
import SurvyList from "./index";
import PreviousSurvey from "./previousSurvey";
import { checkAbility } from "../../utility/common";
import SurveyView from "./viewSurvey";

import CompletedSurveyView from "./viewCompletedSurvey";
import SurveyProcess from "./SurveyProcess";
import StartSurvey  from "./Start_Survey/startSurvey";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { Login } from "../Auth/Login";

export const Survey = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.SURVEY) && (
        <Route path="/" element={<SurvyList />} />
      )}
       {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.SURVEY) && (
        <Route path="/previous-survey" element={<PreviousSurvey />} />
      )}
      {checkAbility(PERMISSION_ACCESS.VIEW,PERMISSION_MODULE.SURVEY) && (
        <Route path="/view-survey/:id" element={<SurveyView />} />
      )}
       {checkAbility(PERMISSION_ACCESS.VIEW,PERMISSION_MODULE.SURVEY) && (
        <Route path="/view-completed-survey/:id" element={<CompletedSurveyView />} />
      )}

      {/* <Route path="*" element={<Login />} /> */}
      <Route path="/start-survey" element={<StartSurvey />} />

      <Route path="/survey-detail" element={<SurveyProcess />} />
    </Routes>
  );
};
