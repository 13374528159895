import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { getInstallationChartData } from '../../../services/dashboardMaster.service';
import BarChartSkeleton from '../../../components/common/Skeleton/BarChart.skeleton';
import { DateRangeInterface } from '../dashboardInterface';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart : React.FC<DateRangeInterface> = ({orderDateRange}) => {
  const [loading, setLoading] = React.useState(true);
  const [chartData, setChartData] = React.useState([]);

    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      fetchData(signal);
      return () => {
        controller.abort();
      };
          
    }, [orderDateRange]);
    const fetchData = (signal:any) => { 
      getInstallationChartData(signal,orderDateRange).then((res) => {  
        setChartData(res.data);  
        setLoading(false);
      }
    ).catch((error) => {
      console.log(error);
      setLoading(false);
    });


    }

  const data = {
    labels:  chartData.map((item: any)=>item.month), 
    datasets: [
      {
        label: 'Installation',
        data: chartData.map((item: any)=>item.data),  
        fill: false,
        borderColor: 'rgba(0,0,0,1)',
        tension: 0.4, // This makes the line curved
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false, // Disable datalabels for this specific chart
      },
      
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: 'Day of the Week',
          
        },
        
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'I N S T A L L A T I O N',
          color: "#A3A3A3",
          
        },
        
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false

  };

  return (
   <div className='dashbord-lineChartBox'>
     
      {loading  ? (
         <BarChartSkeleton/>
      ) : (
        <Line  data={data} options={options} style={{height:'300px'}} />
      )}
   </div>
  )
};

export default LineChart;
