import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Col, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import InputText from "../../components/common/InputText";
import CustomButton from "../../components/common/CustomButton";
import jobNameIcon from "../../assets/images/work.svg";
import assingToIcon from "../../assets/images/account_circle.svg";
import dateIcon from "../../assets/images/date_range.svg";
import timeIcon from "../../assets/images/schedule.svg";
import jobTypeIcon from "../../assets/images/work_alert.svg";
import descriptionIcon from "../../assets/images/planner-info.svg";

import Tactic from "../../assets/images/tactic.svg";

import { IoBagOutline } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import FormSelect from "../../components/common/FormSelect";
import InputDatePicker from "../../components/common/InputDatePicker";
import { FaCalendarAlt } from "react-icons/fa";
import InputTimePicker from "../../components/common/InputTimePicker";

import { IoMdTime } from "react-icons/io";
import { jobTypePlanner } from "../../constants/constants";
import InputTextArea from "../../components/common/InputTextArea";
import moment from "moment";
import { addTaskApiCall, updateTaskApiCall } from "../../services/task.service";
import Loader from "../../components/common/Loader";
import { toast } from "react-toastify";
import InputCheckBox from "../../components/common/InputCheckBox";
import DeletePopup from "../../components/common/deletePopup";
import FormAsyncSelect from "../../components/common/AsyncSelect/FormAsyncSelect";
import { getOrderNumberListApi } from "../../services/dealService";

const AddScheduleModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate,
  memberList,
  selectedTask,
  projectID,
}) => {
  const [formData, setFormData] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [verifyPopupOpen, setVerifyPopupOpen] = useState(false);
  const [verifyMsg, setVerifyMsg] = useState("");

  const formikRef = useRef(null);
  const handleClose = () => {
    setIsOpenScheduleModal(false);
  };

  const id = selectedTask?.event?.id ?? null;

  useEffect(() => {
    if (isOpenScheduleModal) {
      setFormData({
        ...formData,
        start: scheduleStartDate,
        end: scheduleEndDate,
      });
    } else {
      setScheduleStartDate(null);
      setScheduleEndDate(null);
    }
  }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

  const initialValues = {
    title: selectedTask?.event?.title ?? "",
    assign_to: selectedTask?.event?.extendedProps?.assignee
      ? memberList.find(
          (member) =>
            member.user_id === selectedTask?.event?.extendedProps?.assignee.id
        )
      : null,
    start_date: selectedTask?.event?.start
      ? moment(selectedTask?.event?.start)
      : moment(scheduleStartDate),
    start_time: selectedTask?.event?.start
      ? moment(selectedTask?.event?.start)
      : moment(scheduleStartDate).set({ hour: 8, minute: 0 }),
    end_date: selectedTask?.event?.end
      ? moment(selectedTask?.event?.end)
      : moment(scheduleEndDate),
    end_time: selectedTask?.event?.end
      ? moment(selectedTask?.event?.end)
      : moment(scheduleEndDate).set({ hour: 17, minute: 0 }),
    job_type: selectedTask?.event?.extendedProps?.job_type
      ? jobTypePlanner.find(
          (type) => type.key === selectedTask?.event?.extendedProps?.job_type
        )
      : null,
    description: selectedTask?.event?.extendedProps?.description ?? "",
    status: selectedTask?.event?.extendedProps?.status ?? "",
    orderNumber: selectedTask?.event?.extendedProps?.project?.id
      ? {
          id: selectedTask?.event?.extendedProps?.project?.id,
          name: `${selectedTask?.event?.extendedProps?.project?.project_no_str}  ${selectedTask?.event?.extendedProps?.project?.name} `,
        }
      : projectID,
  };

  const addSchema = Yup.object().shape({
    title: Yup.string().trim().min(3).max(30).required("Job Name"),
    assign_to: Yup.object().required("Assign To"),
    orderNumber: Yup.object().required("Project is required"),
    start_date: Yup.date().required("Start Date"),
    start_time: Yup.string().trim().required("Start Time"),
    end_date: Yup.date()
      .required("End Date")
      .min(Yup.ref("start_date"), "End Date should be greater than Start Date"),
    //end_time: Yup.string().trim().required("End Time"),
    //if end_date is same as start_date then end_time should be greater than start_time
    end_time: Yup.string()
      .required("End time is required")
      .test(
        "is-greater",
        "End time must be later than start time if dates are the same",
        function (value) {
          const { start_date, end_date, start_time, end_time } = this.parent;
          const startTime = moment(start_time).format("HH:mm:ss");
          const endTime = moment(end_time).format("HH:mm:ss");
          //console.log("start_date",start_date, end_date, startTime, endTime)
          if (
            start_date &&
            end_date &&
            new Date(start_date).getTime() === new Date(end_date).getTime()
          ) {
            let res =
              value &&
              start_time &&
              moment(endTime, "HH:mm:ss").isAfter(
                moment(startTime, "HH:mm:ss")
              );
            return res;
          }
          return true;
        }
      ),

    job_type: Yup.object().required("Job Type"),
    description: Yup.string().trim().min(3).max(255).required("Description"),
  });
  const submitForm = (values, actions) => {
    console.log(values);
    setBtnLoading(true);
    if (!id) {
      //remove status form values if new task
      delete values.status;
    }
    const formatedValues = {
      ...values,
      start_date: moment(values.start_date).format("YYYY-MM-DD"),
      start_time: moment(values.start_time).format("HH:mm:ss"),
      end_date: moment(values.end_date).format("YYYY-MM-DD"),
      end_time: moment(values.end_time).format("HH:mm:ss"),
      assign_to: values.assign_to.user_id,
      job_type: values.job_type.key,
      project_id: values.orderNumber.id,
      isVerified: isVerified,
    };

    delete formatedValues.orderNumber;

    if (id) {
      updateTaskApiCall(formatedValues, id)
        .then((res) => {
          toast.success(res.msg);
          setVerifyPopupOpen(false);
          handleClose();
        })
        .catch((err) => {
          if (err.response.status == 403) {
            toast.error(err.response.data.message);
          } else {
            setVerifyMsg(err.response.data.message);
            setVerifyPopupOpen(true);
          }
        })
        .finally(() => {
          setBtnLoading(false);
        });
    } else {
      addTaskApiCall(formatedValues)
        .then((res) => {
          toast.success(res.msg);
          setVerifyPopupOpen(false);
          handleClose();
        })
        .catch((err) => {
          //if status is 403 then show error in the toast
          if (err.response.status == 403) {
            toast.error(err.response.data.message);
          } else {
            setVerifyMsg(err.response.data.message);
            setVerifyPopupOpen(true);
          }
        })
        .finally(() => {
          setBtnLoading(false);
        });
    }
  };

  const getOrderNumber = (params) => {
    const exportType = "project";
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType,
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  return (
    <>
      <Modal
        show={isOpenScheduleModal}
        onHide={handleClose}
        contentClassName="border"
        className="assign-modal-main"
        centered
      >
        <>
          <Loader loading={btnLoading} />
          <Modal.Header
            closeButton
            closeVariant={undefined}
            className="bg-body-white px-x1 border-bottom-0 pb-0"
          >
            <Modal.Title className="fs-18 info-text fw-bold">
              Assign Job
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-x1">
            <Formik
              validationSchema={addSchema}
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                submitForm(values, actions);
              }}
              innerRef={formikRef}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                touched,
                isValid,
                errors,
              }) => (
                <Form
                  className="add-schedule-modal-form"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Row>
                    
                    <Col md={12}>
                      <div className="d-flex add-schedule-modal-job-type">
                        <div className="me-4 text-primary mt-1">
                          <img src={jobNameIcon} />
                        </div>
                        <InputText
                          controlId="title"
                          label=""
                          placeholder="Job Name"
                          touched={touched.title}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.title}
                          value={values.title}
                        />
                      </div>
                    </Col>
                    {!id && (
                      <Col md={12}>
                        <div className="d-flex">
                          <div className="me-4 text-primary mt-1">
                            <img src={Tactic} className="w-16" />
                          </div>
                          <div className="w-100">
                            <FormAsyncSelect
                              id=""
                              name="orderNumber"
                              label=""
                              placeholder="Project"
                              isAsync
                              getOptions={getOrderNumber}
                              value={values.orderNumber}
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                              }}
                              error={errors.orderNumber}
                              touched={touched.orderNumber}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col md={12}>
                      <div className="d-flex">
                        <div className="me-4 text-primary mt-1">
                          <img src={assingToIcon} />
                        </div>
                        <FormSelect
                          placeholder="Assign to"
                          label=""
                          name="assign_to"
                          options={memberList}
                          getOptionLabel={(option) =>
                            `${
                              option.full_name
                                ? option.full_name
                                : option.user.full_name
                            } ${
                              option.user
                                ? `(${option.user?.employeeDetails?.designation})`
                                : ""
                            }`
                          }
                          getOptionValue={(option) => option.user_id}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          value={values.assign_to}
                          error={errors.assign_to}
                          touched={touched.assign_to}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="d-flex">
                        <div className="me-4 text-primary mt-1">
                          <img src={dateIcon} />
                        </div>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="start_date"
                                label=""
                                placeholder="Start Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    readOnly: true,
                                    className: "form-control",
                                    id: "order_date",
                                  },
                                }}
                                value={values.start_date}
                                onChange={(name, newValue) => {
                                  setFieldValue(name, newValue);
                                }}
                                disabledWeekend={true}
                                touched={touched.start_date}
                                errors={errors.start_date}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="end_date"
                                label=""
                                placeholder="End Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    readOnly: true,
                                    className: "form-control",
                                    id: "order_date",
                                  },
                                }}
                                value={values.end_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                disabledWeekend={true}
                                touched={touched.end_date}
                                errors={errors.end_date}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="d-flex">
                        <div className="me-4 text-primary mt-1">
                          <img src={timeIcon} />
                        </div>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3 custom-from-group">
                              <InputTimePicker
                                name="start_time"
                                label=""
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    id: "survey_time",
                                  },
                                }}
                                //set min time is 08:00 AM and max time is 06:00 PM
                                value={values.start_time}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.start_time}
                                errors={errors.start_time}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3 custom-from-group">
                              <InputTimePicker
                                name="end_time"
                                label=""
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    id: "survey_time",
                                  },
                                }}
                                value={values.end_time}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.end_time}
                                errors={errors.end_time}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="d-flex">
                        <div className="me-4 text-primary mt-1">
                          <img src={jobTypeIcon} />
                        </div>
                        <FormSelect
                          placeholder="Job Type"
                          label=""
                          name="job_type"
                          options={jobTypePlanner}
                          getOptionLabel={(option) => option.value}
                          getOptionValue={(option) => option.key}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          value={values.job_type}
                          error={errors.job_type}
                          touched={touched.job_type}
                        />
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="d-flex">
                        <div className="me-4 text-primary mt-1">
                          <img src={descriptionIcon} />
                        </div>
                        <InputTextArea
                          controlId="description"
                          label=""
                          placeholder="Job Descriptions"
                          touched={touched.description}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.description}
                          name="description"
                          value={values.description}
                          className="max-height300 resize-none w-100"
                        />
                      </div>
                    </Col>

                    {id && (
                      <Col md={12}>
                        <div className="d-flex">
                          <InputCheckBox
                            controlId="status"
                            label=" Mark as  Completed"
                            value={values.status == "completed" ? true : false}
                            handleChange={(e) => {
                              console.log("e.target.checked", e.target.checked);
                              if (e.target.checked) {
                                setFieldValue("status", "completed");
                              } else {
                                setFieldValue("status", "pending");
                              }
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <div className="d-flex justify-content-end">
                      <div
                        className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                        onClick={handleClose}
                      >
                        Cancel
                      </div>

                      <button
                        variant="primary"
                        onClick={handleSubmit}
                        className="fw-semibold fs-13 text-white mw-60  mt-2 btn btn-primary"
                        type="button"
                      >
                        {id ? "Update" : "Assign"}
                      </button>
                    </div>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </>
      </Modal>
      {verifyPopupOpen && (
        <DeletePopup
          isOpen={verifyPopupOpen}
          onClose={() => setVerifyPopupOpen(false)}
          onDelete={() => {
            setIsVerified(true);
            if (formikRef.current) {
              formikRef.current.handleSubmit(); // Trigger Formik submit
            }
          }}
          label="Are you sure?"
          text={verifyMsg}
          actionType="checkmark"
        />
      )}
    </>
  );
};

AddScheduleModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired,
};

export default AddScheduleModal;
