// store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Auth/authSlice';


const store = configureStore({
  reducer: {
    auth: authReducer,
    // Add other reducers as needed
  },
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
