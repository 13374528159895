// DeletePopup.tsx
import React, { FC, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import auditProfile from "../../../assets/images/profile-dummy.png";
import contractEdit from "../../../assets/images/contract_edit.svg";
import article from "../../../assets/images/article.svg";
import { getAuditTrialData } from "../../../services/project.service";
import { Scrollbar } from "react-scrollbars-custom";


import moment from "moment";

interface AuditDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: any;
}

const AuditData: FC<AuditDataPopupProps> = ({ isOpen, onClose, projectId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getAuditTrialData(projectId)
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTypeImage = (type: string) => {
    switch (type) {
      case "person":
        return (
          <span>
            <img src={auditProfile} />
          </span>
        );
      case "payment":
        return (
          <span className="bg-primary">
            <CalendarTodayIcon className="text-white fs-16" />
          </span>
        );
      case "contract":
        return (
          <span className="audit-bg-blue">
            <img src={contractEdit} />
          </span>
        );
      case "document":
        return (
          <span className="audit-bg-yellow">
            <img src={article} />
          </span>
        );
      default:
        return (
          <span className="audit-bg-yellow">
            <img src={article} />
          </span>
        );
    }
  };

  return (
    <>
      <Modal
        className="recieve-payment-modal"
        show={isOpen}
        onHide={onClose}
        centered
      >
        <Modal.Body className="position-relative">
          <button
            type="button"
            className="btn-close text-right  btn-option-cover"
            aria-label="Close"
            onClick={onClose}
          ></button>
          <div className="fs-18 fw-bold mb-3 info-text">Trail</div>
          <div className="audit-list-main-bk">
          <Scrollbar            
            className="audit-list-scroll"
          >
            {data && data.length > 0 ? (
              data.map((item: any, index: number) => (
                <div className={`mb-3 ${index===data.length-1?'':'audit-modal-list'} d-flex`} key={index}>
                  <div className="audit-modal-img me-4">
                    <span>{getTypeImage(item.type)}</span>
                  </div>
                  <div className="">
                    <h3 className="fs-14 fw-semibold">{item.message}</h3>
                    <p className="fs-14 gray-text">
                      {moment(item.created_at).fromNow()}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center p-4">No Audit Data Found</div>
            )}          


            </Scrollbar>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuditData;
