import { Route, Routes } from "react-router-dom";
import List from "./index";
import View from "./view";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { Login } from "../Auth/Login";

export const ProfileChangeRequest = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.PROFILE_CHANGE_REQUEST) && (
        <Route path="/" element={<List />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.PROFILE_CHANGE_REQUEST) && (
        <Route path="/view/:id" element={<View />} />
      )}

      <Route path="*" element={<Login />} />

    </Routes>
  );
};
