import * as Yup from "yup";
import { MAXIMUM_30_CHAR } from "../../../validations/ValidationErrors";
const actionValidationSchema = Yup.object().shape({
  field_conditions: Yup.array().of(
    Yup.object().shape({
      actions: Yup.array().of(
        Yup.object().shape({
          action: Yup.object().required().label("Action"),
          field: Yup.object().required().label("Field"),
        })
      ),
      conditions: Yup.array().of(
        Yup.object().shape({
          condition: Yup.object().required().label("Condition"),
          field: Yup.object().required().label("Field"),
          value: Yup.string()
            .trim()
            .required()
            .max(30, MAXIMUM_30_CHAR)
            .label("Value"),
        })
      ),
    })
  ),
});

export { actionValidationSchema };
