import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import FileUploadIcon from "../../../assets/images/file-upload.svg";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";
import AddCircle from "../../../assets/images/add_circle.svg";
import constants, { PoPaymentStatus, PoStatus, pageSetting, pageSizeModel } from "../../../constants/constants";
import { checkAbility } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block";
import {  
  deletePoApi,
  getGrnList,
  getOpeningStocks, getPoList,
} from "../../../services/warehouseService";

import moment from "moment";
import DeletePopup from "../../../components/common/deletePopup";
import ExportGoodInwardData from "./exportData";
import PoListPopup from "./poListPopup";

const GoodsInwardsList: React.FC = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [exportPopup,setExportPopup] = useState(false);
  const [isNewGrnPopup,setIsNewGrnPopup] = useState(false);
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const handleNewPo = () => {
    navigate("add");
  }

  const handleEditClick = (id: GridRowId) => () =>{
    navigate(`edit/${id}`);
  }

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`view/${id}`);
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleDelete = () => {
    setLoading(false);
    deletePoApi(deletedId)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const fetchData = (defaultParams: any) => {
    getGrnList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if(filterModel.items[0].field === "po_number"){
        filterModel.items[0].value = filterModel.items[0].value.replace(/[PO-]/g, '');
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "$purchaseOrder.po_number$",
      headerName: "PO Number",
      flex: 1,
      sortable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params){
        return params.row.purchaseOrder.po_number_str
      }
    },
    {
      field: "gr_number",
      headerName: "Goods Inward Notes",      
      flex: 1,
      sortable: true,      
    },
    {
      field: "remarks",
      headerName: "Remarks",      
      flex: 1,
      sortable: true,   
      renderCell(params){
        return params.row.remarks ? params.row.remarks : "-"
      }   
    },
    {
      field: "received_date",
      headerName: "Goods Inward Date",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.received_date),
      renderCell(params) {
        const orderDate = new Date(params.row.received_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "inspection_date",
      headerName: "Inspection Date",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.inspection_date),
      renderCell(params) {
        if(params.row.inspection_date){
          const orderDate = new Date(params.row.inspection_date);
          return (
            <span>
              {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
            </span>
          );
        }
        else{
          return "-"
        }
      },
    }    
  ];
  
  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.GOOD_INWARD
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.GOOD_INWARD
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.GOOD_INWARD
    );   

    if (
      checkEditAbilityCondition ||      
      checkDeleteAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
              label="View"
              icon={<ViewIcon />}
              showInMenu={false}
              onClick={handleViewClick(id)}
              className="text-primary"
              />,              
            );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setLimit(newPaginationModel.pageSize);
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  const toggleNewGrnPopup = () => {
    setIsNewGrnPopup(!isNewGrnPopup);
  }

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
            <div className="pageHeader px-3 py-2 my-1">
              <Row className="align-items-center">
                <Col>
                  <h1 className="fw-bold h4 mt-2 mb-0">Good Inward</h1>
                </Col>
                <Col className="text-end">
                {exportPopup && <ExportGoodInwardData isOpen={exportPopup} onClose={toggleExportPopup} exportType="deal" label="Export Deals" text="Export Deals" />} 
                  <button
                    className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                    title="Export Good Inward"
                    onClick={toggleExportPopup}
                  >
                    <img src={FileUploadIcon} alt="File Upload Icon" />
                  </button>                 
                  {checkAbility(
                    PERMISSION_ACCESS.ADD,
                    PERMISSION_MODULE.GOOD_INWARD
                  ) && (
                    <>
                    {isNewGrnPopup && (<PoListPopup isOpen={isNewGrnPopup}
                    onClose={toggleNewGrnPopup}/>) }
                    
                    <Button
                      className="fw-semibold fs-12 text-white  btn btn-primary"
                      variant="primary"
                      type="button"
                      onClick={() => toggleNewGrnPopup()}
                    >
                      <img
                        src={AddCircle}
                        className="me-2 fs-15"
                        alt="File Upload Icon"
                      />
                      Add Good Inward
                    </Button>
                    </>
                  )}
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              <Card className="  w-100 pageContentInner">
                <div className="d-flex flex-column  rounded-0 dataGridListMain">
                  <DataGrid
                    rows={data}
                    {...data}
                    columns={columns}
                    rowCount={totalRow}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationModelChange}
                    paginationModel={paginationModel}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    sortingMode="server"
                    pageSizeOptions={pageSizeModel}
                    disableRowSelectionOnClick
                    localeText={{ noRowsLabel: "No Good Inwards Note found" }}
                    className="border-0 rounded-2"
                  />
                  <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Purchase Order"
                  text="Are you sure you want to delete this Purchase Order?"
                />
                </div>
              </Card>
            </div>
          </div>          
        </>
      )}
    </React.Fragment>
  );
};

export default GoodsInwardsList;
