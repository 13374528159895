import React, { FC, useState } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import moreIcon from "../../../../assets/images/more_horiz.svg";
import { formTypeOption } from "../../../../constants/constants";
import CommonNotes from "../commonNotes";
import { Slider } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { Formik } from "formik";
import Loader from "../../../../components/common/Loader";
import { surveyCompleteApi } from "../../../../services/survey/survey.service";
import DeletePopup from "../../../../components/common/deletePopup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SurveyFindingPage: FC<any> = ({
  data,
  survey_id,
  comparePageSubmit,
  setComparePageSubmit,
  onlyView=false
}) => {
  const [templateData, setTemplateData] = useState<any>(data[0]);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [warningPopup, setWarningPopup] = useState(false);
  const navigate = useNavigate()

  const getInitivalValues = (data: any) => {
    let marks: any = [];
    marks = [
      {
        value: data[0].from,
        label: `${data[0].from}`,
      },
      {
        value: data[0].to,
        label: `${data[0].to}`,
      },
    ];
    console.log("marks", marks);
    return marks;
  };
  const handleSubmit = (values: any) => {
     setWarningPopup(true);
  };

  const handleClose = () => {
     setWarningPopup(false);
  }
  const surveyCompleteCall = () => {
    data[0] = templateData;
    const transformData = {
      survey_id: survey_id,
      template : data
     }
       surveyCompleteApi(transformData).then((res) => {
        toast.success(res.msg);
        setWarningPopup(false);
        navigate('/survey')
    }
    ).catch((error) => {
      toast.error(error.response.data.msg);
      setWarningPopup(false);

    });
  }
  const handlePrevious = () => {
    setComparePageSubmit(!comparePageSubmit);
  };
  return (
    <>
    {warningPopup && 
     <DeletePopup
                isOpen={warningPopup}
                onClose={handleClose}
                onDelete={surveyCompleteCall}
                label="Save Survey"
                text="You won't be able to revert this action so please make sure you have reviewed the survey findings before saving."
                actionType="warning"
              />
    }
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldError,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Card className="p-3 w-100  mb-3 survey-finding">
            <h3 className="fs-20 fw-bold info-text mb-3">Survey Findings</h3>

            {templateData.map((item: any, index: number) => {
              if (item.subsections.length) {
                return (
                  <>
                    {item.subsections.map((subItem: any, subIndex: number) => {
                      if (subItem.fields.length) {
                        return (
                          <>
                            {
                              <>
                                {subItem.fields.length &&
                                  subItem.fields.map(
                                    (field: any, fieldIndex: number) => {
                                      if(field.visibility) {
                                        return (
                                          <Card className="p-3 w-100  mb-3">
                                            <div className="d-flex flex-column rounded-0 ">
                                              <Row className="d-flex">
                                                {/* type text */}
                                                { field.type == "text" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <p className="fw-bold mb-0">
                                                        {field.value}
                                                      </p>
                                                    </div>
                                                  </Col>
                                                )}
  
                                                {/* type dropdown */}
                                                {field.type == "dropdown" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <p className="fw-bold mb-0">
                                                        {field?.value?.value}
                                                      </p>
                                                    </div>
                                                  </Col>
                                                )}
  
                                                {/* type slider */}
                                                {field.type == "slider" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <Slider
                                                        aria-label="Always visible"
                                                        defaultValue={field.value}
                                                        step={0}
                                                        marks={getInitivalValues(
                                                          field.data_set
                                                        )}
                                                        max={field.data_set[0].to}
                                                        disabled
                                                        valueLabelDisplay="on"
                                                      />
                                                    </div>
                                                  </Col>
                                                )}
  
                                                {/* type radio_button */}
                                                {field.type == "radio_button" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <p className="fw-bold mb-0">
                                                        {field.value}
                                                      </p>
                                                    </div>
                                                  </Col>
                                                )}
  
                                                {/* type message */}
                                                {field.type == "message" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.default_value}{" "}
                                                    </p>
                                                  </Col>
                                                )}
  
                                                {/* type button */}
                                                {field.type == "button" && (
                                                  <Col className="col-md-11 pe-0">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <label className="browse-cover   survey-browse">
                                                      <div className="d-flex flex-wrap">
                                                        { field.signed_image_set && 
                                                        field.signed_image_set
                                                          .length &&
                                                          field.signed_image_set.map(
                                                            (
                                                              image: any,
                                                              imageIndex: number
                                                            ) => {
                                                              return (
                                                                <div className="survey-image p-0 m-1">
                                                                  <img
                                                                    src={image}
                                                                    height={50}
                                                                    width={50}
                                                                    className="imageFitScal"
                                                                  />
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                    </label>
                                                  </Col>
                                                )}
  
                                                {field.type == "sign_off" && (
                                                  <Col className="col-md-11">
                                                    <p className="fs-14 mb-2">
                                                      {field.title}{" "}
                                                      {field.require && "*"}
                                                    </p>
                                                    <div className="w-100 survery-input">
                                                      <p className="fw-bold mb-0">
                                                        {field.value}
                                                      </p>
                                                      <div>
                                                        <img
                                                          src={
                                                            field.signed_signature
                                                          }
                                                          height={50}
                                                          width={120}
                                                        />
                                                      </div>
                                                    </div>
                                                  </Col>
                                                )}
                                                {!onlyView && 
                                                <Col className="col-md-1 text-end">
                                                  <CommonNotes
                                                    field={field}
                                                    index={index}
                                                    fieldIndex={fieldIndex}
                                                    subIndex={subIndex}
                                                    touched={touched}
                                                    errors={errors}
                                                    values={values}
                                                    templateData={templateData}
                                                    setTemplateData={
                                                      setTemplateData
                                                    }
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                  />
                                                </Col>
                                      }
                                              </Row>
  
                                              {field.notes && (
                                                <Row>
                                                  <Col className="col-md-11">
                                                    <p
                                                      className="fw-bold mb-0 text-danger"
                                                      title={field.notes}
                                                    >
                                                      <InfoIcon /> Note :{" "}
                                                      {field.notes}{" "}
                                                    </p>
                                                  </Col>
                                                </Row>
                                              )}
                                            </div>
                                          </Card>
                                        );
                                      }
                                     
                                    }
                                  )}
                              </>
                            }
                          </>
                        );
                      }
                    })}
                  </>
                );
              }
            })}
          </Card>
          {/* //submit button and previous button */}
          {!onlyView && 
          <div className="d-flex justify-content-between mt-3 border-top pt-3 survery-complete-bottom-button">
            <button
              className="btn btn-outline-info  me-3 fs-14 "
              onClick={handlePrevious}
            >
              <KeyboardArrowLeftIcon className="me-1 fs-18" />
              Previous Page
            </button>
            {btnLoading ? (
              <button className="btn btn-primary text-white fs-14" disabled>
                <Loader isLoading={btnLoading} />
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary text-white fs-14"
              >
                Submit
              </button>
            )}
          </div>
}
        </Form>
      )}
    </Formik>
    </>
  );
};

export default SurveyFindingPage;
