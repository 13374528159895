import { Route, Routes } from 'react-router-dom';
import ProjectList from './index';
import { checkAbility } from '../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../constants/permissionUser';
import { ViewProject } from './View';
import ProjectSurvey from './Survey';
import { EditProject } from './Edit';
import { ProjectProcurement } from './Procurement';
import ProcessStart from './ProcessStart';


export const Projects = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.PROJECTS) && (
                <Route path="/" element={<ProjectList />} />
            )}
            {/* {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.PROJECTS) && (
                <Route path="/Add" element={<AddVendor />} />
            )} */}
            {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.PROJECTS) && (
                <Route path="/edit/:id" element={<EditProject />} />
            )}
            {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.PROJECTS) && (
                // <Route path="/view/:id" element={<ViewProject />} />
                <Route path="/view/:id" element={<ProcessStart />} />
            )}                       
            {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.PROJECTS) && (
                <Route path="/survey/:id" element={<ProjectSurvey />} />
            )}
            {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.PROJECTS) && (
                <Route path="/procurement/:id" element={<ProjectProcurement />} />
            )}
            
                                   
        </Routes>
    )
};