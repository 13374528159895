import React from 'react';
import { Image } from 'react-bootstrap';
import AddPhotoAlternate from "../../assets/images/add_photo_alternate.svg";

import ProfileEditIcon from '../../assets/images/profile-edit-icon.svg';
import { Spinner } from "react-bootstrap";

interface ImageUploadProps {
  imageLoading: boolean;
  imagePreview?: string | null;
  label? : string;
  uploadProfileImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileUploader: React.FC<ImageUploadProps> = ({ imageLoading, imagePreview, uploadProfileImage,label }) => {
  return (
    <>
    {label && <label className="lh-sm control-label fs-14 fw-bold form-label">
    {label}
  </label>}
    <div className="position-relative">
      {imageLoading ? (
        <label className="file-box">
          <span>
            <Spinner />
          </span>
        </label>
      ) : imagePreview ? (
        <label className="file-box">
          <input type="file" id="image-upload" name="image" onChange={uploadProfileImage} accept="image/png, image/gif, image/jpeg" />
          <span>
            <Image className="img-fluid" src={imagePreview} alt="Logo" />
            <div className="profile-edit-icon cursor-pointer">
              <Image className="img-fluid" src={ProfileEditIcon} alt="Logo" />
            </div>
          </span>
        </label>
      ) : (
        <label className="file-box">
          <input type="file" name="image" onChange={uploadProfileImage} accept="image/png, image/gif, image/jpeg" />
          <span>
            {' '}
            <Image className="img-fluid" src={AddPhotoAlternate} alt="Logo" />
            <div className="profile-edit-icon cursor-pointer">
              <Image className="img-fluid" src={ProfileEditIcon} alt="Logo" />
            </div>
          </span>
        </label>
      )}
    </div>
    </>
  );
};

export default FileUploader;
