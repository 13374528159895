import { Card, Image } from "react-bootstrap";
import { Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputText from "../../../components/common/InputText";
import { LoginSchema } from "../../../validations/Auth";
import AddCircle from "../../../assets/images/add_circle.svg";
import { useNavigate, useParams } from "react-router-dom";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import React, { useEffect, useState } from "react";
import { getProductDetailsApi } from "../../../services/productService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import ProductReceived from "./product-received";
import ProductDispatchToSite from "./product-dispatch-to-site";
import { currencyMask } from "../../../utility/common";

export const ViewProduct = () => {
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(AddPhotoAlternate);

  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getProductDetails(id);
    } else {
      navigate(-1);
    }
  }, []);

  /**
   * Get product details from server
   * @async
   * @param {id} ID of product
   */
  const getProductDetails = async (id: string) => {
    setLoading(true);
    await getProductDetailsApi(id)
      .then((response: any) => {
        setProductData(response.data.product);
        if (response.data?.fileSigned) {
          setImagePreview(response.data?.fileSigned);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Product Detail</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Card className=" p-3 w-100 pageContentInner mb-3">
              <Row>
                <Col className="col-md-9 col-xl-10">
                  <Row>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">SKU Number</label>
                      <div className="fs-14 mb-3">{productData?.hs_sku}</div>
                    </Col>
                    <Col>
                      <label className="fw-bold control-label">Product Name</label>
                      <div className="fs-14 mb-3">{productData?.name}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="fw-bold control-label">
                        Product Description
                      </label>
                      <div className="fs-14 mb-3">
                        {productData?.desc ? productData?.desc : "-"}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Category Type</label>
                      <div className="fs-14 mb-3">{productData?.category}</div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">
                        Qty.(Available Stock)
                      </label>
                      <div className="fs-14 mb-3">{productData?.stock_qty}</div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">
                        Fast Moving Products
                      </label>
                      <div className="fs-14 mb-3">
                        {productData?.is_fmp ? "Yes" : "No"}
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Shelf</label>
                      <div className="fs-14 mb-3">
                        {productData?.shelf_id
                          ? productData.shelf?.shelf_number
                          : "-"}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Cost</label>
                      <div className="fs-14 mb-3">{currencyMask(productData?.cost)}</div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Margin Cost</label>
                      <div className="fs-14 mb-3">
                        {currencyMask(productData?.margin_on_cost)}
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Price</label>
                      <div className="fs-14 mb-3">{currencyMask(productData?.price)}</div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">
                        Min re-Order Quantity
                      </label>
                      <div className="fs-14 mb-3">
                        {productData?.is_min_qty_reorder ? "Yes" : "No"}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Minimum Quantity</label>
                      <div className="fs-14 mb-3">{productData?.min_qty}</div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Maximum Quantity</label>
                      <div className="fs-14 mb-3">{productData?.max_qty}</div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Preferred vendor</label>
                      <div className="fs-14 mb-3">
                        {productData?.vendor_id
                          ? productData?.vendor?.vendor_name
                          : "-"}
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <label className="fw-bold control-label">Favourite Item</label>
                      <div className="fs-14 mb-3">
                        {productData?.is_favourite ? "Yes" : "No"}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-md-3 col-xl-2">
                  <label className="file-box">
                    <span>
                      <Image
                        className="img-fluid"
                        src={imagePreview}
                        alt="Logo"
                      />
                    </span>
                  </label>
                </Col>
              </Row>
            </Card>
            <div className="pageHeader py-2 my-1">
              <Row className="align-items-center">
                <Col>
                  <h1 className="fw-bold h4 mt-2 mb-0">Transactions</h1>
                </Col>
              </Row>
            </div>
            <ProductReceived productId={id} />
            <ProductDispatchToSite productId={id} />
          </div>
          
        </div>
      )}
    </React.Fragment>
  );
};
