import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  INVALID_DATE,
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";


export const addSchema = yup.object({
  date_from: yup
    .date()
    .required()        
    .typeError(INVALID_DATE)
    .label('From Date'),
  date_to: yup
    .date()
    .required()
    .min(yup.ref('date_from'), 'To Date must be after From Date')
    .test('same-year', 'From Date and To Date must be in the same financial year', function(value) {
      const { date_from } = this.parent;
      if (!value || !date_from) return true; // If either date is not set, let other validations handle it
      return value.getFullYear() === date_from.getFullYear();
    })
    .typeError(INVALID_DATE)
    .label('To Date'),
  leave_type: yup.object().required().label('Leave Type'),
  appointment_time: yup.string().label("Approintment Time").when("leave_type", {
    is: (status: any) => status && status.is_appointment_type ,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  description: yup.string().trim().required().label('Description'),
});

