// LightboxGallery.tsx
import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";

interface LightboxGalleryProps {
  images: { src: string; alt?: string }[];
  currentIndex: number;
  open: boolean;
  onClose: () => void;
}

const LightboxGallery: React.FC<LightboxGalleryProps> = ({
  images,
  currentIndex,
  open,
  onClose,
}) => {
  return (
    <Lightbox
      open={open}
      close={onClose}
      slides={images}
      index={currentIndex}
      plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Counter, Download]}
      counter={{ container: { style: { top: 0 } } }}
    />
  );
};

export default LightboxGallery;
