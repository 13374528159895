import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function LineSkeleton() {
  return (
   <div className="p-4">
     <Stack spacing={1}>
      <div className="d-flex justify-content-between">
        <div className="lineChartSkeleton d-flex justify-content-around">
          <Skeleton variant="rounded" width={15} height={60} />
          <Skeleton variant="rounded" width={15} height={80} />
          <Skeleton variant="rounded" width={15} height={120} />
          <Skeleton variant="rounded" width={15} height={90} />
          <Skeleton variant="rounded" width={15} height={180} />
          <Skeleton variant="rounded" width={15} height={130} />
          <Skeleton variant="rounded" width={15} height={55} />
          <Skeleton variant="rounded" width={15} height={180} />
          <Skeleton variant="rounded" width={15} height={30} />
          <Skeleton variant="rounded" width={15} height={92} />
          <Skeleton variant="rounded" width={15} height={150} />
          <Skeleton variant="rounded" width={15} height={20} />
          <Skeleton variant="rounded" width={15} height={30} />
          <Skeleton variant="rounded" width={15} height={55} />
          <Skeleton variant="rounded" width={15} height={55} />
          <Skeleton variant="rounded" width={15} height={70} />
          <Skeleton variant="rounded" width={15} height={30} />
          <Skeleton variant="rounded" width={15} height={55} />
          <Skeleton variant="rectangular" width={15} height={120} />
          <Skeleton variant="rounded" width={15} height={100} />
          <Skeleton variant="rectangular" width={15} height={80} />
          <Skeleton variant="rounded" width={15} height={60} />
        </div>
        <div>
          <Stack spacing={2}>
            <Skeleton variant="rounded" width={210} height={30} />
            <Skeleton variant="rounded" width={210} height={30} />
            <Skeleton variant="rounded" width={210} height={30} />
            <Skeleton variant="rounded" width={210} height={30} />
          </Stack>
        </div>
      </div>
    </Stack>
    
   </div>
  );
}
