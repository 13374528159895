import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { getDashboardGoodsMovementApi } from "../../../services/warehouseService";
import { useEffect, useState } from "react";

export const DashboardGoodMovement = () => {
  const [goodMovement, setGoodsMovement] = useState<any>({
    "in_transit": 0,
    "outgoing_delivery": 0
  });

  const getDashboardGoodsMovement = () => {
    // setLoading(true);
    getDashboardGoodsMovementApi()
       .then((response) => {
         setGoodsMovement(response.data);
        // setLoading(false);
       })
       .catch((error) => {
       //  setLoading(false);
       })
       .finally(() => {
        // setLoading(false);
       });
   };
  useEffect(() => {
    getDashboardGoodsMovement();
  }, []);
  return (
    <div className="d-flex flex-column pageContainer  w-100 pb-sm-1 h-100">
      <Card className="p-3 w-100 pageContentInner rounded-3 h-100">
              <h5 className="fs-18 fw-bold info-text mb-4">Goods Movement</h5>
              <div className="bg-today text-white p-3 rounded-2">
                <div className="d-flex justify-content-between align-items-center min-height-105">
                  <div className="fs-18 fw-semibold w-50">Shipment in Transit</div>
                  <div className="fs-40 fw-semibold">{goodMovement?.in_transit}</div>
                </div>
              </div>
              <div className="bg-green mt-2 p-3 rounded-2 text-white">
                <div className="d-flex justify-content-between align-items-center min-height-105" >
                  <div className="fs-18 fw-semibold w-50">Outgoing Delivery</div>
                  <div className="fs-40 fw-semibold">{goodMovement?.outgoing_delivery}</div>
                </div>
              </div>
      </Card>
    </div>
  );
};
