import React, { useEffect, useState } from 'react'; 
import { Card } from 'react-bootstrap';
import { getInstallationCount } from '../../../services/dashboardMaster.service';
import InstallationSkeleton from '../../../components/common/Skeleton/InstallationCounter.skeleton';
import { DateRangeInterface } from '../dashboardInterface';
const InstallationCount:  React.FC<DateRangeInterface> = ({ orderDateRange }) => {
    const [loading, setLoading] = React.useState(true);
    const [installationData, setInstallationData] =  useState({} as any);
    useEffect(() => {
        const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
        
    }, [orderDateRange]);
    const fetchData = (signal:any) => {
        getInstallationCount(orderDateRange,signal).then((res) => {
            setInstallationData(res.data);
            setLoading(false);
        }
        ).catch((err) => {
            console.log(err);
            setLoading(false);
        })

    }
    return (
        <div>
            {loading ? <InstallationSkeleton /> : 
           <>
           <h5 className="fs-18 info-text fw-bold mb-3">Installation</h5>
            <div>
                <Card className='p-3 mb-2 rounded-2 blue-bg-light border-0'>
                    <div className='fs-13 fw-bold blue-text'>Total Installations</div>
                    <p className='fs-26 fw-bold text-black mb-0'>{installationData.totalInstallation}</p>
                </Card>
                <Card className='p-3 mb-2 rounded-2 green-bg-light border-0'>
                    <div className='fs-13 fw-bold green-text'>This Week Installations</div>
                    <p className='fs-26 fw-bold text-black mb-0'>{installationData.thisWeekInstallation}</p>
                </Card>
                <Card className='p-3 mb-2 rounded-2 red-bg-light border-0'>
                    <div className='fs-13 fw-bold red-text'>Overdue Installations</div>
                    <p className='fs-26 fw-bold text-black mb-0'>{installationData.overDueInstallation}</p>
                </Card>
            </div>
            </>
            }
        </div>
    )
}

export default InstallationCount;