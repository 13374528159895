import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Formik, isObject } from "formik";
import {  useSelector } from 'react-redux';
import { addSchema } from "./sendEmailValidation";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import QuillEditor from "../../../components/common/QuillEditor";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import FormSelect from "../../../components/common/FormSelect";
import {
  getEmailTemplates,
  emailTemplatesDTO
} from "../../../services/letterTemplateService";
import {getEmployeeEmailList, employeeEmailsListDTO, sendEmailTemplate, emailTemplateKeywordsReplace} from '../../../services/employeeService';
import {selectAuthentication} from "../../../features/Auth/authSlice";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";

interface selectBox{
    key: string | number;
    value: string;
  };

type FormValues = {
  title: string | selectBox;
  cc_receivers: selectBox[];
  email_subject: string;
  email_body: string;
};

export default function SendEmail() {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState<any>({});
  const [employeeEmailsList, setEmployeeEmailsList] = useState<any>({});
  const [employeeData, setEmployeeData] = useState<any>({});
  const [emailTemplateResponse, setEmailTemplateResponse] = useState<any>();
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<emailTemplatesDTO>();
  const navigate = useNavigate();
  let { id, meeting_id } = useParams();
  const { user }:any = useSelector(selectAuthentication);

  /**
   * Submit Method to call form gets submitted
   * @async
   * @param {FormValues, actions} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setLoading(true);
    const {email_subject, email_body} = values;

    const dataValues = {
        email_subject,
        email_body,
        sender_id: user.user.id,
        receiver_id: employeeData.id,
        letter_template_id: values.title && isObject(values.title) ? (values.title as selectBox).key : '',
        cc_receivers: values.cc_receivers.length > 0 ? values.cc_receivers.map((receiver:selectBox) => receiver.key): [],
        meeting_id: meeting_id !== undefined ? meeting_id : null
    };

    sendEmailTemplate(dataValues)
    .then((response: any) => {
        toast(response.message, { type: toast.TYPE.SUCCESS });
        navigate(`/employees/view-employee/${employeeData.id}/${meeting_id ? 'meetings':'emails'}`);
        setBtnLoading(false);
    })
    .catch((error: any) => {
        setSubmitting(false);
        setBtnLoading(false);
        if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
        });
        } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
        });
        }
    }).finally(() => {
        setLoading(false);
    });
    
  };

  /**
   * Change handler for title field to get corresponding email subject and email body. This function also replaces the keywords inside mail body with the actual values
   * @async
   * @param {FormValues, actions} values
   * @returns {*}
   */
  const handleTitleChange = (name: string, value: selectBox, setFieldValue:any) => {
    const selectedEmailTemplateData = emailTemplateResponse.find((item:emailTemplatesDTO) => item.id === value.key);
    setSelectedEmailTemplate(selectedEmailTemplateData);
    setFieldValue(name, value);
    setFieldValue('email_subject',selectedEmailTemplateData.email_subject);

    const replaceEmailBody = emailTemplateKeywordsReplace(selectedEmailTemplateData.email_body, employeeData, user);
    setFieldValue('email_body',replaceEmailBody);
  };

  /**
   * Cancel handler - redirection to listing
   * @returns {*}
   */
  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if(id){
        Promise.all([getEmailTemplates(), getEmployeeEmailList(id)])
        .then(([templateResponse, employeeResponse]) => {
            // Getting email templates
            setEmailTemplateResponse(templateResponse.data);
            const emailTemplatesData = templateResponse.data.map((item: emailTemplatesDTO) => ({
                key: item.id,
                value: item.title
              }));
            setEmailTemplates(emailTemplatesData);

            // Getting employee emails
            const employeeEmailsData = employeeResponse.data.map((item: employeeEmailsListDTO) => ({
                key: item.email,
                value: item.email
              }));
            setEmployeeEmailsList(employeeEmailsData);

            // Setting current employee data
            setEmployeeData(employeeResponse.selectedUser);

            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            toast(error.response.data.message, { type: toast.TYPE.ERROR });
        });
    }
  }, []);

  
  const initialValues = {
    title: "",
    cc_receivers: (meeting_id !== undefined) ? [{'key':user.user.email,'value':user.user.email}] : [],
    email_subject: "",
    email_body: ""
  };

  const employeeEmail = employeeData ? <span className="py-1 px-2 rounded-1 Onboarding-tag d-inline-block fs-11">{employeeData.email}</span> : '';

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Send Email {(employeeData ? `To ${employeeData.full_name}` : '')}
                {employeeEmail}</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={() => addSchema(id)}
              initialValues={initialValues}
              onSubmit={(values: FormValues, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleBlur,
                values,
                touched,
                isValid,
                errors
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  
                  <Card className="p-3 w-100 pageContentInner">
                    <div>
                      <Row>
                        <div className="col-md-4">
                        <FormSelect
                            placeholder="Select Email Template"
                            label="Email Template"
                            name="title"
                            options={emailTemplates}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: selectBox) => {
                              handleTitleChange(name, value, setFieldValue);
                            }}
                            value={values.title}
                            error={errors.title}
                            touched={touched.title}
                          />
                        </div>
                        <div className="col-md-4">
                          <FormSelect
                            placeholder="Select Receivers"
                            label="CC"
                            name="cc_receivers"
                            options={employeeEmailsList}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            value={values.cc_receivers}
                            error={errors.cc_receivers}
                            touched={touched.cc_receivers}
                            multi={true}
                          />
                        </div>
                      </Row>
                      <Row>
                      <div className="col-md-8">
                          <InputText
                            controlId="email_subject"
                            label="Subject"
                            placeholder="Email Subject"
                            touched={touched.email_subject}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.email_subject}
                            value={values.email_subject}
                          />
                        </div>
                        <div>
                          <QuillEditor
                            controlId="email_body"
                            label="Email Body"
                            placeholder="Enter Email Body"
                            touched={touched.email_body}
                            handleBlur={() => {
                              setFieldTouched('email_body',true)
                            }}
                            handleChange={(name: string, content: any, editor: any) => {
                              editor.getText().trim() ? setFieldValue(name, content): setFieldValue(name, '');
                            }}
                            errorsField={errors.email_body}
                            name="email_body"
                            value={values.email_body}
                            className=" resize-none"
                          />
                        </div>
                      </Row>
                    </div>
                  </Card>
                  

                  <div className="text-end my-3 px-3">
                  <CustomButtonCancel label="Cancel" />
                    <CustomButton
                      type="submit"
                      loading={btnLoading}
                      className="fw-semibold fs-13 text-white mw-60 mt-2"
                      variant="primary"
                    >
                      {id ? "Send" : "Add"}
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
            </div>
        </div>
      )}
    </React.Fragment>
  );
}
