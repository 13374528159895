import React, { useEffect, useState } from "react";
import { Card, Col, Row, Image, Spinner } from "react-bootstrap";

import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../components/common/InputText";
import { projectSchema } from "./validation";
import FormSelect from "../../../components/common/FormSelect";
import { commonMasking } from "../../../constants/constants";
import CustomButton from "../../../components/common/CustomButton";
import { currencyMask, phoneMask } from "../../../utility/common";
import Loader from "../../../components/common/Loader";
import FileUploader from "../../../components/common/FileUploader";
import {
  getAllProductCategory,
  getProductDetailsApi,
  updateProductApi,
  uploadProductImageApi,
} from "../../../services/productService";
import { ProjectData } from "./product.type";
import InputCheckBox from "../../../components/common/InputCheckBox";
import { getAllVendorsName } from "../../../services/vendorService";
import { getWarehouseDetail, getWarehouseShelves } from "../../../services/warehouseService";
import InputTextArea from "../../../components/common/InputTextArea";
import CurrencyInput from "react-currency-input-field";
import InputCurrencyText from "../../../components/common/InputCurrencyText";

interface ProjectOption {
  id: string;
  vendor_name: string;
}

export const EditProduct = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [productData, setProductData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [categoryOption, setCategoryOption] = useState([]);
  const [vendorOption, setVendorsOption] = useState([]);
  const [shelvesOption, setShelvesOption] = useState([]);
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ];
  const maxFileSizeMB = 1;
  const confirmOptions = [
    { key: true, value: "Yes" },
    { key: false, value: "No" },
  ];

  let { id } = useParams();

  /**
   * Submit Method to call when product save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: ProjectData, { setSubmitting }: any) => {
    const formData = {
      ...values
    };
    console.log(formData);
   // return;
    setBtnLoading(true);
    if (id) {
      updateProductApi(id, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/product");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
    }
  };

  const getProductDetails = (id: string) => {
    setLoading(true);
    getProductDetailsApi(id)
      .then((response) => {
        setProductData(response.data.product);
        if (response.data?.fileSigned) {
          setImagePreview(response.data?.fileSigned);
        }
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/product");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/product");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/product");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    Promise.all([fetchCategories(), fetchVendors(), fetchShelves()]).then(
      () => {
        if (id) {
          getProductDetails(id);
        } else {
          setLoading(false);
        }
      }
    );
  }, []);

  /**
   * fetch vendors to list in the vendor drop down
   * @async
   */
  const fetchVendors = async () => {
    return new Promise((resolve, reject) => {
      getAllVendorsName().then((response) => {
        const modifiyOption = response.data.map((item: ProjectOption) => ({
          key: item.id,
          value: item.vendor_name,
        }));
        setVendorsOption(modifiyOption);
        return resolve(true);
      });
    });
  };
  /**
   * fetch Categories to list in the category type drop down
   * @async
   */
  const fetchCategories = async () => {
    return new Promise((resolve, reject) => {
      // const catOption:any = [{
      //   key : 'Flooring', value:'Flooring'
      // }]
      // setCategoryOption(catOption);
      // return resolve(true);
      getAllProductCategory().then((response) => {
        const modifiyOption = response.data.map((item: any) => ({
          key: item.category,
          value: item.category,
        }));
        setCategoryOption(modifiyOption);
        return resolve(true);
      });
    });
  };

  /**
   * fetch Shelves to list in the Shelves drop down
   * @async
   */
  const fetchShelves = async () => {
    return new Promise((resolve, reject) => {
      getWarehouseShelves().then((response) => {
        const modifiyOption = response.data.map(
          (item: any) => ({
            key: item.id,
            value: item.shelf_number,
          })
        );
        setShelvesOption(modifiyOption);
        return resolve(true);
      });
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleCostPrice = (costPrice: any, formValues:any, setFieldValue: any) => {    
    //console.log(costPrice,Number.parseFloat(costPrice),formValues)
    costPrice = costPrice ? costPrice : 0;
    const marginCost = Number.parseFloat(formValues.price)- Number.parseFloat(costPrice);
    setFieldValue('margin_on_cost',marginCost);
  }

  const uploadProfileImage = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    //console.log("file", imageFile);

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setImageLoading(true);
      if (imageFile) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImagePreview(reader.result);
        };

        reader.readAsDataURL(imageFile);
      } else {
        setImagePreview(null);
      }

      // Create FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append("file", imageFile);

      uploadProductImageApi(id,formData)
        .then((response) => {
          setImageLoading(false);
          setFieldValue("image", response.data.file);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };

  const initialValues: any = {
    hs_sku: productData?.hs_sku ? productData?.hs_sku : "",
    name: productData?.name ? productData?.name : "",
    desc: productData?.desc ? productData?.desc : "",
    category: productData?.category
      ? categoryOption.find((item: any) => item.key == productData?.category)
      : null,
    shelf: productData?.shelf_id
      ? shelvesOption.find((item: any) => item.key == productData?.shelf_id)
      : null,
    vendor: productData?.vendor_id
      ? vendorOption.find((item: any) => item.key == productData?.vendor_id)
      : null,
    cost: productData?.cost ? productData?.cost : "",
    margin_on_cost: productData?.margin_on_cost
      ? productData?.margin_on_cost
      : "",
    price: productData?.price ? productData?.price : "",
    min_qty: productData?.min_qty ? productData?.min_qty : "",
    max_qty: productData?.max_qty ? productData?.max_qty : "",
    is_fmp: productData?.is_fmp!== null
      ? confirmOptions.find((item) => item.key == productData?.is_fmp)
      : null,
    is_favourite: productData?.is_favourite ? productData?.is_favourite : false,
    is_min_qty_reorder_notify: productData?.is_min_qty_reorder_notify
      ? productData?.is_min_qty_reorder_notify
      : false,
    image: productData?.image ? productData?.image : "",
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Edit Product</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={projectSchema}
              initialValues={initialValues}
              onSubmit={(values: ProjectData, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-8">
                            <Row>
                              <div className="col-md-6">
                                <InputText
                                  controlId="hs_sku"
                                  label="SKU Number"
                                  placeholder="Enter SKU Number"
                                  touched={touched.hs_sku}
                                  isDisable
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.hs_sku}
                                  value={values.hs_sku}
                                />
                              </div>
                              <div className="col-md-6">
                                <InputText
                                  controlId="name"
                                  label="Product Name"
                                  placeholder="Enter Product name"
                                  touched={touched.name}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.name}
                                  value={values.name}
                                />
                              </div>

                              <div className="col-md-12">
                                <InputTextArea
                                  controlId="desc"
                                  label="Product Description"
                                  placeholder="Enter Product Description"
                                  touched={touched.desc}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.desc}
                                  name="desc"
                                  value={values.desc}
                                  className="max-height300 resize-none"
                                />
                              </div>
                            </Row>
                          </Col>
                          <Col className="col-md-4">
                            <div className="mb-3 custom-from-group">
                              <label className="lh-sm control-label fs-14 fw-bold form-label">
                                Product Photo
                              </label>
                              <div className="position-relative ">
                                <FileUploader
                                  uploadProfileImage={(e) =>
                                    uploadProfileImage(e, setFieldValue)
                                  }
                                  imageLoading={imageLoading}
                                  imagePreview={imagePreview}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Preferred Vendor"
                              name="vendor"
                              options={vendorOption}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.vendor}
                              error={errors.vendor}
                              touched={touched.vendor}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Category Type"
                              name="category"
                              options={categoryOption}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.category}
                              error={errors.category}
                              touched={touched.category}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Shelf"
                              name="shelf"
                              options={shelvesOption}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.shelf}
                              error={errors.shelf}
                              touched={touched.shelf}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Fast Moving Item"
                              name="is_fmp"
                              options={confirmOptions}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.is_fmp}
                              error={errors.is_fmp}
                              touched={touched.is_fmp}
                            />
                          </Col>
                          <Col className="col-md-4">
                          <InputCurrencyText
                            name="cost"
                            label="Cost"
                            placeholder="Enter Cost"                            
                            value={values.cost}
                            onChange={(value, name) =>{
                              setFieldValue("cost", value);
                              handleCostPrice(value, values,setFieldValue);
                            }}
                            touched={touched.cost}
                            errors={errors.cost}
                          />                          
                          
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="margin_on_cost"
                              label="Margin Cost"
                              placeholder="Enter Margin Cost"
                              touched={touched.margin_on_cost}
                              handleBlur={handleBlur}
                              isDisable={true}
                              handleChange={handleChange}
                              errorsField={errors.margin_on_cost}
                              value={currencyMask(values.margin_on_cost)}
                            />
                            <span className="text-muted">
                              Price:{currencyMask(productData?.price)}
                            </span>
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="min_qty"
                              label="Min Stock Level"
                              placeholder="Enter Min Stock Level"
                              touched={touched.min_qty}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.min_qty}
                              value={values.min_qty}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="max_qty"
                              label="Max Stock Level"
                              placeholder="Enter Max Stock Level"
                              touched={touched.max_qty}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.max_qty}
                              value={values.max_qty}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-4">
                            <InputCheckBox
                              controlId="is_min_qty_reorder_notify"
                              label="Send notification on Min Re-Order Qty"
                              touched={touched.is_min_qty_reorder_notify}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.is_min_qty_reorder_notify}
                              value={values.is_min_qty_reorder_notify}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputCheckBox
                              controlId="is_favourite"
                              label="Mark as Favourite Item"
                              touched={touched.is_favourite}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.is_favourite}
                              value={values.is_favourite}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <div
                            className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                            onClick={handleCancel}
                          >
                            Cancel
                          </div>

                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={imageLoading || btnLoading}
                            className="fw-semibold fs-13 text-white mw-60 mt-2"
                            variant="primary"
                          >
                            Update
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
