import * as yup from "yup";
import {
  MINIMUM_3_CHAR,
  MAXIMUM_30_CHAR,
  MAXIMUM_50_CHAR,
  MAXIMUM_500_CHAR,
  MAXIMUM_255_CHAR,
  MAXIMUM_100_CHAR,
  ALPHA_NUMERIC_ALLOW,
  CAPITAL_LETTERS,
  SELECT_ONE_OPTION,
  INVALID_DATE,
  ALPHABETS_ALLOW
} from "../../validations/ValidationErrors";
import {
  ALPHANUMERIC_WITH_SPACE,
  CAPITAL_LETTER_REGEX,
  ONLY_ALPHABET
} from "../../validations/regex";

const healthQuestions = {
  employment_ever_terminated: yup.string().required(SELECT_ONE_OPTION),
  sickness_duration_last_12_months: yup
    .string()
    .trim()
    .required("This field is required")
    .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
    .max(30, MAXIMUM_30_CHAR),
  sickness_duration_prior_to_last_12_months: yup
    .string()
    .trim()
    .required("This field is required")
    .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
    .max(30, MAXIMUM_30_CHAR),
  alcohol_units: yup
    .string()
    .trim()
    .required("This field is required")
    .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
    .max(30, MAXIMUM_30_CHAR),
  smoke: yup.string().required(SELECT_ONE_OPTION),
  medicine: yup.string().required(SELECT_ONE_OPTION),
  under_care: yup.string().required(SELECT_ONE_OPTION),
  last_consult_gp: yup
    .string()
    .trim()
    .required("This field is required")
    .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR),
  illness: yup.array().nullable(),
  details_of_illness: yup.string().when("illness", {
    is: (illness: []) => illness.length > 0,
    then: (schema) =>
      yup
        .string()
        .trim()
        .required("Please write details of illness")
        .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
        .min(3, MINIMUM_3_CHAR)
        .max(30, MAXIMUM_500_CHAR),
    otherwise: (schema) =>
      yup
        .string()
        .trim()
        .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
        .min(3, MINIMUM_3_CHAR)
        .max(30, MAXIMUM_500_CHAR),
  }),
  disabilities: yup
    .string()
    .trim()
    .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_500_CHAR),
};

export const medicalSchema = () => {
  return yup.object({
    post_offered: yup
      .string()
      .trim()
      .required()
      .matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(50, MAXIMUM_50_CHAR)
      .label("Post Offered"),
    surname: yup
      .string()
      .trim()
      .required()
      .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Surname"),
    forename: yup
      .string()
      .trim()
      .required()
      .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Forename"),
    telephone: yup
      .number()
      .required()
      .typeError("Telephone number must be a number")
      .positive("Telephone must be positive")
      .integer("Telephone number must be an integer")
      .max(999999999999999, "Telephone number must be at most 15 digits")
      .label("Telephone number"),
    mobile: yup
      .number()
      .required()
      .typeError("Mobile number must be a number")
      .positive("Mobile must be positive")
      .integer("Mobile number must be an integer")
      .max(999999999999999, "Mobile number must be at most 15 digits")
      .label("Mobile number"),
    address: yup
      .string()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("Address"),
    gp_name: yup
      .string()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(100, MAXIMUM_100_CHAR)
      .label("GP Name"),
    gp_address: yup
      .string()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("GP Address"),
    health_questions: yup.object(healthQuestions),
    declaration_full_name: yup
      .string()
      .trim()
      .required()
      .matches(CAPITAL_LETTER_REGEX, CAPITAL_LETTERS)
      .min(3, MINIMUM_3_CHAR)
      .max(100, MAXIMUM_100_CHAR)
      .label("Full Name"),
    declaration_date: yup
      .date()
      .required()
      .typeError(INVALID_DATE)
      .label("Date"),
    sign: yup.string().required().label("Signature"),
  });
};
