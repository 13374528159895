import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../components/common/Loader";
import { Col,  Row } from "react-bootstrap";
import constants from "../../../constants/constants";
import moment from "moment";
import {
  saveTicketCommentsApi,
  uploadTicketImageApi,
} from "../../../services/ticketService";
import { toast } from "react-toastify";
import commentPlusIcon from "../../../assets/images/comment-icon.svg";
import commentBlueIcon from "../../../assets/images/comment-blue-icon.svg";

import { Scrollbar } from "react-scrollbars-custom";
import CommentsForm from "../../../components/common/commentForm";
interface AddProps {
  ticketId: string | number | undefined;
  ticketData: object[];
  commentData: object[];
  onSubmitData: (ticketId: string | number | undefined) => void;
}

const TicketComments: React.FC<AddProps> = ({
  ticketId,
  ticketData,
  commentData,
  onSubmitData,
}) => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [signedUrlsFiles, setSignedUrlsFiles] = useState<any>([]);
  const [isMutation, setIsMutation] = useState(false);
  
  useEffect(() => {
    setLoading(false);
  }, [ticketId]);

  const commentsContainerRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollToBottom();
    }
  };
  useEffect(() => {
    // Scroll to the last comment on load
    if (commentsContainerRef.current) {
      scrollToBottom();
    }
  }, [ticketData, commentData]);



  const uploadFile = (
    imageFile: any,
    uploadedFiles: any,
    signedUrlsFiles: any
  ) => {
   
      setImageLoading(true);

      // Create FormData object
      const formData = new FormData();
      let filesData: any = [];
      filesData.push(imageFile);
      // Append the file to the FormData object
      formData.append("files", imageFile);

      uploadTicketImageApi(formData)
        .then((response) => {
          setImageLoading(false);
          const uploadedFilesArr = uploadedFiles;
          uploadedFilesArr.push(response.data.files[0]);
          setUploadedFiles(uploadedFilesArr);
          const signedUrlsFilesArr = signedUrlsFiles;
          signedUrlsFilesArr.push(response.data.filesSignedURL[0]);
          setSignedUrlsFiles(signedUrlsFilesArr);
         
        })
        .catch(() => {
          setImageLoading(false);
        });
  };

  const submitForm = async (values: any, ticketId: number | string) => {
    const formData = {
      ...values,
      tag_users_id: values.tag_users_id.map((item: { id: any }) => item.id),
    };
    // console.log(formData);
    //  return;
    saveTicketCommentsApi(ticketId, formData)
      .then((response: any) => {
        toast(response.message, { type: toast.TYPE.SUCCESS });
        setIsMutation(!isMutation);
        onSubmitData(ticketId);
        setUploadedFiles([]);
        setSignedUrlsFiles([]);
        setBtnLoading(false);
      })
      .catch((error: any) => {
        setBtnLoading(false);

        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="flex-grow-1 pageContent pb-2">
          <Scrollbar
            ref={commentsContainerRef}
            className="ticket-comment-scroll   border-bottom"
          >
            <div className="p-3">
              {ticketData.map((ticketObj: any, index: any) => (
                <Row className="comment-line" key={`ticket_data${index}`}>
                  <Col className="col-2 text-end">
                    <img src={commentPlusIcon} alt="comment" />
                  </Col>
                  <Col className="col-10">
                    <div>
                      Task Created by{" "}
                      <span className="fw-bold">
                        {ticketObj?.createdUser?.full_name}
                      </span>
                    </div>
                    <div className="text-muted text-gray">
                      {moment(ticketObj?.createdAt).format(
                        `${constants.datetimeFormats.slashSeparatedDateTime}`
                      )}
                    </div>
                  </Col>
                </Row>
              ))}
              {commentData.map((commentObj: any, index: any) => (
                <Row className="mt-3 comment-line" key={`ticket_data${index}`}>
                  <Col className="col-2 text-end">
                    {" "}
                    <img src={commentBlueIcon} alt="comment" />
                  </Col>
                  <Col className="col-10">
                    <div>
                      <span className="fw-bold">
                        {commentObj?.user?.full_name}
                      </span>{" "}
                      commented on ticket
                    </div>
                    <div className="text-muted">
                      {moment(commentObj?.createdAt).format(
                        `${constants.datetimeFormats.slashSeparatedDateTime}`
                      )}
                    </div>
                    <div className="comment-message fs-14">
                      {commentObj.tag_users &&
                        commentObj.tag_users.map((user: any, index: any) => (
                          <span
                            key={`taggedUser${index}`}
                            className="me-2 text-info"
                          >
                            {user.full_name}
                          </span>
                        ))}
                    </div>
                    <div className="comment-message fs-14">
                      {commentObj.message}
                    </div>
                    <div className="col-md-12 d-flex good-inward-box-cover">
                      {commentObj.filesSignedURL &&
                        commentObj.filesSignedURL.map(
                          (imagePath: any, index: any) => (
                            <div key={`images${index}`}>
                              <label className="file-box me-3">
                                <span>
                                  {" "}
                                  <img
                                    className="img-fluid"
                                    src={imagePath}
                                    alt="Logo"
                                  />
                                </span>
                              </label>
                            </div>
                          )
                        )}
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </Scrollbar>
          <CommentsForm
            ticketId={ticketId}
            onSubmitData={submitForm}
            onUpload={uploadFile}
            isMutation={isMutation}
            uploadedFilesData={uploadedFiles}
            signedUrlsFilesData={signedUrlsFiles}
            buttonLoaing={btnLoading}
            imageLoading={imageLoading}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default TicketComments;
