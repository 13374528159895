import path from "path";
import { SvgIcons } from "../../common/SvgIcons";
import { FaMeetup } from "react-icons/fa";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import ReceiptIcon from '@mui/icons-material/Receipt';

const NavItems = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: SvgIcons.dashboardIcon,
    path: "/dashboard-master",
    moduleName: "DASHBOARD", //dashboard
  },
  {
    id: 2,
    menuName: "Deals",
    menuIcon: SvgIcons.percentIcon,
    moduleName: "DEALS",
    path: "/deals",
  },
  {
    id: 3,
    menuName: "Projects",
    menuIcon: SvgIcons.tacticIcon,
    path: "/projects",
  },
  {
    id: 4,
    menuName: "Survey",
    menuIcon: SvgIcons.checklistIcon,
    path: "/survey",
    moduleName: "SURVEY",
  },
  {
    id: 5,
    menuName: "Form Builder",
    menuIcon: SvgIcons.addNotesIcon,
    path: "/formbuilder",
    moduleName: "FORM_BUIDER",
  },
  {
    id: 6,
    menuName: "Warehouse & Stock",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/warehouse",
    moduleName: "WAREHOUSE",
    subMenu: [
      {
        subId: 1,
        menuName: "Warehouse",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/warehouse",
        moduleName: "WAREHOUSE",
      },
      {
        subId: 2,
        menuName: "Opening Stock",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/opening-stocks",
        moduleName: "OPENING_STOCK",
      },
      {
        subId: 3,
        menuName: "Product Master",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/product",
        moduleName: "PRODUCT_MASTER",
      },
      {
        subId: 4,
        menuName: "Vendor Management",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/vendor",
        moduleName: "VENDOR_MANAGEMENT",
      },
      {
        subId: 5,
        menuName: "Purchase Order",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/purchase-orders",
        moduleName: "PURCHASE_ORDER",
      },
      {
        subId: 6,
        menuName: "Good Inward",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/good-inward",
        moduleName: "GOOD_INWARD",
      },
      {
        subId: 7,
        menuName: "Dispatch Orders",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/dispatch-orders",
        moduleName: "DISPATCH_ORDER",
      },
    ],
  },
    
  {
    id: 8,
    menuName: "HR Management",
    menuIcon: SvgIcons.hrmanagementIcon,
    path: "/hr-management",
    moduleName: "HR_MANAGEMENT",
    subMenu: [
      {
        subId: 1,
        menuName: "Roles",
        menuIcon: SvgIcons.hrmanagementIcon,
        path: "/roles",
        moduleName: "Role",
      },
      {
        subId: 2,
        menuName: "All Employees",
        menuIcon: SvgIcons.hrmanagementIcon,
        path: "/employees",
        moduleName: "ALL_EMPLOYEE",
      },
      {
        subId: 3,
        menuName: "Letter Templates",
        menuIcon: SvgIcons.hrmanagementIcon,
        path: "/letter-templates",
        moduleName: "LETTER_TEMPLATE",
      },
      {
        subId: 4,
        menuName: "Asset Management",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/assets",
        moduleName: "ASSET_MANAGEMENT",
      },
      {
        subId: 5,
        menuName: "National Holidays",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/holidays",
        moduleName: "HOLIDAYS_MANAGEMENT",
      },
      {
        subId: 6,
        menuName: "Library",
        menuIcon: <LibraryBooksIcon />,
        path: "/library",
        moduleName: "LIBRARY_MANAGEMENT",
      },
      {
        subId: 7,
        menuName: "Leave",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/leaves",
        moduleName: "LEAVES",
      },
      {
        subId: 8,
        menuName: "Announcements",
        menuIcon: SvgIcons.wharehouseIcon,
        path: "/announcement",
        moduleName: "ANNOUNCEMENTS",
      },
    ],
  },
  {
    id: 8,
    menuName: "Finance",
    menuIcon: SvgIcons.financeIcon,
    path: "/finance/dashboard",
    moduleName: "FINANCE_MANAGEMENT",
    subMenu: [
      {
        subId: 1,
        menuName: "Dashboard",
        menuIcon: SvgIcons.financeIcon,
        path: "/finance/dashboard",
        moduleName: "FINANCE_DASHBOARD",
      },
      {
        subId: 2,
        menuName: "Projects",
        menuIcon: SvgIcons.financeIcon,
        path: "/finance/projects",
        moduleName: "FINANCE_PROJECTS",
      },
      {
        subId: 3,
        menuName: "Invoices",
        menuIcon: SvgIcons.financeIcon,
        path: "/finance/invoices",
        moduleName: "FINANCE_INVOCIES",
      },
      {
        subId: 4,
        menuName: "External Expenses",
        menuIcon: SvgIcons.financeIcon,
        path: "/finance/vendors",
        moduleName: "FINANCE_EXTERNAL_EXPENSES",
      },
      {
        subId: 5,
        menuName: "Expenses",
        menuIcon: SvgIcons.financeIcon,
        path: "/",
        moduleName: "FINANCE_EXPENSES",
      },
    ],
  }, 
  {
    id: 7,
    menuName: "Manage External Links",
    menuIcon: SvgIcons.manageExternalIcon,
    path: "/external-link",
    moduleName: "MANAGE_EXTERNAL_LINKS",
  },

  {
    id: 10,
    menuName: "Postal-code",
    menuIcon: SvgIcons.postalCodeIcon,
    path: "/postalcode",
    moduleName: "POSTAL_CODE",
  },
  {
    id : 10,
    menuName : "Planner",
    menuIcon : SvgIcons.plannerIcon,
    path : "/planner",
    moduleName : "PLANNER",
  
  },
  
  {
    id: 11,
    menuName: "Task",
    menuIcon: SvgIcons.dashboardIcon,
    path  : "/tasks",
    moduleName: "TASK",   
  },
  {
    id: 12,
    menuName: "Fleet Manager",
    menuIcon: SvgIcons.fleetManage,
    path: "/fleet",
    moduleName: "FLEET_MANAGEMENT",
  },
  {
    id: 13,
    menuName: "Service Warranty Management",
    menuIcon: SvgIcons.serviceManagementIcon,
    path: "/service-warranty",
    moduleName: "SERVICE_WARRANTY_MANAGEMENT",
  },
];

const NavItemsAdministrator = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: SvgIcons.dashboardIcon,
    path: "/dashboard-master",
    moduleName: "DASHBOARD", //dashboard
  },  
  {
    id: 2,
    menuName: "Form Builder",
    menuIcon: SvgIcons.addNotesIcon,
    path: "/formbuilder",
    moduleName: "FORM_BUIDER",
  },

  {
    id: 3,
    menuName: "Manage External Links",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/external-link",
    moduleName: "MANAGE_EXTERNAL_LINKS",
  },

  {
    id: 4,
    menuName: "Postal-code",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/postalcode",
    moduleName: "POSTAL_CODE",
  },
  {
    id: 5,
    menuName: "Roles",
    menuIcon: SvgIcons.hrmanagementIcon,
    path: "/roles",
    moduleName: "Role",
  },
  {
    id : 6,
    menuName : "Planner",
    menuIcon : SvgIcons.plannerIcon,
    path : "/planner",
    moduleName : "PLANNER",
  },   
  
  {
    id: 8,
    menuName: "Fleet Manager",
    menuIcon: SvgIcons.fleetManage,
    path: "/fleet",
    moduleName: "FLEET_MANAGEMENT",
  }
];

const commonWithAdminAndEmoloyee = [
  {

    id: 1,
    menuName: "Tickets Management",
    menuIcon: SvgIcons.ticketManagementIcon,
    path: "/tickets",
    moduleName: "TICKET_MANAGEMENT"   
  }, 
]

const NavItemsHR = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: SvgIcons.dashboardIcon,
    path: "/hr-dashboard",
    moduleName: "DASHBOARD", //dashboard
  },
  {
    id: 2,
    menuName: "All Employees",
    menuIcon: SvgIcons.hrmanagementIcon,
    path: "/employees",
    moduleName: "ALL_EMPLOYEE", //dashboard
  },
  /*{
    subId: 2,
    menuName: "All Employees",
    menuIcon: SvgIcons.hrmanagementIcon,
    path: "/employees",
    moduleName: "ALL_EMPLOYEE",
    subMenu: [
      {
        subId: 1,
        menuName: "All Employees",
        menuIcon: SvgIcons.hrmanagementIcon,
        path: "/employees",
        moduleName: "ALL_EMPLOYEE",
      },
      {
        subId: 2,
        menuName: "Archive Employee",
        menuIcon: SvgIcons.hrmanagementIcon,
        path: "/employees/archive-employees",
        moduleName: "ALL_EMPLOYEE",
      }
    ]
  },*/
  {
    subId: 3,
    menuName: "Profile Change Req",
    menuIcon: SvgIcons.hrmanagementIcon,
    path: "/profile-change-requests",
    moduleName: "PROFILE_CHANGE_REQUEST",
  },
  {
    subId: 4,
    menuName: "Letter Templates",
    menuIcon: SvgIcons.hrmanagementIcon,
    path: "/letter-templates",
    moduleName: "LETTER_TEMPLATE",
  },
  {
    subId: 5,
    menuName: "Asset Management",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/assets",
    moduleName: "ASSET_MANAGEMENT",
  },
  {
    subId: 6,
    menuName: "National Holidays",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/holidays",
    moduleName: "HOLIDAY",
  },
  {
    subId: 7,
    menuName: "Library",
    menuIcon: <LibraryBooksIcon />,
    path: "/library",
    moduleName: "LIBRARY",
  },
  {
    subId: 7,
    menuName: "Leave",
    menuIcon: <HistoryToggleOffIcon />,
    path: "/leaves",
    moduleName: "LEAVES",
  },
  {
    subId: 8,
    menuName: "Announcements",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/announcement",
    moduleName: "ANNOUNCEMENTS",
  },
];

const NavItemsWarehouse = [
  {
    subId: 1,
    menuName: "Warehouse",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/warehouse",
    moduleName: "WAREHOUSE",
  },
  {
    subId: 2,
    menuName: "Opening Stock",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/opening-stocks",
    moduleName: "OPENING_STOCK",
  },
  {
    subId: 3,
    menuName: "Product Master",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/product",
    moduleName: "PRODUCT_MASTER",
  },
  {
    subId: 4,
    menuName: "Vendor Management",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/vendor",
    moduleName: "VENDOR_MANAGEMENT",
  },
  {
    subId: 5,
    menuName: "Purchase Order",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/purchase-orders",
    moduleName: "PURCHASE_ORDER",
  },
  {
    subId: 6,
    menuName: "Good Inward",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/good-inward",
    moduleName: "GOOD_INWARD",
  },
  {
    subId: 7,
    menuName: "Dispatch Orders",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/dispatch-orders",
    moduleName: "DISPATCH_ORDER",
  },
];

const NavItemsFinance = [
  {
    id: 1,
    menuName: "Finance Dashboard",
    menuIcon: SvgIcons.dashboardIcon,
    path: "/finance/dashboard",
    moduleName: "FINANCE_DASHBOARD", //dashboard
  },
  {
    subId: 2,
    menuName: "Finance Projects",
    menuIcon: SvgIcons.financeIcon,
    path: "/finance/projects",
    moduleName: "FINANCE_PROJECTS",
  },
  {
    subId: 3,
    menuName: "Expenses",
    menuIcon: <CurrencyPoundIcon />,
    path: "/finance/expenses",
    moduleName: "FINANCE_EXPENSES",
  },
  {
    subId: 4,
    menuName: "Ext. Procurement Expenses",
    menuIcon: <CurrencyPoundIcon />,
    path: "/finance/external-expenses",
    moduleName: "FINANCE_EXTERNAL_EXPENSES",
  },
  {
    subId: 5,
    menuName: "Invoices",
    menuIcon: <ReceiptIcon />,
    path: "/finance/invoices",
    moduleName: "FINANCE_INVOCIES",
  }  
];

const NavItemsForAllEmployee = [
  {
    id: 1,
    menuName: "My Dashboard",
    menuIcon: SvgIcons.dashboardIcon,
    path: "dashboard",
    moduleName: "all",
  },
  {
    id: 2,
    menuName: "My Meetings",
    menuIcon: <MeetingRoomIcon />,
    path: "/meetings",
    moduleName: "all",
  },
  {
    id: 3,
    menuName: "Leave",
    menuIcon: <HistoryToggleOffIcon />,
    path: "/leaves/leave-history",
    moduleName: "LEAVES",
},
/*   {
    id: 4,
    menuName: "HMRC Form",
    menuIcon: <InsertDriveFileIcon />,
    path: "/hmrc",
    moduleName: "all",
  },
  {
    id: 5,
    menuName: "Medical Questionnaire",
    menuIcon: <InsertDriveFileIcon />,
    path: "/medical",
    moduleName: "all",
  }, */
  {
    id: 6,
    menuName: "My Documents",
    menuIcon: SvgIcons.addNotesIcon,
    path: "/employees/documents",
    moduleName: "MY_DOCUMENT",
  },

  {
    id: 8,
    menuName: "Library",
    menuIcon: <LibraryBooksIcon />,
    path: "/library/list",
    moduleName: "LIBRARY_MANAGEMENT",
  },
  {
    id: 9,
    menuName: "National Holidays",
    menuIcon: SvgIcons.wharehouseIcon,
    path: "/holidays/list",
    moduleName: "HOLIDAYS_MANAGEMENT",
  },
  {
    id : 10,
    menuName : "Planner",
    menuIcon : SvgIcons.plannerIcon,
    path : "/planner",
    moduleName : "PLANNER",
  },
  {
    id: 11,
    menuName: "My Team Task",
    menuIcon: SvgIcons.dashboardIcon,
    path  : "/tasks",
    moduleName: "TASK",   
  }
];

const NavItemsEmployee = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: SvgIcons.dashboardIcon,
    path: "dashboard",
    moduleName: "all",
  },
  {
    id: 2,
    menuName: "My Meetings",
    menuIcon: <MeetingRoomIcon />,
    path: "/meetings",
    moduleName: "all",
  },
  {
    id: 3,
    menuName: "Team Meetings",
    menuIcon: <MeetingRoomIcon />,
    path: "/team-meetings",
    moduleName: "all",
  },
  {
    id: 4,
    menuName: "HMRC Form",
    menuIcon: <InsertDriveFileIcon />,
    path: "/hmrc",
    moduleName: "all",
  },
  {
    id: 5,
    menuName: "Medical Questionnaire",
    menuIcon: <InsertDriveFileIcon />,
    path: "/medical",
    moduleName: "all",
  },
  
];
const NavItemsInstallationEngineer = [
  {
    id: 1,
    menuName: "Installation Tasks",
    menuIcon: SvgIcons.dashboardIcon,
    path: "/installation",
    moduleName: "INSTALLATIOIN_TASK", //dashboard
  },
  {
    id : 2,
    menuName : "Planner",
    menuIcon : SvgIcons.plannerIcon,
    path : "/planner",
    moduleName : "PLANNER",
  }
];

const NavItemsReportingManagerExpenses = [
  {
    id: 201,
    menuName: "Expenses",
    menuIcon: <CurrencyPoundIcon />,
    path: "/expenses",
    moduleName: "EXPENSES",
    subMenu: [
      {
        subId: 1,
        menuName: "Log Expenses",
        menuIcon: SvgIcons.hrmanagementIcon,
        path: "/expenses/log-expenses",
        moduleName: "EXPENSES",
      },
      {
        subId: 2,
        menuName: "Team Expenses",
        menuIcon: SvgIcons.hrmanagementIcon,
        path: "/expenses/team-expenses",
        moduleName: "EXPENSES",
      }      
    ],
  }, 
];

const NavItemsEmployeeExpenses = [
  {
    id: 201,
    menuName: "Log Expenses",
    menuIcon: <CurrencyPoundIcon />,
    path: "/expenses/log-expenses",
    moduleName: "EXPENSES",
  },
];

const NavItemsProjectManager = [
  {
    id: 100,
    menuName: "Dashboard",
    menuIcon: SvgIcons.dashboardIcon,
    path: "/dashboard-master",
    moduleName: "DASHBOARD", //dashboard
  }, 
  {
    id: 101,
    menuName: "Deals",
    menuIcon: SvgIcons.percentIcon,
    moduleName: "DEALS",
    path: "/deals",
  },
  {
    id: 102,
    menuName: "Projects",
    menuIcon: SvgIcons.tacticIcon,
    moduleName: "PROJECTS",
    path: "/projects",
  },
  {
    id: 103,
    menuName: "Survey",
    menuIcon: SvgIcons.checklistIcon,
    path: "/survey",
    moduleName: "SURVEY",
  },
  {
    id: 104,
    menuName: "Service Warranty Management",
    menuIcon: SvgIcons.serviceManagementIcon,
    path: "/service-warranty",
    moduleName: "SERVICE_WARRANTY_MANAGEMENT",
  },
  { id : 105,
    menuName : "Planner",
    menuIcon : SvgIcons.plannerIcon,
    path : "/planner",
    moduleName : "PLANNER",
  }
];
const NavItemsReportingManager = [
  {
    id: 1,
    menuName: "My Team",
    menuIcon: SvgIcons.dashboardIcon,
    path: "/my-team",
    moduleName: "MY_TEAM",
  },
  {
    id: 2,
    menuName: "Team Meetings",
    menuIcon: <MeetingRoomIcon />,
    path: "/team-meetings",
    moduleName: "all",
  },
];

const NavItemsForDriver = [
  {
    id: 1,
    menuName: "My Fleets",
    menuIcon: SvgIcons.fleetManage,
    path: "/my-fleet",
    moduleName: "FLEET_MANAGEMENT",
  },
];
export {
  NavItems,
  NavItemsAdministrator,
  NavItemsEmployee,
  NavItemsHR,
  NavItemsWarehouse,
  NavItemsReportingManager,
  NavItemsFinance,
  NavItemsInstallationEngineer,
  NavItemsForAllEmployee,
  NavItemsForDriver,
  NavItemsProjectManager,
  NavItemsReportingManagerExpenses,
  NavItemsEmployeeExpenses,
  commonWithAdminAndEmoloyee
};
