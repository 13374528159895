import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { getEmployeeMeetingDetail } from "../../../../services/employeeService";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import moment from "moment";
import constants, { Employee, Reporting_Manager, SUPER_ADMIN, MEETING_SOURCE_PAGES } from "../../../../constants/constants";
import ViewObjectEmotions from "./ViewObjectEmotions";
import { selectAuthentication } from "../../../../features/Auth/authSlice";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  meetingId?: string |number | null | undefined;
  source: string;
}

const ViewMeetingNotesPopup: React.FC<AddProps> = ({ isOpen, onClose, meetingId, source }) => {  
  const { user }: any = useSelector(selectAuthentication);
  const [loading, setLoading] = useState(true);
  const [meetingData, setMeetingData] = useState<any>({}); 

  useEffect(()=>{
    fetchData()
   },[])
 
   const fetchData = () =>{         
      if(meetingId){
        setLoading(true)
        getEmployeeMeetingDetail(meetingId).then((response)=>{
          setMeetingData(response?.data);
          setLoading(false)  
        }).catch((error)=>{
          setLoading(false)
        })
      }
      else{
        setLoading(false)
      }  
   } 

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal" show={isOpen} onHide={onClose} centered>          
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    View Meeting Notes
                  </h4> 
                  <Row>
                    <Col className="col-md-3">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Meeting Title</label> 
                        <p className="fs-14 mb-0">{meetingData?.title}</p>
                      </div>
                    </Col>                    
                    <Col className="col-md-3">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Meeting Date</label> 
                        <p className="fs-14 mb-0">{meetingData?.date ? moment(meetingData.date).format(constants.dateFormats.slashSeparatedDate) : "-"}</p>
                      </div>
                    </Col> 
                    <Col className="col-md-3">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Reporting Manager</label> 
                        <p className="fs-14 mb-0">{meetingData?.manager?.full_name}</p>
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <div className="mb-3 custom-from-group">
                        <label className="lh-sm control-label fs-14 fw-bold form-label">Meeting Time</label> 
                        <p className="fs-14 mb-0">{meetingData?.time ? moment(meetingData.time, 'HH:mm:ss').format('hh:mm A') : "-"}</p>
                      </div>
                    </Col>                                      
                  </Row>
                  <Row>
                  {meetingData?.meetingNotes?.notes && Object.keys(meetingData?.meetingNotes?.notes).map((key) => {
                    const notesData = meetingData.meetingNotes.notes[key];
                    return (<div key={key}>
                      {notesData.type === 'subjective' && 
                      (<>
                     
                        <p className="fs-20 info-text fw-bold mb-2">{notesData.label}</p>
                        {source === MEETING_SOURCE_PAGES.hr && <><p className="fs-16 fw-bold text-black mb-2">Colleague's comment</p><p className="fs-14 text-black mb-2">{notesData.colleague_comment ? notesData.colleague_comment : '-'}</p>
                        <p className="fs-14 fw-bold control-label mb-1">Manager's comment</p><p className="fs-14 text-black mb-2">{notesData.manager_comment ? notesData.manager_comment : '-'}</p></>}
                        {/** A reporting manager can see both reporting manager's and colleague's comment for subjective question but an employee/colleague can only see his comment */}
                        {(source === MEETING_SOURCE_PAGES.reporting_manager || source === MEETING_SOURCE_PAGES.reporting_manager_team) && <><p className="fs-16 fw-bold text-black mb-2">Colleague's comment</p><p className="fs-14 text-black mb-2">{notesData.colleague_comment ? notesData.colleague_comment : '-'}</p>
                        <p className="fs-14 fw-bold control-label mb-1">Manager's comment</p><p className="fs-14 text-black mb-2">{notesData.manager_comment ? notesData.manager_comment : '-'}</p></>}
                        {source === MEETING_SOURCE_PAGES.employee && <p className="fs-14 mb-2">{notesData.colleague_comment ? notesData.colleague_comment : '-'}</p>}
                        
                      </>)
                      }
                      
                      {notesData.type === 'objective_emotion' && 
                      (<>
                        
                        <ViewObjectEmotions 
                          label={notesData.label}
                          emotion_options={notesData.emotion_options}
                          colleague_comment={notesData.colleague_comment}
                        />
                        </>)
                      }
                    </div>)
                  })}
                  </Row>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Close
                  </button>                 
                </Modal.Footer>              
        </Modal>
      )}
    </>
  );
};
export default ViewMeetingNotesPopup;