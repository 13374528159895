import React from 'react';  
import PurchaseChart from './purchase.chart';
import DelayedPorchaseOrder from './DelayedPorchaseOrder';
import { DateRangeInterface } from '../dashboardInterface';
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
const PurchaseOrder: React.FC<DateRangeInterface> = ({orderDateRange}) => {
 
return (
    <div className='col-md-12'>
        <Card className="w-100 pageContentInner rounded-2  mb-3">
            <Row>
                <Col className='col-md-4'>   
                    <PurchaseChart orderDateRange={orderDateRange} />
                </Col>
                <Col className='col-md-8 dashboard-border-left'>
                    <DelayedPorchaseOrder orderDateRange={orderDateRange}/>
                </Col>
            </Row>
        </Card>         
    </div>
    )
}

export default PurchaseOrder;