// DeletePopup.tsx
import React, { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FormAsyncSelect from "../../components/common/AsyncSelect/FormAsyncSelect";
import { Formik } from "formik";
import FormSelect from "../../components/common/FormSelect";
import constants, {
  departmentOptions,
  tickerStatus,
} from "../../constants/constants";
import InputDateRangePicker from "../../components/common/InputDateRangePicker";
import moment from "moment";
import { getAllManagerList } from "../../services/authService";
import { toast } from "react-toastify";
import { exportServiceComplaintData, exportTicketData } from "../../services/ticketService";
import { getOrderNumberListApi } from "../../services/dealService";

interface ExportDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  text: string;
}

interface ProjectOption {
  id: string;
  vendor_name: string;
  contact_person: string;
}

const ExportServiceComplaintData: FC<ExportDataPopupProps> = ({
  isOpen,
  onClose,
  label,
  text,
}) => {
  const [orderDateRange, setOrderDateRange] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [isOpen]);

  const handleSubmit = (values: any) => {
    const transformedValues = {
      ...values,
      contract_id: values.contract_id?.id,
      customer_name: values.customer_name?.name,
      project_owner: values.project_owner?.id,
      project_task_owner: values.project_task_owner?.id,
      status: values.status?.key,
      department: values.department?.key,
      assigned_to: values.assigned_to?.id,
      date: values?.date_arr,
      //  order_date:orderDateRange
    };

    delete transformedValues.date_arr;

    //  console.log(transformedValues);
    //  return;
    exportServiceComplaintData(transformedValues)
      .then((response) => {
        // Create a Blob object from the response data
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()} _Service_Logs_data.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
        onClose();
      })
      .catch((error) => {
        if (error.response.status == " 404") {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`No records available for export.`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        showAll : true
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      const transformParam = {
        ...params,
      };
      getAllManagerList(transformParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const orderDateFilter = (
    data: any,
    fieldName: string,
    setFieldValue: any
  ) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(
        constants.dateFormats.databaseFormat
      );
      const endDate = moment(dataRange[1]).format(
        constants.dateFormats.databaseFormat
      );
      setFieldValue(fieldName, `${startDate},${endDate}`);
      setOrderDateRange(`${startDate},${endDate}`);
    } else {
      setOrderDateRange("");
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          contract_id: null,
          customer_name: null,
          project_owner: null,
          project_task_owner: null,
          date: null,
          assigned_to: null,
          status: null,
          department: null,
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            className="export-excel-modal"
            show={isOpen}
            onHide={onClose}
            centered
          >
            <Form className="" noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <div className="fs-24 fw-bolder mb-3 info-text">
                  Export to Excel
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <FormAsyncSelect
                      id="contract_id"
                      name="contract_id"
                      placeholder="All"
                      label="Project Order No."
                      defaultFilter={{ exportType: "project" }}
                      isAsync
                      isClearable
                      getOptions={getOrderNumber}
                      value={values.contract_id}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormAsyncSelect
                      id="customer_name"
                      name="customer_name"
                      placeholder="All"
                      label="Customer Name"
                      isAsync
                      isClearable
                      defaultFilter={{ exportType: "name" }}
                      getOptions={getOrderNumber}
                      value={values.customer_name}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormAsyncSelect
                      id="project_owner"
                      placeholder="All"
                      name="project_owner"
                      label="Project Owner"
                      isAsync
                      isClearable
                      getOptions={getProjectOwner}
                      value={values.project_owner}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormAsyncSelect
                      id="project_task_owner"
                      placeholder="All"
                      name="project_task_owner"
                      label="Project Task Owner"
                      isAsync
                      isClearable
                      getOptions={getProjectOwner}
                      value={values.project_task_owner}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputDateRangePicker
                      controlId="date"
                      label="Date Range"
                      placeholder="Select"
                      touched={false}
                      handleBlur={false}
                      handleChange={(e: any) => {
                        setFieldValue("date", e);
                        orderDateFilter(e, "date_arr", setFieldValue);
                      }}
                      handleClose={orderDateFilter}
                      errorsField={false}
                      value={values.date}
                      position="auto"
                    />
                  </div>
                  <div className="col-md-6">
                    <FormAsyncSelect
                      id="assigned_to"
                      placeholder="All"
                      name="assigned_to"
                      label="Assigned To"
                      isAsync
                      isClearable
                      getOptions={getProjectOwner}
                      value={values.assigned_to}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="All"
                      label="Status"
                      name="status"
                      options={tickerStatus}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.status}
                      error={errors.status}
                      touched={touched.status}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="All"
                      label="Department"
                      name="department"
                      options={departmentOptions}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      value={values.department}
                      error={errors.department}
                      touched={touched.department}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                <button
                  className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <Button
                  type="submit"
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Export
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ExportServiceComplaintData;
