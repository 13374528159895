import { Route, Routes } from "react-router-dom";
import HolidaysList from "./index";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";

export const Holidays = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.HOLIDAY) && (
      <Route path="/" element={<HolidaysList />} />
      )}

    <Route path="/list" element={<HolidaysList utype="emp"  />} />

    </Routes>
  );
};
