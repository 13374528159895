import * as yup from "yup";
import { useParams } from "react-router-dom";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_500_CHAR,
  ALPHA_NUMERIC_ALLOW,
  SELECT_ONE_USER_IN_RECEIPIENT_LIST
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";

export const addSchema = (id: string | number | undefined) => {
  return yup.object({
    title : yup.object().required().label('Email Template'),
    cc_receivers: yup.array().min(1,SELECT_ONE_USER_IN_RECEIPIENT_LIST).required().label("Role"),
    email_subject: yup.string().trim().required().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(500,MAXIMUM_500_CHAR).label('Email Subject'),
    email_body: yup.string().required('Email Body is required'),
  });
}
