import { Route, Routes } from 'react-router-dom';
import { AddFleet } from './Add';
import { ViewFleet } from './View';
import { checkAbility } from '../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../constants/permissionUser';
import FleetList from './index';
import { useSelector } from 'react-redux';
import { selectAuthentication } from '../../features/Auth/authSlice';
import { OperationDesignation } from '../../constants/constants';

export const Fleet = () => {
    const {user }:any = useSelector(selectAuthentication);
    const isDriverUser = user?.user?.employeeDetails?.designation == OperationDesignation.DS ? true : false;
    return (
        <Routes>            
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.FLEET_MANAGEMENT) && (
                <Route path="/" element={<FleetList />} />
            )}
            {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.FLEET_MANAGEMENT) && (
                <Route path="/Add" element={<AddFleet />} />
            )}
            {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.FLEET_MANAGEMENT) && (
                <Route path="/edit/:id" element={<AddFleet />} />
            )}
            {(checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.FLEET_MANAGEMENT) ) && (
                <Route path="/view/:id" element={<ViewFleet />} />
            )}                      
        </Routes>
    )
};

export const MyFleet = () => {
    const {user }:any = useSelector(selectAuthentication);
    const isDriverUser = user?.user?.employeeDetails?.designation == OperationDesignation.DS ? true : false;
    return (
        <Routes>
            { isDriverUser && (
                <Route path="/" element={<FleetList uType="driver" />} />
            )}  
            {isDriverUser && (
                <Route path="/view/:id" element={<ViewFleet uType="driver" />} />
            )}                               
        </Routes>
    )
};