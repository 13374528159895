import * as yup from "yup";
import { 
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  ROLE_DESC_REQUIRED,
  ROLE_REQUIRED
} from "../../../validations/ValidationErrors";

export const addRolesSchema = yup.object({
  role: yup.string().trim().required(ROLE_REQUIRED).min(3,MINIMUM_3_CHAR).max(30,MAXIMUM_30_CHAR),
 description :  yup.string().trim().required(ROLE_DESC_REQUIRED).min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR),
});
