import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { getEmployeeInductionDetail, markReadAnnoucementDashboardAlert } from "../../services/employeeService";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import moment from "moment";
import constants from "../../constants/constants";
import { getLibraryByIdApi } from "../../services/libraryService";
import PDFViewer from "../../components/common/PDFViewer";
import { getAnnouncementByIdApi } from "../../services/announcementService";

interface AddProps {
  isOpen: boolean;
  onClose: () => void; 
  announcementId?: string |number | null | undefined;
  fromDashboard?:boolean;
}

const ViewAnnouncementPopup: React.FC<AddProps> = ({ isOpen, onClose, announcementId }) => {  
  const [loading, setLoading] = useState(true);
  const [announcementData, setAnnouncementData] = useState<any>({}); 
  const [docUrl, setDocUrl] = useState<any>(); 

  useEffect(()=>{
    fetchData()
   },[])

   const handleAnnoucementMarkRead = (announcId: any) => {   
    markReadAnnoucementDashboardAlert(announcId)
      .then((response) => {        
        toast(response.message, { type: toast.TYPE.SUCCESS });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }        
      })
      .finally(() => {        
      });
  }
 
   const fetchData = () =>{         
      if(announcementId){
        setLoading(true)
        getAnnouncementByIdApi(announcementId)
            .then((response: any) => {
              setAnnouncementData(response.data);
              if(response.announcementFile){
                setDocUrl(response.announcementFile);
                if(response.data.type == 1){
                  console.log("121212")
                  handleAnnoucementMarkRead(announcementId);
                }
              }
              else{
                onClose();
              }
              setLoading(false);
            })
            .catch((error: any) => {
              setLoading(false);
              toast(error.data.msg, { type: toast.TYPE.ERROR });
            });      
      }
      else{
        setLoading(false)
      }  
   } 

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="pdf-viewer" show={isOpen} onHide={onClose} centered>          
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    { announcementData.title}
                  </h4>   
                  <Row>
                    <Col>
                      <div className="mb-3 custom-from-group">
                        <p className="fs-14">{announcementData?.description}</p>
                      </div>
                    </Col>                            
                  </Row>                
                  { docUrl && (
                  <Row>
                    <Col>
                    <PDFViewer pdfUrl={docUrl} />
                    </Col>
                  </Row> 
                  )}
                                 
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                              
                </Modal.Footer>              
        </Modal>
      )}
    </>
  );
};
export default ViewAnnouncementPopup;