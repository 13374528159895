import { Route, Routes } from "react-router-dom";
import EmployeeHmrcForm from "./index";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { Login } from "../Auth/Login";

export const Hmrc = () => {
  return (
    <Routes>
      <Route path="/" element={<EmployeeHmrcForm />} />
    </Routes>
  );
};
