import react, { useState, useEffect, useRef } from "react";
import React, { FC } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import {
  ITypePropsAcceptRejectPopup,
  ITypePropsCanvas,
} from "./surveyInterface";
import CustomButton from "../../../components/common/CustomButton";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";
import { ButtonGroup } from "@mui/material";

const AcceptRejectPopup: FC<ITypePropsAcceptRejectPopup> = ({
  showPopup,
  handleClosePopup,
  type,
  handleProductAccept,
  index,
  text,
}) => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleSubmit = (values: any) => {
    handleProductAccept(index, type);
    handleClosePopup();
  };

  return (
    <React.Fragment>
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        centered
        dialogClassName="modal-700w"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-18 fw-bold mb-3 info-text">
            {type == "accept" ? "Approve Product Item" : "Remove Product Item"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {text
              ? text
              : type == "accept"
              ? "You Are Approving The Product Item On Behalf Of Survey."
              : "You Are Denying The Product Item On Behalf Of Survey."}
          </p>
          <Form className="text-start" noValidate onSubmit={handleSubmit}>
            <div className="d-flex justify-content-end">
              <button
                className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`fw-semibold fs-13 text-white mw-90 mt-2 ${
                  type == "accept" ? "btn btn-primary" : "btn btn-danger"
                }`}
                onClick={handleSubmit}
              >
                {type == "accept" ? "Approve" : "Proceed"}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {loading && <Loader isLoading={loading} />}
    </React.Fragment>
  );
};
export default AcceptRejectPopup;
