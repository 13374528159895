// @flow
import * as React from "react";
import Calendar from "./Calendar";
import { Card } from "react-bootstrap";
import SideEvents from "./sideEvents";
import { selectAuthentication } from "../../features/Auth/authSlice";
import { useSelector } from "react-redux";
import MapLocation from "./MapLocation";
import Loader from "../../components/common/Loader";
type Props = {};
const Planner: React.FC<Props> = () => {
  const [selectedTeamMembers, setSelectedTeamMembers] = React.useState([]);
  const [teamview, setTeamview] = React.useState(false);
  const [onlyEmployeeView, setOnlyEmployeeView] = React.useState(false);
  const [isMapView, setIsMapView] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { user }: any = useSelector(selectAuthentication);
  const [selectedContract, setSelectedContract] = React.useState<any>(null);
  const [checkedMembers, setCheckedMembers] = React.useState<any>([]); 
  const [isSidebarLoaded, setIssidebarLoaded] = React.useState(false)
  const userDesignation =
    user.user?.employeeDetails?.designation || "Super Admin";

  React.useEffect(() => {
    if (
      (userDesignation != "Project Manager" &&
      userDesignation != "Super Admin" && 
      userDesignation != "HR Administration")
    ) {
      setOnlyEmployeeView(true);
    }
    setLoading(false);
  }, [userDesignation]);

  return (
    <div className="d-flex flex-column h-100 pageContainer  w-100">

      <div className="d-flex p-3 flex-grow-1 overflow-auto pageContent">
        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <>
            {" "}
            <div className="mw-366 min-w-200 pe-2 ">
              <h1 className="fw-semibold h4 my-2">
                {!teamview ? "Task" : "Team Availability"}
              </h1>
               <Card>
                <Card.Body className="p-0">
                  <SideEvents
                    teamview={teamview}
                    onlyEmployeeView={onlyEmployeeView}
                    selectedContract={selectedContract}
                    setCheckedMembers={setCheckedMembers}
                    checkedMembers={checkedMembers}  
                    setIssidebarLoaded={setIssidebarLoaded}
                    setSelectedTeamMembers={setSelectedTeamMembers}
                    selectedTeamMembers={selectedTeamMembers}
                    
                  />
                </Card.Body>
              </Card>
            </div>
            <div className="w-100">
              {/* <TaskBar
          setSelectedTeamMembers={setSelectedTeamMembers}
          startDate={startDate}
          endDate={endDate}
        /> */}
              {isSidebarLoaded && 
              
              isMapView ? (
                <MapLocation
                  setIsMapView={setIsMapView}
                  isMapView={isMapView}
                />
              ) : (
                <Calendar
                  setSelectedTeamMembers={setSelectedTeamMembers}
                  setTeamview={setTeamview}
                  teamview={teamview}
                  onlyEmployeeView={onlyEmployeeView}
                  setIsMapView={setIsMapView}
                  isMapView={isMapView}
                  setSelectedContract={setSelectedContract}
                  checkedMembers={checkedMembers}
                  setCheckedMembers={setCheckedMembers}
                  selectedTeamMembers={selectedTeamMembers}
                 />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Planner;
