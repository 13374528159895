import { Card, Col, Row } from "react-bootstrap";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { getExpireDocumentList } from "../../services/hrdashboard.service";
import Loader from "../../components/common/Loader";

const ExpireDocumentFullList = () => {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, []);
  const fetchData = (signal: any) => {
    getExpireDocumentList(signal)
      .then((res) => {
        setListData(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: "$employee_docs.full_name$",
      headerName: "Name",
      sortable: true,
      filterable: true,
      flex: 1,
      hideable: false,
      renderCell: (params) => {
        return (
          <div title={params.row.employee_docs.full_name}>
            {params.row.employee_docs.full_name}
          </div>
        );
      },
    },
    {
      field: "doc_name",
      headerName: "Document",
      flex: 1,
      filterable: true,
    },
    {
      field: "expiry_date",
      headerName: "Expiry",
      flex: 1,
      filterable: true,
    },
  ];

  return (
    <>
      <Row>
        <Col md={12} className="mb-3">
          <h2 className="fs-20 info-text fw-bold">Expiry Documents</h2>
          {loading ? (
            <Loader isLoading={loading} />
          ) : (
            <Card className="p-0 w-100 ">
              <div className="d-flex flex-column rounded-0 dataGridListMain">
                <DataGrid
                  rows={listData}
                  columns={columns}
                  pageSizeOptions={[5]}
                  rowSelection={false}
                />
              </div>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ExpireDocumentFullList;
