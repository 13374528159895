import { Route, Routes } from 'react-router-dom';
import { checkAbility } from '../../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../../constants/permissionUser';
import FinanceExnternalFinanaceList from './index';

export const FinanceExternalExpenses = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.FINANCE_EXTERNAL_EXPENSES) && (
                <Route path="/" element={<FinanceExnternalFinanaceList />} />
            )}           
        </Routes>
    )
};