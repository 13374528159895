import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import {
  getProfileUpdateRequestList,
  profileChangeRequestAction
} from "../../services/employeeService";
import { Card } from "react-bootstrap";
import datetimeFormats, { pageSetting, pageSizeModel } from "../../constants/constants";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import VisibilityIcon from '@mui/icons-material/Visibility';

const List: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  // Profile change request edit handler
  const handleEditClick = (employee_id: string) => () => {
    navigate(`/profile-change-requests/view/${employee_id}`);
  };

  // Profile change request delete handler
  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  // This function will reject the profile change request
  const handleDelete = () => {
    setLoading(false);
    profileChangeRequestAction(deletedId,{action:'2'})
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getProfileUpdateRequestList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet)
        setData(convertData);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const convertDataToObjects = (data:any) => {
    return data.map((item:any) => ({
      ...item,
      created_at: new Date(item.created_at),
    }));
  };

  
  const columns: GridColDef[] = [
    { 
        field: "$employee.full_name$", 
        headerName: "Employee Name", 
        flex: 1, 
        sortable: true,
        renderCell(params) {
            return  <span title={params.row.employee.full_name}>{params.row.employee.full_name}</span>;
        },
    },
    {
      field: "created_at",
      headerName: "Request Date",
      flex: 1,
      sortable: true,
      type: 'date',
      renderCell(params) {
        return <span className="fs-14 fw-semibold text-222"><span className="w-100 d-block control-label fw-normal">{moment(params.row.created_at).format(datetimeFormats.dateFormats.slashSeparatedDate)}</span></span>
    }
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span className={`py-1 px-2 rounded-1 Notice-Period-tag d-inline-block fs-11`}>
            Pending
          </span>
        );
      },
    },
    {
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 0.5,
        cellClassName: "actions",
        getActions: (params) => [
          <div className="table-tags d-flex">
            {checkAbility(
              PERMISSION_ACCESS.EDIT,
              PERMISSION_MODULE.PROFILE_CHANGE_REQUEST
            ) && (
              <div className="me-3" onClick={handleEditClick(params.row.employee_id)}>
                <VisibilityIcon className="text-primary cursor-pointer" />
              </div>
            )}{" "}
            {checkAbility(
              PERMISSION_ACCESS.DELETE,
              PERMISSION_MODULE.PROFILE_CHANGE_REQUEST
            ) && (
              <div onClick={handleDeleteClick(params.row.employee_id)}>
                <svg
                  className="cursor-pointer"
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                    fill="#21AC96"
                  />
                </svg>
              </div>
            )}
          </div>,
        ],
      },
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2 mb-0">Profile Change Request</h1>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  className="border-0"
                  pageSizeOptions={pageSizeModel}
                />
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Request"
                  text="Are you sure you want to delete this profile change request"
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default List;
