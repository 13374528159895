import React from 'react';  
import InvoiceChart from './invoice.chart';
import { DateRangeInterface } from '../dashboardInterface';
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
  const Invoice:React.FC<DateRangeInterface> = ({orderDateRange}) => {
    return (
        <div className='col-md-12'>
            
                <InvoiceChart orderDateRange={orderDateRange}/>
            
        </div>
    )
}

export default Invoice;