import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,  
  GridFilterModel,
  GridActionsCellItem
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import constants, { pageSetting } from "../../../constants/constants";
import { checkAbility, currencyMask } from "../../../utility/common";
import moment from "moment";
import { getFinanceDashboardOutstandingInvoices, getFinanceDashboardPendingAssets } from "../../../services/financeService";
import BlockIcon from "@mui/icons-material/Block";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import TableSkeleton from "../../../components/common/Skeleton/Table.skeleton";

interface AddProps {
  dateRange: any;
}

const FinancePendingOutstandingInvoices: React.FC<AddProps> = ({dateRange}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });

  const [orderDateRange, setOrderDateRange] = useState<any>(null);

  const defaultParams = {
    ...dateRange,
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  

  const fetchData = (defaultParams: any) => {
    console.log(defaultParams, dateRange)
    getFinanceDashboardOutstandingInvoices(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "$project.project_no$",
      headerName: "Project Order No.",
      flex: 1.5,
      sortable: false,
      filterable: false,
      hideable:false,
      renderCell(params) {
        return checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.FINANCE_PROJECTS) ? (<Link
          target="_blank"
          className="blue-text text-decoration-none fw-semibold"
          to={`/finance/projects/view/${params.row.contract_id}`}
        >
          {" "}
          <span title={params.row.project.project_no}>{params.row.project.project_no}</span>
        </Link>) : params.row.project.project_no
      }
    },
    {
      field: "customer_name",
      headerName: "Contact Person",
      flex: 1.5,
      sortable: false,
      filterable: false,
    },
    {
      field: "$payment_milestone.name$",
      headerName: "Type",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return (<span title={params.row.payment_milestone.name}>{params.row.payment_milestone.name}</span>)
      }
    },
    {
      field: "total_amount",
      headerName: "Invoice Amount",
      flex: 1.5,
      sortable: false,
      filterable: false,
      type:"number",
      renderCell(params) {
        return (<span>{currencyMask(params.row.total_amount)}</span>)
      }
    },
    {
      field: "due_date",
      headerName: "Due Date",
      type: "date",
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter: (params) => new Date(params?.row?.due_date),
      renderCell(params) {
        const orderDate = new Date(params?.row?.due_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    }   
  ];  

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };


  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection, dateRange]);

  return (
    <React.Fragment>
      {loading ? (
         
         <Card className="p-3 w-100 pageContentInner rounded-2 h-100">
           <TableSkeleton />
         </Card>
      
      ) : (
        <div className="flex-grow-1 pageContent  h-100">
            <Card className="p-3 w-100 pageContentInner rounded-2 h-100">
            <Row>
                      <Col>
                        <h5 className="fs-18 fw-bold info-text mb-0">Outstanding Invoices</h5>
                      </Col>
                      <Col className="text-end">
                        <Link to="/finance/invoices" className="link-text text-decoration-none fw-bold">View All 
                          <span className="caret-icon">
                          <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.40001 4L0.333344 0.933333L1.26668 0L5.26668 4L1.26668 8L0.333344 7.06667L3.40001 4Z" fill="#297FCE"/>
                          </svg>
                          </span>
                        </Link>
                      </Col>
                    </Row>
              
              <Row>
                <Col>
                <DataGrid
                  rows={data}               
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2 table-custom"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"   
                  disableRowSelectionOnClick             
                  pageSizeOptions={[5,10]}
                  localeText={{ noRowsLabel: "No Record Found" }}
                /> 
                </Col>
              </Row>
            </Card>
          </div>        
      )}
    </React.Fragment>
  );
};

export default FinancePendingOutstandingInvoices;
