import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function TableSkeleton() {
  return (
    <div className="p-1">
      <div className="mb-1">
        <Skeleton variant="rounded" width="100%" height={50} />
      </div>
      <Stack spacing={2} >
      
      <Skeleton variant="rounded" width="100%" height={20}  />
      <Skeleton variant="rounded" width="100%" height={20}  />
      <Skeleton variant="rounded" width="100%" height={20}  />
      <Skeleton variant="rounded" width="100%" height={20}  />
      <Skeleton variant="rounded" width="100%" height={20}  />
      <Skeleton variant="rounded" width="100%" height={20}  />
      <Skeleton variant="rounded" width="100%" height={20}  />

 
 
      </Stack>
    </div>
  );
}
