import React, { useCallback, useEffect, useState } from "react";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { Button, Card } from "react-bootstrap";
import Loader from "../../components/common/Loader";
import ChecklistIcon from "@mui/icons-material/Checklist";
import MyTask from "./Mytask";
import TaskInProgress from "./TaskInProgress";
import { useNavigate } from "react-router";
 
const Index = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handlePreviousTask =  () => {
    navigate("/tasks/previous-task");
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-semibold h4 my-2">Task</h1>
              </div>
              <div>
              
                <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={handlePreviousTask}
                   
                >
                    <ChecklistIcon className="me-2" />
                  
                  Previous Tasks
                </Button>
                 
              </div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
             
              <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
                 <MyTask />  

                <div className="mt-4">
                   <TaskInProgress />  
                </div>
              </div>
               

             
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Index;
