import * as yup from "yup";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_30_CHAR,
  MAXIMUM_255_CHAR,
  ALPHA_NUMERIC_ALLOW
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";

export const addSchema = (id: string | number | undefined) => {
  return yup.object({
    name : yup.string().trim().required().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(30,MAXIMUM_30_CHAR).label('Name'),
    type: yup.object().required().label("Asset Type"),
    serial_number : yup.string().trim().required().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(30,MAXIMUM_30_CHAR).label('Serial Number'),
    amount : yup.number().required()
    .transform((originalValue, originalObject) => (originalObject.price === '' ? null : originalValue))
    .typeError('Amount must be a number')
    .max(9999999999.99, 'Price must be less than or equal to 10 digits')
    .positive('Price must be a positive number')
    .test('decimal-places', 'Amount must have up to 2 decimal places', (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      const [, decimalPart] = value.toString().split('.');
      return !decimalPart || decimalPart.length <= 2;
    }),
    vendor_id: yup.object().required().label("Vendor"),
    invoice_number : yup.string().trim().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(30,MAXIMUM_30_CHAR).label('Invoice Number'),
    description :  yup.string().trim().required().matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).required().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).label("Description")
  });
}
