import * as yup from "yup";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_500_CHAR,
  ALPHA_NUMERIC_ALLOW
} from "../../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../../validations/regex";
import { Employee, MEETING_SOURCE_PAGES } from "../../../../constants/constants";

interface Question{
  label: string;
  type: string;
  emotion_options: string[] | null;
  colleague_comment: string | null;
  manager_comment: string | null;
}

export const MeetingNotesValidationSchema = (questions:Record<string, Question>, source: string) => {
    const yupSchema:Record<string, any> = {};

    Object.keys(questions).map(key => {
       const questionsData = questions[key];
        let fieldSchema = yup.string();
        if(questionsData.type === 'subjective'){
          fieldSchema = yup.string().trim().required('This field is required.').matches(ALPHANUMERIC_WITH_SPACE, ALPHA_NUMERIC_ALLOW).min(3,MINIMUM_3_CHAR).max(500,MAXIMUM_500_CHAR);
        }

        //Objective question's validation is rquired for colleague only
        if(questionsData.type === 'objective_emotion' && questionsData.emotion_options && source === MEETING_SOURCE_PAGES.employee){
          fieldSchema = fieldSchema.required('Select one option.').oneOf(questionsData.emotion_options);
        }
        
        yupSchema[key] = fieldSchema;
    });

    return yup.object(yupSchema);

};
