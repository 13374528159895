import { useRef, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { ForgotPasswordSchema } from "../../../validations/Auth";
import { Formik } from "formik";
import { AuthHeader } from "../authHeader/AuthHeader";
import InputText from "../../../components/common/InputText";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import LogoBanner from "../../../assets/images/login-right-banner.png";
import BackArrow from "../../../assets/images/back-arrow.svg";
import CustomButton from "../../../components/common/CustomButton";
import { toast } from "react-toastify";

import { forgotPasswordAPI } from "../../../services/authService";
import { CAPTCH_INVALID } from "../../../validations/ValidationErrors";
import Captcha from "../captcha";
export const ForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [captchaCode, setCaptchaCode] = useState("");

  const submitForm = async (email: string, actions: any) => {
    if (!captchaCode) {
      toast.error(CAPTCH_INVALID);
      return;
    }

    setLoading(true);

    forgotPasswordAPI({ email })
      .then((response) => {
        setLoading(false);
        toast.success(response.message);
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const onChangeCaptchaCode = (value: any) => {
    setCaptchaCode(value);
  };

  return (
    <Formik
      validationSchema={ForgotPasswordSchema}
      initialValues={{
        email: "",
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        submitForm(values.email, actions);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <div className="container-fluid">
          <div className="row bg-white">
            <div className="col-md-6 col-xl-4">
              <div className="d-flex  h-100 w-100">
                <div className="align-content-between d-flex flex-wrap min-vh-100 login-left-mobile mw-350 mx-auto py-4 w-100">
                  <AuthHeader />
                  <div className="d-inline-block mt-5  w-100">
                    <Form className="" noValidate onSubmit={handleSubmit}>
                      <h6 className="fw-bold fs-32  mb-0">Forgot Password</h6>
                      <p className="fs-13">Enter your email address</p>
                      <InputText
                        controlId="email"
                        label="Email Address"
                        placeholder="Enter Email Address"
                        touched={touched.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.email}
                        value={values.email}
                        name="email"
                      />
                       
                       <Captcha  onChangeCaptchaCode={onChangeCaptchaCode}/>

                      <Stack
                        direction="horizontal"
                        gap={3}
                        className="justify-content-between"
                      >
                        <CustomButton
                          type="submit"
                          loading={loading}
                          className="fw-semibold fs-13 text-white mw-90 mt-2"
                          variant="primary"
                        >
                          Send
                        </CustomButton>
                      </Stack>
                    </Form>
                  </div>
                  <div className="fs-13">
                    <Image
                      className="img-fluid me-2"
                      src={BackArrow}
                      alt="Back Arrow"
                    />{" "}
                    Back to{" "}
                    <Link className="fs-13 text-decoration-none fw-bold" to="/">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-xl-8 login-right'>
              <div className="left-0 login-right-box lh-38 p-3 px-4 position-absolute text-white top-30 z-2 fw-300 mw-366 fs-28">
                <strong className="fw-bold">
                  Enabling & restoring independence
                </strong>{" "}
                in your own home
              </div>
              <Image
                className="img-fluid "
                src={LogoBanner}
                alt="Logo Banner"
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
