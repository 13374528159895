import { axios } from "../services/axios";
import {
  FORGOT_API,
  LOGIN_API,
  LOGOUT,
  REFRESH_TOKEN,
  RESEND_CODE,
  RESET_API,
  VERIFICATION,
  ALL_USER_LIST,
  ALL_MANAGER_LIST,
  ALL_REPORTING_MANAGER_LIST,
  COMPANY_DETAIL,
  GET_PROFILE,
  UPDATE_ADMIN_PROFILE,
  CHANGE_PASSWORD,
  UPDATE_EMPLOYEE_PROFILE_REQUEST,
  GENERAL_DETAIL,
  ALL_PROJECT_MANAGER_LIST,
  
} from "../constants/paths";



/**
 * Login DTO
 * @export
 * @typedef {LoginCredentialsDTO}
 */
export type LoginCredentialsDTO = {
  email: string;
  password: string;
  
};

/**
 * MFA Code DTO
 * @export
 * @typedef {LoginVerificationCodeDTO}
 */
export type LoginVerificationCodeDTO = {
  code: string;
  user_id: number;
};

/**
 * MFA Code DTO
 * @export
 * @typedef {ResetPasswordDTO}
 */
export type ResetPasswordDTO = {
  password: string;
  token: string;
};

/**
 * MFA Code DTO
 * @export
 * @typedef {LogoutDTO}
 */
export type LogoutDTO = {
  refreshToken: string;
};

/**
 * MFA Code DTO
 * @export
 * @typedef {ForgotPasswordDTO}
 */
export type ForgotPasswordDTO = {
  email: string;
};

/**
 * Resend MFA Code DTO
 * @export
 * @typedef {ResendMFACodeDTO}
 */
export type ResendMFACodeDTO = {
  user_id: number;
};

/**
 * Login API Call
 * @param {LoginCredentialsDTO} data
 * @returns {Promise<any>}
 */
export const loginWithUserNameAndPassword = (
  data: LoginCredentialsDTO
): Promise<any> => {
  return axios.post(LOGIN_API, data);
};

/**
 * MFA Authentication API Call
 * @param {LoginCredentialsDTO} data
 * @returns {Promise<any>}
 */

export const loginWithVerificationCode = (
  data: LoginVerificationCodeDTO
): Promise<any> => {
  return axios.post(VERIFICATION, data);
};

/**
 * Forgot Password API Call
 * @param {ForgotPasswordDTO} data
 * @returns {Promise<any>}
 */
export const forgotPasswordAPI = (data: ForgotPasswordDTO): Promise<any> => {
  return axios.post(FORGOT_API, data);
};

/**
 * Reset Password API Call
 *
 * @param {ResetPasswordDTO} data
 * @returns {Promise<any>}
 */
export const resetPasswordAPI = (data: ResetPasswordDTO): Promise<any> => {
  return axios.post(RESET_API, data);
};

/**
 * Logout API Call
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */
export const logoutAPI = (data: LogoutDTO): Promise<any> => {
  return axios.post(LOGOUT, data);
};

/**
 * Resend MFA Code API Call
 *
 * @returns {Promise<any>}
 */
export const resendMFACode = (data: ResendMFACodeDTO): Promise<any> => {
  return axios.post(RESEND_CODE, data);
};


/**
 * Refresh Token API
 *
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */
export const refreshTokenCALL = (data: LogoutDTO): Promise<any> => {
 return  axios.post(REFRESH_TOKEN, data);
};


export const getAllUsersApiCall = ():Promise<any> =>{
   return axios.get(ALL_USER_LIST);
}

export const getAllManagersApiCall = ():Promise<any> =>{
   return axios.get(ALL_PROJECT_MANAGER_LIST);
}

export const getAllManagerList = (params:any|null = {}):Promise<any> =>{
  return axios.get(ALL_MANAGER_LIST,{params});
}

export const getAllReportingManagerList = (params:any|null = {}):Promise<any> =>{
  return axios.get(ALL_REPORTING_MANAGER_LIST,{params});
}
 
export const getCompanyDetail = ():Promise<any> =>{
  return axios.get(COMPANY_DETAIL);
}

export const getMileageCost = ():Promise<any> =>{
  return axios.get(`${GENERAL_DETAIL}/mileage-cost`);
}
 

export const getProfileAPI = (id: string): Promise<any> => {
  return axios.get(GET_PROFILE);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const editProfile = (data: any): Promise<any> => {
  const transformData = {
    ...data,
    keen_relation1: data?.keen_relation1?.key,
    keen_relation2: data?.keen_relation2?.key,    
  };
  transformData.user_photo = data.user_photo;
  return axios.put(UPDATE_EMPLOYEE_PROFILE_REQUEST, transformData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @param {formData: FormData } params
 * @returns Promise<any>
 */
export const editAdminProfile = (data: any): Promise<any> => {
  return axios.post(UPDATE_ADMIN_PROFILE, data);
};

/**
 * @param {formData: FormData } params
 * @returns Promise<any>
 */
export const changePasswordAPI = (data: any): Promise<any> => {
  const trnnsformData = {
    currentPassword: data.current_password,
    password: data.password
  };

  return axios.post(CHANGE_PASSWORD, trnnsformData);
};
