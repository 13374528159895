import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { deleteDealApi } from "../../services/dealService";
import CustomButton from "./CustomButton";
import { toast } from "react-toastify";
import Loader from "./Loader";
import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
} from "../../validations/ValidationErrors";
import InputTextArea from "./InputTextArea";

interface IDeletePoup {
  deleteRemark: any;
}

interface AddProps {
  isOpen: boolean;
  deletedId: any;
  onClose: () => void;
  onSubmitData: (formData:any, deletedId:number) => void;
  deletedData?: IDeletePoup | null;
  label : string;
  text : string;
  reasonText : string;
}

interface FormValues {
  deleteRemarks: string;
}
const DeleteWithReasonPopup: React.FC<AddProps> = ({
  isOpen,
  onClose,
  deletedData,
  deletedId,
  label,
  text,
  onSubmitData,
  reasonText
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const addSchema = yup.object({
    deleteRemarks: yup.string().min(3).required().label(`${reasonText} Remarks`),
  });

  const initialValues = {
    deleteRemarks: "",
  };

  //  const id  = deletedData && deletedData.id ? deletedData.id :""
  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
   // setLoading(true);
    const formData: any = {
      ...values,
    };
    onSubmitData(formData, deletedId);
   // console.log(formData, deletedId);
    return;
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal" show={isOpen} onHide={onClose}>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <div className="delete-title text-start">{label}</div>
                  <div className="sure-delete text-start">
                    {text}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <InputTextArea
                        controlId="deleteRemarks"
                        label={`Reason For ${reasonText}`}
                        placeholder={`Enter Reason for ${reasonText}`}
                        touched={touched.deleteRemarks}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.deleteRemarks}
                        value={values.deleteRemarks}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Submit
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default DeleteWithReasonPopup;
