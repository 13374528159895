import React, {  useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Loader from '../../components/common/Loader';
import { Card ,Row, Col} from 'react-bootstrap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import contract from '../../assets/images/contract.svg'
import moreIcon from '../../assets/images/more_horiz.svg'
import descriptionIcon from '../../assets/images/description.svg'

import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import Form from "react-bootstrap/Form";
import { FieldArray, Formik, getIn } from "formik";
import { addEmployeeSchema } from "./validation";
import InputText from "../../components/common/InputText";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Slider from '@mui/material/Slider';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Box from '@mui/material/Box';
const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '10',
  },
];

function valuetext(value: number) {
  return `${value}`;
}
function valueLabelFormat(value: number) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}
const SurveyProcess: React.FC = () => {
   

   
    return (
        
          <div className="d-flex flex-column h-100 pageContainer px-sm-0 border-left w-100 pb-sm-1">
            <div className="pageHeader position-relative px-4 py-3  text-white survery-process-bg d-flex align-items-center justify-content-between">
              <div className="survery-title">
                <div className="fs-20 fw-bold">Survey Template</div>
                <div className="fs-14 opacity-7 fw-normal">last edited by John Smith at  8 Jun 2023 1:09 PM</div>
              </div>
              

              <div className="fs-18 position-absolute text-center text-uppercase w-100 fw-bold lt-30 left-0 survery-process-top-text">Survey Process</div>

              <div className="fs-14">Page 1 of 11</div>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent">
              <Row className="gx-0 min-h-100">
                <div className="col-md-3 card ">
                  <ul className="p-3 survery-list">
                    <li>
                      <a href="" className="active">Delivery and Location</a>
                    </li>
                    <li>
                      <a href="">Project Detail</a>
                    </li>
                    <li>
                      <a href="">Home Services 
                      <span className="fs-12 control-label fw-normarl w-100 d-block">(Plumbing)</span>
                      </a>
                    </li>
                    <li>
                      <a href="">HWC Services
                      <span className="fs-12 control-label fw-normarl w-100 d-block">(Heating, Waste and Soil Connections, Compliance)</span>
                      </a>
                    </li>
                    <li>
                      <a href="">Flooring, Wall Covering</a>
                    </li>
                    <li>
                      <a href="">Grab Rails</a>
                    </li>
                    <li>
                      <a href="">Order Details</a>
                    </li>
                    <li>
                      <a href="">Services
                      <span className="fs-12 control-label fw-normarl w-100 d-block"><span className="fs-12 control-label fw-normarl w-100 d-block">(Plastering/Decoration, Home, Height)</span></span>
                      </a>
                    </li>
                    <li>
                      <a href="">Installer & Project Notes, Time Duration</a>
                    </li>
                    <li>
                      <a href="">Sign Off</a>
                    </li>
                    <li>
                      <a href="">Compare & Finish</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-9 pt-4">
                  <div className="max-width-700 mx-auto survery-middle-content">
                    <h3 className="fs-20 fw-bold info-text mb-3">Delivery and Location</h3>
                    
                    <Card className="p-3 w-100  mb-3">
                      <div className="d-flex flex-column rounded-0 ">
                    
                        <Row className="d-flex">
                          <Col className="col-md-11">
                              <p className="fs-14 mb-2">What type of property is it?*</p>
                              <div className="w-100 survery-input">
                              <InputText
                                controlId="first_name"
                                label=""
                                placeholder="Enter first name"
                                //touched={}
                                //handleBlur={handleBlur}
                               // handleChange={handleChange}
                                //errorsField={}
                                //value={}
                              />
                              </div>
                          </Col>
                          <Col className="col-md-1 text-end">
                          <Dropdown>
                            <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                              <img src={moreIcon}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                            
                          </Col>
                          
                        </Row>
                        
                        
                            
                      </div>
                    </Card>

                    <Card className="p-3 w-100  mb-3">
                      <div className="d-flex flex-column rounded-0 ">
                    
                        
                        
                        <Row className="d-flex">
                          <Col className="col-md-11">
                              <p className="fs-14 mb-2">Are there any parking issues or requirements to access the property?*</p>
                              <div className="w-100 survery-input">
                              <InputText
                                controlId="first_name"
                                label=""
                                placeholder="Enter if any"
                                //touched={}
                                //handleBlur={handleBlur}
                               // handleChange={handleChange}
                                //errorsField={}
                                //value={}
                              />
                              </div>
                          </Col>
                          <Col className="col-md-1 text-end">
                          <Dropdown>
                            <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                              <img src={moreIcon}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                            
                          </Col>
                          
                        </Row>
                            
                      </div>
                    </Card>
                    <p className="fs-14 mb-2 px-3 ">Main roads, parking permits, double yellows, and ground type (i.e. not drivable with the van) need to be captured within the comments to enable delivery planning.</p>
                    <Card className="p-3 w-100  mb-3">
                      <div className="d-flex flex-column  rounded-0 ">
                    
                        
                        
                        <Row className="d-flex">
                          <Col className="col-md-11">
                              <p className="fs-14 mb-2">Is there anywhere to store products prior to installation start? if so where? (please discuss with the client and update notes)</p>
                              <div className="w-100 survery-input">
                              <InputText
                                controlId="first_name"
                                label=""
                                placeholder="Enter if any"
                                //touched={}
                                //handleBlur={handleBlur}
                               // handleChange={handleChange}
                                //errorsField={}
                                //value={}
                              />
                              </div>
                          </Col>
                          <Col className="col-md-1 text-end">
                          <Dropdown>
                            <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                              <img src={moreIcon}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                            
                          </Col>
                          
                        </Row>
                            
                      </div>
                    </Card>
                    <Card className="p-3 w-100  mb-3">
                      <div className="d-flex flex-column  rounded-0 ">
                    
                        <Row className="d-flex">
                          <Col className="col-md-11">
                              <p className="fs-14 mb-2">Please confirm amount of grabrails required*</p>
                              <div className="w-100 survery-input">
                              <Slider
                              aria-label="Always visible"
                              defaultValue={40}
                              getAriaValueText={valuetext}
                              step={10}
                              marks={marks}
                              valueLabelDisplay="on"
                            />
                              </div>
                          </Col>
                          <Col className="col-md-1 text-end">
                          <Dropdown>
                            <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                              <img src={moreIcon}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                            
                          </Col>
                          
                        </Row>
                        
                        
                            
                      </div>
                    </Card>
                    <Card className="p-3 w-100  mb-3">
                      <div className="d-flex flex-column  rounded-0 ">
                    
                        <Row className="d-flex">
                          <Col className="col-md-11 d-flex pe-0">
                              <p className="fs-14 mb-2">Please capture any required photos to support actions required in notes, i.e. location of soil connection or specific location that a product or item requires to be installed</p>
                              <label className="browse-cover ms-2 survey-browse">
                                <input type="file"/>
                                <span className="btn btn-outline-info fw-bold fs-14 text-no-wrap"><FileUploadIcon /> Add Media</span>
                              </label>
                              
                          </Col>
                          <Col className="col-md-1 text-end">
                          <Dropdown>
                            <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                              <img src={moreIcon}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                            
                          </Col>
                          
                        </Row>
                        
                        
                            
                      </div>
                    </Card>


                    <Card className="p-3 w-100  mb-3 survey-finding">
                     <h3 className="fs-20 fw-bold info-text mb-3">Survey Findings</h3>
                      <Card className="p-3 w-100  mb-3">
                        <div className="d-flex flex-column rounded-0 ">
                      
                          <Row className="d-flex">
                            <Col className="col-md-11">
                                <p className="fs-14 mb-2">What type of property is it?*</p>
                                <div className="w-100 survery-input">
                                  <p className="fw-bold mb-0">Bungalow</p>
                                </div>
                            </Col>
                            <Col className="col-md-1 text-end">
                            <Dropdown>
                              <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                                <img src={moreIcon}/>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1" className="fs-13 fw-bold"><img src={descriptionIcon}/> Add Discrepancy Note</Dropdown.Item>
                                
                              </Dropdown.Menu>
                            </Dropdown>
                              
                            </Col>
                            
                          </Row>
                          
                          
                              
                        </div>
                      </Card>

                      
                      <Card className="p-3 w-100  mb-3">
                        <div className="d-flex flex-column  rounded-0 ">
                          <Row className="d-flex">
                            <Col className="col-md-11">
                                <p className="fs-14 mb-2">Are there any parking issues or requirements to access the property?*</p>
                                <div className="w-100 survery-input">
                                <p className="fw-bold mb-0">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                            </Col>
                            <Col className="col-md-1 text-end">
                            <Dropdown>
                              <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                                <img src={moreIcon}/>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1" className="fs-13 fw-bold"><img src={descriptionIcon}/> Add Discrepancy Note</Dropdown.Item>
                                
                              </Dropdown.Menu>
                            </Dropdown>
                              
                            </Col>
                            
                          </Row>
                              
                        </div>
                      </Card>
                      <Card className="p-3 w-100  mb-3">
                        <div className="d-flex flex-column  rounded-0 ">
                      
                          <Row className="d-flex">
                            <Col className="col-md-11">
                                <p className="fs-14 mb-2">Please confirm amount of grabrails required*</p>
                                <div className="w-100 survery-input">
                                <Slider
                                aria-label="Always visible"
                                defaultValue={40}
                                getAriaValueText={valuetext}
                                step={10}
                                marks={marks}
                                valueLabelDisplay="on"
                              />
                                </div>
                            </Col>
                            <Col className="col-md-1 text-end">
                            <Dropdown>
                              <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                                <img src={moreIcon}/>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1" className="fs-13 fw-bold"><img src={descriptionIcon}/> Add Discrepancy Note</Dropdown.Item>
                                
                              </Dropdown.Menu>
                            </Dropdown>
                              
                            </Col>
                            
                          </Row>
                          
                          
                              
                        </div>
                      </Card>
                      <Card className="p-3 w-100  mb-3">
                        <div className="d-flex flex-column  rounded-0 ">
                      
                          <Row className="d-flex">
                            <Col className="col-md-11 d-flex pe-0">
                                <p className="fs-14 mb-2">Please capture any required photos to support actions required in notes, i.e. location of soil connection or specific location that a product or item requires to be installed</p>
                                <label className="browse-cover ms-2 survey-browse">
                                  <input type="file"/>
                                  <span className="btn btn-outline-info fw-bold fs-14 text-no-wrap"><FileUploadIcon /> Add Media</span>
                                </label>
                                
                            </Col>
                            <Col className="col-md-1 text-end">
                            <Dropdown>
                              <Dropdown.Toggle className="survery-menu-icon" id="dropdown-basic">
                                <img src={moreIcon}/>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1" className="fs-13 fw-bold"><img src={descriptionIcon}/> Add Discrepancy Note</Dropdown.Item>
                                
                              </Dropdown.Menu>
                            </Dropdown>
                              
                            </Col>
                            
                          </Row>
                          
                          
                              
                        </div>
                      </Card>
                    </Card>

                    <div className="d-flex justify-content-between mt-3 border-top pt-3 survery-bottom-button">
                      <button className="btn btn-outline-info  me-3 fs-14 "><KeyboardArrowLeftIcon className="me-1 fs-18"/>Previous Page</button>
                      <button className="btn btn-primary text-white fs-14">Next Page <KeyboardArrowRightIcon className="ms-1 fs-18"/></button>
                    </div>
                    
                  </div>
                  
                </div>
              </Row>
              
            </div>
          </div>
        
    );
};

export default SurveyProcess;