import React, { useState, useEffect, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectDetails } from "../../../services/project.service";
import { Button, Col, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { ViewProject } from "../View";
import Loader from "../../../components/common/Loader";
import ProjectSurvey from "../Survey";
import { ProjectProcurement } from "../Procurement";
import { ProjectInstallation } from "../Installation";
import { PROJECT_PREFIX } from "../../../constants/constants";
const ProcessStart: FC<any> = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [mainProjectControls, setMainProjectControl] = useState<any>({});
  const [projectData, setProjectData] = useState<any>({});
  const [activeTab, setActiveTab] = useState<string>("PROJECT_ONBOARDING");
  const [activeTabisDone, setActiveTabIsDone] = useState<string>("0");

  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchProjectDetail(id).then(() => {
      setLoading(false);
    });
  }, []);

  const fetchProjectDetail = (id: any) => {
    return new Promise((resolve, reject) => {
      getProjectDetails(id)
        .then((response) => {
          getProjectControl(response.data.project);
          setProjectData(response.data.project);

          resolve(true);
        })
        .catch((error) => {
          navigate("/projects");
        })
        .finally(() => {
          setLoading(false);
          resolve(true);
        });
    });
  };
  const getProjectControl = (projectDataObj: any) => {
    setMainProjectControl(projectDataObj?.project_page_control);
    //set active tab which value is 0 or 1 first and break the loop
    for (let key in projectDataObj?.project_page_control) {
      if (
        projectDataObj?.project_page_control[key]?.value === 1 ||
        projectDataObj?.project_page_control[key]?.value === 0
      ) {
        setActiveTab(projectDataObj?.project_page_control[key].key);
        setActiveTabIsDone(projectDataObj?.project_page_control[key].value);
        const projectControls = projectDataObj?.project_page_control.find(
          (item: any) =>
            item.key == projectDataObj?.project_page_control[key].key
        );

        break;
      }
    }
  };

  const handleTabChange = (tab: any) => {
    if (tab.value == 2 || tab.value == 1 || tab.value == 3) {
      setActiveTab(tab.key);
      setActiveTabIsDone(tab.value);
    }
  };

  return (
    <>
      <React.Fragment>
        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
            <div className="pageHeader px-3 py-2 my-1">
              <Row className="align-items-center">
                <Col className="col-md-12 col-xl-4">
                  <h1 className="h4 mt-2 mb-0">{PROJECT_PREFIX}{projectData?.project_no}</h1>
                </Col>
                <Col className="text-end col-md-12 col-xl-8">
                  {mainProjectControls &&
                    mainProjectControls.map((mainControl: any, index: any) => (
                      <button
                        className={`form-builder-link ${
                          mainControl.key == activeTab
                            ? "active"
                            : mainControl.value == 2
                            ? "previous"
                            : ""
                        }`}
                        onClick={() => handleTabChange(mainControl)}
                      >
                        {mainControl?.label}
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.06634 10.0666L10.7663 5.36659L9.83301 4.43325L6.06634 8.19992L4.16634 6.29992L3.23301 7.23325L6.06634 10.0666ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4916 4.39967 13.1416C3.58856 12.7916 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20801 10.411 0.858008 9.59992C0.508008 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508008 5.21103 0.858008 4.39992C1.20801 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20825 4.39967 0.858252C5.21079 0.508252 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508252 9.59967 0.858252C10.4108 1.20825 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7913 3.58881 13.1413 4.39992C13.4913 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4913 8.78881 13.1413 9.59992C12.7913 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7916 9.59967 13.1416C8.78856 13.4916 7.9219 13.6666 6.99967 13.6666Z"
                            fill="#21AC59"
                          />
                        </svg>
                      </button>
                    ))}
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
              {activeTab === "PROJECT_ONBOARDING" && (
                <div>
                  <ViewProject
                    setActiveTab={setActiveTab}
                    activeTabisDone={activeTabisDone}
                  />
                </div>
              )}

              {activeTab === "SURVEY" && (
                <>
                  <ProjectSurvey
                    setActiveTab={setActiveTab}
                    activeTabisDone={activeTabisDone}
                  />
                </>
              )}

              {activeTab === "PROCUREMENT" && (
                <div>
                  {" "}
                  <ProjectProcurement
                    setActiveTab={setActiveTab}
                    activeTabisDone={activeTabisDone}
                  />{" "}
                </div>
              )}

              {activeTab === "INSTALL" && (
                <div>
                  <ProjectInstallation />
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    </>
  );
};

export default ProcessStart;
