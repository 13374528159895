import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function CounterSkeleton() {
  return (
    <>
      <div className="mb-3">
        <Skeleton variant="rounded" width="100%" height={50} />
      </div>
      <Stack spacing={2} >
      <div className="d-flex justify-content-around">
      <Skeleton variant="rounded" width="80%" height={20} />
      <Skeleton variant="rounded" width="5%" height={20} />

      </div>

      <div className="d-flex justify-content-around">
      <Skeleton variant="rounded" width="80%" height={20} />
      <Skeleton variant="rounded" width="5%" height={20} />

      </div>
      <div className="d-flex justify-content-around">
      <Skeleton variant="rounded" width="80%" height={20} />
      <Skeleton variant="rounded" width="5%" height={20} />

      </div>

      </Stack>
    </>
  );
}
