import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from 'moment';
import {
    GridRowId,
  } from "@mui/x-data-grid";
import EmployeeMeetingsTable from "./EmployeeMeetingsTable";
import {MEETING_TYPES} from "../../../../constants/constants";
import {
    yearType, meetingTypes
} from "../../../../services/employeeService";
import AddEmployeeMeeting from "./Add";
import ViewMeetingNotesPopup from "./ViewMeetingNotes";
import { selectAuthentication } from "../../../../features/Auth/authSlice";
import MeetingNotesFormPopup from "./MeetingNotesForm";

interface EmployeeMeetingsProps{
  employeeID: number;
  source: string;
}


const EmployeeMeetings: React.FC<EmployeeMeetingsProps> = ({employeeID, source}) => {
    const { user }: any = useSelector(selectAuthentication);
    const [year, setYear] = useState<number>(moment().year());
    // Creating a mutation object state for every meeting type which will help to rerender the respective meeting table only when needed.
    const [isMutation, setIsMutation] = useState<meetingTypes>(Object.keys(MEETING_TYPES).reduce((result, key) => {
      result[key  as keyof meetingTypes] = false;
      return result;
    }, {} as meetingTypes));

    const [isNewMeetingPopupOpen, setNewMeetingPopupOpen] = useState(false);
    const [isViewMeetingPopupOpen, setViewMeetingPopupOpen] = useState(false);
    const [isMeetingNotesPopupOpen, setMeetingNotesPopupOpen] = useState(false);
    const [meetingId, setMeetingId] = useState<GridRowId | null>(null);
    const [meetingType, setMeetingType] = useState<string>('one_on_one');
  
    /**
     * Add New meeting click handler
     */
    const addNewMeeting = (meetingType: string) => {
      setMeetingId(null);
      setMeetingType(meetingType);
      setNewMeetingPopupOpen(!isNewMeetingPopupOpen);
    };

    /**
      * New meeting or edit existing meeting popup close handler
    */
    const handleNewMeetingClose = () => {
      setNewMeetingPopupOpen(false);
      setIsMutation((prevIsMutation: any) => {
        return {...prevIsMutation, [meetingType]: !isMutation[meetingType as keyof meetingTypes]}});
      setMeetingId(null);
    };

    /**
      * Edit meeting click handler
    */
    const handleEditClick = async (id: GridRowId, meetingType: string, empDetail?: any) => {
      setMeetingId(id);
      setMeetingType(meetingType);
      setNewMeetingPopupOpen(!isNewMeetingPopupOpen);
    };

    /**
     * View meeting click handler
    */
    const handleViewClick = (id: GridRowId) => {
      setMeetingId(id);
      setViewMeetingPopupOpen(!isViewMeetingPopupOpen);
    };
    
    /**
      * View meeting popup close handler
    */
    const handleViewMeetingClose = () => {
      setViewMeetingPopupOpen(false);
      setMeetingId(null);
    };

    /**
      * meeting notes form popup Open handler
    */
    const openMeetingNotesForm = (id: GridRowId, meetingType: string) => {
      setMeetingId(id);
      setMeetingType(meetingType);
      setMeetingNotesPopupOpen(!isMeetingNotesPopupOpen);
    }

    /**
      * meeting notes form popup close handler
    */
    const handleMeetingNotesClose = () => {
      setMeetingNotesPopupOpen(false);
      setIsMutation((prevIsMutation: any) => {
        return {...prevIsMutation, [meetingType]: !isMutation[meetingType as keyof meetingTypes]}});
      setMeetingId(null);
    };

    /**
      * Handler function when year changes
    */
    const handleChangeYear = (year: yearType) => {
        setYear(year.value);
    }

    return (
        <React.Fragment>
            {Object.keys(MEETING_TYPES).map(key => (
                <EmployeeMeetingsTable 
                    employeeID={employeeID} 
                    meetingType={key} 
                    meetingLabel={MEETING_TYPES[key as keyof typeof MEETING_TYPES]} 
                    year={year} 
                    onChangeYear={handleChangeYear} 
                    onAdd={addNewMeeting}
                    onEdit={handleEditClick}
                    onView={handleViewClick}
                    isMutation={isMutation}
                    loggedInID={user.user.id}
                    onOpenMeetingNotes={openMeetingNotesForm}
                    source={source}
                  />
            ))}

        {isNewMeetingPopupOpen && (
            <AddEmployeeMeeting
              isOpen={isNewMeetingPopupOpen}
              onClose={handleNewMeetingClose}
              employeeID={employeeID}
              meetingId={meetingId}
              meetingType={meetingType}
              source={source}
              loggedInName={user.user?.full_name}
              loggedInID={user.user.id}
            />
          )}

        {isViewMeetingPopupOpen && (
            <ViewMeetingNotesPopup
              isOpen={isViewMeetingPopupOpen}
              onClose={handleViewMeetingClose}
              meetingId={meetingId}
              source={source}
            />
          )}

        {isMeetingNotesPopupOpen && (
              <MeetingNotesFormPopup
                isOpen={isMeetingNotesPopupOpen}
                onClose={handleMeetingNotesClose}
                meetingId={meetingId}
                source={source}
              />
            )}

        </React.Fragment>
    );
};

export default EmployeeMeetings;
