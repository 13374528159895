import { Card } from "react-bootstrap";
import { Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputText from "../../../components/common/InputText";
import { LoginSchema } from "../../../validations/Auth";
import AddCircle from "../../../assets/images/add_circle.svg";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate, useParams } from "react-router-dom";
import { getVendorDetailsApi } from "../../../services/vendorService";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ProductReceived from "../../Product/View/product-received";
import { checkAbility, currencyMask } from "../../../utility/common";
import VendorRelatedItems from "./related-items";
import VendorPoHistory from "./po-history";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import Loader from "../../../components/common/Loader";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
export const ViewVendor = () => {
  const [loading, setLoading] = useState(true);
  const [vendorData, setVendorData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(AddPhotoAlternate);

  let { id } = useParams();
  const navigate = useNavigate();

  const editVendor = () => {
    console.log(232323);
    navigate(`/vendor/edit/${id}`);
  };

  const getVendorDetails = (id: string) => {
    setLoading(true);
    getVendorDetailsApi(id)
      .then((response) => {
        setVendorData(response.data);
        if (response.vendorImage) {
          setImagePreview(response.vendorImage);
        }
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/vendor");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/vendor");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/vendor");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getVendorDetails(id);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">
                  {vendorData?.vendor_name}
                </h1>
              </Col>
              <Col className="text-end">
                {/* <button className='fw-semibold fs-12 text-white  btn btn-info me-2 px-2' onClick={handleShow}><img src={FileUploadIcon}  alt="File Upload Icon"/></button> */}
                <div className="d-inline-block me-2 pt-0 pe-2 vertical-middle">
                  Credit Terms{" "}
                  <span className="info-tag py-1 px-2 ms-2 rounded-1 text-center fw-bold d-block fs-14 vertical-middle">
                    {vendorData?.credit_terms}
                  </span>
                </div>
                {checkAbility(
                  PERMISSION_ACCESS.EDIT,
                  PERMISSION_MODULE.VENDOR_MANAGEMENT
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => editVendor()}
                  >
                    <ModeEditIcon className="me-2 fs-15" />
                    Edit Vendor
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Row>
              <Col className="col-md-3">
                <Card className=" p-3 w-100 pageContentInner mb-3">
                  <div className="media">
                    <div className="project-profile me-3 vendor-dummy-img">
                      <img
                        className=""
                        src={imagePreview}
                        alt={vendorData?.contact_person}
                      />
                    </div>
                    <div className="media-body">
                      <label className="fs-16 fw-bold">
                        {vendorData?.contact_person}
                      </label>
                      <div className="fs-14 control-label">
                        {vendorData?.vendor_address}
                      </div>
                    </div>
                  </div>
                  <hr className="border-light" />
                  <div className="mb-1">
                    <PhoneIcon className="me-2 text-primary fs-15" />+
                    {vendorData?.phone_number}
                  </div>
                  <div className="mb-0">
                    <AlternateEmailIcon className="me-2 text-primary fs-15" />
                    {vendorData?.email}
                  </div>

                  <hr className="border-light" />
                  <div className="mb-1 d-flex">
                    <AccountBalanceWalletIcon className="me-2 text-primary fs-15" />
                    <div className="">
                      <label className="control-label fw-bold">
                        Preferred Payment Method
                      </label>
                      <div className="fs-14">
                        {vendorData.vendor_type == "website"
                          ? "Online"
                          : "Through Cheque"}
                      </div>
                    </div>
                  </div>
                  <hr className="border-light" />
                  <div className="d-flex fs-14 mb-2 justify-content-between">
                    Account Payable{" "}
                    <span className="fw-bold ">
                      {currencyMask(vendorData?.payable_amount)}
                    </span>
                  </div>
                  <div className="d-flex fs-14 mb-2 justify-content-between">
                    Account Recievable{" "}
                    <span className="fw-bold ">
                      {currencyMask(vendorData?.receivable_amount)}
                    </span>
                  </div>
                </Card>
              </Col>
              <Col className="col-md-9 vendor-box-right">
                <VendorRelatedItems vendorId={id} />
              </Col>
            </Row>
            <div className="pageHeader py-2 my-1">
              <VendorPoHistory vendorId={id} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
