import React from 'react';
import { Row, Col } from 'react-bootstrap';

interface Leave {
  leave_type_id: number;
  color_code?: string;
  svg?: string;
  name: string;
  leaves_count: number;
}

interface Props {
  leaveHistroyData: Leave[];
  leaveAvailableData: number;
}

const LeaveHistoryData: React.FC<Props> = ({ leaveHistroyData, leaveAvailableData }) => {
  const svgCode = `<svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.2" clip-path="url(#clip0_375_21360)">
      <path
          d="M23.4301 24.8033C21.3028 22.7998 19.4399 21.1716 17.8426 19.7754C12.8513 15.414 10.1025 13.0111 10.1025 8.42752C10.1026 3.70198 13.5628 0 17.979 0C21.2297 0 23.2712 1.93185 24.4649 3.71609C25.7205 1.80813 27.8014 0 30.8538 0C35.2714 0 38.7316 3.70198 38.7316 8.42761C38.7316 13.0969 35.8718 15.5856 30.6823 20.1031C29.1582 21.4289 27.3978 22.9615 25.3943 24.8132C24.8407 25.3244 23.9826 25.3249 23.4301 24.8033Z"
          fill="white" />
      <path
          d="M23.0604 31.415C23.0604 28.6886 21.52 26.1962 19.0814 24.9769L13.6261 22.2493C12.6688 21.7705 11.5424 22.4667 11.5424 23.537C11.599 23.8583 11.1671 27.0097 13.5088 28.5308L16.6462 30.5686C17.3126 31.003 17.5025 31.8944 17.0694 32.5609C16.6292 33.2357 15.7334 33.4115 15.0771 32.9841L10.6284 30.0934C9.40254 29.2967 8.6628 27.9341 8.6628 26.472V18.5538C8.6628 16.0354 7.23995 13.7333 4.98741 12.607C4.03008 12.1283 2.90381 12.8245 2.90381 13.8947V27.9066C2.90381 34.4165 9.36521 37.7113 9.08791 37.4233C10.6212 38.4456 11.5423 40.1665 11.5423 42.0093V47.5589C11.5423 48.354 12.1869 48.9986 12.982 48.9986H21.6205C22.4157 48.9986 23.0603 48.354 23.0603 47.5589V31.415H23.0604Z"
          fill="white" />
      <path
          d="M29.9188 24.9769L35.3741 22.2493C36.3315 21.7705 37.4578 22.4667 37.4578 23.537C37.4012 23.8583 37.8331 27.0097 35.4914 28.5308L32.354 30.5686C31.6876 31.003 31.4978 31.8944 31.9308 32.5609C32.371 33.2357 33.2668 33.4115 33.9232 32.9841L38.3718 30.0934C39.5977 29.2967 40.3374 27.9341 40.3374 26.472V18.5538C40.3374 16.0354 41.7603 13.7333 44.0128 12.607C44.9701 12.1283 46.0964 12.8245 46.0964 13.8947V27.9066C46.0964 34.4165 39.635 37.7113 39.9123 37.4233C38.379 38.4456 37.4579 40.1665 37.4579 42.0093V47.5589C37.4579 48.354 36.8133 48.9986 36.0182 48.9986H27.3797C26.5846 48.9986 25.9399 48.354 25.9399 47.5589V31.415C25.9398 28.6886 27.4802 26.1962 29.9188 24.9769Z"
          fill="white" />
  </g>
  <defs>
      <clipPath id="clip0_375_21360">
          <rect width="49" height="49" fill="white" />
      </clipPath>
  </defs>
</svg>`;
  return (
    <Row>
      {leaveHistroyData.map((leave: Leave) => (
        <Col className="col-md-3" key={`leave_${leave.leave_type_id}`}>
          <div
            className={`card p-4 mb-3 text-white asset-card`}
            style={{ backgroundColor: leave.color_code ? leave.color_code : '#EE3030' }}
          >
            <div
              className="asset-card-background"
              style={{
                backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(
                  leave.svg ? leave.svg : svgCode
                )}")`,
              }}
            />
            <div className="fs-16">Booked/Requested {leave.name}</div>
            <div className="fs-25 fw-bold">{leave.leaves_count}</div>
          </div>
        </Col>
      ))}
      <Col className="col-md-3">
        <div className={`card p-4 mb-3 text-white asset-card asset-card-available-leave`}>
          <div className="fs-16">Remaning Annual Leave</div>
          <div className="fs-25 fw-bold">{leaveAvailableData}</div>
        </div>
      </Col>
    </Row>
  );
};

export default LeaveHistoryData;
