import { Button, Card, Col, Row } from "react-bootstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { JSXElementConstructor, Key, ReactElement, ReactNode, SetStateAction, useEffect, useState } from "react";
import { getAnnoucementDashboardData, getEmployeeDashboardAlerts, markReadAnnoucementDashboardAlert } from "../../services/employeeService";
import ViewAnnouncementPopup from "../Announcement/View";
import { toast } from "react-toastify";
import { Scrollbar } from "react-scrollbars-custom";
const AlertsAndAnnouncements = () => {
  const [annoucementData, setAnnoucementData] = useState([]as any);
  const [alertData, setAlerttData] = useState([]as any);
  const [loading, setLoading] = useState(true);
  const [isViewPopupOpen, setViewPopupOpen] = useState(false);
  const [announcmentId, setAnnouncmentId] = useState(null);
  const [isMutation, setIsMutation] = useState(false);
  useEffect(() => {
    fetchAnnoucements();
    fetchAlerts();
  }, [isMutation]);
  const fetchAnnoucements = () => {
    getAnnoucementDashboardData()
      .then((res) => {
        setAnnoucementData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchAlerts = () => {
    getEmployeeDashboardAlerts()
      .then((res) => {
        setAlerttData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleAnnoucementView = (annoucement: any) => {
    setViewPopupOpen(true);
    if(annoucement?.type == 1 ){      
     // handleAnnoucementMarkRead(annoucement?.id);
    }
    setAnnouncmentId(annoucement?.id);
  }

  const handleAnnoucementMarkRead = (announcId: any) => {   
    markReadAnnoucementDashboardAlert(announcId)
      .then((response) => {
        setIsMutation(!isMutation);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
        setIsMutation(!isMutation);
      })
      .finally(() => {
        setIsMutation(!isMutation);
      });
  }

  // markReadAnnoucementDashboardAlert

  const handleClose = () => {    
    setViewPopupOpen(false);
    setAnnouncmentId(null);
    setIsMutation(!isMutation);
  };

  const colorizeKeywords = (str: string) => {
    const keywords = ['expire', 'delay', 'due'];
    //console.log(str)
    return str.replace(
      new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi'),
      '<span class="text-danger">$1</span>'
    );
  };

  return (
    <>
      <Row>
        <Col md={4} className="mb-3">
          <h2 className="fs-20 info-text fw-bold">Alerts</h2>
          <Card className="p-0 w-100 ">
            <Card.Body className="pt-1">
            <Scrollbar          
            className="alert-scroll"
          >
            {alertData.length > 0 ? alertData.map((alert: any, index: any) => (
              <div key={`alerts_${index}`} className="pb-2 pt-2 border-bottom">
                <span dangerouslySetInnerHTML={{ __html: colorizeKeywords(alert?.alert) }}></span>
                {/* Driving license <span className="text-danger">expire</span> on
                17/6/2023 */}
              </div>
            )) : <div className="pt-2 text-center align-items-center d-flex justify-content-center alert-scroll">No Alerts</div>}

          </Scrollbar>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8} className="mb-3">
          <h2 className="fs-20 info-text fw-bold">Announcements</h2>
          <Card className="p-0 w-100 ">
            <Card.Body className="pt-1">
            <Scrollbar          
            className="alert-scroll"
          >
              {annoucementData.length > 0 ? annoucementData.map((announcement: any, index: any) => (
              <div key={`annoucement_${index}`} className="border-bottom pb-2 pt-2 d-flex justify-content-between">
                <div>
                  {announcement?.title}
                </div>
                <div className="">
                  {
                    announcement?.type == 0 && (
                    <Button type="button" title="Mark this annoucement as read" variant="link" onClick={() => handleAnnoucementMarkRead(announcement.id)}>
                      <CheckCircleIcon className="text-green fs-20 me-2" />
                    </Button>
                    )
                  }
                  {
                    announcement.announcement_file ? (
                      <Button type="button" title="View Annoucement Document" variant="link" onClick={() => handleAnnoucementView(announcement)}>
                        <RemoveRedEyeIcon className="text-primary fs-20" />
                      </Button>
                    )
                    :
                    (
                      <Button type="button" title="Document not available" variant="link" className="cursor-not-allowed opacity-50" >
                        <RemoveRedEyeIcon className="text-primary fs-20" />
                      </Button>
                    )
                  }
                </div>
              </div>
              )) : <div className="pt-2 text-center align-items-center d-flex justify-content-center alert-scroll">No Announcements</div>}             
            </Scrollbar>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {isViewPopupOpen && (
            <ViewAnnouncementPopup
              isOpen={isViewPopupOpen}
              onClose={handleClose}    
              fromDashboard={true}          
              announcementId={announcmentId}
            />
          )}
    </>
  );
};

export default AlertsAndAnnouncements;
