import { Button, Card, Col, Row, Table } from "react-bootstrap";
import CustomButton from "../../../components/common/CustomButton";
import AddCircle from "../../../assets/images/add_circle.svg";
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from "react-router-dom";
import { checkAbility, currencyMask } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import WarningIcon from "@mui/icons-material/Warning";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Badge from "@mui/material/Badge";
import { DashboardStockLevel } from "./Stock-Levels";
import { DashboardPendingPo } from "./Pending-Po";
import { DashboardPendingDispatch } from "./Pending-Dispatch";
import { DashboardGoodMovement } from "./Good-Movement";
import { DashboardOrderReceived } from "./Order-Received";
import { DashboardPoRaised } from "./Po-Raised";
import { DashboardNumberPoRaised } from "./Number-Po-Raised";
import { DashboardInventoryTrend } from "./Inventory-Trends";
import React, { useEffect, useState } from "react";
import { getDelayedPoShipmentApi, getProductStockAlertApi } from "../../../services/warehouseService";
import Loader from "../../../components/common/Loader";

export const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [productAlertData, setProductAlertData] = useState<any>({});
  const [delayShipment, setDelayShipment] = useState<any>([]);
  const updateWarehouse = () => {
    navigate("update-warehouse");
  };

  const getProductStockAlert = () => {
    setLoading(true);
    getProductStockAlertApi()
      .then((response) => {
        setProductAlertData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDelayedPoShipment = () => {
   // setLoading(true);
    getDelayedPoShipmentApi()
      .then((response) => {
        setDelayShipment(response.data);
       // setLoading(false);
      })
      .catch((error) => {
      //  setLoading(false);
      })
      .finally(() => {
       // setLoading(false);
      });
  };

  useEffect(() => {
    getProductStockAlert();
    getDelayedPoShipment();
  }, []);

  const handleCriticalStockRedirect = () => {
    if(checkAbility(
      PERMISSION_ACCESS.LIST,
      PERMISSION_MODULE.PRODUCT_MASTER
    )){
      navigate("/product/minstock");
    }
  }

  const handlePoDelayedRedirect = () => {
    if(checkAbility(
      PERMISSION_ACCESS.LIST,
      PERMISSION_MODULE.WAREHOUSE
    )){
      navigate("/purchase-orders/delayed");
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">
                  Dashboard - Warehouse Manager
                </h1>
              </Col>
              <Col className="text-end">
                <Button
                  className="fw-semibold fs-13 text-danger bg-white me-3"
                  variant="outline-danger" title="Critical Stock" onClick={handleCriticalStockRedirect}
                >
                   
                    <Badge
                      badgeContent={
                        productAlertData?.minStockAlertCount ?? 0
                      }
                      color="primary"
                    >
                      <WarningIcon />
                    </Badge>
                   
                </Button>
                <Button
                  className="fw-semibold fs-13 text-warning bg-white me-3"
                  variant="outline-warning" title="Shipment Delay" onClick={handlePoDelayedRedirect}
                >
                  <Badge badgeContent={delayShipment?.length} color="primary">
                    <AccessTimeFilledIcon />
                  </Badge>
                </Button>
                {checkAbility(
                  PERMISSION_ACCESS.EDIT,
                  PERMISSION_MODULE.WAREHOUSE
                ) && (
                  <CustomButton
                    type="submit"
                    className="fw-semibold fs-13 text-white mw-90 h-40"
                    variant="primary"
                    onClick={() => updateWarehouse()}
                  >
                    <EditIcon className="me-2 fs-15"  />
                    
                    Edit Warehouse
                  </CustomButton>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <DashboardStockLevel productAlertData={productAlertData} />            
            <Row className="mt-3">
              <Col md={5}>
                <DashboardPendingPo />
              </Col>
              <Col md={7}>
                <DashboardPendingDispatch />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <DashboardGoodMovement />
              </Col>
              <Col md={4}>
                <DashboardOrderReceived />
              </Col>
              <Col md={4}>
                <DashboardPoRaised />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={5}>
                <DashboardNumberPoRaised />
              </Col>
              <Col md={7}>
                <DashboardInventoryTrend />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
