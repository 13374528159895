import React, { useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { getTicketManagementData } from "../../../services/dashboardMaster.service";
import Loader from "../../../components/common/Loader";
import OverdueList from "./OverdueList";
import { DateRangeInterface } from "../dashboardInterface";
import CountLabelSkeleton from "../../../components/common/Skeleton/CountLabel.skeleton";
import TableSkeleton from "../../../components/common/Skeleton/Table.skeleton";
import InstallationSkeleton from "../../../components/common/Skeleton/InstallationCounter.skeleton";
const TicketManagement: React.FC<DateRangeInterface> = ({ orderDateRange }) => {
  const [loading, setLoading] = React.useState(true);
  const [ticketData, setTicketData] = React.useState<any>({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, [orderDateRange]);

  const fetchData = (signal:any) => {
    getTicketManagementData(orderDateRange,signal)
      .then((res) => {
        setTicketData(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="col-md-12">
      <Card className="rounded-2 mt-3  mb-3 p-3">
        {loading ? (
          <Row>
            <Col md={4}>
              <InstallationSkeleton />
            </Col>
            <Col md={8}>
              <TableSkeleton />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="pt-3">
              <Col className="col-md-4">
                <h5 className="fs-18 info-text fw-bold mb-3">
                  Ticket Management
                </h5>
                <div className="p-3 mb-2 rounded-2 blue-bg-light border-0 card">
                  <div className="fs-13 fw-bold blue-text">Open Tickets</div>
                  <p className="fs-26 fw-bold text-black mb-0">
                    {ticketData?.ticketCount?.open_tickets}
                  </p>
                </div>
                <div className="p-3 mb-2 rounded-2 red-bg-light border-red-2 card">
                  <div className="fs-13 fw-bold red-text">Overdue Tickets</div>
                  <p className="fs-26 fw-bold text-black mb-0">
                    {ticketData?.ticketCount?.overdue_tickets}
                  </p>
                </div>
              </Col>
              <Col className="col-md-8">
                <div className="ticketCounter">
                  <OverdueList overdueList={ticketData?.overDueTicketList} />
                </div>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </div>
  );
};

export default TicketManagement;
