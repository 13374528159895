import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import CustomButton from "../CustomButton";
import {
  getProjectDetails,
  quickUpdateProjectApi,
  updateProjectCustomerInfoApi,
} from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../Loader";
import {
  commonMasking,
  PROJECT_PREFIX,
} from "../../../constants/constants";
import InputText from "../InputText";
import { phoneMask } from "../../../utility/common";
import InputTextArea from "../InputTextArea";
import { projectPersonalDataSchema } from "../../../pages/Projects/Edit/validation";



interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  projectsData: any;
}

const ProjectPersonalInfoEdits: React.FC<AddProps> = ({
  isOpen,
  onClose,
  projectsData,
}) => {
  const [projectDataUpdated, setProjectDataUpdated] = useState<any | null>({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const projectId = projectsData[0]?.id;

  useEffect(() => {
    fetchData().then(() => {
      // Call this function after fetchData completes
    });
  }, []);

  const fetchData = async () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getProjectDetails(projectId)
        .then((response) => {
          //console.log(modifiedOption);
          setProjectDataUpdated(response.data.project);
          setLoading(false);
          resolve(true);
          if(response.data?.project.status != 'cp'){
            onClose();
          }
        })
        .catch((error) => {
          setLoading(false);
          onClose();
          reject(error);
        });
    });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    setBtnLoading(true);
    const formData: any = {
      ...values,
    };
    updateProjectCustomerInfoApi(projectId, formData)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        onClose();
      })
      .catch((error) => {
        setBtnLoading(false);

        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      });
  };


  const initialValues = {
    name: projectDataUpdated?.name ? projectDataUpdated?.name : "",
    email: projectDataUpdated?.email ? projectDataUpdated?.email : "",
    phone: projectDataUpdated?.phone ? projectDataUpdated?.phone : "",
    address: projectDataUpdated?.address ? projectDataUpdated?.address : "",
    city: projectDataUpdated?.projectDetails?.city
      ? projectDataUpdated?.projectDetails?.city
      : "",
    state: projectDataUpdated?.projectDetails?.state
      ? projectDataUpdated?.projectDetails?.state
      : "",
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="export-excel-modal"
          aria-labelledby="Assign modal owner"
          centered
          show={isOpen}
          onHide={onClose}
        >
          <Formik
            validationSchema={projectPersonalDataSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Personal Info Edit - ({PROJECT_PREFIX}
                    {projectDataUpdated?.project_no})
                  </h4>

                  <Row>
                    <Col className="col-md-6">
                      <InputText
                        controlId="name"
                        label="Name"
                        placeholder="Enter customer name"
                        touched={touched.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.name}
                        value={values.name}
                      />
                    </Col>
                    <Col className="col-md-6">
                      <InputText
                        controlId="email"
                        label="Email"
                        placeholder="Enter customer Email"
                        touched={touched.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.email}
                        value={values.email}
                      />
                    </Col>
                    <Col className="col-md-6">
                      <InputText
                        controlId="phone"
                        label="Phone Number"
                        placeholder="Enter Phone Number"
                        touched={touched.phone}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.phone}
                        value={phoneMask(values.phone)}
                        mask={commonMasking}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-12">
                      <div className="mb-3 custom-from-group">
                        <div className="position-relative ">
                          <InputTextArea
                            controlId="address"
                            label=" Address"
                            placeholder="Enter complete address"
                            touched={touched.address}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.address}
                            name="address"
                            value={values.address}
                            className="form-control textarea-height"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <InputText
                        controlId="city"
                        label="City"
                        placeholder="Enter city"
                        touched={touched.city}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.city}
                        value={values.city}
                      />
                    </Col>
                    <Col className="col-md-6">
                      <InputText
                        controlId="state"
                        label="State"
                        placeholder="Enter state"
                        touched={touched.state}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.state}
                        value={values.state}
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Update
                  </CustomButton>
                </Modal.Footer>                
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default ProjectPersonalInfoEdits;
