import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import FormSelect from "../../components/common/FormSelect";
import CustomButton from "../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import { assingProductVendorApi } from "../../services/productService";
import { getAllVendorsName } from "../../services/vendorService";

const addSchema = yup.object({
  vendor_id: yup.object().required().label("Project Owner"),
});

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  productData: string[];
}

interface ProjectOption {
  id: string;
  vendor_name: string;
}

interface FormValues {
  productData: string[];
  vendor_id: any;
}
const ProductVendorAssign: React.FC<AddProps> = ({ isOpen, onClose, productData }) => {
  const [vendorOption, setVendorsOption] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const initialValues = {
    productData: productData,
    vendor_id: [],
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    getAllVendorsName()
      .then((response) => {
        const modifiyOption = response.data.map((item: ProjectOption) => ({
          key: item.id,
          value: item.vendor_name,
        }));
        setVendorsOption(modifiyOption);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onClose();
      });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    const productIds = values?.productData.map((item:any) => item.id)
    setBtnLoading(true);
    const formData: any = {
      vendor_id: values?.vendor_id?.key,
      products_id:productIds
    };
 
    assingProductVendorApi(formData).then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose()

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal assign-modal" show={isOpen} onHide={onClose} centered>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative pb-0">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Assign Vendor
                  </h4>                  
                  <div className="row">
                    <div className="col-md">
                      <FormSelect
                        placeholder="Select"
                        label="Vendor"
                        name="vendor_id"
                        options={vendorOption}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.key}
                        onChange={(name: string, value: string) => {
                          setFieldValue(name, value);
                        }}
                        value={values.vendor_id}
                        error={errors.vendor_id}
                        touched={touched.vendor_id}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-60 mt-2"
                    variant="primary"
                  >
                    Submit
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default ProductVendorAssign;
