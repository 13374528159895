import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import FileUploader from "../../components/common/FileUploader";
import { EmployeeDocumentTypeExtension, allowedFileTypes, formTemplateMaxFileSizeMB, maxFileSizeMB } from "../../constants/constants";
import {
  DOCUMENT_TYPE_ERROR,
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
  FORM_TEMPLATE_SIZE_ERROR,
} from "../../validations/ValidationErrors";
import { toast } from "react-toastify";
import UploadImage from "../../assets/images/upload-img-1.png";
import CrossIcon from "../../assets/images/cross-icon.svg";
import ImageIcon from '@mui/icons-material/Image';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { getInstallationMediaImagesApi, saveInstallationMediaImagesApi, uploadInstallationMediaApi } from "../../services/installation.service";
import MultiFileUploader from "../../components/common/MultiFileUploader";
import CustomButtonCancel from "../../components/common/CustomButtonCalcel";
import CustomButton from "../../components/common/CustomButton";
import { getLocalDateFormat } from "../../utility/common";
import ContactComplicatd from "../../assets/images/contact-complicatd-new.svg";
import DescriptionIcon from '@mui/icons-material/Description';
import { Link } from "react-router-dom";
import LightboxGallery from "../../components/common/LightboxGallery";
interface LastDayCheckListProps {
  projectId: string | number | undefined;
}

interface ImagePreview {
  signedUrl: string;
  name: string;
  size: number;
}

const MediaUpload: React.FC<LastDayCheckListProps> = ({ projectId }) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<ImagePreview[]>([]);
  const [loading, setLoading] = useState(false);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesSet, setImagesSet] = useState<any>([]);
  const uploadProfileImage = (event: any) => {
    if (event.target.files.length === 0) {
      return;
    }
    const form_data = new FormData();
    const files = event.target.files;

    //loop for multiple files
    for (let i = 0; i < files.length; i++) {
      // Check file type
      if (!EmployeeDocumentTypeExtension.includes(files[i].type)) {
        toast(DOCUMENT_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (files[i].size > formTemplateMaxFileSizeMB * 1024 * 1024) {
        toast(FORM_TEMPLATE_SIZE_ERROR, { type: toast.TYPE.ERROR });

        return;
      } else {
        form_data.append("image", files[i]);
      }
    }
    form_data.append("project_id", `${projectId}`);

    setImageLoading(true);

    uploadInstallationMediaApi(form_data)
      .then((response) => {
        // const existingDocs = [...imagePreview];
        // const newDocs = [...response.data];
        const updatedDocs = [...response.data,...imagePreview];
        setImagePreview(updatedDocs);
        setImageLoading(false);
        
      })
      .catch(() => {
        setImageLoading(false);
      });
  };

  useEffect(() => {
    transformImage(imagePreview);
  }, [imagePreview]);

  const transformImage = (images: any[]) => {
    if (images?.length > 0) {
      const updatedImages = images.map(
        (image: { signedUrl: any; src: any; file_name: any }) => {
          const extension: any = image.file_name
            .split(".")
            .pop()
            ?.toLowerCase();
          let img: any = {};
          if (["png", "jpeg", "jpg", "svg"].includes(extension)) {
            img.src = image.signedUrl;
            return img;
          }
        }
      ).filter(img => img !== undefined);      
      setImagesSet(updatedImages);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

const fetchData = () => {
    setLoading(true);
    getInstallationMediaImagesApi(projectId).then((response) => {
        setImagePreview(response.data);
        setLoading(false);
        }
    ).catch(() => {
        setLoading(false);
    }).finally(() => {
        setLoading(false);
    });
}

const handleSubmit = () => {
  setBtnLoading(true);
  const docArray = imagePreview.map(({ signedUrl, ...rest }) => ({
    ...rest,
  }));
  const transformData = {
    files : docArray
  }
  saveInstallationMediaImagesApi(projectId, transformData).then((response) => {
        setImagePreview(response.data);
        setBtnLoading(false);
        fetchData();
        }
    ).catch(() => {
        setBtnLoading(false);
    }).finally(() => {
        setBtnLoading(false);
    });
}

const handleDeleteDocument = (docIndex : number) => {    
    setImagePreview((prevDocIndex: any) => {
      const updatedDocArray = [...prevDocIndex];
      updatedDocArray.splice(docIndex, 1);
      return updatedDocArray;
    });  
}

const getExtension = (fileObj: any) => {
  const extension:any = fileObj.file_name.split('.').pop()?.toLowerCase();  
  if (extension === 'pdf') {
    return (<Link to={fileObj?.signedUrl} target="_blank" className="p-2 w-100"><img
    src={ContactComplicatd}
    alt="Icon"
    className="w-100"
  /></Link>)
  } else if (['png', 'jpeg', 'jpg', 'svg'].includes(extension)) {
    return (<><img
    src={fileObj?.signedUrl}
    alt="Logo"
    className="img-fluid"
    style={{ width: "100px", height: "100px" }}
    onClick={() => openLightbox(fileObj)}
  />
  <span className="btn btn-green">
    <ImageIcon className="text-white fs-10" />
  </span></>)
  } else {
    return (<Link to={fileObj?.signedUrl} target="_blank" className="p-2" ><DescriptionIcon className="rounded-1 blue-text w-100 h-100" /></Link>);
  }
};

const openLightbox = (fileObj: any) => {
  const index = imagesSet.findIndex((image: { src: any; }) => image.src === fileObj.signedUrl);
  setCurrentIndex(index);
  setLightboxIsOpen(true);
};

const handleClose = () => {
  setLightboxIsOpen(false);
};

  return (
    <div>
      <Card className="mt-2 rounded-4">
        <div className="position-relative  p-4">
          <div className="d-flex flex-wrap  mt-0">
            <div className="d-flex  me-3 media-upload-img-box">
              <MultiFileUploader
                uploadImage={(e: any) => uploadProfileImage(e)}
                imageLoading={imageLoading}
                imagePreview={imagePreview}
                acceptFormat={EmployeeDocumentTypeExtension.join(", ")}
              />
            </div>

            {imagePreview && (
              <>
                {imagePreview.map((image: any, index: number) => (
                  <div
                    key={index}
                    className="d-flex  me-3 media-upload-img-box"
                  >
                    <Button
                      className="media-cross-icon"
                      onClick={() =>
                        handleDeleteDocument(index)
                      }
                    >
                      <img src={CrossIcon} />
                    </Button>
                    <div className="media-upload-img">
                      {getExtension(image)}
                    </div>
                    <h5 className="fs-14 fw-bold">
                      {image.file_name.substring(image.file_name.lastIndexOf('/') + 1)}
                    </h5>
                    <p className="fs-14 gray-text">
                      <CalendarTodayIcon className="me-1 fs-13" />
                      { getLocalDateFormat(image.created_at)}
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </Card>
      <Row>
        <Col className="col-md-12">
          <div className="text-end my-3">
            <CustomButtonCancel label={"Cancel"} />

            <CustomButton
              type="button"
              loading={btnLoading}
              disabled={imageLoading || btnLoading}
              className="fw-semibold fs-13 text-white mw-65 mt-2"
              variant="primary"
              onClick={() => handleSubmit()}
            >
              Submit
            </CustomButton>
          </div>
        </Col>
      </Row>
      <LightboxGallery images={imagesSet} currentIndex={currentIndex} open={lightboxIsOpen} onClose={handleClose} />
    </div>
  );
};
export default MediaUpload;
