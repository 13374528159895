import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import Loader from "../../components/common/Loader";
 import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG } from "../../validations/ValidationErrors";
import { uploadCustomerSign } from "../../services/installation.service";
interface iSignatureCanvas {
  handleSignModalClose: () => void;
  showSign: boolean;
  project_id: string;
  setIsSigned : any;
}
const CustomerSignatureCanvas: React.FC<iSignatureCanvas> = ({
  handleSignModalClose,
  showSign,
  project_id,
  setIsSigned
}) => {
  const [loading, setLoading] = useState(false);
  const SignatureSchema = Yup.object().shape({
    sign: Yup.string().required().label("Signature"),
  });
  const initialValuesSign = {
    sign: "",
  };
  const sigCanvas = useRef<SignatureCanvas>(null);
  const resetCanvas = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  function setSignatureOnChange(setFieldValue: any) {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL("image/png");
      setFieldValue("sign", dataUrl);
    }
  }

  const handleSubmit = (values: any) => {
    console.log("data", values);

    const formData = {
      image: values.sign,
      project_id: project_id,
    };
    setLoading(true);

    uploadCustomerSign(formData)
      .then((res) => {
        toast.success(res.msg);
        setIsSigned(true)
        handleSignModalClose();
      })
      .catch((err) => {
        toast.error(SOMETHING_WENT_WRONG);
        setLoading(false);
      });

    // handleSignModalClose();
  };

  return (
    <React.Fragment>
      <Modal
        show={showSign}
        onHide={handleSignModalClose}
        centered
        dialogClassName="modal-400w"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="fs-18 fw-bold info-text">
            Customer Signature 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={SignatureSchema}
            initialValues={initialValuesSign}
            onSubmit={(values, actions) => {
              handleSubmit(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form className="text-start" noValidate onSubmit={handleSubmit}>
                <div className="sigContainer">
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{ className: "sigPad" }}
                    onEnd={(e: any) => {
                      setSignatureOnChange(setFieldValue);
                    }}
                  />
                  <p>
                    Sign here using MOUSE or TOUCH screen if using a tablet.
                  </p>
                  {errors.sign && touched.sign ? (
                    <div className="error-message text-danger">
                      {errors.sign}
                    </div>
                  ) : null}
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <div
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={resetCanvas}
                  >
                    Reset
                  </div>

                  <button
                    className="fw-semibold fs-13  mw-90 me-2 mt-2 btn btn-outline-info"
                    onClick={handleSignModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="fw-semibold fs-13  mw-90 me-2 mt-2 btn btn-info"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {loading && <Loader isLoading={loading} />}
    </React.Fragment>
  );
};

export default CustomerSignatureCanvas;
