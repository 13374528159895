import { Card, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useEffect, useState } from "react";
import { getEmployeeDashboardData } from "../../services/employeeService";
import Loader from "../../components/common/Loader";
 import AlertsAndAnnouncements from "./AlertsAndAnnouncements";
import MyTask from "./Mytask";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../features/Auth/authSlice";
import { Link } from "react-router-dom";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";

interface IAuthSelector {
  user: any;
}

 export const Dashboard = () => {
  const [data, setData] = useState({} as any);
  const [loading, setLoading] = useState(true);
  const { user }: IAuthSelector = useSelector(selectAuthentication);
  const userDesignation =  user.user?.employeeDetails?.designation;
  const navigate = useNavigate();
  useEffect(() => {
    if(!userDesignation){
      navigate("/");
    }
    fetchData();
  }, []);
  const fetchData = () => {
    getEmployeeDashboardData()
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handlePreviousTask =  () => {
    navigate("/tasks/my-previous-task");
  };

  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 py-2 my-1">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="fw-semibold h4 my-2">Dashboard</h1>
          </div>
           
          <div>
            
            <Button
              className="fw-semibold fs-12 text-white  btn btn-primary"
              variant="primary"
              type="button"
              onClick={handlePreviousTask}
            >
              <ChecklistIcon className="me-2" />
              Previous Tasks
            </Button>
          </div>
          
        </div>
        
      </div>

      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <>
            <Row>
              <Col md={3} className="mb-3">
                <Card className=" h-100">
                  <Card.Header className="bg-primary rounded-top fs-18 text-white fw-bold dashboard-card-header">
                    Tasks
                  </Card.Header>
                  <Card.Body>
                    <ul className="dashboard-card-box-list">
                      <li>
                        Total Tasks{" "}
                        <span className="fw-bold">
                          {data.employTaskCount?.totalTask ?? "-"}
                        </span>
                      </li>
                      <li>
                        Today’s Task{" "}
                        <span className="fw-bold">
                          {data.employTaskCount?.todayTask ?? "-"}
                        </span>
                      </li>
                      <li>
                        Task Progress{" "}
                        <span className="fw-bold">
                          {data.employTaskCount?.taskInProgress ?? "-"}
                        </span>
                      </li>
                      <li>
                        Delayed Tasks{" "}
                        <span className="fw-bold">
                          {data.employTaskCount?.taskDelayed ?? "-"}
                        </span>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className=" h-100">
                  <Card.Header className="bg-info rounded-top fs-18 text-white fw-bold dashboard-card-header">
                    Leave Requests
                  </Card.Header>
                  <Card.Body>
                    <ul className="dashboard-card-box-list">
                      <li>
                        Total Leaves Applied{" "}
                        <span className="fw-bold">
                          {data.employLeaveCount?.appliedCount ?? "-"}
                        </span>
                      </li>
                      <li>
                        Total Leaves Remaining{" "}
                        <span className="fw-bold">
                          {data.employLeaveCount?.leaveRemaining ?? "-"}
                        </span>
                      </li>
                      <li>
                        Pending Approval{" "}
                        <span className="fw-bold">
                          {data.employLeaveCount?.approvePendingCount ?? "-"}
                        </span>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className=" h-100">
                  <Card.Header className="bg-purple rounded-top fs-18 text-white fw-bold dashboard-card-header">
                    Meetings
                  </Card.Header>
                  <Card.Body>
                    <ul className="dashboard-card-box-list">
                      <li>
                        Pending Meeting Notes{" "}
                        <span className="fw-bold">
                          {data?.employMeetingCount?.meetingNotePendingCount ??
                            "-"}
                        </span>
                      </li>
                      <li>
                        Meeting Due{" "}
                        <span className="fw-bold">
                          {data?.employMeetingCount?.meetingDue}
                        </span>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mb-3">
                <Card className=" h-100">
                  <Card.Header className="bg-orange rounded-top fs-18 text-white fw-bold dashboard-card-header">
                    Pending Documents
                  </Card.Header>
                  <Card.Body>
                    <ul className="dashboard-card-box-list">
                    {data?.employeeDocumentCount?.checkHMRCFill  == 0 &&
                      <li>
                        HMRC{" "}
                        <span className="fw-bold">
                        <Link to="/hmrc" className="link-text text-decoration-none">                          
                            Fill
                          </Link>
                        </span>
                      </li>}
                      {data?.employeeDocumentCount?.checkMedicalFill == 0 &&
                      <li>
                        Medical
                        <span className="fw-bold">
                        <Link to="/medical" className="link-text text-decoration-none">                          
                            Fill
                          </Link>
                        </span>
                      </li>
                      }
                      <li>
                        Needed by HR{" "}
                        <span className="fw-bold">
                           
                            {data?.employeeDocumentCount?.neededFromHr}
                          
                        </span>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
        <MyTask />
        <AlertsAndAnnouncements />
      </div>
    </div>
  );
};
