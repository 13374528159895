import { Route, Routes } from "react-router-dom";
import AddExternal from "./Add";
import ExternalList from "./index";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { checkAbility } from "../../utility/common";

export const ExternalLink = () => {
  return (
    <Routes>
          {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.MANAGE_EXTERNAL_LINKS) && (
          <Route path="/" element={<ExternalList />} />
          )}
          {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.MANAGE_EXTERNAL_LINKS) && (
          <Route path="/add-external-link" element={<AddExternal />} />)}

          {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.MANAGE_EXTERNAL_LINKS) && (
          <Route path="/edit-external-link/:id" element={<AddExternal />} />)}
    </Routes>
  );
};
