import { axios } from "../services/axios";
import {
  WAREHOUSE_DETAIL,
  WAREHOUSE_UPDATE,
  WAREHOUSE_PHOTO_UPLOAD,
  WAREHOUSE_LIST_OPENING_STOCK,
  WAREHOUSE_EXPORT_OPENING_STOCK,
  WAREHOUSE_IMPORT_OPENING_STOCK,
  WAREHOUSE_SHELVES_LIST,
  WAREHOUSE_PO_ADD,
  WAREHOUSE_PO_DETAIL,
  WAREHOUSE_PO_EDIT,
  WAREHOUSE_PO_LIST,
  WAREHOUSE_PO_NUMBER,
  WAREHOUSE_PRODUCT_LIST_ASYNC,
  WAREHOUSE_PO_DELETE,
  WAREHOUSE_PO_COMPLETE,
  WAREHOUSE_PO_SEARCH,
  WAREHOUSE_GRN_ADD,
  WAREHOUSE_GRN_DETAIL,
  WAREHOUSE_GRN_GETNO,
  WAREHOUSE_GRN_IMAGE,
  WAREHOUSE_GRN_LIST,
  WAREHOUSE_GRN_EXPORT,
  WAREHOUSE_GRN_UPDATENOTE,
  WAREHOUSE_DP_LIST,
  WAREHOUSE_DP_EDIT,
  WAREHOUSE_DP_DETAIL,
  WAREHOUSE_DP_GETNO,
  WAREHOUSE_DP_ADD,
  WAREHOUSE_TEMPLATE_OPENING_STOCK,
  WAREHOUSE_DP_GETPRODUCT,
  WAREHOUSE_DP_EXPORT,
  WAREHOUSE_PO_EXPORT,
  WAREHOUSE_DASHBOARD,
  WAREHOUSE_RETRUN_DISPATCH_QTY,
} from "../constants/paths";


export type CreatePODTO = {
  date: string;
  contract_id: AsyncSelectType;
  project_id: AsyncSelectType;
  driver_id: AsyncSelectType;
  vendor_id: FormSelectType;
  ref_number: string;
  status: FormSelectType;
  pay_status: FormSelectType;
  pay_terms: string;
  description: string;
  bill_address: string;
  shipping_address: string;
  orderProducts: Array<{
    product_id: number;
    qty: number;
    price: number;
    amount: number;
    product:any;
    received_qty:number;
    qty_returned:number;
  }>;
  dispatchProducts: Array<{
    product_id: number;
    qty: number;  
    product:any;  
    received_date:string;
    availableStatus:boolean;
    contract_id: number;
    dispatchReceivedProducts:any;
  }>;
  remarks:string;
  return_date:string;
  received_date:string;
  receivedProducts: Array<{    
      inspected_by: number;
      balance_qty: number;
      productDetail : object;
      purchase_order_product_id: number,
      product_id: number,
      qty_received: number,
      qty_returned: number,
      qty_discard: number,
      quality_status: FormSelectType,
      return_remarks: string,
      images: any;  
      imagesPreview: any;  
  }>;
};

export interface FormSelectType{
  key: number | string;
  value: string;
};
export interface AsyncSelectType{
  id: number | string;
  name: string;
};

export const getWarehouseDetail = (): Promise<any> => {
  return axios.get(WAREHOUSE_DETAIL);
};

export const updateWarehouseApi = (data: any): Promise<any> => {
  const transformData = {
    ...data,
    user_id: data?.user_id?.key    
  };
  return axios.post(WAREHOUSE_UPDATE, transformData);
};

export const uploadWarehouseImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${WAREHOUSE_PHOTO_UPLOAD}`, data, config);
};

export const getWarehouseShelves = ():Promise<any> =>{
  return axios.get(WAREHOUSE_SHELVES_LIST);
}

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const getProductListAsync = (params:any|null ): Promise<any> => {
  return axios.get(WAREHOUSE_PRODUCT_LIST_ASYNC,{params});
};

export const getOpeningStocks = (formData: any): Promise<any> => {
  return axios.get(WAREHOUSE_LIST_OPENING_STOCK, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const exportOpeningStocks = (params:any = {}) : Promise<any> => {
  return axios.get(WAREHOUSE_EXPORT_OPENING_STOCK,{params,responseType: 'arraybuffer',});
}

// get Opening stock template
export const getOpeningStocksTemplateSignedUrl = (): Promise<any> => {
  return axios.get(`${WAREHOUSE_TEMPLATE_OPENING_STOCK}`);
};

export const importOpeningStocks = (data:any) : Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${WAREHOUSE_IMPORT_OPENING_STOCK}`, data, config);
}

/**
 * Get a single PO by ID
 * @param {id: string } params
 * @returns asset
 */
export const getPODetailById = (id: string): Promise<any> => {
  return axios.get(WAREHOUSE_PO_DETAIL + "/" + id);
};

export const getPoNumberListApi = (params:any,type:number | string | undefined = 0) : Promise<any> => {
  return axios.get(`${WAREHOUSE_PO_SEARCH}/${type}`,{params});
};

/**
 * Get all PO from server
 * @param {formData: any }
 * @returns PO
 */
export const getPoList = (formData: any): Promise<any> => {
  return axios.get(WAREHOUSE_PO_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...( formData.delayedAlert ? {delayedAlert : formData.delayedAlert} : {}),
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * Get PO number
 * @returns Po number
 */
export const getPoNumber = (): Promise<any> => {
  return axios.get(WAREHOUSE_PO_NUMBER);
};

/**
 * Create a new PO
 * @param {data: creatPODTO } 
 */
export const createNewPo = (data: CreatePODTO): Promise<any> => {
  const orderProductsArray =  data.orderProducts.map(({ product, received_qty,  ...rest }) => rest);

  const transformData = {
    ...data,    
    contract_id: data?.contract_id?.id,
    vendor_id: data?.vendor_id?.key,    
    status: data?.status?.key,
    pay_status: data?.pay_status?.key,  
    orderProducts :orderProductsArray 
  };   
  return axios.post(WAREHOUSE_PO_ADD, transformData);
};
/**
 * Update PO
 * @param {data: creatPODTO } 
 */
export const updatePo = ( id: string,
  data: CreatePODTO): Promise<any> => {
    const orderProductsArray =  data.orderProducts.map(({ product, received_qty,qty_returned, ...rest }) => rest);
    const transformData = {
      ...data,    
      contract_id: data?.contract_id?.id,
      vendor_id: data?.vendor_id?.key,    
      status: data?.status?.key,
      pay_status: data?.pay_status?.key,  
      orderProducts :orderProductsArray 
    };  
  return axios.put(`${WAREHOUSE_PO_EDIT}/${id}`, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deletePoApi = (id: string | number | null): Promise<any> => {
  return axios.delete(WAREHOUSE_PO_DELETE + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const markPoComplete = (id: string | number | null | undefined): Promise<any> => {
  return axios.patch(WAREHOUSE_PO_COMPLETE + "/" + id);
};

export const exportPoData = (params:any = {}) : Promise<any> => {
  return axios.get(WAREHOUSE_PO_EXPORT,{params,responseType: 'arraybuffer',});
}

/**
 * Get all GRN from server
 * @param {formData: any }
 * @returns PO
 */
export const getGrnList = (formData: any): Promise<any> => {
  return axios.get(WAREHOUSE_GRN_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * Get PO number
 * @returns Po number
 */
export const getGrnNumber = (): Promise<any> => {
  return axios.get(WAREHOUSE_GRN_GETNO);
};

export const uploadGrnReturnImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${WAREHOUSE_GRN_IMAGE}/grn`, data, config);
};

/**
 * Create a new PO
 * @param {data: creatPODTO } 
 */
export const createNewGrnApi = (poId:string | number | undefined, data: CreatePODTO): Promise<any> => {
  const orderProductsArray = data.receivedProducts.filter(item => item.qty_received > 0).map(({ productDetail, imagesPreview,  ...rest }) => ({
    ...rest,
    quality_status: rest.quality_status?.key
  }));
  const transformData = {
    ...data,      
    receivedProducts :orderProductsArray 
  };   
  return axios.post(`${WAREHOUSE_GRN_ADD}/${poId}`, transformData);
};

/**
 * Get a single GRN by ID
 * @param {id: string } params
 * @returns asset
 */
export const getGrnDetailById = (id: string): Promise<any> => {
  return axios.get(WAREHOUSE_GRN_DETAIL + "/" + id);
};

export const exportGrnData = (params:any = {}) : Promise<any> => {
  return axios.get(WAREHOUSE_GRN_EXPORT,{params,responseType: 'arraybuffer',});
}

export const updateGrnNoteApi =(grnId:string | number |undefined, data: any): Promise<any> => { 
  return axios.patch(WAREHOUSE_GRN_UPDATENOTE + "/" + grnId, data);
};

export const uploadDispatchOrderImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${WAREHOUSE_GRN_IMAGE}/dp`, data, config);
};

/**
 * Get DP number
 * @returns DP number
 */
export const getDPNumber = (): Promise<any> => {
  return axios.get(WAREHOUSE_DP_GETNO);
};

/**
 * Get DP number
 * @returns DP number
 */
export const getProductToDispatchApi = (projectId: number | string | undefined): Promise<any> => {
  return axios.get(`${WAREHOUSE_DP_GETPRODUCT}/${projectId}`);
};

/**
 * Create a new DP
 * @param {data: creatPODTO } 
 */
export const createNewDispatchOrder = (data: CreatePODTO): Promise<any> => {
  const orderProductsArray = data.dispatchProducts.map(({ product,availableStatus,contract_id,dispatchReceivedProducts ,  ...rest }) => ({
    ...rest,    
  }));
  const transformData = {
    ...data,      
    dispatchProducts :orderProductsArray,

  };   
  //delete transformData.project_id;
  return axios.post(`${WAREHOUSE_DP_ADD}`, transformData);
};

/**
 * Get a single DP by ID
 * @param {id: string } params
 * @returns asset
 */
export const getDispatchOrderById = (id: string): Promise<any> => {
  return axios.get(WAREHOUSE_DP_DETAIL + "/" + id);
};

/**
 * Edit a new DP
 * @param {data: creatPODTO } 
 */
export const updateDispatchOrder = (dpId:string | number | undefined,data: CreatePODTO): Promise<any> => {
  const orderProductsArray = data.dispatchProducts.map(({ product,received_date,availableStatus,contract_id,dispatchReceivedProducts,  ...rest }) => ({
    ...rest,    
  }));
  const transformData = {
    ...data,      
    dispatchProducts :orderProductsArray,
  };   
  return axios.post(`${WAREHOUSE_DP_EDIT}/${dpId}`, transformData);
};

/**
 * Get all Dispatch orders from server
 * @param {formData: any }
 * @returns PO
 */
export const getDpList = (formData: any): Promise<any> => {
  return axios.get(WAREHOUSE_DP_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const exportDpData = (params:any = {}) : Promise<any> => {
  return axios.get(WAREHOUSE_DP_EXPORT,{params,responseType: 'arraybuffer',});
}

// Warehouse Dashboard Apis

export const getProductStockAlertApi = (): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/product-stock-and-alert`);
};

export const getDelayedPoShipmentApi = (): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/delayed-po-shipment`);
};

export const getDashboardPendingPoApi = (): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/pending-po`);
};

export const getDashboardPendingDispatchApi = (): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/pending-dispatch`);
};

export const getDashboardGoodsMovementApi = (): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/goods-movement`);
};

export const getDashboardDispatchOrderChartApi = (): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/dispatch-order-status-count`);
};

export const getDashboardPoRaisedChartApi = (rData:any): Promise<any> => {
  return axios.post(`${WAREHOUSE_DASHBOARD}/po-raised-status-chart`,rData);
};

export const getDashboardPoRaisedCountChartApi = (quarter:number): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/po-raised-count-chart/${quarter}`);
};

export const getDashboardProductListApi = (params:any|null): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/products-list`,{params});
};

export const getDashboardInventoryTrendsChartApi = (rData:any): Promise<any> => {
  return axios.post(`${WAREHOUSE_DASHBOARD}/inventory-trends`,rData);
};

export const getDashboardDispatchReturnProductQtyApi = (): Promise<any> => {
  return axios.get(`${WAREHOUSE_DASHBOARD}/dispatch-return-products`);
};

export const returnDispatchItemToStockApi = (return_product_id:any): Promise<any> => {
  return axios.patch(`${WAREHOUSE_RETRUN_DISPATCH_QTY}/${return_product_id}`);
};




