import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,    
  MINIMUM_3_CHAR,
  INVALID_DATE,
} from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";
export const addSchema = yup.object({
  event_name: yup
    .string()
    .trim()    
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .required()
    .label("Event Name"),
  event_date: yup.date().required().typeError(INVALID_DATE).label("Event Date"),
});
