// CustomButton.tsx
import React, { ReactNode } from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';

interface CustomButtonProps extends ButtonProps {
  children: ReactNode;
  loading?: boolean; // Add loading prop
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, loading = false, ...props }) => {
 

  return (
    <Button disabled={loading}  {...props}>
      {loading ? <Spinner animation="border" size="sm" /> : null}
      {children}
    </Button>
  );
};

export default CustomButton;
