import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import CustomButton from "../../../../../components/common/CustomButton";
import {  
  saveEmployeeDocumentRequest,
  getEmployeeDocumentRequestDetail,
  updateEmployeeDocumentRequest
} from "../../../../../services/employeeService";
import { toast } from "react-toastify";
import Loader from "../../../../../components/common/Loader";
import InputText from "../../../../../components/common/InputText";
import InputDatePicker from "../../../../../components/common/InputDatePicker";
import InputTextArea from "../../../../../components/common/InputTextArea";
import {docValidationSchema} from "./NeededFromEmployeeValidation";
import moment from "moment";
import constants from "../../../../../constants/constants";

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  employeeID: number;
  documentId?: string |number | null | undefined;
}

const DocumentNeededPopup: React.FC<AddProps> = ({ isOpen, onClose, employeeID, documentId }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentRequestData, setDocumentRequestData] = useState<any>({});   

  useEffect(()=>{
    fetchData()
   },[])
 
   const fetchData = () =>{
    if(documentId){
      setLoading(true)
      getEmployeeDocumentRequestDetail(employeeID,documentId).then((response)=>{
        setDocumentRequestData(response?.data?.doc);
        setLoading(false)  
      }).catch((error)=>{
        setLoading(false)
        onClose()
  
      })
    }
    else{
      setLoading(false)
    }
   }

   const initialValues = {    
    doc_name: documentRequestData?.doc_name ? documentRequestData?.doc_name : "",
    submission_last_date: documentRequestData?.submission_last_date ? moment(documentRequestData?.submission_last_date) : null,
    expiry_date: documentRequestData?.expiry_date ? moment(documentRequestData?.expiry_date) : null,
    description: documentRequestData?.description ? documentRequestData?.description : "",

  };
  /**
   * Submit Method to call when user save button
   * @async
   * @param  values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    //console.log(343434);
    setBtnLoading(true);
    const formData: any = {
      ...values,
      submission_last_date: moment(values.submission_last_date).format(
        constants.dateFormats.databaseFormat
      ),
      expiry_date:  moment(values.expiry_date).isValid() ?  moment(values.expiry_date).format(
        constants.dateFormats.databaseFormat
      ) : null,

    };

    // Example usage:
    const saveOrUpdateDocumentRequest = () => {
      // Provide the appropriate API request function
      return documentId
        ? updateEmployeeDocumentRequest(documentId, employeeID, formData)
        : saveEmployeeDocumentRequest(employeeID, formData);
    };
 
    saveOrUpdateDocumentRequest().then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      onClose()

    }).catch((error)=>{
      setBtnLoading(false);

      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal max-modal-590" show={isOpen} onHide={onClose} centered>
          <Formik
            validationSchema={docValidationSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Add Documents <span className="fs-15 fw-normal">(Needed from Employee)</span>
                  </h4> 
                  <Row>
                    <Col className="col-md-6">
                    <InputText
                      controlId="doc_name"
                      label="Document Name"                      
                      placeholder="Enter document name"
                      touched={touched.doc_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.doc_name}
                      value={values.doc_name}
                    />
                    </Col>
                    <Col className="col-md-6">
                    <InputDatePicker
                            name="submission_last_date"
                            label="Last Date of Submission"
                            slotProps={{
                              textField: {
                                size: "small",
                                placeholder: "Select",
                                className: "form-control",
                                readOnly: true,
                                id: "submission_last_date",
                              },
                            }}
                            value={values.submission_last_date}
                            onChange={(name, newValue) =>
                              setFieldValue(name, newValue)
                            }
                            touched={touched.submission_last_date}
                            errors={errors.submission_last_date}
                          />  
                    </Col>
                  </Row>                 
                  <Row className="mb-3">
                    <Col className="col-md-6">
                    <InputDatePicker
                            name="expiry_date"
                            label="Expiry Date"
                            slotProps={{
                              textField: {
                                size: "small",
                                placeholder: "Select",
                                className: "form-control",
                                readOnly: true,
                                id: "expiry_date",
                              },
                            }}
                            value={values.expiry_date}
                            onChange={(name, newValue) =>
                              setFieldValue(name, newValue)
                            }
                            touched={touched.expiry_date}
                            errors={errors.expiry_date}
                          /> 
                    </Col>                    
                  </Row>                 
                  <Row>
                    <Col className="col-md-12">
                    <InputTextArea
                        controlId="description"
                        label="Description"
                        placeholder="Enter description"
                        touched={touched.description}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.description}
                        value={values.description}
                      />
                    </Col>                   
                  </Row>                
                  
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-60 mt-2"
                    variant="primary"
                  >
                    Add
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default DocumentNeededPopup;
