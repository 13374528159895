import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row, Spinner } from "react-bootstrap";

import { ErrorMessage, FieldArray, Formik } from "formik";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../../components/common/InputText";
import { PoDataDTO, ReceivedProduct } from "./po.type";
import FileAddIcon from "../../../../assets/images/file-add-icon.svg";
import constants, {
  GrnQualityCheckStatus,
} from "../../../../constants/constants";
import CustomButton from "../../../../components/common/CustomButton";

import Loader from "../../../../components/common/Loader";
import FileUploader from "../../../../components/common/FileUploader";
import {
  createNewGrnApi,
  getDispatchOrderById,
  getGrnNumber,
  getPODetailById,
  uploadGrnReturnImageApi,
} from "../../../../services/warehouseService";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import InputTextArea from "../../../../components/common/InputTextArea";
import moment from "moment";
import grnSchema from "./validation";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../../features/Auth/authSlice";
import AddPhotoAlternate from "../../../../assets/images/add_photo_alternate.svg";
import DeleteIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { currencyMask } from "../../../../utility/common";
import InputCurrencyText from "../../../../components/common/InputCurrencyText";
import FormSelect from "../../../../components/common/FormSelect";
import {
  getInstallationTaskOrdersReceived,
  saveInstallationTaskOrdersReceived,
  uploadOrderReceivedImageApi,
} from "../../../../services/installation.service";
import EditIcon from '@mui/icons-material/Edit';

const OrderRecievedDetails: React.FC<any> = ({ projectId, projectData,setAllDispatchReceived }) => {
  const { user }: any = useSelector(selectAuthentication);

  const userName = user.user.full_name;
  const userId = user.user.id;
  const [grnNumber, setGrnNumbers] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dpData, setDpData] = useState<any>(null);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [productArray, setProductArray] = useState<ReceivedProduct[]>();
  const [isMutation, setIsMutation] = useState(false);
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ];
  const maxFileSizeMB = 1;

  /**
   * Submit Method to call when grn save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: PoDataDTO, { setSubmitting }: any) => {
    const formData: any = {
      ...values,
    };
    delete formData.po_number;
    delete formData.product_id;
    delete formData.product_price;
    delete formData.product_qty;
    console.log(formData);
    //return;
    setBtnLoading(true);
    if (projectId) {
      saveInstallationTaskOrdersReceived(projectId, formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          setIsMutation(!isMutation);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const updateRecevicedProductArray = (dpData: any) => {
    // Create initial values based on products
    console.log("dpData",dpData)
    const initialPoProducts = dpData ? dpData : [];
    const initialReceivedProducts: any[] = initialPoProducts
      .map((productDe: any) => (
        {
        inspected_by: userId,
        balance_qty: productDe.qty - (productDe.received_qty | 0),        
        productDetail: productDe.product,
        dispatch_order_product_id: productDe.id,
        dispatch_order_id: productDe.dispatch_order_id,
        product_id: productDe.product_id,
        id: productDe?.dispatchReceivedProducts?.id ?? null,
        isEdit: productDe?.dispatchReceivedProducts?.id  ? false : true,
        qty_received: productDe?.dispatchReceivedProducts?.qty_received ?? 0,
        qty_returned: productDe?.dispatchReceivedProducts?.qty_returned ?? 0,
        qty_discard: productDe?.dispatchReceivedProducts?.qty_discard ?? 0,
        is_return_approved: productDe?.dispatchReceivedProducts?.is_return_approved ?? false,
        quality_status: productDe?.dispatchReceivedProducts?.quality_status
          ? {
              key: productDe?.dispatchReceivedProducts?.quality_status,
              value: productDe?.dispatchReceivedProducts?.quality_status,
            }
          : null,
        return_remarks: productDe?.dispatchReceivedProducts?.return_remarks ?? "",
        images: productDe?.dispatchReceivedProducts?.images ?? [],
        imagesPreview: productDe?.dispatchReceivedProducts?.imagesSigned ?? [],
        
      }))
      .filter(
        (receivedProduct: ReceivedProduct) => receivedProduct.balance_qty !== 0
      );
      //validate all items recieved
      setAllDispatchReceived(
        !initialReceivedProducts.some(
          (item: any) => item.qty_returned > 0 && item.is_return_approved == false
        )
      );
    console.log(initialReceivedProducts);
    setProductArray(initialReceivedProducts);
    initialValues.receivedProducts = initialReceivedProducts;
    setLoading(false)
  };

  const getDispatchOrderDetails = (id: string) => {
    setLoading(true);
    getInstallationTaskOrdersReceived(id)
      .then((response) => {
        setDpData(response.data);
          console.log("response.data",response)
        updateRecevicedProductArray(response.data);
       })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          // navigate("/good-inward");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          //  navigate("/good-inward");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          //  navigate("/good-inward");
        }
      })
      .finally(() => {
      //  setLoading(false);
      });
  };

  useEffect(() => {
    if (projectId) {
      getDispatchOrderDetails(projectId);
    } else {
      setLoading(false);
    }
  }, [projectId, isMutation]);

  useEffect(() => {
    if (dpData) {
      // console.log(dpData);
      updateRecevicedProductArray(dpData);
    }
  }, [dpData]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleProducts = (formValues: any, setFieldValue: any) => {
    if (formValues.product_qty > 0 && formValues.product_price > 0) {
      const fieldDataObj = {
        product_id: formValues.product_id?.id,
        qty: formValues.product_qty,
        price: formValues.product_price,
        amount: Number(
          parseInt(formValues.product_qty) *
            parseFloat(formValues.product_price)
        ),
        product: {
          name: formValues.product_id?.name,
        },
      };
      const productArray = [...formValues.orderProducts];
      // console.log(fieldDataObj, productArray);
      productArray.push(fieldDataObj);
      setFieldValue("orderProducts", productArray);
      setFieldValue("product_id", null);
      setFieldValue("product_qty", "");
      setFieldValue("product_price", "");
    }
    //  shelvesArrr.push(sleveValue);
    /*  if (sleveValue.trim() !== "" && !sleveErrors) {
      const exists: boolean = shelfNumbers.some(
        (obj: any) => obj.shelf_number === sleveValue.trim()
      );
      // Check file type
      if (exists) {
        toast(`${sleveValue} is already added.`, {
          type: toast.TYPE.ERROR,
        });
        return;
      }
      const shelfNumberVal = { shelf_number: sleveValue.trim() };
      setShelfNumbers((prevShelfNumbers: any) => [
        ...prevShelfNumbers,
        shelfNumberVal,
      ]);
      // Clear the input value
      setFieldValue("shelf_number", "");
    } */
  };

 // console.log(projectData);

  const handleDeleteReturnImage = (
    formValues: any,
    indexNumber: number,
    imageIndex: number,
    setFieldValue: any
  ) => {
    const failedGrnImageDetails =
      formValues.receivedProducts[indexNumber].images;
    const failedGrnImagePreview =
      formValues.receivedProducts[indexNumber].imagesPreview;
    failedGrnImageDetails.splice(imageIndex, 1);
    failedGrnImagePreview.splice(imageIndex, 1);
    setFieldValue(
      `receivedProducts[${indexNumber}].images`,
      failedGrnImageDetails
    );
    setFieldValue(
      `receivedProducts[${indexNumber}].imagesPreview`,
      failedGrnImagePreview
    );
  };
  // console.log(poData);
  const uploadProfileImage = (
    event: any,
    formValues: any,
    indexNumber: number,
    setFieldValue: any
  ) => {
    const imageFile = event.target.files[0];
    // console.log("file", imageFile);

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setImageLoading(true);

      setImagePreview(null);

      // Create FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append("file", imageFile);
      const failedGrnImageDetails =
        formValues.receivedProducts[indexNumber].images;
      const failedGrnImagePreview =
        formValues.receivedProducts[indexNumber].imagesPreview;
      //  return;
      uploadOrderReceivedImageApi(formData)
        .then((response) => {
          failedGrnImageDetails.push(response.data.file);
          failedGrnImagePreview.push(response.data.signedURL);
          setImageLoading(false);
          setFieldValue(
            `receivedProducts[${indexNumber}].images`,
            failedGrnImageDetails
          );
          setFieldValue(
            `receivedProducts[${indexNumber}].imagesPreview`,
            failedGrnImagePreview
          );
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };

  const initialValues: any = {
    //    receivedProducts: dpData.orderProducts?.length ? productArray : [],
    receivedProducts: productArray
  };
  // console.log(initialValues, productArray);
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column  pageContainer  w-100 ">
          {/* <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Goods Inward Note</h1>
              </Col>
            </Row>
          </div> */}
          
            <Formik
              validationSchema={grnSchema(projectId)}
              initialValues={initialValues}
              onSubmit={(values: PoDataDTO, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                   <div>
                    <FieldArray
                      name="receivedProducts"
                      render={(arrayHelpers) => (
                        <div>
                          {values.receivedProducts.map(
                            (entry: any, index: any) => (
                              <div key={`order_products_grn${index}`}>
                                <Card className="p-3 w-100 pageContentInner mb-3 install-detail-card">
                                  <Row className="border-bottom py-2 mb-3 pt-0">
                                    <Col className="fs-16 fw-bold">
                                      {entry?.productDetail?.name}
                                    </Col>

                                    <Col className="col-md-2 text-end fs-14 d-flex align-items-center justify-content-end">
                                      {
                                        entry.id && !entry.is_return_approved && projectData?.status !='cp' && (
                                        <span className="ms-2">
                                          <Button
                                            type="button"
                                            className="btn btn-link text-primary w-32 h-32 p-0 install-edit-btn"
                                            onClick={() => setFieldValue(
                                              `receivedProducts[${index}].isEdit`,
                                              !values.receivedProducts[index]?.isEdit
                                            )}
                                          >
                                            <EditIcon />
                                          </Button>
                                        </span>
                                        )
                                      }
                                      <span className="ms-2">
                                        {values.receivedProducts[index]
                                          ?.quality_status?.key === "Fail" ? (
                                          <Button
                                            type="button"
                                            className="btn btn-primary text-white w-32 h-32 p-0"
                                          >
                                            <ExpandLessIcon />
                                          </Button>
                                        ) : (
                                          <Button
                                            type="button"
                                            className="btn btn-secondary w-32 h-32 p-0"
                                          >
                                            <ExpandMoreIcon />
                                          </Button>
                                        )}
                                      </span>
                                    </Col>
                                  </Row>
                                  {
                                    entry.isEdit ? (
                                  <Row className="">
                                    <Col className="col-auto col-md-2">                                      
                                      <div>
                                        <span className="control-label fs-14 fw-bold me-2">
                                          Qty Ordered:
                                        </span>{" "}
                                        {entry?.balance_qty}
                                      </div>
                                    </Col>                                    
                                    <Col className="col-auto col-md-2">
                                      <InputCurrencyText
                                        name={`receivedProducts[${index}].qty_received`}
                                        label="Qty. Received"
                                        placeholder="Qty."
                                        value={
                                          values.receivedProducts[index]
                                            ?.qty_received
                                        }
                                        isInteger={true}
                                        onChange={(value, name) => {
                                          setFieldValue(
                                            `receivedProducts[${index}].qty_received`,
                                            value
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].qty_received`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Col>
                                    <Col className="col-auto col-md-2">
                                      <InputCurrencyText
                                        name={`receivedProducts[${index}].qty_returned`}
                                        label="Qty. Returned"
                                        placeholder="Qty."
                                        value={
                                          values.receivedProducts[index]
                                            ?.qty_returned
                                        }
                                        isInteger={true}
                                        onChange={(value, name) => {
                                          setFieldValue(
                                            `receivedProducts[${index}].qty_returned`,
                                            value
                                          );
                                        }}
                                      />
                                      {/* <InputText
                                        controlId={`receivedProducts[${index}].qty_returned`}
                                        label="Qty. Returned"
                                        placeholder="Qty."
                                        // touched={touched?.receivedProducts[index]?.qty_returned}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        // errorsField={errors?.receivedProducts[index]?.qty_returned}
                                        value={
                                          values.receivedProducts[index]
                                            ?.qty_returned
                                        }
                                      /> */}
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].qty_returned`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Col>
                                    <Col className="col-auto col-md-2">
                                      <InputCurrencyText
                                        name={`receivedProducts[${index}].qty_discard`}
                                        label="Qty. Discard"
                                        placeholder="Qty."
                                        value={
                                          values.receivedProducts[index]
                                            ?.qty_discard
                                        }
                                        isInteger={true}
                                        onChange={(value, name) => {
                                          setFieldValue(
                                            `receivedProducts[${index}].qty_discard`,
                                            value
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].qty_discard`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Col>
                                    <Col className="col-auto">
                                      <FormSelect
                                        placeholder="Select"
                                        label="Quality Check"
                                        name={`receivedProducts[${index}].quality_status`}
                                        options={GrnQualityCheckStatus}
                                        getOptionLabel={(option: any) =>
                                          option.value
                                        }
                                        getOptionValue={(option: any) =>
                                          option.key
                                        }
                                        onChange={(
                                          name: string,
                                          value: string
                                        ) => {
                                          setFieldValue(name, value);
                                        }}
                                        value={
                                          values.receivedProducts[index]
                                            ?.quality_status
                                        }
                                      />
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].quality_status`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Col>
                                  </Row>

                                    ) : (
                                      <Row>                                        
                                        <Col className="col-md-2">
                                            <label className="control-label fs-14 fw-bold text-nowrap">Qty Ordered</label>
                                            <div className="fs-14 mb-3">
                                            {entry?.balance_qty}
                                            </div>
                                        </Col>                                        
                                        <Col className="col-auto col-md-2">
                                            <label className="control-label fs-14 fw-bold">Qty. Received</label>
                                            <div className="fs-14 mb-3">
                                            {entry?.qty_received}
                                            </div>
                                        </Col>
                                        <Col className="col-auto col-md-2">
                                            <label className="control-label fs-14 fw-bold">Qty. Returned</label>
                                            <div className="fs-14 mb-3">
                                            {entry?.qty_returned}
                                            </div>
                                        </Col>
                                        <Col className="col-auto col-md-2">
                                            <label className="control-label fs-14 fw-bold">Qty. Discard</label>
                                            <div className="fs-14 mb-3">
                                            {entry?.qty_discard}
                                            </div>
                                        </Col>
                                        <Col className="col-auto col-md-2">
                                            <label className="control-label fs-14 fw-bold">Quality Check</label>
                                            <div className="fs-14 mb-3">
                                            {entry?.quality_status?.value}
                                            </div>
                                        </Col>
                                      </Row>
                                    )

                                  }
                                  {values.receivedProducts[index]
                                    ?.quality_status?.key === "Fail" && (
                                    <Row className="pt-2 pb-0">
                                      <Col className="col-md-12 pb-2">
                                        <label className="control-label fs-14 fw-bold w-100">Return Reason</label>
                                        {
                                          entry.isEdit ? (
                                            <>
                                            <InputTextArea
                                          controlId={`receivedProducts[${index}].return_remarks`}
                                          label=""
                                          placeholder="Enter return reason"
                                          // touched={touched.receivedProducts[index]?.return_remarks}
                                          handleBlur={handleBlur}
                                          handleChange={handleChange}
                                          // errorsField={errors.receivedProducts[index]?.return_remarks}
                                          name={`receivedProducts[${index}].return_remarks`}
                                          value={
                                            values.receivedProducts[index]
                                              ?.return_remarks
                                          }
                                          className="form-control textarea-height"
                                        />
                                        <ErrorMessage
                                          name={`receivedProducts[${index}].return_remarks`}
                                          component="div"
                                          className="text-danger fs-14"
                                        /></>
                                          )
                                          :
                                          ( <span>{values.receivedProducts[index]
                                            ?.return_remarks}</span>)
                                        }
                                        
                                      </Col>
                                      <Col className="col-md-12">
                                        <h3 className="fs-18 fw-bold info-text mt-2">
                                          Products Photos
                                        </h3>
                                      </Col>
                                      <Col className="col-md-12 d-flex good-inward-box-cover">
                                        {
                                          entry.isEdit && (
                                            <>
                                            {imageLoading ? (
                                              <label className="file-box">
                                                <span>
                                                  <Spinner />
                                                </span>
                                              </label>
                                            ) : (
                                              <label className="file-box me-3">
                                                <input
                                                  type="file"
                                                  name="image"
                                                  onChange={(e) =>
                                                    uploadProfileImage(
                                                      e,
                                                      values,
                                                      index,
                                                      setFieldValue
                                                    )
                                                  }
                                                  accept="image/png, image/gif, image/jpeg"
                                                />
                                                <span>
                                                  {" "}
                                                  <Image
                                                    className="img-fluid"
                                                    src={FileAddIcon}
                                                    alt="Logo"
                                                  />
                                                </span>
                                              </label>
                                            )}
                                            </>
                                          )
                                        }

                                        {/* <FileUploader uploadProfileImage={(e) => uploadProfileImage(e, values, index, setFieldValue)} imageLoading={imageLoading} imagePreview={imagePreview} /> */}
                                        {values.receivedProducts[
                                          index
                                        ].imagesPreview.map(
                                          (imagePath: any, imageIndex: any) => (
                                            <div
                                              key={`returnImage_${index}_${imageIndex}`}
                                            >
                                              <label className="file-box good-inward-box-cover me-3">
                                                <span>
                                                  {" "}
                                                  <Image
                                                    className="img-fluid"
                                                    src={
                                                      imagePath
                                                        ? imagePath
                                                        : AddPhotoAlternate
                                                    }
                                                    alt="Logo"
                                                  />
                                                  {
                                                    entry.isEdit && (
                                                      <div
                                                        className="good-inward-delete-icon cursor-pointer"
                                                        onClick={() =>
                                                          handleDeleteReturnImage(
                                                            values,
                                                            index,
                                                            imageIndex,
                                                            setFieldValue
                                                          )
                                                        }
                                                      >
                                                        <DeleteIcon className="text-danger fs-16" />
                                                      </div>
                                                    )
                                                  }
                                                </span>
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </Col>
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].images`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Row>
                                  )}
                                </Card>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    />
                    <div className="row">
                      <div className="col-md-12">
                        {
                          values.receivedProducts.length > 0 ? (
                            <div className="text-end mb-2">
                              <div
                                className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                                onClick={handleCancel}
                              >
                                Cancel
                              </div>
                              {
                                projectData?.status !='cp' && (
                                  <CustomButton
                                    type="submit"
                                    loading={btnLoading}
                                    disabled={imageLoading || btnLoading}
                                    className="fw-semibold fs-13 text-white mw-65 mt-2"
                                    variant="primary"
                                  >
                                    Submit
                                  </CustomButton>
                                )
                              }
                            </div>
                          )
                          :
                          (
                            <div className="text-start fw-bold">No Products Dispatched.</div>
                          )

                        }
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          
        </div>
      )}
    </React.Fragment>
  );
};

export default OrderRecievedDetails;
