import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import Loader from "../../components/common/Loader";
import {
  getHmrcForm,
  getCorrectStudentLoanList,
  correctStudentLoanDTO
} from "../../services/hmrcService";

import FormHmrc from "./FormHmrc";
import ViewHmrc from "./ViewHmrc";

const EmployeeHmrcForm: React.FC<Record<string, number>> = () => {
  const [loading, setLoading] = useState(true);
  const [hmrcData, setHmrcData] = useState<any>(null);
  const [signatureUrl, setSignatureUrl] = useState(null);
  const [isMutation, setIsMutation] = useState(false);
  const [correctStudentLoanData, setCorrectStudentLoanData] = useState<correctStudentLoanDTO[]>([]);

  useEffect(() => {
    fetchData();
  }, [isMutation]);

  const fetchData = () => {
    setLoading(true);
    getHmrcForm()
      .then((response) => {
        setHmrcData(response?.data);
        setSignatureUrl(response?.signatureUrl);
      })
      .catch((error) => {
        getCorrectStudentLoanList()
        .then((response: any) => {
          const responseData = response.data;
          setCorrectStudentLoanData(
            Object.keys(responseData).map((name: string) => ({ key: name, name: responseData[name].label, tooltip: responseData[name].tooltip }))
          );
        })
        .catch((error) => {
          toast(error.response.data.message, { type: toast.TYPE.ERROR });
        })
      }).finally(() => {
        setLoading(false);
      });
  };

  const mutationUpdate = () => {
    setIsMutation(!isMutation);
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">HMRC Form</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            {hmrcData === null && <FormHmrc correctStudentLoanData = {correctStudentLoanData} setIsMutation={mutationUpdate} />}
            {hmrcData && <ViewHmrc hmrcData = {hmrcData} signatureUrl={signatureUrl} />}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeeHmrcForm;
