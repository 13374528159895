import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, layouts } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import FormSelect from "../../../components/common/FormSelect";
import { currencyMask } from "../../../utility/common";
import { getFinanceDashboardPaymentStatus, getFinanceDashboardTotalIncomeExpense } from "../../../services/financeService";
import { useScrollTrigger } from "@mui/material";
import PieChartSkeleton from "../../../components/common/Skeleton/PieChart.skeleton";
import CounterSkeleton from "../../../components/common/Skeleton/Counter.skeleton";

interface AddProps {
  dateRange: any;
  isMutation: boolean;
}

export const FinanceDashboardProjectPayment: React.FC<AddProps> = ({ dateRange, isMutation }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [selectFilter, setSelectFilter] = useState<any>( { key: 6, value : "Last 6 Months"});
  const [loading, setLoading] = useState(true);
  const [centerTextPlugin, setCenterTextPlugin] = useState<any>();
  const [projectCount,setProjectCount] = useState(0)
  const [totalIncomeData,setTotalIncomeData] = useState<any>({
    total_expenses: 0,total_income: 0,total_profile_loss:0
  });
  const getDashboardPaymentStatus = () => {
    
    getFinanceDashboardPaymentStatus(dateRange)
      .then((response) => {
        //setPoRaisedData(response.data);
        convertToChartData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getDashboardTotalIncomeExpenseData = () => {
    
    getFinanceDashboardTotalIncomeExpense(dateRange)
      .then((response) => {
        //setPoRaisedData(response.data);
       // convertToChartData(response.data);
       setTotalIncomeData({
        total_expenses: response.data?.total_expenses ?? 0,
        total_income: response.data?.total_income ?? 0,
        total_profile_loss:response.data?.total_profile_loss ?? 0
       })
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getDashboardPaymentStatus();
    getDashboardTotalIncomeExpenseData();
  }, [dateRange]);

  useEffect(() => {    
    getDashboardTotalIncomeExpenseData();
  }, [dateRange, isMutation]);
  const labels = ["Amount Received 0", "Amount Pending"];
  const POdata = {
    labels,
    datasets: [
      {
        data: [0, 0],
        hoverOffset: 4,
        backgroundColor : ["#4ECB71","#ee3030"]
      },
    ],
  };

   // Create a custom plugin
   

  // Function to convert API response to Chart.js data
  const convertToChartData = (apiResponse: any) => {    
    const receiveAmt = apiResponse.received_amount.toFixed(2) || 0;
    const pendingAmt = apiResponse.pending_amount.toFixed(2) || 0;
    const totalProject = apiResponse.projects_count || 0;
    setProjectCount(totalProject)
    const dataLabels = [`Amount Received ${receiveAmt}`, `Amount Pending ${pendingAmt}`];
    setPoRaisedData({
      labels: dataLabels,
      datasets: [
        {
          data:[receiveAmt,pendingAmt],          
          backgroundColor: ["#4ECB71","#ee3030"], // Ensuring we have the right number of colors
          hoverOffset: 4,
        },
      ],
    });
    
    setCenterTextPlugin( {
      id: 'centerText',
      afterDraw: (ChartJS: any) => {
        const { ctx, chartArea: { width, height } } = ChartJS;
        ctx.save();
        const titleName = 'Total  \n';
        const titleSubname = 'Project \n';
        const totalCount =  totalProject;
        ctx.font = ' 14px "Nunito Sans", sans-serif';
        ctx.fillStyle = '#222';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(titleName, width / 2, (height / 2) - 20);
        ctx.fillText(titleSubname, width / 2, (height / 2) + 0);
        ctx.fillText(totalCount, width / 2, (height / 2) + 20);
        ctx.restore();
      }
    });
  };

  const [poRaisedData, setPoRaisedData] = useState<any>(POdata);

 

 // ChartJS.register(centerTextPlugin);

  const options: any = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  plugins: {
    legend: {
      position: "right" as const,
      labels: {
        boxWidth: 40, // Adjust this to increase the width
        boxHeight: 20, // Adjust this to increase the height
        padding: 20,
        usePointStyle: true,
        pointStyle: 'rectRounded',
        template: {
          maxWidth: 30,
        },
        font: {
          size: 12,
          weight: 400
        }
      },
    },
    title: {
      display: false
    },
    datalabels: {
      display: false
    },
  },
  };

  return (
    <React.Fragment>
      {loading ? (
        <>
        <Card className="p-3 w-100 pageContentInner rounded-2 mb-1">
    <PieChartSkeleton />
   </Card>
   <Card className="p-3 w-100 pageContentInner rounded-2 mb-1">
    <CounterSkeleton />
   </Card>
   </>
      ) : (
        <div className="d-flex flex-column pageContainer px-sm-1 w-100 ">
          <Card className="p-3 w-100 pageContentInner rounded-2 mb-1">

          <h5 className="fs-18 fw-bold info-text mb-0">Project Payment Status</h5>
          <div style={{ width: '100%', height: '200px' }}>
          <Doughnut data={poRaisedData} options={options} plugins={[centerTextPlugin]} key={projectCount} />
          </div>
          </Card>  
          <Card className="p-3 w-100 pageContentInner rounded-2 mb-0">
            <h5 className="fs-18 fw-bold info-text mb-0">Income/Expenses</h5> 
              <Row className="mt-3">
                <Col>Total Income</Col>
                <Col className="text-end fw-bold">{ currencyMask(totalIncomeData.total_income)}</Col>
              </Row>
              <Row className="mt-3">
                <Col>Total Expenses</Col>
                <Col className="text-end fw-bold">{ currencyMask(totalIncomeData.total_expenses)}</Col>
              </Row>
              <Row className="mt-3">
                <Col>GP1</Col>
                <Col className={`text-end fw-bold ${totalIncomeData.total_profile_loss >= 0 ? 'text-primary': 'text-danger'}`}>{ currencyMask(totalIncomeData.total_profile_loss)}</Col>
              </Row>
              </Card>        
        </div>
      )
    }
    </React.Fragment>
  );
};
