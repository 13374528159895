import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import { getFleetAlertsApi } from "../../../services/fleetService";
import { Col, Row } from "react-bootstrap";
interface AddProps {
  fleetId: string | number | undefined;
}

export const ViewFleetAlerts: React.FC<AddProps> = ({ fleetId }) => {
  const [loading, setLoading] = useState(true);
  const [fleetAlertData, setFleetAlertsData] = useState<any>([]);

  const getFleetAlerts = () => {
    setLoading(true);
    getFleetAlertsApi(fleetId)
      .then((response) => {
        setFleetAlertsData(response.data);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fleetId) {
      getFleetAlerts();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <Row>
            <Col>
              {fleetAlertData.length > 0 ? fleetAlertData.map(
                (item: { alert_message: string }, index: any) => (
                  <div key={`alert_${index}`} className="mt-2 ms-0">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.alert_message.replace(
                          /\bdue\b/g,
                          '<span class="text-danger">due</span>'
                        ),
                      }}
                    />
                  </div>
                )
              ) : (
                <div className="mt-2 ms-0">No Record</div>
              )}
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};
