export const PERMISSION_MODULE = {
  ALL_EMPLOYEE: "ALL_EMPLOYEE",
  FORM_BUILDER: "FORM_BUIDER",
  MANAGE_EXTERNAL_LINKS: "MANAGE_EXTERNAL_LINKS",
  POSTAL_CODE : "POSTAL_CODE",
  DEALS:'DEALS',
  LETTER_TEMPLATE:'LETTER_TEMPLATE',
  VENDOR_MANAGEMENT:'VENDOR_MANAGEMENT',
  PROJECTS:'PROJECTS',
  SURVEY:'SURVEY',
  WAREHOUSE:'WAREHOUSE',
  OPENING_STOCK:'OPENING_STOCK',
  PRODUCT_MASTER:'PRODUCT_MASTER',
  GOOD_INWARD:'GOOD_INWARD',
  DISPATCH_ORDER:'DISPATCH_ORDER',
  INTERNAL  :'INTERNAL',
  SUPPORT:'SUPPORT',
  HR_DASHBOARD:'HR_DASHBOARD',
  ATTENDANCE_MANAGEMENT:'ATTENDANCE_MANAGEMENT',
  PROFILE_CHANGE_REQUEST:'PROFILE_CHANGE_REQUEST',
  HOLIDAY:'HOLIDAY',
  LEAVES:'LEAVES',
  LIBRARY:'LIBRARY',
  LIBRARY_VERSION_HISTORY:'LIBRARY_VERSION_HISTORY',
  ASSET_MANAGEMENT:'ASSET_MANAGEMENT',
  HMRC_FORM:'HMRC_FORM',
  MEDICAL_QUESTIONNAIRE: 'MEDICAL_QUESTIONNAIRE',
  ANNOUNCEMENTS:'ANNOUNCEMENTS',
  FINANCE_DASHBOARD:'FINANCE_DASHBOARD',
  FINANCE_PROJECTS:'FINANCE_PROJECTS',
  FINANCE_INVOCIES:'FINANCE_INVOCIES',
  FINANCE_EXTERNAL_EXPENSES:'FINANCE_EXTERNAL_EXPENSES',
  FINANCE_EXPENSES:'FINANCE_EXPENSES',
  SERVICE_WARRANTY_MANAGEMENT:'SERVICE_WARRANTY_MANAGEMENT',
  FLEET_MANAGEMENT:'FLEET_MANAGEMENT',
  MY_TEAM: 'MY_TEAM',
  MEETING: 'MEETING',
  TEAM_MEETING: 'TEAM_MEETING',
  INSTALLATIOIN_TASK: 'INSTALLATIOIN_TASK',
  TASK: 'TASK',



};

export const PERMISSION_ACCESS = {
  ADD: "Add",
  EDIT: "Edit",
  DELETE: "Delete",
  LIST: "List",
  VIEW: "View",
  SENSITIVE_DATA: "Sensitive_Data",
};
