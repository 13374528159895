import { Card } from "react-bootstrap";
import { Button, Col, Row } from "react-bootstrap";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckIcon from '@mui/icons-material/Check';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkAbility, currencyMask, getFirstLetters } from "../../../utility/common";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";

import ticketDateIcon from "../../../assets/images/ticket-date-icon.svg";
import Loader from "../../../components/common/Loader";
import { getTicketCommentsApi, getTicketDetailsApi, updateTicketStatusApi } from "../../../services/ticketService";
import moment from "moment";
import constants, { tickerStatus } from "../../../constants/constants";
import DeletePopup from "../../../components/common/deletePopup";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import TicketComments from "./comments";
import { selectAuthentication } from "../../../features/Auth/authSlice";

export const ViewInternalTicket = () => {
  const [loading, setLoading] = useState(true);
  const [ticketData, setTicketData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(AddPhotoAlternate);
  const [copied, setCopied] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [ticketChatData, setTicketChatData] = useState([]);
  const {user }:any = useSelector(selectAuthentication);
  const userId = user.user.id;

  let { id } = useParams();
  const navigate = useNavigate();

  const editTicket = () => {    
    navigate(`/tickets/edit/${id}`);
  };

   /**
   * fetch Categories to list in the category type drop down
   * @async
   */
   const getTicketComments = async (ticketId: string | number | undefined) => {
    return new Promise((resolve, reject) => {  
      if(ticketId){
        getTicketCommentsApi(ticketId).then((response) => {    
          setTicketChatData(response.data.ticketData); 
          setCommentData(response.data.chatData); 
         // setLoading(false);          
          return resolve(true);
        })
        .catch(() => {
         // setLoading(false);
        });
      }    
    });
  };
  

  const getTicketDetails = (id: string) => {
    setLoading(true);
    getTicketDetailsApi(id)
      .then((response) => {
        setTicketData(response.data);        
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/tickets");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/tickets");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/tickets");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getTicketDetails(id);
      getTicketComments(id);
    } else {
      setLoading(false);
    }
  }, []);

  const renderStatusDiv = (checkedVal: number | string |undefined) => {
    const statsuArr:any = tickerStatus
    const statusName = statsuArr.find(
      (item: { key: string | number | undefined; }) => item.key == checkedVal
    );
    return <span className={`${statusName?.tagClass} py-1 px-2 ms-0 rounded-1 d-inline-block fs-11 fw-bold vertical-middle`}>{statusName?.value}</span>
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (error) {
      console.error('Failed to copy link: ', error);
      alert('Failed to copy link');
    }
  };

  const handleStatusChangeClick = () => {
    setDeletePopupOpen(true);
  }

  const handleDelete = () => {
    setLoading(false);
    updateTicketStatusApi(id)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);  
        handleClose();      
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });          
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });          
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });          
        }
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    if(id){
      getTicketDetails(id);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1 pt-3">
          <Row>
            <Col className="col-8 pe-0">
              <div className="pageHeader px-3 pb-2 mb-1">
                <Row className="align-items-center">
                  <Col>
                    {(checkAbility(
                      PERMISSION_ACCESS.EDIT,
                      PERMISSION_MODULE.INTERNAL
                    ) &&
                      ticketData.status != 2 && ((ticketData.created_by === userId && user.user?.employeeDetails) || (user.user?.employeeDetails === null))) && (
                        <Button
                          className="fw-semibold fs-12 text-white  btn btn-info"
                          variant="info"
                          type="button"
                          onClick={() => handleStatusChangeClick()}
                        >
                          <CheckIcon className="me-2 fs-15" />
                          Mark Complete
                        </Button>
                      )}

                    <div className="d-inline position-relative">
                      <Button
                        className="fw-semibold fs-12 ms-2  btn btn-outline-info bg-white copied-text"
                        variant="outline-info"
                        type="button"
                        onClick={() => handleCopy()}
                      >
                        <InsertLinkIcon className="me-2 fs-15" />
                        {copied ? "Copied" : "Copy Link"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="text-end">
                    {(checkAbility(
                      PERMISSION_ACCESS.EDIT,
                      PERMISSION_MODULE.INTERNAL
                    ) &&
                      ticketData.status != 2 && ((ticketData.created_by === userId && user.user?.employeeDetails) || (user.user?.employeeDetails === null))) && (
                        <Button
                          className="fw-semibold fs-12 text-white  btn btn-primary"
                          variant="primary"
                          type="button"
                          onClick={() => editTicket()}
                        >
                          <ModeEditIcon className="me-2 fs-15" />
                          Edit Ticket
                        </Button>
                      )}
                  </Col>
                </Row>
                <h1 className="fw-bold h4 mt-2 mb-0">{ticketData?.title}</h1>
                <div className="py-1 px-2 rounded-1 info-tag d-inline-block fs-11 mt-2">
                  <span className="fw-bold">Ticket Id:</span>{" "}
                  {ticketData.ticket_no_str}
                </div>
              </div>
              <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
                <Row>
                  <Col className="col">
                    <Card className=" p-3 w-100 pageContentInner mb-3">
                      <Row>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Assignee
                          </label>
                          <div className="fs-14 d-flex">
                            <span className="ticket-user-tag me-2">
                              {getFirstLetters(
                                ticketData?.assignedUser?.full_name
                              )}
                            </span>
                            {ticketData?.assignedUser?.full_name}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Due Date
                          </label>
                          <div className="fs-14 d-flex">
                            <span className="me-2">
                              <img src={ticketDateIcon} />
                            </span>
                            {moment(ticketData?.due_date).format(
                              constants.dateFormats.slashSeparatedDate
                            )}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Status
                          </label>
                          <div className="fs-14">
                            {renderStatusDiv(ticketData.status)}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Created By
                          </label>
                          <div className="fs-14 d-flex">
                            <span className="ticket-user-tag me-2 green">
                              {getFirstLetters(
                                ticketData?.createdUser?.full_name
                              )}
                            </span>
                            {ticketData?.createdUser?.full_name}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Department Name
                          </label>
                          <div className="fs-14">{ticketData?.department}</div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Created Date
                          </label>
                          <div className="fs-14">
                            {moment(ticketData?.createdAt).format(
                              constants.dateFormats.slashSeparatedDate
                            )}
                          </div>
                        </Col>
                        <Col className="col-12 mb-3">
                          <label className="control-label fw-bold">
                            Task Description
                          </label>
                          <div className="fs-14">{ticketData.description}</div>
                        </Col>
                        {
                          ticketData?.filesSignedURL && ticketData?.filesSignedURL.length > 0 && (
                            <Col className="col-12">
                              <div className="fs-14">
                                <label className="file-box me-3 ticket-flex-box">
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={ticketData?.filesSignedURL[0]}
                                      alt="Logo"
                                    />
                                  </span>
                                </label>
                              </div>
                            </Col>
                          )
                        }
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-4 ps-0">
              <div className="pe-3">
                <Card className="pageHeader w-100 pageContentInner mb-3">
                  <h1 className="fw-bold h4 fs-20 info-text pt-3 pb-3 mt-0 mb-0 p-3 pb-0 border-bottom">
                    Ticket Thread
                  </h1>
                  <div className="">
                    <TicketComments
                      ticketId={id}
                      ticketData={ticketChatData}
                      commentData={commentData}
                      onSubmitData={getTicketComments}                      
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <DeletePopup
            isOpen={isDeletePopupOpen}
            onClose={handleClose}
            onDelete={handleDelete}
            label="Mark Ticket as Complete"
            text="Are you sure you want to mark this Ticket as complete?"
            actionType="info"
          />
        </div>
      )}
    </React.Fragment>
  );
};
