import { Card, Col, Row } from "react-bootstrap";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { getDelayMeetingList } from "../../services/hrdashboard.service";
import Loader from "../../components/common/Loader";
   
 const MeetingDelayFullList = () => {
    

    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState([]);
    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      fetchData(signal);
      return () => {
        controller.abort();
      };
    }, []);
    const fetchData =(signal: any) =>{
      getDelayMeetingList(signal).then((res)=>{
          setListData(res.data);
  
      }).catch((err)=>{
  
      }).finally(()=>{
          setLoading(false)
      })
    }

    
    const columns: GridColDef[] = [
      {
        field: "$employee?.full_name$",
        headerName: "Employee Name",
        sortable: true,
        filterable: true,
        flex : 1,
        hideable:false,
        renderCell: (params) => {
          return (
            <div title={params.row.employee?.full_name}>
               {params.row.employee?.full_name}
            </div>
          );
        }     
      },
      {
        field: "$employee?.employeeDetails?.manager?.full_name$",
        headerName: "Reporting Manager",
        sortable: true,
        filterable: true,
        flex : 1,
        hideable:false,
        renderCell: (params) => {
          return (
            <div title={params.row.employee?.employeeDetails?.manager?.full_name}>
               {params.row.employee?.employeeDetails?.manager?.full_name}
            </div>
          );
        }     
      },
      {
        field :'type',
        headerName: 'Type',
        flex: 1,
        filterable: true,

      },
      {
        field :'date',
        headerName: 'Date',
        flex: 1,
        filterable: true,
      },
      {
        field :'status',
        headerName: 'Status',
        flex: 1,
        filterable: true,
        renderCell: (params) => {
          return (
            <div title="Delay">
               Delay
            </div>
          );
        }   
      },
     
      ];


      
      
      
  return (
    <>
      <Row>
      
        <Col md={12} className="mb-3">
          <h2 className="fs-20 info-text fw-bold">Meeting Status</h2>
          {loading ? <Loader isLoading={loading}/> : 
           <Card className="p-0 w-100 ">
           <div className="d-flex flex-column rounded-0 dataGridListMain">
            <DataGrid
                  rows={listData}               
                  columns={columns}  
                  pageSizeOptions={[5]}
                  rowSelection={false}
              />
              </div>
          </Card>}
        </Col>
      </Row>
    </>
  );
};

export default MeetingDelayFullList;
