import React from 'react';  
import InstallationCount from './installation.count';
import { Line } from 'react-chartjs-2';
import LineChart from '../Charts/line.chart';
import TopInstaller from './topInstaller';
import { DateRangeInterface } from '../dashboardInterface';
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
const Installation: React.FC<DateRangeInterface> = ({orderDateRange}) => {
    return (
        <div className='col-md-12'>
            <Card className="w-100 pageContentInner rounded-2  mb-3">
                <Row>
                    <Col className='col-md-8'>
                        <div className='p-3'>
                        <Row>
                            <Col className='col-md-5'>
                                <InstallationCount  orderDateRange={orderDateRange}/>
                            </Col>
                            <Col className='col-md-7'>
                                <LineChart  orderDateRange={orderDateRange}/>
                            </Col>
                        </Row>
                        </div>
                        
                    </Col>
                    <Col className='col-md-4 dashboard-border-left'>
                        <div className='w-100'>
                    
                            <TopInstaller />
                        </div>
                    </Col>
                </Row>
                
            
            </Card>
        </div>
    )
}

export default Installation;