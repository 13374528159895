import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { addSchema } from "./validation";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import InputTextArea from "../../../components/common/InputTextArea";
import QuillEditor from "../../../components/common/QuillEditor";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import {
  formTemplateAllowedFileTypes,
  formTemplateMaxFileSizeMB,
} from "../../../constants/constants";
import FormSelect from "../../../components/common/FormSelect";
import GeneralFileUploader from "../../../components/common/GeneralFileUploader";
import {
  FORM_TEMPLATE_TYPE_ERROR,
  FORM_TEMPLATE_SIZE_ERROR,
} from "../../../validations/ValidationErrors";
import {
  createLetterTemplate,
  getLetterTemplateById,
  getLetterTemplateTypes,
  updateLetterTemplate,
  TemplateType
} from "../../../services/letterTemplateService";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";

type FormValues = {
  title: string;
  type: TemplateType | string;
  description: string;
  email_subject?: string;
  email_body?: string;
  file?: string | File;
  filename?: string;
};

export default function AddLetterTemplate() {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [templateTypes, setTemplateTypes] = useState<TemplateType[]>([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [letterTemplate, setLetterTemplate] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams();

  /**
   * Get existing letter template from server
   * @async
   * @param {id} ID of template
   */
  const getLetterTemplate = async (id: string) => {
    setLoading(true);
    await getLetterTemplateById(id)
      .then((response: any) => {
        setLetterTemplate(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  /**
   * fetch template types to list in the type drop down
   * @async
   */
  const fetchTemplateTypes = async () => {
    return new Promise((resolve, reject) => {
      getLetterTemplateTypes().then((response) => {
        const templateTypeOptions = response.data.map((item: string) => ({
          key: item,
          value: item
        }));
        setTemplateTypes(templateTypeOptions);
        return resolve(true);
      });
    });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues, actions} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
      setLoading(true);
      if(typeof values.type === 'object' && values.type.key === 'Email Template'){
        delete values.file;
      }

      if(typeof values.type === 'object' && values.type.key === 'Form Template'){
        delete values.email_subject;
        delete values.email_body;

        if(typeof values.file === 'string'){
          delete values.file;
        }
      }

      delete values.filename;

    if (id) {
      updateLetterTemplate(id, values)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/letter-templates");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        }).finally(() => {
          setLoading(false);
        });
    } else {
      createLetterTemplate(values)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/letter-templates");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        }).finally(() => {
          setLoading(false);
        });
    }
  };

  /**
   * Cancel handler - redirection to listing
   * @returns {*}
   */
  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchTemplateTypes().then(() => {
      if (id) {
        getLetterTemplate(id);
      }else{
        setLoading(false)
      }
    });
  }, []);

  /**
   * Upload form template PDF/Word file
   * @param {events, setFieldValue}
   * @returns {*}
   */
  const uploadFile = (event: any, setFieldValue: any) => {
    const file = event.target.files[0];
    if(file){
      // Check file type
      if (!formTemplateAllowedFileTypes.includes(file.type)) {
        toast(FORM_TEMPLATE_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (file.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
        toast(FORM_TEMPLATE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        setFieldValue('file', file);
        setFieldValue('filename',file.name);
      }
    }
  };

  const initialValues = {
    title: letterTemplate?.title ? letterTemplate?.title : "",
    type: letterTemplate?.type ? {'key': letterTemplate?.type, 'value': letterTemplate?.type} : "",
    description: letterTemplate?.description ? letterTemplate?.description : "",
    email_subject: letterTemplate?.email_subject ? letterTemplate?.email_subject : "",
    email_body: letterTemplate?.email_body ? letterTemplate?.email_body : "",
    file: letterTemplate?.file ? letterTemplate?.file : "",
    filename: letterTemplate?.file ? letterTemplate?.file_original_name : ""
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">{id ? "Edit" : "Add"} Letter Template</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={() => addSchema(id)}
              initialValues={initialValues}
              onSubmit={(values: FormValues, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleBlur,
                values,
                touched,
                isValid,
                errors
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  
                  <Card className="p-3 w-100 pageContentInner">
                    <div>
                      <Row>
                        <div className="col-md-4">
                          <InputText
                            controlId="title"
                            label="Title"
                            placeholder="Enter Title"
                            touched={touched.title}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.title}
                            value={values.title}
                          />
                        </div>
                        <div className="col-md-4">
                          <FormSelect
                            placeholder="Select Template Type"
                            label="Type"
                            name="type"
                            options={templateTypes}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            value={values.type}
                            error={errors.type}
                            touched={touched.type}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div>
                          <InputTextArea
                            controlId="description"
                            label="Description"
                            placeholder="Enter Description"
                            touched={touched.description}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.description}
                            name="description"
                            value={values.description}
                            className="max-height300 resize-none"
                          />
                        </div>
                      </Row>
                      {typeof values.type === 'object' && values.type.key === 'Email Template' && (<Row>
                      <div className="col-md-8">
                          <InputText
                            controlId="email_subject"
                            label="Email Subject"
                            placeholder="Email Subject"
                            touched={touched.email_subject}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.email_subject}
                            value={values.email_subject}
                          />
                        </div>
                        <div>
                          <QuillEditor
                            controlId="email_body"
                            label="Email Body"
                            placeholder="Enter Email Body"
                            touched={touched.email_body}
                            handleBlur={() => {
                              setFieldTouched('email_body',true)
                            }}
                            handleChange={(name: string, content: any, editor: any) => {
                              editor.getText().trim() ? setFieldValue(name, content): setFieldValue(name, '');
                            }}
                            errorsField={errors.email_body}
                            name="email_body"
                            value={values.email_body}
                            className=" resize-none"
                          />
                        </div>
                      </Row>)}
                      {typeof values.type === 'object' && values.type.key === 'Form Template' && <Row>
                      <div className="col-md-12">
                          <GeneralFileUploader
                            uploadFile={(e) =>
                              uploadFile(e, setFieldValue)
                            }
                            imageLoading={imageLoading}
                            label="Upload Form"
                            filename={values.filename}
                          />

                          {errors.file && (
                            <p className="text-danger mt-1">Form Template is required</p>
                          )}
                        </div>
                      </Row>}
                    </div>
                  </Card>
                  

                  <div className="text-end my-3 px-3">
                  <CustomButtonCancel label="Cancel" />
                    <CustomButton
                      type="submit"
                      loading={btnLoading}
                      className="fw-semibold fs-13 text-white mw-90 mt-2"
                      variant="primary"
                    >
                      {id ? "Update" : "Add"}
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
            </div>
        </div>
      )}
    </React.Fragment>
  );
}
