import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getPurchaseOrderChartData } from "../../../services/dashboardMaster.service";
import { DateRangeInterface } from "../dashboardInterface";
import PieChartSkeleton from "../../../components/common/Skeleton/PieChart.skeleton";
ChartJS.register(ArcElement, Tooltip, Legend,ChartDataLabels);
 
const PurchaseChart : React.FC<DateRangeInterface> = ({orderDateRange}) => {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [poData, setPoData] = useState<any>([]);
    
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
        
  }, [orderDateRange]);
  const fetchData = (signal:any) =>{
    getPurchaseOrderChartData(orderDateRange,signal).then((res) => { 
        convertToChartData(res.data);
        setPoData(res.data);
        setLoading(false);
    }).catch((error) => {
        console.log(error);
        setLoading(false);
    });
  }
  // Function to convert API response to Chart.js data
  const convertToChartData = (apiResponse: any[]) => {
    const chartData = apiResponse.filter((item) => item.status_count> 0);
    const labels = chartData.map((item) => item.label);
    const data = chartData.map((item) => item.status_count);
    const dataPercentage = chartData.map((item) => item.status_percent);
    const backgroundColor = chartData.map((item) => item.color);
    const totalObj = apiResponse.find(item => item.hasOwnProperty('status_count'));
     if(totalObj){

       setTotal(totalObj.status_count)
     }

    setPoRaisedData({
      labels,
      datasets: [
        {
          data,
          dataPercentage,
          backgroundColor: backgroundColor.slice(0, labels.length), // Ensuring we have the right number of colors
          hoverOffset: 4,
        },
      ],
    });
  };
  const dpData = {
    labels: ["Completed", "Pending", "Delayed"],
    datasets: [
      {
        data: [0, 0, 0],
        hoverOffset: 4,
      },
    ],
  };

  const [poRaisedData, setPoRaisedData] = useState<any>(dpData);

  const options: any = {
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 20,
          padding: 20,
          
        },
        usePointStyle:true,
        pointStyle:'rectRounded',
      },
      datalabels: {
        color: "#FFFFFF",
        anchor: "middle",
        align: "middle",
        borderWidth: 0,
        borderColor: "#36A2EB",
        borderRadius: 25,        
        backgroundColor: (context: { dataset: { backgroundColor: any } }) => {
          return context.dataset.backgroundColor;
        },
        font: {
          weight: "bold",
          size: "18",
        },
        formatter: (value: any,context:any) => {
         // console.log(context.dataset.dataPercentage, context.dataIndex)
          return context.dataset?.dataPercentage[context.dataIndex] && `${context.dataset?.dataPercentage[context.dataIndex]}%`;
        },
      },
    },
    maintainAspectRatio: false
  };


// Custom plugin to draw the total in the center

 

  
  
  return (
    <React.Fragment>
      {loading ? (
        <PieChartSkeleton />
      ) : (
        
          <div className="p-3 w-100 ">
            <h5 className="fs-18 info-text fw-bold mb-3">Purchase Order</h5>
            {poData.length > 0 ? 
            <div className="my-3" style={{ height: '340px', width: '100%' }}> 
            <Doughnut data={poRaisedData} options={options}  />
            </div>
            : <div className="text-center">No Data Available</div>}
          </div>
       
      )
    }
    </React.Fragment>
  );
};

export default PurchaseChart;