import { Route, Routes } from 'react-router-dom';
import { checkAbility } from '../../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../../constants/permissionUser';
import FinanceInvoiceList from './index';

export const FinanceExpenses = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.FINANCE_EXPENSES) && (
                <Route path="/" element={<FinanceInvoiceList />} />
            )}           
        </Routes>
    )
};