import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { validationSchema } from "./section.validation";
import { getSectionByIdApiCall , addSectionAPICall,
  updateSectionAPICall,} from "../../../services/formbuilder/section.services.js";


import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import InputText from "../../../components/common/InputText";
import InputTextArea from "../../../components/common/InputTextArea";

export default function AddSection(props) {
  const [sectionData, setSectionData] = useState(null);
  const [formLoader, setFormLoader] = useState(true);
  let id = props.sectionId;
  useEffect(() => {
    if (id) {
      getSectionByIdApiCall(id).then((response) => {
        setFormLoader(false);

        setSectionData(response.data);
      });
    } else {
      setFormLoader(false);
    }
  }, []);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    if (id) {
      updateSectionAPICall(id, values)
        .then((response) => {
          toast.success(response.msg);
          props.handleModalClose();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.response.msg);
        });
    } else {
      addSectionAPICall(values)
        .then((response) => {
          //navigate(adminRoutes.manageFaq.path);
          toast.success(response.msg);
          props.handleModalClose();
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.response.msg);
        });
    }
  };
  return (
    <div id="section">
      {formLoader ? (
        <Loader />
      ) : (
        
        <Modal
          show={props.showSection}
          onHide={props.handleModalClose}
          centered
          backdrop="static"
          dialogClassName="modal-400w">
          

          <Modal.Body>
          <button type="button" className="btn-close text-right  btn-option-cover" aria-label="Close" onClick={props.handleModalClose}></button>
            <h4 className="fw-bold info-text mb-3">{id ? "Edit Section Section" : "Add Section"}</h4>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                title: sectionData ? sectionData.title : "",
                order: sectionData ? parseInt(sectionData.order) :parseInt(props.sectionOrder),
                sub_title: sectionData ? sectionData.sub_title : "",
                field_sheet_id: props.field_sheet_id,
               
              }}
              onSubmit={handleSubmit}>
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
              }) => (
                <div>
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row">
                            
                              <div className="col-md-6">
                               
                                <InputText
                                  controlId="title"
                                  label="Section Title "
                                  placeholder="Enter section title"
                                  touched={touched.title}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.title}
                                  value={values.title}
                                />
                              </div>
                              <div className="col-md-6">
                             
                              <InputText
                                  controlId="sub_title"
                                  label="Section Subtitle "
                                  placeholder="Enter section subtitle"
                                  touched={touched.sub_title}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.sub_title}
                                  value={values.sub_title}
                                />
                                                            
                            
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row">
                             
                              <div className="col-md-6">
                                <label className="lh-sm control-label fs-14 fw-bold form-label">Order</label>
                                <select
                                  name="order"
                                  onChange={handleChange}
                                  error={errors.order}
                                  value={values.order}
                                  touched={touched.order}
                                  disabled={props.isView}
                                  className={`form-control form-select mb-3 ${
                                    errors.order && touched.order
                                      ? "is-invalid"
                                      : ""
                                  }`}>                                  
                                 
                                  {Array.from(
                                    { length: props.sectionOrder},
                                    (_, index) => (
                                      <option key={index + 1} value={index + 1}>
                                        {index + 1}
                                      </option>
                                    )
                                  )}
                                </select>
                                {touched.order && errors.order && (
                                  <p className="fs-11 text-danger pt-1 mb-0">
                                    {errors.order}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    

                   



                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                  <button
                      variant="secondary"
                      className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                      onClick={props.handleModalClose}>
                      Cancel
                    </button>

                    <button
                      variant="primary"
                      onClick={handleSubmit}
                      className="fw-semibold fs-13 text-white mw-60  mt-2 btn btn-primary"
                      type="button">
                      {id ? "Edit Section" : "Add Section"}
                    </button>
                   
                  </div>
                </div>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
