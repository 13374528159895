import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getDocumentVerificationChart } from "../../services/hrdashboard.service";
import { DateRangeInterface } from "../Dashboard/dashboardInterface";
import PieChartSkeleton from "../../components/common/Skeleton/PieChart.skeleton";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DocumentVerificationChart: React.FC<DateRangeInterface> = ({
  orderDateRange,
}) => {
  const [loading, setLoading] = useState(true);
  const [documentData, setDocumentData] = useState<any>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
     
    convertToChartData(signal);
    return () => {
      controller.abort();
    };
    
  }, []);
  // Function to convert API response to Chart.js data
  const convertToChartData = (signal: any) => {
    getDocumentVerificationChart(orderDateRange, signal)
      .then((res) => {
        const apiResponse = res.data;
        console.log("api res", apiResponse);
        const chartData = apiResponse.filter(
          (item: any) => item.status_count >= 0
        );
        const labels = chartData.map((item: any) => item.label);
        const data = chartData.map((item: any) => item.status_count);
        const dataPercentage = chartData.map(
          (item: any) => item.status_percent
        );
        const backgroundColor = chartData.map((item: any) => item.color);
        const totalObj = apiResponse.find((item: any) =>
          item.hasOwnProperty("total")
        );
        if (totalObj) {
          setTotal(totalObj.total);
        }
        setDocumentData({
          labels,
          datasets: [
            {
              data,
              dataPercentage,
              backgroundColor: backgroundColor.slice(0, labels.length), // Ensuring we have the right number of colors
              hoverOffset: 4,
            },
          ],
        });
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  /*   const docData = {
    labels: ["Completed", "Pending", "Delayed"],
    datasets: [
      {
        data: [12,10,2],
        hoverOffset: 4,
      },
    ],
  }; */

  const options: any = {
    plugins: {
      legend: {
        position: "right" as const,
        labels: {
          boxWidth: 50,
          padding: 20,
          color: "#222222",
          usePointStyle:true,
          pointStyle:'rectRounded',
          font: {
            weight: "600",
            family: "'Nunito Sans', sans-serif",
            size: "14",
          },
        },
      },
      datalabels: {
        color: "#FFFFFF",
        anchor: "middle",
        align: "middle",
        borderWidth: 0,
        borderColor: "#36A2EB",
        borderRadius: 25,
        backgroundColor: (context: { dataset: { backgroundColor: any } }) => {
          return context.dataset.backgroundColor;
        },
        font: {
          weight: "600",
          size: "14",
        },
        formatter: (value: any, context: any) => {
          // console.log(context.dataset.dataPercentage, context.dataIndex)
          return (
            context.dataset?.dataPercentage[context.dataIndex] &&
            `${context.dataset?.dataPercentage[context.dataIndex]}%`
          );
        },
      },
    },
    maintainAspectRatio: false

  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column pageContainer w-100 pb-sm-1">
        <Card className="p-3 w-100 pageContentInner rounded-2">
          {loading ? (
            <PieChartSkeleton />
          ) : (
            <>
              <span className="fs-18 gray-text verify-total-text">
                Total Verifications :{" "}
                <span className="text-black fw-bold fs-20">{total}</span>
              </span>
              <div className=" " style={{height:'190px', width:'540px' }} >
              <Doughnut data={documentData} options={options}   />

              </div>
            </>
          )}
        </Card>
      </div>
    </React.Fragment>
  );
};

export default DocumentVerificationChart;
