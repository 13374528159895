import React, { useState, useEffect, MouseEvent, useRef } from "react";
import { Card, Row, Modal, OverlayTrigger, Button, Tooltip, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

import ClearIcon from '@mui/icons-material/Clear';
import InputText from "../../components/common/InputText";
import InputTextArea from "../../components/common/InputTextArea";
import Loader from "../../components/common/Loader";
import CustomButtonCancel from "../../components/common/CustomButtonCalcel";
import CustomButton from "../../components/common/CustomButton";

import { hmrcSchema } from "./validation";
import InfoIcon from '@mui/icons-material/Info';
import GeneralFileUploader from "../../components/common/GeneralFileUploader";
import {
  hmrcDto,
  employeeStatementDependency,
  studentLoansDependency,
  getCountries,
  fillHmrcForm,
  correctStudentLoanDTO
} from "../../services/hmrcService";
import InputDatePicker from "../../components/common/InputDatePicker";
import FormAsyncSelect from "../../components/common/AsyncSelect/FormAsyncSelect";
import InputRadio from "../../components/common/InputRadio";
import FileUploader from "../../components/common/FileUploader";
import InputCheckBoxGroup from "../../components/common/InputCheckBoxGroup";

import constants, {
  allowedFileTypes,
  maxFileSizeMB,
} from "../../constants/constants";
import {
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
} from "../../validations/ValidationErrors";
import DeletePopup from "../../components/common/deletePopup";
import { FaInfoCircle } from "react-icons/fa";

interface props {
  correctStudentLoanData: correctStudentLoanDTO[];
  setIsMutation: () => void;
}

const FormHmrc: React.FC<props> = ({ correctStudentLoanData, setIsMutation }) => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [isSaveConfirmationPopupOpen, setSaveConfirmationPopup] =
    useState(false);
  
  const [informationModalOpen, setInformationModalOpen] = useState<
    string | boolean
  >(false);
  const sigCanvas = useRef<SignatureCanvas>(null);

  const navigate = useNavigate();

  /**
   * Submit Method to call when user save button
   * @async
   * @param {hmrcDto, actions} values
   * @returns {*}
   */
  const submitHmrcForm = async (values: hmrcDto, { setSubmitting }: any) => {
    setLoading(true);

    const formData = {
      ...values,
      dob: moment(values.dob).isValid()
        ? moment(values.dob).format(constants.dateFormats.databaseFormat)
        : "",
      start_date: moment(values.start_date).isValid()
        ? moment(values.start_date).format(constants.dateFormats.databaseFormat)
        : "",
      declaration_date: moment(values.declaration_date).isValid()
        ? moment(values.declaration_date).format(
            constants.dateFormats.databaseFormat
          )
        : "",
      employee_statement: JSON.stringify(values.employee_statement),
      student_loans: JSON.stringify(values.student_loans),
    };

    fillHmrcForm(formData)
      .then((response: any) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setBtnLoading(false);
        setIsMutation();
      })
      .catch((error) => {
        setBtnLoading(false);

        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .finally(() => {
        setLoading(false);
        setImagePreview(null);
      });
  };

  // Fetching reporting managers
  const getAllCountries = (params: any) => {
    return new Promise((resolve, reject) => {
      getCountries(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  // Signature Update
  function setSignatureOnChange(setFieldValue: any) {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL("image/png");
      setFieldValue("sign", dataUrl);
    }
  }

  // Clear signature
  const clearSignature = (setFieldValue: any) => {
    if (sigCanvas.current) {
      setFieldValue("sign", '');
      sigCanvas.current.clear();
    }
  }

  const initialValues = {
    first_name: "",
    last_name: "",
    dob: null,
    home_address: "",
    postal_code: "",
    country_id: null,
    gender: "",
    ni_number: "",
    start_date: null,
    employee_statement: {
      another_job: null,
      other_payments: null,
      other_payments_since_apr6: null,
    },
    student_loans: {
      student_loan: null,
      apply_statements: null,
      correct_student_loan: null,
    },
    declaration_full_name: "",
    declaration_date: null,
    sign: ""
  };

  const handleClose = () => {
    setSaveConfirmationPopup(false);
  };

  const handleInformationModalPopupOpen = (
    event: MouseEvent<HTMLAnchorElement>,
    modalType: string
  ) => {
    event.preventDefault();
    setInformationModalOpen(modalType);
  };

  const handleInformationModalPopupClose = () => {
    setInformationModalOpen(false);
  };
  
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Formik
          validationSchema={hmrcSchema}
          initialValues={initialValues}
          onSubmit={(values: hmrcDto, actions) => {
            if (isSaveConfirmationPopupOpen === false) {
              setSaveConfirmationPopup(true);
            } else {
              setSaveConfirmationPopup(false);
              submitHmrcForm(values, actions);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            submitForm,
          }) => (
            <Form className="" noValidate onSubmit={handleSubmit}>
              
              <Card className="p-3 w-100 pageContentInner mb-2">
                <h3 className="fs-20 fw-bold info-text mb-3">Employee's Personal Details</h3>
                <Row>
                  <div className="col-md-4">
                    <InputText
                      controlId="last_name"
                      label="Last Name"
                      placeholder="Enter Last Name"
                      touched={touched.last_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.last_name}
                      value={values.last_name}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputText
                      controlId="first_name"
                      label="First Name"
                      placeholder="Enter First Name"
                      touched={touched.first_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.first_name}
                      value={values.first_name}
                      labelTooltip={<OverlayTrigger
                        placement="top"
                        overlay={<Tooltip><p className="mb-0 fs-13 fw-bold">Do not enter initials or shortened names for example, Jim for James or Liz for Elizabeth</p></Tooltip>}
                    >
                        <Button className="align-baseline d-inline-block ms-1 p-0 text-light" variant="link">
                          <InfoIcon className="fs-15 me-1 link-text"/>
                        </Button>
                    </OverlayTrigger> }
                    />
                  </div>
                  <div className="col-md-4">
                    <InputDatePicker
                      name="dob"
                      label="Date of Birth"
                      slotProps={{
                        textField: {
                          size: "small",
                          placeholder: "Select",
                          className: "form-control",
                          id: "dob",
                        },
                      }}
                      value={values.dob}
                      onChange={(name, newValue) =>
                        setFieldValue(name, newValue)
                      }
                      touched={touched.dob}
                      errors={errors.dob}
                      disableFuture={true}
                    />
                  </div>
                </Row>

                <Row>
                  <div className="col-md-4">
                    <InputTextArea
                      controlId="home_address"
                      label="Home Address"
                      placeholder="Enter Home Address"
                      touched={touched.home_address}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.home_address}
                      value={values.home_address}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputText
                      controlId="postal_code"
                      label="Postal Code"
                      placeholder="Enter Postal Code"
                      touched={touched.postal_code}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.postal_code}
                      value={values.postal_code}
                    />
                    <div className="hmrc-radio-cover">
                    <InputRadio
                      controlId="gender"
                      placeholder="Sex"
                      
                      label="What is your sex?"
                      name="gender"
                      data={[
                        { key: "male", name: "Male" },
                        { key: "female", name: "Female" },
                      ]}
                      handleChange={(event: any) => {
                        setFieldValue("gender", event.target.value);
                      }}
                      value={values.gender}
                      errorsField={errors.gender}
                      touched={touched.gender}

                      labelTooltip={<OverlayTrigger
                        placement="top"
                        overlay={<Tooltip><p className="mb-0 fs-13 fw-bold">As shown on your birth certificate or gender recognition</p></Tooltip>}
                    >
                        <Button className="align-baseline d-inline-block ms-1 p-0 text-light" variant="link">
                          <InfoIcon className="fs-15 me-1 link-text"/>
                        </Button>
                    </OverlayTrigger> }
                    />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <FormAsyncSelect
                      id="country_id"
                      label="Country"
                      name="country_id"
                      isAsync
                      isClearable
                      getOptions={getAllCountries}
                      value={values.country_id}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                      error={errors.country_id}
                      touched={touched.country_id}
                    />
                  </div>
                </Row>

                <Row>
                  <div className="col-md-4">
                    <InputText
                      controlId="ni_number"
                      label="National Insurance Number (If Any)"
                      placeholder="Enter National Insurance Number"
                      touched={touched.ni_number}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.ni_number}
                      value={values.ni_number}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputDatePicker
                      name="start_date"
                      label="Employment Start Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          placeholder: "Select",
                          className: "form-control",
                          id: "start_date",
                        },
                      }}
                      value={values.start_date}
                      onChange={(name, newValue) =>
                        setFieldValue(name, newValue)
                      }
                      touched={touched.start_date}
                      errors={errors.start_date}
                    />
                  </div>
                </Row>
              </Card>

             
              
              <Card className="p-3 w-100 pageContentInner mb-2">
                <div className="d-flex justify-content-between">
                  <h3 className="fs-20 fw-bold info-text mb-3">Employee's Statement</h3>
                  <a
                    className="fs-14 fw-semibold link-text text-decoration-none"
                    href="#"
                    onClick={(event) =>
                      handleInformationModalPopupOpen(event, "statement")
                    }
                  >
                    <InfoIcon className="fs-15 me-1"/>
                    More Information
                  </a>
                </div>
                <p className="fs-14 control-label">These questions will help you to choose the statement that matches your circumstances. The statement you choose helps your employer to apply the correct tax code.</p>
                <div className="form-hrmc-statment">
                <Row >
                  <InputRadio
                    controlId="another_job"
                    label="Do you have another job?"
                    name="another_job"
                    data={[
                      { key: "1", name: <>Yes <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <h4 className="text-info mb-1 fs-16 fw-bold">Statement C</h4>
                          <p className="fs-14 mb-3 pb-2">Tax Code BR</p>
                          <div className="fs-14 fw-bolder mb-2">Key</div>
                          <p className="fs-14 mb-0">
                            I have another job and/or I am in receipt of a State,
                            workplace or private pension.
                          </p>
                        </Tooltip>
                      }
                    >
                      <Button
                        className="align-baseline d-inline-block ms-1  statement-tag"
                        variant="link"
                      >
                        Statement C
                      </Button>
                    </OverlayTrigger></> },
                      { key: "0", name: "No" },
                    ]}
                    handleChange={(event: any) => {
                      setFieldValue(
                        "employee_statement.another_job",
                        event.target.value
                      );
                      employeeStatementDependency["another_job"].forEach(
                        (element) => {
                          setFieldValue(`employee_statement[${element}]`, null);
                          setFieldTouched(
                            `employee_statement[${element}]`,
                            false
                          );
                        }
                      );
                    }}
                    value={values.employee_statement.another_job}
                    errorsField={errors.employee_statement?.another_job}
                    touched={touched.employee_statement?.another_job}
                  />
                </Row>
                </div>
                {values.employee_statement.another_job === "1" && (
                  <div className="form-hrmc-statment">
                  <Row >
                    <InputRadio
                      controlId="other_payments"
                      label="Do you receive payments from a State, workplace or private pension?"
                      name="other_payments"
                      data={[
                        { key: "1", name: <>Yes <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            <h4 className="text-info mb-1 fs-16 fw-bold">Statement C</h4>
                            <p className="fs-14 mb-3 pb-2">Tax Code BR</p>
                            <div className="fs-14 fw-bolder mb-2">Key</div>
                            <p className="fs-14 mb-0">
                              I have another job and/or I am in receipt of a State,
                              workplace or private pension.
                            </p>
                          </Tooltip>
                        }
                      >
                        <Button
                          className="align-baseline d-inline-block ms-1  statement-tag"
                          variant="link"
                        >
                          Statement C
                        </Button>
                      </OverlayTrigger></> },
                        
                        { key: "0", name: "No" },
                      ]}
                      handleChange={(event: any) => {
                        setFieldValue(
                          "employee_statement.other_payments",
                          event.target.value
                        );
                        employeeStatementDependency["other_payments"].forEach(
                          (element) => {
                            setFieldValue(
                              `employee_statement[${element}]`,
                              null
                            );
                            setFieldTouched(
                              `employee_statement[${element}]`,
                              false
                            );
                          }
                        );
                      }}
                      value={values.employee_statement.other_payments}
                      errorsField={errors.employee_statement?.other_payments}
                      touched={touched.employee_statement?.other_payments}
                    />
                  </Row>
                  </div>
                )}
                {values.employee_statement.other_payments === "1" && (
                   <div className="form-hrmc-statment">
                  <Row>
                    <InputRadio
                      controlId="other_payments_since_apr6"
                      label={
                        <>
                          <div>
                            Since 6 April have you received payments from:
                          </div>
                          <ul className="fw-normal mt-2 ps-4">
                            <li>
                              Another job which has ended or any of the
                              following taxable benifts
                            </li>
                            <li>Jobseeker's Allowance (JSA)</li>
                            <li>Employment and Support Allowance (ESA)</li>
                            <li>Incapacity Benefit</li>
                          </ul>
                        </>
                      }
                      name="other_payments_since_apr6"
                      data={[
                        { key: "1", name: <>Yes <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            <h4 className="text-info mb-1 fs-16 fw-bold">Statement B</h4>
                            <p className="fs-14 mb-3 pb-2">
                              Current personal allowance on a Week 1/Month 1 basis
                            </p>
                            <div className="fs-14 fw-bolder mb-2">Key</div>
                            <p className="fs-14">
                              This is my first job since 6 April and since the 6 April I
                              have not received payments from any of the following:
                            </p>
                            <ul className="ps-4 fs-14">
                              <li className="">
                                Jobseeker’s Allowance 
                              </li>
                              <li className="">
                                
                                  Employment and Support Allowance
                                
                              </li>
                              <li className="">
                                Incapacity Benefit
                              </li>
                            </ul>
                          </Tooltip>
                        }
                      >
                        <Button
                          className="align-baseline d-inline-block ms-1  statement-tag"
                          variant="link"
                        >
                          Statement B
                        </Button>
                      </OverlayTrigger></> },
                        { key: "0", name: <>No <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            <h4 className="text-info mb-1 fs-16 fw-bold">Statement A</h4>
                            <p className="fs-14 mb-3 pb-2">Current personal allowance</p>
                            <div className="fs-14 fw-bolder mb-2">Key</div>
                            <p className="fs-14">
                              This is my first job since 6 April and since the 6 April I
                              have not received payments from any of the following:
                            </p>
                            <ul className="ps-4 fs-14">
                              <li className="">
                                Jobseeker’s Allowance 
                              </li>
                              <li className="">
                                
                                  Employment and Support Allowance
                                
                              </li>
                              <li className="">
                                Incapacity Benefit
                              </li>
                            </ul>
                          </Tooltip>
                        }
                      >
                        <Button
                          className="align-baseline d-inline-block ms-1  statement-tag"
                          variant="link"
                        >
                          Statement A
                        </Button>
                      </OverlayTrigger></> },
                      ]}
                      handleChange={(event: any) => {
                        setFieldValue(
                          "employee_statement.other_payments_since_apr6",
                          event.target.value
                        );
                      }}
                      value={
                        values.employee_statement.other_payments_since_apr6
                      }
                      errorsField={
                        errors.employee_statement?.other_payments_since_apr6
                      }
                      touched={
                        touched.employee_statement?.other_payments_since_apr6
                      }
                    />
                  </Row>
                  </div>
                )}
              </Card>

              
              
              <Card className="p-3 w-100 pageContentInner mb-2">
              <div className="d-flex justify-content-between">
                <h3 className="fs-20 fw-bold info-text mb-3">Student Loans</h3>
                

                <a
                 className="fs-14 fw-semibold link-text text-decoration-none"
                href="#"
                onClick={(event) =>
                  handleInformationModalPopupOpen(event, "loan")
                }
              >
                <InfoIcon className="fs-15 me-1"/>
                More Information
              </a>
              </div>
              <div className="form-hrmc-statment">
                <Row>
                  <InputRadio
                    controlId="student_loan"
                    label="Do you have a student or postgraduate loan?"
                    name="student_loan"
                    data={[
                      { key: "1", name: "Yes" },
                      { key: "0", name: "No" },
                    ]}
                    handleChange={(event: any) => {
                      setFieldValue(
                        "student_loans.student_loan",
                        event.target.value
                      );
                      studentLoansDependency["student_loan"].forEach(
                        (element) => {
                          setFieldValue(`student_loans[${element}]`, null);
                          setFieldTouched(`student_loans[${element}]`, false);
                        }
                      );
                    }}
                    value={values.student_loans.student_loan}
                    errorsField={errors.student_loans?.student_loan}
                    touched={touched.student_loans?.student_loan}
                  />
                </Row>
              </div>
                {values.student_loans.student_loan === "1" && (
                  <div className="form-hrmc-statment">
                  <Row>
                    <InputRadio
                      controlId="apply_statements"
                      label={
                        <>
                          <span>Do any of the following statements apply:</span>
                          <ul className="fw-normal mt-2 ps-4">
                            <li>
                              You're still studying on a course that your
                              student loan relates to
                            </li>
                            <li>
                              You completed or left your course after the start
                              of the current tax year, which start on 6 April
                            </li>
                            <li>You've already repaid your loan in full</li>
                            <li>
                              You're paying the Student Loans Company by Direct
                              Debit from your bank to manage your end of loan
                              repayments
                            </li>
                          </ul>
                        </>
                      }
                      name="apply_statements"
                      data={[
                        { key: "1", name: "Yes" },
                        { key: "0", name: "No" },
                      ]}
                      handleChange={(event: any) => {
                        setFieldValue(
                          "student_loans.apply_statements",
                          event.target.value
                        );
                        studentLoansDependency["apply_statements"].forEach(
                          (element) => {
                            setFieldValue(`student_loans[${element}]`, null);
                            setFieldTouched(`student_loans[${element}]`, false);
                          }
                        );
                      }}
                      value={values.student_loans.apply_statements}
                      errorsField={errors.student_loans?.apply_statements}
                      touched={touched.student_loans?.apply_statements}
                    />
                  </Row>
                  </div>
                )}
                {values.student_loans.apply_statements === "0" && (
                  <div className="form-hrmc-statment form-hrmc-plans">
                  <Row>
                    {correctStudentLoanData && (
                      <>
                        
                        <InputCheckBoxGroup
                          label={
                            <>
                              To avoid repaying more than you need to, tick the
                          correct student loan or loans that you have - use the
                          guidance on the right to help you
                            </>
                          }
                          controlId="correct_student_loan"
                          touched={touched.student_loans?.correct_student_loan}
                          data={correctStudentLoanData}
                          handleChange={(event: any) => {
                            if (
                              values.student_loans.correct_student_loan === null
                            ) {
                              values.student_loans.correct_student_loan = [];
                            }
                            if (event.target.checked) {
                              setFieldValue(
                                "student_loans.correct_student_loan",
                                [
                                  ...values.student_loans.correct_student_loan,
                                  event.target.value,
                                ]
                              );
                            } else {
                              setFieldValue(
                                "student_loans.correct_student_loan",
                                values.student_loans.correct_student_loan.filter(
                                  (item) => item !== event.target.value
                                )
                              );
                            }
                          }}
                          handleBlur={handleBlur}
                          errorsField={
                            errors.student_loans?.correct_student_loan
                          }
                          existingData={
                            values.student_loans?.correct_student_loan ?? []
                          }
                        />
                      </>
                    )}
                  </Row>
                  </div>
                )}
              </Card>

              
              <Card className="p-3 w-100 pageContentInner">
                <h3 className="fs-20 fw-bold info-text mb-3">Declaration</h3>
                <p className="fs-14 control-label">I confirm that the information I’ve given on this form is correct.</p>
                <Row>
                  <div className="col-md-4">
                    <InputText
                      controlId="declaration_full_name"
                      label="Full Name (USE CAPITAL LETTERS)"
                      placeholder="Enter full name"
                      touched={touched.declaration_full_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.declaration_full_name}
                      value={values.declaration_full_name}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputDatePicker
                      name="declaration_date"
                      label="Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          placeholder: "Select",
                          className: "form-control",
                          id: "declaration_date",
                        },
                      }}
                      value={values.declaration_date}
                      onChange={(name, newValue) =>
                        setFieldValue(name, newValue)
                      }
                      touched={touched.declaration_date}
                      errors={errors.declaration_date}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="col-md-4">
                    <div className="position-relative">
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{ className: "sigPad" }}
                      onEnd={(e: any) => {
                        setSignatureOnChange(setFieldValue);
                      }}
                    />
                    <Button className="btn-outline-secondary cav-close" type="button" onClick={() => clearSignature(setFieldValue)} ><ClearIcon /></Button>
                   </div>
                  <p>Sign here using MOUSE or TOUCH screen if using a tablet.</p>
                  {errors.sign && touched.sign ? (
                    <div className="error-message text-danger">{errors.sign}</div>
                  ) : null}

                    
                  </div>
                </Row>
              </Card>

              <div className="text-end my-3 px-3">
                <CustomButton
                  type="submit"
                  loading={btnLoading}
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Submit
                </CustomButton>
              </div>

              {isSaveConfirmationPopupOpen && (
                <DeletePopup
                  isOpen={isSaveConfirmationPopupOpen}
                  onClose={handleClose}
                  onDelete={() => {
                    submitForm();
                  }}
                  actionType="submit"
                  label="Are you sure?"
                  text="Once you submit, you will not be able to fill and save the HMRC form again, are you sure to submit?"
                />
              )}
            </Form>
          )}
        </Formik>
      )}
      {informationModalOpen && informationModalOpen === "statement" && (
        <Modal className="form-hmrc-modal" show={true} centered>
          <Modal.Body className="position-relative">
            <button
              type="button"
              className="btn-close text-right  btn-option-cover"
              aria-label="Close"
              onClick={handleInformationModalPopupClose}
            ></button>
            <h2 className="text-info fw-bolder mb-3 fs-24">More Information</h2>
 
          <Row>
            <Col md={4}>
              <div className="common-box">
                <h4 className="text-info mb-1 fs-16 fw-bold">Statement A</h4>
                <p className="fs-14 mb-3 pb-2">Current personal allowance</p>
                <div className="fs-14 fw-bolder mb-2">Key</div>
                <p className="fs-14">
                  This is my first job since 6 April and since the 6 April I
                  have not received payments from any of the following:
                </p>
                <ul className="ps-4 fs-14">
                  <li className="">
                    Jobseeker’s Allowance 
                  </li>
                  <li className="">
                    
                      Employment and Support Allowance
                    
                  </li>
                  <li className="">
                    Incapacity Benefit
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="common-box">
                <h4 className="text-info mb-1 fs-16 fw-bold">Statement B</h4>
                <p className="fs-14 mb-3 pb-2">
                  Current personal allowance on a Week 1/Month 1 basis
                </p>
                <div className="fs-14 fw-bolder mb-2">Key</div>
                <p className="fs-14">
                  This is my first job since 6 April and since the 6 April I
                  have not received payments from any of the following:
                </p>
                <ul className="ps-4 fs-14">
                  <li className="">
                    Jobseeker’s Allowance 
                  </li>
                  <li className="">
                    
                      Employment and Support Allowance
                    
                  </li>
                  <li className="">
                    Incapacity Benefit
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="common-box">
                <h4 className="text-info mb-1 fs-16 fw-bold">Statement C</h4>
                <p className="fs-14 mb-3 pb-2">Tax Code BR</p>
                <div className="fs-14 fw-bolder mb-2">Key</div>
                <p className="fs-14">
                  I have another job and/or I am in receipt of a State,
                  workplace or private pension.
                </p>
              </div>
            </Col>
          </Row>

          <Col md={12} className="border-bottom pb-3 mb-0">
            <div className="text-center mt-4 fs-14">
              For more information about tax codes, go to{" "}
              <Link target="_blank" to="https://www.gov.uk/tax-codes" className="link-text text-decoration-none fw-bold">www.gov.uk/tax-codes</Link>
            </div>
          </Col>
          <Col md={12}>
            <div className="common-box-new">
              <div className="fs-14 fw-bolder mb-2">Key</div>

              <ul className="ps-4 fs-14">
                <li className="">
                  
                    Jobseeker’s Allowance (JSA) is an unemployment benefit which
                    can be claimed while looking for work.{" "}
                  
                </li>
                <li className="">
                  
                    Employment and Support Allowance (ESA) is a benefit which
                    can be claimed if you have a disability or health condition
                    that affects how much you can work.{" "}
                  
                </li>
                <li className="">
                  
                    Incapacity Benefit is help if you could not work because of
                    an illness or disability before 31 January 2011.{" "}
                  
                </li>
                <li className="">
                  
                    State Pension is a pension paid when you reach State Pension
                    age.{" "}
                  
                </li>
                <li className="">
                  
                    Workplace pension is a pension which was arranged by your
                    employer and is being paid to you.{" "}
                  
                </li>
                <li className="">
                  
                    Private pension is a pension arranged by you and is being
                    paid to you.{" "}
                  
                </li>
              </ul>
              <div className="fs-14 mt-3">Please note that no other Government or HMRC paid benefits need to be considered when completing this form.</div>
            </div>
            
          </Col>







 
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end mb-2 pt-0"></Modal.Footer>
        </Modal>
      )}
      {informationModalOpen && informationModalOpen === "loan" && (
        <Modal className="export-excel-modal" show={true} centered>
          <Modal.Body className="position-relative">
            <button
              type="button"
              className="btn-close text-right  btn-option-cover"
              aria-label="Close"
              onClick={handleInformationModalPopupClose}
            ></button>
           <h2 className="text-info fw-bolder mb-3 fs-24">More Information</h2>

          
          <div className="mb-3">
            <p className="fs-14 mb-0">Employees, for more information about the type of loan you have or to check your balance, go to</p> 
            <Link target="_blank" to="https://www.gov.uk/sign-in-to-manage-your-student-loan-balance" className="link-text text-decoration-none fw-bold">www.gov.uk/sign-in-to-manage-your-student-loan-balance</Link> 
          </div>
          <div className="mb-3">
            <p className="fs-14 mb-0">Employers, for guidance on student loans and which plan or loan type to use if your employee has selected more than one, go to </p>
            <Link target="_blank" to="https://www.gov.uk/guidance/special-rules-for-student-loans" className="link-text text-decoration-none fw-bold">www.gov.uk/guidance/special-rules-for-student-loans</Link>
          </div>
          
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end mb-2 pt-0"></Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default FormHmrc;
