//image set preview model bootstrap react js
import React, { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getSignedUrlApi } from "../../../services/survey/survey.service";
import Loader from "../../../components/common/Loader";

interface IImagePreview {
  show: boolean;
  onHide: () => void;
  images: string[];
}

const ImagesPreview: FC<IImagePreview> = ({ show, onHide, images }) => {
  const [signedImages, setSignedImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (images.length) {
      fetchImages();
    }
  }, [images]);
  const fetchImages = () => {
    setLoading(true);
    getSignedUrlApi({ images: images })
      .then((res) => {
        setSignedImages(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        onHide();
      });
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="export-excel-modal"
        >
          <Modal.Body>
            <>
              <button
                type="button"
                className="btn-close text-right  btn-option-cover"
                aria-label="Close"
                onClick={onHide}
              ></button>

              <div className="fs-18 fw-bold mb-3 info-text">Preview Image</div>
              {/* preview images here */}
              <div className="d-flex flex-wrap">
                {signedImages.map((image, index) => {
                  return (
                    <div key={index} className="m-2">
                      <img
                        src={image}
                        alt="preview"
                        loading="lazy"
                        style={{ width: "130px", height: "130px" }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ImagesPreview;
