import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { addSchema } from "./validation";
import { toast } from "react-toastify";
import { Card, Col, Row, Image } from "react-bootstrap";
import InputTextArea from "../../../components/common/InputTextArea";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import FormSelect from "../../../components/common/FormSelect";
import {
  assetStatusDTO,
  getAssetById,
  manageAssetStatus,
  FormSelectEmployeeType
} from "../../../services/assetService";
import { getEmployeeEmailList, employeeListDTO } from "../../../services/employeeService";
import InputRadio from "../../../components/common/InputRadio";
import DepositePaid from "../../../assets/images/deposit-paid-new.svg";
import AssetsHistory from "./history";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";

export default function AllocateAsset() {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [employees, setEmployees] = useState<FormSelectEmployeeType[]>([]);
  const [lastAllocatedEmployee, setLastAllocatedEmployee] = useState<any>({});
  const [assetImagePreview, setAssetImagePreview] = useState<any>(null);
  const [asset, setAsset] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams();



  /**
   * Get existing asset from server
   * @async
   * @param {id} ID of Asset
   */
  const getAsset = async (id: string) => {
    setLoading(true);
    await getAssetById(id)
      .then((response: any) => {
        if(response.data.status_key === 'rejected' || response.data.status_key === 'pending_for_approval'){
          navigate(-1);
        }
        setAsset(response.data);
        if(response.asset_image_url){
          setAssetImagePreview(response.asset_image_url)
        }
        // Fetching all employees to list in member dropdown and detail of employee to whom asset currently allocated 
        getEmployeeEmailList(response.data.lastAllocatedTo ? response.data.lastAllocatedTo.id : undefined).then((employeeResponse) => {
          const employeesData = employeeResponse.data.map((item: employeeListDTO) => ({
            key: item.id,
            value: item.full_name,
            empDetails: item.employeeDetails
          }));
          setEmployees(employeesData);

          if(response.data.lastAllocatedTo){
            setLastAllocatedEmployee(response.data.lastAllocatedTo);
          }
        });
        
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {createAssetDTO, actions} values
   * @returns {*}
   */
  const submitForm = async (values: assetStatusDTO, { setSubmitting }: any) => {
    setLoading(true);
    if(id){
      manageAssetStatus(id, values)
      .then((response: any) => {
        toast(response.message, { type: toast.TYPE.SUCCESS });
        navigate("/assets");
        setBtnLoading(false);
      })
      .catch((error: any) => {
        setSubmitting(false);
        setBtnLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      }).finally(() => {
        setLoading(false);
      });
    }else{
      toast("Something went wrong", { type: toast.TYPE.ERROR });
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if(id){
      getAsset(id);
    }
    setLoading(false);
  }, []);

  const initialValues = {
    status: "",
    note:"",
    employee_id:""
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Asset Allocation</h1>
              </Col>
            </Row>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={() => addSchema(asset.status_key)}
              initialValues={initialValues}
              onSubmit={(values: assetStatusDTO, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleBlur,
                values,
                touched,
                isValid,
                errors
              }) => (
                <Row ><div className="col-md-6">
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Card className="p-3 w-100 pageContentInner allocation-left-box">
                      {asset && (
                        <Row className="border-bottom ">
                          <div className="col-md-12 col-xl-8">
                            <div className="d-flex mb-3 align-items-center">
                              <div className="allocation-img-cover">{assetImagePreview ? (<Image className="img-fluid" src={assetImagePreview} alt="Asset Image" />) : (<Image className="img-fluid" src={DepositePaid} alt="No Image" />)}</div>
                              <div className="">
                                <div className="">
                                  <label className=" fs-16 fw-bold info-text me-2 mb-1">{asset.name}</label>
                                  <span className="asset-status-in-inventory">{asset.type}</span>
                                </div>
                                <div className=""><label className="lh-sm control-label fs-14 fw-bold form-label">{asset.asset_id}</label></div>
                                
                              </div>
                             
                            </div>
                            
                            
                          </div>
                          
                          
                            <div className="col-md-12 col-xl-4 mb-2">
                              {asset.status_key === 'in_inventory' && <div className="custom-from-group"><label className="lh-sm control-label fs-14  form-label fw-normal">{'Currently In Inventory'}</label></div>}
                              {asset.status_key === 'allocated' && <div className="custom-from-group"><label className="lh-sm control-label fs-14  form-label fw-normal">{'Currently Allocated to'}</label></div>}
                              {asset.status_key === 'dead_inventory' && <div className="custom-from-group"><label className="lh-sm control-label fs-14  form-label fw-normal">{'Currently In Dead Inventory'}</label></div>}
                              
                              {(asset.status_key === 'allocated' && lastAllocatedEmployee) && 
                                (<div>
                                  <div className="d-flex align-items-center">
                                    <div className="alloction-small-img me-1">{lastAllocatedEmployee?.employeeDetails?.user_photo ? (
                                      <Image className="img-fluid" src={lastAllocatedEmployee.employeeDetails.user_photo} alt="Employee Image" />
                                      ) : (
                                      <Image className="img-fluid" src={DepositePaid} alt="No Image" />)
                                      }
                                    </div>
                                    <label className="lh-sm info-text fs-14 fw-bold">{lastAllocatedEmployee.full_name}</label>
                                  </div>
                                  
                                </div>
                              )}
                            </div>
                          
                        </Row>
                      )}


                      <div className="pt-3 pb-0 pe-2 ps-2">
                        <Row>
                          <div className="col-md-12 allocation-radio">
                            <InputRadio
                              controlId="status"
                              placeholder="Reallcoate to"
                              label="Reallcoate to"
                              name="status"
                              data={[
                                { key: 'in_inventory', name: 'Inventory' },
                                { key: 'allocated', name: 'Member' },
                                { key: 'dead_inventory', name: 'Dead Inventory' },
                              ]}
                              handleChange={(event: any) => {
                                setFieldValue('status',event.target.value);
                              }}
                              value={values.status}
                              errorsField={errors.status}
                              touched={touched.status}
                            />
                          </div>
                        </Row>

                        
                        
                        {values.status === 'allocated' && (<Row>
                          <div className="col-md-12 col-xl-4">
                            <FormSelect
                              placeholder="Select Member"
                              label="Member"
                              name="employee_id"
                              options={employees}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.employee_id}
                              error={errors.employee_id}
                              touched={touched.employee_id}
                            />
                          </div>

                          {typeof values?.employee_id === 'object' && values?.employee_id?.empDetails && (
                            <div className="col-md-12 col-xl-8">
                              <div className="row mt-xl-4">
                                <div className="col-md-6">
                                  <label className="fs-14 control-label fw-bold">
                                    Member Code: 
                                  </label>
                                  <p className="fs-14">{values.employee_id.empDetails.employment_id}</p>
                                </div>
                                <div className="col-md-6">
                                  <label className="fs-14 control-label fw-bold">
                                  Department: 
                                  </label>
                                  <p className="fs-14">{values.employee_id.empDetails.department}</p>
                                </div>
                              </div>
                              
                              
                            </div>
                          )}

                        </Row>)}
                        <Row>
                          <div className="col-md-12">
                            <InputTextArea
                              controlId="note"
                              label="Note"
                              placeholder="Enter optional note here"
                              touched={touched.note}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.note}
                              name="note"
                              value={values.note}
                              className="max-height300 resize-none"
                            />
                          </div>
                        </Row>
                        
                      </div>
                    </Card>
                  

                    <div className="text-end my-3 px-3">
                    <CustomButtonCancel label="Cancel" />
                      <CustomButton
                        type="submit"
                        loading={btnLoading}
                        className="fw-semibold fs-13 text-white mw-90 mt-2"
                        variant="primary"
                      >
                        Reallocate
                      </CustomButton>
                    </div>
                </Form>
                </div>
                <div className="col-md-6">
                  <Card className="p-3 w-100 pageContentInner allocation-left-box">
                    {id && <AssetsHistory assetId = {Number(id)} />}
                  </Card>
                </div>
                </Row>
              )}
            </Formik>
            </div>
        </div>
      )}
    </React.Fragment>
  );
}
