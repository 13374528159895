import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getDayChecklistApi } from "../../../services/task.service";
import Loader from "../../../components/common/Loader";
import CheckListTemplate from "./CheckListTemplate";

interface IDayProcess {
  selectedTab: string;
  projectId: string | number | undefined;
  type : string
  title : string
}

const DayProcess: React.FC<IDayProcess> = ({
  selectedTab,
  projectId,
  type,
  title
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getDayChecklistApi(projectId)
      .then((res) => {
         setData(res.data[type]);
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="survery-middle-content">
          
          
          <p className="fw-bold fs-14 ">{title}</p>
            
          <div>
            {data && data.length > 0 ? (
              <CheckListTemplate data={data} />
            ) : (
              <div className="text-center">No Data Found</div>
            )}
          </div>
          
        </div>
      )}
    </div>
  );
};

export default DayProcess;
