import * as yup from "yup";
import { useParams } from "react-router-dom";
import { 
  MINIMUM_3_CHAR,
  MAXIMUM_500_CHAR,
  ALPHA_NUMERIC_ALLOW,
  SELECT_ONE_USER_IN_RECEIPIENT_LIST,
  MAXIMUM_255_CHAR
} from "../../../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../../../validations/regex";

export const docValidationSchema = (id: string | number | undefined) => {
  return yup.object({
    doc_name : yup.string().trim().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).required().label('Document Name'),
    submission_last_date: yup.date().required().typeError('Please Enter valid date').label("Last Date of Submission"),
    expiry_date: yup.date().optional().nullable().typeError('Please Enter valid date').label("Expiry Date"),
    description: yup.string().trim().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).optional().label('description'),   
  });
}

export const sendDocValidationSchema = (id: string | number | undefined) => {
  return yup.object({
    doc_name : yup.string().trim().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).required().label('Document Name'),    
    description: yup.string().trim().min(3,MINIMUM_3_CHAR).max(255,MAXIMUM_255_CHAR).optional().label('description'),   
    file: yup.string().required().label('Upload File'),   
  });
}
