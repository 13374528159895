export interface EmployeeDataType{
  first_name?: string;
  last_name?: string;
  phone_no?: string;
  address?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  ni_number?: string;
  job_description?: string;
  keen_name1?: string;
  keen_contact_no1?: string;
  keen_relation1?: {
    value?: string;
    key?: string;
  };
  keen_name2?: string;
  keen_contact_no2?: string;
  keen_relation2?: {
    value?: string;
    key?: string;
  };
  bank_name?: string;
  account_name?: string;
  account_number?: string;
  sort_code?: string;
  user_photo?: string;
  user_photo_url?: string;
  personal_email?:string;
};

export interface AdminDataType{
  first_name: string;
  last_name: string;
};

export interface changePasswordDataType{
  current_password: string;
  password: string;
  confirm_password: string;
};

interface profileChangeFieldsLabelType{
  [key: string]: string;
}

export const profileChangeFieldsLabel:profileChangeFieldsLabelType = {
  'phone_no':'Contact Number',
  'first_name':'First Name',
  'last_name':'Family Name',
  'address':'Address',
  'city':'City',
  'state':'County',
  'postal_code':'Postal Code',
  'ni_number':'Ni Number',
  'job_description':'Job Description',
  'user_photo': 'Profile Photo',
  'keen_name1':'Kin 1 - Name',
  'keen_contact_no1':'Kin 1 - Contact Number',    
  'keen_relation1':'Kin 1 - Relation',  
  'keen_name2':'Kin 2 - Name',  
  'keen_contact_no2':'Kin 2 - Contact Number',
  'keen_relation2':'Kin 1 - Relation',  
  'bank_name':'Bank Name',  
  'account_name':'Account Name',  
  'account_number':'Account Number',  
  'sort_code':'Sort Code',
}

export type EmployeeProfileChangeRequest = {
  [key: string]: {
    [key: string]: string;
};
}

