import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PieChartSkeleton from "../../../components/common/Skeleton/PieChart.skeleton";
ChartJS.register(ArcElement, Tooltip, Legend,ChartDataLabels);
interface IProps {
  data: any;
  title : string;
  subTitle : string;
}
const ProjectChart : React.FC<IProps> = ({ data, title ,subTitle}) => {
   const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
   
  useEffect(() => {
    if(data){
      convertToChartData(data);
       
    }
  }, []);
  // Function to convert API response to Chart.js data
  const convertToChartData = (apiResponse: any[]) => {
    const chartData = apiResponse.filter((item) => item.status_count> 0);
    const labels = chartData.map((item) => item.label);
    const data = chartData.map((item) => item.status_count);
    const dataPercentage = chartData.map((item) => item.status_percent);
    const backgroundColor = chartData.map((item) => item.color);
    const totalObj = apiResponse.find(item => item.hasOwnProperty('total'));
     if(totalObj){

       setTotal(totalObj.total)
     }

    setPoRaisedData({
      labels,
      datasets: [
        {
          data,
          dataPercentage,
          backgroundColor: backgroundColor.slice(0, labels.length), // Ensuring we have the right number of colors
          hoverOffset: 4,
        },
      ],
    });
    setLoading(false)
  };
  const dpData = {
    labels: ["Completed", "Pending", "Delayed"],
    datasets: [
      {
        data: [0, 0, 0],
        hoverOffset: 4,
      },
    ],
  };

  const [poRaisedData, setPoRaisedData] = useState<any>(dpData);

  const options: any = {
    plugins: {
      legend: {
        position: "right" as const,
        labels: {
          boxWidth: 20,
          padding: 20,
          color: "#222222",
          usePointStyle:true,
          pointStyle:'rectRounded',
          font: {
            weight: "600",
            family: "'Nunito Sans', sans-serif",
            size: "14",
          },
        },
 
      },
      datalabels: {
        color: "#FFFFFF",
        anchor: "middle",
        align: "middle",
        borderWidth: 0,
        borderColor: "#36A2EB",
        borderRadius: 25,        
        backgroundColor: (context: { dataset: { backgroundColor: any } }) => {
          return context.dataset.backgroundColor;
        },
        font: {
          weight: "600",
          size: "14",
        },
        formatter: (value: any,context:any) => {
         // console.log(context.dataset.dataPercentage, context.dataIndex)
          return context.dataset?.dataPercentage[context.dataIndex] && `${context.dataset?.dataPercentage[context.dataIndex]}%`;
        },
      },
    },
    maintainAspectRatio: false

  };


// Custom plugin to draw the total in the center


const centerTextPlugin = {
  id: 'centerText',
  afterDraw: (ChartJS:  any) => {
    const { ctx, chartArea: { width, height } } = ChartJS;
    ctx.save();
    const titleName = subTitle;    
    const totalCount = total;

    // Draw titleName in bold
    ctx.font = '400 12px "Nunito Sans"';
    ctx.fillStyle = '#A9A9A9';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(titleName, width / 2, height / 2 - 10);

    // Draw totalCount in gray
    ctx.font = '600 20px "Nunito Sans"';
    ctx.fillStyle = '#222'; // Gray color
    ctx.fillText(totalCount, width / 2, height / 2 + 15);

    ctx.restore();
  }
};
  
  
  return (
    <React.Fragment>
      
        <div className="d-flex flex-column   w-100 pb-sm-1  ">
          
        
          <Card className="p-3 w-100   rounded-2 overflow-hidden">
          {loading ?  (<PieChartSkeleton  />) : (
            <>
            <h5 className="fs-18 info-text fw-bold">{title}</h5>
            <div className="my-3" style={{ height: '187px', width: '340px' }}> 
            <Doughnut data={poRaisedData} options={options}  plugins={[centerTextPlugin]}  />
              
            </div>
        
            </>
              )}
           </Card>
        </div>
      
    
    </React.Fragment>
  );
};

export default ProjectChart;