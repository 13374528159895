import { FieldArray, Formik, getIn } from "formik";
import React from "react";
import { Form, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import FormSelect from "../../../components/common/FormSelect";
import plusIcon from "../../../assets/images/formbuilderAdd.svg";
import deleteIconRed from "../../../assets/images/awesome-trash-red.svg";
import { actionOptions } from "../../../constants/constants";


const Actions = ({
  fieldData,
  keyIndex,
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
}) => {
  return (
    <div>
      <div className="">
        <FieldArray
          name={`field_conditions[${keyIndex}].actions`}
          render={(arrayHelpers) => (
            <div>
              {values.field_conditions[keyIndex].actions &&
               values.field_conditions[keyIndex].actions.length > 0 ? (
                values.field_conditions[keyIndex].actions.map(
                  (act, index) => (
                    <div key={index}>
                      <div className="p-0  border-0">
                        <div className="">
                          <Row className="align-items-center ">
                            <div className="col-md-3">
                              {index === 0 && (
                                <label className="lh-sm control-label fs-14 fw-bold form-label">
                                  Set conditions{" "}
                                </label>
                              )}

                              <FormSelect
                                placeholder="Select"
                                label=" "
                                name={`field_conditions[${keyIndex}].actions[${index}].field`}
                                controlId={`field_conditions[${keyIndex}].actions[${index}].field`}
                                options={fieldData}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                onChange={(name, value) => {
                                  setFieldValue(name, value);
                                }}
                                value={getIn(
                                  values,
                                  `field_conditions[${keyIndex}].actions[${index}].field`
                                )}
                                error={getIn(
                                  errors,
                                  `field_conditions[${keyIndex}].actions[${index}].field`
                                )}
                                touched={getIn(
                                  touched,
                                  `field_conditions[${keyIndex}].actions[${index}].field`
                                )}
                              />
                            </div>
                            <div className="col-md-3">
                              {index === 0 && (
                                <label className="lh-sm control-label fs-14 fw-bold form-label">
                                  Action
                                </label>
                              )}

                              <FormSelect
                                placeholder="Select"
                                label=" "
                                name={`field_conditions[${keyIndex}].actions[${index}].action`}
                                controlId={`field_conditions[${keyIndex}].actions[${index}].action`}
                                options={actionOptions}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                onChange={(name, value) => {
                                  setFieldValue(name, value);
                                }}
                                value={getIn(
                                  values,
                                  `field_conditions[${keyIndex}].actions[${index}].action`
                                )}
                                error={getIn(
                                  errors,
                                  `field_conditions[${keyIndex}].actions[${index}].action`
                                )}
                                touched={getIn(
                                  touched,
                                  `field_conditions[${keyIndex}].actions[${index}].action`
                                )}
                              />
                            </div>
                            <div className="col-md-3 pb-4">
                              {index === 0 && (
                                <div
                                  className="cursor-pointer pt-3"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      field: "",
                                      action: "",
                                    })
                                  }
                                >
                                  <img src={plusIcon} />
                                </div>
                              )}
                              {index != 0 && (
                                <div
                                  className="cursor-pointer"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <img src={deleteIconRed} />
                                </div>
                              )}
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className="d-flex justify-content-between">
                  <div>Field Conditions</div>

                  <div onClick={() => arrayHelpers.insert()}>
                    {" "}
                    Add Condition
                  </div>
                </div>
              )}             
            </div>
          )}
        />
      </div>
    </div>
  );
};
export default Actions;
