import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridFilterModel,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Form, Row } from "react-bootstrap";
import constants, {
  allowedFileTypes,
  maxFileSizeMB,
  pageSetting,
  pageSizeModel,
} from "../../../constants/constants";
import moment from "moment";
import InputText from "../../../components/common/InputText";
import { Formik } from "formik";
import CustomButton from "../../../components/common/CustomButton";
import CommentIcon from "@mui/icons-material/Comment";
import AddCommentIcon from "@mui/icons-material/AddComment";
import SendIcon from "@mui/icons-material/Send";
import AddLinkIcon from "@mui/icons-material/AddLink";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import { getAllManagerList } from "../../../services/authService";
import { getFleetNotesApi, saveFleetNotesApi, uploadFleetImageApi } from "../../../services/fleetService";
import { Scrollbar } from 'react-scrollbars-custom';
import commentPlusIcon from "../../../assets/images/comment-icon.svg";
import commentBlueIcon from "../../../assets/images/comment-blue-icon.svg";
interface AddProps {
  fleetId: string | number | undefined;
}

interface FilterValues {
  message: string;
  files: any;
  filename: any;
}

const FleetNotes: React.FC<AddProps> = ({ fleetId }) => {
  const [notesData, setNotesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [signedUrlsFiles, setSignedUrlsFiles] = useState<any>([]);
  const [isMutation, setIsMutation] = useState(false);

  /**
   * fetch Categories to list in the category type drop down
   * @async
   */
  const fetchData = async () => {
    return new Promise((resolve, reject) => {
      if (fleetId) {
        getFleetNotesApi(fleetId)
          .then((response) => {
            setNotesData(response.data.noteData);
            setLoading(false);
            return resolve(true);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    Promise.all([fetchData()]).then(() => {
      setLoading(false);
    });
  }, [fleetId, isMutation]);

  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to the last comment on load
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [notesData]);

 

  const initialValues = {
    message: "",
    files: [],
    filename: [],
  };

  const uploadFile = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    //console.log("file", imageFile);

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setImageLoading(true);

      // Create FormData object
      const formData = new FormData();
      let filesData: any = [];
      filesData.push(imageFile);
      // Append the file to the FormData object
      formData.append("files", imageFile);

      uploadFleetImageApi(formData)
        .then((response) => {
          setImageLoading(false);
          const uploadedFilesArr = uploadedFiles;
          uploadedFilesArr.push(response.data.files[0]);
          setUploadedFiles(uploadedFilesArr);

          const signedUrlsFilesArr = signedUrlsFiles;
          signedUrlsFilesArr.push(response.data.filesSignedURL[0]);
          setSignedUrlsFiles(signedUrlsFilesArr);
          setFieldValue("files", uploadedFilesArr);
          setFieldValue("filename", signedUrlsFilesArr);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };

  const handleDeleteReturnImage = (
    formValues: any,
    indexNumber: number,
    setFieldValue: any
  ) => {
    const failedGrnImageDetails = formValues.files;
    const failedGrnImagePreview = formValues.filename;
    failedGrnImageDetails.splice(indexNumber, 1);
    failedGrnImagePreview.splice(indexNumber, 1);
    setUploadedFiles(failedGrnImageDetails);
    setSignedUrlsFiles(failedGrnImagePreview);
    setFieldValue(`files`, failedGrnImageDetails);
    setFieldValue(`filename`, failedGrnImagePreview);
  };

  const submitForm = async (
    values: FilterValues,
    { setSubmitting, resetForm }: any
  ) => {
    const formData = {
      ...values
    };
    console.log(formData);
    //  return;
    saveFleetNotesApi(fleetId, formData)
      .then((response: any) => {
        toast(response.message, { type: toast.TYPE.SUCCESS });
        resetForm();
        setIsMutation(!isMutation);
        setBtnLoading(false);
      })
      .catch((error: any) => {
        setSubmitting(false);
        setBtnLoading(false);

        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
    // return;
    //  setBtnLoading(true);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="flex-grow-1 pageContent pb-2">
          <Scrollbar  className="ticket-comment-scroll   border-bottom">
          <div className="p-3" ref={commentsContainerRef}>
            {notesData.length > 0 ? notesData.map((commentObj: any, index: any) => (
              <Row className="mt-3 comment-line" key={`note_data${index}`}>
                <Col className="col-2 text-end">
                  {" "}
                  <img src={commentBlueIcon} />
                </Col>
                <Col className="col-10">
                  <div>
                    <span className="fw-bold">
                      {commentObj?.user?.full_name}
                    </span>{" "}
                    Added Note
                  </div>
                  <div className="text-muted text-gray">
                    {moment(commentObj?.createdAt).format(
                      `${constants.datetimeFormats.slashSeparatedDateTime}`
                    )}
                  </div>
                  <div className="comment-message fs-14">{commentObj.notes}</div>
                  <div className="col-md-12 d-flex good-inward-box-cover">
                    {commentObj.filesSignedURL &&
                      commentObj.filesSignedURL.map(
                        (imagePath: any, index: any) => (
                          <div key={`images${index}`}>
                            <label className="file-box me-3">
                              <span>
                                {" "}
                                <img
                                  className="img-fluid"
                                  src={imagePath}
                                  alt="Logo"
                                />
                              </span>
                            </label>
                          </div>
                        )
                      )}
                  </div>
                </Col>
              </Row>
            )) : (
              <div>No Record</div>
            )}
            </div>
          </Scrollbar>
          
          <Row>
            <Col>
              <Formik
                validationSchema={""}
                initialValues={initialValues}
                onSubmit={(values: FilterValues, actions) => {
                  submitForm(values, actions);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldError,
                  setFieldValue,
                }) => (
                  <Form className="ticket-comment-bottom" noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Col className="col-12 ticket-comment-input fleet-comment-input d-flex">
                      <span className="ticket-user-tag ms-2 mt-2">aa</span>
                        <InputText
                          controlId="message"
                          label=""
                          placeholder="Enter Comment"
                          className="border-0 bg-none"
                          touched={touched.message}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.message}
                          value={values.message}
                        />
                      </Col>
                      <Col className="text-end col-auto">
                        <input
                          type="file"
                          id="fileInput"
                          className="d-none"
                          accept=".jpeg, .png,.jpg,.svg"
                          onChange={(e) => uploadFile(e, setFieldValue)}
                        />
                        {/* Custom icon/button */}
                        <label htmlFor="fileInput" className="cursor-pointer">
                          <AddLinkIcon  className="text-gray "/>
                        </label>                       
                        <CustomButton
                          type="submit"
                          loading={btnLoading}
                          disabled={btnLoading}
                          className="fw-semibold fs-13 text-primary "
                          variant="link"
                        >
                          <SendIcon />
                        </CustomButton>
                      </Col>                     
                      {values.filename.length > 0 && (
                        <Col className="col-12">
                          {values.filename.map((fileUrl: any, index: any) => (
                            <label
                              key={`commentImages${index}`}
                              className="file-box good-inward-box-cover me-3"
                            >
                              <span>
                                {" "}
                                <img
                                  className="img-fluid"
                                  src={fileUrl}
                                  alt="Logo"
                                />
                                <div
                                  className="good-inward-delete-icon cursor-pointer"
                                  onClick={() =>
                                    handleDeleteReturnImage(
                                      values,
                                      index,
                                      setFieldValue
                                    )
                                  }
                                >
                                  <CancelIcon className="text-danger bg-white rounded-5 fs-16" />
                                </div>
                              </span>
                            </label>
                          ))}
                        </Col>
                      )}
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};

export default FleetNotes;
