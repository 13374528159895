import { Route, Routes } from "react-router-dom";
import LetterTemplates from "./index";
import AddLetterTemplate from "./Add";
import ViewLetterTemplate from "./view";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { Login } from "../Auth/Login";

export const LetterTemplate = () => {
  return (
    <Routes>
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.LETTER_TEMPLATE) && (
        <Route path="/" element={<LetterTemplates />} />
      )}
      {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.LETTER_TEMPLATE) && (
        <Route path="/add-letter-template" element={<AddLetterTemplate />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.LETTER_TEMPLATE) && (
        <Route path="/edit-letter-template/:id" element={<AddLetterTemplate />} />
      )}
      {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.LETTER_TEMPLATE) && (
        <Route path="/view-letter-template/:id" element={<ViewLetterTemplate />} />
      )}

      <Route path="*" element={<Login />} />
     
    </Routes>
  );
};
