import { axios } from "../../services/axios";
import {
  ADD_FIELDSHEET,
  FIELDSHEET_LIST,
  UPDATE_FIELDSHEET,
  FIELDSHEET_DETAILS,
  FORM_DELETE,
  UPDATE_SECTION_ORDER,
  FORM_TEMPLATE_LIST

} from "../../constants/paths";
import { AxiosResponse } from "axios";  
//Add field sheet data
export const addFieldSheetAPICall = async (data) => {
  return await axios.post(ADD_FIELDSHEET, data);
};

//Get field sheet data list
export const getFieldSheetApiCall = async (params) => {
  return await axios.get(FIELDSHEET_LIST, { params });
};

//Get a field sheet data by id
export const getFieldsheetApiCall = async (id) => {
  return await axios.get(`${FIELDSHEET_DETAILS}/${id}`);
};

//Update a field sheet data by id
export const updateFieldsheetApiCall = async (id, data) => {
  return await axios.patch(`${UPDATE_FIELDSHEET}/${id}`, data);
};

//update order by drag and drop api
export const updateOrderApiCall = async (id, data) => {
  return await axios.patch(`${UPDATE_SECTION_ORDER}/${id}`, data);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteFormApi = (id) => {
  return axios.delete(FORM_DELETE +'/'+id);
}


export const getFormTemplates = async  (params:any|null ): Promise<AxiosResponse> => {
  return await axios.get(FORM_TEMPLATE_LIST,{params});
}