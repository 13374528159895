import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from 'moment';
import {
    GridRowId,
  } from "@mui/x-data-grid";
import EmployeeMeetingsTable from "../Employees/View/Meetings/EmployeeMeetingsTable";
import { MEETING_TYPES, Employee } from "../../constants/constants";
import {
    yearType, meetingTypes
} from "../../services/employeeService";
import { selectAuthentication } from "../../features/Auth/authSlice";
import ViewMeetingNotesPopup from "../Employees/View/Meetings/ViewMeetingNotes";
import MeetingNotesFormPopup from "../Employees/View/Meetings/MeetingNotesForm";


const EmployeeMeetings: React.FC<{source: string}> = ({source}) => {
    const { user }: any = useSelector(selectAuthentication);
    const [year, setYear] = useState<number>(moment().year());
    // Creating a mutation object state for every meeting type which will help to rerender the respective meeting table only when needed.
    const [isMutation, setIsMutation] = useState<meetingTypes>(Object.keys(MEETING_TYPES).reduce((result, key) => {
      result[key  as keyof meetingTypes] = false;
      return result;
    }, {} as meetingTypes));

    const [isViewMeetingPopupOpen, setViewMeetingPopupOpen] = useState(false);
    const [isMeetingNotesPopupOpen, setMeetingNotesPopupOpen] = useState(false);
    const [meetingId, setMeetingId] = useState<GridRowId | null>(null);
    const [meetingType, setMeetingType] = useState<string>('one_on_one');

    /**
      * meeting notes form popup Open handler
    */
    const openMeetingNotesForm = (id: GridRowId, meetingType: string) => {
      setMeetingId(id);
      setMeetingType(meetingType);
      setMeetingNotesPopupOpen(!isMeetingNotesPopupOpen);
    }

    /**
     * View meeting click handler
    */
    const handleViewClick = (id: GridRowId) => {
      setMeetingId(id);
      setViewMeetingPopupOpen(!isViewMeetingPopupOpen);
    };
    
    /**
      * View meeting popup close handler
    */
    const handleViewMeetingClose = () => {
      setViewMeetingPopupOpen(false);
      setMeetingId(null);
    };

    /**
      * meeting notes form popup close handler
    */
    const handleMeetingNotesClose = () => {
      setMeetingNotesPopupOpen(false);
      setIsMutation((prevIsMutation: any) => {
        return {...prevIsMutation, [meetingType]: !isMutation[meetingType as keyof meetingTypes]}});
      setMeetingId(null);
    };

    /**
      * Handler function when year changes
    */
    const handleChangeYear = (year: yearType) => {
        setYear(year.value);
    }

    return (
        <React.Fragment>
          <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
            <div className="pageHeader px-3 py-2 my-1">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                <h1 className="fw-bold h4 my-2">Meetings</h1>
                </div>
              </div>
            </div>
            <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            {user && Object.keys(MEETING_TYPES).map(key => (
                <EmployeeMeetingsTable 
                    key={key}
                    employeeID={user.user.id} 
                    meetingType={key} 
                    meetingLabel={MEETING_TYPES[key as keyof typeof MEETING_TYPES]} 
                    year={year} 
                    onChangeYear={handleChangeYear}
                    onView={handleViewClick}
                    isMutation={isMutation}
                    onOpenMeetingNotes={openMeetingNotesForm}
                    loggedInID={user.user.id}
                    source={source}
                  />
            ))}

            {isViewMeetingPopupOpen && (
              <ViewMeetingNotesPopup
                isOpen={isViewMeetingPopupOpen}
                onClose={handleViewMeetingClose}
                meetingId={meetingId}
                source={source}
              />
            )}

            {isMeetingNotesPopupOpen && (
              <MeetingNotesFormPopup
                isOpen={isMeetingNotesPopupOpen}
                onClose={handleMeetingNotesClose}
                meetingId={meetingId}
                source={source}
              />
            )}
            </div>
            </div>

        </React.Fragment>
    );
};

export default EmployeeMeetings;
