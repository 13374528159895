// DeletePopup.tsx
import { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import constants from "../../../constants/constants";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import InputDateRangePicker from "../../../components/common/InputDateRangePicker";
import InputText from "../../../components/common/InputText";
import {
  getOrderNumberListApi
} from "../../../services/dealService";
import { exportFinanceExternalExpensesApi } from "../../../services/financeService";

interface ExportDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  exportType: string;
  text: string;
}

const ExportFinanceExternalExpensesData: FC<ExportDataPopupProps> = ({
  isOpen,
  onClose,
  label,
  text,
  exportType,
}) => {
  const [orderDateRange, setOrderDateRange] = useState<any>(null);
  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        // exportType
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  }; 

  const handleSubmit = (values: any) => {
    const transformedValues = {
      ...values,
      contract_id: values.contract_id?.id, 
      invoice_date: orderDateRange,
    };
    delete transformedValues.orderNumber;
    // console.log(transformedValues);
    //  return;
    exportFinanceExternalExpensesApi(transformedValues)
      .then((response) => {
        // Create a Blob object from the response data
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()}_external_procurement_expenses.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
        onClose();
      })
      .catch((error) => {
        if (error.response.status == " 404") {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`No records available for export.`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const orderDateFilter = (data: any) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(
        constants.dateFormats.databaseFormat
      );
      const endDate = moment(dataRange[1]).format(
        constants.dateFormats.databaseFormat
      );
      setOrderDateRange(`${startDate},${endDate}`);
    } else {
      setOrderDateRange("");
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          contract_id: null,
          category: "",          
          sub_category: "",
          vendor: "",
          invoice_date: null,
          min_amount: "",
          max_amount: "",
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            className="export-excel-modal"
            show={isOpen}
            onHide={onClose}
            centered
          >
            <Form className="" noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <button
                    type="button"
                   // disabled={docLoading}
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                <div className="fs-18 fw-bold mb-3 info-text">
                  Export to Excel
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <FormAsyncSelect
                      id="contract_id"
                      name="contract_id"
                      placeholder="All"
                      label="Project order No."
                      isAsync
                      isClearable
                      defaultFilter={{ exportType: "project" }}
                      getOptions={getOrderNumber}
                      value={values.contract_id}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputDateRangePicker
                      controlId="invoice_date"
                      label="Date Range"
                      placeholder="Date Range"
                      touched={false}
                      handleBlur={false}
                      handleChange={orderDateFilter}
                      handleClose={orderDateFilter}
                      errorsField={false}
                      value={values.invoice_date}
                      position="auto"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      controlId="category"
                      label="Category"
                      placeholder="Enter"
                      handleChange={handleChange}
                      value={values.category}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      controlId="sub_category"
                      label="Sub Category"
                      placeholder="Enter"
                      handleChange={handleChange}
                      value={values.sub_category}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      controlId="vendor"
                      label="Vendor"
                      placeholder="Enter Vendor Name"
                      handleChange={handleChange}
                      value={values.vendor}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputText
                      controlId="min_amount"
                      label="Min Amount"
                      placeholder="Enter Min amount"
                      handleChange={handleChange}
                      value={values.min_amount}
                    />
                  </div>
                  <div className="col-md-3">
                    <InputText
                      controlId="max_amount"
                      label="Max Amount"
                      placeholder="Enter Max amount"
                      handleChange={handleChange}
                      value={values.max_amount}
                    />
                  </div>                             
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                <button
                  className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <Button
                  type="submit"
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Export
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ExportFinanceExternalExpensesData;
