import React, { useState } from "react";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import deleteIconRed from "../../../assets/images/awesome-trash-red.svg";
import editIcon from "../../../assets/images/formbuilderedit.svg";
import deleteIcon from "../../../assets/images/formbuilderdelete.svg";
import addIcon from "../../../assets/images/subsectionadd.svg";
import { deleteSubSectionApiCall } from "../../../services/formbuilder/subsection.services";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DeletePopup from "../../../components/common/deletePopup";
import FieldContent from "./FieldContent";
import AddField from "../Field";
export default function SubsectionContent({
  keyVal,
  val,
  handleEditSubModel,
  getTitleName,
  setIsMutation,
  isMutation,
  conditionData
}) {
  const [deleteSubSectionPopup, setDeleteSubSectionPopup] = useState(false);
  const [subSectionId, setSubSectionId] = useState(0);
  const [fieldId, setFieldId] = useState(null);
  const [fieldSheetId, setFieldSheetId] = useState(null); //val.field_sheet_id
  const [fieldOrder, setFieldOrder] = useState(0);
  const [field_title, setFieldtitle] = useState("Add new Field");
  const [field_btn_title, setFieldBtntitle] = useState("Add  Field");
  const [showFieldModel, setShowFieldModel] = useState(null);

  const handleDeleteSubSection = () => {
    deleteSubSectionApiCall(subSectionId).then((response) => {
      toast.success(response.msg);
      setIsMutation(!isMutation);
      setDeleteSubSectionPopup(false);
      setSubSectionId(null);
    });
  };
  const handleCloseSubSectionDelete = () => {
    setDeleteSubSectionPopup(false);
    setSubSectionId(null);
  };

  const checkFieldHasCondition = (fieldcondition,id) => {
    const hasCondition = fieldcondition.some(entry => {
      return (
        entry.conditions.some(condition => condition.field.subsection_id === id) ||
        entry.actions.some(action => action.field.subsection_id === id)
      );
    });
    return hasCondition;
  }

  const handleDelete = (id) => {
    let  hasCondition = false;
    if(conditionData && conditionData.field_conditions && conditionData.field_conditions.length > 0){
      hasCondition = checkFieldHasCondition(conditionData.field_conditions,id);
    }

    if(hasCondition){
      toast.error("Sub Section's Field has condition, Please delete the condition first")
      return false;
    }
    setDeleteSubSectionPopup(true);
    setSubSectionId(id);
  };

  const handleFieldModalClose = () => {
    setShowFieldModel(false);
    setIsMutation(!isMutation);
  };

  const addFieldModelOpen = (sectionid,field_sheet_id, orderLength) => {
    setSubSectionId(sectionid);
    setFieldSheetId(field_sheet_id)
    setFieldId(null);
    setFieldOrder(orderLength == 0 ? "1" : orderLength + 1);
    setFieldtitle("Add new Field");
    setFieldBtntitle("Add Field");
    setShowFieldModel(true);
  };
   const editFieldModel = (subsection_id, field_id, field_sheet_id, orderLength) => {
       setSubSectionId(subsection_id);
      setFieldId(field_id);
      setFieldSheetId(val.field_sheet_id);
      setShowFieldModel(true);
      setFieldtitle("Edit Field");
      setFieldBtntitle("Edit Field");
     
      setFieldOrder(orderLength);
      
    };

    

  return (
    <>
      {deleteSubSectionPopup && (
        <DeletePopup
          isOpen={deleteSubSectionPopup}
          onClose={handleCloseSubSectionDelete}
          onDelete={handleDeleteSubSection}
          label="Delete Sub Section"
          text="Are you sure you want to delete this Sub Section?"
        />
      )}

      {showFieldModel && (
        <AddField
          showFieldModel={showFieldModel}
          handleFieldModalClose={handleFieldModalClose}
          subSectionId={subSectionId ? subSectionId : null}
          fieldSheetId={fieldSheetId}
          fieldId={fieldId}
          title={field_title}
          field_btn_title={field_btn_title}
          orderLength={fieldOrder}
          setIsMutation={setIsMutation}
          isMutation={isMutation}
        />
      )}
      <Accordion.Collapse eventKey={keyVal}>
       
        {val.subsections.length > 0 ? (
          <Card.Body>
            {val.subsections.length > 0 &&
              val.subsections.map((item, srKey) => {
                return (
                  <div className="sub_section_wraper subbox-border border-left-5">
                    <div className="">
                      <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className=" fs-16 fw-bold text-capitalize">
                          {item.title} {item.order}
                        </div>

                        <div>
                          <div className="otherpart">
                            <div className="section-action d-flex align-items-center">
                              <ul className="m-0 actions">
                                <li title="Add Field">
                                  <img
                                    src={addIcon}
                                    onClick={() =>
                                      addFieldModelOpen(
                                        item.id,
                                        val.field_sheet_id,
                                        item.fields.length
                                      )
                                    }
                                  />
                                </li>
                                <li
                                  title="Edit sub section"
                                  className=""
                                  onClick={() =>
                                    handleEditSubModel(
                                      item.id,
                                      val.id,
                                      val.subsections.length
                                    )
                                  }
                                >
                                  <img src={editIcon} />
                                </li>
                                <li
                                  title="Delete sub section "
                                  className=""
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <img src={deleteIcon} />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fieldSection">
                        <FieldContent
                          keyVal={srKey}
                          val={val.subsections[srKey]}
                          fieldSheetId={val.field_sheet_id}
                          editFieldModel={editFieldModel}
                          isMutation={isMutation}
                          setIsMutation={setIsMutation}
                          conditionData={conditionData}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </Card.Body>
        ) : (
          <Card.Body>No sub section added</Card.Body>
        )}
      </Accordion.Collapse>
    </>
  );
}
