import * as Yup from "yup";
import { ALPHA_NUMERIC_ALLOW, MAXIMUM_255_CHAR, MAXIMUM_30_CHAR, MINIMUM_3_CHAR } from "../../../validations/ValidationErrors";
import { ALPHANUMERIC_WITH_SPACE } from "../../../validations/regex";

const validationSchema = Yup.object({
  title: Yup.string()
  .trim()
    .required()    
    .min(3,MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .matches(ALPHANUMERIC_WITH_SPACE,ALPHA_NUMERIC_ALLOW)
    .label("Title")

    ,
    sub_title : Yup.string()
    .trim()      
      .min(3,MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .matches(ALPHANUMERIC_WITH_SPACE,ALPHA_NUMERIC_ALLOW)
      .label("Sub Title"),

      order : Yup.number().required().label("Order")

});



export { validationSchema };
