import { axios } from "../services/axios";
import {
  GET_COUNTRIES,
  EMPLOYEE_FILL_HMRC_FORM,
  EMPLOYEE_GET_HMRC_FORM,
  EMPLOYEE_DOWNLOAD_HMRC_FORM,
  DOWNLOAD_BLANK_HMRC_FORM,
  CORRECT_STUDENT_LOAN_LIST
} from "../constants/paths";

export interface EmployeeStatement{
  'another_job': string | null;
  'other_payments': string | null;
  'other_payments_since_apr6': string | null;
}

export interface StudentLoans{
  student_loan: string | null;
  apply_statements: string | null;
  correct_student_loan: string[] | null;
}

export type hmrcDto = {
  first_name: string;
  last_name: string;
  dob: Date | null;
  home_address: string;
  postal_code: string;
  country_id: countryList | null;
  gender: string;
  ni_number: string | null;
  start_date: Date | null;
  employee_statement: EmployeeStatement;
  student_loans:StudentLoans;
  declaration_full_name: string | null;
  declaration_date: Date | null;
  sign: string;
};

export type correctStudentLoanDTO = {
  name: string;
  key: string;
  tooltip?: string;
};

export interface countryList {
    id:string;
    name:string;
}


export const employeeStatementDependency = {
  another_job: ['other_payments', 'other_payments_since_apr6'],
  other_payments: ['other_payments_since_apr6'],
};

export const studentLoansDependency = {
  student_loan: ['apply_statements', 'correct_student_loan'],
  apply_statements: ['correct_student_loan'],
};

export const getCorrectStudentLoanList = (params:any|null = {}):Promise<any> =>{
  return axios.get(CORRECT_STUDENT_LOAN_LIST,{params});
};

export const getCountries = (params:any|null = {}):Promise<any> =>{
    return axios.get(GET_COUNTRIES,{params});
}
/**
 * Post HMRC details for an employee
 * @param {data: any (formdata) } params
 */
export const fillHmrcForm = (data: any): Promise<any> => {
  const transformData = {
    ...data,
    country_id: data?.country_id?.id,
  };
  
  return axios.post(EMPLOYEE_FILL_HMRC_FORM, transformData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};

/**
 * Get HMRC details by employee ID
 * @param {id: string } params
 */
export const getHmrcForm = (id?: string | number | undefined | null): Promise<any> => {
  return axios.get(id ? `${EMPLOYEE_GET_HMRC_FORM}/${id}` : EMPLOYEE_GET_HMRC_FORM);
};

/**
 * Download PDF containing HMRC data for an employee
 * @param {id: string } params
 * @returns asset
 */
export const downloadHmrcForm = (id: string | number): Promise<any> => {
  return axios.get(`${EMPLOYEE_DOWNLOAD_HMRC_FORM}/${id}`,{ responseType: 'arraybuffer' });
};

/**
 * Download Blank HMRC PDF
 * @param {id: string } params
 * @returns asset
 */
export const downloadBlankHmrcForm = (): Promise<any> => {
  return axios.get(DOWNLOAD_BLANK_HMRC_FORM,{ responseType: 'blob' });
};
