import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import CancelIcon from "@mui/icons-material/Cancel";
import CachedIcon from "@mui/icons-material/Cached";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import { ITypePropsReviewAndSubmit } from "./surveyInterface";
import { getProductsForSurveySubmit, saveSurveyProductApi } from "../../../services/survey/survey.service";
import { Formik } from "formik";
import AcceptRejectPopup from "./acceptRejectProduct";
import { toast } from "react-toastify";
import { VALID_QUANTITY } from "../../../validations/ValidationErrors";
import ChangeProductItem from "./changeProductItem";
import { surveyProductStatus } from "../../../constants/constants";
import PreviewSurveyPage from "./compareAndFinish/previewSurvey";
const CompareAndFinish: FC<ITypePropsReviewAndSubmit> = ({ survey_id,setActiveStep,activeStep,contract_id }) => {
  const [loading, setLoading] = useState(false);
  const [productItem, setProductItem] = useState<any>([]);
  const [popupShow, setPopupShow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showChangeProduct , setShowChangeProduct] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [type, setType] = useState("accept");
  const [comparePageSubmit, setComparePageSubmit] = useState(false);
  useEffect(() => {
    fetchProductItems(survey_id);
  }, [survey_id]);

  useEffect(() => {
    if(productItem.length > 0){
      let isDisabled = false;
      productItem.map((item:any)=>{
        if(item.status == surveyProductStatus.PENDING){
          isDisabled = true;
        }
      })
      setSubmitDisabled(isDisabled);
    }
  }
  ,[productItem]);

  const fetchProductItems = (survey_id: string) => {
    getProductsForSurveySubmit(survey_id)
      .then((res) => {
        setProductItem(res.data?.project?.contract_products);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleQuantityChange = (e: any, item: any, index: number) => {
    //change value of input
    const value = e.target.value;
    if(value <=0 || value=="" || value==null || value==undefined || value > 1000){
      toast.error(VALID_QUANTITY);
      return;      
    }
    console.log("valueeeeeeeee",value)
    let productArrValue = [...productItem];
    productArrValue[index].updated_qty = e.target.value;
    setProductItem(productArrValue);   
  };

  const handleProductAccept = (index: number, type: string) => {
    console.log("type====", index);
    let productArrValue = [...productItem];
    if (type === "accept") {
      //if quantity is updated then set status to 4
      if(productArrValue[index].updated_qty){
        productArrValue[index].status = 4; //4  is for accepted a product with updated quantity
      }else{

        productArrValue[index].status = 1; //1  is for approved a product
      }
      
    } else {
      productArrValue[index].status = 2; //1  is for approved a product
    }
    setProductItem(productArrValue);
  };

  const statusReset = (index: number) => {
    let productArrValue = [...productItem];
    productArrValue[index].status = 0; //0  is for pending a product
    setProductItem(productArrValue);
  }
  const showAccept = (index: number) => {
     const quentity = productItem[index]?.qty;
     if(quentity <=0 || quentity=="" || quentity==null || quentity==undefined || quentity > 1000){
      toast.error(VALID_QUANTITY);
      return;
     }

    setSelectedIndex(index);
    setType("accept");
    setPopupShow(true);
  };
  const showReject = (index: number) => {
    setSelectedIndex(index);
    setType("reject");
    setPopupShow(true);
  };
  const handleClosePopup = () => {
    setPopupShow(false);
  };
  const handleAcceptAll = () => {
    let productArrValue = [...productItem];
    productArrValue.map((item: any) => {
      if(item.status==surveyProductStatus.PENDING ){
        item.status =  surveyProductStatus.ACCEPTED
      }
    });
    setProductItem(productArrValue);
  }

  const closeChangeProduct = () => {
    setShowChangeProduct(false);
  }

  const ChangeOrAddProduct = () => {
    setType("add");
    setShowChangeProduct(true);
  }
  const handleChangeProduct = (index:number) => {
    setType("change");
    setShowChangeProduct(true);
    setSelectedIndex(index)
  }
  const handleSubmit = () => {
      if(productItem.length > 0){
        saveSurveyProductApi({products:productItem}).then((res)=>{
          console.log("res",res)
          toast.success("Survey Products Updated Successfully");
          setComparePageSubmit(true);
        }
        ).catch((error)=>{
          toast.error("Error in updating Survey Products");
        })

      }
  }

   // Function to handle input focus
   const handleFocus = (event:any) => {
    event.target.select();
  };
  const handlePrevious = () =>{
    setActiveStep(activeStep - 1)
  }

  return (
    <>
      {comparePageSubmit ?(
      <div className="max-width-900  mx-auto survery-middle-content">

        <PreviewSurveyPage
         survey_id={survey_id} 
        comparePageSubmit={comparePageSubmit}
        setComparePageSubmit={setComparePageSubmit}
        />
      </div> ) : 
      <div className="max-width-730  mx-auto survery-middle-content">
      <Row>
        <Col className="col-md-12 ">
          <div className="d-flex justify-content-between pb-3 border-bottom">
            <div>
              <h3 className="fs-20 fw-bold info-text mb-0">
                Review Product Item
              </h3>
            </div>
            <div className="d-flex ">
              <div className="fs-14 fw-bold me-4 cursor-pointer" onClick={handleAcceptAll} title="Accept All Item">
                <CheckCircleIcon className="text-green me-1" /> Accept All
              </div>
              <div className="fs-14 fw-bold cursor-pointer"  onClick={ChangeOrAddProduct} title="Add New Item">
                <AddCircleIcon className="text-primary  me-1" /> Add New Items
              </div>
            </div>
          </div>
        </Col>
        <Col className="col-md-12">
          {/* <pre>{JSON.stringify(productItem, null, 2)}</pre> */}

          {popupShow && (
            <AcceptRejectPopup
              showPopup={popupShow}
              handleClosePopup={handleClosePopup}
              type={type}
              handleProductAccept={handleProductAccept}
              index={selectedIndex}
            />
          )}

          {showChangeProduct && (
            <ChangeProductItem
              showChangeProduct={showChangeProduct}
              closeChangeProduct={closeChangeProduct}
              type={type}
              productsList={productItem}
              setProductItem={setProductItem}
              index={selectedIndex}
              contract_id={contract_id}

            />
          )  
          }

          <table className="table table-border table-survery">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Quantity</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {productItem.length > 0 ? (
                productItem.map((item: any, index: number) => {
                 if(item.status!=surveyProductStatus.REPLACED){
                  return (
                    <tr key={index}>
                      <td>
                        <DragHandleIcon className="" />
                      </td>
                      <td className="approvProdcutText">{item.product.name}</td>
                      <td>
                        <input
                          name={`myQuenty_${item.prduct_id}`}
                          id={`myQuenty_${item.prduct_id}`}
                          type="number"
                          className="form-control"
                          min="0"
                          disabled={item.status != surveyProductStatus.PENDING ? true : false}
                          onFocus={handleFocus} 
                          value={item.updated_qty ? item.updated_qty : item.qty }
                          onChange={(e) => handleQuantityChange(e, item, index)}
                        />
                      </td>
                      <td className="text-center survery-table-action ">
                       <div className="d-flex align-items-center">
                        {(item.status ==  surveyProductStatus.ACCEPTED || item.status== surveyProductStatus.NEW_ADDED || item.status ==surveyProductStatus.UPDATED ) && (
                          <>
                            <span className="approveItem d-flex align-items-center fs-11">
                              Approved
                              <span
                                className="cursor-pointer ms-2"
                                onClick={(e) => statusReset(index)}
                              >
                                <CloseIcon className="fs-13"  />
                              </span>{" "}
                            </span>
                          </>
                        )}

                        {item.status == surveyProductStatus.REJECTED  && (
                          <>
                            {" "}
                            <span className="rejectItem d-flex align-items-center fs-11">
                              Denied
                              <span
                                className="cursor-pointer ms-2"
                                onClick={(e) => statusReset(index)}
                              >
                                <CloseIcon className="fs-13"   />
                              </span>
                            </span>
                          </>
                        )}
                        {item.status == surveyProductStatus.PENDING && (
                          <>
                            <button
                              className="btn p-0"
                              onClick={(e) => showAccept(index)}
                              title="Accept Item" 
                            >
                              <CheckCircleIcon className="text-green" />
                            </button>
                            <button
                              className="btn p-0 mx-2"
                              onClick={(e) => showReject(index)}
                              title="Remove Item"
                            >
                              <CancelIcon className="text-danger"  />
                            </button>
                          </>
                        )}
                        <button className="btn p-0"  onClick={()=>{handleChangeProduct(index)}} title="Change  Item">
                          <CachedIcon className="text-primary" />
                        </button>
                        </div>
                      </td>
                    </tr>
                  );
                 }
                })
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No Products Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>

        <div className="d-flex justify-content-between mt-3 border-top pt-3 survery-bottom-button">
          <button className="btn btn-outline-info  me-3 fs-14 " onClick={handlePrevious}>
            <KeyboardArrowLeftIcon className="me-1 fs-18" />
            Previous Page
          </button>
          {btnLoading ? (
            <button disabled className="btn btn-primary text-white fs-14" > <Spinner animation="border" size="sm" />  Review & Submit</button>  
          ) : (
            <button type="submit" className={`btn btn-primary text-white fs-14 ${submitDisabled && 'cursor-pointer'}`} disabled={submitDisabled} onClick={handleSubmit}  >
            Review & Submit 
          </button>
          )
          }
        
         
        </div>
      </Row>
      </div>
     }
    </>
  );
};
export default CompareAndFinish;
