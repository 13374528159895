import react, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { DateRangeInterface } from "../Dashboard/dashboardInterface";
import { getOnboardCount } from "../../services/hrdashboard.service";
import CounterSkeleton from "../../components/common/Skeleton/Counter.skeleton";
type OnboardData =  {
    totalActiveEmployee?: number;
    employeeOnInduction? : number;
    totalProbationEmployee? : number
}
const OnboardingCounts: React.FC<DateRangeInterface> = ({ orderDateRange }) => {
  const [loading, setLoading] = useState(true);
  const [data,setData] = useState<OnboardData>({})
  useEffect(() => {

 const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };  }, [orderDateRange]);
  const fetchData = (signal: any) => {
    getOnboardCount(orderDateRange, signal).then((res)=>{
        setData(res.data)
    }).catch((err)=>{

    }).finally(()=>{
        setLoading(false)
    })
  };
  return (
    <Card className=" h-100">
     {loading ? <CounterSkeleton /> :<>
        <Card.Header className="bg-primary rounded-top fs-18 text-white fw-bold dashboard-card-header">
        Onboarding
      </Card.Header>
      <Card.Body>
         
        <ul className="dashboard-card-box-list">
          <li>
            Total Active Employees <span className="fw-bold">{data?.totalActiveEmployee ?? 'N/A'}</span>
          </li>
          <li>
            New Employee on Induction
            <span className="fw-bold">{data?.employeeOnInduction ?? 'N/A'}</span>
          </li>
          <li>
            Employees on Probation
            <span className="fw-bold">{data?.totalProbationEmployee ?? 'N/A'}</span>
          </li>
        </ul>
      </Card.Body>
      </>}
    </Card>
  );
};

export default OnboardingCounts;
