import { Formik, FieldArray, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import FormSelect from "../FormSelect";
import { getAllUsersApiCall } from "../../../services/authService";
import CustomButton from "../CustomButton";
import { assingTeamApi } from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { AddCircle, Category } from "@mui/icons-material";
import DeleteIcon from "../../../assets/images/delete.svg";
import { reverse } from "dns";

const addSchema = yup.object({  
entries: yup.array().of(
    yup.object().shape({
      category: yup.object().required('Category is required'),
      teamMember: yup.object().required('Team Member is required'),
    })
  ),
});

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  projectsData: string[];
  selectedTeamMembers: string[];
  setSelectedTeamMembers?: any;
  setCheckedMembers?: any;
  setIsMutation?: any;
  isMutation?: boolean;
}

interface ProjectOption {
  id: string;
  full_name: string;
}

interface FormValues {
  projectsData: string[];
  selectedTeamMembers: string[];
  project_owner: any;
  populate_all: boolean;
  entries: any;
  
  
}
const TeamAssignToProjects: React.FC<AddProps> = ({
  isOpen,
  onClose,
  projectsData,
  selectedTeamMembers,
  setSelectedTeamMembers,
  setCheckedMembers,
  setIsMutation,
  isMutation
}) => {
  const [projectOwnerOption, setProjectOwnerOption] = useState([]);
  const [projectOwnerList, setProjectOwnerList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const designationOptions = [
    { value: "Surveyor", key: "Surveyor"},
    { value: "Installation Engineer", key: "Installation Engineer"},
    { value: "Other", key: "Other"}
  ]

  
  useEffect(() => {
    fetchData().then(() => {
      // Call this function after fetchData completes
    });
  }, []);

  useEffect(() => {
    if (projectOwnerList.length) {
      updateProjectOwnerOptions();
    }
    
  }, [projectOwnerList,selectedTeamMembers]);
  
  const fetchData = async () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getAllUsersApiCall()
      .then((response) => {
          const modifiedOption = response.data.map((item: any) => ({
            key: item.id,
            value: `${item.full_name} (${item.employeeDetails.designation})`,
            employeeDetails: item.employeeDetails,
            full_name : item.full_name
          }));
          //console.log(modifiedOption);
          setProjectOwnerList(modifiedOption);
          setLoading(false);
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);
          onClose();
          reject(error);
        });
      });
    };
    
    const updateProjectOwnerOptions = () => {
      // Group employees by designation
      const groupedEmployees = projectOwnerList.reduce(
        (result: any, employee: any) => {
          const designation = [
            "Installation Engineer",
            "Project Manager",
          ].includes(employee.employeeDetails.designation)
            ? employee.employeeDetails.designation == "Project Manager"
              ? "Surveyor"
              : employee.employeeDetails.designation
            : "Other";
          // console.log(designation);

          // Create an array for the designation if it doesn't exist
          if (!result[designation]) {
            result[designation] = [];
          }

          // Add the employee to the corresponding designation array
          result[designation].push(employee);

          return result;
        },
      {}
      );
     // console.log(groupedEmployees["employee"], designationOptions);
      setProjectOwnerOption(groupedEmployees);

      // setFieldValue('')
    };

    const setUserInTheSelectedTeamMembers = (transformedArray: any) => {
      //projectOwnerList
      if(projectOwnerList.length){
        const updatedTeamMembers = transformedArray.map((item: any) => {
          const user = projectOwnerList.find((user: any) => user.key === item.user_id);
          return {
            user_id: item.user_id,
            user: user,
            category: item.category,
          };
        });
       
        //add only user_id in the setCheckedMembers
        const userids = updatedTeamMembers.map((item: any) => item.user_id);
        console.log("userids",userids)
        setCheckedMembers(userids);
        setSelectedTeamMembers(updatedTeamMembers);
      }
    }
    
    /**
     * Submit Method to call when user save button
     * @async
     * @param {FormValues} values
     * @returns {*}
    */
   const submitForm = async (values: FormValues, { setSubmitting }: any) => {
     const projectIds = projectsData?.map((item: any) => item.id);
     // Step 1: Map team member keys
      const keys = values.entries.map((item: { teamMember: { key: any; }; }) => item.teamMember.key);

     // Step 2: Use Set to remove duplicates
     const uniqueKeys: number[] = Array.from(new Set(keys));

    // Step 3: Check if lengths are different
    const isDuplicate: boolean = keys.length !== uniqueKeys.length;
    if(isDuplicate){
      toast('Duplicate team members are not allowed', { type: toast.TYPE.ERROR});
      return;
    }
    
     const transformedArray = values.entries.map((item:any) => ({      
       user_id: item.teamMember.key,
       category : item.category.key,    
      }));

    setBtnLoading(true);
    const formData: any = {
      userids: transformedArray,
      //  project_id: projectIds[0],
    };
  
    if(!projectIds[0]){
      setUserInTheSelectedTeamMembers(transformedArray);
     
      onClose();
      return 
    }
    assingTeamApi(formData, projectIds[0]).then((response)=>{
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      //set reverse of setIsMutation
         
      onClose()
      
    }).catch((error)=>{
      setBtnLoading(false);
      
      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        toast(`Error: ${error.response.data.message}`, { type: toast.TYPE.ERROR });
      } else if (error.request) {
        // The request was made but no response was received
        toast("No response from the server", { type: toast.TYPE.ERROR });
      } else {
        // Something happened in setting up the request that triggered an Error
        toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
      }
    })
    return;
  };

  const transformToInitialValues = () => {
    const updatedValue:any = {
      entries: selectedTeamMembers.map((item:any)=> ({
        category: {
          //value: item.user.employeeDetails.designation,
          value: item.category ?? (['Installation Engineer','Project Manager'].includes(item.user.employeeDetails.designation) ? item.user.employeeDetails.designation == "Project Manager"
          ? "Surveyor"
          : item.user.employeeDetails.designation : "Other"),
          key: item.category ?? (['Installation Engineer','Project Manager'].includes(item.user.employeeDetails.designation) ? item.user.employeeDetails.designation == "Project Manager"
          ? "Surveyor"
          : item.user.employeeDetails.designation : "Other").toLowerCase().replace(/\s/g, '_')
        },
        teamMember: {
          key: item.user_id,
          value: `${item.user.full_name} (${item.user.employeeDetails.designation})`,
          name : item.user.full_name,
        }
      }))
    };
   // console.log(updatedValue.entries);
    if (!Array.isArray(updatedValue.entries) || updatedValue.entries.length == 0){
      updatedValue.entries.push({
        category: "",
        teamMember: "",

      });
    }
    
    return updatedValue;
  };
  const initialValues = transformToInitialValues();
  console.log(initialValues);
  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal project-assign-modal" show={isOpen} onHide={onClose} centered>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative project-assign-modal pb-0">                 
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">Assign Resource</h4>
                  <div className="row">
                    <div className="col-md">                      
                      <FieldArray
                        name="entries"
                        render={(arrayHelpers) => (
                          <div>
                            {values.entries.map((entry: any, index: any) => (
                              <Row className="mb-4" key={index}>
                                <Col>
                                  <FormSelect
                                    placeholder="Select Category"
                                    className="mb-0 min-h-28"
                                    name={`entries[${index}].category`}
                                    options={designationOptions}
                                    getOptionLabel={(option: any) =>
                                      option.value
                                    }
                                    getOptionValue={(option: any) => option.key}
                                    onChange={(name: string, value: string) => {
                                      setFieldValue(name, value);
                                      setFieldValue(
                                        `entries[${index}].teamMember`,
                                        ""
                                      );
                                    }}
                                    value={values.entries[index].category}
                                  />
                                  <ErrorMessage
                                    name={`entries[${index}].category`}
                                    component="div"
                                    className="text-danger fs-14"
                                  />
                                </Col>
                                <Col>
                                  <FormSelect
                                    placeholder="Select Team Member"
                                    className="mb-0 min-h-28"
                                    name={`entries[${index}].teamMember`}
                                    options={
                                      projectOwnerOption[
                                        values.entries[index].category.value
                                      ]
                                    }
                                    getOptionLabel={(option: any) =>
                                      option.value
                                    }
                                    getOptionValue={(option: any) => option.key}
                                    onChange={(name: string, value: string) => {
                                      console.log("val", value)
                                      setFieldValue(name, value);
                                    }}
                                    value={values.entries[index].teamMember}
                                    // error={errors.entries[index].category}
                                    // touched={touched.project_owner}
                                  />
                                  <ErrorMessage
                                    name={`entries[${index}].teamMember`}
                                    component="div"
                                    className="text-danger fs-14"
                                  />
                                </Col>
                                <Col className="col-auto">
                                  {index > 0 ? (
                                    <button
                                      className="fw-bold bg-white border-0 p-0 pt-2"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <img src={DeleteIcon} className="w-24"/>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="bg-white border-0 p-0 pt-2"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          category: "",
                                          teamMember: "",
                                        })
                                      }
                                    >
                                      <AddCircle className="text-primary fs-20 " />
                                    </button>
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    type="button"
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-60 mt-2"
                    variant="primary"
                  >
                    Assign
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default TeamAssignToProjects;
