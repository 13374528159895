import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DocumentNeedFromEmployee from "../View/Documents/NeedFromEmployeeList";
import DocumentSentsToEmployee from "../View/Documents/SentToEmployeeList";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../features/Auth/authSlice";


const MyDocuments: React.FC<Record<string, number>> = () => {  
  const {user }:any = useSelector(selectAuthentication);

  const  userName = user.user.full_name;
  const employeeID = user.user.id;
  console.log(userName,employeeID);
  return (
    
    <React.Fragment>   
      <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">My Documents</h1>
              </div>              
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
          <Row>
          <Col>
          <DocumentSentsToEmployee employeeID = {employeeID} mainTitle="Send By HR" uType="doc"/>
          </Col>
        </Row>
        <Row>
          <Col>
            <DocumentNeedFromEmployee employeeID = {employeeID} mainTitle="Needed By HR" uType="doc"/>
          </Col>
        </Row>
          </div>
        </div>   
        
      
    </React.Fragment>
  );
};

export default MyDocuments;
