import React, { useState } from "react";
import { FC } from "react";
import { Card, Col, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import { ITypeProps } from "./surveyInterface";
import CommonNotes from "./commonNotes";
import Loader from "../../../components/common/Loader";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ViewIcon from "@mui/icons-material/Visibility";

import {
  FILE_LIMIT_10,
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
} from "../../../validations/ValidationErrors";
import { toast } from "react-toastify";
import { uploadImagesApi } from "../../../services/survey/survey.service";
import ImagesPreview from "./imagesPreview";
import CanvasSignature from "./signatureCanvas";

const SignOff: FC<ITypeProps> = ({
  field,
  index,
  fieldIndex,
  subIndex,
  templateData,
  setTemplateData,
  errors,
  values,
  setFieldValue,
  handleBlur,
  touched,
  survey_id,
  setFieldError,
}) => {
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageSet, setImageSet] = useState<any>([]);
  const [signData, setSignData] = useState<any>("");
  const handleViewImages = (imageSet: any) => {
    setImageSet(imageSet);
    setShowImageModal(true);
  };
  const hideImageModel = () => {
    setShowImageModal(false);
    setImageSet([]);
  };

  const handleInputChangeSelect = (
    value: string,
    index: number,
    subIndex: number,
    fieldIndex: number,
    name: string,
    setFieldValue: any
  ) => {
    setFieldValue(name, value);
    let fieldSheetTextTemp = [...templateData];
    fieldSheetTextTemp[index].subsections[subIndex].fields[fieldIndex].value =
      value;
    
    setTemplateData(fieldSheetTextTemp);
  };

  const handleCustomerSign = (
    value: string,
    index: number,
    subIndex: number,
    fieldIndex: number
  ) => {
    let fieldSheetTextTemp = [...templateData];
    fieldSheetTextTemp[index].subsections[subIndex].fields[
      fieldIndex
    ].signature = value;
    setTemplateData(fieldSheetTextTemp);
  };
  return (
    <div>
      {fileUploadLoading && <Loader isLoading={fileUploadLoading} />}
      {showImageModal && (
        <ImagesPreview
          show={showImageModal}
          onHide={hideImageModel}
          images={imageSet}
        />
      )}
      <Card className="p-3 w-100 pageContentInner mb-3">
        <div className="d-flex flex-column h-100 rounded-0 ">
          <Row className="d-flex align-items-center">
            <Col className="col-md-9 ">
               <p className="fs-14 mb-2">
                {" "}
                {field.title} {field.require && "*"}
              </p>
              <InputText
                controlId={field.name}
                label={field.label}
                placeholder={field.label}
                touched={touched[field.name]}
                handleBlur={handleBlur}
                handleChange={(e: any) =>
                  handleInputChangeSelect(
                    e.target.value,
                    index,
                    subIndex,
                    fieldIndex,
                    field.name,
                    setFieldValue
                  )
                }
                errorsField={errors[field.name]}
                value={values[field.name]}
              />
            </Col>
            <Col className="col-md-3 text-end">
              <div className="mb-2 ">
                <CommonNotes
                  field={field}
                  index={index}
                  fieldIndex={fieldIndex}
                  subIndex={subIndex}
                  touched={touched}
                  errors={errors}
                  values={values}
                  templateData={templateData}
                  setTemplateData={setTemplateData}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                />
                <label className="browse-cover ms-2 survey-browse">
                  <span
                    className="btn btn-outline-info fw-bold fs-11 text-no-wrap"
                    onClick={handleViewImages}
                  >
                    <FileUploadIcon /> Add Signature
                  </span>
                  
                </label>
                {showImageModal && (
                  <CanvasSignature
                    showSign={showImageModal}
                    handleSignModalClose={hideImageModel}
                    signData={signData}
                    setSignData={setSignData}
                    survey_id={survey_id}
                    handleCustomerSign={handleCustomerSign}
                    index={index}
                    subIndex={subIndex}
                    fieldIndex={fieldIndex}
                    field={field}
                    
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default SignOff;
