import react, { FC, useEffect, useState } from "react";
import { Card, Row, Table } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { getExpireDocumentList } from "../../services/hrdashboard.service";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../components/common/Skeleton/Table.skeleton";
import { getLocalDateFormat } from "../../utility/common";

const ExpireDocuments = () => {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, []);
  const fetchData = (signal: any) => {
    getExpireDocumentList(signal)
      .then((res) => {
        setListData(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRedirect = () => {
    navigate("/hr-dashboard/expiry-doc");
  };
  return (
    <>
      <Card className="p-3 w-100 h-100 pageContentInner rounded-2">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="">
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="fs-18 info-text fw-bold">Document Expire List</h2>
              {listData.length > 3 && (
                <p onClick={handleRedirect} className="link-text fw-bold">
                  {" "}
                  View all <MdKeyboardArrowRight />
                </p>
              )}
            </div>
            <div>
              {listData.length > 0 ? (
                <Table className="p-3 dash-custom-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Document</th>
                      <th>Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listData.length > 0 &&
                      listData.map((item: any, index: number) => {
                        if (index <= 5) {
                          return (
                            <>
                              <tr>
                                <td>{item?.employee_docs?.full_name}</td>
                                <td>{item?.doc_name}</td>
                                <td>{getLocalDateFormat(item?.expiry_date)}</td>
                              </tr>
                            </>
                          );
                        }
                      })}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center">No Expiry Documents Found</div>
              )}
            </div>
          </div>
        )}
      </Card>
    </>
  );
};
export default ExpireDocuments;
