import react, { useState, useEffect, useRef } from "react";
import React, { FC } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import {
  ITypePropsAcceptRejectPopup,
  ITypePropsCanvas,
  ITypePropsChangeProductItem,
} from "./surveyInterface";
import CustomButton from "../../../components/common/CustomButton";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";
import { ButtonGroup } from "@mui/material";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import { getProductListAsync } from "../../../services/survey/survey.service";
import { Formik } from "formik";
import * as Yup from "yup";
import InputText from "../../../components/common/InputText";
import { surveyProductStatus } from "../../../constants/constants";
const ChangeProductItem: FC<ITypePropsChangeProductItem> = ({
  showChangeProduct,
  closeChangeProduct,
  productsList,
  type,
  setProductItem,
  index=0,
  contract_id,
  qtyUpdate=false,
  fromComparativeStudy=false,
}) => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [changedItem, setChangedItem] = useState<any>(null);

  useEffect(() => {
    setLoading(false);
    if(type=='change'){
      setChangedItem(productsList[index]);
    }
  }, []);

  const handleSubmit = (values: any) => {
    console.log("values",values)
    // handleProductAccept(index, type);
    // handleClosePopup();
    console.log("productsList", productsList);
      if(type=='add'){
        if(productsList.length>0){
        let newProduct = JSON.parse(JSON.stringify(productsList[0]));
        newProduct.id = null;
        newProduct.product_id = values.product_id.id;
        newProduct.status = fromComparativeStudy ? surveyProductStatus.ADDED_FROM_COMPARATIVE: surveyProductStatus.NEW_ADDED //5  is for newly added
        newProduct.price = values.product_id.price;    
        newProduct.amount = values.product_id.price; 
        newProduct.qty = values.quantity;  
        newProduct.updated_qty = values.quantity;
        newProduct.product.id = values.product_id.id;
        newProduct.product.name = values.product_id.name;
         //newProduct this object to productItem in last index
        let productArrValue = [...productsList];
        productArrValue.push(newProduct); 
        setProductItem(productArrValue); 
      }else{

        //productsList is empty then set new product
        let newProduct  = {
          id: null,
          product_id: values.product_id.id,
          status: surveyProductStatus.NEW_ADDED,
          price: values.product_id.price,
          amount: values.product_id.price,
          qty: values.quantity,
          updated_qty: values.quantity,
          contract_id : contract_id,
          product: {
            id: values.product_id.id,
            name: values.product_id.name           
          },
        };
        let productArrValue = [...productsList];
        productArrValue.push(newProduct);
        setProductItem(productArrValue);


      } 
      }else{
        let productArrValue = [...productsList];  
        if(qtyUpdate){
         
          productArrValue[index].updated_qty = values.quantity;
          productArrValue[index].status = surveyProductStatus.UPDATED
          
        }else{
        //update product item on index with status of 5
         productArrValue[index].status = surveyProductStatus.REPLACED; // 3 is for replaced a product
        //update productItem
       

        setProductItem(productArrValue);
        //new product
        let newProduct = JSON.parse(JSON.stringify(productsList[0]));
        newProduct.id = null;
        newProduct.product_id = values.product_id.id;
        newProduct.status = fromComparativeStudy?surveyProductStatus.REPLACED_FROM_COMPARATIVE : surveyProductStatus.NEW_ADDED //5  is for newly added
        newProduct.price = values.product_id.price;
        newProduct.amount = values.product_id.price;
        newProduct.qty = values.quantity;
        newProduct.updated_qty = values.quantity;
        newProduct.product.id = values.product_id.id;
        newProduct.product.name = values.product_id.name;
        //newProduct this object to productItem in last index
        productArrValue.splice(index,0,newProduct);
        setProductItem(productArrValue);
      }

      }
   
    closeChangeProduct();
    

  };

  const validationSchema  = Yup.object().shape({
    product_id: Yup.object().required().label("Product").typeError("Product") ,  
    quantity: Yup.number().required().min(1).max(1000).label("Quantity").typeError("Quantity"),
  });

  const initialValues = {
     quantity: 1,
     //set initial product_id set 
     product_id:  qtyUpdate ? productsList && productsList.length > 0 ? productsList[index].product : null :null,
  };
  const defaultFilter  = () =>{   
   const excludeProduct =   productsList && productsList.length > 0
    ? productsList.map((item: any) => {
        if(item.status!=surveyProductStatus.REPLACED && item.status!=surveyProductStatus.REJECTED){
          return item.product_id;
        }
      }
    ) : [];
    return {
      excludeProduct: excludeProduct,
    };

  }

  const getProductsList = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,        
      };
      //console.log(requestParam);
      getProductListAsync(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };


  return (
    <React.Fragment>
      <Modal
        show={showChangeProduct}
        onHide={closeChangeProduct}
        centered
        className="modal-middle-size"
      >
        <Modal.Header closeButton className="border-0 mb-0 pb-0">
          <Modal.Title className="fs-24 fw-bold info-text ">{type=='add'?'Add Product Item':'Change Product Item'}</Modal.Title>
      
        </Modal.Header>
        <Modal.Body>
        <p className="fs-14">
            {type=='add'?'You Are Add The Product Item On Behalf Of Survey.':'You Are Change The Product Item On Behalf Of Survey.'}
          </p>
 
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              handleSubmit(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
          
            <Form className="text-start" noValidate onSubmit={handleSubmit}>
           
              {changedItem && type=='change' && (
               <div className="bg-primary p-3 my-2 mb-3 border-1 bg-replaceBackground">
                <b>Replace Item</b>
                  <p className="mb-0">{changedItem.product.name}</p>
               </div>
              )}
             <Row>
                              <Col className="col-md-6 col-xl-6">
                                <FormAsyncSelect
                                  id="productSelect"
                                  name="product_id"
                                  label="Product Name"
                                  isAsync
                                  isClearable
                                  getOptions={getProductsList}
                                  value={values.product_id}
                                  onChange={(name: any, value: any) => {
                                    qtyUpdate=false;
                                    setFieldValue(name, value);
                                     
                                  }}
                                  error={errors.product_id}
                                  touched={touched.product_id}
                                  defaultFilter={defaultFilter()}

                                />
                              </Col>
                              <Col className="col-md-6 col-xl-6">
                                        <InputText
                                        controlId="quantity"
                                        label="Quantity"
                                        placeholder="Enter Quantity"
                                        touched={touched.quantity}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.quantity}
                                        value={values.quantity}
                                        />
                                </Col>
            </Row> 
            <div className="d-flex justify-content-end">
              <button
                className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                onClick={closeChangeProduct}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`fw-semibold fs-13 text-white mw-90 mt-2 btn btn-primary`}
                 
               >
                {type=='add'?'Add':'Replace'}
              </button>
            </div>
          </Form>
          )}
          </Formik>
        </Modal.Body>
      </Modal>
      {loading && <Loader isLoading={loading} />}
    </React.Fragment>
  );
};
export default ChangeProductItem;
