import React, { memo, useEffect, useState } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { getTopInstallerData } from "../../../services/dashboardMaster.service";
import TableSkeleton from "../../../components/common/Skeleton/Table.skeleton";
const TopInstaller = () => {
  const [loading, setLoading] = useState(true);
  const [topInstallerData, setTopInstallerData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getTopInstallerData()
      .then((res) => {
        setTopInstallerData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Row>
      <Col>
        <Card className="py-3 px-2 border-0 ">
          {loading ? (
            <TableSkeleton />
          ) : (
            <>
              {" "}
              <h5 className="fs-18 info-text fw-bold mb-3">Top 5 Installers</h5>
              {topInstallerData.length > 0 ? (
                <div className="max-heigth-300 w-100">
                  <Table responsive className="dash-custom-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Assigned Installations</th>
                        <th>Completed on Time</th>
                      </tr>
                    </thead>

                    {topInstallerData.map((data: any, index: number) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>{data.full_name}</td>
                            <td>{data.employeeDetails.assign_installation}</td>
                            <td>
                              {
                                data.employeeDetails
                                  .installations_completed_on_time
                              }
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </Table>
                </div>
              ) : (
                <div className="text-center">No Data Found</div>
              )}
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default memo(TopInstaller);
