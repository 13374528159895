import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { deleteDealApi } from "../../services/dealService";
import CustomButton from "../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
} from "../../validations/ValidationErrors";
import InputTextArea from "../../components/common/InputTextArea";

interface IDeletePoup {
  deleteRemark: any;
}

interface AddProps {
  isOpen: boolean;
  deletedId: any;
  onClose: () => void;
  deletedData?: IDeletePoup | null;
}

interface FormValues {
  deleteRemarks: string;
}
const DeleteDealPopup: React.FC<AddProps> = ({
  isOpen,
  onClose,
  deletedData,
  deletedId,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const addSchema = yup.object({
    deleteRemarks: yup.string().min(3).required().label("Archive Remark"),
  });

  const initialValues = {
    deleteRemarks: "",
  };

  //  const id  = deletedData && deletedData.id ? deletedData.id :""
  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setBtnLoading(true);
    const formData: any = {
      ...values,
    };
   // console.log(formData, deletedId);
    deleteDealApi(deletedId, formData?.deleteRemarks)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        onClose();
        setBtnLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setBtnLoading(false);
      })
      .finally(() => {
        setLoading(false);
        setBtnLoading(false);
      });
    return;
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal" show={isOpen} onHide={onClose}>
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <div className="delete-title">Archive Deal</div>
                  <div className="sure-delete">
                    Are you sure you want to archive this deal?
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <InputTextArea
                        controlId="deleteRemarks"
                        label="Reason For Archiving"
                        placeholder="Enter Reason for archiving"
                        touched={touched.deleteRemarks}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.deleteRemarks}
                        value={values.deleteRemarks}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Submit
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default DeleteDealPopup;
