import React, { useEffect, useState } from "react";
import { Card, Col, Row, Image, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import InputText from "../../../components/common/InputText";
import { updateWarehouseSchema } from "./validation";
import { WarehouseData } from "./warehouse.type";
import {
  getWarehouseDetail,
  updateWarehouseApi,
  uploadWarehouseImageApi,
} from "../../../services/warehouseService";
import CustomButton from "../../../components/common/CustomButton";
import FormSelect from "../../../components/common/FormSelect";
import Loader from "../../../components/common/Loader";
import ShelfComponent from './shelves';
import { getAllUsersApiCall } from "../../../services/authService";
import FileUploader from "../../../components/common/FileUploader";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";

//const managerData1 = [{ key: "6", value: "Super Admin" }];

interface ProjectOption {
  id:string;
  full_name:string;
}

export const AddWarehouse = () => {
  const [shelfNumbers, setShelfNumbers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [WarehouseData, setWarehouseData] = useState<any>({});
  const [managerData, setManagerData] = useState<any>({});
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ];
  const maxFileSizeMB = 1;

  useEffect(() => {
    getAllUsersApiCall().then((response)=>{
      const  modifiyOption = response.data.map((item : ProjectOption) => ({ key: item.id, value: item.full_name }));
      setManagerData(modifiyOption);
      fetchWarehouseDetail().then(() => {
        setLoading(false);
      });

    }).catch((error)=>{
      setLoading(false)

    })
    
  }, []);

  const handleCancel = () => {
    navigate(-1);
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: WarehouseData, { setSubmitting }: any) => {
    const formData = {
      ...values,
      shelves: shelfNumbers,
    };

    setBtnLoading(true);
    setLoading(true);
    //if (id) {
    updateWarehouseApi(formData)
      .then((response: any) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        navigate("/warehouse");
        setBtnLoading(false);
        setLoading(false);
      })
      .catch((error: any) => {
        setBtnLoading(false);
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      });
    //}
  };

  const fetchWarehouseDetail = async () => {
    return new Promise((resolve, reject) => {
      getWarehouseDetail().then((response) => {
        const __whData = response.data.warehouse;
        if (__whData) {
          if(response?.data?.warehousePhoto){
            setImagePreview(response?.data?.warehousePhoto);
          }
          setWarehouseData(__whData);
        }

        if (response.data.warehouse?.warehouseShelves) {
          setShelfNumbers(response.data.warehouse.warehouseShelves);
        }
        return resolve(true);
      });
    });
  };

  const uploadWarehouseImage = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    //console.log("file", imageFile)

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setImageLoading(true);
      if (imageFile) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImagePreview(reader.result);
        };

        reader.readAsDataURL(imageFile);
      } else {
        setImagePreview(null);
      }

      // Create FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append("image", imageFile);

      uploadWarehouseImageApi(formData)
        .then((response) => {
          setImageLoading(false);
          setFieldValue("warehouse_photo", response.data);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };
  const initialValues = {
    warehouse_name: WarehouseData?.warehouse_name,
    location_address: WarehouseData?.location_address,
    shipping_address: WarehouseData?.shipping_address,
    user_id: WarehouseData?.user_id
      ? managerData.find((item:any) => item.key == WarehouseData?.user_id)
      : managerData[0],
    warehousePhoto: WarehouseData?.warehousePhoto
      ? WarehouseData?.warehousePhoto
      : "",
    warehouse_photo: WarehouseData?.warehouse_photo
      ? WarehouseData?.warehouse_photo
      : "",
    shelf_number: "",
  };
  const handleShelve = (
    sleveValue: any,
    sleveErrors: any,
    setFieldValue: any
  ) => {    
    //  shelvesArrr.push(sleveValue);
    if (sleveValue.trim() !== "" && !sleveErrors) {
      const exists: boolean = shelfNumbers.some((obj:any) => obj.shelf_number === sleveValue.trim());
      // Check file type
      if (exists) {
        toast(`${sleveValue} is already added.`, {
          type: toast.TYPE.ERROR,
        });
        return;
      }
      const shelfNumberVal = { shelf_number: sleveValue.trim() };
      setShelfNumbers((prevShelfNumbers: any) => [
        ...prevShelfNumbers,
        shelfNumberVal,
      ]);
      // Clear the input value
      setFieldValue("shelf_number", "");
    }
  };

  const updateAddress = (e: any, formData: any, setFieldValue: any) => {
    if (e.target.checked) {
      setFieldValue("shipping_address", formData.location_address);
    }
  };

  const deleteShelf = (shelfObj: any, shelfIndex: number) => {    
    setShelfNumbers((prevShelfNumbers: any) => {
      const updatedShelfNumbers = [...prevShelfNumbers];
      updatedShelfNumbers.splice(shelfIndex, 1);
      return updatedShelfNumbers;
    });
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {!initialValues.warehouse_name ? "Create" : "Update"}{" "}
                  Warehouse
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={updateWarehouseSchema}
              initialValues={initialValues}
              onSubmit={(values: WarehouseData, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-2">
                                <div className="mb-3 custom-from-group">
                                  <div className="position-relative ">
                                  <FileUploader uploadProfileImage={(e) => uploadWarehouseImage(e, setFieldValue)} imageLoading={imageLoading} imagePreview={imagePreview} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <InputText
                                  controlId="warehouse_name"
                                  label="Warehouse Name"
                                  placeholder="Enter warehouse name"
                                  touched={touched.warehouse_name}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.warehouse_name}
                                  value={values.warehouse_name}
                                />
                                <InputText
                                  controlId="location_address"
                                  label="Location Address"
                                  placeholder="Enter Location address"
                                  touched={touched.location_address}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.location_address}
                                  value={values.location_address}
                                />
                              </div>

                              <div className="col-md-4">
                                <FormSelect
                                  placeholder="Select"
                                  label="Manager"
                                  name="user_id"
                                  options={managerData}
                                  getOptionLabel={(option: any) => option.value}
                                  getOptionValue={(option: any) => option.key}
                                  onChange={(name: string, value: string) => {
                                    setFieldValue(name, value);
                                  }}
                                  value={values.user_id}
                                  error={errors.user_id}
                                  touched={touched.user_id}
                                />
                                <InputText
                                  controlId="shipping_address"
                                  label="Shipping Address"
                                  placeholder="Enter shipping address"
                                  touched={touched.shipping_address}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.shipping_address}
                                  value={values.shipping_address}
                                />
                                <div className="">
                                  <Form.Check
                                    className="d-inline-block me-4"
                                    type="checkbox"
                                    id="check1"
                                  >
                                    <Form.Check.Input
                                      type="checkbox"
                                      onClick={(e) =>
                                        updateAddress(e, values, setFieldValue)
                                      }
                                    />
                                    <Form.Check.Label className="fs-14">
                                      Same as location address
                                    </Form.Check.Label>
                                  </Form.Check>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Card>
                    <ShelfComponent
                      shelfNumbers={shelfNumbers}
                      deleteShelf={deleteShelf}
                      shelfNumber={values.shelf_number}
                      touched={touched.shelf_number}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.shelf_number}
                      handleShelve={() =>
                        handleShelve(values.shelf_number, errors.shelf_number, setFieldValue)
                      }
                    />                   

                    <div className="text-end my-3">
                      <CustomButtonCancel label="Cancel" />                      
                      <CustomButton
                        type="submit"
                        loading={btnLoading}
                        className="fw-semibold fs-13 text-white mw-60 mt-2 btn btn-primary"
                        variant="primary"
                      >
                        Submit
                      </CustomButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
