import { Route, Routes } from 'react-router-dom';
import { checkAbility } from '../../../utility/common';
import { PERMISSION_ACCESS, PERMISSION_MODULE } from '../../../constants/permissionUser';
import { ViewInvoice } from './View';
import FinanceInvoiceList from './index';
import { AddNewInvoice } from './Add';


export const FinanceInvoices = () => {
    return (
        <Routes>
            {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.FINANCE_INVOCIES) && (
                <Route path="/" element={<FinanceInvoiceList />} />
            )}
                   
            {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.FINANCE_INVOCIES) && (
                <Route path="/view/:id" element={<ViewInvoice />} />
            )} 

            {checkAbility(PERMISSION_ACCESS.ADD, PERMISSION_MODULE.FINANCE_INVOCIES) && (
                <Route path="/add" element={<AddNewInvoice />} />
            )}  

            {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.FINANCE_INVOCIES) && (
                <Route path="/edit/:id" element={<AddNewInvoice />} />
            )}                  
        </Routes>
    )
};