import {  useState } from "react";
import React from "react";
import Loader from "../../../components/common/Loader";
import projectDummy from "../../../assets/images/project-dummy-icon.png";
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { getFirstLetters } from "../../../utility/common";
interface AddProps {
  id: any;
  projectsData: any;
}
const ProjectBasicDetail: React.FC<AddProps> = ({ id, projectsData}) => {
  const [documentUploadPopup, setDocumentUploadPopup] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(false);

 
  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
        <div className="media">
          <div className="project-profile me-0"> 
          <span className="fs-3 green h-75 m-0 me-2 p-0 rounded-5 ticket-user-tag w-75 pt-1">
            {getFirstLetters(
              projectsData?.name
            )}
          </span>               
            {/* <img className="" src={projectDummy} alt="Generic placeholder image" /> */}
          </div>
          <div className="media-body">
            <label className="fs-16 fw-bold">{projectsData?.name}</label>
            <div className="fs-14 control-label">{projectsData?.address}</div>
          </div>
        </div>
        <hr className="border-light"/>
        <div className="mb-1"><PhoneIcon className="me-2 text-primary fs-15"/>{projectsData.phone}</div>
        <div className="mb-0"><AlternateEmailIcon className="me-2 text-primary fs-15"/>{projectsData.email ? projectsData.email : "-"}</div>
        <hr className="border-light"/>
        <div className="d-flex fs-14 mb-2 justify-content-between">Contract Type <span className="fw-bold ">{projectsData.contract_type}</span></div>
        <div className="d-flex fs-14 mb-2 justify-content-between">Home Assessor <span className="fw-bold ">
          { projectsData?.sales_rep_id ? projectsData?.sales_rep?.full_name : "-"}</span></div>
        </>
      )}
    </>
  );
};
export default ProjectBasicDetail;
