// DeletePopup.tsx
import React, { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FormAsyncSelect from "../../components/common/AsyncSelect/FormAsyncSelect";
import {
  exportDealListApi,
  getOrderNumberListApi,
  getProductTypeList,
} from "../../services/dealService";
import { Formik } from "formik";
import InputText from "../../components/common/InputText";
import FormSelect from "../../components/common/FormSelect";
import constants, {
  dealContractType,
  dealStatus,
  departmentCheck,
  departmentOptions,
  designationOptions,
  employeeTypeOptions,
  employmentStatusOptions,
  projectStatus,
  statusOptions,
} from "../../constants/constants";
import { getPostalCodes } from "../../services/postalcode.service";
import InputDateRangePicker from "../../components/common/InputDateRangePicker";
import moment from "moment";
import { getAllManagerList } from "../../services/authService";
import { toast } from "react-toastify";
import { exportEmployeeListApi } from "../../services/employeeService";

interface ExportDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  exportType: string;
  text: string;
}

const ExportData: FC<ExportDataPopupProps> = ({
  isOpen,
  onClose,
  label,
  text,
  exportType,
}) => {
  const [orderDateRange, setOrderDateRange] = useState<any>(null);
  const [departmentDesignationAssociations, setDepartmentDesignationAssociations] = useState<any>([]);

  useEffect(() => {
    updateDepartmentAssociations();
      
  }, []);

  const updateDepartmentAssociations = () => {
    const associations: { [key: string]: any } = {};
    designationOptions.forEach(designation => {
      if (associations[designation.department]) {
        associations[designation.department].push(designation);
      } else {
        associations[designation.department] = [designation];
      }
    });
  // console.log(associations);
    setDepartmentDesignationAssociations(associations);
  };


  const handleSubmit = (values: any) => {
    const transformedValues = {
      ...values,
      exportType: exportType,
       designation: values.designation?.key,
      department: values.department?.key,
      reporting_manager_id: values.reporting_manager?.id,
      employeeType: values.employeeType?.key,
      employment_status: values.employment_status?.key,
      status: values.status?.key,
      date_range: orderDateRange,
    };
    
    // console.log(transformedValues);
    //  return;
    exportEmployeeListApi(transformedValues)
      .then((response) => {
        // Create a Blob object from the response data
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()} _${exportType}s_report.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
        onClose();
      })
      .catch((error) => {
        if (error.response.status == " 404") {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`No records available for export.`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const orderDateFilter = (data: any) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(
        constants.dateFormats.databaseFormat
      );
      const endDate = moment(dataRange[1]).format(
        constants.dateFormats.databaseFormat
      );
      setOrderDateRange(`${startDate},${endDate}`);
    } else {
      setOrderDateRange("");
    }
  };

  const getReportingManagers = (params: any) => {
    return new Promise((resolve, reject) => {
      getAllManagerList(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          designation: designationOptions[0],
          department: departmentOptions[0],
          reporting_manager: null,
          employeeType: null,
          employment_status: null,
          status: null,
          date_range: null,
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            className="export-excel-modal"
            show={isOpen}
            onHide={onClose}
            centered
          >
            <Form className="" noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <div className="fs-18 fw-bold mb-3 info-text">
                  Export to Excel
                </div>

                <div className="row">
                 
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="Select"
                      label="Department"
                      name="department"
                      options={departmentOptions}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      isClearable
                      value={values.department}
                      error={errors.department}
                      touched={touched.department}
                    />
                  </div>

                  <div className="col-md-6">
                    <FormSelect
                      placeholder="Select"
                      label="Designation"
                      name="designation"
                      options={values.department?.key ? departmentDesignationAssociations[values.department?.key] : []}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      isClearable
                      value={values.designation}
                      error={errors.designation}
                      touched={touched.designation}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormAsyncSelect
                      id="reporting_manager"
                      label="Reporting Manager"
                      name="reporting_manager"
                      isAsync
                      isClearable
                      getOptions={getReportingManagers}
                      value={values.reporting_manager}
                      onChange={(name: any, value: any) => {
                        setFieldValue(name, value);
                      }}

                      error={errors.reporting_manager}
                      touched={touched.reporting_manager}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="Select"
                      label="employeeType"
                      name="employeeType"
                      options={employeeTypeOptions}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      isClearable
                      value={values.employeeType}
                      error={errors.employeeType}
                      touched={touched.employeeType}
                    />
                  </div>

                  <div className="col-md-6">
                    <FormSelect
                      placeholder="Employment Status"
                      label="Employment Status"
                      name="employment_status"
                      options={employmentStatusOptions}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      isClearable
                      value={values.employment_status}
                      error={errors.employment_status}
                      touched={touched.employment_status}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormSelect
                      placeholder="Select"
                      label="Active/Inactive Status"
                      name="status"
                      options={statusOptions}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.key}
                      onChange={(name: string, value: string) => {
                        setFieldValue(name, value);
                      }}
                      isClearable
                      value={values.status}
                      error={errors.status}
                      touched={touched.status}
                    />
                  </div>

                  <div className="col-md-6">
                    <InputDateRangePicker
                      controlId="date_range"
                      label="Date Range"
                      placeholder="Date Range"
                      touched={false}
                      handleBlur={false}
                      handleChange={orderDateFilter}
                      handleClose={orderDateFilter}
                      errorsField={false}
                      value={values.date_range}
                      position="bottomEnd"
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                <button
                  className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <Button
                  type="submit"
                  className="fw-semibold fs-13 text-white mw-60 mt-2"
                  variant="primary"
                >
                  Export
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ExportData;
