import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { getPurchaseOrderDelayedData } from "../../../services/dashboardMaster.service";
import Loader from "../../../components/common/Loader";
import { DateRangeInterface } from "../dashboardInterface";
import TableSkeleton from "../../../components/common/Skeleton/Table.skeleton";
import moment from "moment";
import { Link } from "react-router-dom";
const DelayedPorchaseOrder: React.FC<DateRangeInterface> = ({
  orderDateRange,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const columns: GridColDef[] = [
    {
      field: "project_no_str",
      headerName: "Project Order No",
      flex: 1,
      sortable: true,
      filterable: true,
      hideable: false,
      renderCell: (params) => {
        return <div><Link className="blue-text text-decoration-none fw-semibold" to={`/projects/view/${params.row.project.id}`}>{params.row.project.project_no_str}</Link></div>;
      },
    },
    {
      field: "name",
      headerName: "Project",
      flex: 1,
      filterable: true,
      renderCell: (params) => {
        return <div>{params.row.project.name}</div>;
      },
    },
    {
      field: "shipping_address",
      headerName: "Delivery Location",
      flex: 1.3,
      renderCell: (params) => {
        return <div>{params.row.shipping_address}</div>;
      },
    },
    {
      field: "due_date",
      headerName: "Deadline",
      flex: 0.7,
      filterable: true,
      renderCell: (params) => {
        return <div>{moment(params.row.due_date).format('DD/MM/YYYY')}</div>;
      },
    },
  ];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, [orderDateRange]);
  const fetchData = (signal:any) => {
    getPurchaseOrderDelayedData(orderDateRange,signal)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="py-3 px-2">
      {loading ? (
        <TableSkeleton />
      ) : (
        <>
          <h5 className="fs-18 info-text fw-bold mb-3">
            Delayed Purchase Order
          </h5>

          <div className="dashboard-delay-order-table">
            <DataGrid
              rows={
                data.length
                  ? data.map((row: any, index: number) => ({
                      ...row,
                      project_no_str: row.project.project_no_str,
                      name: row.project.name,
                    }))
                  : []
              }
              columns={columns}
              pageSizeOptions={[5]}
              rowSelection={false}
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DelayedPorchaseOrder;
